import { Card } from "components/Card/Card";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading4 } from "styles/elements/Headings";

export const StyledAdaptivePracticeSessionResultsPageContainer = styled.div<ThemeProps>`
  background-color: #f5f7f9;
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow-y: scroll;
`;

export const StyledAdaptivePracticeSessionResultsPageContent = styled.div`
  max-width: 1100px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 2rem;
  border-top: 1px solid #e0e0e0;
`;

export const StyledStatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-top: 1rem;
`;

export const StyledStatisticCard = styled(Card)`
  text-align: center;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledStatistic = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: ${(props: ThemeProps) => props.theme.font.size._24};
`;

export const StyledStreak = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: ${(props: ThemeProps) => props.theme.font.size._24};
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-right: 10px;
    width: 1.2em;
    height: 1.2em;
  }
`;

export const StyledStatisticDenominator = styled.span`
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: 300;
`;

export const StyledTopicContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-left: 2.5rem;
  font-weight: 300;
  grid-template-columns: 1fr 1fr;
  svg {
    height: 1.5em;
    width: 1.5em;
  }
`;

export const StyledSectionHeading = styled(Heading4)`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
`;
