import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { LoadingSpinner } from "components/LoadingSpinner";
import { QuestionStatus, TestType } from "constants/exam-constants";
import { modalIds } from "constants/modals-constants";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";
import { useAnswer } from "hooks/useAnswer";
import { useModal } from "hooks/useModal";
import { useProduct } from "hooks/useProduct";
import { useWriting } from "hooks/useWriting";
import { useWritingTest } from "hooks/useWritingTest";
import { EmptyExamQuestionsMessage } from "layouts/EmptyExamQuestionsMessage/EmptyExamQuestionsMessage";
import { FixedTestHeader } from "layouts/FixedTestHeader";
import { HstContentPlayer } from "layouts/HstContentPlayer/HstContentPlayer";
import { QuestionManager } from "layouts/QuestionManager";
import { Box } from "styles/helpers/layout";
import { CompletedQuestions } from "../components/CompletedQuestions/CompletedQuestions";
import type { WritingExercisePagePathParams } from "./WritingExercisePage.model";
import { StyledDiagnosticTestExamPage } from "./WritingExercisePage.styled";

export const WritingExercisePage: React.FC = observer(() => {
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);

  const history = useHistory();

  const { setQuestionAnswerTextsForEmailPreview } = useWriting();
  const { writingTestStatus, writingTest, fetchWritingTest } = useWritingTest();
  const { questionStatus, evaluateAnswer, setQuestionStatus } = useAnswer();

  const { topicDetails, fetchTopicDetails, nonOwnedProductError } = useProduct();

  const { productId, exerciseId, moduleId, subjectId, topicId }: WritingExercisePagePathParams =
    useParams();

  const { modalParams, isModalOpen } = useModal();
  const isTheoryModalOpen = isModalOpen(modalIds.viewTheoryModal);

  useEffect(() => {
    setQuestionStatus(QuestionStatus.INITIAL);
  }, []);

  useEffect(() => {
    if (productId && exerciseId) {
      fetchWritingTest(+productId, +exerciseId);
    }
  }, [productId, exerciseId]);

  useEffect(() => {
    if (productId && modalParams?.topicId) {
      fetchTopicDetails(+productId, +modalParams?.topicId);
    }
  }, [isTheoryModalOpen, productId, modalParams?.topicId]);

  useEffect(() => {
    // Reset contents of email preview
    // TODO: this will no longer be needed when we refactor the code so this is stored in the inner state of a compose email exercise component
    return () => setQuestionAnswerTextsForEmailPreview({});
  }, [writingTest]);

  const loadNextQuestion = () => {
    setSelectedQuestionIndex(selectedQuestionIndex + 1);
  };

  const onClose = () => {
    history.push(
      buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_TOPIC_DETAILS, {
        productId,
        moduleId,
        subjectId,
        topicId,
      }),
    );
  };

  if (!Number(productId) || nonOwnedProductError) {
    return <Redirect to={PRODUCT_PAGES_ROUTES.PRODUCT_LIST} />;
  }

  if (!writingTest) return <LoadingSpinner isFullScreen />;

  const { questions } = writingTest;
  const examIsEmpty = questions.length === 0;
  const allQuestionsFinished = selectedQuestionIndex === questions.length;
  const filterPrevQuestionAnswers =
    writingTestStatus.length > 0
      ? writingTestStatus.find(
          ({ id, answers }) =>
            questions?.[selectedQuestionIndex] &&
            questions[selectedQuestionIndex].id === id &&
            answers,
        )?.answers
      : [];

  return (
    <StyledDiagnosticTestExamPage>
      <HstContentPlayer
        content={
          <Box margin="auto" width="100%">
            {questions?.[selectedQuestionIndex] && (
              <QuestionManager
                evaluateAnswer={(
                  productId,
                  questionId,
                  answers,
                  exerciseId,
                  skipAnswerValidation,
                ) => {
                  evaluateAnswer({
                    productId,
                    questionId,
                    answers,
                    skipAnswerValidation,
                    exerciseId,
                    testType: TestType.WRITING_EXERCISE,
                    testToken: writingTest?.token ?? "",
                  });
                }}
                exercise={writingTest}
                flags={[]}
                prevAnswerValues={filterPrevQuestionAnswers}
                question={questions[selectedQuestionIndex]}
                showTheoryButton={false}
                showTip={questionStatus !== QuestionStatus.INITIAL}
              />
            )}
            {!examIsEmpty && allQuestionsFinished && (
              <CompletedQuestions testType={TestType.WRITING_EXERCISE} />
            )}
            {examIsEmpty && <EmptyExamQuestionsMessage />}
          </Box>
        }
        header={
          <FixedTestHeader
            currentQuestion={questions[selectedQuestionIndex]}
            options={{ showTheory: false }}
            selectedQuestionIndex={selectedQuestionIndex}
            subtitle={`${writingTest.topic?.title} | ${writingTest?.title}`}
            title={writingTest?.subject?.title || ""}
            totalQuestionCount={questions.length}
            onClose={onClose}
          />
        }
        loadNextQuestion={loadNextQuestion}
        selectedQuestion={questions[selectedQuestionIndex]}
        testType={TestType.WRITING_EXERCISE}
        topicDetails={topicDetails}
      />
    </StyledDiagnosticTestExamPage>
  );
});
