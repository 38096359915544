import type React from "react";
import { type ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { Select } from "components/SelectUI/Select";

import { useFormUpdate } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/hooks/useFormUpdate";
import {
  FormH4Label,
  FormLabel,
  FormStep,
  FormStepHeader,
  FormStepHeading,
  FormStepIntro,
} from "../FormStep.styled";
import type { TestTitleStepFormValues, TestTitleStepProps } from "./TestTitleStep.model";
import { FormContent, TestVersion } from "./TestTitleStep.styled";
import { createValidationSchema } from "./TestTitleStep.validation";

export const TestTitleStep: React.FC<TestTitleStepProps> = ({
  formValues,
  formErrors,
  isDisabled = false,
  testGroupOptions,
  onFormUpdate,
}) => {
  const { t } = useTranslation("scheduled-tests");
  const [values, setValues] = useState<TestTitleStepFormValues>(
    (({ title = "", testId = -1 }) => ({
      title,
      testId,
    }))(formValues) as TestTitleStepFormValues,
  );

  const [selectedTestGroupId, setSelectedTestGroupId] = useState<number>(() => {
    if (!values.testId) {
      return testGroupOptions[0]?.id || -1;
    }
    // select the test group that the currently selected testId belongs to
    return (
      testGroupOptions.find((testGroup) =>
        testGroup.tests.map((test) => test.id).includes(values.testId),
      )?.id ?? -1
    );
  });

  const selectedTestGroup =
    selectedTestGroupId === -1
      ? null
      : testGroupOptions.find((group) => group.id === selectedTestGroupId);

  const validationSchema = createValidationSchema();
  useFormUpdate<typeof validationSchema>({
    values,
    validationSchema,
    onFormUpdate,
  });

  useEffect(() => {
    // when the selected test group changes and the selected test id is not
    // the id of any of the group's test children, reset the selected testId
    if (
      selectedTestGroup &&
      values.testId !== -1 &&
      !selectedTestGroup.tests.map((test) => test.id).includes(values.testId)
    ) {
      setValues({
        ...values,
        testId: -1,
      });
    }
  }, [selectedTestGroup, values]);

  useEffect(() => {
    // if the selected has been reset, make sure the selected test group is reset too
    if (!values.testId) {
      setSelectedTestGroupId(-1);
    }
  }, [values.testId]);

  const updateValue = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const testGroupDropdownOptions = testGroupOptions.map(({ id, label }) => ({ value: id, label }));

  const testDropdownOptions = useMemo(() => {
    if (!selectedTestGroup?.tests) {
      return [];
    }

    return selectedTestGroup.tests.map(({ id, title }) => ({ value: id, label: title }));
  }, [values, selectedTestGroup]);

  const onTestGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setSelectedTestGroupId(Number.parseInt(event.target.value, 10));
  const onTestChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setValues({
      ...values,
      testId: Number.parseInt(event.target.value, 10),
    });

  return (
    <FormStep>
      <FormStepHeader>
        <FormStepHeading>{t("testTitleStep.header", "Test title - stap 2")}</FormStepHeading>
        <FormStepIntro>{t("testTitleStep.intro")}</FormStepIntro>
      </FormStepHeader>
      <FormContent>
        <div>
          <FormLabel htmlFor="scheduled-test-title">
            {t("testTitleStep.title.label", "Give the test a title")}
          </FormLabel>
          <InputWithErrorMessage
            // https://stackoverflow.com/questions/2180645/is-automatically-assigning-focus-bad-for-accessibility
            autoFocus
            dataCy="scheduled-test-title-input"
            disabled={isDisabled}
            errorMessage={formErrors.title?.errors?.join(", ")}
            hasError={!!formErrors.title}
            id="scheduled-test-title"
            placeholder={t("testTitleStep.title.placeholder", "e.g. English Group 5 - Block 3")}
            value={values.title}
            onChange={updateValue("title")}
          />
        </div>
        <div>
          <FormH4Label id="scheduled-test-wizard-test-group-selector-label">
            {t("testTitleStep.testGroupInput.label", "Test level")}
          </FormH4Label>
          <Select
            aria-labelledby="scheduled-test-wizard-test-group-selector-label"
            dataCy="scheduled-test-level-select"
            options={testGroupDropdownOptions}
            placeholder={t("selectTestLevel.placeholder", "Select a test level")}
            value={selectedTestGroupId}
            onChange={onTestGroupChange}
          />
        </div>
        <div />
        <TestVersion>
          <FormH4Label id="scheduled-test-wizard-test-version-selector-label">
            {t("testTitleStep.testInput.label", "Test version")}
          </FormH4Label>
          <Select
            aria-labelledby="scheduled-test-wizard-test-version-selector-label"
            dataCy="scheduled-test-version-select"
            disabled={!selectedTestGroup}
            errorMessage={formErrors.testId?.errors?.join(", ")}
            hasError={!!formErrors.testId}
            options={testDropdownOptions}
            placeholder={
              !selectedTestGroup
                ? t("selectTestVersion.selectTestLevelMessage", "Select a level first")
                : t("selectTestVersion.placeholder", "Select a version")
            }
            value={values.testId}
            onChange={onTestChange}
          />
        </TestVersion>
      </FormContent>
    </FormStep>
  );
};
