import { Card } from "components/Card/Card";
import { Divider } from "components/Divider/Divider";
import type { ResultsAPI } from "generated/types";
import { useDomainHandler } from "hooks/useDomainHandler";
import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Surface,
  Symbols,
  XAxis,
  YAxis,
} from "recharts";
import type { Payload } from "recharts/types/component/DefaultLegendContent";
import { useTheme } from "styled-components/macro";
import { Heading3, Heading4 } from "styles/elements/Headings";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import { WritingStudentListModal } from "../WritingStudentListModal/WritingStudentListModal";

export interface WritingSubjectsResultsOverviewProps {
  subjects: ResultsAPI.ProductWritingSubjectStatus[];
  studentCount: number;
  onShouldFetchWritingSubjectStudents: (subjectId: number) => void;
}

export interface CustomTickProps {
  x: number;
  y: number;
  payload: { value: string };
  clickable?: boolean;
}

interface CustomLegendEntry {
  color?: string;
  value: string;
}

interface CustomLegendProps {
  payload: CustomLegendEntry[];
}

export const WritingSubjectsResultsOverview: FC<WritingSubjectsResultsOverviewProps> = ({
  subjects,
  studentCount,
  onShouldFetchWritingSubjectStudents,
}) => {
  const theme = useTheme() as ElearningThemeInterface;
  const { getStudentTermByDomain } = useDomainHandler();
  const { t } = useTranslation("teacher-dashboard");

  const subjectsDataForChart = subjects.map((subject) => ({
    name: subject.subjectTitle,
    didNotStart: subject.usersNotStartedAmount,
    inProgress: subject.usersInProgressAmount,
    ready: subject.usersCompletedAmount,
  }));

  const barColors = [
    theme.palette.text.primary,
    theme.palette.yellow._800,
    theme.palette.feedback.success._800,
  ];

  const barNames = {
    didNotStart: t("writingResults.progress.didNotStart"),
    inProgress: t("writingResults.progress.inProgress"),
    ready: t("writingResults.progress.ready"),
  };

  const labelFormatter = (value: number) => (value > 0 ? `${value}` : "");

  // This is used to render the text with the subject names and the view students links
  const CustomTick = (props: CustomTickProps) => {
    const [tickColor, setTickColor] = useState("#0078F1");
    const { x, y, payload, clickable } = props;
    const subjectId = subjects.find((subject) => subject.subjectTitle === payload.value)?.subjectId;
    return (
      <g cursor={clickable ? "pointer" : "auto"} transform={`translate(${x},${y})`}>
        {subjectId && clickable ? (
          // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
          <text
            data-cy="view-students-button"
            fill={tickColor}
            onClick={() => onShouldFetchWritingSubjectStudents(subjectId)}
            onMouseEnter={() => setTickColor("#0078F199")}
            onMouseLeave={() => setTickColor("#0078F1")}
          >
            {t("writingResults.progress.viewStudentsBtn")}
          </text>
        ) : (
          <text data-cy="subject-name" fill={theme.palette.text.secondary}>
            {payload.value}
          </text>
        )}
      </g>
    );
  };

  const CustomLegend = (props: CustomLegendProps) => {
    const { payload } = props;

    return (
      <ul style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "0.75rem" }}>
        {payload.map((entry: Payload) => (
          <li key={entry.value} style={{ display: "flex", listStyleType: "none" }}>
            <Surface height={20} style={{ display: "inline-block" }} width={20}>
              <Symbols
                cx={10}
                cy={12.5}
                fill={entry.color}
                size={100}
                stroke={theme.palette.neutral._900}
                type="circle"
              />
            </Surface>
            <span style={{ marginLeft: 4, color: theme.palette.text.secondary }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <WritingStudentListModal />
      <Card layout={{ mt: 2, p: 2 }}>
        <Heading3 fontWeight="normal">{t("writingResults.progress.title")}</Heading3>
        {studentCount && (
          <Heading4 fontWeight="normal" mb="2rem">
            {`${t("writingResults.progress.total", {
              studentTerm: getStudentTermByDomain({ usePlural: true }),
            })} (${studentCount})`}
          </Heading4>
        )}
        <Divider />
        <ResponsiveContainer
          debounce={300}
          minHeight={subjectsDataForChart.length * 40 + 90}
          width="100%"
        >
          <BarChart
            barSize={25}
            data={subjectsDataForChart}
            data-cy="writing-subjects-results-graph"
            layout="vertical"
            margin={{
              top: 40,
              right: 90,
              left: 250,
              bottom: 5,
            }}
          >
            <XAxis hide type="number" />
            <YAxis
              axisLine={false}
              dataKey="name"
              fill={theme.palette.text.primary}
              interval={0}
              orientation="left"
              textAnchor="start"
              tick={(props: CustomTickProps) => (
                <CustomTick payload={props.payload} x={props.x} y={props.y} />
              )}
              tickLine={false}
              tickMargin={300}
              type="category"
              yAxisId="left"
            />
            <YAxis
              axisLine={false}
              dataKey="name"
              interval={0}
              orientation="right"
              textAnchor="end"
              tick={(props: CustomTickProps) => (
                <CustomTick clickable payload={props.payload} x={props.x} y={props.y} />
              )}
              tickLine={false}
              tickMargin={10}
              type="category"
              yAxisId="right"
            />
            <Legend content={(props) => <CustomLegend payload={props.payload as Payload[]} />} />
            {Object.keys(subjectsDataForChart[0])
              .filter((key) => key !== "name")
              .map((key, index) => (
                <Bar
                  key={key}
                  dataKey={key}
                  fill={barColors[index]}
                  name={barNames[key as keyof typeof barNames]}
                  stackId="a"
                  stroke={theme.palette.neutral._900}
                  strokeWidth="1"
                  yAxisId="left"
                >
                  <LabelList
                    dataKey={key}
                    fill={theme.palette.text.secondary}
                    formatter={labelFormatter}
                    position="insideLeft"
                  />
                </Bar>
              ))}
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </>
  );
};
