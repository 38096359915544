import i18n from "i18n";
import * as Yup from "yup";

export interface UpsertSEBKeySchema {
  name: string;
  key: string;
}

export const buildSEBKeySchema = (): Yup.SchemaOf<UpsertSEBKeySchema> =>
  Yup.object({
    name: Yup.string().required(
      i18n.t("scheduled-tests:testOptionsStep.sebKeys.validation.required"),
    ),
    key: Yup.string().required(
      i18n.t("scheduled-tests:testOptionsStep.sebKeys.validation.required"),
    ),
  }).required();
