import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useScheduledTests } from "hooks/useScheduledTests";
import { useTestStatusAndTimeLeft } from "hooks/useTestStatusAndTimeLeft";
import type { ExerciseResult } from "models/exam/Exam";
import type { ScheduledTestScoreDetail } from "models/exam/ScheduledTest";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";

import { ExerciseQuestionResults } from "components/ExerciseQuestionResults/ExerciseQuestionResults";
import { LoadingSpinner } from "components/LoadingSpinner";

import { ResultsBreadcrumbs } from "../../components/ResultsBreadcrumbs/ResultsBreadcrumbs";
import { ResultsHeader } from "../../components/ResultsHeader/ResultsHeader";
import type { ExerciseResultsPagePathParams } from "./ExerciseResultsPage.model";
import { ExerciseTitleBar } from "./components/ExerciseTitleBar/ExerciseTitleBar";

import { StyledContainer } from "./ExerciseResultsPage.styled";

const isReviewPeriod = (scheduledTestContent: ScheduledTestScoreDetail): boolean => {
  const { reviewStartDate, reviewEndDate } = scheduledTestContent;
  return !!(
    reviewStartDate &&
    new Date() > new Date(reviewStartDate) &&
    reviewEndDate &&
    new Date() < new Date(reviewEndDate)
  );
};

export const ExerciseResultsPage: React.FC = observer(() => {
  const history = useHistory();
  const { code, exerciseId }: ExerciseResultsPagePathParams = useParams();

  const {
    scheduledTestScore,
    scheduledTestExercise: exercise,
    fetchScheduledTestExercise: fetchExercise,
    fetchScheduledTestExerciseResults,
    scheduledTestResults,
    setScheduledTestResults,
    setScheduledTestExercise,
    scheduledTestStatusAndTimeLeft,
  } = useScheduledTests();

  useTestStatusAndTimeLeft(code);

  useEffect(() => {
    if (!scheduledTestScore || !isReviewPeriod(scheduledTestScore)) {
      history.replace(
        buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PAGE, {
          code,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (!exercise || exercise.id !== +exerciseId) {
      setScheduledTestResults(null);
      setScheduledTestExercise(null);
      fetchExercise({ testCode: code, exerciseId: +exerciseId });
      fetchScheduledTestExerciseResults(code, +exerciseId);
    }
  }, [exercise, exerciseId]);

  if (!exercise || !scheduledTestScore || !scheduledTestResults) {
    return <LoadingSpinner isFullScreen />;
  }

  const exerciseResult: ExerciseResult | undefined = scheduledTestResults.find(
    (result) => result.id === +exerciseId,
  );

  const exerciseScore =
    scheduledTestScore.exercises.find((item) => item.id === +exerciseId)?.score || 0;

  return (
    <>
      <ResultsHeader
        secondsLeft={scheduledTestStatusAndTimeLeft?.counter || 0}
        testType={scheduledTestScore.type}
        title={scheduledTestScore.title}
      />
      <StyledContainer>
        <ResultsBreadcrumbs dataCy="test-exercise-page-breadcrumbs" />
        <ExerciseTitleBar score={exerciseScore} title={exercise.title} />
        <ExerciseQuestionResults
          exerciseQuestionResults={exerciseResult?.questions || []}
          exerciseQuestions={exercise.questions || []}
        />
      </StyledContainer>
    </>
  );
});
