import type React from "react";

import type { ProgressBarProps } from "./ProgressBar.model";
import { StyledProgressBar, StyledProgressBarActiveSegment } from "./ProgressBar.styled";

export const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  dataCy = "progress-bar",
  currentValue,
  maxValue = 100,
  variant = "default",
}) => {
  const percentage = (currentValue / maxValue) * 100;

  return (
    <StyledProgressBar className={className} data-cy={dataCy}>
      <StyledProgressBarActiveSegment $variant={variant} $width={percentage} />
    </StyledProgressBar>
  );
};
