import { Button } from "components/Button";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";

export const StyledWrapper = styled.div<Props>`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<Props>`
  color: ${({ theme }: Props) => theme.palette.feedback.error.brand};
  margin-right: 1rem;
`;

export const StyledLink = styled.div<Props>`
  text-decoration: underline;
  color: ${({ theme }: Props) => theme.palette.secondary.brand};
  cursor: pointer;

  :hover {
    color: ${({ theme }: Props) => theme.palette.secondary.brand};
  }
`;

export const StyledInvalidButton = styled(Button)<Props>`
  background: ${({ theme }: Props) => theme.palette.feedback.error.brand};
  color: white;
  font-weight: normal;
  padding: 0.5rem 1.5rem;

  :hover {
    background: #9d0000;
    color: white;
    font-weight: normal;
  }
`;

export const StyledInvalidatedButtonWrapper = styled.div<Props>`
  background: ${({ theme }: Props) => theme.palette.feedback.error._950};
  color: ${({ theme }: Props) => theme.palette.feedback.error.brand};
  padding: 0.5rem 1rem;
  font-weight: 400;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
