import { StyledCountdownTimer } from "components/CountdownTimer/CountdownTimer.styled";
import { TestHeader } from "components/TestHeader/TestHeader";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

export const StyledTestHeader = styled(TestHeader)`
  ${StyledCountdownTimer} {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  }
`;
