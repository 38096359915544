import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

export interface InviteOptionProps extends ThemeProps {
  isSelected?: boolean;
}

export const StyledWrapper = styled.div<ThemeProps>`
  margin-top: 2rem;
  margin-left: 1rem;
`;

export const StyledInnerWrapper = styled.div<ThemeProps>`
  margin-top: 2rem;
  width: 90%;
`;

export const StyledHr = styled.hr<ThemeProps>`
  margin-top: 3rem;
  width: 100%;
`;
