import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { Button } from "components/Button";
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from "constants/routes";
import { useScheduledTests } from "hooks/useScheduledTests";
import { observer } from "mobx-react";
import { type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Heading3 } from "styles/elements/Headings";
import {
  StyledAdditionalTime,
  StyledFontAwesomeIcon,
  StyledLastCompletedTests,
  StyledProgressIndicator,
  StyledTest,
} from "./LastCompletedTests.styled";

interface ComponentProps {
  width?: string;
}

export const LastCompletedTests: FC<ComponentProps> = observer(({ width }) => {
  const { t } = useTranslation("teacher-dashboard");
  const history = useHistory();

  const { lastCompletedTests, setLastCompletedTests, fetchLastCompletedTests } =
    useScheduledTests();

  useEffect(() => {
    if (!lastCompletedTests) {
      fetchLastCompletedTests();
    }
    return () => {
      setLastCompletedTests(null);
    };
  }, []);

  return (
    <StyledLastCompletedTests $width={width || "64%"} data-cy="last-completed-tests">
      <Heading3>{t("homePage.completedTest.title")}</Heading3>
      {lastCompletedTests?.map(({ id, title, hadExtraTime, avgScore }, index) => (
        <StyledTest key={id} even={index % 2 === 0}>
          <span>{title}</span>
          <span>
            <StyledAdditionalTime $visible={hadExtraTime}>
              {t("homePage.completedTest.additionalTime")}
            </StyledAdditionalTime>
          </span>
          <StyledProgressIndicator percentage={Math.round(avgScore)} />
          <span>{`${Math.round(avgScore)}%`}</span>
          <StyledFontAwesomeIcon
            data-cy={
              index === lastCompletedTests.length - 1 && "last-completed-test-details-button"
            }
            iconName="fas fa-chevron-right"
            onClick={() =>
              history.push(
                buildUrlWithPathParams(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS, {
                  scheduledTestId: id,
                }),
              )
            }
          />
        </StyledTest>
      ))}
      <Button
        variant="border"
        onClick={() =>
          history.push(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_OVERVIEW)
        }
      >
        {t("homePage.completedTest.button")}
      </Button>
    </StyledLastCompletedTests>
  );
});
