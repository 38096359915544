import { redirectToIdpLogout } from "_helpers/utils/idp";
import type { AxiosError, AxiosResponse } from "axios";
import { type ApiRequestError, RequestError, type RequestErrorModel } from "models/error/Error";
import { clearCookiesLogout, clearUsetifulUserDataOnLogout } from "persistence/access";

export function handlingResponse<T>(
  successStatus = [200],
  errorMessage = "Error",
  type = "",
): (response: AxiosResponse) => T {
  return function axiosResponse(response: AxiosResponse): T {
    if (!successStatus.includes(response.status)) {
      throw new RequestError({ message: errorMessage, status: response.status, type });
    }
    return response.data as T;
  };
}

export const logError = (
  error: AxiosError<ApiRequestError>,
  useLogoutHandler = true,
): RequestErrorModel => {
  const { data, status } = error.response || {};

  if (useLogoutHandler && status === 401) {
    clearCookiesLogout();
    clearUsetifulUserDataOnLogout();
    redirectToIdpLogout(window.location.pathname);
  }

  return {
    errCode: data?.extra?.errCode,
    extra: data?.extra,
    message: data?.message || "",
    status: status || 500,
    type: data?.type || "",
  };
};
