import i18n from "i18n";
import * as Yup from "yup";

export interface SchoolUpdateFormFields {
  title: string;
  domain: string;
  educations: Array<{ id?: string | null; title: string; archived?: boolean }>;
  sebEnabled?: boolean;
  schoolyearEnabled?: boolean;
  schoolyearKey?: string | null;
  immersiveReaderEnabled?: boolean;
}

export const buildUpdateFormSchema = (): Yup.SchemaOf<SchoolUpdateFormFields> =>
  Yup.object({
    title: Yup.string().required(
      i18n.t("admin-environment:schoolDetails.form.name.errors.required"),
    ),
    domain: Yup.string().required(
      i18n.t("admin-environment:schoolDetails.form.domain.errors.required"),
    ),
    educations: Yup.array()
      .of(
        Yup.object({
          id: Yup.string().nullable(),
          title: Yup.string().required(
            i18n.t("admin-environment:schoolDetails.form.education.name.errors.required"),
          ),
          archived: Yup.boolean().optional(),
        }),
      )
      .required(),
    immersiveReaderEnabled: Yup.boolean().optional(),
    sebEnabled: Yup.boolean().optional(),
    schoolyearEnabled: Yup.boolean().optional(),
    schoolyearKey: Yup.string()
      .nullable()
      .optional()
      .when("schoolyearEnabled", {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty: We want to make the key required when toggle is enabled
        then: Yup.string().required(
          i18n.t("admin-environment:schoolDetails.form.schoolyearKey.errors.required"),
        ),
      }),
  }).required();
