import type React from "react";
import { useCallback } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";

import { useCurrentPathMatch } from "hooks/useCurrentPathMatch";
import { useRedirect } from "hooks/useRedirect";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";

import type { NavigationMenuItemProps } from "./NavigationMenuItem.model";
import { StyledNavigationMenuItem } from "./NavigationMenuItem.styled";

export const NavigationMenuItem: React.FC<NavigationMenuItemProps> = ({
  children,
  dataCy,
  onlyActiveOnExactPath = false,
  redirectPath,
  nestingLevel = 1,
  eventTrackData,
  excludeFromActivePath,
}) => {
  const location = useLocation();
  const { redirectToUrl } = useRedirect();
  const { currentRouteMatchesAny } = useCurrentPathMatch();

  const onNavigationItemClick = useCallback(() => {
    redirectToUrl(redirectPath);

    // Trigger platform event if track data is specified
    eventTrackData && trackPlatformEvent(eventTrackData);
  }, [redirectPath, eventTrackData, redirectToUrl]);

  const currentLocationExactlyMatchesRedirectPath = !!useRouteMatch({
    path: redirectPath,
    exact: true,
  });

  const isActive = onlyActiveOnExactPath
    ? currentLocationExactlyMatchesRedirectPath
    : location.pathname.includes(redirectPath) &&
      (!excludeFromActivePath || !currentRouteMatchesAny(excludeFromActivePath));

  return (
    <StyledNavigationMenuItem
      $nestingLevel={nestingLevel}
      className={isActive ? "active" : ""}
      data-cy={dataCy}
      selected={isActive}
      onClick={onNavigationItemClick}
    >
      {children}
    </StyledNavigationMenuItem>
  );
};
