import styled from "styled-components/macro";

import { Button } from "components/Button";
import { Modal } from "components/Modal";
import type { ThemeProps } from "models/styles/theme";
import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { calculateRem } from "styles/mixins/calculateRem";

type ParagraphProps = ThemeProps & {
  marginTop?: number;
};

export const StyledHeading = styled(Heading3)<ThemeProps>`
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: ${calculateRem(36)};
`;

export const StyledParagraph = styled(ParagraphLarge)<ParagraphProps>`
  color: ${(props: ThemeProps) => props.theme.palette.neutral._400};
  line-height: ${calculateRem(24)};
  margin-bottom: 0;
  margin-top: ${(props: ParagraphProps) => calculateRem(props.marginTop || 0)};
`;

export const StyledButton = styled(Button)<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: bold;
  margin: ${calculateRem(32)} ${calculateRem(27)} 0 ${calculateRem(27)};
`;

export const StyledModal = styled(Modal)<ThemeProps>`
  width: 45rem;
`;
