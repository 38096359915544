import { HST_ERROR_CODES } from "@infinitaslearning/module-he-common";
import axios, { type AxiosError } from "axios";

import { ERROR_TYPES } from "constants/error-constants";
import type { ContentAPI, ResultsAPI } from "generated/types";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type {
  ExerciseAnswer,
  ExerciseResult,
  ExerciseStatusResponse,
  ScheduledTestsResultsUser,
} from "models/exam/Exam";
import type {
  LastCompletedTest,
  OwnedScheduledTestResults,
  OwnedScheduledTestResultsOverviewItem,
  ScheduledTest,
  ScheduledTestCreatePayload,
  ScheduledTestDetails,
  ScheduledTestExercise,
  ScheduledTestResultList,
  ScheduledTestResultOverview,
  ScheduledTestResultsReportSettings,
  ScheduledTestReviewPeriodTimer,
  ScheduledTestScoreDetail,
  ScheduledTestStatus,
  ScheduledTestStatusAndTimeLeft,
  ScheduledTestSummary,
  UpdateScheduledTestDurationPayload,
} from "models/exam/ScheduledTest";
import type { ScheduledTestLog, ScheduledTestStudentLog } from "models/log/Log";
import type { ProductTestGroupType } from "models/product/ProductTestGroups";
import type { OverviewFilters } from "models/progress/Progress";
import type { OwnedScheduledTestResultsOverviewFilters } from "pages/TeacherEnvironment/TeacherDashboard/subpages/OwnedScheduledTestResultsOverview/OwnedScheduledTestResultsOverview.model";
import cookieStorage from "persistence";
import { handlingResponse, logError } from "repositories/utils";

export const fetchScheduledTests = async (
  testStatus?: ScheduledTestStatus,
): Promise<ScheduledTestSummary[]> => {
  try {
    const result = await axios.get("/api/test/schedule", {
      headers: { Authorization: cookieStorage.getToken() },
      params: { status: testStatus },
    });
    return handlingResponse<ScheduledTestSummary[]>(
      [200],
      "Error retrieving scheduled tests",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHEDULED_TESTS_FAILED });
  }
};

export const fetchScheduledTestDetails = async (
  scheduledTestId: number,
): Promise<ScheduledTestDetails> => {
  try {
    const result = await axios.get(`/api/test/schedule/${scheduledTestId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ScheduledTestDetails>(
      [200],
      "Error retrieving scheduled test details",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_SCHEDULED_TEST_DETAILS_FAILED,
    });
  }
};

export const postScheduledTest = async (
  payload: ScheduledTestCreatePayload,
): Promise<ScheduledTest[]> => {
  try {
    const result = await axios.post("/api/test/schedule", payload, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ScheduledTest[]>([200], "Error creating scheduled test ")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.POST_SCHEDULED_TEST_FAILED });
  }
};

export const updateScheduledTest = async (
  scheduledTestId: number,
  payload: ContentAPI.UpdateScheduledTest | UpdateScheduledTestDurationPayload,
): Promise<ScheduledTest> => {
  try {
    const result = await axios.put(`/api/test/schedule/${scheduledTestId}`, payload, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ScheduledTest>([200], "Error updating scheduled test ")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPDATE_SCHEDULED_TEST_FAILED });
  }
};

export const fetchScheduledTestContent = async (
  code: string,
  password?: string,
): Promise<ContentAPI.ScheduledTestExercisesDetails> => {
  try {
    const result = await axios.post(
      `/api/test/${code}`,
      { password },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<ContentAPI.ScheduledTestExercisesDetails>(
      [200],
      `Failed to fetch data on test ${code}`,
    )(result);
  } catch (error) {
    const { errCode, message, status, extra } = logError(error as AxiosError<ApiRequestError>);

    // Throw custom exception when request fails due to Schoolyear being enabled for the test
    if (errCode === HST_ERROR_CODES.SCHOOLYEAR_ACCESS_REQUIRED) {
      throw new RequestError({
        message,
        status,
        type: ERROR_TYPES.SCHEDULED_TEST_SCHOOLYEAR_ACCESS_REQUIRED,
        extra,
      });
    }

    throw new RequestError({
      message,
      status,
      type:
        errCode === HST_ERROR_CODES.TEST_ACCESS_PASSWORD_REQUIRED ||
        errCode === HST_ERROR_CODES.TEST_REVIEW_ACCESS_PASSWORD_REQUIRED
          ? ERROR_TYPES.SCHEDULED_TEST_PASSWORD_REQUIRED
          : ERROR_TYPES.GET_SCHEDULED_TEST_CONTENT_FAILED,
    });
  }
};

export const fetchScheduledTestExercise = async (
  testCode: string,
  exerciseId: number,
  randomizeQuestions?: boolean,
): Promise<ScheduledTestExercise> => {
  try {
    const response = await axios.get(`/api/test/${testCode}/exercise/${exerciseId}`, {
      headers: {
        authorization: cookieStorage.getToken(),
      },
      params: { randomizeQuestions },
    });
    return handlingResponse<ScheduledTestExercise>(
      [200],
      `Failed to fetch data on exercise ${exerciseId}`,
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_SCHEDULED_TEST_EXERCISE_FAILED,
    });
  }
};

export const finishScheduledTest = async (
  testCode: string,
  testToken: string,
): Promise<boolean> => {
  try {
    const result = await axios.put(
      `/api/test/${testCode}/finish`,
      {},
      {
        headers: {
          authorization: cookieStorage.getToken(),
          test_session_token: testToken,
        },
      },
    );
    return handlingResponse<boolean>([200], "Error marking test attempt as finished ")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.FINISH_SCHEDULED_TEST_FAILED });
  }
};

export const fetchScheduledTestExerciseResults = async (
  testCode: string,
  exerciseId: number,
): Promise<ExerciseResult> => {
  try {
    const result = await axios.get(`/api/test/${testCode}/exercise/${exerciseId}/results`, {
      headers: {
        authorization: cookieStorage.getToken(),
      },
    });
    return handlingResponse<ExerciseResult>([200], "Error fetching scheduled test results")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_STUDENT_SCHEDULED_TEST_SCORES_FAILED,
    });
  }
};

export const fetchScheduledTestLiveData = async (
  scheduledTestId: number,
): Promise<ScheduledTestLog[]> => {
  try {
    const result = await axios.get(`/api/test/logs/${scheduledTestId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ScheduledTestLog[]>(
      [200],
      "Error retrieving scheduled test live data",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHEDULED_TEST_LIVE_DATA });
  }
};

export const fetchScheduledTestStudentLogs = async (
  scheduledTestId: number,
  studentId: number,
): Promise<ScheduledTestStudentLog[]> => {
  try {
    const result = await axios.get(`/api/test/logs/${scheduledTestId}/student/${studentId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ScheduledTestStudentLog[]>(
      [200],
      "Error retrieving scheduled test student logs",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHEDULED_TEST_STUDENT_LOGS });
  }
};

export const fetchStudentScheduledTestResultsOverview = async (
  productId: number,
  testType: ProductTestGroupType,
): Promise<ScheduledTestResultOverview[]> => {
  try {
    const scheduledTestResultsOverview = await axios.get(
      `/api/products/${productId}/${testType}/results`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<ScheduledTestResultOverview[]>(
      [200],
      "Error getting scheduled tests results overview",
    )(scheduledTestResultsOverview);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_STUDENT_SCHEDULED_TEST_RESULTS_OVERVIEW_FAILED,
    });
  }
};

export const fetchStudentScheduledTestScores = async (
  testCode: string,
): Promise<ScheduledTestScoreDetail> => {
  try {
    const scheduledTestScores = await axios.get(`/api/test/${testCode}/scores`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ScheduledTestScoreDetail>(
      [200],
      "Error fetching the scores for the requested test code",
    )(scheduledTestScores);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_STUDENT_SCHEDULED_TEST_SCORES_FAILED,
    });
  }
};

export const fetchStudentScheduledTestResultsList = async (
  testType: ProductTestGroupType,
): Promise<ScheduledTestResultList[]> => {
  try {
    const scheduledTestResultsList = await axios.get(`/api/products/${testType}/results`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ScheduledTestResultList[]>(
      [200],
      "Error getting scheduled tests results list",
    )(scheduledTestResultsList);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_STUDENT_SCHEDULED_TEST_RESULTS_LIST_FAILED,
    });
  }
};

// Fetch overview of scheduled test results within calling user educations
export const fetchScheduledTestResultsOverview = async ({
  searchTerm,
  minStartDate,
  maxStartDate,
  testType,
  productId,
  skillLevel,
  onlyOwnedBySelf,
}: OwnedScheduledTestResultsOverviewFilters = {}): Promise<
  OwnedScheduledTestResultsOverviewItem[]
> => {
  try {
    const scheduledTestScoreOverview = await axios.get("/api/tests/overview/scores", {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        searchTerm,
        minStartDate,
        maxStartDate,
        testType,
        productId,
        skillLevel,
        onlyOwnedBySelf,
      },
    });
    return handlingResponse<OwnedScheduledTestResultsOverviewItem[]>(
      [200],
      "Error fetching results overview for test created by any teacher within current user educations",
    )(scheduledTestScoreOverview);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_SCHEDULED_TEST_RESULTS_OVERVIEW_FAILED,
    });
  }
};

export const fetchOwnedScheduledTestResults = async (
  scheduledTestId: number,
): Promise<OwnedScheduledTestResults> => {
  try {
    const ownedScheduledTestScores = await axios.get(
      `/api/scheduled-test/${scheduledTestId}/results`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<OwnedScheduledTestResults>(
      [200],
      "Error fetching results overview for test created by current user",
    )(ownedScheduledTestScores);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_OWNED_SCHEDULED_TEST_RESULTS_FAILED,
    });
  }
};

export const fetchScheduledTestResultsReport = async (
  scheduledTestId: number,
  { cumulativeSinceDate, cumulativeUntilDate }: ScheduledTestResultsReportSettings = {},
): Promise<string> => {
  try {
    const result = await axios.get(`/api/scheduled-test/${scheduledTestId}/results/report`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: { cumulativeSinceDate, cumulativeUntilDate },
    });
    return handlingResponse<string>(
      [200],
      "Error fetching results report for the requested test",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_SCHEDULED_TEST_RESULTS_REPORT_FAILED,
    });
  }
};

export const fetchScheduledTestsReviewPeriodTimer = async (): Promise<
  ScheduledTestReviewPeriodTimer[]
> => {
  try {
    const response = await axios.get("/api/tests/review/timer", {
      headers: { Authorization: cookieStorage.getToken() },
    });

    return handlingResponse<ScheduledTestReviewPeriodTimer[]>(
      [200],
      "Error fetching the time left for review of the scheduled tests",
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_SCHEDULED_TESTS_REVIEW_PERIOD_TIMERS,
    });
  }
};

export const fetchScheduledTestStatus = async (
  code: string,
): Promise<ScheduledTestStatusAndTimeLeft> => {
  try {
    const response = await axios.get(`/api/test/${code}/status`, {
      headers: { Authorization: cookieStorage.getToken() },
    });

    return handlingResponse<ScheduledTestStatusAndTimeLeft>(
      [200],
      "Error fetching the status and time left of the scheduled test",
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_SCHEDULED_TEST_STATUS_AND_TIME_FAILED,
    });
  }
};

export const fetchLastCompletedTests = async (): Promise<LastCompletedTest[]> => {
  try {
    const response = await axios.get("/api/test/scheduled-test/latest", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<LastCompletedTest[]>(
      [200],
      "Error fetching last completed tests",
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_LAST_COMPLETED_TESTS_FAILED,
    });
  }
};

export const fetchDiagnosticTests = async ({
  educationId,
  schoolYearStart,
  schoolYearEnd,
  page,
  resultsPerPage,
  searchTerm,
}: OverviewFilters): Promise<ResultsAPI.DiagnosticTestResultsDetails> => {
  try {
    const response = await axios.get("/api/diagnostic-test/progress-overview", {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        educationId,
        schoolYearStart,
        schoolYearEnd,
        page,
        resultsPerPage,
        searchTerm,
      },
    });
    return handlingResponse<ResultsAPI.DiagnosticTestResultsDetails>(
      [200],
      "Error fetching diagnostic tests",
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_DIAGNOSTIC_TESTS_FAILED,
    });
  }
};

export const allowStudentsToReEnter = async (
  scheduledTestId: number,
  accountIds: number[],
): Promise<boolean> => {
  try {
    const result = await axios.post(
      `/api/scheduled-test/${scheduledTestId}/allow-to-reenter`,
      { accountIds },
      { headers: { Authorization: cookieStorage.getToken() } },
    );

    return handlingResponse<boolean>([200], "Error allowing students to re enter the test")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.ALLOW_STUDENT_TO_REENTER_FAILED });
  }
};

// exercise status to validate if the user has answer or not
export const fetchExerciseStatus = async (
  exerciseId: number,
  testToken?: string,
): Promise<ExerciseStatusResponse> => {
  try {
    const response = await axios.get(`/api/exercises/${exerciseId}/status`, {
      headers: testToken
        ? {
            test_token: testToken,
            Authorization: cookieStorage.getToken(),
          }
        : {
            Authorization: cookieStorage.getToken(),
          },
    });
    return handlingResponse<ExerciseStatusResponse>(
      [200, 204],
      "Error checking exercise status",
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHEDULED_TEST_STATUS });
  }
};

// Fetch answers for an exercise
export const fetchExerciseAnswers = async (
  exerciseId: number,
  testToken = "",
): Promise<ExerciseAnswer[]> => {
  try {
    const result = await axios.get(`/api/exercises/${exerciseId}/answers`, {
      headers: {
        test_token: testToken,
        Authorization: cookieStorage.getToken(),
      },
    });
    return handlingResponse<ExerciseAnswer[]>(
      [200],
      "Error retrieving exercise answers",
      "exerciseExamAnswersError",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_EXERCISE_ANSWERS_FAILED });
  }
};

export const fetchScheduledTestsResultsForUser = async (
  userId: number,
): Promise<ScheduledTestsResultsUser> => {
  try {
    const result = await axios.get(`/api/tests/owned/scores/${userId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ScheduledTestsResultsUser>(
      [200],
      "Error retrieving scheduled tests results for user",
      "scheduledTestsResultsUser",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHEDULED_TESTS_RESULTS_USER });
  }
};
