import type React from "react";
import { useTranslation } from "react-i18next";

import { StatusTag } from "components/StatusTag/StatusTag";
import type { StatusTagVariant } from "components/StatusTag/StatusTag.styled";
import { ExamStatus } from "../../constants/exam-constants";
import type { ExamStatusTagProps } from "./ExamStatusTag.model";

export const ExamStatusTag: React.FC<ExamStatusTagProps> = ({
  dataCy,
  className,
  status = "default",
}) => {
  const { t } = useTranslation("diagnostic-test");

  const statusLabel: Record<ExamStatus, string> = {
    completed: t("status.completed", "Completed"),
    inProgress: t("status.inProgress", "Work In Progress"),
    notStarted: t("status.notStarted", "Not Started"),
    default: t("status.unknown", "Unknown status"),
  };

  const statusVariant: Record<ExamStatus, StatusTagVariant> = {
    completed: "success",
    inProgress: "info",
    notStarted: "default",
    default: "default",
  };

  // Don't show anything if status is unknown or not started, as it's not very relevant
  if (status === ExamStatus.DEFAULT || status === ExamStatus.NOT_STARTED) {
    return null;
  }

  return (
    <StatusTag
      className={className}
      dataCy={dataCy}
      label={statusLabel[status]}
      variant={statusVariant[status]}
    />
  );
};

export default ExamStatusTag;
