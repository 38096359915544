export enum QuestionStatus {
  INITIAL = "initial",
  CORRECT = "correct",
  INCORRECT = "incorrect",
  MISSED = "missed",
  SUBMITTED = "submitted",
  REVIEWING_ANSWERS = "reviewing-answers",
}

export enum QuestionType {
  SINGLE_INPUT = "single-input",
  SINGLE_SELECT = "multiple-choice",
  TEXT_AREA = "text",
  DRAG = "drag",
  MULTIPLE_TEXT_INPUT = "multi-input",
  SELECT_TEXT = "select-text",
  CORRECT_TEXT = "correct-text",
  CORRECT_SENTENCE = "correct-sentence",
  PROGRESSIVE_TEXT = "progressive-text",
  PROGRESSIVE_DOCUMENT = "progressive-document",
  PROGRESSIVE_OVERVIEW = "progressive-overview",

  // TODO: currently unsupported types, names may change during implementation
  REORDER = "word-order",
  SUMMARIZE_TEXT = "summarize-text",
  IMPROVE_TEXT = "improve-text",
  TEXT_WITH_TEXTAREA = "text-with-textarea",
  TEXT_INPUT_IN_SENTENCE = "text-input-in-sentence",
}

const NotEvaluableQuestionTypes = [QuestionType.TEXT_AREA, QuestionType.PROGRESSIVE_TEXT];

export const isNotEvaluableQuestionType = (questionType: QuestionType): boolean =>
  NotEvaluableQuestionTypes.includes(questionType);

const EvaluableOpenQuestionTypes = [
  QuestionType.SINGLE_INPUT,
  QuestionType.MULTIPLE_TEXT_INPUT,
  QuestionType.CORRECT_SENTENCE,
];

export const isEvaluableOpenQuestionType = (questionType: QuestionType): boolean =>
  EvaluableOpenQuestionTypes.includes(questionType);

export enum TestType {
  UNDEFINED = "undefined",
  SKILL_DETERMINATION_TEST = "skill-determination-test",
  ADAPTIVE_PRACTICE_TEST = "adaptive-test",
  DIAGNOSTIC_TEST = "diagnostic-test",
  WRITING_EXERCISE = "writing-exercise",
  MIXED_EXERCISE_TEST = "mixed-exercise",
  SUMMATIVE_TEST = "summative-test",
  FORMATIVE_TEST = "formative-test",
  FREEBIE_TEST = "freebie-test",
  INTAKE_TEST = "intake-test", // Same as freebie test, but this naming is only used in URLs instead of "freebie-test"
}

export enum ExerciseType {
  COMPOSE_EMAIL = "compose-email",
}

export type ExamType = "formative-test" | "summative-test" | "intake-test" | "freebie-test";

export enum QuestionFlag {
  CORRECT_TEXT_COMPARE_MODE = 0,
}

export enum ExamStatus {
  COMPLETED = "completed",
  IN_PROGRESS = "inProgress",
  NOT_STARTED = "notStarted",
  DEFAULT = "default",
}

export enum ExamRule {
  FULL_SCREEN = "full-screen",
  MOUSE_INSIDE = "mouse-inside",
}

export enum TestEventTypes {
  PROCTORING = "proctoring",
  STATUS = "status",
}

export enum ScheduledTestTimeLeftStatus {
  NOT_STARTED = "not-started",
  ONGOING = "ongoing",
  ON_REVIEW = "on-review",
  FINISHED = "finished",
}

export enum AdaptiveTestWorkflowType {
  SEQUENTIAL = "sequential",
  MIXED = "mixed",
}
