import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { CardWithTitleBar } from "components/CardWithTitleBar";
import { SanitizedHtml } from "components/SanitizedHtml";

import { ParagraphLarge } from "styles/elements/Texts";
import { CssFlex } from "styles/helpers/layout";

export const StyledModuleCard = styled(CardWithTitleBar)<ThemeProps>`
  width: 21rem;
  min-height: 21rem;
`;

export const StyledSubtitle = styled.span<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
`;

export const StyledLastPracticedDate = styled(CssFlex)`
  font-size: ${(props: ThemeProps) => props.theme.font.size._12};
`;

export const StyledLastPracticedTopicTitle = styled(SanitizedHtml)`
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold};
`;

export const StyledProgressBarLabel = styled(ParagraphLarge)`
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
`;
