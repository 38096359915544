import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";

import { StyledIcon, StyledInput } from "../../MigrateAccessPage.styled";

export const StyledPasswordInput = styled(StyledInput)`
  padding: 1rem 3rem;
`;

export const StyledPasswordIcon = styled(StyledIcon)<Props>`
  left: unset;
  right: 1rem;
  color: ${(props: Props) => props.theme.palette.neutral._900};
`;
