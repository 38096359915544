import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type { ModuleDetails, ModuleSubject } from "models/product/ModuleDetails";
import type { ProductDetails } from "models/product/ProductDetails";
import type { ProductItem } from "models/product/ProductItem";
import type { ProductTestGroup, ProductTestGroupType } from "models/product/ProductTestGroups";
import type { SchoolYear } from "models/product/SchoolYear";
import type {
  InvitationDetails,
  InvitationList,
  InvitationListFilter,
  StudentInvitation,
  StudentInvitationResponse,
} from "models/product/StudentInvitation";
import type { TopicDetails } from "models/product/TopicDetails";
import cookieStorage from "persistence";
import { handlingResponse, logError } from "repositories/utils";

// Retrieve list of products for the current user
const getProductList = async (): Promise<ProductItem[]> => {
  try {
    const result = await axios.get("/api/products", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ProductItem[]>([200], "Error retrieving products")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_PRODUCT_LIST_FAILED });
  }
};

// Retrieve detailed information on a product
const getProductDetails = async (id: number): Promise<ProductDetails> => {
  try {
    const result = await axios.get(`/api/products/${id}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ProductDetails>([200], "Error retrieving product detail")(result);
  } catch (error) {
    const { message, status, errCode } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      errCode,
      type: ERROR_TYPES.GET_PRODUCT_DETAILS_FAILED,
    });
  }
};
// Retrieve detailed information on a product subject
const getProductSubjectDetails = async (
  productId: number,
  subjectId: number,
): Promise<ModuleSubject> => {
  try {
    const subjectDetails = await axios.get(`/api/products/${productId}/subjects/${subjectId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ModuleSubject>(
      [200],
      "Error retrieving product subject details",
    )(subjectDetails);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SUBJECT_DETAILS_FAILED });
  }
};

// Retrieve detailed information on a module
const getModuleDetails = async (productId: number, moduleId: number): Promise<ModuleDetails> => {
  try {
    const moduleDetails = await axios.get(`/api/products/${productId}/modules/${moduleId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ModuleDetails>([200], "Error retrieving module details")(moduleDetails);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_MODULE_DETAILS_FAILED });
  }
};

// Retrieve detailed information on a topic
const getTopicDetails = async (productId: number, topicId: number): Promise<TopicDetails> => {
  try {
    const topicDetails = await axios.get(`/api/products/${productId}/topics/${topicId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<TopicDetails>([200], "Error retrieving topic details")(topicDetails);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_TOPIC_DETAILS_FAILED });
  }
};

// Retrieve list of tests for a product grouped by skill level
const getProductTestsBySkillLevel = async (
  productId: number,
  testType: ProductTestGroupType,
): Promise<ProductTestGroup[]> => {
  try {
    const testGroups = await axios.get(`/api/products/${productId}/${testType}/levels`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ProductTestGroup[]>(
      [200],
      `Error retrieving product test groups for test type ${testType}`,
    )(testGroups);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_TEST_GROUPS });
  }
};

// Fetch list of school years available for the product
const getProductSchoolYears = async (productId: number): Promise<SchoolYear[]> => {
  try {
    const response = await axios.get(`/api/products/${productId}/school-years`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<SchoolYear[]>([200], "Error fetching product school years")(response);
  } catch (error) {
    const { message, status, errCode } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      errCode,
      type: ERROR_TYPES.GET_PRODUCT_SCHOOL_YEARS_FAILED,
    });
  }
};

const postInviteStudents = async (
  payload: StudentInvitation,
): Promise<StudentInvitationResponse> => {
  try {
    const result = await axios.post("/api/products/invite", payload, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<StudentInvitationResponse>(
      [200],
      "Success status requesting access",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>, false);
    throw new RequestError({ message, status, type: ERROR_TYPES.POST_INVITE_STUDENTS_FAILED });
  }
};

const postAcceptProductInvitation = async (invitationId: string): Promise<void> => {
  try {
    await axios.post(
      `/api/invitation/${invitationId}/accept`,
      {},
      { headers: { Authorization: cookieStorage.getToken() } },
    );
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>, false);
    throw new RequestError({ message, status, type: ERROR_TYPES.ACCEPT_PRODUCT_INVITATION_FAILED });
  }
};

const getInvitationDetails = async (invitationId: string): Promise<InvitationDetails> => {
  try {
    const response = await axios.get(`/api/invite/${invitationId}/`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<InvitationDetails>([200], "Error fetching invitation data")(response);
  } catch (error) {
    const { message, status, errCode } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      errCode,
      type: ERROR_TYPES.GET_PRODUCT_SCHOOL_YEARS_FAILED,
    });
  }
};

const fetchInvitationList = async (filter: InvitationListFilter): Promise<InvitationList> => {
  try {
    const response = await axios.get("/api/invitation-list", {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        page: filter.page,
        resultsPerPage: filter.resultsPerPage,
        accepted: filter.accepted,
        email: filter.email || null,
      },
    });
    return handlingResponse<InvitationList>([200], "Error fetching invitation data")(response);
  } catch (error) {
    const { message, status, errCode } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      errCode,
      type: ERROR_TYPES.GET_PRODUCT_SCHOOL_YEARS_FAILED,
    });
  }
};

const deleteInviteById = async (invitationId: string): Promise<void> => {
  try {
    const response = await axios.delete(`/api/invite/${invitationId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<void>([200], `Error deleting invite ${invitationId}`)(response);
  } catch (error) {
    const { message, status, errCode } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      errCode,
      type: ERROR_TYPES.DELETE_INVITE_FAILED,
    });
  }
};

export {
  getProductList,
  getTopicDetails,
  getModuleDetails,
  getProductDetails,
  getProductSubjectDetails,
  getProductTestsBySkillLevel,
  getProductSchoolYears,
  getInvitationDetails,
  postInviteStudents,
  postAcceptProductInvitation,
  fetchInvitationList,
  deleteInviteById,
};
