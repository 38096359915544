import { featureFlagDefaultValues } from "constants/configcat-constants";
import type { FeatureFlagsSet } from "models/featureFlag/FeatureFlagsSet";
import React, { useCallback, useEffect, useState } from "react";
import { createConfigCatRepository } from "repositories/ConfigCatRepository/ConfigCatRepository";

interface FeatureFlagsContextValues extends FeatureFlagsSet {
  isLoading: boolean;
  refreshFeatureFlagValues: () => void;
}

export const FeatureFlagsContext: React.Context<FeatureFlagsContextValues> =
  React.createContext<FeatureFlagsContextValues>({
    ...featureFlagDefaultValues,
    isLoading: false,
    refreshFeatureFlagValues: () => 0,
  });

export const FeatureFlagsProvider: React.FC = ({ children }) => {
  const { fetchAllFeatureFlags } = createConfigCatRepository();
  const [isLoading, setIsLoading] = useState(false);
  const [featureFlagsValues, setFeatureFlagValues] =
    useState<FeatureFlagsSet>(featureFlagDefaultValues);

  // Method to force feature flags to be refreshed
  const refreshFeatureFlagValues = useCallback(() => {
    setIsLoading(true);
    fetchAllFeatureFlags().then((currentFlagValues) => {
      setIsLoading(false);
      setFeatureFlagValues(currentFlagValues);
    });
  }, []);

  useEffect(() => {
    refreshFeatureFlagValues();
  }, []);

  return (
    // @ts-ignore
    <FeatureFlagsContext.Provider
      value={{
        ...featureFlagsValues,
        isLoading,
        refreshFeatureFlagValues,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};
