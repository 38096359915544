import type * as Yup from "yup";
import type { FormValues, ValidationError } from "./steps/FormStep.model";

// There is no generic validator type in the Yup library, so we just
// create our own so we can pass any Yup schema to the validateObject function
export interface GenericValidator {
  validate: (value: unknown, options: { [key: string]: boolean }) => Promise<unknown>;
}

export const validateObject = async <T extends GenericValidator>(
  schema: T,
  values: FormValues,
): Promise<ValidationError[]> => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    return (error as Yup.ValidationError).inner.map(({ path, type, errors }) => ({
      path,
      type,
      errors,
    }));
  }
  return [];
};
