import i18n from "i18n";
import type { GenericValidator } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/validate";
import * as Yup from "yup";

export const createValidationSchema = (): GenericValidator =>
  Yup.object({
    proctoringEnabled: Yup.boolean().defined(),
    proctoring: Yup.array().of(Yup.string()).defined(),
    cumulative: Yup.boolean().defined(),
    useCustomPassword: Yup.boolean().defined(),
    password: Yup.string()
      .label(i18n.t("test-request-form:formFields.password"))
      .when("useCustomPassword", {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty: <explanation>
        then: Yup.string()
          .min(6, ({ min, label }) =>
            i18n.t("test-request-form:formErrors.minLength", { min, label }),
          )
          .max(30, ({ max, label }) =>
            i18n.t("test-request-form:formErrors.maxLength", { max, label }),
          )
          .defined(),
      }),
    useCustomPassingScore: Yup.boolean().defined(),
    passingScore: Yup.mixed()
      .label(i18n.t("test-request-form:formFields.passingScore"))
      .when("useCustomPassingScore", {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty: <explanation>
        then: Yup.number()
          .typeError(i18n.t("test-request-form:formErrors.NaN"))
          .min(1, ({ min, label }) => i18n.t("test-request-form:formErrors.min", { min, label }))
          .max(100, ({ max, label }) => i18n.t("test-request-form:formErrors.max", { max, label }))
          .defined(),
      }),
    editableAnswers: Yup.boolean().defined(),
    schoolyearEnabled: Yup.boolean().defined(),
  });
