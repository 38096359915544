import type React from "react";
import type { ReactNode } from "react";

import { CssClickableIcon } from "styles/helpers/layout";

import { useReactTooltip } from "hooks/useReactTooltip";
import { ParagraphMedium } from "styles/elements/Texts";
import { StyledBox } from "./ProgressStatBox.styled";

interface ProgressStatBoxProps {
  dataCy?: string;
  title: string;
  tooltip?: string;
  value: ReactNode; // Value is expected to be a node of these types: CssFlex, Heading3
}

export const ProgressStatBox: React.FC<ProgressStatBoxProps> = ({
  dataCy,
  title,
  tooltip,
  value,
}) => {
  useReactTooltip(!!tooltip);

  return (
    <StyledBox data-cy={dataCy} gap={1} gridTemplateRows="max-content 1fr">
      <ParagraphMedium>
        {title}
        {tooltip && (
          <CssClickableIcon
            data-cy="info-icon"
            data-tip={tooltip}
            iconName="far fa-info-circle"
            iconSize="lg"
            marginLeft={1}
          />
        )}
      </ParagraphMedium>
      {value}
    </StyledBox>
  );
};
