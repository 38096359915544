import { createGlobalStyle } from "styled-components/macro";
import { normalize } from "styled-normalize";
import { GlobalStylesCss } from "./Global.styled";
import { GlobalToastCss } from "./GlobalToast.styled";

import "fonts/fonts.css";

export const GlobalAppStyles = createGlobalStyle`
  ${normalize}
  ${GlobalStylesCss}
  ${GlobalToastCss}
`;
