import { useReactTooltip } from "hooks/useReactTooltip";
import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";

import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import {
  StyledContainer,
  StyledFailureBar,
  StyledOuterBar,
  StyledStats,
  StyledSuccessBar,
} from "./PracticeStatusBar.styled";

export const PracticeStatusBar: React.FC = observer(() => {
  useReactTooltip();
  const { t } = useTranslation("adaptive-test");

  const { currentMixedExerciseStats } = useAdaptivePractice();

  const { answeredQuestions, failedQuestions, correctQuestions } = currentMixedExerciseStats;

  const failureRate = Math.round((failedQuestions / answeredQuestions) * 100) || 0;
  const successRate = Math.round((correctQuestions / answeredQuestions) * 100) || 0;

  return (
    <StyledContainer data-cy="mixed-exercise-progress">
      <StyledOuterBar>
        <StyledFailureBar
          $width={failureRate}
          data-tip={t("header.tooltip.incorrectAnswers", { incorrectAnswers: failedQuestions })}
        />
        <StyledSuccessBar
          $width={failureRate + successRate}
          data-tip={t("header.tooltip.correctAnswers", { correctAnswers: correctQuestions })}
        />
      </StyledOuterBar>
      <StyledStats>
        <span data-cy="correctly-answered-questions">{correctQuestions}</span>/
        <span data-cy="total-answered-questions">{answeredQuestions}</span>
      </StyledStats>
    </StyledContainer>
  );
});
