import styled from "styled-components/macro";

import { Link } from "components/Link";
import type { ThemeProps } from "models/styles/theme";
import { Heading2, Heading3 } from "styles/elements/Headings";

export const StyledEditStudentLink = styled(Link)`
  cursor: pointer;
  color: ${(props: ThemeProps) => props.theme.palette.primary._400};
`;

export const StyledUserInfoTextLabel = styled.span`
  font-weight: 400;
`;

export const StyledPageHeader = styled(Heading2)`
  margin-bottom: 2rem;
`;

export const StyledPageSubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledPageSubHeader = styled(Heading3)`
  text-align: left;
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

export const StyledUserInfoWrapper = styled.div`
  border-bottom: 1px solid #ececec;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
`;
