import type { AxiosError } from "axios";
import i18n from "i18n";
import { action, observable } from "mobx";

import { ERROR_TYPES } from "constants/error-constants";
import type {
  LastCompletedTest,
  OwnedScheduledTestResults,
  OwnedScheduledTestResultsOverviewItem,
  ScheduledTestCreatePayload,
  ScheduledTestDetails,
  ScheduledTestExercise,
  ScheduledTestExercisePayload,
  ScheduledTestResultList,
  ScheduledTestResultOverview,
  ScheduledTestResultsReportSettings,
  ScheduledTestReviewPeriodTimer,
  ScheduledTestScoreDetail,
  ScheduledTestStatus,
  ScheduledTestStatusAndTimeLeft,
  ScheduledTestSummary,
  UpdateScheduledTestDurationPayload,
} from "models/exam/ScheduledTest";
import type {
  AssignScheduledTestCertificatesResponse,
  RequestScheduledTestsCertificatesResults,
  ResponseScheduledTestsCertificatesResults,
  ScheduledTestExercisesStatus,
} from "models/results/Results";

//TODO: import { useDomainHandler } from 'hooks/useDomainHandler' and convert this in a hook with shared context among other hooks of this kind;
import { getStudentTermByDomain } from "_helpers/domainHandler";
import { ToastMethods } from "components/ToastNotification";
import type {
  ExamQuestion,
  ExerciseAnswer,
  ExerciseResult,
  ExerciseStatusResponse,
  ScheduledTestsResultsUser,
  SelectedQuestion,
} from "models/exam/Exam";
import { resultsRepository, scheduledTestRepository } from "repositories";

import { downloadExcelFile } from "_helpers/downloadHelper";
import { QuestionStatus } from "constants/exam-constants";
import type { ContentAPI, ResultsAPI } from "generated/types";
import type { ScheduledTestLog, ScheduledTestStudentLog } from "models/log/Log";
import type { ProductTestGroupType } from "models/product/ProductTestGroups";
import type { OverviewFilters, SubjectsProgressOverviewFilters } from "models/progress/Progress";
import type { OwnedScheduledTestResultsOverviewFilters } from "pages/TeacherEnvironment/TeacherDashboard/subpages/OwnedScheduledTestResultsOverview/OwnedScheduledTestResultsOverview.model";

interface ScheduledTestExerciseResponseStatus {
  success: boolean;
  index?: number;
  exerciseTitle?: string;
}

interface ScheduledTestLiveDataResponse {
  scheduledTestId: number;
  logs: ScheduledTestLog[];
}

interface SchoolyearAccessRequiredDetails {
  schoolyearOnboardingUrl: string;
}

interface ScheduledTestAccessErrorResponse {
  status: number;
  message: string;
  type: string;
  extra?: SchoolyearAccessRequiredDetails;
}

interface ScheduledTestAccessStatus {
  accessGranted: boolean;
  requestPassword?: boolean;
  isTestFinished?: boolean;
  redirectUrl?: string;
}

interface ScheduledTestsStore {
  loading: boolean;
  scheduledTest?: ScheduledTestDetails | null;
  scheduledTestLiveData?: ScheduledTestLiveDataResponse | null;
  scheduledTestStudentLogs?: ScheduledTestStudentLog[] | null;
  scheduledTestStatus?: ScheduledTestExercisesStatus[] | null;
  scheduledTests?: ScheduledTestSummary[] | null;
  scheduledTestContent?: ContentAPI.ScheduledTestExercisesDetails | null;
  scheduledTestExercise?: ScheduledTestExercise | null;
  scheduledTestExerciseStatus?: ExerciseStatusResponse | null;
  scheduledTestExerciseAnswers?: ExerciseAnswer[] | null;
  scheduledTestResults: ExerciseResult[] | null;
  scheduledTestsResultsStudent: ScheduledTestsResultsUser | null;
  selectedQuestion: SelectedQuestion;
  visitedExam: boolean;
  studentScheduledTestResultsOverview?: ScheduledTestResultOverview[] | null;
  scheduledTestScore: ScheduledTestScoreDetail | null;
  studentScheduledTestResultsList: ScheduledTestResultList[] | null;
  ownedScheduledTestResultsOverview?: OwnedScheduledTestResultsOverviewItem[] | null;
  ownedScheduledTestResults: OwnedScheduledTestResults | null;
  scheduledTestsReviewPeriodTimer?: ScheduledTestReviewPeriodTimer[] | null;
  scheduledTestStatusAndTimeLeft?: ScheduledTestStatusAndTimeLeft | null;
  scheduledTestsCertificates: ResponseScheduledTestsCertificatesResults;
  invalidatedScheduledTest: boolean | null;
  lastCompletedTests?: LastCompletedTest[] | null;
  diagnosticTestDetails?: ResultsAPI.DiagnosticTestResultsDetails | null;
  shouldClickNextQuestion: boolean;
  authTestCode: string | null;

  // Actions for API requests
  fetchScheduledTest: (scheduledTestId: number) => Promise<boolean>;
  fetchScheduledTestByCode: (testCode: string) => Promise<boolean>;
  fetchScheduledTests: (status?: ScheduledTestStatus) => void;
  createScheduledTest: (payload: ScheduledTestCreatePayload) => Promise<boolean>;
  updateScheduledTest: (
    scheduledTestId: number,
    payload: ContentAPI.UpdateScheduledTest | UpdateScheduledTestDurationPayload,
  ) => Promise<boolean>;
  fetchScheduledTestContent: (
    code: string,
    password?: string,
  ) => Promise<ScheduledTestAccessStatus>;
  fetchScheduledTestStatus: (code: string) => void;
  fetchScheduledTestExerciseAnswers: (exerciseId: number, testToken: string) => Promise<boolean>;
  fetchScheduledTestExercise: (
    payload: ScheduledTestExercisePayload,
  ) => Promise<ScheduledTestExerciseResponseStatus>;
  fetchScheduledTestExerciseResults: (testCode: string, exerciseId: number) => Promise<boolean>;
  finishScheduledTest: (testCode: string, testToken: string) => void;
  fetchScheduledTestLiveData: (scheduledTestId: number) => Promise<boolean>;
  fetchScheduledTestStudentLogs: (scheduledTestId: number, studentId: number) => Promise<boolean>;
  fetchStudentScheduledTestResultsOverview: (
    productId: number,
    testType: ProductTestGroupType,
  ) => Promise<boolean>;
  fetchScheduledTestScore: (testCode: string) => Promise<boolean>;
  fetchStudentScheduledTestResultsList: (testType: ProductTestGroupType) => Promise<boolean>;
  fetchScheduledTestResultsOverview: (
    filters?: OwnedScheduledTestResultsOverviewFilters,
  ) => Promise<boolean>;
  fetchOwnedScheduledTestResults: (scheduledTestId: number) => Promise<boolean>;
  downloadScheduledTestResultsReport: (
    scheduledTestId: number,
    reportSettings?: ScheduledTestResultsReportSettings,
  ) => Promise<boolean>;
  fetchScheduledTestsReviewPeriodTimer: () => Promise<ScheduledTestReviewPeriodTimer[]>;
  fetchScheduledTestStatusAndTimeLeft: (code: string) => Promise<ScheduledTestStatusAndTimeLeft>;
  fetchScheduledTestsCertificates: (payload: RequestScheduledTestsCertificatesResults) => void;
  fetchScheduledTestsResultsStudent: (userId: number) => void;
  invalidateScheduledTestOfStudent: (scheduledTestId: number, studentId: number) => void;
  fetchLastCompletedTests: () => void;
  fetchDiagnosticTestDetails: (filters: SubjectsProgressOverviewFilters) => void;
  allowStudentsToReEnter: (scheduledTestId: number, accountIds: number[]) => void;
  updateStudentScheduledTestsResultsCertificate: (
    scheduledTestId: number,
  ) => Promise<AssignScheduledTestCertificatesResponse>;

  // State setters
  setLoading: (isLoading: boolean) => void;
  setScheduledTests: (scheduledTests: ScheduledTestSummary[] | null) => void;
  setScheduledTest: (scheduledTest: ScheduledTestDetails | null) => void;
  setScheduledTestContent: (content: ContentAPI.ScheduledTestExercisesDetails | null) => void;
  setScheduledTestStatus: (scheduledTestStatus: ScheduledTestExercisesStatus[] | null) => void;
  setScheduledTestExercise: (exercise: ScheduledTestExercise | null) => void;
  setScheduledTestExerciseStatus: (status: ExerciseStatusResponse | null) => void;
  setScheduledTestExerciseAnswers: (answers: ExerciseAnswer[] | null) => void;
  setScheduledTestResults: (results: ExerciseResult[] | null) => void;
  setScheduledTestLiveData: (data: ScheduledTestLiveDataResponse | null) => void;
  setScheduledTestStudentLogs: (logs: ScheduledTestStudentLog[] | null) => void;
  setSelectedQuestion: (question: SelectedQuestion) => void;
  setVisitedExam: (visitedExamValue: boolean) => void;
  setStudentScheduledTestResultsOverview: (results: ScheduledTestResultOverview[] | null) => void;
  setScheduledTestScore: (results: ScheduledTestScoreDetail | null) => void;
  setStudentScheduledTestResultsList: (results: ScheduledTestResultList[] | null) => void;
  setOwnedScheduledTestResultsOverview: (
    results: OwnedScheduledTestResultsOverviewItem[] | null,
  ) => void;
  setOwnedScheduledTestResults: (results: OwnedScheduledTestResults | null) => void;
  setScheduledTestsReviewPeriodTimer: (timers: ScheduledTestReviewPeriodTimer[]) => void;
  setScheduledTestStatusAndTimeLeft: (status: ScheduledTestStatusAndTimeLeft | null) => void;
  setScheduledTestsCertificates: (certificates: ResponseScheduledTestsCertificatesResults) => void;
  setInvalidatedScheduledTest: (invalidated: boolean) => boolean;
  setLastCompletedTests: (lastCompletedTests: LastCompletedTest[] | null) => void;
  setDiagnosticTestDetails: (
    diagnosticTest: ResultsAPI.DiagnosticTestResultsDetails | null,
  ) => void;
  setShouldClickNextQuestion: (val: boolean) => void;
  setScheduledTestsResultsStudent: (val: ScheduledTestsResultsUser) => void;
  setAuthTestCode: (val: string) => void;
}

const initialState = {
  loading: false,
  selectedQuestion: {
    results: [],
    index: 0,
    status: QuestionStatus.INITIAL,
  } as SelectedQuestion,
  visitedExam: false,
  shouldClickNextQuestion: false,
  scheduledTestScore: null,
  scheduledTestsCertificates: {} as ResponseScheduledTestsCertificatesResults,
  invalidatedScheduledTest: null,
  scheduledTestsResultsStudent: null,
  authTestCode: null,
};

const stateSetters = {
  setLoading: action((isLoading: boolean) => {
    store.loading = isLoading;
  }),
  setScheduledTests: action((scheduledTests: ScheduledTestSummary[] | null) => {
    store.scheduledTests = scheduledTests;
  }),
  setScheduledTest: action((scheduledTest: ScheduledTestDetails | null) => {
    store.scheduledTest = scheduledTest;
  }),
  setScheduledTestContent: action((content: ContentAPI.ScheduledTestExercisesDetails | null) => {
    store.scheduledTestContent = content;
  }),
  setScheduledTestStatus: action((scheduledTestStatus: ScheduledTestExercisesStatus[] | null) => {
    store.scheduledTestStatus = scheduledTestStatus;
  }),
  setScheduledTestExercise: action((exercise: ScheduledTestExercise | null) => {
    store.scheduledTestExercise = exercise;
  }),
  setScheduledTestExerciseStatus: action((status: ExerciseStatusResponse | null) => {
    store.scheduledTestExerciseStatus = status;
  }),
  setScheduledTestExerciseAnswers: action((answers: ExerciseAnswer[] | null) => {
    store.scheduledTestExerciseAnswers = answers;
  }),
  setScheduledTestResults: action((results: ExerciseResult[] | null) => {
    store.scheduledTestResults = results;
  }),
  setSelectedQuestion: action((question: SelectedQuestion) => {
    store.selectedQuestion = question;
  }),
  setVisitedExam: action((visitedExamValue: boolean) => {
    store.visitedExam = visitedExamValue;
  }),
  setScheduledTestLiveData: action((data: ScheduledTestLiveDataResponse | null) => {
    store.scheduledTestLiveData = data;
  }),
  setScheduledTestStudentLogs: action((logs: ScheduledTestStudentLog[] | null) => {
    store.scheduledTestStudentLogs = logs;
  }),
  setStudentScheduledTestResultsOverview: action(
    (results: ScheduledTestResultOverview[] | null) => {
      store.studentScheduledTestResultsOverview = results;
    },
  ),
  setScheduledTestScore: action((results: ScheduledTestScoreDetail | null) => {
    store.scheduledTestScore = results;
  }),
  setStudentScheduledTestResultsList: action((results: ScheduledTestResultList[] | null) => {
    store.studentScheduledTestResultsList = results;
  }),
  setOwnedScheduledTestResultsOverview: action(
    (results: OwnedScheduledTestResultsOverviewItem[] | null) => {
      store.ownedScheduledTestResultsOverview = results;
    },
  ),
  setOwnedScheduledTestResults: action((results: OwnedScheduledTestResults | null) => {
    store.ownedScheduledTestResults = results;
  }),
  setScheduledTestsReviewPeriodTimer: action((timers: ScheduledTestReviewPeriodTimer[] | null) => {
    store.scheduledTestsReviewPeriodTimer = timers;
  }),
  setScheduledTestStatusAndTimeLeft: action((status: ScheduledTestStatusAndTimeLeft) => {
    store.scheduledTestStatusAndTimeLeft = status;
  }),
  setScheduledTestsCertificates: action(
    (certificates: ResponseScheduledTestsCertificatesResults) => {
      store.scheduledTestsCertificates = certificates;
    },
  ),
  setInvalidatedScheduledTest: action((invalidated: boolean) => {
    store.invalidatedScheduledTest = invalidated;
  }),
  setLastCompletedTests: action((lastCompletedTests: LastCompletedTest[] | null) => {
    store.lastCompletedTests = lastCompletedTests;
  }),
  setDiagnosticTestDetails: action(
    (diagnosticTestDetails: ResultsAPI.DiagnosticTestResultsDetails | null) => {
      store.diagnosticTestDetails = diagnosticTestDetails;
    },
  ),
  setShouldClickNextQuestion: action((val: boolean) => {
    store.shouldClickNextQuestion = val;
  }),
  setScheduledTestsResultsStudent: action((val: ScheduledTestsResultsUser) => {
    store.scheduledTestsResultsStudent = val;
  }),
  setAuthTestCode: action((val: string) => {
    store.authTestCode = val;
  }),
};

const apiRequests = {
  fetchScheduledTests: action((status?: ScheduledTestStatus) => {
    store.setLoading(true);
    scheduledTestRepository
      .fetchScheduledTests(status)
      .then((scheduledTests: ScheduledTestSummary[]) => {
        store.setScheduledTests(scheduledTests);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:scheduledTest.error.getScheduledTestsList"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchScheduledTest: action(
    (scheduledTestId: number) =>
      new Promise((resolve) => {
        scheduledTestRepository
          .fetchScheduledTestDetails(scheduledTestId)
          .then((scheduledTest: ScheduledTestDetails) => {
            store.setScheduledTest(scheduledTest);
            resolve(true);
          })
          .catch((error: AxiosError<Error>) => {
            if (error.code === "404") {
              ToastMethods.showToast(
                i18n.t("toast:scheduledTest.error.getScheduledTestDetailsNotFound"),
                "error",
              );
            } else if (error.code === "403") {
              ToastMethods.showToast(
                i18n.t("toast:scheduledTest.error.getScheduledTestDetailsForbidden"),
                "error",
              );
            } else {
              ToastMethods.showToast(
                i18n.t("toast:scheduledTest.error.getScheduledTestDetails"),
                "error",
              );
            }
            store.setScheduledTest(null);
            resolve(false);
          })
          .finally(() => {
            store.setLoading(false);
          });
      }),
  ),

  createScheduledTest: action(
    (payload: ScheduledTestCreatePayload) =>
      new Promise((resolve) => {
        store.setLoading(true);
        scheduledTestRepository
          .postScheduledTest(payload)
          .then(() => resolve(true))
          .catch(() => {
            ToastMethods.showToast(i18n.t("toast:scheduledTest.error.postScheduledTest"), "error");
            resolve(false);
          })
          .finally(() => {
            store.setLoading(false);
          });
      }),
  ),

  updateScheduledTest: action(
    (scheduledTestId: number, payload: ContentAPI.UpdateScheduledTest) =>
      new Promise((resolve) => {
        store.setLoading(true);
        scheduledTestRepository
          .updateScheduledTest(scheduledTestId, payload)
          .then(() => {
            ToastMethods.showToast(
              i18n.t("toast:scheduledTest.success.updateScheduledTest"),
              "success",
            );
            resolve(true);
          })
          .catch(() => {
            ToastMethods.showToast(
              i18n.t("toast:scheduledTest.error.updateScheduledTest"),
              "error",
            );
            resolve(false);
          })
          .finally(() => {
            store.setLoading(false);
          });
      }),
  ),

  fetchScheduledTestContent: action(
    (code: string, password?: string): Promise<ScheduledTestAccessStatus> =>
      new Promise((resolve) => {
        store.setLoading(true);
        scheduledTestRepository
          .fetchScheduledTestContent(code, password)
          .then((scheduledTestContent: ContentAPI.ScheduledTestExercisesDetails) => {
            store.setScheduledTestContent(scheduledTestContent);
            const isTestFinished = store.scheduledTestContent?.finished ?? undefined;
            resolve({ accessGranted: true, requestPassword: false, isTestFinished });
          })
          .catch((error: ScheduledTestAccessErrorResponse) => {
            if (error.type === ERROR_TYPES.SCHEDULED_TEST_SCHOOLYEAR_ACCESS_REQUIRED) {
              ToastMethods.showToast(
                i18n.t("toast:scheduledTest.error.getScheduledTestContentSchoolyearAccessRequired"),
                "error",
              );

              // If Schoolyear is required, indicate user must be redirected to SY onboarding page
              resolve({
                accessGranted: false,
                redirectUrl: error.extra?.schoolyearOnboardingUrl,
              });
            } else if (error.type === ERROR_TYPES.SCHEDULED_TEST_PASSWORD_REQUIRED) {
              if (password) {
                // wrong password was entered
                ToastMethods.showToast(
                  i18n.t("toast:scheduledTest.error.getScheduledTestContentWrongPassword"),
                  "error",
                );
              }
              resolve({ accessGranted: false, requestPassword: true });
            } else if (error.status === 404) {
              ToastMethods.showToast(
                i18n.t("toast:scheduledTest.error.getScheduledTestContentNotFound"),
                "error",
              );
              resolve({ accessGranted: false, requestPassword: false });
            } else {
              ToastMethods.showToast(
                i18n.t("toast:scheduledTest.error.getScheduledTestContent"),
                "error",
              );
              resolve({ accessGranted: false, requestPassword: false });
            }
          })
          .finally(() => {
            store.setLoading(false);
          });
      }),
  ),

  fetchScheduledTestStatus: action((code: string) => {
    store.setLoading(true);
    resultsRepository
      .fetchScheduledTestStatus(code)
      .then((scheduledTestStatus: ScheduledTestExercisesStatus[]) => {
        store.setScheduledTestStatus(scheduledTestStatus);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:scheduledTest.error.getScheduledTestStatus"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  // Fetch contents of an exercise within the current test
  fetchScheduledTestExercise: action(
    ({
      testCode,
      exerciseId,
      testToken,
      randomizeQuestions,
    }: ScheduledTestExercisePayload): Promise<ScheduledTestExerciseResponseStatus> => {
      store.setLoading(true);
      return Promise.all([
        scheduledTestRepository.fetchScheduledTestExercise(
          testCode,
          exerciseId,
          randomizeQuestions,
        ),
        scheduledTestRepository.fetchExerciseStatus(exerciseId, testToken),
      ])
        .then(
          action(([exercise, currentStatus]) => {
            store.setScheduledTestExerciseStatus(currentStatus);

            if (Array.isArray(currentStatus?.answeredQuestions)) {
              const exerciseQuestions = exercise.questions;
              const answeredQuestionsIds = currentStatus?.answeredQuestions || 0;

              // If exercise is in progress, put the answered questions at the beginning of the array
              if (exerciseQuestions.length !== answeredQuestionsIds.length) {
                const answeredQuestions: ExamQuestion[] = [];
                const unansweredQuestions: ExamQuestion[] = [];

                for (const question of exerciseQuestions) {
                  if (answeredQuestionsIds.includes(question.id)) {
                    answeredQuestions.push(question);
                  } else {
                    unansweredQuestions.push(question);
                  }
                }

                store.setScheduledTestExercise({
                  ...exercise,
                  questions: [...answeredQuestions, ...unansweredQuestions],
                });
              } else {
                store.setScheduledTestExercise(exercise);
              }

              store.setSelectedQuestion({
                results: [],
                status: QuestionStatus.INITIAL,
                index: answeredQuestionsIds.length,
              });
            } else {
              store.setSelectedQuestion({
                results: [],
                status: QuestionStatus.INITIAL,
                index: 0,
              });
              store.setScheduledTestExercise(exercise);
            }

            return {
              success: true,
              exerciseTitle: exercise.title,
              index: !currentStatus ? 0 : currentStatus.answeredQuestions.length,
            };
          }),
        )
        .catch(
          action(() => {
            ToastMethods.showToast(
              i18n.t("toast:scheduledTest.error.getScheduledTestExercise"),
              "error",
            );
            store.setScheduledTestExercise(null);
            store.setScheduledTestExerciseStatus(null);
            return { success: false };
          }),
        )
        .finally(
          action(() => {
            store.setLoading(initialState.loading);
          }),
        );
    },
  ),

  fetchScheduledTestExerciseAnswers: action(
    (exerciseId: number, testToken: string): Promise<boolean> => {
      store.setLoading(true);
      return scheduledTestRepository
        .fetchExerciseAnswers(exerciseId, testToken)
        .then((answers: ExerciseAnswer[]) => {
          store.setScheduledTestExerciseAnswers(answers);
          return Promise.resolve(true);
        })
        .catch(() => {
          ToastMethods.showToast(
            i18n.t("toast:scheduledTest.error.getScheduledTestExerciseAnswers"),
            "error",
          );
          store.setScheduledTestExerciseAnswers(null);
          return Promise.resolve(false);
        })
        .finally(() => {
          store.setLoading(initialState.loading);
        });
    },
  ),

  // Mark scheduled test attempt as completed
  finishScheduledTest: action((testCode: string, testToken: string) => {
    store.setLoading(true);
    scheduledTestRepository
      .finishScheduledTest(testCode, testToken)
      .then(() => {
        store.scheduledTestContent = {
          ...(store.scheduledTestContent as ContentAPI.ScheduledTestExercisesDetails),
          finished: true,
        };
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.finishScheduledTestFailed"),
          "error",
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchScheduledTestExerciseResults: action(
    (testCode: string, exerciseId: number) =>
      new Promise<boolean>((resolve) => {
        store.setLoading(true);
        scheduledTestRepository
          .fetchScheduledTestExerciseResults(testCode, exerciseId)
          .then((data) => {
            store.setScheduledTestResults([data] as ExerciseResult[]);
            resolve(true);
          })
          .catch(() => {
            ToastMethods.showToast(
              i18n.t("toast:scheduledTest.error.fetchScheduledTestResults"),
              "error",
            );
            resolve(false);
          })
          .finally(() => {
            store.setLoading(false);
          });
      }),
  ),

  fetchScheduledTestLiveData: action(
    (testId: number): Promise<boolean> =>
      new Promise((resolve) => {
        store.setLoading(true);
        scheduledTestRepository
          .fetchScheduledTestLiveData(testId)
          .then((data) => {
            store.setScheduledTestLiveData({
              scheduledTestId: testId,
              logs: data,
            });
            resolve(true);
          })
          .catch(() => {
            ToastMethods.showToast(
              i18n.t("toast:scheduledTest.error.fetchScheduledTestLiveDataFailed"),
              "error",
            );
            resolve(false);
          })
          .finally(() => {
            store.setLoading(false);
          });
      }),
  ),

  fetchScheduledTestStudentLogs: action(
    (testId: number, studentId: number): Promise<boolean> =>
      new Promise((resolve) => {
        store.setLoading(true);
        scheduledTestRepository
          .fetchScheduledTestStudentLogs(testId, studentId)
          .then((logs) => {
            store.setScheduledTestStudentLogs(logs);
            resolve(true);
          })
          .catch(() => {
            ToastMethods.showToast(
              i18n.t("toast:scheduledTest.error.fetchScheduledTestStudentLogsFailed", {
                studentTerm: getStudentTermByDomain(),
              }),
              "error",
            );
            resolve(false);
          })
          .finally(() => {
            store.setLoading(false);
          });
      }),
  ),

  // Fetch result overview for tests of the specified type taken by the student
  fetchStudentScheduledTestResultsOverview: action(
    (productId: number, testType: ProductTestGroupType): Promise<boolean> => {
      store.setLoading(true);
      return scheduledTestRepository
        .fetchStudentScheduledTestResultsOverview(productId, testType)
        .then(
          action((results: ScheduledTestResultOverview[]) => {
            store.setStudentScheduledTestResultsOverview(results);
            return true;
          }),
        )
        .catch(
          action(() => {
            ToastMethods.showToast(
              i18n.t("toast:scheduledTest.error.getStudentScheduledTestResultsOverviewFailed"),
              "error",
            );
            store.setStudentScheduledTestResultsOverview(null);
            return false;
          }),
        )
        .finally(
          action(() => {
            store.setLoading(false);
          }),
        );
    },
  ),

  // Fetch scores obtained by the user on the selected test
  fetchScheduledTestScore: action((testCode: string): Promise<boolean> => {
    store.setLoading(true);
    return scheduledTestRepository
      .fetchStudentScheduledTestScores(testCode)
      .then(
        action((results: ScheduledTestScoreDetail) => {
          store.setScheduledTestScore(results);
          return true;
        }),
      )
      .catch(
        action(() => {
          ToastMethods.showToast(
            i18n.t("toast:scheduledTest.error.getStudentScheduledTestScoresFailed"),
            "error",
          );
          store.setScheduledTestScore(null);
          return false;
        }),
      )
      .finally(
        action(() => {
          store.setLoading(false);
        }),
      );
  }),

  // Fetch result overview for tests of the specified type taken by the student
  fetchStudentScheduledTestResultsList: action((testType: ProductTestGroupType) => {
    scheduledTestRepository
      .fetchStudentScheduledTestResultsList(testType)
      .then((results: ScheduledTestResultList[]) => {
        store.setStudentScheduledTestResultsList(results);
      })
      .catch(
        action(() => {
          ToastMethods.showToast(
            i18n.t("toast:scheduledTest.error.getStudentScheduledTestResultsListFailed"),
            "error",
          );
          store.setStudentScheduledTestResultsList(null);
        }),
      );
  }),

  // Fetch average scores for all tests created by any teacher within the current user educations
  fetchScheduledTestResultsOverview: action(
    (filters?: OwnedScheduledTestResultsOverviewFilters): Promise<boolean> => {
      store.setLoading(true);
      return scheduledTestRepository
        .fetchScheduledTestResultsOverview(filters)
        .then((scheduledTestResultsOverview: OwnedScheduledTestResultsOverviewItem[]) => {
          store.setOwnedScheduledTestResultsOverview(scheduledTestResultsOverview);
          return true;
        })
        .catch(() => {
          ToastMethods.showToast(
            i18n.t("toast:scheduledTest.error.getScheduledTestResultsOverviewFailed"),
            "error",
          );
          return false;
        })
        .finally(() => {
          store.setLoading(false);
        });
    },
  ),

  // Fetch detailed results for a test created by the user
  fetchOwnedScheduledTestResults: action((scheduledTestId: number): Promise<boolean> => {
    store.setLoading(true);
    return scheduledTestRepository
      .fetchOwnedScheduledTestResults(scheduledTestId)
      .then((results: OwnedScheduledTestResults) => {
        store.setOwnedScheduledTestResults(results);
        return true;
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.getOwnedScheduledTestResultsFailed"),
          "error",
        );
        return false;
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  // Download results report for the selected test
  downloadScheduledTestResultsReport: action(
    (scheduledTestId: number, reportSettings: ScheduledTestResultsReportSettings = {}) => {
      store.setLoading(true);
      return scheduledTestRepository
        .fetchScheduledTestResultsReport(scheduledTestId, reportSettings)
        .then((excelReportFile: string) => {
          downloadExcelFile(`test-report-${scheduledTestId}.xlsx`, excelReportFile);
          return true;
        })
        .catch(() => {
          ToastMethods.showToast(
            i18n.t("toast:scheduledTest.error.fetchScheduledTestResultsReport"),
            "error",
          );
          return false;
        })
        .finally(() => {
          store.setLoading(false);
        });
    },
  ),

  // Fetch the timers of the scheduled tests in review period
  fetchScheduledTestsReviewPeriodTimer: action(() => {
    store.setLoading(true);
    scheduledTestRepository
      .fetchScheduledTestsReviewPeriodTimer()
      .then((timers: ScheduledTestReviewPeriodTimer[]) => {
        store.setScheduledTestsReviewPeriodTimer(timers);
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.fetchScheduledTestsReviewPeriodTimer"),
          "error",
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchScheduledTestStatusAndTimeLeft: action((code: string) => {
    store.setLoading(true);
    scheduledTestRepository
      .fetchScheduledTestStatus(code)
      .then((status: ScheduledTestStatusAndTimeLeft) => {
        store.setScheduledTestStatusAndTimeLeft(status);
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.fetchScheduledTestStatusAndTimeLeft", "error"),
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchScheduledTestsCertificates: action((payload: RequestScheduledTestsCertificatesResults) => {
    store.setLoading(true);
    resultsRepository
      .getStudentScheduledTestsResultsCertificate(payload)
      .then((certificates: ResponseScheduledTestsCertificatesResults) => {
        store.setScheduledTestsCertificates(certificates);
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.getCertificateScheduledTestResultsFailed", "error"),
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  updateStudentScheduledTestsResultsCertificate: action((scheduledTestId: number) => {
    store.setLoading(true);
    return resultsRepository
      .updateStudentScheduledTestsResultsCertificate(scheduledTestId)
      .then((response: AssignScheduledTestCertificatesResponse) => {
        const { success, skipped } = response;
        ToastMethods.showToast(
          i18n.t(
            "toast:scheduledTest.success.updateStudentScheduledTestsResultsCertificate",
            "Successfully Assigned",
            {
              certificatesCountAssigned: success || 0,
              certificatesCountSkipped: skipped || 0,
            },
          ),
          "success",
        );
        return response;
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t(
            "toast:scheduledTest.error.updateStudentScheduledTestsResultsCertificate",
            "error",
          ),
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  invalidateScheduledTestOfStudent: action((scheduledTestId: number, studentId: number) => {
    store.setLoading(true);
    resultsRepository
      .invalidateScheduledTestOfStudent(scheduledTestId, studentId)
      .then(() => {
        store.setInvalidatedScheduledTest(true);
      })
      .catch(() => {
        store.setInvalidatedScheduledTest(false);
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.postInvalidateScheduledTestFailed", {
            studentTerm: getStudentTermByDomain(),
          }),
          "error",
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchLastCompletedTests: action(() => {
    store.setLoading(true);
    scheduledTestRepository
      .fetchLastCompletedTests()
      .then((lastCompletedTests: LastCompletedTest[]) => {
        store.setLastCompletedTests(lastCompletedTests);
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.fetchScheduledTestStatusAndTimeLeft", "error"),
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  fetchDiagnosticTestDetails: action((filters: OverviewFilters) => {
    store.setLoading(true);
    scheduledTestRepository
      .fetchDiagnosticTests(filters)
      .then((diagnosticTestDetails: ResultsAPI.DiagnosticTestResultsDetails) => {
        store.setDiagnosticTestDetails(diagnosticTestDetails);
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.fetchScheduledTestStatusAndTimeLeft", "error"),
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  allowStudentsToReEnter: action((scheduledTestId: number, accountIds: number[]) => {
    store.setLoading(true);
    scheduledTestRepository
      .allowStudentsToReEnter(scheduledTestId, accountIds)
      .then(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.success.allowStudentToReEnterSuccess", {
            studentTerm: getStudentTermByDomain(),
          }),
          "success",
        );
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.allowStudentToReEnterFailed", {
            studentTerm: getStudentTermByDomain(),
          }),
          "error",
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchScheduledTestsResultsStudent: action((userId: number) => {
    store.setLoading(true);
    scheduledTestRepository
      .fetchScheduledTestsResultsForUser(userId)
      .then((val) => {
        store.setScheduledTestsResultsStudent(val);
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.getScheduledTestsResultsUserFailed"),
          "error",
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
};

const store: ScheduledTestsStore = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as ScheduledTestsStore);

export const useScheduledTests = (): ScheduledTestsStore => store;
