import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { LoadingSpinner } from "components/LoadingSpinner";
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from "constants/routes";
import { useProduct } from "hooks/useProduct";
import { useScheduledTests } from "hooks/useScheduledTests";
import type { ProductTestGroupType } from "models/product/ProductTestGroups";
import type { ScheduledTestRequestPageParams } from "./ScheduledTestRequestPage.model";
import { StyledLoader, StyledScheduledTestRequestPage } from "./ScheduledTestRequestPage.styled";
import { ScheduledTestRequestForm } from "./form/ScheduledTestRequestForm";
import type { ScheduledTestRequestFormValues } from "./form/ScheduledTestRequestForm.model";
import type { FormValues, ValidationError } from "./steps/FormStep.model";
import {
  getDefaultScheduledTestRequestFormValues,
  parseCreatePayload,
  parseUpdatePayload,
  scheduledTestToFormValues,
} from "./utils";

export const ScheduledTestRequestPage: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation("scheduled-tests");
  const { scheduledTestId }: ScheduledTestRequestPageParams = useParams();
  const { productsList, fetchProducts } = useProduct();
  const {
    loading: isSubmitting,
    scheduledTest,
    setScheduledTest,
    createScheduledTest,
    updateScheduledTest,
    fetchScheduledTest,
  } = useScheduledTests();
  const { productTestGroups, fetchProductTestGroups } = useProduct();
  // when scheduledTestId is set in params, we should wait to fill formValues
  // with data until the existing scheduled test is actually loaded
  const shouldLoadScheduledTest =
    scheduledTestId && !Number.isNaN(Number.parseInt(scheduledTestId, 10));
  const [formValues, setFormValues] = useState<FormValues | null>(() =>
    shouldLoadScheduledTest ? null : getDefaultScheduledTestRequestFormValues(),
  );
  const [formErrors, setFormErrors] = useState<ValidationError[]>([]);

  // if scheduledTestId is set the user wants to edit an existing form
  useEffect(() => {
    if (scheduledTestId && !Number.isNaN(Number.parseInt(scheduledTestId, 10))) {
      // load this test
      if (!scheduledTest || scheduledTest.id !== Number.parseInt(scheduledTestId, 10)) {
        fetchScheduledTest(Number.parseInt(scheduledTestId, 10)).then((success) => {
          // could not load the test details, go  back to overview
          if (!success) {
            history.replace(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_OVERVIEW);
          }
        });
      }
    } else {
      // make sure no scheduled test is loaded
      setScheduledTest(null);
    }
  }, [scheduledTest, setScheduledTest, scheduledTestId]);

  useEffect(() => {
    // when scheduled test loads, fill formValues with data
    if (scheduledTest) {
      setFormValues(scheduledTestToFormValues(scheduledTest));
    }
  }, [scheduledTest]);

  const onSubmit = async () => {
    // we can cast the formValues to ScheduledTestRequestFormValues here
    // because we have validated the values with Yup
    let success = false;
    if (scheduledTest) {
      // test already exists, so we can update it
      success = await updateScheduledTest(
        scheduledTest.id,
        parseUpdatePayload(formValues as ScheduledTestRequestFormValues),
      );
    } else {
      success = await createScheduledTest(
        parseCreatePayload(formValues as ScheduledTestRequestFormValues),
      );
    }
    if (success) {
      // only redirect back to overview page when test has been successfully scheduled
      history.push(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_OVERVIEW);
    }
    // else: will show a toast error
  };

  useEffect(() => {
    if (!productsList) {
      fetchProducts();
    }
  }, [productsList]);

  useEffect(() => {
    // productId or testType changed: fetch the test groups (again)
    if (formValues?.productId && formValues?.testType) {
      fetchProductTestGroups(+formValues.productId, formValues.testType as ProductTestGroupType);
    }
  }, [formValues?.productId, formValues?.testType]);

  if (
    !formValues ||
    !productsList ||
    (formValues.productId && formValues.testType && !productTestGroups)
  ) {
    return (
      <StyledScheduledTestRequestPage>
        <StyledLoader>
          <LoadingSpinner />
        </StyledLoader>
      </StyledScheduledTestRequestPage>
    );
  }

  return (
    <StyledScheduledTestRequestPage>
      <ScheduledTestRequestForm
        formErrors={formErrors}
        formValues={formValues}
        isEditingScheduledTest={!!scheduledTest}
        isSubmitting={isSubmitting}
        productsList={productsList}
        setFormErrors={setFormErrors}
        setFormValues={setFormValues}
        submitButtonTitle={scheduledTest ? t("updateButton.label") : t("submitButton.label")}
        testGroupOptions={productTestGroups}
        onSubmit={onSubmit}
      />
    </StyledScheduledTestRequestPage>
  );
});
