import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { calculateRem } from "styles/mixins/mixins";

import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssClickableIcon, CssFlex, CssGrid } from "styles/helpers/layout";

export const StyledPageContainer = styled(CssFlex)`
  height: 100vh;
  padding: 3rem;
  background-color: #f5f7f9;
`;

export const StyledCloseButton = styled(CssClickableIcon)`
  align-self: flex-end;
`;

export const StyledCard = styled(CssGrid)<ThemeProps>`
  width: 28rem;
  padding: 2rem;
  background: white;
  box-shadow: 3px 3px 5px 0px #93a9b61a;

  row-gap: 1rem;
  column-gap: 2rem;
  align-content: start;

  grid-template-areas:
    'icon title'
    'icon description';
  grid-template-columns: ${calculateRem(70)} 1fr;

  :hover {
    cursor: pointer;
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._999};
  }

  img {
    grid-area: icon;
  }

  ${Heading3} {
    grid-area: title;
    margin: 0;
  }

  ${ParagraphLarge} {
    grid-area: description;
    margin: 0;
  }
`;
