import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { calculateRem } from "styles/mixins/mixins";

import { Button } from "components/Button";
import { SvgIconReact } from "components/SvgIconReact";

import backgroundImage from "assets/images/basic-account-bg.png";

export const StyledPageLayout = styled.div<ThemeProps>`
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  background-image: ${() => `url(${backgroundImage})`};
  background-position: top left;
  background-repeat: no-repeat;
`;

export const StyledContainer = styled.main<ThemeProps>`
  flex-grow: 1;
  width: 45rem;
  max-width: 100%;
  padding: 8rem 4rem;

  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledLogo = styled(SvgIconReact)`
  height: 1.3rem;
  width: auto;
`;

export const StyledForm = styled.form`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledFieldRow = styled.section`
  width: 100%;
  display: grid;
  column-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
`;

export const StyledField = styled.div`
  > label {
    display: block;
    font-size: ${(props: ThemeProps) => props.theme.font.size._16};
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
  }

  input {
    border-radius: ${calculateRem(4)};
  }

  > span {
    font-size: ${(props: ThemeProps) => props.theme.font.size._10};
  }
`;

export const StyledButton = styled(Button)<ThemeProps>`
  font-weight: normal;
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
`;

export const StyledLoginSection = styled.section<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._10};

  a {
    font-size: ${(props: ThemeProps) => props.theme.font.size._10};
  }
`;

export const StyledCheckList = styled.ul<ThemeProps>`
  padding: 0;
  display: grid;
  list-style: none;
  margin: 0.5rem 0 0 0;
  grid-template-columns: 1fr 1fr;
  font-size: ${(props: ThemeProps) => props.theme.font.size._10};
`;

export const StyledCheckListItem = styled.li<ThemeProps>`
  vertical-align: middle;

  ::before {
    content: '⬤';
    margin-right: 0.2rem;
    display: inline-block;
    color: ${(props: ThemeProps) => props.theme.palette.secondary.brand};
  }

  &[hidden] {
    color: #d9d9d9;
    display: initial;

    ::before {
      color: #d9d9d9;
    }
  }
`;
