import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

import { SideModal } from "components/SideModal/SideModal";
import { Heading4 } from "styles/elements/Headings";

export const StyledSideModal = styled(SideModal)<Props>`
  > div {
    min-width: 33.5rem;
    width: auto;
    overflow-y: scroll;
  }
`;

export const StyledHeading = styled(Heading4)<Props>`
  margin: 2rem 0;
`;

export const StyledEventContainer = styled.ul<Props>`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

export const StyledFraudWarning = styled.div<Props>`
  display: flex;
  padding: 1rem;
  margin: 2rem 0;
  column-gap: 1rem;
  align-items: center;

  font-weight: bold;
  color: ${({ theme }: Props) => theme.palette.feedback.error.brand};
  background: ${({ theme }: Props) => theme.palette.feedback.error._950};
`;
