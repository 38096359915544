import {
  type WebObservability,
  type WebObservabilityOptions,
  configureWebObservability,
} from "@infinitaslearning/observability-web";
import axios from "axios";
import { isLocal, isTest, serviceENV } from "constants/env-constants";

const serviceName = "app-he-hst-web";

// Options to be used for localhost development only. Configuration slightly differs between local and other environments,
// for more details see the web module docs:
// @see https://github.com/infinitaslearning/module-observability-web-js?tab=readme-ov-file#locally
const localOptions: WebObservabilityOptions = {
  serviceName,
  serverUrl: "https://a2ccf4ce09324b819578f6253260eca7.apm.westeurope.azure.elastic-cloud.com:443",
  serverUrlPrefix: undefined,
  sendCredentials: false,
};

// These options are used for all other environments, in the docs it is referred to as "production"
const options: WebObservabilityOptions = {
  serviceName,
  environment: serviceENV(),
  serviceVersion: "0.0.1", // TODO: once we have source maps uploaded too, this will be useful
};

const initAPM = () => configureWebObservability(isLocal() || isTest() ? localOptions : options);

let apm: WebObservability | null = initAPM();

export const getAPM = (): WebObservability | null => {
  if (!apm) apm = initAPM();
  return apm;
};

export const logAPMError = (
  error: Error,
  errorLocation: string,
  metadata?: Record<string, number | string | Date | boolean>,
): void => {
  if (!apm) apm = getAPM();
  apm?.captureError(error, errorLocation, metadata);
};

// Here we log all errors that go through Axios, so no need to log in individual requests:
// https://stackoverflow.com/a/51768316/419194
export const setAxiosLogger = (): void => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      logAPMError(error, "network-error");
      return Promise.reject(error);
    },
  );
};
