import { Modal } from "components/Modal";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import type React from "react";
import { SideMenu } from "./components/SideMenu";
import type { SideMenuProps } from "./components/SideMenu.model";

export const SideMenuModal: React.FC<SideMenuProps> = (props) => {
  const { closeModal, isModalOpen } = useModal();
  return (
    <Modal
      closeOnClickOutside
      dataCy={modalIds.modalSideMenu}
      isOpen={isModalOpen(modalIds.modalSideMenu)}
      modalAnimation="slideRight"
      modalPosition={["center", "right"]}
      onClose={closeModal}
    >
      <SideMenu {...props} />
    </Modal>
  );
};
