import type React from "react";
import { useTranslation } from "react-i18next";

import { SvgIconReact } from "components/SvgIconReact";
import type { PaginatorProps } from "./Paginator.model";
import {
  StyledButton,
  StyledDot,
  StyledLink,
  StyledPages,
  StyledPaginator,
} from "./Paginator.styled";

export const Paginator: React.FC<PaginatorProps> = ({
  links,
  currentPage,
  onChangePage,
  totalPages,
}) => {
  const { t } = useTranslation("common");
  const showPrevDots = currentPage > 1;
  const showNextDots = currentPage < totalPages;
  const pageMaxLimit = 4;

  const begin = currentPage - 1;
  const end = begin + pageMaxLimit;
  const currentPagination = links.slice(begin, end);

  return (
    <StyledPaginator>
      <StyledButton
        dataCy="previous-page-button"
        disabled={currentPage === 1}
        variant="secondary"
        onClick={() => onChangePage(currentPage - 1)}
      >
        <SvgIconReact iconName="leftArrowIcon" />
        {t("paginator.previousButton.label", "Previous")}
      </StyledButton>
      <StyledPages>
        {showPrevDots && <StyledDot>&hellip;</StyledDot>}
        {currentPagination.map(({ path, title }, index) => (
          <StyledLink
            key={title}
            $selected={currentPage === title}
            data-cy={`paginator-link-${index}`}
            to={path}
          >
            {title}
          </StyledLink>
        ))}
        {showNextDots && <StyledDot>&hellip;</StyledDot>}
      </StyledPages>
      <StyledButton
        dataCy="next-page-button"
        disabled={currentPage === links.length}
        variant="brand"
        onClick={() => onChangePage(currentPage + 1)}
      >
        {t("paginator.nextButton.label", "Next")}
        <SvgIconReact iconName="rightArrow" />
      </StyledButton>
    </StyledPaginator>
  );
};
