import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";

import type { AdaptiveTestPageParams } from "pages/StudentEnvironment/AdaptiveTestPage/AdaptiveTestPage.model";

import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";

import { Button } from "components/Button";
import { EncircledIcon } from "components/EncircledIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { SvgIconReact } from "components/SvgIconReact";

import { Heading1, Heading3 } from "styles/elements/Headings";
import { CssFlex, CssGrid } from "styles/helpers/layout";

import {
  StyledAdaptivePracticeSessionResultsPageContainer,
  StyledAdaptivePracticeSessionResultsPageContent,
  StyledButtonContainer,
  StyledSectionHeading,
  StyledStatistic,
  StyledStatisticCard,
  StyledStatisticDenominator,
  StyledStreak,
} from "./AdaptivePracticeSessionResultsPage.styled";

import { buildComparedSessionTopicProgress } from "./utils";

import { ParagraphLarge } from "styles/elements/Texts";
import { PracticedTopicItem } from "./components/PracticedTopicItem/PracticedTopicItem";

export const AdaptivePracticeSessionResultsPage: React.FC = observer(() => {
  const { t } = useTranslation("adaptive-test");
  const history = useHistory();
  const { productId, moduleId }: AdaptiveTestPageParams = useParams();

  const {
    adaptivePracticeSessionResults,
    startedSessionTimestamp,
    setStartedSessionTimestamp,
    resetAdaptivePracticeSessionResults,
  } = useAdaptivePractice();
  const { fetchModuleProgress, moduleSubjectsProgress, productGoalLevel, fetchProductGoalLevel } =
    useProductProgress();
  const { productDetails, fetchProductDetails } = useProduct();

  const [showAllImprovedTopics, setShowAllImprovedTopics] = useState(false);
  const [timeStampWhenResultShown] = useState(new Date().getTime());

  // Store user's goal level data
  const goalLevel = productGoalLevel?.goalLevel;
  const goalLevelName = productDetails?.levels.find(({ level }) => level === goalLevel)?.label;

  useEffect(() => {
    if (productId && moduleId) {
      fetchModuleProgress(+productId, +moduleId);
    }
  }, [productId, moduleId]);

  useEffect(() => {
    if (productId) {
      fetchProductDetails(+productId);
      fetchProductGoalLevel(+productId);
    }
  }, [productId]);

  useEffect(() => {
    if (adaptivePracticeSessionResults.answeredQuestions.length === 0) {
      resetAdaptivePracticeSessionResults();
      history.replace(
        buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
          productId,
          moduleId,
        }),
      );
    }
  }, [history, adaptivePracticeSessionResults]);

  useEffect(() => {
    if (moduleSubjectsProgress && productDetails && startedSessionTimestamp > 0) {
      trackPlatformEvent({
        module: STUDENT_MODULES.BASIC_PROGRESS,
        itemId: PLATFORM_EVENT_TYPES.STUDENT_ADAPTIVE_PRACTICE_EVENT_TYPES.ENDED_ADAPTIVE_PRACTICE,
        value: JSON.stringify({
          startSessionTimestamp: new Date(startedSessionTimestamp).toISOString(),
          endSessionTimestamp: new Date().toISOString(),
          totalQuestionAnswered: adaptivePracticeSessionResults.answeredQuestions.length,
        }),
      });
      setStartedSessionTimestamp(0);
    }
  }, [moduleSubjectsProgress, productDetails, startedSessionTimestamp]);

  const handleBackToOverviewClick = () => {
    trackPlatformEvent({
      module: STUDENT_MODULES.BASIC_PROGRESS,
      itemId: PLATFORM_EVENT_TYPES.STUDENT_ADAPTIVE_PRACTICE_EVENT_TYPES.CLICK_BACK_TO_OVERVIEW,
      elementId: "back-to-overview-btn",
      value: JSON.stringify({
        timeSpentOnResultsPageInMillis: new Date().getTime() - timeStampWhenResultShown,
      }),
    });
    resetAdaptivePracticeSessionResults();
    return history.push(
      buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, { productId, moduleId }),
    );
  };

  if (!moduleSubjectsProgress || !productDetails) {
    return <LoadingSpinner />;
  }

  const practicedTopics = buildComparedSessionTopicProgress(
    adaptivePracticeSessionResults,
    moduleSubjectsProgress,
    productDetails,
  );

  // We store the topic progress as soon as it's loaded, so we need to check to make sure the user has actually
  // answered a question for the topic before displaying it in the results
  const negativeProgressTopics = practicedTopics
    .filter((t) => !t.isImproved)
    .filter((t) =>
      adaptivePracticeSessionResults.answeredQuestions.some((q) => q.topicId === t.id),
    );

  const positiveProgressTopics = practicedTopics
    .filter((t) => t.isImproved)
    .filter((t) =>
      adaptivePracticeSessionResults.answeredQuestions.some((q) => q.topicId === t.id),
    );

  return (
    <StyledAdaptivePracticeSessionResultsPageContainer>
      <CssFlex flexGrow={1} justifyContent="center" p={4} pt="4rem">
        <StyledAdaptivePracticeSessionResultsPageContent>
          <Heading1 mb={2} textAlign="center">
            {t("sessionResults.title")}
          </Heading1>
          <Heading3 fontWeight={"300"} mb={4} textAlign="center">
            {t("sessionResults.subtitle")}
          </Heading3>

          <CssGrid gap={1.5} gridTemplateColumns="repeat(3,1fr)">
            <StyledStatisticCard>
              <ParagraphLarge>{t("sessionResults.statistics.totalTopics")}</ParagraphLarge>
              <StyledStatistic data-cy="session-results-card-total-topics">
                {negativeProgressTopics.length + positiveProgressTopics.length}
              </StyledStatistic>
            </StyledStatisticCard>
            <StyledStatisticCard>
              <ParagraphLarge>{t("sessionResults.statistics.totalQuestions")}</ParagraphLarge>
              <StyledStatistic data-cy="session-results-card-num-questions">
                {
                  adaptivePracticeSessionResults.answeredQuestions.filter((q) => q.isAnswerCorrect)
                    .length
                }
                <StyledStatisticDenominator>
                  {` / ${adaptivePracticeSessionResults.answeredQuestions.length}`}
                </StyledStatisticDenominator>
              </StyledStatistic>
            </StyledStatisticCard>
            <StyledStatisticCard>
              <ParagraphLarge>{t("sessionResults.statistics.highestStreak")}</ParagraphLarge>
              <StyledStreak>
                <SvgIconReact iconName="flameIcon" />
                <span data-cy="session-results-card-streak">
                  {adaptivePracticeSessionResults.maxStreak}
                </span>
              </StyledStreak>
            </StyledStatisticCard>
          </CssGrid>

          {negativeProgressTopics.length > 0 && (
            <>
              <StyledSectionHeading>
                <EncircledIcon iconName="fas fa-bullseye" size="small" variant="primary" />
                {t("sessionResults.progressNegative")}
              </StyledSectionHeading>

              <CssGrid data-cy="session-results-negative-progress-list" rowGap={1.5}>
                {negativeProgressTopics.map((topic) => (
                  <PracticedTopicItem
                    key={topic.id}
                    goalLevel={goalLevel}
                    goalLevelName={goalLevelName}
                    iconName="chartLineDown"
                    practicedTopicStats={topic}
                  />
                ))}
              </CssGrid>
            </>
          )}

          {positiveProgressTopics.length > 0 && (
            <>
              <StyledSectionHeading>
                <EncircledIcon iconName="fas fa-thumbs-up" size="small" variant="secondary" />
                {t("sessionResults.progressPositive")}
              </StyledSectionHeading>

              <CssGrid data-cy="session-results-positive-progress-list" rowGap={1.5}>
                {positiveProgressTopics
                  .slice(0, showAllImprovedTopics ? positiveProgressTopics.length : 3)
                  .map((topic) => (
                    <PracticedTopicItem
                      key={topic.id}
                      goalLevel={goalLevel}
                      goalLevelName={goalLevelName}
                      iconName="chartLineUp"
                      practicedTopicStats={topic}
                    />
                  ))}
              </CssGrid>
            </>
          )}

          {!showAllImprovedTopics && positiveProgressTopics.length > 3 && (
            <CssFlex justifyContent="center" mt={5}>
              <Button hasIconRight variant="text" onClick={() => setShowAllImprovedTopics(true)}>
                {t("sessionResults.showMoreTopicsButton")}
                <SvgIconReact iconName="downArrow" />
              </Button>
            </CssFlex>
          )}
        </StyledAdaptivePracticeSessionResultsPageContent>
      </CssFlex>
      <StyledButtonContainer>
        <Button
          hasIconRight
          dataCy="back-to-overview-btn"
          variant="primary"
          onClick={handleBackToOverviewClick}
        >
          {t("sessionResults.backToOverviewButton")}
        </Button>
      </StyledButtonContainer>
    </StyledAdaptivePracticeSessionResultsPageContainer>
  );
});
