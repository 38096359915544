import styled, { css } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type { DropdownDirection } from "./OptionsDropdown.model";

export interface OptionDropdownHeaderProps {
  theme: ElearningThemeInterface;
  $isOpen: boolean;
}

export interface OptionDropdownContentProps extends OptionDropdownHeaderProps {
  $direction: DropdownDirection;
}

export const OptionsDropdownHeaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

export const OptionDropdownTriangle = styled.span<OptionDropdownHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  transform: rotate(180deg);
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(0);
    `}
`;

export const OptionsDropdownHeader = styled.div<OptionDropdownHeaderProps>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid ${({ theme }: OptionDropdownHeaderProps) => theme.palette.neutral._600};
  padding: 1rem;
  > div {
    display: flex;
  }
  &.disabled {
    background-color: ${({ theme }: OptionDropdownHeaderProps) => theme.palette.neutral._999};
    cursor: not-allowed;
  }
`;

const topDirectionCss = css`
  top: 100%;
  transform: translateY(-100%);
  margin-top: -58px;
`;

export const OptionsDropdownContent = styled.div<OptionDropdownContentProps>`
  position: relative;
  > div {
    z-index: 1002;
    position: absolute;
    display: flex;
    background: ${({ theme }: OptionDropdownContentProps) => theme.palette.neutral.white};
    border: 1px solid ${({ theme }: OptionDropdownContentProps) => theme.palette.neutral._900};
    border-radius: 4px;
    ${({ $direction }) => $direction === "top" && topDirectionCss};
    ul {
      list-style: none;
      padding: 0;
      margin: 1rem;
    }
  }
`;
