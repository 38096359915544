import { observer } from "mobx-react";
import type React from "react";
import type { DiagnosticTestContentProps } from "./DiagnosticTestContent.model";
import {
  StyledDiagnosticTestContentDescription,
  StyledDiagnosticTestContentSubtitle,
  StyledDiagnosticTestContentTitle,
} from "./DiagnosticTestContent.styled";

export const DiagnosticTestContent: React.FC<DiagnosticTestContentProps> = observer(
  ({ title, subtitle, description, children }) => (
    <div>
      <StyledDiagnosticTestContentTitle data-cy="diagnostic-content-title">
        {title}
      </StyledDiagnosticTestContentTitle>
      <StyledDiagnosticTestContentSubtitle data-cy="diagnostic-content-subtitle">
        {subtitle}
      </StyledDiagnosticTestContentSubtitle>
      <StyledDiagnosticTestContentDescription data-cy="diagnostic-content-description">
        {description}
      </StyledDiagnosticTestContentDescription>
      {children}
    </div>
  ),
);
