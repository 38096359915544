import { Modal } from "components/Modal";
import { modalIds } from "constants/modals-constants";
import { Form, Formik } from "formik";
import { useModal } from "hooks/useModal";
import { useEffect, useMemo, useState } from "react";

import { Button } from "components/Button";
import FileUpload from "components/FileUpload/FileUpload";
import { LoadingSpinner } from "components/LoadingSpinner";
import type { SelectOptions } from "components/SelectUI/Select.model";
import { useProduct } from "hooks/useProduct";
import { useTeacherInstructions } from "hooks/useTeacherInstructions";
import { observer } from "mobx-react";
import { TeacherTypesForInstructions } from "models/product/TeacherInstructions";
import { useTranslation } from "react-i18next";
import { Heading3 } from "styles/elements/Headings";
import { CssFlex, CssLabel } from "styles/helpers/layout";

import {
  type TeacherInstructionFormFields,
  buildTeacherInstructionsSchema,
} from "./TeacherInstructionModal.schema";
import { StyledInput, StyledSelect } from "./TeacherInstructionsModal.styled";

interface TeacherInstructionsModalProps {
  onProductInstructionsSubmit: (productId: number) => void;
}

export const TeacherInstructionsModal: React.FC<TeacherInstructionsModalProps> = observer(
  ({ onProductInstructionsSubmit }) => {
    const { updateTeacherInstructions, createTeacherInstructions, selectedEditTeacherInstruction } =
      useTeacherInstructions();
    const { productsList, fetchProducts, fetchProductDetails, productDetails, loading } =
      useProduct();
    const { closeModal, isModalOpen, modalParams } = useModal();
    const { t } = useTranslation("admin-environment");

    const [selectedProductId, setSelectedProductId] = useState<number | string | null>(null);
    const [, setFile] = useState<File | null>(null);

    // Automatically select product id based on modal params or selected instructions
    useEffect(() => {
      if (!selectedProductId && isModalOpen(modalIds.teacherInstructionsModal)) {
        setSelectedProductId(
          selectedEditTeacherInstruction?.productId || modalParams?.productId?.toString() || null,
        );
      }
    }, [modalParams, selectedEditTeacherInstruction, isModalOpen]);

    // Reset selected product on modal close
    useEffect(() => {
      if (!isModalOpen(modalIds.teacherInstructionsModal)) {
        setSelectedProductId(null);
      }
    }, [isModalOpen]);

    // Fetch data required to populate product and module selectors
    useEffect(() => {
      if (isModalOpen(modalIds.teacherInstructionsModal)) {
        if (!productsList) {
          fetchProducts();
        }
        if (selectedProductId) {
          fetchProductDetails(+selectedProductId);
        }
      }
    }, [productsList, selectedProductId, fetchProducts, fetchProductDetails, isModalOpen]);

    // Initialize options for product selector
    const productOptions: SelectOptions[] = useMemo(() => {
      return (
        productsList?.map(({ id, title }) => ({
          value: id,
          label: title,
        })) || []
      );
    }, [productsList]);

    // Initialize options for module selector depending on currently selected product
    const moduleOptions = useMemo(() => {
      if (productDetails?.modules) {
        return productDetails.modules.map(({ title, id }) => ({
          value: id,
          label: title,
        }));
      }

      return [];
    }, [productDetails, t]);

    // Initialize options for teacher type selector
    const teacherTypeOptions = useMemo(
      () => [
        {
          value: TeacherTypesForInstructions.GENERIC,
          label: t("teacherInstructions.genericTeacher"),
        },
        {
          value: TeacherTypesForInstructions.LANGUAGE,
          label: t("teacherInstructions.languageTeacher"),
        },
      ],
      [],
    );

    const handleSubmit = (values: TeacherInstructionFormFields) => {
      if (!values.productId || !values.moduleId) return;

      const updatedValues = {
        title: values.title,
        productId: +values.productId,
        moduleId: +values.moduleId,
        targetTeacherType: values.targetTeacherType as TeacherTypesForInstructions,
        pdfFile: values.pdfFile || undefined,
        // TODO: language should be customizable with an option in the form UI
        languages: ["nl", "en"],
      };

      if (selectedEditTeacherInstruction) {
        updateTeacherInstructions(
          {
            ...updatedValues,
            documentUrl: selectedEditTeacherInstruction.documentUrl,
          },
          selectedEditTeacherInstruction.id,
        );
      } else {
        createTeacherInstructions(updatedValues);
      }

      closeModal();

      // Notify parent component of product where the item was created / edited,
      // to update the page filters accordingly
      onProductInstructionsSubmit(updatedValues.productId);
    };

    return (
      <Modal
        wrapInModalBox
        dataCy={modalIds.teacherInstructionsModal}
        isOpen={isModalOpen(modalIds.teacherInstructionsModal)}
        onClose={() => {
          closeModal();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            title: selectedEditTeacherInstruction?.title || "",
            moduleId: selectedEditTeacherInstruction?.moduleId,
            targetTeacherType: selectedEditTeacherInstruction?.targetTeacherType,
            pdfFile: null,
            productId:
              selectedEditTeacherInstruction?.productId || modalParams?.productId?.toString(),
          }}
          validationSchema={buildTeacherInstructionsSchema()}
          onSubmit={handleSubmit}
        >
          {({ dirty, handleChange, isValid, isSubmitting, setFieldValue, values }) => (
            <Form data-cy="teacher-instructions-form">
              <CssFlex flexDirection="column" alignItems="baseline" width="34rem">
                <Heading3>
                  {t("teacherInstructions.modal.title", "Upload een docenteninstructie")}
                </Heading3>
                <CssFlex flexDirection="column" alignItems="baseline" marginTop="1rem">
                  <CssLabel>{t("teacherInstructions.modal.fields.title.label")}</CssLabel>
                  <StyledInput
                    name="title"
                    placeholder={t("teacherInstructions.modal.fields.title.label")}
                    value={values.title}
                    dataCy="teacher-intruction-title-input"
                    onChange={handleChange}
                  />
                </CssFlex>
                <CssFlex
                  flexDirection="row"
                  alignItems="end"
                  columnGap={1}
                  margin="1rem 0"
                  width="100%"
                  justifyContent="space-between"
                >
                  <CssFlex flexDirection="column" alignItems="baseline" flex={1} maxWidth="16rem">
                    <CssLabel id="teacher-instruction-modal-product-selector-label">
                      {t("teacherInstructions.modal.fields.product.label", "Product")}
                    </CssLabel>
                    <StyledSelect
                      aria-labelledby="teacher-instruction-modal-product-selector-label"
                      dataCy="teacher-intruction-product-selector"
                      name="productId"
                      options={productOptions}
                      value={values.productId}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        handleChange(event);
                        setSelectedProductId(+event.target.value);

                        // Reset module selection on product change, as current one will no longer be valid
                        setFieldValue("moduleId", undefined);
                      }}
                    />
                  </CssFlex>
                  <CssFlex flexDirection="column" alignItems="baseline" flex={1} maxWidth="16rem">
                    <CssLabel id="teacher-instruction-modal-module-selector-label">
                      {t("teacherInstructions.modal.fields.module.label")}
                    </CssLabel>
                    {loading ? (
                      <LoadingSpinner size={35} />
                    ) : (
                      <StyledSelect
                        aria-labelledby="teacher-instruction-modal-module-selector-label"
                        dataCy="teacher-intruction-module-selector"
                        name="moduleId"
                        options={moduleOptions}
                        placeholder={t("teacherInstructions.modal.fields.module.placeholder")}
                        value={values.moduleId}
                        onChange={handleChange}
                      />
                    )}
                  </CssFlex>
                </CssFlex>
                <CssFlex flexDirection="column" alignItems="baseline" width="100%">
                  <CssLabel id="teacher-instruction-modal-teacher-type-selector-label">
                    {t("teacherInstructions.modal.fields.targetTeacherType.label")}
                  </CssLabel>
                  <StyledSelect
                    aria-labelledby="teacher-instruction-modal-teacher-type-selector-label"
                    dataCy="teacher-intruction-teacher-type-selector"
                    name="targetTeacherType"
                    options={teacherTypeOptions}
                    placeholder={t(
                      "teacherInstructions.modal.fields.targetTeacherType.placeholder",
                    )}
                    value={values.targetTeacherType}
                    onChange={handleChange}
                  />
                </CssFlex>
                <CssFlex margin="1rem 0" width="100%">
                  <FileUpload
                    setFieldValue={setFieldValue}
                    setFile={setFile}
                    file={values.pdfFile}
                  />
                </CssFlex>
                <Button
                  type="submit"
                  variant="primary"
                  dataCy="teacher-instruction-submit"
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  {t("teacherInstructions.submit")}
                </Button>
              </CssFlex>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  },
);
