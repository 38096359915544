import type {
  ScheduledTestExerciseAvgResult,
  UserScheduledTestResults,
} from "models/exam/ScheduledTest";
import type React from "react";
import { useMemo } from "react";
import { Heading3 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";
import { StyledDottedScore, StyledExercise } from "./ExercisesList.styled";

export interface ExercisesListProps {
  exercises: ScheduledTestExerciseAvgResult[];
  student: UserScheduledTestResults;
}

export const ExercisesList: React.FC<ExercisesListProps> = ({ exercises = [], student }) => {
  const exercisesWithStudentScore: ScheduledTestExerciseAvgResult[] = useMemo(
    () =>
      exercises.map(({ score, ...exercise }) => ({
        ...exercise,
        score: student.exerciseScores.find(({ id }) => id === exercise.id)?.score || 0,
      })),
    [exercises, student],
  );

  return (
    <>
      {exercisesWithStudentScore.map((exercise) => (
        <StyledExercise key={exercise.id} gap={1} gridTemplateColumns="1fr auto 2.5rem">
          <Heading3 data-cy={`title-exercise-${exercise.id}`}>{exercise.title}</Heading3>
          <CssFlex alignItems="center" columnGap={0.5} flexDirection="row" justifyContent="center">
            {Array.from(Array(5).keys()).map((_, index) => (
              <StyledDottedScore
                key={`score-${exercise.id}-${_}`}
                currIndex={index}
                score={exercise.score}
              />
            ))}
          </CssFlex>
          <span data-cy={`score-exercise-${exercise.id}`}>{`${Math.round(exercise.score)}%`}</span>
        </StyledExercise>
      ))}
    </>
  );
};
