import type { ResultsAPI } from "generated/types";
import { useDomainHandler } from "hooks/useDomainHandler";
import { type FC, useEffect, useState } from "react";
import ProgressBar from "react-customizable-progressbar";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import { StyledFontAwesomeIcon, StyledTableRow, StyledWrapper } from "./TestDetails.styled";

export interface TestDetailsProps {
  diagnosticTestDetails: ResultsAPI.DiagnosticTestResultsDetails;
}

export const TestDetails: FC<TestDetailsProps> = ({ diagnosticTestDetails }) => {
  const [progressBarWrapperRef, setProgressBarWrapperRef] = useState<HTMLDivElement | null>(null);
  const [progressBarHeight, setProgressBarHeight] = useState<number>(0);
  const { t } = useTranslation("teacher-dashboard");
  const { getStudentTermByDomain } = useDomainHandler();
  const hogesTheme = useTheme() as ElearningThemeInterface;

  useEffect(() => {
    if (progressBarWrapperRef) {
      setProgressBarHeight(progressBarWrapperRef.scrollHeight);
    }
  }, [progressBarWrapperRef]);

  const { totalStudentsCompleted, totalStudentsNotCompleted, studentIds } = diagnosticTestDetails;

  return (
    <StyledWrapper>
      <div ref={(div) => setProgressBarWrapperRef(div)}>
        <ProgressBar
          progress={Math.round((totalStudentsCompleted / studentIds.length) * 100)}
          radius={progressBarHeight / 2.5}
          strokeColor={hogesTheme.palette.feedback.success._800}
          trackStrokeColor={hogesTheme.palette.neutral._600}
        />
      </div>
      <div>
        <Heading3>
          {t("diagnosticTest.testDetails.totalStudents", {
            totalStudents: studentIds.length,
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
        </Heading3>
        <StyledTableRow isHighlighted>
          <StyledFontAwesomeIcon finished iconName="fas fa-circle" />
          {t("diagnosticTest.testDetails.completed", { totalStudentsCompleted })}
        </StyledTableRow>
        <StyledTableRow>
          <StyledFontAwesomeIcon iconName="fas fa-circle" />
          {t("diagnosticTest.testDetails.notCompleted", { totalStudentsNotCompleted })}
        </StyledTableRow>
      </div>
    </StyledWrapper>
  );
};
