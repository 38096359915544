import { addYears, format } from "date-fns";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { CombiLicenseProducts, LicenseDurationPerType, ProductType } from "constants/hst-constants";
import { useProduct } from "hooks/useProduct";

import type { OptionProps } from "components/Select/Select.model";
import { Select } from "components/SelectUI/Select";

import { SeparatorLine } from "components/SeparatorLine";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProfile } from "hooks/useProfile";
import type { ProductSkillLevel } from "models/product/ProductDetails";
import { Heading2, Heading3 } from "styles/elements/Headings";
import { Box, CssForm } from "styles/helpers/layout";
import {
  StyledDescription,
  StyledInput,
  StyledInputWrapper,
  StyledWrapper,
} from "./AddProduct.styled";
import { SkillLevelSelector } from "./SkillLevelSelector";

export interface AddProductProps {
  products: SelectedProduct[] | undefined;
  onSelectedProductChanged: (selectedProducts: SelectedProduct[], licenseValidTill: Date) => void;
  onSelectedSkillLevel: (productId: number, selectedSkillLevel: number) => void;
}

export interface SelectedProduct {
  id: number;
  name: string;
  type?: ProductType;
  initialSkillLevel?: number;
}

export const AddProduct: React.FC<AddProductProps> = observer(
  ({ products = [], onSelectedProductChanged, onSelectedSkillLevel }: AddProductProps) => {
    const { t } = useTranslation("teacher-dashboard");
    const { showStudentGoalLevelSelector } = useFeatureFlags();
    const { userDetails, fetchUserDetails } = useProfile();
    // Define list of options for the dropdown menu
    const { productsList, productDetails } = useProduct();
    const { getDefaultProductTypeByDomain } = useDomainHandler();

    const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(products);
    const [selectedLicenseType, setSelectedLicenseType] = useState<ProductType>(
      getDefaultProductTypeByDomain(),
    );
    const [selectedRadioButton, setSelectedRadioButton] = useState<number>(-1);
    const [canSelectSkillLevel, setCanSelectSkillLevel] = useState<boolean>(true);

    useEffect(() => {
      if (userDetails) {
        fetchUserDetails();
      }
    }, []);

    const options: OptionProps[] = useMemo(() => {
      if (!productsList) {
        return [];
      }

      const ddlOptions = productsList.map((prod) => ({
        label: prod.title,
        value: `${prod.id}`,
      }));

      // Only show combi license option if teacher owns both english and dutch
      const canSendCombiLicense = CombiLicenseProducts.every((pid) =>
        productsList.find(({ id }) => id === pid),
      );
      if (canSendCombiLicense) {
        ddlOptions.push({
          label: t("studentLicense.addProducts.form.combiLicense"),
          value: CombiLicenseProducts.join(","),
        });
      }

      return ddlOptions;
    }, [productsList]);

    const availableLevels: ProductSkillLevel[] = useMemo(() => {
      if (selectedProducts.length) {
        return (
          productDetails?.levels.filter((level) => level.selectable) || ([] as ProductSkillLevel[])
        );
      }
      return [];
    }, [selectedProducts, productDetails]);

    // Calculate license expiration date based on the selected product type
    const licenseValidTill = useMemo(() => {
      const currDate = new Date();
      return addYears(currDate, LicenseDurationPerType[selectedLicenseType]);
    }, [selectedLicenseType]);

    useEffect(() => {
      if (selectedProducts) {
        onSelectedProductChanged(selectedProducts, licenseValidTill);
      }
    }, [selectedProducts, licenseValidTill]);

    // Obtain list of selected product ids in string format to select the right option in the dropdown menu
    const getSelectedOptionId = () => {
      if (selectedProducts.length) {
        return selectedProducts.map(({ id }) => id).join(",");
      }
    };

    // Handler for the change event in the product selector
    const onSelectedOptionChange = (selectedOptionId: string) => {
      if (!selectedOptionId || !productsList) {
        setSelectedProducts([]);
        return;
      }

      const selectedProductIds = selectedOptionId.split(",");
      const selectedProductList = productsList.filter(({ id }) =>
        selectedProductIds.includes(`${id}`),
      );

      // If the current selection contains a business product, license expiration period for business should apply
      const isBusinessProductSelected = selectedProductList.some(
        ({ type }) => type === ProductType.BUSINESS,
      );
      setSelectedLicenseType(
        isBusinessProductSelected ? ProductType.BUSINESS : ProductType.REGULAR,
      );

      setSelectedProducts(
        selectedProductList.map((product) => ({
          id: product.id,
          name: product.title,
          type: product.type,
        })),
      );

      // If list contains only one element, we can display the optional skill level selection part, otherwise
      // we avoid displaying it since it won't make sense for multiple product that may have different skill levels
      // Also, this feature will only be available if the selected product is of type business (may change eventually)
      setCanSelectSkillLevel(isBusinessProductSelected && selectedProductList.length === 1);
      setSelectedRadioButton(-1);
    };

    const skillLevelSelectionPart =
      showStudentGoalLevelSelector &&
      canSelectSkillLevel &&
      availableLevels.length > 0 &&
      userDetails?.canSetStudentTargetLevel ? (
        <Box data-cy="optional-skill-level-selection">
          <SeparatorLine direction="horizontal" height={0} />
          <Heading2 marginTop={3}>{t("studentLicense.addProducts.form.level.select")}</Heading2>
          <StyledDescription>
            <Heading3>{t("studentLicense.addProducts.form.level.description")}</Heading3>
          </StyledDescription>
          <Box width="80%">
            <SkillLevelSelector
              availableSkillLevels={availableLevels}
              selectedRadioButtonIndex={selectedRadioButton}
              onRadioButtonChange={(radioButtonIndex, skillLevel) => {
                setSelectedRadioButton(radioButtonIndex);
                onSelectedSkillLevel(selectedProducts[0].id, skillLevel);
              }}
            />
          </Box>
        </Box>
      ) : (
        <></>
      );

    return (
      <StyledWrapper>
        <Heading2>{t("studentLicense.addProducts.title")}</Heading2>
        <StyledDescription>
          <Heading3>{t("studentLicense.addProducts.description")}</Heading3>
        </StyledDescription>
        <CssForm>
          <Box width="60%">
            <Heading3>{t("studentLicense.addProducts.form.select")}</Heading3>
            {productsList && (
              <Select
                aria-label={t("studentLicense.addProducts.form.select")}
                dataCy="product-dropdown"
                options={options}
                placeholder={t("studentLicense.addProducts.form.select")}
                value={getSelectedOptionId()}
                onChange={(e) => onSelectedOptionChange(e.target.value)}
              />
            )}
            <StyledInputWrapper>
              <label htmlFor="license-expiration-date-input">
                {t("studentLicense.addProducts.form.date")}
              </label>
              <StyledInput
                disabled
                dataCy="add-products-date"
                id="license-expiration-date-input"
                type="date"
                value={format(licenseValidTill, "yyyy-MM-dd")}
              />
            </StyledInputWrapper>
          </Box>
          {skillLevelSelectionPart}
        </CssForm>
      </StyledWrapper>
    );
  },
);
