import type React from "react";
import { Switch } from "react-router-dom";

import { ADMIN_ENVIRONMENT_ROUTES, AUTH_PAGES_ROUTES } from "constants/routes";
import { AuthRoute } from "layouts/AuthRoute";

import { AdminHeader } from "./components/AdminHeader/AdminHeader";
import { AdminNavigationMenu } from "./components/AdminNavigationMenu/AdminNavigationMenu";

import { NewUserPage } from "./subpages/NewUserPage/NewUserPage";
import { UserDetailsPage } from "./subpages/UserDetailsPage/UserDetailsPage";
import { UsersPage } from "./subpages/UsersPage/UsersPage";

import { SchoolDetailsPage } from "./subpages/SchoolDetailsPage/SchoolDetailsPage";
import { SchoolsPage } from "./subpages/SchoolsPage/SchoolsPage";

import { StyledAdminPage } from "./AdminPage.styled";
import { TeacherInstructions } from "./subpages/TeacherInstructions/TeacherInstructions";
import { UserScheduledTests } from "./subpages/UserScheduledTests/UserScheduledTests";
import { UserScheduledTestsGroup } from "./subpages/UserScheduledTestsGroup/UserScheduledTestsGroup";
import { UserToolsPage } from "./subpages/UserToolsPage/UserToolsPage";

export const AdminPage: React.FC = () => (
  <>
    <AdminHeader />
    <StyledAdminPage>
      <AdminNavigationMenu className="aside-menu" />
      <main>
        <Switch>
          <AuthRoute
            exact
            component={UsersPage}
            path={ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={NewUserPage}
            path={ADMIN_ENVIRONMENT_ROUTES.USER_CREATE_PAGE}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={UserDetailsPage}
            path={ADMIN_ENVIRONMENT_ROUTES.USER_DETAILS_PAGE}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={UserScheduledTests}
            path={ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={UserScheduledTestsGroup}
            path={ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS_GROUP}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={UserToolsPage}
            path={ADMIN_ENVIRONMENT_ROUTES.USER_TOOLS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={TeacherInstructions}
            path={ADMIN_ENVIRONMENT_ROUTES.TEACHER_INSTRUCTIONS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />

          <AuthRoute
            exact
            component={SchoolsPage}
            path={ADMIN_ENVIRONMENT_ROUTES.SCHOOL_LIST}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={SchoolDetailsPage}
            path={ADMIN_ENVIRONMENT_ROUTES.SCHOOL_DETAILS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={SchoolsPage}
            path={ADMIN_ENVIRONMENT_ROUTES.COMPANY_LIST}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={SchoolDetailsPage}
            path={ADMIN_ENVIRONMENT_ROUTES.COMPANY_DETAILS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
        </Switch>
      </main>
    </StyledAdminPage>
  </>
);
