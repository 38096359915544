import type React from "react";
import { useTranslation } from "react-i18next";

import { InfoMessage } from "components/InfoMessage/InfoMessage";

export const UnderConstructionMessage: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <InfoMessage icon="fas fa-forklift" title={t("underConstruction.title", "Under construction")}>
      {t("underConstruction.message", "This section is currently unavailable.")}
    </InfoMessage>
  );
};
