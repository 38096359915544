import searchIcon from "assets/icons/search.svg";
import { Input } from "components/Input";
import { Link } from "components/Link";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledSchoolsPage = styled.div`
  width: 70rem;
`;

export const StyledLink = styled(Link)`
  align-items: center;
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
  display: flex;
  width: fit-content;
`;

export const StyledInput = styled(Input)<ThemeProps>`
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._950};
  background-image: url(${searchIcon});
  background-position: left;
  background-position-x: ${calculateRem(15)};
  background-repeat: no-repeat;
  background-size: ${calculateRem(25)};
  border-radius: ${calculateRem(8)};
  height: ${calculateRem(48)};
  padding-left: ${calculateRem(50)};
`;
