import type React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { SvgIconReact } from "components/SvgIconReact";

import {
  StyledBreadcrumbs,
  StyledButton,
  StyledUserAdminBreadcrumbs,
} from "./UserAdminBreadcrumbs.styled";

interface UserAdminBreadcrumbsProps {
  previousText: string;
  previousAdminUrl: string;
  previousRoutePath: string;
  previousRoutePathLabel: string;
  currentPagePath: string;
  currentPageLabel: string;
}

export const UserAdminBreadcrumbs: React.FC<UserAdminBreadcrumbsProps> = ({
  previousText,
  previousAdminUrl,
  previousRoutePath,
  previousRoutePathLabel,
  currentPagePath,
  currentPageLabel,
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <StyledUserAdminBreadcrumbs>
      <StyledButton
        dataCy="previous-page-button"
        variant="default"
        onClick={() => history.push(`${previousAdminUrl}/${location.state.params}`)}
      >
        <SvgIconReact iconName="leftArrowIcon" />
        {previousText}
      </StyledButton>
      <StyledBreadcrumbs
        dataCy="admin-environment"
        routes={[
          {
            path: previousRoutePath,
            breadcrumb: previousRoutePathLabel,
          },
          {
            path: currentPagePath,
            breadcrumb: currentPageLabel,
          },
        ]}
        showAllRoutes={false}
      />
    </StyledUserAdminBreadcrumbs>
  );
};
