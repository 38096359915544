import styled, { css } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
  selected?: boolean;
  $nestingLevel: number;
}

export const StyledNavigationMenuItem = styled.nav<Props>`
  height: 3rem;
  // IDEA: add padding-right: 2rem; to account for big font mode
  padding-left: ${({ $nestingLevel }: Props) => ($nestingLevel > 1 ? `${3 + ($nestingLevel - 1) * 1.5}rem` : "3rem")};
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: ${(props: Props) => props.theme.font.size._16};

  ${(props: Props) =>
    props.selected &&
    css`
      font-weight: ${props.theme.font.weight.bold};
      border: solid 2px ${props.theme.palette.secondary.brand};
      color: ${props.theme.palette.secondary._400};
      background-color: ${props.theme.palette.secondary._950};
    `}

  &:hover {
    cursor: pointer;
    color: ${(props: Props) => props.theme.palette.secondary._400};
    background-color: ${(props: Props) => props.theme.palette.secondary._950};
  }
`;
