import type React from "react";

import { colorProgressLevel } from "_helpers/colorProgressLevel";
import { LevelDash } from "components/LevelDash";
import type { LevelDashGroupProps } from "./LevelDashGroup.model";
import { StyledLevelDashGroup, StyledLevelLabel } from "./LevelDashGroup.styled";

export const LevelDashGroup: React.FC<LevelDashGroupProps> = ({
  className,
  numberOfDashes,
  label,
  level,
  dashSize,
  isStateless = false,
}) => {
  const renderDashes = Array(numberOfDashes)
    .fill(null)
    .map((_, i) => i);
  const colorLevel = colorProgressLevel(level || 0);
  return (
    <StyledLevelDashGroup className={className} data-cy="progress-bar">
      <div className="level-dashes">
        {renderDashes.map((dash, i) => (
          <LevelDash
            key={dash}
            isStateless={isStateless}
            level={i >= colorLevel.index ? "default" : colorLevel.levelType}
            size={dashSize}
          />
        ))}
      </div>
      {label && <StyledLevelLabel>{label}</StyledLevelLabel>}
    </StyledLevelDashGroup>
  );
};
