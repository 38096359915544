import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

interface OutlinedProgressBarProps extends Props {
  $width: string;
}

export const StyledOutlinedProgressBar = styled.div<OutlinedProgressBarProps>`
  background-color: ${({ theme }: OutlinedProgressBarProps) => theme.palette.neutral._900};
  width: ${(props: OutlinedProgressBarProps) => props.$width};
  height: 0.5rem;
  border-radius: 1rem;
  margin-right: 1rem;
`;

export const StyledWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
`;

export const StyledPercentage = styled.span<Props>`
  font-size: ${({ theme }: Props) => theme.font.size._16};
`;
