import styled, { css } from "styled-components/macro";
import {
  type ColorProps,
  type FlexboxProps,
  type FontWeightProps,
  type PaddingProps,
  type SpaceProps,
  type TypographyProps,
  flexbox,
  space,
  typography,
} from "styled-system";
import type { Layout } from "styles/elements/ElementsInterface";

export const ParagraphLargeCss = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.normal};
  font-size: ${(props: Layout) => props.theme.font.size._16};
  line-height: ${(props: Layout) => props.theme.font.lineHeight._150};
  margin: 0;
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
`;

export const ParagraphLarge = styled.p<
  Layout & FontWeightProps & ColorProps & PaddingProps & TypographyProps & FlexboxProps & SpaceProps
>`
  ${ParagraphLargeCss}
  ${typography}
  ${flexbox}
  ${space}
`;
export const ParagraphMediumCss = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.normal};
  font-size: ${(props: Layout) => props.theme.font.size._12};
  line-height: ${(props: Layout) => props.theme.font.size._16};
  margin: 0;
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
`;

export const ParagraphMedium = styled.p<Layout & SpaceProps & TypographyProps>`
  ${ParagraphMediumCss}
  ${typography}
`;

export const ParagraphSmallCss = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.normal};
  font-size: ${(props: Layout) => props.theme.font.size._12};
  line-height: ${(props: Layout) => props.theme.font.size._16};
  margin: 0;
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
`;

export const ParagraphSmall = styled.p<Layout & SpaceProps & TypographyProps>`
  ${ParagraphSmallCss}
  ${typography}
`;

export const LabelCss = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.normal};
  font-size: ${(props: Layout) => props.theme.font.size._12};
  margin: 0;
  line-height: ${(props: Layout) => props.theme.font.lineHeight._150};
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
`;

export const Label = styled.p<Layout & SpaceProps & TypographyProps>`
  ${LabelCss}
  ${typography}
`;

export const ParagraphBanner = styled.p<Layout>`
  font-size: 1.1rem;
  letter-spacing: 0;
  line-height: 1.2rem;
  margin: 0.7rem 1rem 0 0;
`;
