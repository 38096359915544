import type { ThemeProps } from "models/styles/theme";
import { css } from "styled-components/macro";
import {
  Heading1Css,
  Heading2Css,
  Heading3Css,
  Heading4Css,
  Heading5Css,
  Heading6Css,
} from "./elements/Headings";
import { ParagraphLargeCss } from "./elements/Texts";

export const GlobalStylesCss = css`
  html {
    font-size: ${(props: ThemeProps) => props.theme.base};
  }
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  body {
    height: 100vh;
    background-color: ${(props: ThemeProps) => props.theme.palette.neutral.white};
    font-family: ${(props: ThemeProps) => props.theme.font.family.main};
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
    text-align: left;
    position: relative;
    margin: 0 auto;
  }

  #root {
    height: 100%;
  }

  h1 {
    ${Heading1Css};
  }
  h2 {
    ${Heading2Css};
  }
  h3 {
    ${Heading3Css};
  }
  h4 {
    ${Heading4Css};
  }
  h5 {
    ${Heading5Css};
  }
  h6 {
    ${Heading6Css};
  }

  p {
    ${ParagraphLargeCss};
  }

  .modal-tooltip {
    z-index: 1051 !important;
  }
`;
