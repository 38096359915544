import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

import { Modal } from "components/Modal";
import type { ModalProps } from "components/Modal/Modal.model";

import { Heading2 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledTooltipModal = styled(Modal)<ModalProps>`
  & > div {
    align-items: flex-start;
  }
`;

export const StyledHeading = styled(Heading2)`
  line-height: ${calculateRem(39)};
  text-align: left;
`;

export const StyledParagraph = styled(ParagraphLarge)<ThemeProps>`
  line-height: ${calculateRem(23)};
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  text-align: left;
`;
