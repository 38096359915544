import styled from "styled-components/macro";

import { AchievementToast } from "components/AchievementToast";
import { Button } from "components/Button";

export const StyledAchievementToast = styled(AchievementToast)`
  width: 45rem;
  margin-left: -22.5rem;
  align-items: flex-start;
`;

export const StyledButtonBar = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  padding: 0.5rem 1rem;
  font-weight: bold;
`;

export const StyledMessage = styled.section`
  > div {
    display: inline;
  }
`;
