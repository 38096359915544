import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";

import { useScheduledTests } from "hooks/useScheduledTests";

import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";

import type { ResultsBreadcrumbProps } from "./ResultsBreadcrumbs.model";
import { StyledBreadcrumbs } from "./ResultsBreadcrumbs.styled";

export const ResultsBreadcrumbs: React.FC<ResultsBreadcrumbProps> = observer(({ dataCy }) => {
  const { t } = useTranslation("test-environment");

  const { scheduledTestScore, scheduledTestExercise } = useScheduledTests();

  if (!scheduledTestScore || !scheduledTestExercise) {
    return null;
  }

  return (
    <StyledBreadcrumbs
      dataCy={dataCy}
      routes={[
        {
          path: TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PATH,
          breadcrumb: t("testResults.title"),
        },
        {
          path: TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PAGE,
          breadcrumb: scheduledTestScore.title,
        },
        {
          path: TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_RESULTS_PAGE,
          breadcrumb: scheduledTestExercise.title,
        },
      ]}
      showAllRoutes={false}
    />
  );
});
