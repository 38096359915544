import { Button } from "components/Button";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import styled from "styled-components/macro";
import { CssFlex } from "styles/helpers/layout";

import type { ThemeProps } from "models/styles/theme";
import { ParagraphMedium } from "styles/elements/Texts";

export const StyledButton = styled(Button)<ThemeProps>`
  width: 9rem;
  padding: 0.7rem 1rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold};
`;

export const StyledProgressMessage = styled(CssFlex)<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
`;

export const StyledDeterminationStatus = styled(ParagraphMedium)<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  color: ${(props: ThemeProps) => props.theme.palette.neutral._500};
  width: 14rem;
`;

export const StyledCompleteIcon = styled(FontAwesomeIcon)<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.palette.feedback.success._600};
  font-size: ${(props: ThemeProps) => props.theme.font.size._24};
  padding: 0.5rem 0;
  align-self: center;
`;
