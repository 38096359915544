import type React from "react";
import type InputProps from "./Input.model";
import { StyledInput, StyledInputPostFix, StyledInputWrapper } from "./Input.styled";

export const Input: React.FC<InputProps> = ({ dataCy, postfixLabel, ...props }) => (
  <StyledInputWrapper $hasError={props.hasError} $hasPostFix={!!postfixLabel}>
    <StyledInput
      $hasError={props.hasError}
      $hasPostFix={!!postfixLabel}
      data-cy={dataCy}
      {...props}
    />
    {postfixLabel && (
      <StyledInputPostFix $hasError={props.hasError}>
        <span>{postfixLabel}</span>
      </StyledInputPostFix>
    )}
  </StyledInputWrapper>
);

export default Input;
