import { isDev, isLocal, isTest } from "constants/env-constants";

export default (): string => {
  if (isTest()) return "test-school";
  if (isLocal()) return "test";
  const [schoolDomain] = window.location.hostname.split(".");
  if (isDev() && schoolDomain === "hogeschooltaal") {
    return "school";
  }
  return schoolDomain;
};
