import type React from "react";
import { useEffect, useMemo, useState } from "react";

import { productFlagIconNames as productIdsToFlagComponentNames } from "components/ProductFlag/ProductFlag";
import { SteppedForm } from "components/SteppedForm/SteppedForm";
import type { FormValues, ValidationError } from "../steps/FormStep.model";
import { TestDatesStep } from "../steps/TestDatesStep/TestDatesStep";
import { TestOptionsStep } from "../steps/TestOptionsStep/TestOptionsStep";
import { TestSubmitStep } from "../steps/TestSubmitStep/TestSubmitStep";
import { TestTitleStep } from "../steps/TestTitleStep/TestTitleStep";
import { TestTypeStep } from "../steps/TestTypeStep/TestTypeStep";
import type {
  ScheduledTestRequestFormProps,
  ScheduledTestRequestFormValues,
} from "./ScheduledTestRequestForm.model";

export const ScheduledTestRequestForm: React.FC<ScheduledTestRequestFormProps> = ({
  formValues,
  formErrors,
  submitButtonTitle,
  isSubmitting,
  isEditingScheduledTest = false,
  productsList,
  testGroupOptions = [],
  setFormValues,
  setFormErrors,
  onSubmit,
}) => {
  const [currentStep, setCurrentStep] = useState(1);

  const keyedFormErrors = useMemo(
    () =>
      formErrors.reduce((acc, error) => {
        if (!error.path) return acc;
        return {
          // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
          ...acc,
          [error.path]: error,
        };
      }, {}),
    [formErrors],
  );

  const productOptions = productsList?.map((product) => ({
    id: product.id,
    flagIconName: productIdsToFlagComponentNames.get(product.id) || "netherlandsFlag",
    title: product.title,
  }));

  useEffect(() => {
    // clear error messages on step change
    setFormErrors([]);
  }, [currentStep]);

  useEffect(() => {
    // when productId or testType selection changes, reset the selected testId (if any)
    if (formValues.testId) {
      const test = testGroupOptions?.find((testGroupOption) =>
        testGroupOption.tests.map((t) => t.id).includes(formValues.testId as number),
      );
      if (test) {
        // current selected test is present in the testGroupOptions array
        // so we can just leave the value as is
        return;
      }
    }
    setFormValues({
      ...formValues,
      testId: null,
    } as ScheduledTestRequestFormValues);
  }, [formValues.productId, formValues.testType]);

  const onFormUpdate = (values: FormValues, errors: ValidationError[]) => {
    setFormValues({
      ...formValues,
      ...values,
    } as ScheduledTestRequestFormValues);
    setFormErrors(errors);
  };

  const wizardSteps = [
    <TestTypeStep
      key="test-type-step"
      formErrors={keyedFormErrors}
      formValues={formValues}
      productOptions={productOptions ?? []}
      testGroupOptions={testGroupOptions ?? []}
      onFormUpdate={onFormUpdate}
    />,
    <TestTitleStep
      key="test-title-step"
      formErrors={keyedFormErrors}
      formValues={formValues}
      isDisabled={isSubmitting}
      testGroupOptions={testGroupOptions ?? []}
      onFormUpdate={onFormUpdate}
    />,
    <TestDatesStep
      key="test-dates-step"
      formErrors={keyedFormErrors}
      formValues={formValues}
      isDisabled={isSubmitting}
      isEditingScheduledTest={isEditingScheduledTest}
      onFormUpdate={onFormUpdate}
    />,
    <TestOptionsStep
      key="test-options-step"
      formErrors={keyedFormErrors}
      formValues={formValues}
      isDisabled={isSubmitting}
      isEditingScheduledTest={isEditingScheduledTest}
      onFormUpdate={onFormUpdate}
    />,
    <TestSubmitStep
      key="test-submit-step"
      formErrors={keyedFormErrors}
      formValues={formValues}
      isDisabled={isSubmitting}
      onFormUpdate={onFormUpdate}
    />,
  ];

  // Skip support message step if editing an existing test
  const steps = isEditingScheduledTest ? wizardSteps.slice(0, -1) : wizardSteps;

  return (
    <SteppedForm
      currentStep={currentStep}
      isLoading={isSubmitting}
      nextStepDisabled={
        formErrors.length > 0 || (currentStep === 1 && testGroupOptions.length === 0)
      }
      submitButtonTitle={submitButtonTitle}
      totalSteps={steps.length}
      onNextStep={setCurrentStep}
      onPrevStep={setCurrentStep}
      onSubmit={onSubmit}
    >
      {steps}
    </SteppedForm>
  );
};
