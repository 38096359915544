import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { useModal } from "hooks/useModal";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { TestType } from "constants/exam-constants";
import { modalIds } from "constants/modals-constants";

import { TEACHER_MONITOR_PAGE_ROUTES, TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from "constants/routes";
import { useScheduledTests } from "hooks/useScheduledTests";

import { BackButton } from "components/BackButton";
import { Button } from "components/Button";
import { SvgIconReact } from "components/SvgIconReact";

import { Heading2 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";

import type { ScheduledTestResultsReportPathParams } from "./TestResultsReport.model";
import {
  StyledCertificateButton,
  StyledFraudLogButton,
  StyledHeader,
} from "./TestResultsReport.styled";

import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useSchoolyear } from "hooks/useSchoolyear";
import { TeacherPageLayout } from "../components/TeacherPageLayout/TeacherPageLayout";
import { EditReviewPeriodModal } from "./components/EditReviewPeriodModal/EditReviewPeriodModal";
import { ExerciseSummary } from "./components/ExerciseSummary/ExerciseSummary";
import { ReportSettingsModal } from "./components/ReportSettingsModal/ReportSettingsModal";
import { StudentScoreTable } from "./components/StudentScoreTable/StudentScoreTable";
import { TestFeatureBox } from "./components/TestFeatureBox/TestFeatureBox";

export const ScheduledTestResultsDetails: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation("teacher-dashboard");

  const { toggleModal } = useModal();
  const {
    loading: isLoading,
    scheduledTest,
    ownedScheduledTestResults: scheduledTestResults,
    fetchScheduledTest,
    fetchOwnedScheduledTestResults,
    downloadScheduledTestResultsReport,
    updateStudentScheduledTestsResultsCertificate,
  } = useScheduledTests();
  const { enableSchoolyearIntegration } = useFeatureFlags();
  const { schoolyearDashboard, fetchSchoolyearDashboard } = useSchoolyear();

  const { scheduledTestId }: ScheduledTestResultsReportPathParams = useParams();

  useEffect(() => {
    if (!scheduledTest || scheduledTest.id !== +scheduledTestId) {
      fetchScheduledTest(+scheduledTestId);
      fetchOwnedScheduledTestResults(+scheduledTestId);
    }
  }, [scheduledTestId, scheduledTest]);

  useEffect(() => {
    if (enableSchoolyearIntegration && scheduledTest?.schoolyearEnabled) {
      fetchSchoolyearDashboard(scheduledTest.id);
    }
  }, [enableSchoolyearIntegration, scheduledTest, fetchSchoolyearDashboard]);

  // Show modal only if test is cumulative, otherwise trigger download
  const onDownloadReportClick = () => {
    if (scheduledTest?.cumulative) {
      toggleModal(modalIds.scheduledTestResultsReportModal);
    } else {
      downloadScheduledTestResultsReport(+scheduledTestId);
    }
  };

  const onVisitFraudLogsClick = () => {
    if (scheduledTest) {
      history.push(
        buildUrlWithPathParams(TEACHER_MONITOR_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR, {
          scheduledTestId: scheduledTest.id,
        }),
      );
    }
  };

  const handleAssignCertificates = () => {
    updateStudentScheduledTestsResultsCertificate(+scheduledTestId).then(() => {
      // Refresh results data, as the `hasCertificate` field may have changed after this action
      fetchOwnedScheduledTestResults(+scheduledTestId);
    });
  };

  if (!scheduledTest) {
    return null;
  }

  return (
    <TeacherPageLayout>
      <BackButton
        dataCy="back-to-overview-button"
        label={t(
          "ownedScheduledTestResults.details.buttons.backToOverview.label",
          "Back to overview",
        )}
        redirectUrl={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_OVERVIEW}
      />
      <StyledHeader>
        <div>
          <Heading2>{scheduledTest.title}</Heading2>
          <Button
            hasIconRight
            dataCy="download-report-button"
            disabled={isLoading}
            isLoading={isLoading}
            variant="secondary"
            onClick={onDownloadReportClick}
          >
            <span>
              {t(
                "ownedScheduledTestResults.details.buttons.downloadReport.label",
                "Download report",
              )}
            </span>
            <SvgIconReact iconName="downloadIcon" />
          </Button>
        </div>
        <CssFlex alignItems="center" gap={0.5}>
          <StyledFraudLogButton
            hasIconRight
            dataCy="view-fraud-logs-button"
            disabled={isLoading}
            isLoading={isLoading}
            variant="border"
            onClick={onVisitFraudLogsClick}
          >
            <span>
              {t("ownedScheduledTestResults.details.buttons.fraudLogs.label", "To fraudlogs")}
            </span>
          </StyledFraudLogButton>

          {/* Show certificate button only for summative tests created by the current user */}
          {scheduledTest?.ownedBySelf && scheduledTest.testType === TestType.SUMMATIVE_TEST && (
            <StyledCertificateButton
              dataCy="assign-certificates-button"
              disabled={isLoading}
              isLoading={isLoading}
              variant="primary"
              onClick={() => handleAssignCertificates()}
            >
              {t(
                "ownedScheduledTestResults.details.buttons.AssignCertificates.label",
                "Assign Certificates",
              )}
            </StyledCertificateButton>
          )}
        </CssFlex>
      </StyledHeader>
      {scheduledTestResults && <ExerciseSummary exerciseResults={scheduledTestResults.exercises} />}
      <TestFeatureBox scheduledTest={scheduledTest} schoolyearDashboard={schoolyearDashboard} />
      {scheduledTestResults && (
        <StudentScoreTable
          exerciseList={scheduledTestResults.exercises}
          passingScore={scheduledTest.passingScore}
          userScheduledTestResults={scheduledTestResults.students}
        />
      )}
      <ReportSettingsModal scheduledTestId={+scheduledTestId} />
      <EditReviewPeriodModal scheduledTest={scheduledTest} />
    </TeacherPageLayout>
  );
});
