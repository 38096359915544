import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { modalIds } from "constants/modals-constants";
import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useModal } from "hooks/useModal";

import { ActionMessageModal } from "components/ActionMessage/ActionMessageModal";
import { SanitizedHtml } from "components/SanitizedHtml";

export const MyCorrectTextQuestionMessageModal: React.FC = () => {
  const { t } = useTranslation("modal");
  const { toggleModal } = useModal();

  const { currentTopic } = useAdaptivePractice();

  const modalId = modalIds.correctTextQuestionModal;

  const onActionButtonClick = () => {
    toggleModal(modalId);
  };

  return (
    <ActionMessageModal
      actionButtonClick={onActionButtonClick}
      actionButtonLabel={t("correctTextQuestionModal.button.label")}
      message={
        <Trans
          components={{
            chapterTitleRenderer: (
              <SanitizedHtml
                dataCy="chapter-title"
                dirtyHtml={currentTopic?.chapter.title || ""}
                tagName="b"
              />
            ),
          }}
          i18nKey={"correctTextQuestionModal.content"}
          t={t}
        />
      }
      modalId={modalId}
      title={t("correctTextQuestionModal.title")}
    />
  );
};

export const CorrectTextQuestionMessageModal = React.memo(MyCorrectTextQuestionMessageModal);
