import { Button } from "components/Button";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

export const StyledFilterButtonContainer = styled.div<ThemeProps>`
  margin: 2rem 0;
  text-align: right;
`;

export const StyledFilterButton = styled(Button)<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: bold;

  border-radius: 0;

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
