import type { AccessibilityTextSize } from "components/AccessibilityMenuModal/AccessibilityMenuModal";
import type React from "react";
import { Button, StyledParagraph, ToggleContainer } from "./MultipleOptionToggle.styled";

interface MultipleOptionToggleProps {
  activeButton: string;
  onClick: (textSize: AccessibilityTextSize) => void;
}

const MultipleOptionToggle: React.FC<MultipleOptionToggleProps> = ({ activeButton, onClick }) => {
  return (
    <ToggleContainer data-active={activeButton} data-cy="multiple-option-selector">
      {/* <Button active={activeButton === 'small'} data-cy="text-size-selector" onClick={() => onClick('small')}>
        <StyledParagraph fontSize={'1rem'} fontWeight={400}>
          T
        </StyledParagraph>
      </Button> */}
      <Button
        active={activeButton === "medium"}
        data-cy="text-size-selector"
        onClick={() => onClick("medium")}
      >
        <StyledParagraph fontSize={"1.0rem"} fontWeight={600}>
          T
        </StyledParagraph>
      </Button>
      <Button
        active={activeButton === "large"}
        data-cy="text-size-selector"
        onClick={() => onClick("large")}
      >
        <StyledParagraph fontSize={"1.5rem"} fontWeight={1000}>
          T
        </StyledParagraph>
      </Button>
    </ToggleContainer>
  );
};

export default MultipleOptionToggle;
