import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathAndQueryParams, buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { LoadingSpinner } from "components/LoadingSpinner/LoadingSpinner";
import { QuestionStatus, TestType } from "constants/exam-constants";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";
import {
  type MixedExerciseForSubject,
  type MixedExerciseForTopic,
  useAdaptivePractice,
} from "hooks/useAdaptivePractice";
import { useAdaptivePracticeHelper } from "hooks/useAdaptivePracticeHelper";
import { type EvaluateAnswerParams, useAnswer } from "hooks/useAnswer";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import { useProfile } from "hooks/useProfile";
import { useQueryParam } from "hooks/useQueryParam";
import type { ExamQuestion } from "models/exam/Exam";
import { NormalTopicProgressionStatus } from "models/progress/Progress";
import { CssFlex } from "styles/helpers/layout";
import type { AdaptiveTestPageParams } from "./AdaptiveTestPage.model";
import { StyledEmptyExamQuestionsMessage, StyledPageWrapper } from "./AdaptiveTestPage.styled";
import { CongratulationsMessage } from "./components/CongratulationsMessage/CongratulationsMessage";
import { AdaptiveTestHeader } from "./components/ImprovementTestHeader";
import { SkillLevelChangeMessage } from "./components/SkillLevelChangeMessage/SkillLevelChangeMessage";
import { AdaptiveTestContent } from "./components/TestContent/AdaptiveTestContent";

export const AdaptiveTestPage: React.FC = observer(() => {
  const history = useHistory();

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [currentQuestion, setCurrentQuestion] = useState<ExamQuestion>();
  const [showContinueButton, setShowContinueButton] = useState<boolean>(false);

  const {
    adaptiveTestDetails,
    currentTopic,
    selectedQuestion,
    currentMixedExerciseStats,
    fetchAdaptivePracticeQuestion,
    congratulationsMessage,
    previousTopic,
    fetchMixedExercise,
    isMixedExerciseActive,
    failedToLoadQuestion,
    hasSwappedTopic,
    subjectCompleted,
    nextTopicId,
    setCongratulationsMessage,
    setMixedExercise,
    setCurrentMixedExerciseStats,
    resetCurrentMixedExerciseStats,
    nextQuestionIsReady,
    reset,
    setNextQuestionIsReady,
    setSelectedQuestion,
    checkIfNextQuestionIsReady,
    setPreviousTopic,
    addSessionQuestionResult,
    updateSessionStreak,
    resetAdaptivePracticeSessionResults,
    setStartedSessionTimestamp,
    setAdaptivePracticeDetails,
  } = useAdaptivePractice();

  const { evaluateAnswer, setQuestionStatus, setQuestionResult } = useAnswer();
  const { moduleDetails, fetchModuleDetails } = useProduct();
  const {
    onAllNormalTopicsCompleted,
    onChapterCompleted,
    onSelectedTopicAlreadyCompleted,
    onTopicCompleted,
  } = useAdaptivePracticeHelper();

  const { productId, subjectId, moduleId }: AdaptiveTestPageParams = useParams();
  const topicId = useQueryParam("topicId");

  const {
    productDetails,
    topicDetails,
    productSubject,
    nonOwnedProductError,
    fetchProductDetails,
    fetchTopicDetails,
    fetchProductSubjectDetails,
  } = useProduct();
  const { productGoalLevel, moduleSubjectsProgress, fetchModuleProgress, fetchProductGoalLevel } =
    useProductProgress();
  const { userDetails, fetchUserDetails } = useProfile();

  useEffect(() => {
    resetAdaptivePracticeSessionResults();
    fetchUserDetails();
    setQuestionStatus(QuestionStatus.INITIAL);
    setQuestionResult(null);
    setStartedSessionTimestamp(new Date().getTime());

    if (moduleDetails?.id !== +moduleId) {
      fetchModuleDetails(+productId, +moduleId);
    }

    return () => {
      reset();
      setAdaptivePracticeDetails(null);
    };
  }, []);

  useEffect(() => {
    if (productDetails?.id !== +productId) {
      fetchProductDetails(+productId);
    }
  }, [productDetails, productId]);

  // Reset stats when starting a test for a different subject
  useEffect(() => {
    resetCurrentMixedExerciseStats();
  }, [subjectId, topicId]);

  useEffect(() => {
    if (!!productId && !!currentTopic?.id) {
      fetchTopicDetails(+productId, currentTopic?.id);
    }
  }, [productId, currentTopic?.id]);

  useEffect(() => {
    if (productSubject?.moduleId && productId) {
      fetchModuleProgress(+productId, productSubject.moduleId);
    }
  }, [productId, productSubject?.moduleId]);

  useEffect(() => {
    if (productId && (!productGoalLevel || +productId !== +productGoalLevel.productId)) {
      fetchProductGoalLevel(+productId);
    }
  }, [productId]);

  useEffect(() => {
    if (productId && subjectId) {
      fetchProductSubjectDetails(+productId, +subjectId);
    }
  }, [productId, subjectId]);

  useEffect(() => {
    if (nextQuestionIsReady && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [nextQuestionIsReady]);

  const onEvaluateAnswer = (params: EvaluateAnswerParams) => {
    const { answeredQuestions, correctQuestions, failedQuestions } = currentMixedExerciseStats;

    evaluateAnswer(params).then((res) => {
      if (!res) return;

      if (res.questionResult.isAnswerCorrect !== null) {
        updateSessionStreak(res.questionResult.isAnswerCorrect);
        if (currentTopic) {
          addSessionQuestionResult(
            params.questionId,
            res.questionResult.isAnswerCorrect,
            currentTopic.id,
          );
        }
      }

      const { questionResult, results, status } = res;

      setSelectedQuestion({
        ...selectedQuestion,
        results,
        status,
      });

      if (isMixedExerciseActive) {
        setCurrentMixedExerciseStats({
          answeredQuestions: answeredQuestions + 1,
          correctQuestions: questionResult.isAnswerCorrect
            ? correctQuestions + 1
            : correctQuestions,
          failedQuestions: questionResult.isAnswerCorrect ? failedQuestions : failedQuestions + 1,
        });
      } else {
        const generatedIntervalId = setInterval(() => checkIfNextQuestionIsReady(), 1000);
        setIntervalId(generatedIntervalId);
      }
    });
  };

  const loadNextQuestion = (skipTopicId?: boolean) => {
    setShowContinueButton(false);
    setNextQuestionIsReady(false);

    if (isMixedExerciseActive) {
      const studentHasClickedOnSubject = !topicId;
      const mixedExerciseParams = studentHasClickedOnSubject
        ? ({ productId: +productId, subjectId: +subjectId } as MixedExerciseForSubject)
        : ({
            productId: +productId,
            subjectId: +subjectId,
            topicId: +topicId,
          } as MixedExerciseForTopic);
      fetchMixedExercise(mixedExerciseParams);
    } else {
      fetchAdaptivePracticeQuestion(
        +productId,
        +subjectId,
        skipTopicId || !topicId ? undefined : (nextTopicId ?? +topicId ?? undefined),
      );
    }
  };

  const onContinueTopic = (alterRoute: boolean) => {
    setCongratulationsMessage("none");
    setPreviousTopic(null);
    setShowContinueButton(false);
    setNextQuestionIsReady(false);

    if (alterRoute) {
      history.push(
        buildUrlWithPathParams(TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST, {
          productId,
          moduleId,
          subjectId,
        }),
      );
      loadNextQuestion(true);
    }
  };

  const onContinueMixedExercises = () => {
    resetCurrentMixedExerciseStats();
    setMixedExercise(true);
    setCongratulationsMessage("none");

    // if subject is completed, we want to practice subject-wide
    history.push(
      buildUrlWithPathAndQueryParams(
        TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
        { productId, moduleId, subjectId },
        !subjectCompleted && previousTopic ? { topicId: previousTopic.id } : {},
      ),
    );
  };

  useEffect(() => {
    loadNextQuestion(topicId === null);
  }, [topicId]);

  useEffect(() => {
    if (nextTopicId) {
      history.push(
        buildUrlWithPathAndQueryParams(
          TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
          { productId, moduleId, subjectId },
          { topicId: nextTopicId },
        ),
      );
    }
  }, [nextTopicId]);

  useEffect(() => {
    isMixedExerciseActive && loadNextQuestion();
  }, [isMixedExerciseActive]);

  useEffect(() => {
    if (topicId && failedToLoadQuestion && moduleSubjectsProgress.length > 0) {
      if (hasSwappedTopic) {
        fetchAdaptivePracticeQuestion(+productId, +subjectId, +topicId);
        return;
      }

      const allTopics = moduleSubjectsProgress
        .flatMap((x) => x.normalTopics)
        .concat(moduleSubjectsProgress.flatMap((x) => x.themeAssignments));
      const activeTopic = allTopics.find((x) => x.id === +topicId);
      if (activeTopic?.state === NormalTopicProgressionStatus.COMPLETED) {
        onSelectedTopicAlreadyCompleted();
        return;
      }

      if (
        moduleDetails?.subjects
          .flatMap((x) => x.chapters)
          ?.flatMap((x) => x.topics)
          .find((x) => x.id === nextTopicId)?.isTrialTopic &&
        nextTopicId
      ) {
        onTopicCompleted(nextTopicId);
        onAllNormalTopicsCompleted();
        return;
      }

      nextTopicId ? onTopicCompleted(nextTopicId) : onChapterCompleted();
    }
  }, [failedToLoadQuestion, nextTopicId, moduleSubjectsProgress]);

  if (!Number(productId) || nonOwnedProductError) {
    return <Redirect to={PRODUCT_PAGES_ROUTES.PRODUCT_LIST} />;
  }

  const examQuestions = adaptiveTestDetails?.questions ? adaptiveTestDetails.questions : [];
  const examQuestionsIsEmpty = examQuestions.length === 0;

  return (
    <StyledPageWrapper
      content={
        congratulationsMessage !== "none" ? (
          <CongratulationsMessage
            currentTopicTitle={previousTopic?.title}
            nextTopicTitle={currentTopic?.title}
            productGoalLevel={productGoalLevel?.goalLevel}
            userName={userDetails?.firstName}
            onContinueMixedExercises={onContinueMixedExercises}
            onContinueTopic={onContinueTopic}
          />
        ) : (
          <>
            {!adaptiveTestDetails || failedToLoadQuestion ? (
              <CssFlex justifyContent="center">
                <LoadingSpinner />
              </CssFlex>
            ) : examQuestionsIsEmpty ? (
              <StyledEmptyExamQuestionsMessage />
            ) : (
              <>
                <SkillLevelChangeMessage />
                <AdaptiveTestContent
                  evaluateAnswer={(productId, questionId, answers, skipAnswerValidation) =>
                    onEvaluateAnswer({
                      productId,
                      questionId,
                      answers,
                      skipAnswerValidation,
                      testType: isMixedExerciseActive
                        ? TestType.MIXED_EXERCISE_TEST
                        : TestType.ADAPTIVE_PRACTICE_TEST,
                      testToken: adaptiveTestDetails.token,
                    })
                  }
                  setShowContinueButton={(val) => setShowContinueButton(val)}
                  showContinueButton={showContinueButton}
                  onSelectedQuestionChanged={(q) => setCurrentQuestion(q)}
                />
              </>
            )}
          </>
        )
      }
      header={<AdaptiveTestHeader />}
      loadNextQuestion={loadNextQuestion}
      selectedQuestion={currentQuestion}
      testType={
        isMixedExerciseActive ? TestType.MIXED_EXERCISE_TEST : TestType.ADAPTIVE_PRACTICE_TEST
      }
      topicDetails={topicDetails}
    />
  );
});
