import i18n from "i18n";
import { action, observable } from "mobx";

import cookieStorage, { type ROLE } from "persistence";
import { profileRepository } from "repositories";

import type { Education, RegistrationDetails } from "models/auth/Login";
import type {
  StudentAccountUpdate,
  Survey,
  UpdatableUserFields,
  User,
  UserByEducations,
  UserUpdate,
} from "models/user/User";

import { ToastMethods } from "components/ToastNotification";
import { getAPM } from "observability";

interface ProfileInterface {
  isLoading: boolean;
  userDetails?: User;
  userEducations?: Education[];
  isRegistrationComplete: boolean | null;
  usersByEducations: UserByEducations[];
  survey: Survey | null;

  setUserDetails: (userDetails?: User) => void;
  setUserEducations: (educations: Education[]) => void;
  setIsLoading: (val: boolean) => void;
  setIsRegistrationComplete: (ready: boolean | null) => void;
  setUsersByEducations: (users: UserByEducations[]) => void;
  setSurvey: (survey: Survey | null) => void;

  fetchUserDetails: () => void;
  updateUserDetails: (userDetails: UserUpdate) => void;
  updateStudentDetails: (studentAccountId: string, userDetails: StudentAccountUpdate) => void;
  updateHogesUserProfile: (userDetails: UpdatableUserFields) => void;
  fetchUserEducations: () => void;
  fetchRegistrationDetails: (email: string) => void;
  fetchUsersByEducationsForTeacher: (searchTerm?: string, roles?: ROLE[]) => void;
  fetchSurvey: () => void;
  submitSurvey: () => void;
}

const store = observable({
  isLoading: false,
  isRegistrationComplete: null,
  survey: null,

  setIsLoading: action((val: boolean) => {
    store.isLoading = val;
  }),

  setUserDetails: action((userDetails?: User) => {
    store.userDetails = userDetails;
  }),

  setIsRegistrationComplete: action((ready: boolean) => {
    store.isRegistrationComplete = ready;
  }),

  setUserEducations: action((educations: Education[]) => {
    store.userEducations = educations;
  }),

  setUsersByEducations: action((users: UserByEducations[]) => {
    store.usersByEducations = users;
  }),

  setSurvey: action((survey: Survey | null) => {
    store.survey = survey;
  }),

  fetchUserDetails: action(() => {
    profileRepository
      .getUserAccountDetails()
      .then((currentUser: User) => {
        store.setUserDetails(currentUser);

        const apm = getAPM();
        apm?.setUserContext({
          id: currentUser.id,
          email: currentUser.email,
        });

        // Override current language by the one specified in the user profile
        if (i18n.language !== currentUser.language) {
          i18n.changeLanguage(currentUser.language);
        }
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:profile.userdetail.error"), "error");
      });
  }),

  updateUserDetails: action((userDetails: UserUpdate) => {
    profileRepository
      .updateUserAccountDetails(userDetails)
      .then(() => {
        ToastMethods.showToast(i18n.t("toast:account.success.updated"), "success");
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:account.error.updated"), "error");
      });
  }),

  // IDEA: This method is for a teacher editing a student's details. It could be moved to a separate file.
  updateStudentDetails: action((studentAccountId: string, userDetails: StudentAccountUpdate) => {
    profileRepository
      .updateStudentAccountDetails(studentAccountId, userDetails)
      .then(() => {
        ToastMethods.showToast(i18n.t("toast:account.success.updated"), "success");
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:account.error.updated"), "error");
      });
  }),

  updateHogesUserProfile: action((userDetails: UpdatableUserFields) => {
    const isUserLoggedIn = !!cookieStorage.getToken();
    if (isUserLoggedIn) {
      profileRepository
        .updateHogesUserAccountDetails(userDetails)
        .then(() => {
          ToastMethods.showToast(i18n.t("toast:account.success.updated"), "success");

          // Refresh user data after update
          store.fetchUserDetails();
        })
        .catch(() => {
          ToastMethods.showToast(i18n.t("toast:account.error.updated"), "error");
        });
    }
    // Override current language by the one specified in the user profile
    if (!isUserLoggedIn && i18n.language !== userDetails.language) {
      i18n.changeLanguage(userDetails.language);
    }
  }),

  fetchUserEducations: action(() => {
    store.setIsLoading(true);
    profileRepository
      .getUserEducations()
      .then((educations: Education[]) => {
        store.setUserEducations(educations);
        store.setIsLoading(false);
      })
      .catch(() => {
        store.setUserEducations([]);
        ToastMethods.showToast(i18n.t("toast:profile.getUserEducations.error"), "error");
      });
  }),

  fetchRegistrationDetails: action((email: string) => {
    profileRepository
      .getRegistrationDetails(email)
      .then(({ ready }: RegistrationDetails) => {
        store.setIsRegistrationComplete(ready);
      })
      .catch(() => {
        store.setIsRegistrationComplete(null);
        ToastMethods.showToast(i18n.t("toast:profile.getUserEducations.error"), "error");
      });
  }),

  fetchUsersByEducationsForTeacher: action((searchTerm?: string, roles?: ROLE[]) => {
    store.setIsLoading(true);
    profileRepository
      .getUsersByEducationsForTeacher(searchTerm, roles)
      .then((users: UserByEducations[]) => {
        store.setUsersByEducations(users);
        store.setIsLoading(false);
      })
      .catch(() => {
        store.setUsersByEducations([]);
        ToastMethods.showToast(
          i18n.t("toast:profile.getUsersByEducationsForTeacher.error"),
          "error",
        );
      });
  }),

  fetchSurvey: action(() => {
    store.setIsLoading(true);
    profileRepository
      .fetchSurvey()
      .then((survey: Survey) => {
        store.setSurvey(survey);
        store.setIsLoading(false);
      })
      .catch(() => {
        store.setSurvey(null);
        ToastMethods.showToast(i18n.t("toast:profile.fetchCanDoSurvey.error"), "error");
      });
  }),

  submitSurvey: action(() => {
    store.setIsLoading(true);
    profileRepository
      .submitSurvey()
      .then(() => {
        store.setIsLoading(false);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:profile.fetchCanDoSurvey.error"), "error");
      });
  }),
} as ProfileInterface);

export const useProfile = (): ProfileInterface => store;
