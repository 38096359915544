import type { Option } from "components/OptionsDropdown/OptionsDropdown.model";
import i18n from "i18next";

export enum ProctoringOption {
  FOCUS = "focus",
  ONLINE = "online",
  FULLSCREEN = "fullscreen",
  OTHER_APPS_CLOSED = "other_apps_closed",
  SINGLE_SCREEN = "single_screen",
  WEBCAM_ENABLED = "webcam",
  FACE_DETECTION = "face_detection",
  SCREENSHOTS = "screenshots",
}

export type ProctoringSelectOption = Option & {
  value: ProctoringOption;
};

export const getDefaultProctoringOptions = (): readonly ProctoringSelectOption[] => [
  {
    label: i18n.t("proctoring-options:focus.label", "Programma is gefocussed"),
    value: ProctoringOption.FOCUS,
    isChecked: true,
    isDisabled: true,
  },
  {
    label: i18n.t("proctoring-options:online.label", "Online"),
    value: ProctoringOption.ONLINE,
    isChecked: true,
    isDisabled: true,
  },
  {
    label: i18n.t("proctoring-options:fullscreen.label", "Fullscreen"),
    value: ProctoringOption.FULLSCREEN,
    isChecked: true,
    isDisabled: true,
  },
  {
    label: i18n.t("proctoring-options:otherApps.label", "Andere programma's gesloten"),
    value: ProctoringOption.OTHER_APPS_CLOSED,
    isChecked: true,
    isDisabled: false,
  },
  {
    label: i18n.t("proctoring-options:screen.label", "Max. 1 beeldscherm"),
    value: ProctoringOption.SINGLE_SCREEN,
    isChecked: false,
    isDisabled: false,
  },
  {
    label: i18n.t("proctoring-options:webcam.label", "Webcam aan (+ foto's gelogd)"),
    value: ProctoringOption.WEBCAM_ENABLED,
    isChecked: false,
    isDisabled: false,
  },
  {
    label: i18n.t(
      "proctoring-options:faceDetection.label",
      "Gezichtsdetectie (1 persoon in beeld)",
    ),
    value: ProctoringOption.FACE_DETECTION,
    isChecked: false,
    isDisabled: false,
  },
  {
    label: i18n.t("proctoring-options:screenshots.label", "Screenshots worden gelogd"),
    value: ProctoringOption.SCREENSHOTS,
    isChecked: false,
    isDisabled: false,
  },
];

export const getDefaultProctoringOptionsState = (
  initialState: readonly ProctoringOption[],
): readonly ProctoringSelectOption[] => {
  if (!initialState) {
    // no values set, use default
    return getDefaultProctoringOptions();
  }
  return getDefaultProctoringOptions().map((option) => {
    if (initialState.includes(option.value)) {
      // proctoring key found in values,
      // set property to true
      return {
        ...option,
        isChecked: true,
      };
    }
    if (!option.isDisabled) {
      // proctoring key not in values, uncheck
      // except when it's disabled (= always on and required)
      return {
        ...option,
        isChecked: false,
      };
    }
    return option;
  });
};
