import { AccordionItem } from "components/AccordionItem";
import { Link } from "components/Link";
import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledDiagnosticTestRowItem = styled(Link)<Props>`
  width: 100%;
`;
export const StyledAccordionDiagnosticListItem = styled(AccordionItem)<Props>`
  .tab-label::before {
    content: none;
  }
`;

export const StyledPanelRightHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  width: 18rem;
  justify-content: space-between;
`;
export const StyledStatusInfo = styled.div<Props>`
  width: 100%;
`;
