import { observer } from "mobx-react";
import type React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useProduct } from "hooks/useProduct";
import { useProfile } from "hooks/useProfile";
import { useRandomFact } from "hooks/useRandomFact";
import { useSelectedProductId } from "hooks/useSelectedProductId";
import { useWriting } from "hooks/useWriting";

import type { ProgressAPI, ResultsAPI } from "generated/types";
import { ModuleType, type ProductModules } from "models/product/ProductDetails";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { ProductType } from "constants/hst-constants";
import { PRODUCT_PAGES_ROUTES, TEST_ENVIRONMENT_ROUTES } from "constants/routes";

import { Heading1, Heading3, Heading4 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import goToTestImg from "assets/images/go-to-test-image-person.svg";
import { Button } from "components/Button";
import { useProductProgress } from "hooks/useProductProgress";
import { useRedirect } from "hooks/useRedirect";
import { ParagraphLarge } from "styles/elements/Texts";
import { StyledMainSection, StyledSelect, StyledTestEnvCard } from "./StudentDashboardPage.styled";
import { ModuleCard } from "./components/ModuleCard/ModuleCard";

export const StudentDashboardPage: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation("homepage");

  const randomFact = useRandomFact();
  const { userDetails } = useProfile();
  const selectedProductId = useSelectedProductId();
  const { productDetails, productsList } = useProduct();
  const { redirectToUrl } = useRedirect();
  const { lastPracticedAdaptiveTopicStatuses, fetchLastPracticedAdaptiveTopicStatuses } =
    useAdaptivePractice();
  const { lastPracticedWritingTopicStatuses, fetchLastPracticedWritingTopicStatuses } =
    useWriting();
  const { fetchProductGoalLevel, productGoalLevel } = useProductProgress();
  const productSelectorOptions = useMemo(
    () => productsList?.map(({ id, title }) => ({ label: title, value: id })) || [],
    [productsList],
  );

  // Obtain object with current goal level
  const currentGoalLevel = useMemo(() => {
    if (!productGoalLevel?.goalLevel || !productDetails) {
      return null;
    }

    return productDetails.levels.find(({ level }) => level === productGoalLevel.goalLevel);
  }, [productGoalLevel, productDetails]);

  useEffect(() => {
    if (selectedProductId) {
      fetchLastPracticedAdaptiveTopicStatuses(selectedProductId);
      fetchLastPracticedWritingTopicStatuses(selectedProductId);
      fetchProductGoalLevel(selectedProductId);
    }
  }, [selectedProductId]);

  const getLastPracticedTopicForModule = useCallback(
    (
      module: ProductModules,
    ):
      | ResultsAPI.LastPracticedWritingTopicStatus
      | ProgressAPI.LastPracticedAdaptiveTopicStatus
      | null => {
      switch (module.type) {
        case ModuleType.ADAPTIVE:
          return (
            lastPracticedAdaptiveTopicStatuses?.find(({ moduleId }) => moduleId === module.id) ||
            null
          );
        case ModuleType.WRITING:
          return (
            lastPracticedWritingTopicStatuses?.find(({ moduleId }) => moduleId === module.id) ||
            null
          );
        default:
          return null;
      }
    },
    [lastPracticedAdaptiveTopicStatuses, lastPracticedWritingTopicStatuses],
  );

  const onSelectProductId = (productId: number) => {
    history.push(buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, { productId }));
  };

  const handleClick = () => {
    trackPlatformEvent({
      module: STUDENT_MODULES.DASHBOARD,
      itemId: PLATFORM_EVENT_TYPES.STUDENT_DASHBOARD_EVENT_TYPES.CLICK_GO_TEST_ENVIRONMENT,
      elementId: "test-env-button-dashboard",
    });
    redirectToUrl(TEST_ENVIRONMENT_ROUTES.LANDING_PAGE);
  };
  return (
    <StyledMainSection flexDirection="column" marginLeft="2rem" rowGap={3}>
      <section>
        <Heading1 marginBottom="2.5rem">
          {t("studentDashboard.welcomeMessage", { userName: userDetails?.firstName })}
        </Heading1>
        <ParagraphLarge data-cy="random-fact">{randomFact}</ParagraphLarge>
      </section>
      {/* -- Product select section -- */}
      {productSelectorOptions.length > 1 && (
        <section>
          <Heading3 id="student-dashboard-product-selector-label" marginBottom="2rem">
            {t("studentDashboard.productSelector.label")}
          </Heading3>
          <StyledSelect
            aria-labelledby="student-dashboard-product-selector-label"
            dataCy="product-selector"
            options={productSelectorOptions}
            placeholder={t("studentDashboard.productSelector.placeholder")}
            showPlaceholderInOptionList={false}
            value={selectedProductId?.toString()}
            onChange={(event) => onSelectProductId(+event.target.value)}
          />
        </section>
      )}
      {/* -- Module cards section -- */}
      {selectedProductId && productDetails?.id === selectedProductId && (
        <section>
          <Heading3 marginBottom="2.5rem">{t("studentDashboard.continueSection.title")}</Heading3>
          <CssFlex flexDirection="row" flexWrap="wrap" gap={2}>
            {productDetails?.modules.map((module) => (
              <ModuleCard
                key={module.id}
                currentGoalLevel={currentGoalLevel}
                lastPracticedTopicStatus={getLastPracticedTopicForModule(module)}
                moduleDetails={module}
                productId={selectedProductId}
              />
            ))}
          </CssFlex>
        </section>
      )}
      {/* -- Shortcut to test environment (for regular products only) -- */}
      {productDetails?.type === ProductType.REGULAR && (
        <StyledTestEnvCard>
          <Heading4>
            {t("studentDashboard.goToTest.cardTitle", { name: userDetails?.firstName })}
          </Heading4>
          <img alt={t("studentDashboard.goToTest.imageAlt")} src={goToTestImg} />
          <Button dataCy="dashboard-test-env-button" variant="primary" onClick={handleClick}>
            {t("studentDashboard.goToTest.button")}
          </Button>
        </StyledTestEnvCard>
      )}
    </StyledMainSection>
  );
});
