import type React from "react";

import type LevelDashProps from "./LevelDash.model";
import { StyledLevelDash } from "./LevelDash.styled";

export const LevelDash: React.FC<LevelDashProps> = ({
  level,
  className,
  size = 1,
  isStateless = false,
}) => (
  <StyledLevelDash $isStateless={isStateless} $size={size} className={className} level={level} />
);
