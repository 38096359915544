import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";

import inviteStudentsBackground from "assets/images/invite-students.png";

export interface InviteOptionProps extends Props {
  isSelected?: boolean;
}

export interface DarkOverlayProps extends Props {
  $alignItems?: string;
}

export interface StyledNumberWrapperProps extends Props {
  $marginRight?: string;
}

export const StyledDarkOverlay = styled.div<DarkOverlayProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: ${({ $alignItems }: DarkOverlayProps) => $alignItems ?? "center"};
  left: 0;
  top: 0;
  z-index: 10;
`;

export const StyledWindow = styled.div<Props>`
  position: relative;
  background: white;
  background-image: url(${inviteStudentsBackground});
  background-repeat: no-repeat;
  width: 60%;
  padding-top: 3rem;
  text-align: center;
`;

export const StyledDescription = styled.div<Props>`
  width: 70%;
  margin: auto;
  color: ${({ theme }: Props) => theme.palette.neutral._500};
  margin-bottom: 3rem;
`;

export const StyledButtonsWrapper = styled.div<Props>`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const StyledOutlinedButton = styled.div<Props>`
  background: white;
  color: ${({ theme }: Props) => theme.palette.primary.brand};
  border: 1px solid ${({ theme }: Props) => theme.palette.primary.brand};
  line-height: 1.5rem;
  padding: 0.6875rem 2.125rem;
  transition: all 0.15s ease-in-out;
  border-radius: 0.3rem;
  cursor: pointer;
`;

export const StyledCloseIcon = styled.div<Props>`
  position: absolute;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
`;

export const StyledInvitedOverview = styled.div<Props>`
  width: 70%;
  background: white;
  margin: auto;
`;

export const StyledInvitedInnerOverview = styled.div<Props>`
  margin: auto;
  margin-bottom: 2rem;
  width: 85%;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    font-weight: ${({ theme }: Props) => theme.font.weight.bold};
  }
`;

export const StyledNumberWrapper = styled.span<StyledNumberWrapperProps>`
  background: ${({ theme }: StyledNumberWrapperProps) => theme.palette.secondary.brand};
  margin-right: ${({ $marginRight }: StyledNumberWrapperProps) => $marginRight ?? "center"};
  border-radius: 40%;
  color: white;
  padding: 0.1rem 0.7rem 0.1rem 0.7rem;
`;

export const StyledExistingAccounts = styled.div<Props>`
  > i {
    margin-left: 2rem;
    cursor: pointer;
  }
`;

export const StyledExistingAccountsList = styled.span<Props>`
  text-align: left;
  > * {
    padding: 0.5rem;
  }
`;
