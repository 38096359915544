import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { modalIds } from "constants/modals-constants";
import type { ResultsAPI } from "generated/types";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useModal } from "hooks/useModal";
import { useSEBKeys } from "hooks/useSEBKeys";

import { Checkbox } from "components/Checkbox/Checkbox";
import { FormDivider } from "components/SteppedForm/SteppedForm.styled";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";
import { useTheme } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import { TooltipModal } from "../TooltipModal/TooltipModal";
import {
  KeyWrapper,
  StyledCreateNewButton,
  StyledFontAwesomeIcon,
  StyledIconWrapper,
  StyledSEBCheckBoxWrapper,
  StyledTable,
} from "./FormSEBListStep.styled";
import { SEBKeyUpsertDialog } from "./components/SEBKeyUpsertDialog/SEBKeyUpsertDialog";

export interface FormSEBListStepProps {
  scheduledTestId: number | null;
  onKeysSelected: (keys: number[] | null) => void;
  enabledSY: boolean;
  setSecureTestOptionSelected: (safeExamOptions: {
    syEnabled: boolean;
    sebEnabled: boolean;
  }) => void;
}

export const FormSEBListStep: React.FC<FormSEBListStepProps> = observer(
  ({ scheduledTestId, onKeysSelected, enabledSY, setSecureTestOptionSelected }) => {
    const [showSEBKeysList, setShowSEBKeysList] = useState(false);
    const [currentKeys, setCurrentKeys] = useState<ResultsAPI.SEBKey[]>([]);
    const [updatingKeyId, setUpdatingKeyId] = useState<number | null>(null);
    const { availableKeys, insertKey, updateKey, fetchAvailableKeys } = useSEBKeys();
    const { toggleModal, closeModal } = useModal();
    const { getCustomNameByDomain } = useDomainHandler();
    const { t } = useTranslation("scheduled-tests");
    const hogesTheme = useTheme() as ElearningThemeInterface;

    useEffect(() => {
      fetchAvailableKeys(scheduledTestId);
    }, []);

    useEffect(() => {
      setCurrentKeys(availableKeys);

      if (availableKeys.some(({ isActiveInTest }) => isActiveInTest)) {
        setShowSEBKeysList(true);
      }
    }, [availableKeys]);

    useEffect(() => {
      if (!showSEBKeysList) {
        onKeysSelected(null);
      }
    }, [showSEBKeysList]);

    const onCheckedKey = (id: number, checked: boolean) => {
      const modifiedKeys = currentKeys.map((key) =>
        key.id === id ? { ...key, isActiveInTest: checked } : key,
      );
      setCurrentKeys(modifiedKeys);
      onKeysSelected(
        modifiedKeys.filter(({ isActiveInTest }) => isActiveInTest === true).map(({ id }) => id),
      );
    };

    const handleSubmitted = (key: ResultsAPI.InsertSEBKey | ResultsAPI.UpdateSEBKey) => {
      if (updatingKeyId !== null)
        updateKey(key as ResultsAPI.UpdateSEBKey, () => fetchAvailableKeys(scheduledTestId));
      else insertKey(key as ResultsAPI.InsertSEBKey, () => fetchAvailableKeys(scheduledTestId));
      setUpdatingKeyId(null);
      closeModal();
    };

    return (
      <>
        <FormDivider />
        <SEBKeyUpsertDialog
          editSEBKey={currentKeys.find(({ id }) => id === updatingKeyId) ?? null}
          onSubmit={handleSubmitted}
        />
        <StyledSEBCheckBoxWrapper>
          <span>{t("testOptionsStep.sebKeys.modal.title")}</span>
          <>
            <ToggleSwitch
              dataCy="toggle-seb-keys"
              isActive={showSEBKeysList}
              variant="primary"
              isDisabled={enabledSY}
              onChange={() => {
                setSecureTestOptionSelected({ syEnabled: enabledSY, sebEnabled: !showSEBKeysList });
                setShowSEBKeysList(!showSEBKeysList);
              }}
            />
            <TooltipModal
              content={{
                title: t("testOptionsStep.sebKeys.modal.title"),
                paragraph: t("testOptionsStep.sebKeys.modal.paragraph", {
                  appName: getCustomNameByDomain(),
                }),
              }}
              dataCy="seb-modal"
              modalId={modalIds.sebKeysInfoModal}
            />
          </>
        </StyledSEBCheckBoxWrapper>
        {showSEBKeysList && (
          // TODO: rethink this design. Recommended to use grid https://github.com/infinitaslearning/app-he-hoges-web/issues/1167
          <StyledTable>
            <thead>
              <tr>
                <th>{t("testOptionsStep.sebKeys.modal.availableKeys")}</th>
                <th>{t("testOptionsStep.sebKeys.modal.keys")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {currentKeys.map(({ id, isActiveInTest, key, name }) => (
                <tr key={`checkbox-wrapper-${id}`}>
                  <td>
                    <Checkbox
                      checked={isActiveInTest}
                      dataCy={`seb-key-${id}-checkbox`}
                      id={`seb-key-${id}-checkbox`}
                      label={name}
                      onChange={(e) => onCheckedKey(id, e.target.checked)}
                    />
                  </td>
                  <td>
                    <KeyWrapper data-cy={`seb-key-${id}`}>{key}</KeyWrapper>
                  </td>
                  <td>
                    <StyledFontAwesomeIcon
                      data-cy={`modify-seb-key-${id}`}
                      iconName="fas fa-edit"
                      onClick={() => {
                        toggleModal(modalIds.sebKeysModal);
                        setUpdatingKeyId(id);
                      }}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <StyledIconWrapper>
                    <StyledFontAwesomeIcon
                      iconName="fas fa-plus"
                      style={{ color: hogesTheme.palette.primary.brand }}
                    />
                    <StyledCreateNewButton
                      data-cy="new-seb-key"
                      variant="default"
                      onClick={() => {
                        toggleModal(modalIds.sebKeysModal);
                        setUpdatingKeyId(null);
                      }}
                    >
                      {t("testOptionsStep.sebKeys.modal.new")}
                    </StyledCreateNewButton>
                  </StyledIconWrapper>
                </td>
              </tr>
            </tbody>
          </StyledTable>
        )}
      </>
    );
  },
);
