import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { StudentLogsSideModal } from "pages/TeacherEnvironment/TeacherMonitor/subpages/ScheduledTestLiveMonitor/components/StudentLogsSideModal/StudentLogsSideModal";
import { InvalidateTestOverlay } from "../InvalidateTestOverlay/InvalidateTestOverlay";
import {
  StyledInvalidButton,
  StyledInvalidatedButtonWrapper,
  StyledLink,
  StyledWrapper,
} from "./InvalidateTest.styled";

export interface InvalidateTestProps {
  scheduledTestId: number;
  studentId: number;
  studentFullName: string;
  isInvalid: boolean;
  enableInvalidateButton: boolean;
  onSubmit: () => void;
}

export const InvalidateTest: React.FC<InvalidateTestProps> = ({
  scheduledTestId,
  studentFullName,
  studentId,
  isInvalid,
  enableInvalidateButton,
  onSubmit,
}) => {
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const { t } = useTranslation("teacher-dashboard");

  return (
    <StyledWrapper>
      {enableInvalidateButton && (
        <InvalidateTestOverlay
          isOpen={isOverlayOpen}
          onClose={() => setIsOverlayOpen(false)}
          onSubmit={onSubmit}
        />
      )}
      <StyledLink data-cy="open-student-logs" onClick={() => setIsSideModalOpen(true)}>
        {t("scheduledTestForStudent.InvalidateTest.action")}
      </StyledLink>
      <StudentLogsSideModal
        closeOnClickOutside
        isOpen={isSideModalOpen}
        scheduledTestId={+scheduledTestId}
        studentId={studentId}
        title={studentFullName}
        onClose={() => setIsSideModalOpen(false)}
      />
      {isInvalid ? (
        <StyledInvalidatedButtonWrapper>
          <FontAwesomeIcon iconName="far fa-exclamation-triangle" />
          <span data-cy="invalidated-student">
            {t("scheduledTestForStudent.InvalidateTest.invalidated")}
          </span>
        </StyledInvalidatedButtonWrapper>
      ) : (
        enableInvalidateButton && (
          <StyledInvalidButton dataCy="invalidate-student" onClick={() => setIsOverlayOpen(true)}>
            {t("scheduledTestForStudent.InvalidateTest.invalidate")}
          </StyledInvalidButton>
        )
      )}
    </StyledWrapper>
  );
};
