import type React from "react";

import { CssIcon } from "styles/helpers/layout";

import {
  type EncircledIconSize,
  type EncircledIconVariant,
  StyledIconContainer,
} from "./EncircledIcon.styled";

export interface EncircledIconProps {
  className?: string;
  iconName: string;
  size?: EncircledIconSize;
  variant?: EncircledIconVariant;
}

export const EncircledIcon: React.FC<EncircledIconProps> = ({
  className,
  iconName,
  variant = "primary",
  size = "default",
}) => {
  return (
    <StyledIconContainer $size={size} $variant={variant} className={className}>
      <CssIcon iconName={iconName} />
    </StyledIconContainer>
  );
};
