import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { modalIds } from "constants/modals-constants";
import { TEST_PAGE_ROUTES } from "constants/routes";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";

import { useDeterminationTest } from "hooks/useDeterminationTest";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useModal } from "hooks/useModal";

import { LevelDashGroup } from "components/LevelDashGroup";

import { CssFlex, CssGrid } from "styles/helpers/layout";
import {
  StyledButton,
  StyledCompleteIcon,
  StyledDeterminationStatus,
  StyledProgressMessage,
} from "./SubjectProgressBar.styled";

interface SubjectProgressBarProps {
  goalLevel: number;
  productId: number;
  moduleId: number;
  subjectId: number;
  subjectName: string;
  currentProgress: number;
  isSubjectStarted: boolean;
}

export const SubjectProgressBar: React.FC<SubjectProgressBarProps> = observer(
  ({
    productId,
    moduleId,
    subjectId,
    subjectName,
    goalLevel,
    currentProgress,
    isSubjectStarted,
  }) => {
    const { t } = useTranslation("homepage");
    const history = useHistory();
    const { toggleModal } = useModal();
    const { determinationTestStatus } = useDeterminationTest();
    const { enableMixedAdaptiveWorkflow } = useFeatureFlags();

    const isSubjectFinished = currentProgress === 100;

    const determinationStatus = determinationTestStatus?.find(
      (subject) => subject.subjectId === subjectId,
    );

    const isSkillDeterminationTestInProgress =
      determinationStatus &&
      determinationStatus.answeredQuestions > 0 &&
      determinationStatus.answeredQuestions !== determinationStatus.totalQuestions;

    // Redirect to different type of test depending on current progress
    const onButtonClick = () => {
      const trackingItemId = !isSubjectStarted
        ? isSkillDeterminationTestInProgress
          ? PLATFORM_EVENT_TYPES.STUDENT_MODULES_EVENT_TYPES.CLICK_CONTINUE_DETERMINATION_TEST
          : PLATFORM_EVENT_TYPES.STUDENT_MODULES_EVENT_TYPES.CLICK_START_DETERMINATION_TEST
        : PLATFORM_EVENT_TYPES.STUDENT_MODULES_EVENT_TYPES.CLICK_PRACTICE_SUBJECT;
      trackPlatformEvent({
        module: STUDENT_MODULES.BASIC_PROGRESS,
        itemId: trackingItemId,
        value: JSON.stringify({
          productId,
          moduleId,
          subjectId,
          goalLevel,
          isSubjectStarted,
          isSubjectFinished,
        }),
      });

      if (isSubjectFinished) {
        toggleModal(modalIds.completedSubject, { subjectId, subjectName });
      } else {
        const testUrl = !isSubjectStarted
          ? TEST_PAGE_ROUTES.SKILL_DETERMINATION_TEST
          : enableMixedAdaptiveWorkflow
            ? TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_WORKFLOW_SELECT
            : TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST;
        history.push(buildUrlWithPathParams(testUrl, { productId, moduleId, subjectId }));
      }
    };

    return (
      <CssGrid
        gap={2}
        gridTemplateColumns={isSubjectStarted ? "1fr auto" : "auto"}
        justifyContent="end"
      >
        {isSubjectStarted && (
          <CssFlex columnGap={1} flexDirection="row" justifyContent="end">
            {isSubjectFinished && (
              <StyledCompleteIcon data-cy="subject-completed-icon" iconName="fas fa-badge-check" />
            )}
            <CssFlex flexDirection="column">
              <StyledProgressMessage
                alignItems="center"
                data-cy="current-subject-progress"
                gap={0.5}
                mb={1}
              >
                <b>{`${Math.floor(currentProgress || 0)}%`}</b>
                {` ${t("subjects.achieved", "achieved")}`}
              </StyledProgressMessage>
              <LevelDashGroup level={currentProgress || 0} numberOfDashes={5} />
            </CssFlex>
          </CssFlex>
        )}
        <CssFlex alignItems="center">
          {!isSubjectStarted && isSkillDeterminationTestInProgress && (
            <StyledDeterminationStatus data-cy="determination-status">
              {t("subjects.determinationStatus", {
                answeredQuestions: determinationStatus?.answeredQuestions
                  ? determinationStatus.answeredQuestions + 1
                  : 0,
                totalQuestions: determinationStatus?.totalQuestions || 0,
              })}
            </StyledDeterminationStatus>
          )}
          <StyledButton
            dataCy="start-subject-practice-button"
            disabled={!goalLevel}
            variant="primary"
            onClick={onButtonClick}
          >
            {!isSubjectStarted
              ? isSkillDeterminationTestInProgress
                ? t("subjects.button.continue.label", "Continue")
                : t("subjects.button.start.label", "Start")
              : t("subjects.button.improve.label", "Improve")}
          </StyledButton>
        </CssFlex>
      </CssGrid>
    );
  },
);
