import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";

import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProfile } from "hooks/useProfile";

import { CookiePolicy, launchAsync } from "@microsoft/immersive-reader-sdk";
import type { Options } from "@microsoft/immersive-reader-sdk/lib/options";
import { showToast } from "components/ToastNotification/ToastNotification.methods";
import { isProd } from "constants/env-constants";
import { logAPMError } from "observability";
import { useTranslation } from "react-i18next";
import {
  type CognitiveServicesTokenResponse,
  fetchImmersiveReaderToken,
} from "repositories/CognitiveServicesRepository/CognitiveServicesRepository";
import type { ImmersiveReaderContextValue } from "./ImmersiveReader.model";

export const ImmersiveReaderContext: React.Context<ImmersiveReaderContextValue> =
  React.createContext<ImmersiveReaderContextValue>({} as ImmersiveReaderContextValue);

// Azure resource name
const devSubdomain = "il-higher-ir-stg";
const prdSubdomain = "il-higher-ir-prd";

const ACCESSIBILITY_SUBDOMAIN = isProd() ? prdSubdomain : devSubdomain;

type ImmersiveReaderConfig = {
  token: string;
  innerHtmlContent: string;
  onExit(): void;
  language: string;
};

const tidyContent = (content: string) => {
  const result = content.replace(/_{3,}/g, "_");
  return result;
};

const initImmersiveReader = ({
  token,
  innerHtmlContent,
  onExit,
  language,
}: ImmersiveReaderConfig) => {
  const fullscreenElement = document.getElementById("irFullScreenContainer");

  const options: Options = {
    cookiePolicy: CookiePolicy.Enable,
    uiLang: language,
    uiZIndex: 500,
    onExit,
    allowFullscreen: false,
    disableGrammar: true,
    disableTranslation: true,
  };

  // We need the Immersive Reader to take up the whole viewport, overlaid on top of the fullscreen content
  // when active. Otherwise, in every other case, it can just be attached to the body
  if (fullscreenElement) options.parent = fullscreenElement;

  const content = {
    title: "Immersive Reader",
    chunks: [
      {
        content: tidyContent(innerHtmlContent),
        mimeType: "text/html",
        lang: language,
      },
    ],
  };

  launchAsync(token, ACCESSIBILITY_SUBDOMAIN, content, options);

  // Annoying things: the iframe is not immediately available, so we need to wait for it to be inserted
  // We can't attach event listeners to it because of cross origin policy
  // We can't look inside its content because of cross origin policy (XSS)
  // We need to modify the height of the iframe to trigger a re-render of the contained virtualization library
  // We can't verify that the content is inside and ready to be re-rendered
  // Since the iframe is 100vw and 100vh, it eats all events (otherwise we could just attach a listener to the parent)
  // Many of the online solutions to "force rerender without visual changes" don't trigger the re-render in the virtualization library
  // When the virtualization library actually re-renders when an updated size, it scrolls to the top of the content
  // On top of all that, the IR library automatically closes the IR/iframe if it doesn't all load within 15 seconds (you can
  // throttle to 2g in the browser to emulate this)

  // However: we are luckily able to attach a global window listener to the message event, which is triggered by the IR

  const resize = () => {
    const iframe = document.querySelector("iframe");
    if (!iframe) return;

    iframe.style.height = "99vh"; // 99.9 is too much and won't cause a re-render
    setTimeout(() => {
      iframe.style.height = "100vh";
    }, 5);
  };

  // add listener for the messages internall from the library:
  // https://github.com/microsoft/immersive-reader-sdk/blob/dev/js/src/launchAsync.ts
  window.addEventListener("message", (e) => {
    if (e.data === "ImmersiveReader-LaunchSuccessful") {
      setTimeout(resize, 500);
    }
  });
};

export const ImmersiveReaderProvider: React.FC = observer(({ children }) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [immersiveReaderClicked, setImmersiveReaderClicked] = useState(false);
  const [tokenSettings, setTokenSettings] = useState<CognitiveServicesTokenResponse | null>(null);

  const { userDetails } = useProfile();
  const { showImmersiveReader } = useFeatureFlags();

  const userLanguage = userDetails?.language || "en";

  const irEnabled = !!userDetails?.isImmersiveReaderEnabled && (showImmersiveReader as boolean);

  const refreshToken = useCallback(() => {
    setLoading(true);
    fetchImmersiveReaderToken()
      .then((resp) => {
        setTokenSettings(resp);
      })
      .catch((e) => {
        showToast(t("immersiveReader.failedToLaunch"), "error");
        logAPMError(e as Error, "immersive-reader");
        setTokenSettings(null);
      })
      .finally(() => setLoading(false));
  }, [t]);

  useEffect(() => {
    // According to IR docs
    const minExpirationSeconds = 3599;

    const refreshId = setInterval(() => {
      if (irEnabled) refreshToken();
    }, minExpirationSeconds * 1000);

    return () => {
      clearInterval(refreshId);
    };
  }, [irEnabled, refreshToken]);

  useEffect(() => {
    if (irEnabled) refreshToken();
  }, [irEnabled, refreshToken]);

  useEffect(() => {
    if (!tokenSettings || !content) return;

    const resetImmersiveReader = () => {
      setImmersiveReaderClicked(false);
      setContent("");
    };

    try {
      initImmersiveReader({
        token: tokenSettings.access_token,
        innerHtmlContent: content,
        onExit: resetImmersiveReader,
        language: userLanguage,
      });
    } catch (e) {
      showToast(t("immersiveReader.failedToLaunch"), "error");
      logAPMError(e as Error, "immersive-reader");
      resetImmersiveReader();
    }
  }, [content, t, tokenSettings, userLanguage]);

  const value = {
    loading,
    setContent,
    immersiveReaderClicked,
    setImmersiveReaderClicked,
    irEnabled,
    token: tokenSettings?.access_token,
  };

  return (
    <ImmersiveReaderContext.Provider value={value}>{children}</ImmersiveReaderContext.Provider>
  );
});
