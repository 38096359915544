import { AccordionItem } from "components/AccordionItem";
import { SubjectProgressBar } from "pages/StudentEnvironment/StudentDashboard/subpages/ModuleDetailsPage/components/SubjectProgressBar";
import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/calculateRem";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledWritingSubject = styled.div<Props>`
  text-align: start;
`;

export const StyledSubjectProgressBar = styled(SubjectProgressBar)<Props>`
  button {
    width: ${calculateRem(104)};
  }
`;

export const StyledResetSubjectProgress = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SyledChapterOverviewWrapper = styled.div`
  display: grid;
  gap: 2rem;
  padding-bottom: 2rem;
`;

export const StyledSubjectAccordionItem = styled(AccordionItem)`
  .tab-label {
    display: grid;
    column-gap: 1.5rem;
    grid-template-columns: auto 2fr 1fr;
  }

  .tab-label::before {
    margin-right: 0;
  }

  .tab-content {
    padding: 0;
  }
`;
