import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import { type ApiRequestError, RequestError } from "models/error/Error";
import { handlingResponse, logError } from "repositories/utils";

import cookieStorage from "persistence";

export const fetchTallyToken = async (): Promise<string> => {
  try {
    const token = await axios.get("/api/tally/token", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<string>([200], "Error retrieving tally token", "tally token")(token);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.FETCH_TALLY_TOKEN });
  }
};
