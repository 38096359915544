import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";

import { useModal } from "hooks/useModal";
import { useTeacherOverview } from "hooks/useTeacherOverview";
import { useWriting } from "hooks/useWriting";

import { modalIds } from "constants/modals-constants";
import { TEACHER_INSIGHT_PAGE_ROUTES } from "constants/routes";

import type { OverviewFilters } from "models/progress/Progress";
import { TeacherReportType } from "models/report/Report";

import { Paginator } from "components/PaginatorRemake/Paginator";
import { SkeletonLoader } from "components/SkeletonLoader/SkeletonLoader";
import { TeacherDashboardFilters } from "../../components/TeacherDashboardFilters";
import { WritingStudentsResultsOverview } from "./components/StudentOverview/WritingStudentsResultsOverview";
import { WritingSubjectsResultsOverview } from "./components/SubjectOverview/WritingSubjectsResultsOverview";

import { StyledPageContainer } from "../BasicProgress/BasicProgress.styled";

export const WritingResultsOverviewPage: React.FC = observer(() => {
  const {
    productId,
    page,
    currentEducationTitle,
    currentYearsTitle,
    showEducationFilter,
    showSchoolYearFilter,
    userEducations,
    productSchoolYears,
    paginationFilters,
    buildPaginatedURL,
  } = useTeacherOverview(TEACHER_INSIGHT_PAGE_ROUTES.WRITING_SKILLS);

  const {
    writingStudentsResultsOverview,
    writingSubjectsResultsOverview,
    writingSubjectStudentsDetails,
    fetchWritingStudentsResultsOverview,
    fetchWritingSubjectsResultsOverview,
    fetchWritingSubjectStudentsDetails,
  } = useWriting();

  const { openModal, closeModal } = useModal();

  const {
    resultsPerPage,
    educationId,
    schoolYearEnd,
    searchTerm,
    schoolYearStart,
    groupId,
  }: OverviewFilters = paginationFilters;

  useEffect(() => {
    fetchWritingSubjectsResultsOverview(+productId, paginationFilters);
  }, [productId, educationId, searchTerm, schoolYearStart, schoolYearEnd, groupId]);

  useEffect(() => {
    fetchWritingStudentsResultsOverview(+productId, paginationFilters);
  }, [
    productId,
    page,
    searchTerm,
    resultsPerPage,
    educationId,
    schoolYearStart,
    schoolYearEnd,
    groupId,
  ]);

  useEffect(() => {
    if (
      writingSubjectStudentsDetails &&
      Object.values(writingSubjectStudentsDetails).some((val) => Array(val).length > 0)
    ) {
      openModal(modalIds.writingStudentListModal);
    }
  }, [writingSubjectStudentsDetails]);

  useEffect(() => {
    closeModal();
  }, []);

  const numPages =
    writingSubjectsResultsOverview && resultsPerPage
      ? Math.ceil(writingSubjectsResultsOverview.studentIds.length / resultsPerPage)
      : 1;

  return (
    <StyledPageContainer $hasSideFilters>
      <div>
        <TeacherDashboardFilters
          buildPaginatedURL={buildPaginatedURL}
          currentEducationTitle={currentEducationTitle}
          currentYearsTitle={currentYearsTitle}
          productId={+productId}
          productSchoolYears={productSchoolYears}
          showEducationFilter={showEducationFilter}
          showSchoolYearFilter={showSchoolYearFilter}
          teacherReportType={TeacherReportType.WRITING_PROGRESS}
          userEducations={userEducations}
        />

        {writingSubjectsResultsOverview ? (
          <WritingSubjectsResultsOverview
            studentCount={writingSubjectsResultsOverview.studentIds.length}
            subjects={writingSubjectsResultsOverview.subjects}
            onShouldFetchWritingSubjectStudents={(subjectId) =>
              fetchWritingSubjectStudentsDetails(+productId, subjectId, paginationFilters)
            }
          />
        ) : (
          <SkeletonLoader $marginTop={2} count={4} />
        )}
        {writingStudentsResultsOverview ? (
          <WritingStudentsResultsOverview
            productId={+productId}
            studentCount={writingSubjectsResultsOverview?.studentIds?.length}
            students={writingStudentsResultsOverview}
          />
        ) : (
          <SkeletonLoader $marginTop={2} count={8} />
        )}

        {page && numPages > 1 && (
          <Paginator
            currentPage={page}
            linkBuilderFunction={buildPaginatedURL}
            totalPages={numPages}
          />
        )}
      </div>
    </StyledPageContainer>
  );
});
