import { useRedirect } from "hooks/useRedirect";
import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";

import { type EnvironmentType, serviceENV as getCurrentEnvironment } from "constants/env-constants";
import { HogesWebshopURL } from "constants/registration-links";
import { useDomainHandler } from "hooks/useDomainHandler";

import { ParagraphLarge } from "styles/elements/Texts";
import { StyledButton } from "./NoOwnedTextContent.styled";

export const NoOwnedTextContent: React.FC = observer(() => {
  const { t } = useTranslation("homepage");
  const { getCustomNameByDomain } = useDomainHandler();
  const { redirectToUrl } = useRedirect();
  const currentEnv = getCurrentEnvironment() as EnvironmentType;

  return (
    <>
      <ParagraphLarge>
        {t("noOwned.description", { appName: getCustomNameByDomain() })}
      </ParagraphLarge>
      <ParagraphLarge>{t("noOwned.button.title", "Visit our website.")}</ParagraphLarge>
      <StyledButton
        dataCy="go-to-webshop-button"
        variant="primary"
        onClick={() => redirectToUrl(HogesWebshopURL[currentEnv])}
      >
        {t("noOwned.button.text", "Go to the website")}
      </StyledButton>
    </>
  );
});
