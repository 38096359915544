import { ScheduledTestTimeLeftStatus } from "constants/exam-constants";
import { useEffect } from "react";
import { useScheduledTests } from "./useScheduledTests";

export const useTestStatusAndTimeLeft = (code: string): void => {
  const {
    scheduledTestStatusAndTimeLeft,
    setScheduledTestStatusAndTimeLeft,
    fetchScheduledTestStatusAndTimeLeft,
  } = useScheduledTests();

  useEffect(() => {
    if (!scheduledTestStatusAndTimeLeft) {
      fetchScheduledTestStatusAndTimeLeft(code);
    }

    const interval = setInterval(() => {
      if (scheduledTestStatusAndTimeLeft?.status !== ScheduledTestTimeLeftStatus.FINISHED) {
        fetchScheduledTestStatusAndTimeLeft(code);
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (scheduledTestStatusAndTimeLeft?.counter) {
        setScheduledTestStatusAndTimeLeft({
          ...scheduledTestStatusAndTimeLeft,
          counter: scheduledTestStatusAndTimeLeft.counter - 1,
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [scheduledTestStatusAndTimeLeft]);
};
