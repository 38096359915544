import { Button } from "components/Button";
import { isTest } from "constants/env-constants";
import { useFullScreen } from "hooks/useFullScreen";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { RulesProps } from "./Rules.model";
import { StyleOrderedList, StyledButtonContainer, StyledNoticeListItem } from "./Rules.styled";

export const Rules: React.FC<RulesProps> = ({ onAccept }) => {
  const { t } = useTranslation("test-environment");
  const { fullScreen } = useFullScreen();

  useEffect(() => {
    if (fullScreen?.active) onAccept();
  }, [fullScreen?.active]);

  return (
    <>
      <StyleOrderedList data-cy="instructions-list-container">
        <StyledNoticeListItem data-cy="instructions-list-item">
          {t(
            "instructions.list.programFocused",
            "You’re not allowed to leave the test window with your mouse.",
          )}
        </StyledNoticeListItem>
        <StyledNoticeListItem data-cy="instructions-list-item">
          {t(
            "instructions.list.online",
            "You’re not allowed to click on another tab, window or program.",
          )}
        </StyledNoticeListItem>
        <StyledNoticeListItem data-cy="instructions-list-item">
          {t(
            "instructions.list.record",
            "During the test, your movements and actions are monitored and recorded.",
          )}
        </StyledNoticeListItem>
        <StyledNoticeListItem data-cy="instructions-list-item">
          {t(
            "instructions.list.refreshPage",
            "When the test gets stuck, you can refresh the page with F5 or the refresh button next to the URL.",
          )}
        </StyledNoticeListItem>
      </StyleOrderedList>
      <StyledButtonContainer>
        <Button
          dataCy="instructions-button-rules"
          variant="primary"
          onClick={() => (isTest() ? onAccept() : fullScreen?.enter())}
        >
          {t("instructions.rules.button", "Okay, start the test")}
        </Button>
      </StyledButtonContainer>
    </>
  );
};
