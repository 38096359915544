import jwtDecode from "jwt-decode";

import {
  COOKIE_REFRESH_TOKEN,
  COOKIE_SESSION_ID,
  COOKIE_SESSION_TOKEN,
  cookieENV,
} from "constants/env-constants";

import { redirectToIdpLogout } from "_helpers/utils/idp";

import type { UsetifulWindow } from "contexts/UsetifulProvider/Usetiful.model";
import cookieStorage, { type ROLE, type TokenValue } from ".";

interface AccessParams {
  jwt: string;
  refreshToken: string;
}

interface UserData {
  id: number;
  role: ROLE;
}

/**
 *  Store cookies with information on currently connected user data.
 *
 * @param {object} jwt - Object with connected user data.
 * @param {string} refreshToken - Refresh token value
 */
const setAccess = ({ jwt, refreshToken }: AccessParams): void => {
  const env = cookieENV();
  cookieStorage.setItem(`${env}-${COOKIE_SESSION_TOKEN}`, jwt);
  cookieStorage.setItem(`${env}-${COOKIE_REFRESH_TOKEN}`, refreshToken);
};

/**
 *  Return id associated with current session (if any).
 */
const getCurrentSessionId = (): string => {
  const env = cookieENV();
  return cookieStorage.getItem(`${env}-${COOKIE_SESSION_ID}`);
};

/**
 *  If a session is not currently in course, create a new random id to
 * identify a new one.
 *
 * @return {string} Random id assigned to the current session.
 */
const startSession = (): string => {
  const env = cookieENV();
  const currentSessionId = getCurrentSessionId();

  if (!currentSessionId) {
    const sessionId = `${new Date().getTime()}.${Math.random().toString(36).substring(5)}`;
    cookieStorage.setItem(`${env}-${COOKIE_SESSION_ID}`, sessionId);
    return sessionId;
  }

  return currentSessionId;
};

/**
 *  Clear current user session data from cookies.
 */
const clearCookiesLogout = (): void => {
  const env = cookieENV();
  cookieStorage.removeItem(`${env}-${COOKIE_SESSION_ID}`);
  cookieStorage.removeItem(`${env}-${COOKIE_SESSION_TOKEN}`);
  cookieStorage.removeItem(`${env}-${COOKIE_REFRESH_TOKEN}`);

  // Generate a new session id after logout
  startSession();
};

// Clear usetiful data in local storage
// @see https://help.usetiful.com/support/solutions/articles/77000551160-fe-api-user-functions
const clearUsetifulUserDataOnLogout = (): void => {
  const usetifulAPI = (window as UsetifulWindow).USETIFUL;
  if (usetifulAPI) {
    usetifulAPI.user?.setId(null);
    usetifulAPI.user?.clearProgress();
    usetifulAPI.reinitialize();
  }
};

/**
 *  Return information on the current user (if any).
 */
const getCurrentUser = (): UserData | null => {
  const token = cookieStorage.getToken();

  try {
    const decodedToken = jwtDecode<TokenValue>(token);
    return {
      id: decodedToken.uuid,
      role: decodedToken.role,
    };
  } catch (_error) {
    // If token is corrupted, force the user to login again
    clearCookiesLogout();
    clearUsetifulUserDataOnLogout();
    redirectToIdpLogout(window.location.pathname);
    return null;
  }
};

export {
  setAccess,
  startSession,
  clearCookiesLogout,
  clearUsetifulUserDataOnLogout,
  getCurrentUser,
  getCurrentSessionId,
};
