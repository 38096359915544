import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type { TeacherTypesForInstructions } from "models/product/TeacherInstructions";
import cookieStorage from "persistence";
import { handlingResponse, logError } from "repositories/utils";

interface UploadResponse {
  downloadUrl: string;
  blobServiceRequestId: string;
}

interface DeleteFileResponse {
  blobServiceRequestId: string;
}

interface Metadata {
  productId: number;
  moduleId: number;
  targetTeacherType: TeacherTypesForInstructions;
}

const uploadFile = async (
  file: File,
  metadata: Metadata,
  allowOverride = false,
  filePath?: string,
): Promise<UploadResponse> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("metadata", JSON.stringify(metadata));
    if (filePath) {
      const id = filePath.split("/").pop();
      formData.append("filePath", `teacherInstructions/${id}`);
    }
    const response = await axios.post(`/api/v1/files?allowOverride=${allowOverride}`, formData, {
      headers: {
        Authorization: cookieStorage.getToken(),
      },
    });
    return handlingResponse<UploadResponse>([200])(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.UPDATE_TEACHER_INSTRUCTIONS_FAILED,
    });
  }
};
const deleteFile = async (filePath?: string): Promise<DeleteFileResponse> => {
  try {
    if (!filePath) throw new Error("filePath is required");
    const id = filePath.split("/").pop();
    const response = await axios.delete("/api/v1/files", {
      data: {
        filePath: `teacher-instructions/${id}`,
      },
      headers: {
        Authorization: cookieStorage.getToken(),
      },
    });
    return handlingResponse<DeleteFileResponse>([200])(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.DELETE_TEACHER_INSTRUCTIONS_FAILED,
    });
  }
};

export { uploadFile, deleteFile };
