import styled, { css } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type TextAreaProps from "./TextArea.model";

interface Props extends TextAreaProps {
  theme: ElearningThemeInterface;
}

export const StyledTextArea = styled.textarea<Props>`
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._500};
  border-radius: 4px;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0.75rem 1rem;
  ${(props: Props) => {
    if (props.error) {
      return css`
        border: 1px solid ${props.theme.palette.feedback.error._950};
      `;
    }
    return css`
      border: 1px solid ${props.theme.palette.neutral._600};
    `;
  }}
  &&:focus {
    outline: none !important;
    border: 1px solid ${({ theme }: Props) => theme.palette.secondary.brand};
    box-shadow: 0 0 10px #719ece;
  }
`;
