import { differenceInMinutes } from "date-fns";
import type { TFunction } from "i18next";

export const formatDatesAsPastDateStrings = (t: TFunction, date: Date): string => {
  if (!date) return "-";
  const difference = differenceInMinutes(new Date(), date);
  const minutesInYear = 525948;
  const minutesInMonths = 43829;
  const minutesInWeeks = 10080;
  const minutesInDays = 1440;
  const minutesInHours = 60;

  const differenceInYears = difference / minutesInYear;
  if (differenceInYears >= 1) {
    return t(`pastDates.year${Math.round(differenceInYears) > 1 ? "s" : ""}`, {
      years: Math.round(differenceInYears),
    });
  }
  const differenceInMonths = difference / minutesInMonths;
  if (differenceInMonths >= 1) {
    return t(`pastDates.month${Math.round(differenceInMonths) > 1 ? "s" : ""}`, {
      months: Math.round(differenceInMonths),
    });
  }
  const differenceInWeeks = difference / minutesInWeeks;
  if (differenceInWeeks >= 1) {
    return t(`pastDates.week${Math.round(differenceInWeeks) > 1 ? "s" : ""}`, {
      weeks: Math.round(differenceInWeeks),
    });
  }
  const differenceInDays = difference / minutesInDays;
  if (differenceInDays >= 1) {
    return t(`pastDates.day${Math.round(differenceInDays) > 1 ? "s" : ""}`, {
      days: Math.round(differenceInDays),
    });
  }
  const differenceInHours = difference / minutesInHours;
  return t(`pastDates.hour${Math.round(differenceInHours) > 1 ? "s" : ""}`, {
    hours: Math.round(differenceInHours),
  });
};
