import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { useScheduledTests } from "hooks/useScheduledTests";
import type { ScheduledTestStudentLog } from "models/log/Log";

import type { StudentLogsSideModalProps } from "./StudentLogsSideModal.model";
import {
  StyledEventContainer,
  StyledFraudWarning,
  StyledHeading,
  StyledSideModal,
} from "./StudentLogsSideModal.styled";
import { EventListItem } from "./components/EventListItem/EventListItem";
import { IpAddressList } from "./components/IpAddressList/IpAdressList";

export const StudentLogsSideModal: React.FC<StudentLogsSideModalProps> = observer(
  ({ isOpen, onClose, scheduledTestId, studentId, title }) => {
    const { t } = useTranslation("scheduled-tests");

    const { scheduledTestStudentLogs, setScheduledTestStudentLogs, fetchScheduledTestStudentLogs } =
      useScheduledTests();

    useEffect(() => {
      if (studentId) {
        fetchScheduledTestStudentLogs(scheduledTestId, studentId);
      } else {
        setScheduledTestStudentLogs([]);
      }
    }, [studentId]);

    const isPossibleFraud = scheduledTestStudentLogs?.some(
      ({ eventType }) => eventType === "fraud",
    );

    return (
      <StyledSideModal
        closeOnClickOutside
        dataCy="student-logs-side-modal"
        isOpen={isOpen}
        title={title}
        onClose={onClose}
      >
        <StyledHeading>{t("liveMonitor.studentLogsModal.title", "Logs overview")}</StyledHeading>

        <IpAddressList studentLogs={scheduledTestStudentLogs} />

        {isPossibleFraud && (
          <StyledFraudWarning data-cy="possible-fraud-warning">
            <FontAwesomeIcon iconName="far fa-exclamation-triangle" />
            {t("liveMonitor.studentLogsModal.possibleFraud", "Possible fraud")}
          </StyledFraudWarning>
        )}

        <StyledEventContainer>
          {scheduledTestStudentLogs?.map((trackedEvent: ScheduledTestStudentLog) => (
            <EventListItem
              key={`${trackedEvent.eventType}-${trackedEvent.date}`}
              event={trackedEvent}
            />
          ))}
        </StyledEventContainer>
      </StyledSideModal>
    );
  },
);
