import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { HST_TEST_STATUS_EVENTS } from "@infinitaslearning/module-he-common";
import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { ErrorRenderer } from "components/ErrorRenderer/ErrorRenderer";
import { LoadingSpinner } from "components/LoadingSpinner";
import { QuestionStatus, QuestionType, TestEventTypes, TestType } from "constants/exam-constants";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";
import type { ContentAPI } from "generated/types";
import { useAnswer } from "hooks/useAnswer";
import { useFailingExamRules } from "hooks/useExamRules";
import { useLogs } from "hooks/useLogs";
import { useScheduledTests } from "hooks/useScheduledTests";
import { useTestStatusAndTimeLeft } from "hooks/useTestStatusAndTimeLeft";
import { ExamProgressBar } from "layouts/ExamProgressBar/ExamProgressBar";
import { NextQuestion } from "layouts/NextQuestion";
import { QuestionManager } from "layouts/QuestionManager";
import { AnswerButton } from "layouts/QuestionManager/components/AnswerButton/AnswerButton";
import { logAPMError } from "observability";
import { ScheduledTestHeader } from "pages/TestEnvironment/TestPage/components/ScheduledTestHeader/ScheduledTestHeader";
import { TextQuizText } from "pages/TestEnvironment/TestPage/components/TextQuizText/TextQuizText";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { CssFlex } from "styles/helpers/layout";
import { ExamRuleFailingModal } from "../TestOverviewPage/components/ExamRuleFailingModal/ExamRuleFailingModal";
import { ExerciseFinishedMessage } from "./ExerciseFinishedMessage/ExerciseFinishedMessage";
import type { PagePathParams } from "./ExercisePage.model";
import {
  GlobalCSS,
  StyledBottomBar,
  StyledButtonContainer,
  StyledExercisePage,
  StyledTextQuizExerciseText,
} from "./ExercisePage.styled";

export const ExercisePage: React.FC = observer(() => {
  const { t } = useTranslation("skill-determination-test");
  const history = useHistory();
  const failingRules = useFailingExamRules();
  const { trackTestEvent } = useLogs();

  const { evaluateAnswer, resetAnswerState } = useAnswer();
  const {
    scheduledTestContent,
    fetchScheduledTestExercise: fetchExercise,
    scheduledTestExercise: exercise,
    setScheduledTestExercise: setExercise,
    scheduledTestExerciseStatus: exerciseStatus,
    scheduledTestStatusAndTimeLeft,
    selectedQuestion,
    setSelectedQuestion,
    setShouldClickNextQuestion,
  } = useScheduledTests();
  const { code, productId, exerciseId }: PagePathParams = useParams();
  const { answerButtonProps } = useAnswer();

  const {
    isDisabled: isAnswerButtonDisabled,
    isLoading: isAnswerButtonLoading,
    isHidden: shouldHideAnswerButton,
    label: answerButtonContent,
    onClick: onAnswerButtonClick,
  } = answerButtonProps;

  const testToken = scheduledTestContent?.token || "";

  useEffect(
    () => () => {
      setSelectedQuestion({
        index: 0,
        results: [],
        status: QuestionStatus.INITIAL,
      });
      setExercise(null);
      resetAnswerState();
    },
    [],
  );

  const backToTestOverview = () => {
    history.replace(buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_DETAILS_PAGE, { code }));
  };

  useEffect(() => {
    // If test data is still not loaded, redirect back to test overview
    if (!scheduledTestContent) {
      backToTestOverview();
    }
  }, [scheduledTestContent]);

  useTestStatusAndTimeLeft(code);

  const fetchScheduledTestSuccessful = (index: number, exerciseTitle?: string) => {
    if (!index) {
      trackTestEvent(HST_TEST_STATUS_EVENTS.EXERCISE_STARTED, TestEventTypes.STATUS, testToken, {
        exerciseId: +exerciseId,
        name: exerciseTitle || "",
      });
    }
    setSelectedQuestion({ ...selectedQuestion, index });
  };

  useEffect(() => {
    if (scheduledTestContent && productId && exerciseId) {
      fetchExercise({
        testCode: code,
        exerciseId: +exerciseId,
        testToken,
        randomizeQuestions: true,
      }).then(({ success, index, exerciseTitle }) => {
        if (!success) {
          backToTestOverview();
        } else {
          fetchScheduledTestSuccessful(index || 0, exerciseTitle);
        }
      });
    }
  }, [scheduledTestContent, productId, exerciseId]);

  const questions = exercise?.questions ? exercise.questions : [];
  const currentQuestion = questions?.[selectedQuestion.index];
  const allQuestionsAnswered =
    selectedQuestion.index === questions.length ||
    (exerciseStatus && exerciseStatus?.answeredQuestions.length === exerciseStatus?.totalQuestions);
  let nextExercise: ContentAPI.ExerciseDetailsSummary | undefined;
  if (exercise) {
    const currentExerciseIndex = scheduledTestContent?.exercises
      .map((e) => e.id)
      .indexOf(exercise.id);
    if (currentExerciseIndex) {
      nextExercise = scheduledTestContent?.exercises[currentExerciseIndex + 1];
    }
  }

  const onNextExerciseButtonClick = () => {
    if (nextExercise) {
      history.replace(
        buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_PAGE, {
          code,
          productId: +productId,
          exerciseId: nextExercise.id,
        }),
      );
    }
  };

  const onReviewButtonClick = () => {
    history.push(
      buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_REVIEW_PAGE, {
        code,
        productId,
        exerciseId,
      }),
    );
  };

  let footerButton = null;
  if (!shouldHideAnswerButton) {
    footerButton = (
      <AnswerButton
        disabled={isAnswerButtonDisabled}
        isLoading={isAnswerButtonLoading}
        showConfirmAnswerButton={!shouldHideAnswerButton || true}
        onSubmitAnswer={(skipAnswerValidation?: boolean) => {
          onAnswerButtonClick?.(skipAnswerValidation);
        }}
      >
        {answerButtonContent && answerButtonContent.length !== 0
          ? answerButtonContent
          : t("button.checkAnswer.label", "Check answer")}
      </AnswerButton>
    );
  } else {
    footerButton = <NextQuestion showButtonWhileEvaluating questions={questions} />;
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <ErrorRenderer message={error?.message} />}
      onError={(error, { componentStack }) => {
        logAPMError(error, componentStack, {
          component: "ExercisePage",
          testToken,
          testType: scheduledTestContent?.testType || "",
          exerciseId: exercise?.id || -1,
          questionId: currentQuestion?.id || -1,
        });
      }}
    >
      <GlobalCSS />
      <ScheduledTestHeader
        counter={scheduledTestStatusAndTimeLeft?.counter || 0}
        scheduledTestContent={scheduledTestContent}
        title={exercise?.title || ""}
        onClose={backToTestOverview}
      />
      {!exercise && <LoadingSpinner isFullScreen />}
      {exercise && (
        <StyledExercisePage
          correctTextLayout={currentQuestion?.type === QuestionType.CORRECT_TEXT}
          quizLayout={exercise?.isTextQuiz}
        >
          {questions && currentQuestion && !allQuestionsAnswered && (
            <CssFlex>
              {exercise?.isTextQuiz && (
                <StyledTextQuizExerciseText data-cy="text-quiz-text-content">
                  <TextQuizText text={exercise.text || ""} />
                </StyledTextQuizExerciseText>
              )}
              <CssFlex alignItems="center" flexDirection="column" rowGap={1}>
                <QuestionManager
                  hideAnswers
                  evaluateAnswer={(productId, questionId, answers, _, skipAnswerValidation) => {
                    evaluateAnswer({
                      productId,
                      questionId,
                      answers,
                      exerciseId: exercise.id,
                      skipAnswerValidation,
                      testToken: testToken,
                      testType: (scheduledTestContent?.testType as TestType) || TestType.UNDEFINED,
                    }).then(() => setShouldClickNextQuestion(true));
                  }}
                  flags={[]}
                  question={currentQuestion}
                  showTheoryButton={false}
                  showTip={false}
                  singleSelectShowLoadingButton={false}
                />
                {/* Display save button right below the question */}
                {!allQuestionsAnswered && (
                  <StyledButtonContainer>{footerButton}</StyledButtonContainer>
                )}
              </CssFlex>
            </CssFlex>
          )}
          {!allQuestionsAnswered && (
            <StyledBottomBar alignItems="center" justifyContent="center">
              <ExamProgressBar
                selectedQuestionIndex={selectedQuestion.index}
                totalQuestionCount={questions.length}
              />
            </StyledBottomBar>
          )}
          {allQuestionsAnswered && (
            <ExerciseFinishedMessage
              showNextExerciseButton={false}
              showReviewButton={!!scheduledTestContent?.editableAnswers}
              onBackToOverviewButtonClick={backToTestOverview}
              onNextExerciseButtonClick={onNextExerciseButtonClick}
              onReviewButtonClick={onReviewButtonClick}
            />
          )}
        </StyledExercisePage>
      )}
      <ExamRuleFailingModal failingRules={failingRules} />
    </ErrorBoundary>
  );
});
