import {
  type Page,
  type User,
  createTracker,
} from "@infinitaslearning/module-data-analyticstracker";
import type { HST_PROGRESS_EVENTS } from "@infinitaslearning/module-he-common";

import type { COMMON, STUDENT_MODULES, TEACHER_MODULES } from "analytics/constants/pageTypes";
import { trackingENV as getTrackingEnvironment } from "constants/env-constants";

type TrackerPromise = ReturnType<typeof tracker.elementClicked>;
export type HstCustomEventPayload = {
  module: STUDENT_MODULES | TEACHER_MODULES | COMMON; // kebab-case, identifies the section of the page where the event occured
  itemId: keyof typeof HST_PROGRESS_EVENTS; // kebab-case, identifies the item on which the event occured
  elementId?: string; // kebab-case, identifies the (html) element on which the event occured
  type?: string; // kebab-case, identifies the element type on which the event occured (ex.: button, checkbox)
  value?: string;
};

export const tracker = createTracker({
  platform: "Hogeschooltaal",
  platformVersion: "2.0.0",
  environment: getTrackingEnvironment() as "local" | "acceptance" | "development" | "production",
  opCo: "noordhoff",
  platformVariant: "pupil",
});

let isTrackingModuleInitialized = false;
// biome-ignore lint/suspicious/noAssignInExpressions: First Biome migration
tracker.awaitInitialized().then(() => (isTrackingModuleInitialized = true));

export const trackIdentifyEvent = (eventPayload: User): void => {
  if (isTrackingModuleInitialized) {
    tracker.identify(eventPayload);
  } else {
    console.warn("Cannot track identify event because tracking module hasn't been initialized yet");
  }
};

export const trackPageEvent = (eventPayload: Page): void => {
  if (isTrackingModuleInitialized) {
    tracker.page(eventPayload);
  } else {
    console.warn("Cannot track page event because tracking module hasn't been initialized yet");
  }
};

export const trackPlatformEvent = ({
  module,
  itemId,
  elementId,
  type,
  value,
}: HstCustomEventPayload): TrackerPromise =>
  tracker.elementClicked({
    module,
    itemId: itemId as string,
    elementId,
    type,
    value,
  });

//  If running on Cypress, we store a reference to the tracker object in the window object
// so we're able to stub its methods
// @see https://stackoverflow.com/questions/67474324/how-to-stub-a-module-in-cypress
// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.metabaseTracker = tracker;
}
