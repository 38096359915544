import { getThemeTextSize } from "a11y-utils";
import _ from "lodash";
import type { ElearningThemeInterface } from "./elearningInterface.theme";
import { hogesTheme } from "./hoges";
import { hogesBusinessTheme } from "./hogesBusiness";

const hogesThemeSmallText = _.merge({}, hogesTheme, getThemeTextSize("small"));
const hogesThemeMediumText = _.merge({}, hogesTheme, getThemeTextSize("medium"));
const hogesThemeLargeText = _.merge({}, hogesTheme, getThemeTextSize("large"));

const hogesBusinessThemeSmallText = _.merge({}, hogesBusinessTheme, getThemeTextSize("small"));
const hogesBusinessThemeMediumText = _.merge({}, hogesBusinessTheme, getThemeTextSize("medium"));
const hogesBusinessThemeLargeText = _.merge({}, hogesBusinessTheme, getThemeTextSize("large"));

// IDEA: This could get unwieldy if we have more options for theming
export const getTheme = (
  isBusiness: boolean,
  textSize: string | undefined,
): ElearningThemeInterface => {
  if (isBusiness) {
    if (textSize === "small") return hogesBusinessThemeSmallText;
    if (textSize === "large") return hogesBusinessThemeLargeText;
    return hogesBusinessThemeMediumText;
  }
  if (textSize === "small") return hogesThemeSmallText;
  if (textSize === "large") return hogesThemeLargeText;
  return hogesThemeMediumText;
};
