import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { calculateRem } from "styles/mixins/mixins";

export const StyledErrorMessage = styled.span`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  color: ${(props: ThemeProps) => props.theme.palette.feedback.error.brand};
`;

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${calculateRem(10)};
`;
