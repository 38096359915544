import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import { type ApiRequestError, RequestError } from "models/error/Error";
import { handlingResponse, logError } from "repositories/utils";

import cookieStorage from "persistence";

export interface CognitiveServicesTokenResponse {
  token_type: string;
  expires_in: string;
  ext_expires_in: string;
  expires_on: string;
  not_before: string;
  resource: string;
  access_token: string;
}

export const fetchImmersiveReaderToken = async (): Promise<CognitiveServicesTokenResponse> => {
  try {
    const token = await axios.get("/api/cognitiveServices/token", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<CognitiveServicesTokenResponse>(
      [200],
      "Error retrieving Cognitive Services Immersive Reader token",
      "Cognitive Services",
    )(token);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.FETCH_IMMERSIVE_READER_TOKEN });
  }
};
