import { Formik } from "formik";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo, useRef, useState } from "react";

import { useModal } from "hooks/useModal";
import { useWriting } from "hooks/useWriting";
import type { ProgressiveOverviewQuestion } from "models/exam/Exam";
import type { OverviewQuestionAnswer, OverviewQuestionResult } from "models/writing/WritingDetail";

import { EmbeddedHtml } from "components/EmbeddedHtml";
import { modalIds } from "constants/modals-constants";
import { useAnswer } from "hooks/useAnswer";
import { useWritingTest } from "hooks/useWritingTest";
import { commonSanitizeOptions } from "layouts/QuestionManager/QuestionManager.constants";
import type { QuestionsManagerPathParams } from "layouts/QuestionManager/QuestionManager.model";
import { useParams } from "react-router-dom";
import { ReflectiveQuestionsModal } from "../ReflectiveQuestionsModal";
import { StyledOverviewQuestion } from "./OverviewQuestion.styled";
import { OverviewAnswer } from "./components/OverviewAnswer/OverviewAnswer";
import { OverviewLetter } from "./components/OverviewLetter/OverviewLetter";

export interface OverviewQuestionProps {
  productId: number;
  question: ProgressiveOverviewQuestion;
}

export const OverviewQuestion: React.FC<OverviewQuestionProps> = observer(
  ({ productId, question }) => {
    const [highlightedQuestionId, setHighlightedQuestionId] = useState<number>(-1);
    const { writingTest, fetchWritingTest } = useWritingTest();
    const { overviewQuestionResults, fetchOverviewQuestionResults, submitOverviewQuestionResults } =
      useWriting();
    const { openModal } = useModal();
    const { exerciseId }: QuestionsManagerPathParams = useParams();
    const { setShowProgressButton, setAnswerButtonProps } = useAnswer();

    const { id: questionId, content, prevQuestions } = question;
    const reflectionQuestions = question.metadata?.reflectionQuestions;

    useEffect(() => {
      exerciseId && fetchWritingTest(productId, +exerciseId);
    }, [productId, exerciseId]);

    useEffect(() => {
      fetchOverviewQuestionResults(productId, questionId, writingTest?.token || "");
    }, [questionId, writingTest]);

    // Disable answer button in bottom bar for this specific question type (it uses its own
    // because users should be able to modify their answers multiple times before moving on)
    useEffect(() => {
      setAnswerButtonProps({
        label: "",
        isDisabled: false,
        isHidden: true,
        isLoading: false,
        onClick: () => 0,
      });
    }, []);

    const initialValues = useMemo(
      () =>
        prevQuestions.reduce((formFields, prevQuestion) => {
          const result: OverviewQuestionResult | undefined = overviewQuestionResults.find(
            (q) => q.id === prevQuestion.id,
          );

          return {
            // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
            ...formFields,
            [`answer-${prevQuestion.id}`]: result?.answers ? result?.answers[0].value : "",
          };
        }, {}),
      [prevQuestions, overviewQuestionResults],
    );

    const currentAnswers = useRef<string[]>([]);

    const onSubmitAnswers = () => {
      const userAnswers: OverviewQuestionAnswer = prevQuestions.map((prevQuestion, index) => ({
        questionId: prevQuestion.id,
        exerciseId: prevQuestion.exerciseId,
        solution: [{ order: 0, value: currentAnswers.current[index] }],
      }));
      writingTest &&
        submitOverviewQuestionResults(
          +productId,
          writingTest.id,
          questionId,
          userAnswers,
          writingTest?.token || "",
        );

      setShowProgressButton(true);
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values: Record<string, string>) => {
          currentAnswers.current = Object.values(values);
          openModal(modalIds.progressiveTextQuestionModal);
        }}
      >
        <>
          <EmbeddedHtml rawHtml={content} sanitizeOptions={commonSanitizeOptions} />
          <StyledOverviewQuestion>
            <OverviewLetter
              highlightedQuestionId={highlightedQuestionId}
              prevQuestions={prevQuestions}
            />
            <OverviewAnswer
              highlightedQuestionId={highlightedQuestionId}
              prevQuestions={prevQuestions}
              setHighlightedQuestionId={(id) => setHighlightedQuestionId(id)}
            />
          </StyledOverviewQuestion>
          <ReflectiveQuestionsModal
            reflectionQuestions={reflectionQuestions ?? []}
            onCompleteReflectionQuestions={onSubmitAnswers}
          />
        </>
      </Formik>
    );
  },
);
