import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

export const StyledProductItem = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  width: 410px;

  button {
    margin-right: 24px;

    & > span > svg {
      color: ${({ theme }: ThemeProps) => theme.palette.feedback.error.brand};
    }
  }
`;

export const StyledProductDescription = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;

  span {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
    display: flex;

    &:first-of-type {
      color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
      font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
      margin-bottom: 8px;
    }

    button {
      margin: 0 0 0 13px;

      & > span:first-of-type {
        margin-bottom: 0;

        & > svg {
          color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
        }
      }
    }
  }
`;
