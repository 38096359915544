import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { ROLES } from "@infinitaslearning/module-he-common";
import { getCurrentUser } from "persistence/access";

import {
  ADMIN_ENVIRONMENT_ROUTES,
  GENERIC_ROUTES,
  PRODUCT_PAGES_ROUTES,
  TEACHER_HOME_PAGE_ROUTES,
} from "constants/routes";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { useProduct } from "hooks/useProduct";
import { useSelectedProductId } from "hooks/useSelectedProductId";

export const RootPath: React.FC = observer(() => {
  const user = getCurrentUser();
  const selectedProductId = useSelectedProductId();
  const { productsList, fetchProducts } = useProduct();

  // Fetch list of products of the current user
  useEffect(() => {
    if (user && !productsList) {
      fetchProducts();
    }
  }, [user, productsList, fetchProducts]);

  if (!productsList) {
    return null;
  }

  // Redirect to the appropiate route depending on the user's role
  switch (user?.role) {
    case ROLES.ADMIN:
      return <Redirect to={ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE} />;
    case ROLES.TEACHER:
      return <Redirect to={TEACHER_HOME_PAGE_ROUTES.HOME_PAGE} />;
    case ROLES.STUDENT:
      return (
        <Redirect
          to={
            !selectedProductId || !productsList.some(({ id }) => id === selectedProductId)
              ? PRODUCT_PAGES_ROUTES.PRODUCT_LIST
              : buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, {
                  productId: +selectedProductId,
                })
          }
        />
      );
    default:
      return <Redirect to={GENERIC_ROUTES.NOT_FOUND} />;
  }
});
