import type { AvailableLanguage } from "constants/language-constants";

export enum CalendarPosition {
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_RIGHT = "bottom-right",
}

export type DatePickerProps = {
  dataCy?: string;
  hasError?: boolean;
  language: AvailableLanguage;
  onChange: (date: Date) => void;
  value?: Date | null;
  disabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  calendarPosition?: CalendarPosition;
};
