import type React from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  type EnvironmentType,
  serviceENV as getCurrentEnvironment,
  isLocal,
  isTest,
} from "constants/env-constants";
import { HogesWebshopURL } from "constants/registration-links";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";

import { DismissableMessage } from "components/DismissableMessage/DismissableMessage";
import { Link } from "components/Link";

interface PurchasePromptProps {
  parentDataCy?: string;
}

export const PurchasePrompt: React.FC<PurchasePromptProps> = ({ parentDataCy }) => {
  const { t } = useTranslation("results");

  const currentEnv = getCurrentEnvironment() as EnvironmentType;

  return (
    <DismissableMessage
      dataCy="license-purchase-prompt"
      title={t("purchasePrompt.title")}
      onClose={() => {
        trackPlatformEvent({
          module: STUDENT_MODULES.TEST_RESULTS,
          itemId: PLATFORM_EVENT_TYPES.STUDENT_TEST_RESULT_EVENT_TYPES.CLICK_CLOSE_PURCHASE_PROMPT,
          elementId: `${parentDataCy}-license-purchase-prompt-close-button`,
        });
      }}
    >
      <Trans
        components={{
          webshopLink: (
            <Link
              dataCy="license-purchase-prompt-webshop-link"
              external={!isTest() && !isLocal()}
              to={HogesWebshopURL[currentEnv]}
              variant="underline"
              onClick={() => {
                trackPlatformEvent({
                  module: STUDENT_MODULES.TEST_RESULTS,
                  itemId:
                    PLATFORM_EVENT_TYPES.STUDENT_TEST_RESULT_EVENT_TYPES
                      .CLICK_PURCHASE_PROMPT_WEBSHOP,
                  elementId: `${parentDataCy}-license-purchase-prompt-webshop-link`,
                });
              }}
            />
          ),
        }}
        i18nKey={"purchasePrompt.content"}
        t={t}
      />
    </DismissableMessage>
  );
};
