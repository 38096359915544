import { SortableTable } from "components/SortableTable/SortableTable";
import { StyledCell, StyledHeaderCell } from "components/SortableTable/SortableTable.styled";
import type { InvitationStatus } from "models/product/StudentInvitation";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { Box } from "styles/helpers/layout";

export interface StyledStatusProps extends ThemeProps {
  status: InvitationStatus;
}

export const StyledStatus = styled(Box)<StyledStatusProps>`
  ${({ status, theme }: StyledStatusProps) =>
    status === "accepted"
      ? `
        border: 1px solid ${theme.palette.feedback.success.brand};
        background: ${theme.palette.feedback.success._950};
        color: ${theme.palette.feedback.success.brand};
      `
      : `
        border: 1px solid ${theme.palette.neutral._400};
        background: ${theme.palette.neutral._950};
        color: ${theme.palette.neutral._400};
  `};

  width: 9rem;
  text-align: center;
  border-radius: 0.2rem;
`;

export const StyledSortableTable = styled(SortableTable)`
  margin: 0 -1rem;

  ${StyledHeaderCell} {
    padding: 0.5rem 1rem;
  }

  ${StyledCell} {
    padding: 0.5rem 1rem;
  }
`;
