import type { OverviewAnswerResult, Status } from "models/exam/Exam";

export interface TopicsDetailItems {
  id: number;
  title: string;
}

export interface TopicsStatus {
  topicId: number;
  status: Status;
}

export interface Chapter {
  id: number;
  title: string;
  topics: TopicsDetailItems[];
}

export interface SubjectResponse {
  chapters: Chapter[];
  title: string;
  type: string;
  id: number;
  description: string | null;
}

type TopicStatus = TopicsDetailItems & { status: Status };

export type ChapterStatus = Chapter & { topics: TopicStatus[] };

export type WritingSubjectDetails = Omit<SubjectResponse, "chapters"> & {
  chapters: ChapterStatus[];
};

export enum ExerciseStatus {
  CORRECT = "correct",
  INCORRECT = "incorrect",
  NOT_STARTED = "initial",
  IN_PROGRESS = "inProgress",
}

export interface TopicExerciseStatus {
  exerciseId: number;
  status: ExerciseStatus;
}

export interface WritingSubjectStatus {
  subjectId: number;
  totalTopics: number;
  startedTopics: number;
  completedTopics: number;
}

export interface OverviewQuestionResult {
  id: number;
  answers: OverviewAnswerResult[];
}

export interface LinkedQuestionAnswer {
  exerciseId: number;
  questionId: number;
  solution: OverviewAnswerResult[];
}

export type OverviewQuestionAnswer = LinkedQuestionAnswer[];
