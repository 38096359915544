import type React from "react";
import { useTranslation } from "react-i18next";

import type {
  ScheduledTestResultList,
  ScheduledTestReviewPeriodTimer,
} from "models/exam/ScheduledTest";

import { ScheduledTestResultRow } from "../ScheduledTestResultRow/ScheduledTestResultRow";

import { StyledTable } from "./ScheduledTestResultsTable.styled";

interface ScheduledTestResultsTableProps {
  data: ScheduledTestResultList[];
  timers: ScheduledTestReviewPeriodTimer[];
}

export const ScheduledTestResultsTable: React.FC<ScheduledTestResultsTableProps> = ({
  data = [],
  timers = [],
}) => {
  const { t } = useTranslation("results");

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>{t("testResultOverviewTable.columns.date", "Date")}</th>
          <th>{t("testResultOverviewTable.columns.skillLevel", "Level")}</th>
          <th>{t("testResultOverviewTable.columns.testTitle", "Test")}</th>
          <th>{t("testResultOverviewTable.columns.results", "Results")}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data.map((scheduledTestOverview, index) => (
          <ScheduledTestResultRow
            key={scheduledTestOverview.id}
            isOddRow={index % 2 === 0}
            reviewPeriodTimeLeft={
              timers.find(({ id }) => id === scheduledTestOverview.id)?.counter || 0
            }
            scheduledTestOverview={scheduledTestOverview}
          />
        ))}
      </tbody>
    </StyledTable>
  );
};
