import format from "date-fns/format";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAdmin } from "hooks/useAdmin";
import { useModal } from "hooks/useModal";
import { useScheduledTests } from "hooks/useScheduledTests";

import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Input } from "components/Input";
import { SkeletonLoader } from "components/SkeletonLoader/SkeletonLoader";

import { TestType } from "constants/exam-constants";

import { Heading3 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";

import type { PathParams } from "../../../UserScheduledTestsGroup/UserScheduledTestsGroup";
import { StyledInnerWrapper, StyledReviewDateForm } from "./UpdateScheduledTest.styled";

type KeyValue = {
  [key: string]: string | number | boolean;
};

export interface UpdateScheduledTestProps {
  id: number;
  cumulative: boolean;
  reviewStartDate: string;
  reviewEndDate: string;
  testType: string;
  passingScore: number;
}

export const UpdateScheduledTest: React.FC<UpdateScheduledTestProps> = observer(
  ({ id, testType, cumulative, passingScore, reviewEndDate, reviewStartDate }) => {
    const [values, setValues] = useState<KeyValue>();
    const { date }: PathParams = useParams();
    const { closeModal } = useModal();

    const { updateScheduledTest } = useScheduledTests();
    const { t } = useTranslation("admin-environment");
    const { fetchScheduledTestsByDate } = useAdmin();

    useEffect(() => {
      !values &&
        setValues({
          cumulative,
          reviewStartDate,
          reviewEndDate,
          passingScore,
        });
    }, [values]);

    const Save = () => {
      if (!values) return;

      updateScheduledTest(id, {
        cumulative: values.cumulative as boolean,
        reviewStartDate: values.reviewStartDate.toString(),
        reviewEndDate: values.reviewEndDate.toString(),
        passingScore: +values.passingScore,
      }).then(() => {
        closeModal();
        fetchScheduledTestsByDate(format(new Date(date), "Y-MM-dd"));
      });
    };

    if (!values) return <SkeletonLoader $marginTop={1} />;

    return (
      <StyledInnerWrapper>
        <Heading3>{t("adminTools.scheduledTest.modal.testOptions.title")}</Heading3>
        {/* Allow admins to change the passing score */}
        <CssFlex alignItems="flex-start" flexDirection="column" gap={0.5}>
          <label
            id="admin-update-test-passing-score-label"
            htmlFor="admin-update-test-passing-score-input"
          >
            {t("adminTools.scheduledTest.modal.testOptions.passingScore", "Passing score")}
          </label>
          <Input
            id="admin-update-test-passing-score-input"
            aria-labelledby="admin-update-test-passing-score-label"
            dataCy="passing-score-input"
            max="100"
            min="1"
            postfixLabel="%"
            type="number"
            value={values.passingScore?.toString() ?? ""}
            onChange={(e) =>
              setValues((prevValues) => ({ ...prevValues, passingScore: e.target.value }))
            }
          />
        </CssFlex>
        {/* Only allow overriding cumulative setting for summative tests (don't apply for others) */}
        {testType === TestType.SUMMATIVE_TEST && (
          <Checkbox
            checked={(values?.cumulative as boolean) ?? false}
            dataCy="cumulative-option"
            id="cumulative-option"
            label={t("adminTools.scheduledTest.modal.testOptions.cumulative", "Cumulative")}
            onChange={(e) =>
              setValues((prevValues) => ({ ...prevValues, cumulative: e.target.checked }))
            }
          />
        )}
        {/* Allow admins to override review dates */}
        <Heading3>{t("adminTools.scheduledTest.modal.reviewPeriod.title")}</Heading3>
        <StyledReviewDateForm
          endDate={values?.reviewEndDate.toString() ?? ""}
          startDate={values?.reviewStartDate.toString() ?? ""}
          onReviewEndDateChange={(updatedReviewEndDate) => {
            setValues((prevValues) => ({ ...prevValues, reviewEndDate: updatedReviewEndDate }));
          }}
          onReviewStartDateChange={(updatedReviewStartDate) => {
            setValues((prevValues) => ({ ...prevValues, reviewStartDate: updatedReviewStartDate }));
          }}
        />
        <Button
          dataCy="save-update-test"
          disabled={!values}
          variant="primary"
          onClick={() => Save()}
        >
          {t("adminTools.scheduledTest.modal.submitButton")}
        </Button>
      </StyledInnerWrapper>
    );
  },
);
