import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledCorrectTextQuestionWord = styled.button<Props>`
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  background-color: transparent;
  color: ${(props: Props) => props.theme.palette.neutral.black};

  &[disabled] {
    cursor: default;
  }

  // hide next word when the word is merged or swapped
  &.is-merged + .CorrectTextQuestionWord,
  &.is-swapped + .CorrectTextQuestionWord {
    display: none;
  }

  &.is-missed,
  &.is-correct,
  &.is-incorrect {
    font-weight: 700;
    padding: 0 4px;
  }

  &.is-active {
    background-color: ${(props: Props) => props.theme.palette.secondary.brand};
    color: ${(props: Props) => props.theme.palette.neutral.white};
    font-weight: bold;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
  }

  &.is-changed {
    color: ${(props: Props) => props.theme.palette.secondary.brand};
    font-weight: 700;
  }

  &.is-correct {
    background-color: ${(props: Props) => props.theme.palette.feedback.success._800};
  }

  &.is-incorrect {
    background-color: #e48080;
  }

  &.is-missed {
    color: ${(props: Props) => props.theme.palette.feedback.error.brand};
    text-decoration: underline;
  }

  &.is-removed {
    text-decoration: line-through;
  }
  &.submitted {
    background: ${(props: Props) => props.theme.palette.secondary._950};
    border-color: ${(props: Props) => props.theme.palette.secondary.brand};
  }
`;
