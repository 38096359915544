import type React from "react";
import styled from "styled-components/macro";

const StyledEmptyGraphPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
`;

export const EmptyGraphPlaceholder: React.FC = ({ children }) => (
  <StyledEmptyGraphPlaceholder>
    <p>{children}</p>
  </StyledEmptyGraphPlaceholder>
);
