import type { BreadcrumbsRoute } from "components/Breadcrumbs/Breadcrumbs.model";
import { SkeletonLoader } from "components/SkeletonLoader/SkeletonLoader";
import { StatusTag } from "components/StatusTag/StatusTag";
import type { StatusTagVariant } from "components/StatusTag/StatusTag.styled";
import { TEACHER_INSIGHT_PAGE_ROUTES } from "constants/routes";
import { SkillsSelectorType } from "constants/teacher-constants";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useWriting } from "hooks/useWriting";
import { observer } from "mobx-react";
import { ForAccountHeading } from "pages/TeacherEnvironment/TeacherDashboard/components/ForAccountHeading/ForAccountHeading";
import type React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import type { BasicProgressForAccountPagePathParams } from "../../../BasicProgressForAccount/BasicProgressForAccount.model";
import { StyledTable } from "./WritingResultsForAccount.styled";

export const WritingResultsForAccount: React.FC = observer(() => {
  const { t } = useTranslation("teacher-dashboard");
  const { productId, accountId }: BasicProgressForAccountPagePathParams = useParams();
  const {
    writingResultsForAccount,
    writingSubjectsResultsOverview,
    fetchWritingResultsForAccount,
    fetchWritingSubjectsResultsOverview,
  } = useWriting();
  const { getStudentTermByDomain } = useDomainHandler();

  useEffect(() => {
    if (!writingSubjectsResultsOverview) fetchWritingSubjectsResultsOverview(+productId, {});
  }, [productId, writingSubjectsResultsOverview]);

  useEffect(() => {
    fetchWritingResultsForAccount(+productId, +accountId);
  }, [productId, accountId]);

  // We don't want to show cases if all of the subjects don't have any
  const shouldShowCases = useMemo(() => {
    return writingResultsForAccount?.subjectDetails?.some((subject) => subject.totalCases > 0);
  }, [productId, writingResultsForAccount?.subjectDetails]);

  if (!writingResultsForAccount || !writingSubjectsResultsOverview)
    return <SkeletonLoader $marginTop={2} count={8} />;

  const { email, firstName, lastName, middleName, studentNumber, subjectDetails } =
    writingResultsForAccount;
  const fullName = middleName
    ? `${firstName} ${middleName} ${lastName}`
    : `${firstName} ${lastName}`;

  const breadCrumbRoutes: BreadcrumbsRoute[] = [
    {
      path: TEACHER_INSIGHT_PAGE_ROUTES.WRITING_SKILLS,
      breadcrumb: t("writingResults.title"),
    },
    {
      path: TEACHER_INSIGHT_PAGE_ROUTES.WRITING_RESULTS_FOR_ACCOUNT,
      breadcrumb: fullName,
    },
  ];

  const getSubjectStatusTagType = (subjectStatus: string): StatusTagVariant => {
    switch (subjectStatus) {
      case "notStarted":
        return "default";
      case "completed":
        return "success";
      default:
        return "info";
    }
  };

  const getSubjectProgressText = (subjectStatus: string): string => {
    switch (subjectStatus) {
      case "notStarted":
        return t("basicProgress.forWritingAccount.progressType.notStarted");
      case "completed":
        return t("basicProgress.forWritingAccount.progressType.completed");
      default:
        return t("basicProgress.forWritingAccount.progressType.inProgress");
    }
  };

  return (
    <ForAccountHeading
      breadCrumbRoutes={breadCrumbRoutes}
      email={email}
      fullName={fullName}
      skillType={SkillsSelectorType.WRITING_SKILLS}
      studentIds={writingSubjectsResultsOverview.studentIds}
      studentNumber={studentNumber ?? ""}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>{t("basicProgress.forWritingAccount.topic", "Topic")}</th>
            <th>{t("basicProgress.forWritingAccount.progress", "Progress")}</th>
            <th>{t("basicProgress.forWritingAccount.completedTopics", "Completed topics")}</th>
            <th>
              {t("basicProgress.forWritingAccount.completedExercises", "Completed exercises")}
            </th>
            {shouldShowCases && (
              <th data-cy="completed-cases-head">
                {t("basicProgress.forWritingAccount.completedCases", "Completed cases")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {subjectDetails.length === 0 && (
            <tr>
              <td colSpan={3} data-cy="no-writing-exercises-linked">
                {t("writingResults.noResultsFound", { studentTerm: getStudentTermByDomain() })}
              </td>
            </tr>
          )}
          {subjectDetails.map(
            ({
              subjectId,
              subjectTitle,
              totalExercises,
              completedExercises,
              totalCases,
              completedCases,
              totalTopics,
              completedTopics,
              subjectStatus,
            }) => (
              <tr key={subjectId} data-cy="student-writing-subject-progress-row">
                <td data-cy={`subject-${subjectId}-title`}>{subjectTitle}</td>
                <td data-cy={`subject-${subjectId}-status`}>
                  <StatusTag
                    label={getSubjectProgressText(subjectStatus)}
                    variant={getSubjectStatusTagType(subjectStatus)}
                  />
                </td>
                <td
                  data-cy={`subject-${subjectId}-topic-stats`}
                >{`${completedTopics}/${totalTopics}`}</td>
                <td
                  data-cy={`subject-${subjectId}-exercise-stats`}
                >{`${completedExercises}/${totalExercises}`}</td>
                {shouldShowCases && (
                  <td
                    data-cy={`subject-${subjectId}-assignment-stats`}
                  >{`${completedCases}/${totalCases}`}</td>
                )}
              </tr>
            ),
          )}
        </tbody>
      </StyledTable>
    </ForAccountHeading>
  );
});
