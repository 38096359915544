import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/mixins";

import type { ThemeProps } from "models/styles/theme";
import { Heading6 } from "styles/elements/Headings";
import { Box, CssIcon } from "styles/helpers/layout";

export const StyledChapterOverview = styled(Box)<ThemeProps>`
  .accordion-content-body {
    display: grid;
    row-gap: 1rem;
  }
`;

export const StyledChapterTitle = styled(Heading6)`
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold};
  color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
  margin-bottom: 0;
`;

export const StyledProgressPercentage = styled.b<ThemeProps>`
  width: 2rem;
  display: inline-block;
  margin: 0 0.25rem 0 0.75rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._12};
`;

export const TopicButton = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  height: ${calculateRem(40)};
  align-items: center;
  grid-template-areas: 'Name Level';
  padding: 0 1.5rem 2rem 4rem;

  ${CssIcon} {
    color: ${(props: ThemeProps) => props.theme.palette.feedback.success._600};
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props: ThemeProps) => props.theme.palette.neutral._999};
  }

  &.disabled {
    color: ${(props: ThemeProps) => props.theme.palette.neutral._600};
    cursor: not-allowed;
    background-color: transparent;
  }
`;
