import type SeparatorLineProps from "components/SeparatorLine/SeparatorLine.model";
import { StyledSeparatorLine } from "components/SeparatorLine/SeparatorLine.styled";
import type React from "react";
import { CssFlex } from "styles/helpers/layout";
import { StyledText } from "./SeparatorLineText.styled";

export interface SeparatorLineTextProps extends SeparatorLineProps {
  text: string;
  verticalOffset?: number;
}

export const SeparatorLineText: React.FC<SeparatorLineTextProps> = ({
  direction = "vertical",
  verticalOffset = 0,
  color,
  height,
  width,
  text,
}) => (
  <CssFlex
    alignItems="center"
    flexDirection={direction === "vertical" ? "column" : "row"}
    gap={0.5}
    justifyContent="center"
  >
    <StyledSeparatorLine
      color={color}
      direction={direction}
      height={height && height / 2 + verticalOffset}
      width={width}
    />
    <StyledText>{text}</StyledText>
    <StyledSeparatorLine
      color={color}
      direction={direction}
      height={height && height / 2 + verticalOffset}
      width={width}
    />
  </CssFlex>
);
