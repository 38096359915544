import styled from "styled-components/macro";

interface Props {
  isBlock?: boolean;
}

export const StyledImmersiveReaderButton = styled.span<Props>`
  height: 3rem;
  width: 3rem;
  padding: 0.5rem;
  transition: background-color 0.2s;
  cursor: pointer;

  ${(props: Props) => props.isBlock && "display: block;"}

  &:hover {
    background-color: rgba(39, 104, 101, 0.08);
    border-radius: 4px;
  }
`;
