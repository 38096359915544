import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { ParagraphLarge } from "styles/elements/Texts";

export const StyledClickToCopy = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._950};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;

  color: ${({ theme }: ThemeProps) => theme.palette.primary.brand};

  svg {
    margin-left: 1rem;
    fill: ${({ theme }: ThemeProps) => theme.palette.secondary.brand};
  }
`;

export const StyledCopyableText = styled(ParagraphLarge)`
  color: ${({ theme }: ThemeProps) => theme.palette.secondary.brand};
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
`;

export const StyledCopiedSuccessText = styled.span`
  color: #28a745;
`;
