import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { ParagraphLarge } from "styles/elements/Texts";

export const StyledParagraph = styled(ParagraphLarge)<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral._500};
  margin-bottom: 1.5rem;
`;

export const StyledEmailWrapper = styled.span<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral.black};
  background: ${({ theme }: Props) => theme.palette.neutral._950};
  width: auto;
  padding: 1rem;
  font-weight: 700;
`;

export const StyledWrapper = styled.div<Props>`
  margin-top: 2rem;
`;
