import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { CountdownTimer } from "components/CountdownTimer/CountdownTimer";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";
import { useScheduledTests } from "hooks/useScheduledTests";

import { AccessibilityMenuButton } from "components/AccessibilityMenuButton/AccessibilityMenuButton";
import { AccessibilityMenuModal } from "components/AccessibilityMenuModal/AccessibilityMenuModal";
import { TestHeader } from "pages/TestEnvironment/components/TestHeader/TestHeader";
import type { TestPagePathParams } from "../../subpages/TestOverviewPage/TestOverviewPage.model";
import type { ScheduledTestHeaderProps } from "./ScheduledTestHeader.model";

export const ScheduledTestHeader: React.FC<ScheduledTestHeaderProps> = observer(
  ({ counter, title, onClose }) => {
    const { t } = useTranslation("test-environment");
    const { scheduledTestStatusAndTimeLeft } = useScheduledTests();
    const history = useHistory();
    const { code }: TestPagePathParams = useParams();

    return (
      <>
        <AccessibilityMenuModal />
        <TestHeader
          rightComponent={
            scheduledTestStatusAndTimeLeft?.counter !== undefined && (
              <div>
                <AccessibilityMenuButton />
                <CountdownTimer
                  prefix={t("navbar.timeLeft", "Time left: ")}
                  secondsLeft={counter}
                  warningTimeSeconds={10 * 60}
                  onCountdownFinish={() =>
                    history.replace(
                      buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_COMPLETED_PAGE, { code }),
                    )
                  }
                />
              </div>
            )
          }
          title={title}
          onClose={onClose}
        />
      </>
    );
  },
);
