import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/mixins";

import type { ThemeProps as Props } from "models/styles/theme";

import { SvgIconReact } from "components/SvgIconReact";

import backgroundImage from "assets/images/bg-bottom-pink-gradient.svg";

export const StyledPageLayout = styled.div<Props>`
  min-height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding: 3rem;

  background-image: ${() => `url(${backgroundImage})`};
  background-position: bottom right;
  background-repeat: no-repeat;
`;

export const StyledLogo = styled(SvgIconReact)`
  height: ${calculateRem(40)};
  width: auto;
`;

export const StyledContainer = styled.main<Props>`
  flex-grow: 1;
  width: 62rem;
  max-width: 100%;
  padding-top: 13rem;

  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  align-self: center;
  align-items: center;
`;

export const StyledText = styled.section<Props>`
  text-align: center;
`;

export const StyledLink = styled.a<Props>`
  font-weight: bold;
  text-decoration: none;
  color: ${(props: Props) => props.theme.palette.neutral.black};
`;
