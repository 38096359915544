import type React from "react";

import type LinkProps from "./Link.model";
import { StyledLink } from "./Link.styled";

export const Link: React.FC<LinkProps> = ({
  variant = "default",
  borderVariant = "default",
  fontColor,
  size = "default",
  to,
  children,
  dataCy,
  isBlock = false,
  disabled = false,
  external = false,
  onClick,
  ...props
}: LinkProps) => {
  return (
    <StyledLink
      $borderVariant={borderVariant}
      $disabled={disabled}
      $fontColor={fontColor}
      $isBlock={isBlock}
      $size={size}
      $variant={variant}
      data-cy={dataCy}
      target={external ? "_blank" : ""}
      to={external ? { pathname: to } : to}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledLink>
  );
};
