import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "components/Link";
import { HogesPrivacyURL, HogesTermsURL } from "constants/registration-links";

import { StyledFooter } from "./TermsFooter.styled";

export const TermsFooter: FC = () => {
  const { t } = useTranslation("auth-pages");

  return (
    <StyledFooter>
      <Link external to={HogesTermsURL} variant="tertiary">
        {t("registrationPage.footer.links.terms", "Terms & Conditions")}
      </Link>
      {" | "}
      <Link external to={HogesPrivacyURL} variant="tertiary">
        {t("registrationPage.footer.links.privacy", "Privacy statement")}
      </Link>
      {" | "}
      {t("registrationPage.footer.copyright", "DigiTaalbedrijf B.V.")}
    </StyledFooter>
  );
};
