import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

export const StyledArrowContainer = styled.span`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  align-items: center;
  justify-content: center;
`;

export const StyledArrowButton = styled.button<ThemeProps>`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
  [disabled] {
    cursor: not-allowed;
  }
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  margin: 0;
  svg {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._600};
    &.active {
      color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
    }
    width: 10px;
  }
`;
