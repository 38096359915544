import { useDomainHandler } from "hooks/useDomainHandler";
import { type FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";

import { Heading3 } from "styles/elements/Headings";
import {
  StyledButtonsWrapper,
  StyledCloseIcon,
  StyledDarkOverlay,
  StyledDescription,
  StyledExistingAccounts,
  StyledExistingAccountsList,
  StyledInvitedInnerOverview,
  StyledInvitedOverview,
  StyledNumberWrapper,
  StyledOutlinedButton,
  StyledWindow,
} from "./Invitation.styled";

export interface InvitationProps {
  teacherEmail: string;
  ownersList: string[];
  invitedStudentsCount: number;
  existingOwnersCount: number;
  onSubmit: () => void;
  onCancel: () => void;
  onClose: () => void;
}

export const Invitation: FC<InvitationProps> = ({
  invitedStudentsCount,
  existingOwnersCount,
  ownersList = [],
  onSubmit,
  onCancel,
  onClose,
}) => {
  const [isInvitationSent, setIsInvitationSent] = useState<boolean>(false);
  const [isExistingOwnersOpen, setIsExistingOwnersOpen] = useState<boolean>(false);
  const { t } = useTranslation("teacher-dashboard");
  const { getStudentTermByDomain } = useDomainHandler();

  const description = isInvitationSent ? (
    <Trans
      i18nKey="studentLicense.invitation.invitationSent.description"
      t={t}
      values={{ studentTerm: getStudentTermByDomain() }}
    >
      De uitnodigingen zijn verstuurd. Nu is de cursist aan zet om een account aan te maken en te
      starten met oefenen!
      <br />
      <br />
      Als een cursist zelf al toegang had tot het product, dan kun je dat hieronder zien. Deze
      producten worden natuurlijk niet gefactureerd.
    </Trans>
  ) : (
    t("studentLicense.invitation.sendInvitation.description", {
      studentTerm: getStudentTermByDomain({ usePlural: true }),
    })
  );

  return (
    <StyledDarkOverlay>
      <StyledWindow>
        <StyledCloseIcon onClick={isInvitationSent ? onClose : onCancel}>
          <FontAwesomeIcon iconName="fal fa-times" iconSize="2x" />
        </StyledCloseIcon>
        <Heading3>
          {isInvitationSent
            ? t("studentLicense.invitation.invitationSent.title", {
                studentTerm: getStudentTermByDomain({ usePlural: true }),
              })
            : t("studentLicense.invitation.sendInvitation.title")}
        </Heading3>
        <StyledDescription>{description}</StyledDescription>
        {isInvitationSent && (
          <StyledInvitedOverview>
            <StyledInvitedInnerOverview>
              <div>
                <span>
                  {t("studentLicense.invitation.invitationSent.invitedStudents", {
                    studentTerm: getStudentTermByDomain({ usePlural: true }),
                  })}
                </span>
                <StyledNumberWrapper $marginRight="2.8rem" data-cy="invited-students-count">
                  {invitedStudentsCount}
                </StyledNumberWrapper>
              </div>
              <hr />
              <div>
                <span>{t("studentLicense.invitation.invitationSent.existingAccounts")}</span>
                <StyledExistingAccounts>
                  <StyledNumberWrapper>{existingOwnersCount}</StyledNumberWrapper>
                  <FontAwesomeIcon
                    iconName={`fas fa-chevron-${isExistingOwnersOpen ? "up" : "down"}`}
                    onClick={() => setIsExistingOwnersOpen(!isExistingOwnersOpen)}
                  />
                </StyledExistingAccounts>
              </div>
              {isExistingOwnersOpen && (
                <StyledExistingAccountsList>
                  {ownersList.map((owner) => (
                    <div key={owner}>{owner}</div>
                  ))}
                </StyledExistingAccountsList>
              )}
            </StyledInvitedInnerOverview>
          </StyledInvitedOverview>
        )}
        <StyledButtonsWrapper>
          {!isInvitationSent ? (
            <>
              <StyledOutlinedButton onClick={onCancel}>
                {t("studentLicense.invitation.sendInvitation.modify")}
              </StyledOutlinedButton>
              <Button
                dataCy="send-invitation"
                variant="primary"
                onClick={() => {
                  setIsInvitationSent(true);
                  onSubmit();
                }}
              >
                {t("studentLicense.invitation.sendInvitation.send")}
              </Button>
            </>
          ) : (
            <Button dataCy="invitation-close" variant="primary" onClick={onClose}>
              {t("studentLicense.invitation.invitationSent.close")}
            </Button>
          )}
        </StyledButtonsWrapper>
      </StyledWindow>
    </StyledDarkOverlay>
  );
};
