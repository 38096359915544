import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import type { ContentAPI, ResultsAPI } from "generated/types";
import type {
  AccountDetailsResponse,
  CreateNewAccountRequest,
  CreateSchoolRequest,
  FilteredAccountsResponse,
  ProductSkillLevelResponse,
  RequestFilteredAccounts,
  RequestScheduleTests,
  RequestSchools,
  RequestSimulateLoginAsTeacher,
  ScheduledTestsAdminResponse,
  SchoolResponse,
  SchoolsResponse,
  SimulateLoginAsTeacherResponse,
  UpdateAccountDetailsRequest,
  UpdateSchoolRequest,
} from "models/admin/Admin";
import type { SchoolType } from "models/auth/Login";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type { TransferResult } from "pages/AdminEnvironment/AdminPage/subpages/UserToolsPage/components/TransferResults/TransferResults.schema";
import cookieStorage from "persistence";
import { handlingResponse, logError } from "repositories/utils";

const fetchFilteredAccounts = async ({
  page,
  input,
  role,
  education,
  school,
  results,
}: RequestFilteredAccounts): Promise<FilteredAccountsResponse> => {
  try {
    const filteredAccounts = await axios.get("/api/admin/accounts", {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        page,
        input,
        role,
        education,
        school,
        results,
      },
    });
    return handlingResponse<FilteredAccountsResponse>(
      [200],
      "Error retrieving filtered accounts",
      "filteredAccountsError",
    )(filteredAccounts);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_FILTERED_ACCOUNTS_FAILED });
  }
};

const fetchAccountDetails = async (accountId: string): Promise<AccountDetailsResponse> => {
  try {
    const accountDetails = await axios.get(`/api/admin/accounts/${accountId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<AccountDetailsResponse>(
      [200],
      "Error retrieving the requested account",
      "accountDetailsError",
    )(accountDetails);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_ACCOUNT_DETAILS_FAILED });
  }
};

const updateAccountDetails = async (
  accountDetails: UpdateAccountDetailsRequest,
): Promise<AccountDetailsResponse> => {
  try {
    const updatedAccountDetails = await axios.patch("/api/admin/account", accountDetails, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<AccountDetailsResponse>(
      [200],
      "Error updating account from admin panel",
      "updateAccountAdminPanelError",
    )(updatedAccountDetails);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPDATE_ACCOUNT_ADMIN_PANEL });
  }
};

const createNewAccount = async (accountDetails: CreateNewAccountRequest): Promise<void> => {
  try {
    await axios.post("/api/admin/account/create", accountDetails, {
      headers: { Authorization: cookieStorage.getToken() },
    });
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.CREATE_ACCOUNT_ADMIN_PANEL });
  }
};

const fetchScheduledTests = async ({
  testStatus,
  page,
  resultsPerPage,
  teacher,
  productId,
  skillLevel,
  input,
}: RequestScheduleTests): Promise<ScheduledTestsAdminResponse> => {
  try {
    const scheduledAdminTests = await axios.get("/api/admin/scheduled-tests", {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        status: testStatus || undefined,
        page,
        results: resultsPerPage,
        creatorId: teacher || undefined,
        productId,
        skillLevel,
        code: input || undefined,
      },
    });

    return handlingResponse<ScheduledTestsAdminResponse>(
      [200],
      "Error retrieving admin scheduled tests",
      "scheduledTestsAdminError",
    )(scheduledAdminTests);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHEDULED_ADMIN_TESTS_FAILED });
  }
};

const fetchProductSkillLevels = async (): Promise<Array<ProductSkillLevelResponse>> => {
  try {
    const productsAdminSkills = await axios.get("/api/admin/products/levels", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<Array<ProductSkillLevelResponse>>(
      [200],
      "Error retrieving admin products skill levels",
    )(productsAdminSkills);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_ADMIN_PRODUCTS_SKILLS_FAILED });
  }
};

const fetchSchoolList = async (
  payload: RequestSchools,
  type?: SchoolType,
): Promise<SchoolsResponse> => {
  try {
    const schools = await axios.get("/api/schools", {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        searchTerm: payload.input || undefined,
        type,
      },
    });
    return handlingResponse<SchoolsResponse>(
      [200],
      "Error retrieving schools",
      "schoolsError",
    )(schools);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHOOLS_FAILED });
  }
};

const fetchSchoolDetails = async (schoolId: string): Promise<SchoolResponse> => {
  try {
    const school = await axios.get(`/api/school/${schoolId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<SchoolResponse>(
      [200],
      "Error retrieving school",
      "schoolError",
    )(school);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHOOL_FAILED });
  }
};

const createSchool = async (payload: CreateSchoolRequest): Promise<void> => {
  try {
    await axios.post("/api/school", payload, {
      headers: { Authorization: cookieStorage.getToken() },
    });
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.CREATE_SCHOOL_FAILED });
  }
};

const updateSchool = async (schoolId: string, payload: UpdateSchoolRequest): Promise<void> => {
  try {
    await axios.patch(`/api/school/${schoolId}`, payload, {
      headers: { Authorization: cookieStorage.getToken() },
    });
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPDATE_SCHOOL_FAILED });
  }
};

const simulateLoginAsTeacher = async ({
  id,
}: RequestSimulateLoginAsTeacher): Promise<SimulateLoginAsTeacherResponse> => {
  try {
    const simulateLoginReponse = await axios.post(
      "/api/admin/signin/simulate",
      { id },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<SimulateLoginAsTeacherResponse>(
      [200],
      "Error simulating login as teacher",
      "simulateLoginAsTeacherError",
    )(simulateLoginReponse);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.SIMULATE_LOGIN_AS_TEACHER_FAILED });
  }
};

const fetchScheduledTestsByDate = async (
  date: string,
): Promise<Array<ContentAPI.ScheduledTestByDate>> => {
  try {
    const scheduledTestsByDate = await axios.get(`/api/admin/scheduled-tests/${date}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<Array<ContentAPI.ScheduledTestByDate>>(
      [200],
      "Error retrieving the scheduled tests by date",
      "scheduledTestsByDateError",
    )(scheduledTestsByDate);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_SCHEDULED_ADMIN_TESTS_BY_DATE_FAILED,
    });
  }
};

const fetchAllParticipatedTestCodes = async (email: string): Promise<string[]> => {
  try {
    const result = await axios.get(`/api/admin/all-participated-test-codes/account/${email}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<string[]>(
      [200],
      "Error retrieving the participated test codes",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_ALL_PARTICIPATED_TEST_CODES_FAILED,
    });
  }
};

const transferResults = async ({
  sourceEmail,
  targetEmail,
  testCode,
}: TransferResult): Promise<ResultsAPI.ScheduledTestSessionTransferResponse> => {
  try {
    const transferResultsResponse = await axios.put(
      `/api/scheduled-test/${testCode}/session/transfer`,
      { sourceEmail, targetEmail },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<ResultsAPI.ScheduledTestSessionTransferResponse>(
      [200],
      "Error transfering the results",
    )(transferResultsResponse);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.SIMULATE_LOGIN_AS_TEACHER_FAILED });
  }
};

const deleteAccounts = async (emails: string[]): Promise<void> => {
  try {
    await axios.delete("/api/accounts", {
      data: emails,
      headers: { Authorization: cookieStorage.getToken() },
    });
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.DELETE_ACCOUNTS_FAILED });
  }
};

const changeScheduledTestOwner = async (testCode: string, targetEmail: string): Promise<void> => {
  try {
    const transferResultsResponse = await axios.put(
      `/api/scheduled-test/${testCode}/transfer`,
      { targetEmail },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<void>(
      [200],
      `Error transferring test ${testCode} to ${targetEmail}`,
    )(transferResultsResponse);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.TRANSFER_SCHEDULED_TEST_OWNERSHIP_FAILED,
    });
  }
};

export {
  fetchFilteredAccounts,
  fetchAccountDetails,
  updateAccountDetails,
  createNewAccount,
  fetchScheduledTests,
  fetchProductSkillLevels,
  simulateLoginAsTeacher,
  fetchScheduledTestsByDate,
  deleteAccounts,
  fetchAllParticipatedTestCodes,
  transferResults,
  changeScheduledTestOwner,
  fetchSchoolList,
  fetchSchoolDetails,
  createSchool,
  updateSchool,
};
