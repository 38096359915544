import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";

export interface EmailWrapperProps extends Props {
  isIncorrect: boolean;
}

export const StyledInnerWrapper = styled.div<Props>`
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 80%;

  > ${Heading3} {
    font-weight: normal;
    margin-bottom: 0.1rem;
  }
`;

export const StyledText = styled.div<Props>`
  width: 75%;
`;

export const StyledEmailsBox = styled(CssFlex)<Props>`
  cursor: default;
  overflow-y: auto;
  border: 1px solid black;

  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }: Props) => theme.palette.neutral._600};
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 0.5rem;
  }
`;

export const StyledEmailBox = styled(CssFlex)<EmailWrapperProps>`
  display: flex;
  height: 2rem;
  align-items: center;
  border: 1px solid
    ${({ isIncorrect, theme }: EmailWrapperProps) =>
      isIncorrect ? theme.palette.feedback.error.brand : theme.palette.feedback.success._800};
  padding: 0.7rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const StyledRemoveEmailIcon = styled(FontAwesomeIcon)<Props>`
  padding-right: 0.7rem;
  cursor: pointer;
`;

export const StyledInvalidEmail = styled.div<Props>`
  background: ${({ theme }: Props) => theme.palette.neutral.black};
  color: white;
  padding: 1rem;
  width: 70%;
  margin-top: 1rem;
`;

export const StyledEmailInputBox = styled(CssFlex)<Props>`
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral.black};

  > i {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

export const StyledEmailInput = styled.input<Props>`
  width: 90%;
  padding: 1rem;
  outline: none;
  border: none;
`;
