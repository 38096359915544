import { Modal } from "components/Modal";
import { useModal } from "hooks/useModal";
import type React from "react";
import { ActionMessage } from "./ActionMessage";
import type { ActionMessageProps } from "./ActionMessage.model";

interface ActionMessageModalProps extends ActionMessageProps {
  modalId: string;
}

export const ActionMessageModal: React.FC<ActionMessageModalProps> = ({ modalId, ...props }) => {
  const { closeModal, isModalOpen } = useModal();
  const onCloseModalHandler = () => {
    closeModal();
    if (props.cancelButtonClick) {
      props.cancelButtonClick();
    }
  };
  return (
    <Modal
      wrapInModalBox
      dataCy={modalId}
      isOpen={isModalOpen(modalId)}
      onClose={onCloseModalHandler}
    >
      <ActionMessage {...props} />
    </Modal>
  );
};
