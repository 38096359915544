import type React from "react";
import { useTranslation } from "react-i18next";
import { CertificateResultsPageLayout } from "./components";

export const CertificateTestResultsPage: React.FC = () => {
  const { t } = useTranslation("results");

  return (
    <CertificateResultsPageLayout
      buttonText={t("certificatesTestPage.button", '"Continue practicing')}
      dataCy="certificate-test-results"
      noResultsMessage={t(
        "placeholder.noCertificatesData",
        "You not have any certificates or badges yet",
      )}
      subtitle={t("certificatesTestPage.subtitle", "Download and share your results on social")}
      title={t("certificatesTestPage.title", "Certificates and Badges")}
    />
  );
};
