import { differenceInDays, format } from "date-fns";
import { observer } from "mobx-react";
import type React from "react";
import { type FC, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import {
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  ROWS_PER_PAGE,
} from "constants/misc-constants";
import { InvitationStatus } from "models/product/StudentInvitation";

import { useDomainHandler } from "hooks/useDomainHandler";
import { useProduct } from "hooks/useProduct";
import { useReactTooltip } from "hooks/useReactTooltip";
import { useLanguage } from "hooks/useSelectedLanguage";

import { IconButton } from "components/IconButton/IconButton";
import { Paginator } from "components/PaginatorRemake/Paginator";
import { Select } from "components/SelectUI/Select";
import type { TableRow } from "components/SortableTable/SortableTable.model";
import { CssFlex } from "styles/helpers/layout";

import {
  StyledHeader,
  StyledSelect,
  StyledSelectContainer,
  StyledTableContainer,
  StyledTableHeader,
} from "pages/AdminEnvironment/AdminPage/subpages/UsersPage/components/StudentsTable/StudentsTable.styled";
import {
  StyledField,
  StyledInput,
} from "pages/AdminEnvironment/AdminPage/subpages/UsersPage/components/UsersFilter/UsersFilter.styled";
import { StyledSortableTable, StyledStatus } from "./InvitationList.styled";

type InviteQueryParams = {
  page: number;
  resultsPerPage: number;
  email: string | null;
  status: string;
};

export const InvitationList: FC = observer(() => {
  useReactTooltip();
  const { currentLanguage } = useLanguage();
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("teacher-dashboard");
  const { getStudentTermByDomain } = useDomainHandler();

  const { loading, invitationList, fetchInvitationList, deleteInviteById } = useProduct();

  const [filters, setFilters] = useState<InviteQueryParams>({
    email: null,
    status: "all",
    page: DEFAULT_PAGE,
    resultsPerPage: DEFAULT_ROWS_PER_PAGE,
  });

  const { email, status, page, resultsPerPage } = filters;
  const accepted = status === "all" ? undefined : status === InvitationStatus.ACCEPTED;

  // Define debounced callback method to avoid too many calls to endpoint while user is typing in search box
  const refreshSentInviteList = useDebouncedCallback(
    () => fetchInvitationList({ page, resultsPerPage, accepted, email }),
    DEFAULT_DEBOUNCE_TIME,
    { leading: true },
  );

  // Trigger request whenever any of the filter values change
  useEffect(() => {
    refreshSentInviteList();
  }, [page, resultsPerPage, accepted, email]);

  // Delete an invite and refresh list afterwards
  const onDeleteButtonClick = (inviteId: string) => {
    deleteInviteById(inviteId).then(() => {
      refreshSentInviteList();
    });
  };

  // Options for the status selector (will refresh if user changes their UI language)
  const inviteStatusOptions = useMemo(
    () => [
      {
        value: "all",
        label: t("studentLicense.invitationList.filters.status.options.all"),
      },
      {
        value: "accepted",
        label: t("studentLicense.invitationList.filters.status.options.accepted"),
      },
      {
        value: "awaiting",
        label: t("studentLicense.invitationList.filters.status.options.awaiting"),
      },
    ],
    [currentLanguage],
  );

  const numPages = Math.ceil(invitationList.totalInvitations / resultsPerPage);
  const tableData = useMemo<TableRow[]>(
    () =>
      invitationList.invitations.map(({ id, email, status, createdOn }) => {
        const isRemovableInvite =
          status === InvitationStatus.PENDING &&
          differenceInDays(new Date(), new Date(createdOn)) <= 14;

        return {
          id: `invite-${id}-row`,
          cells: [
            {
              id: `invite-${id}-email`,
              content: email,
              dataCy: `invite-${id}-email`,
            },
            {
              id: `invite-${id}-status`,
              dataCy: `invite-${id}-status`,
              content: (
                <StyledStatus
                  data-status={status}
                  data-tip={
                    status === InvitationStatus.ACCEPTED
                      ? t("studentLicense.invitationList.tooltip.acceptedInvite")
                      : undefined
                  }
                  pl={2}
                  pr={2}
                  status={status}
                >
                  {t(`studentLicense.invitationList.${status}`)}
                </StyledStatus>
              ),
            },
            {
              id: `invite-${id}-sent-date`,
              dataCy: `invite-${id}-sent-date`,
              content: (
                <span
                  data-tip={
                    isRemovableInvite
                      ? t("studentLicense.invitationList.tooltip.removableInvite")
                      : t("studentLicense.invitationList.tooltip.removalExpired")
                  }
                >
                  {format(new Date(createdOn), "dd-MM-yyyy")}
                </span>
              ),
            },
            {
              id: `invite-${id}-action-buttons`,
              content: (
                <CssFlex alignItems="center" padding="1rem">
                  {/* Delete feature is only available for pending invites created in the past 14 days */}
                  {isRemovableInvite && (
                    <IconButton
                      aria-label={tCommon("iconButtonLabel.delete")}
                      dataCy={`delete-invite-${id}-button`}
                      disabled={loading}
                      fontColor="grey"
                      iconName="trashIcon"
                      onClick={() => onDeleteButtonClick(id)}
                    />
                  )}
                </CssFlex>
              ),
            },
          ],
        };
      }),
    [invitationList.invitations, loading],
  );

  // Update filters object passing function to "setFilters" to ensure we get the right prev state
  // @see https://stackoverflow.com/a/69945784/419194
  const updateFilterValue = (key: keyof InviteQueryParams, value: string | number | null) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  return (
    <>
      <CssFlex>
        <StyledField>
          <label htmlFor="invited-student-email-search-input">
            {t("studentLicense.invitationList.filters.studentSearch.label", {
              studentTerm: getStudentTermByDomain(),
            })}
          </label>
          <StyledInput
            data-cy="accounts-filter-input"
            id="invited-student-email-search-input"
            value={email ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateFilterValue("email", e.target.value)
            }
          />
        </StyledField>
        <StyledField>
          <label id="invite-status-selector-label" htmlFor="invite-status-selector">
            {t("studentLicense.invitationList.filters.status.label")}
          </label>
          <Select
            id="invite-status-selector"
            aria-labelledby="invite-status-selector-label"
            dataCy="invite-status-selector"
            options={inviteStatusOptions}
            showPlaceholderInOptionList={false}
            value={status}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              updateFilterValue("status", event.target.value);
            }}
          />
        </StyledField>
      </CssFlex>
      <StyledTableContainer>
        <StyledTableHeader>
          <StyledHeader data-cy="header">
            <Trans
              components={{
                inviteCountWrapper: <span data-cy="total-invitations" />,
              }}
              i18nKey={"studentLicense.invitationList.totalInvitations"}
              t={t}
              values={{ totalInvitations: invitationList.totalInvitations }}
            />
          </StyledHeader>
          <StyledSelectContainer>
            {t("studentLicense.invitationList.show")}
            <StyledSelect
              ariaLabel="results-selector"
              dataCy="results-selector"
              defaultValue={DEFAULT_ROWS_PER_PAGE}
              options={ROWS_PER_PAGE.map((rows) => ({
                value: rows,
                label: `${rows}`,
              }))}
              value={resultsPerPage}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                updateFilterValue("resultsPerPage", Number.parseInt(e.target.value, 10))
              }
            />
          </StyledSelectContainer>
        </StyledTableHeader>
        <StyledSortableTable
          flexStartCellHeaders
          headerCells={[
            {
              id: "invite-student-email-column",
              title: t("studentLicense.invitationList.columns.email"),
              size: "1.5fr",
            },
            {
              id: "invite-status-column",
              title: t("studentLicense.invitationList.columns.status"),
              size: "1fr",
            },
            {
              id: "invite-sent-date-column",
              title: t("studentLicense.invitationList.columns.sentDate"),
              size: "1fr",
            },
            { id: "invite-actions-column", title: "", size: "auto" },
          ]}
          rows={tableData}
        />
      </StyledTableContainer>
      {page && numPages > 1 && (
        <Paginator
          currentPage={+page}
          totalPages={numPages}
          onPageChange={(selectedPage) => updateFilterValue("page", selectedPage)}
        />
      )}
    </>
  );
});
