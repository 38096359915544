import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AUTH_PAGES_ROUTES } from "constants/routes";

import getSchoolDomain from "_helpers/getSchoolDomain";
import { useLogin } from "hooks/useLogin";
import type { SigninInterface } from "schemas/signin.schema";

import { AccessPageLayout } from "layouts/AccessPageLayout";

import { AccessForm } from "./components/AccessFrom";
import { MigrationSuccess } from "./components/MigrationSuccess";
import { ProvideAccessForm } from "./components/ProvideAccessForm";

enum MigrateAccessSteps {
  REQUEST_CREDENTIALS = 0,
  REQUEST_EMAIL = 1,
  MIGRATION_SUCCESS = 2,
}

export const MigrateAccessPage: React.FC = observer(() => {
  const history = useHistory();

  const { accessCode, userCode, requestAccess, provideAccess, setAccessCode, setUserCode } =
    useLogin();

  const [accessFormPassword, setAccessFormPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(MigrateAccessSteps.REQUEST_CREDENTIALS);

  // Reset code fields before attempting new migration
  useEffect(() => {
    setUserCode("");
    setAccessCode("");
  }, []);

  useEffect(() => {
    if (accessCode !== "") {
      setCurrentStep(MigrateAccessSteps.REQUEST_EMAIL);
    }
  }, [accessCode]);

  useEffect(() => {
    if (userCode !== "") {
      if (accessCode !== "") {
        setCurrentStep(MigrateAccessSteps.MIGRATION_SUCCESS);
      } else {
        // If the user had been migrated already, do login automatically
        history.push(`${AUTH_PAGES_ROUTES.VALIDATE}?code=${userCode}`);
      }
    }
  }, [userCode]);

  const onCredentialsSubmit = (values: SigninInterface) => {
    setAccessFormPassword(values.password);
    requestAccess({
      schoolDomain: getSchoolDomain(),
      username: values.username,
      password: values.password,
    });
  };

  return (
    <AccessPageLayout>
      {currentStep === MigrateAccessSteps.REQUEST_CREDENTIALS && (
        <AccessForm onSubmit={onCredentialsSubmit} />
      )}
      {currentStep === MigrateAccessSteps.REQUEST_EMAIL && (
        <ProvideAccessForm
          onSubmit={({ email }) =>
            provideAccess({
              email,
              password: accessFormPassword || null,
              code: accessCode,
            })
          }
        />
      )}
      {currentStep === MigrateAccessSteps.MIGRATION_SUCCESS && <MigrationSuccess />}
    </AccessPageLayout>
  );
});
