import type React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { useDeterminationTest } from "hooks/useDeterminationTest";
import { TestCompletedMessage } from "layouts/TestCompletedMessage";
import type { SkillTestCompletedMessageProps } from "./SkillTestCompletedMessage.model";

export const SkillTestCompletedMessage: React.FC<SkillTestCompletedMessageProps> = ({
  onSeeResults,
  onStartPracticing,
}) => {
  const { determinationTest } = useDeterminationTest();
  const { t } = useTranslation("skill-determination-test");

  const subjectTitle = determinationTest?.subject?.title;

  return (
    <TestCompletedMessage
      buttons={
        <>
          <Button
            dataCy="see-results-button"
            fontColor="black"
            variant="secondary"
            onClick={() => onSeeResults()}
          >
            {t("button.seeResults.label", "See results")}
          </Button>
          <Button
            dataCy="start-practicing-button"
            variant="primary"
            onClick={() => onStartPracticing()}
          >
            {t("button.startPracice.label", "Start practising")}
          </Button>
        </>
      }
      dataCy="finished-questions-skill-test"
      description={
        subjectTitle
          ? t(
              "finishedQuestions.description",
              "You completed a set of questions and now we can present more accurate content according to your current skill level.You’re ready to take the Tenses exercises.",
              { subjectTitle },
            )
          : t(
              "finishedQuestions.descriptionNoSubject",
              "You completed a set of questions and now we can present more accurate content according to your current skill level.You’re ready to take the Tenses exercises.",
            )
      }
      iconName="badgeIcon"
    />
  );
};
