import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { useWriting } from "hooks/useWriting";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEST_PAGE_ROUTES } from "constants/routes";

import { Heading2 } from "styles/elements/Headings";

import { ImmersiveReaderButton } from "components/ImmersiveReaderButton/ImmersiveReaderButton";
import type { TopicDetails } from "models/product/TopicDetails";
import { CssFlex } from "styles/helpers/layout";
import { getExerciseStatus } from "./CommonExerciseUtils";
import { ExerciseElement } from "./ExerciseElement";

export interface PathParams {
  productId: string;
  moduleId: string;
  subjectId: string;
  topicId: string;
}

interface ExerciseSelectorProps {
  topic: TopicDetails;
}

const IR_TARGET_SELECTOR = "writing-exercises-list";

export const ExerciseSelector: React.FC<ExerciseSelectorProps> = observer(({ topic }) => {
  const { t } = useTranslation("writing-module");
  const { productId, moduleId, subjectId, topicId }: PathParams = useParams();
  const history = useHistory();
  const { exerciseStatus: topicExerciseStatus } = useWriting();

  if (!topic || !topic.exercises || topic.exercises.length === 0) {
    return null;
  }

  const goToExercise = (exerciseId: number) => {
    history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.WRITING_EXERCISE, {
        productId,
        moduleId,
        subjectId,
        topicId,
        exerciseId,
      }),
    );
  };

  return (
    <div className={IR_TARGET_SELECTOR}>
      <CssFlex
        as="section"
        width="100%"
        alignItems="flex-start"
        justifyContent="space-between"
        margin="3rem 0 0.5rem 0"
      >
        <Heading2>
          <ImmersiveReaderButton targetSelector={`.${IR_TARGET_SELECTOR}`} targetIndex={0} />
          {t("topicDetails.button.startPractice.label", "Start practising")}
        </Heading2>
      </CssFlex>
      <div className="w-100">
        <CssFlex pr={0} flexDirection="column" data-cy="exercise-list-container" gap={1}>
          {topic.exercises?.map((exercise) => {
            const exerciseStatus = getExerciseStatus(exercise.id, topicExerciseStatus);
            return (
              <ExerciseElement
                key={exercise.id}
                id={exercise.id}
                title={exercise.title}
                handleClick={(key) => goToExercise(key)}
                exStatus={exerciseStatus || undefined}
                questionCount={exercise.questionCount}
                questionType={exercise.firstQuestionType}
              />
            );
          })}
        </CssFlex>
      </div>
    </div>
  );
});
