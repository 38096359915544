import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";

import { SvgIconReact } from "components/SvgIconReact";
import { ScheduledTestTimeLeftStatus } from "constants/exam-constants";
import { useScheduledTests } from "hooks/useScheduledTests";
import { useTestStatusAndTimeLeft } from "hooks/useTestStatusAndTimeLeft";

import type { TimerBarProps } from "./TimerBar.model";
import { StyledTimeLeft, StyledTimerBar } from "./TimerBar.styled";

export const TimerBar: React.FC<TimerBarProps> = observer(({ duration, code }) => {
  const { scheduledTestStatusAndTimeLeft } = useScheduledTests();
  const { t } = useTranslation("scheduled-tests");

  useTestStatusAndTimeLeft(code);

  const getMinutesLeft = () => {
    const minutesLeft =
      ((scheduledTestStatusAndTimeLeft?.status === ScheduledTestTimeLeftStatus.ONGOING &&
        scheduledTestStatusAndTimeLeft?.counter) ||
        0) / 60;

    return minutesLeft > 0 ? minutesLeft : 0;
  };

  return (
    <StyledTimerBar data-cy="scheduled-test-time-left">
      <SvgIconReact iconName="clockIcon" size={1.5} />
      <StyledTimeLeft timeLeft={(getMinutesLeft() * 100) / duration} />
      {`${Math.round(getMinutesLeft())} ${t("scheduledTestList.item.timeLeft", "minutes left")}`}
    </StyledTimerBar>
  );
});
