import type React from "react";
import { useTranslation } from "react-i18next";

import { modalIds } from "constants/modals-constants";
import { GENERIC_ROUTES } from "constants/routes";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useModal } from "hooks/useModal";

import { Link } from "components/Link";
import { SideMenuModal } from "components/SideMenuModal/SideMenuModal";

import type { HeaderProps } from "./Header.model";

import { AccessibilityMenuButton } from "components/AccessibilityMenuButton/AccessibilityMenuButton";
import { AccessibilityMenuModal } from "components/AccessibilityMenuModal/AccessibilityMenuModal";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssIcon } from "styles/helpers/layout";
import { StyledExtraContent, StyledHeader, StyledLogo, StyledProfileButton } from "./Header.styled";

export interface PathParams {
  productId?: string;
}

export const Header: React.FC<HeaderProps> = ({
  children,
  sideMenuEnabled = true,
  userDetails,
  toggleSideMenu,
  updateUserDetails,
  onLogout,
}) => {
  const { t } = useTranslation("common");
  const { toggleModal, isModalOpen } = useModal();
  const { isBusinessDomain, getCustomLogoByDomain } = useDomainHandler();
  const { showImmersiveReader } = useFeatureFlags();

  const onCloseSideMenu = () => {
    if (isModalOpen(modalIds.modalSideMenu)) {
      toggleModal(modalIds.modalSideMenu);
    }
  };

  const onMenuButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleSideMenu();
  };

  return (
    <StyledHeader>
      {!isBusinessDomain() && (
        <Link aria-label={t("header.button.goToMainPage")} to={GENERIC_ROUTES.HOME_PAGE}>
          <StyledLogo dataCy="header-app-logo" iconName={getCustomLogoByDomain()} />
        </Link>
      )}
      <StyledExtraContent>{children}</StyledExtraContent>
      {showImmersiveReader && <AccessibilityMenuButton />}
      {sideMenuEnabled && (
        <>
          <StyledProfileButton
            alignItems="center"
            data-cy="open-side-menu-button"
            gap={1}
            onClick={onMenuButtonClick}
          >
            <ParagraphLarge data-cy="account-header-name">{`${userDetails?.firstName} ${userDetails?.lastName}`}</ParagraphLarge>
            <CssIcon iconName="far fa-angle-down" />
          </StyledProfileButton>
          <SideMenuModal
            userDetails={userDetails}
            onClose={onCloseSideMenu}
            onLogout={onLogout}
            onSubmit={updateUserDetails}
          />
          <AccessibilityMenuModal onClose={onCloseSideMenu} />
        </>
      )}
    </StyledHeader>
  );
};
