import { observer } from "mobx-react";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { ProductType } from "constants/hst-constants";
import { DEFAULT_DEBOUNCE_TIME } from "constants/misc-constants";
import { TEACHER_INSIGHT_PAGE_ROUTES } from "constants/routes";

import type { Education } from "models/auth/Login";
import type { ProductSkillLevel } from "models/product/ProductDetails";
import type { SchoolYear } from "models/product/SchoolYear";
import { TeacherReportType } from "models/report/Report";

import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProduct } from "hooks/useProduct";
import { useReport } from "hooks/useReport";
import { useTeacherOverview } from "hooks/useTeacherOverview";

import { SearchBox } from "components/SearchBox";
import { GoalLevelSelector } from "../subpages/BasicProgressForAccount/components/GoalLevelSelector/GoalLevelSelector";
import { DownloadReportButton } from "./DownloadReportButton/DownloadReportButton";
import { EducationSelector } from "./EducationSelector/EducationSelector";
import { GroupSelector } from "./GroupSelector/GroupSelector";
import { SchoolYearSelector } from "./SchoolYearSelector/SchoolYearSelector";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { Box, CssFlex } from "styles/helpers/layout";
import { StyledFilterContainer, StyledWrapper } from "./TeacherDashboardFilters.styled";

export interface TeacherDashboardFiltersProps {
  productId: number;
  currentEducationTitle?: string;
  currentYearsTitle?: string;
  showSchoolYearFilter?: boolean;
  showEducationFilter?: boolean;
  productSchoolYears?: SchoolYear[];
  userEducations?: Education[];
  teacherReportType: TeacherReportType;
  buildPaginatedURL: (key: string, value?: string | number | string[] | undefined) => string;
  targetLevel?: number;
  selectableGoalLevels?: ProductSkillLevel[];
  onTargetLevelChange?: (targetLevelIndex: number) => void;
}

export const TeacherDashboardFilters: React.FC<TeacherDashboardFiltersProps> = observer(
  ({
    currentEducationTitle = "",
    currentYearsTitle = "",
    teacherReportType,
    productId,
    showEducationFilter = false,
    showSchoolYearFilter = false,
    productSchoolYears = [],
    userEducations = [],
    buildPaginatedURL,
    targetLevel,
    selectableGoalLevels,
    onTargetLevelChange,
  }) => {
    const { productDetails } = useProduct();
    const { showGoalLevelSelectorOnTeacherDashboard } = useFeatureFlags();
    const [textFilter, setTextFilter] = useState<string>("");
    const { t } = useTranslation("teacher-dashboard");
    const history = useHistory();

    const { paginationFilters } = useTeacherOverview(TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW);
    const { loading, generateBasicProgressReport, generateWritingResultsReport } = useReport();

    // Define debounced callback method to avoid too many calls to endpoint while user is typing in search box
    const appendSearchTermAndTrack = useDebouncedCallback((val: string) => {
      trackPlatformEvent({
        module: TEACHER_MODULES.BASIC_PROGRESS,
        itemId: PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES.FILTER_USERS_BY_NAME,
        value: JSON.stringify({ searchTerm: val }),
      });

      history.push(buildPaginatedURL("searchTerm", textFilter || undefined));
    }, DEFAULT_DEBOUNCE_TIME);

    const onDownloadReportButtonClicked = () => {
      trackPlatformEvent({
        module: TEACHER_MODULES.BASIC_PROGRESS,
        itemId: PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES.CLICK_DOWNLOAD_REPORT,
        value: JSON.stringify({ teacherReportType, productId, paginationFilters, targetLevel }),
      });

      if (teacherReportType === TeacherReportType.BASIC_PROGRESS) {
        if (!showGoalLevelSelectorOnTeacherDashboard) {
          generateBasicProgressReport(productId, paginationFilters);
        } else if (targetLevel) {
          generateBasicProgressReport(+productId, { ...paginationFilters, targetLevel });
        }
      } else {
        generateWritingResultsReport(productId, paginationFilters);
      }
    };

    const isBusinessProduct = productDetails?.type === ProductType.BUSINESS;
    const isBasicSkillsPage = teacherReportType === TeacherReportType.BASIC_PROGRESS;
    const getFiltersTitle = () => {
      if (isBasicSkillsPage) {
        return t("basicProgress.overview.filters.basicSkillTitle");
      }
      if (isBusinessProduct) {
        return t("basicProgress.overview.filters.writingSkillBussinessProductTitle");
      }
      return t("basicProgress.overview.filters.writingSkillTitle");
    };
    const filtersInfoSubtitle = `${currentYearsTitle} - ${currentEducationTitle}`;

    const handleStudentSearchBoxUpdate = (val: string): void => {
      setTextFilter(val);
      appendSearchTermAndTrack(val);
    };
    return (
      <>
        <StyledWrapper>
          <CssFlex flexDirection="column" justifyContent="center">
            <Heading3 mb={0}>{getFiltersTitle()}</Heading3>
            {!isBusinessProduct && (
              <ParagraphLarge paddingTop={1}>{filtersInfoSubtitle}</ParagraphLarge>
            )}
          </CssFlex>
          <DownloadReportButton loading={loading} onClick={onDownloadReportButtonClicked} />
        </StyledWrapper>
        {(showEducationFilter || showSchoolYearFilter) && (
          <CssFlex gap={1} justifyContent="end" mt={2}>
            <Box alignContent="end" mr="auto">
              <SearchBox
                dataCy="student-search-box"
                placeholder={t("basicProgress.overview.filters.filterPlaceholder")}
                value={textFilter}
                onChange={handleStudentSearchBoxUpdate}
              />
            </Box>
            <StyledFilterContainer gap={1}>
              {!isBusinessProduct && showSchoolYearFilter && (
                <SchoolYearSelector
                  schoolYears={productSchoolYears}
                  onChange={(selectedSchoolYear: SchoolYear | null) => {
                    history.push(
                      buildPaginatedURL(
                        "schoolYear",
                        selectedSchoolYear
                          ? [
                              selectedSchoolYear.startDate as string,
                              selectedSchoolYear.endDate as string,
                            ]
                          : ["", ""],
                      ),
                    );
                  }}
                />
              )}
              {onTargetLevelChange && showGoalLevelSelectorOnTeacherDashboard && (
                <GoalLevelSelector
                  selectableGoalLevels={selectableGoalLevels}
                  targetLevel={targetLevel}
                  onChange={onTargetLevelChange}
                />
              )}
              {!isBusinessProduct && showEducationFilter && (
                <EducationSelector
                  educations={userEducations}
                  onChange={(selectedEducationId: string | null) => {
                    trackPlatformEvent({
                      module: TEACHER_MODULES.BASIC_PROGRESS,
                      itemId:
                        PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES
                          .UPDATE_VIEW_BY_EDUCATION,
                      value: JSON.stringify({ educationId: selectedEducationId }),
                    });
                    history.push(
                      buildPaginatedURL("educationId", selectedEducationId || undefined),
                    );
                  }}
                />
              )}
              {!isBusinessProduct && (
                <GroupSelector
                  defaultValue={
                    paginationFilters.groupId ? `${paginationFilters.groupId}` : undefined
                  }
                  onChange={(selectedGroupId: string | null) => {
                    trackPlatformEvent({
                      module: TEACHER_MODULES.BASIC_PROGRESS,
                      itemId:
                        PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES
                          .UPDATE_VIEW_BY_GROUP,
                      value: JSON.stringify({ groupId: selectedGroupId }),
                    });
                    history.push(buildPaginatedURL("groupId", selectedGroupId || undefined));
                  }}
                />
              )}
            </StyledFilterContainer>
          </CssFlex>
        )}
      </>
    );
  },
);
