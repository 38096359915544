import { Button } from "components/Button";
import styled from "styled-components/macro";

export const StyledSchoolDetailsPage = styled.div`
  margin-right: 32px;
  width: 50rem;
`;

export const StyledBackButton = styled(Button)`
display: flex;
align-items: center;

svg {
  margin-right: 8px;
}

span {
  display: flex;
  align-items: center;
}

`;
