import { EmptyExamQuestionsMessage } from "layouts/EmptyExamQuestionsMessage/EmptyExamQuestionsMessage";
import { HstContentPlayer } from "layouts/HstContentPlayer/HstContentPlayer";
import { StyledAnswerBox } from "layouts/QuestionAnswerBox/QuestionAnswerBox.styled";
import styled from "styled-components/macro";

export const StyledEmptyExamQuestionsMessage = styled(EmptyExamQuestionsMessage)`
  justify-self: center;
  align-self: center;
`;

export const StyledPageWrapper = styled(HstContentPlayer)`
  background-color: #f5f7f9;
  height: 100vh;
  display: flex;
  flex-direction: column;

  ${StyledAnswerBox} {
    > :first-child {
      > button {
        background: transparent;
      }
    }
  }
`;
