import type React from "react";
import { type ReactElement, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { SanitizedHtml } from "components/SanitizedHtml";
import { TEST_PAGE_ROUTES } from "constants/routes";
import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useProduct } from "hooks/useProduct";
import { useQueryParam } from "hooks/useQueryParam";
import type { AdaptiveTestPageParams } from "../../AdaptiveTestPage.model";
import type { CongratulationsMessageProps } from "./CongratulationsMessage.model";
import {
  StyledAchievementToast,
  StyledButton,
  StyledButtonBar,
  StyledMessage,
} from "./CongratulationsMessage.styled";

type TextView = {
  dataCy: string;
  title: ReactElement;
  description: ReactElement;
};

export const CongratulationsMessage: React.FC<CongratulationsMessageProps> = ({
  nextTopicTitle,
  onContinueTopic,
  onContinueMixedExercises,
  userName,
  currentTopicTitle,
  productGoalLevel,
}) => {
  const { t } = useTranslation("adaptive-test");
  const { productDetails, moduleDetails } = useProduct();
  const history = useHistory();
  const {
    currentTopic,
    isReadyForCorrectText,
    congratulationsMessage,
    setCongratulationsMessage,
    setIsReadyForCorrectText,
    setPreviousTopic,
    setCurrentTopic,
  } = useAdaptivePractice();
  const topicId = useQueryParam("topicId");
  const { productId, subjectId, moduleId }: AdaptiveTestPageParams = useParams();

  const goalLevelName = productDetails?.levels.find(
    ({ level }) => level === productGoalLevel,
  )?.label;
  const subjectName = moduleDetails?.subjects.find((x) => x.id === +subjectId)?.title;
  const chapterName = currentTopic?.chapter.title;

  const onContinue = () => {
    if (congratulationsMessage === "sub-theme") {
      return onContinueTopic(!topicId);
    }

    history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST, {
        productId,
        moduleId,
        subjectId,
      }),
    );
    setCongratulationsMessage("none");
    setPreviousTopic(null);
    setCurrentTopic(null);
  };

  const getButtonsView = () => {
    switch (congratulationsMessage) {
      case "subject":
        return (
          <StyledButton
            dataCy="continue-mixed-exercises-button"
            variant="brand"
            onClick={onContinueMixedExercises}
          >
            {t("button.continueMixedExercises.label", "Continue")}
          </StyledButton>
        );
      case "theme":
        return (
          <StyledButton
            dataCy="continue-adaptive-test-topic-button"
            variant="primary"
            onClick={onContinue}
          >
            {t("button.continueTheme.label")}
          </StyledButton>
        );
      case "sub-theme":
        return (
          <>
            <StyledButton
              dataCy="continue-mixed-exercises-button"
              variant="brand"
              onClick={onContinueMixedExercises}
            >
              {t("button.continueMixedExercises.label", "Continue")}
            </StyledButton>
            <StyledButton
              dataCy="continue-adaptive-test-topic-button"
              variant="primary"
              onClick={onContinue}
            >
              {isReadyForCorrectText
                ? t("button.continueCorrectText.label")
                : t("button.continueTopic.label", "Continue")}
            </StyledButton>
          </>
        );
    }
  };

  const getTextView = (): TextView | null => {
    switch (congratulationsMessage) {
      case "subject": {
        return {
          dataCy: "subject-finished-message",
          title: (
            <Trans
              components={{
                finishedSubject: <span data-cy="subject-title" />,
                user: <span data-cy="user-name" />,
              }}
              i18nKey={"finishedSubject.title"}
              t={t}
              values={{ userName, subjectName }}
            />
          ),
          description: (
            <Trans
              components={{ finishedSubject: <span data-cy="subject-title-description" /> }}
              i18nKey={"finishedSubject.description"}
              t={t}
              values={{ subjectName }}
            />
          ),
        };
      }
      case "theme": {
        return {
          dataCy: "chapter-finished-message",
          title: (
            <Trans
              components={{
                finishedChapter: (
                  <SanitizedHtml
                    dataCy="chapter-title"
                    dirtyHtml={chapterName || ""}
                    tagName="span"
                  />
                ),
                user: <span data-cy="user-name" />,
              }}
              i18nKey={"finishedChapter.title"}
              t={t}
              values={{ userName }}
            />
          ),
          description: (
            <Trans
              components={{
                goalLevel: <span data-cy="goal-level" />,
                finishedChapter: (
                  <SanitizedHtml
                    dataCy="finished-chapter-title"
                    dirtyHtml={chapterName || ""}
                    tagName="span"
                  />
                ),
              }}
              i18nKey={"finishedChapter.description"}
              t={t}
              values={{ userName, goalLevel: goalLevelName }}
            />
          ),
        };
      }
      case "sub-theme": {
        return {
          dataCy: "topic-finished-message",
          title: (
            <Trans
              components={{ user: <span data-cy="user-name" /> }}
              i18nKey={
                isReadyForCorrectText
                  ? "finishedTopicAndStartingThemeAssignment.title"
                  : "finishedTopic.title"
              }
              t={t}
              values={{ userName }}
            />
          ),
          description: (
            <Trans
              components={{
                chapter: (
                  <SanitizedHtml
                    dataCy="chapter-title"
                    dirtyHtml={chapterName || ""}
                    tagName="span"
                  />
                ),
                currentTopic: (
                  <SanitizedHtml
                    dataCy="current-topic-title"
                    dirtyHtml={currentTopicTitle || ""}
                    tagName="span"
                  />
                ),
                goalLevel: <span data-cy="goal-level" />,
                nextTopic: (
                  <SanitizedHtml
                    dataCy="next-topic-title"
                    dirtyHtml={nextTopicTitle || ""}
                    tagName="span"
                  />
                ),
              }}
              i18nKey={
                isReadyForCorrectText
                  ? "finishedTopicAndStartingThemeAssignment.description"
                  : nextTopicTitle
                    ? "finishedTopic.description"
                    : "finishedTopic.descriptionNoTopic"
              }
              t={t}
              values={{ goalLevel: goalLevelName }}
            />
          ),
        };
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    return () => setIsReadyForCorrectText(false);
  }, []);

  const messageContents = getTextView();
  if (!messageContents) {
    return null;
  }

  const { dataCy, title, description } = messageContents;

  return (
    <StyledAchievementToast showConfetti dataCy={dataCy} title={title}>
      <StyledMessage>{description}</StyledMessage>
      <StyledButtonBar>{getButtonsView()}</StyledButtonBar>
    </StyledAchievementToast>
  );
};
