import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { Input } from "components/Input";
import { SvgIconReact } from "components/SvgIconReact";

interface StyledInfoPropsInterface {
  $color: string;
}

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledInfoIcon = styled(SvgIconReact)`
  color: ${(props: StyledInfoPropsInterface) => props.$color};
`;

export const StyledInput = styled(Input)`
  padding-right: 3rem;
`;

export const StyledPasswordIcon = styled(FontAwesomeIcon)<Props>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin-top: 1px;
  color: ${(props: Props) => props.theme.palette.neutral.black};
`;
