import undoSvg from "assets/icons/arrow_undo.svg";
import mergeSvg from "assets/icons/merge.svg";
import pencilSvg from "assets/icons/pencil.svg";
import plusSvg from "assets/icons/plus-icon.svg";
import removeSvg from "assets/icons/remove.svg";
import swapSvg from "assets/icons/swap.svg";
import { Button } from "components/Button";
import { WordAction } from "models/exam/CorrectTextQuestion";
import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { CorrectTextQuestionOptionListProps } from "./CorrectTextQuestionOptionList.model";
import { StyledCorrectTextQuestionOptionList } from "./CorrectTextQuestionOptionList.styled";

type ButtonInfo = {
  label: string;
  undoLabel: string;
  iconPath?: string;
};

export const CorrectTextQuestionOptionList: React.FC<CorrectTextQuestionOptionListProps> = ({
  activeWordAction = WordAction.NONE,
  dataCy,
  enabledWordActions,
  undoEnabled = false,
  onWordAction,
  onUndo,
}) => {
  const { t } = useTranslation("correct-text-question");

  const buttonLabels = useMemo<Map<WordAction, ButtonInfo>>(
    () =>
      new Map([
        [
          WordAction.EDIT_WORD,
          {
            label: t("wordActions.edit"),
            undoLabel: t("undoWordActions.edit"),
            iconPath: pencilSvg,
          },
        ],
        [
          WordAction.ADD_WORD_AFTER,
          {
            label: t("wordActions.addAfter"),
            undoLabel: t("undoWordActions.addAfter"),
            iconPath: plusSvg,
          },
        ],
        [
          WordAction.SWAP_WORDS,
          { label: t("wordActions.swap"), undoLabel: t("undoWordActions.swap"), iconPath: swapSvg },
        ],
        [
          WordAction.MERGE_WORDS,
          {
            label: t("wordActions.merge"),
            undoLabel: t("undoWordActions.merge"),
            iconPath: mergeSvg,
          },
        ],
        [
          WordAction.REMOVE_WORD,
          {
            label: t("wordActions.remove"),
            undoLabel: t("undoWordActions.remove"),
            iconPath: removeSvg,
          },
        ],
      ]),
    [t],
  );

  const buttons = enabledWordActions.map(
    (actionType) =>
      buttonLabels.has(actionType) && (
        <Button
          key={actionType}
          isBlock
          data-cy={`correct-text-question-word-option-${actionType}`}
          title={buttonLabels.get(actionType)?.label}
          variant="border"
          onClick={() => onWordAction(actionType)}
        >
          {buttonLabels.get(actionType)?.iconPath && (
            <img alt="icon" src={buttonLabels.get(actionType)?.iconPath} />
          )}
          <span>{buttonLabels.get(actionType)?.label}</span>
        </Button>
      ),
  );

  return (
    <StyledCorrectTextQuestionOptionList data-cy={dataCy}>
      {undoEnabled && (
        <Button
          isBlock
          dataCy="correct-text-question-word-option-undo"
          title={buttonLabels.get(activeWordAction)?.undoLabel ?? t("undoWordActions.general")}
          variant="border"
          onClick={() => onUndo()}
        >
          <img alt="icon" src={undoSvg} />
          <span>
            {buttonLabels.get(activeWordAction)?.undoLabel ?? t("undoWordActions.general")}
          </span>
        </Button>
      )}
      {!undoEnabled && buttons}
    </StyledCorrectTextQuestionOptionList>
  );
};
