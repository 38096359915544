import { useDomainHandler } from "hooks/useDomainHandler";
import type React from "react";
import { useTranslation } from "react-i18next";
import { Heading3, Heading4 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { StyledInnerWrapper, StyledWrapper } from "./InviteStudent.styled";

export const InviteStudent: React.FC = () => {
  const { t } = useTranslation("teacher-dashboard");
  const { getStudentTermByDomain } = useDomainHandler();

  return (
    <StyledWrapper>
      <Heading3>{t("studentLicense.inviteStudents.title")}</Heading3>
      <StyledInnerWrapper>
        <Heading4 marginBottom="1rem">
          {t("studentLicense.inviteStudents.description.introduction", {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
        </Heading4>
        <ParagraphLarge marginBottom="1rem">
          {t("studentLicense.inviteStudents.description.middle", {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
        </ParagraphLarge>
        <ParagraphLarge marginBottom="1rem">
          {t("studentLicense.inviteStudents.description.end")}
        </ParagraphLarge>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};
