import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";

import { Dropdown } from "components/Dropdown";
import { ProductType } from "constants/hst-constants";

export const StyledDropdownProducts = styled(Dropdown)<Props>`
  div:first-child {
    h4 {
      font-weight: 700;

      i {
        display: none;
      }
    }
  }
`;

interface StyledProductRowProps extends Props {
  $productType: ProductType;
}

export const StyledProductRow = styled.div<StyledProductRowProps>`
  width: 100%;
  display: flex;
  column-gap: 1rem;
  flex-direction: row;
  align-items: center;

  color: ${({ $productType, theme }: StyledProductRowProps) => {
    switch ($productType) {
      case ProductType.BUSINESS:
        return theme.palette.secondary.brand;
      default:
        return theme.palette.neutral.black;
    }
  }};

  > i {
    display: ${({ $productType }: StyledProductRowProps) =>
      $productType === ProductType.REGULAR ? "none" : "initial"};
  }
`;
