import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

export const StyledQuestionManager = styled.div<ThemeProps>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-y: auto;
`;

export const StyledQuestion = styled.article<ThemeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3.5rem 1rem 3.5rem;
  width: 100%;

  div:has(.opdracht) {
    width: 100%;
  }

  .opdracht {
    text-align: left;
    color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
    font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold};
    font-size: ${(props: ThemeProps) => props.theme.font.size._24};
    line-height: 2rem;
  }

  .vraag {
    text-align: left;
    font-size: ${(props: ThemeProps) => props.theme.font.size._16};
    line-height: 1.5rem;
    text-align: left;
    color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
    font-weight: ${(props: ThemeProps) => props.theme.font.weight.normal};
  }

  p {
    color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
  }

  img {
    margin: auto;
    display: block;
    max-width: 100%;
    height: auto !important;
  }
`;
