import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { clearButtonCss } from "styles/elements/Buttons";
import { Heading4Css } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { calculateRem } from "styles/mixins/mixins";

import { Button } from "components/Button";
import { IconButton } from "components/IconButton/IconButton";
import { ProductFlag } from "components/ProductFlag/ProductFlag";

export const StyledScheduledTestItem = styled.div<ThemeProps>`
  border: ${(props: ThemeProps) => `1px solid ${props.theme.palette.neutral._900}`};
  display: flex;
  flex-direction: column;
  margin-bottom: ${calculateRem(14)};
  padding: 2rem;
  padding-right: 5rem;
  position: relative;
`;

export const StyledCollapseButton = styled(IconButton)<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
  position: absolute;
  right: 2rem;
  top: 2rem;
`;

export const StyledProductFlag = styled(ProductFlag)`
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
`;

export const StyledGeneralInformation = styled.div`
  display: flex;
  flex-grow: 1;
  row-gap: 1rem;
  flex-direction: column;
`;

export const StyledInfoRow = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledMetadataContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledTestName = styled(ParagraphLarge)`
  text-align: left;
  line-height: 150%;
`;

const StyledMetadata = styled.div`
  display: flex;
  height: 2rem;
  align-items: center;
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  column-gap: ${calculateRem(10)};
`;

export const StyledTestLevel = styled(StyledMetadata)`
  width: 4rem;
`;

export const StyledDateMetadata = styled(StyledMetadata)`
  width: 7.5rem;
`;

export const StyledTimeMetadata = styled(StyledMetadata)`
  width: 9rem;
`;

export const StyledTestCode = styled(StyledMetadata)<ThemeProps>`
  width: fit-content;
  min-width: 6rem;
  column-gap: 0;
  color: ${(props: ThemeProps) => props.theme.palette.secondary.brand};

  > svg > path {
    fill: ${(props: ThemeProps) => props.theme.palette.secondary.brand};
  }
`;

export const StyledDetailedInformation = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
`;

export const StyledInformationBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInformationItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${calculateRem(10)};
  margin-bottom: ${calculateRem(24)};
`;

export const StyledEditButton = styled(Button)`
  ${Heading4Css};
  height: fit-content;
  max-width: fit-content;
  padding: 1rem;

  i {
    margin-right: 0.75rem;
  }
`;

export const PasswordButton = styled.button<ThemeProps>`
  ${clearButtonCss};
  color: ${({ theme }: ThemeProps) => theme.palette.secondary.brand};
  background-color: transparent;
  > svg {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
  }
`;
