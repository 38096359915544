import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { Card } from "components/Card/Card";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Modal } from "components/Modal";
import { ModalRenderType } from "components/Modal/Modal.model";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import { observer } from "mobx-react";
import type { ProductSkillLevel } from "models/product/ProductDetails";
import type { SubjectsProgress } from "models/progress/Progress";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Heading3, Heading4 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { Box, CssFlex } from "styles/helpers/layout";
import { calculateRem } from "styles/mixins/calculateRem";
import {
  StyledAccountProductModal,
  StyledActiveFilterText,
  StyledAnswersColumn,
  StyledDivider,
  StyledHeaderProgressBar,
  StyledModalSubtitle,
  StyledProgressColumn,
  StyledSubjectContainer,
  StyledSubjectHeader,
  StyledTopicColumnHeader,
} from "./DetailedProgressModal.styled";

export const DetailedProgressModal: React.FC<{
  accountId: number;
  productId: number;
  moduleId: number | undefined;
  targetLevel?: ProductSkillLevel;
  subjects: SubjectsProgress | null;
}> = observer(({ accountId, productId, targetLevel, moduleId }) => {
  const { closeModal, isModalOpen } = useModal();
  const { t } = useTranslation("teacher-dashboard");
  const { studentDetailedProgress, fetchStudentDetailedProgress } = useProductProgress();
  const { moduleDetails, fetchModuleDetails } = useProduct();
  const [expandedCards, setExpandedCards] = useState<number[]>([]);

  const isOpen = isModalOpen(modalIds.detailedStudentProgressModal);

  // Fetch module details only if modal is open, otherwise it's not worth consuming resources in this request
  useEffect(() => {
    if (isOpen && productId && moduleId) {
      fetchModuleDetails(productId, moduleId);
    }
  }, [productId, moduleId, isOpen]);

  // Fetch progress data only if modal is open and a target level has been specified
  useEffect(() => {
    if (isOpen && accountId && productId && targetLevel) {
      fetchStudentDetailedProgress(productId, accountId, targetLevel.level);
    }
  }, [accountId, productId, targetLevel, isOpen]);

  const detailedStudentProgressBySubject = useMemo(() => {
    if (!studentDetailedProgress || !moduleDetails) return [];

    const topicMap: {
      [id: string]: {
        id: number;
        answeredQuestions: number;
        progressTowardsGoal: number;
        subjectId: number;
        subjectProgress: number;
      };
    } = {};

    for (const subject of studentDetailedProgress) {
      for (const topic of subject.topics) {
        topicMap[topic.id] = {
          ...topic,
          subjectId: subject.id,
          subjectProgress: subject.progress,
        };
      }
    }

    return moduleDetails.subjects.map((subject) => {
      return {
        ...subject,
        progress: studentDetailedProgress.find((s) => s.id === subject.id)?.progress || 0,
        chapters: subject.chapters.map((chapter) => {
          return {
            ...chapter,
            topics: chapter.topics.map((topic) => {
              return {
                ...topic,
                ...topicMap[topic.id],
              };
            }),
          };
        }),
      };
    });
  }, [studentDetailedProgress, moduleDetails]);

  const toggleExpandedCard = (id: number) => {
    trackPlatformEvent({
      module: TEACHER_MODULES.BASIC_PROGRESS,
      itemId:
        PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES
          .CLICK_TOGGLE_STUDENT_PROGRESS_SUBJECT,
      value: JSON.stringify({ subjectId: id }),
    });
    setExpandedCards((prevState) =>
      prevState.includes(id) ? prevState.filter((i) => i !== id) : [...prevState, id],
    );
  };

  const isLoading =
    !accountId || !productId || !targetLevel || !moduleDetails || !studentDetailedProgress;

  return (
    <Modal
      closeOnClickOutside
      dataCy="detailed-student-progress-modal"
      isOpen={isOpen}
      renderToElementType={ModalRenderType.REACT_PORTAL}
      onClose={() => {
        trackPlatformEvent({
          module: TEACHER_MODULES.BASIC_PROGRESS,
          itemId:
            PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES
              .CLICK_CLOSE_STUDENT_PROGRESS_SUBJECTS_MODAL,
          elementId: "detailed-student-progress-modal",
        });
        closeModal();
      }}
    >
      <StyledAccountProductModal>
        <Heading3 mb={1}>{t("basicProgress.forAccount.detailedProgressModal.title")}</Heading3>
        <StyledModalSubtitle fontWeight={300}>
          {t("basicProgress.forAccount.detailedProgressModal.subtitle")}
        </StyledModalSubtitle>
        {isLoading ? (
          <CssFlex justifyContent="center" padding="5rem">
            <LoadingSpinner />
          </CssFlex>
        ) : (
          <>
            {/* Show information on the selected filters to visually re-confirm which data is being displayed */}
            <CssFlex columnGap={1} mb={20} mt={2}>
              <StyledActiveFilterText>
                {`${t("basicProgress.forAccount.detailedProgressModal.appliedFilters.goalLevel")}: `}
                <b>{targetLevel.label}</b>
              </StyledActiveFilterText>
              <StyledActiveFilterText>
                {`${t("basicProgress.forAccount.detailedProgressModal.appliedFilters.insightType")}: `}
                <b>{t("basicProgress.forAccount.skillsSelectorType.basicSkills")}</b>
              </StyledActiveFilterText>
            </CssFlex>
            <Card>
              {detailedStudentProgressBySubject.map((subject) => {
                if (!subject) return null;
                return (
                  <StyledSubjectContainer key={subject.id} data-cy="modal-subject-container">
                    <CssFlex
                      alignItems="center"
                      columnGap={1.5}
                      justifyContent="space-between"
                      p={2}
                    >
                      <StyledSubjectHeader
                        data-cy="subject-header"
                        m={2}
                        mr={3}
                        p={1}
                        onClick={() => toggleExpandedCard(subject.id)}
                      >
                        <FontAwesomeIcon
                          iconName="fas fa-chevron-up"
                          style={{
                            transform: `rotate(${expandedCards.includes(subject.id) ? "180deg" : "0deg"})`,
                            transition: "transform 0.3s",
                          }}
                        />
                        {subject.title}
                      </StyledSubjectHeader>
                      <StyledHeaderProgressBar>
                        <ProgressBar currentValue={subject.progress} />
                        <ParagraphLarge data-cy="modal-subject-percent-completed">
                          {t("basicProgress.forAccount.detailedProgressModal.percentCompleted", {
                            percentage: subject.progress,
                          })}
                        </ParagraphLarge>
                      </StyledHeaderProgressBar>
                    </CssFlex>
                    <Box
                      display={expandedCards.includes(subject.id) ? "block" : "none"}
                      px={2}
                      py={0}
                    >
                      <CssFlex alignItems="center" gap={1.5} mx={3} my={3}>
                        <StyledProgressColumn
                          alignItems="center"
                          display="flex"
                          width={calculateRem(120)}
                        >
                          <StyledTopicColumnHeader>
                            {t(
                              "basicProgress.forAccount.detailedProgressModal.progressTowardsGoal",
                            )}
                          </StyledTopicColumnHeader>
                        </StyledProgressColumn>
                        <StyledAnswersColumn>
                          <StyledTopicColumnHeader>
                            {t(
                              "basicProgress.forAccount.detailedProgressModal.totalAnsweredQuestions",
                            )}
                          </StyledTopicColumnHeader>
                        </StyledAnswersColumn>
                      </CssFlex>
                      {subject.chapters.map((chapter) => {
                        // Exclude topics for which no progress is returned (since they're not available for selected goal)
                        const availableTopics = chapter.topics.filter(
                          (topic) =>
                            Object.hasOwn(topic, "progressTowardsGoal") &&
                            typeof topic.progressTowardsGoal === "number",
                        );

                        // If this chapter doesn't have any topics available, don't show it
                        if (!availableTopics.length) return null;

                        return (
                          <Box key={chapter.id} ml={3}>
                            <Heading4>{chapter.title}</Heading4>
                            <StyledDivider height={1} mb={1} />
                            {availableTopics.map((topic) => (
                              <CssFlex
                                key={topic.id}
                                alignItems="center"
                                data-cy="modal-topic-container"
                                gap={1.5}
                                mx={0}
                                my={4}
                              >
                                <Box flex={1}>
                                  <ParagraphLarge>{topic.title}</ParagraphLarge>
                                </Box>
                                <StyledProgressColumn
                                  alignItems="center"
                                  display="flex"
                                  width={calculateRem(120)}
                                >
                                  <ProgressBar currentValue={topic.progressTowardsGoal} />
                                  {` ${topic.progressTowardsGoal}%`}
                                </StyledProgressColumn>
                                <StyledAnswersColumn>
                                  <ParagraphLarge>{topic.answeredQuestions}</ParagraphLarge>
                                </StyledAnswersColumn>
                              </CssFlex>
                            ))}
                          </Box>
                        );
                      })}
                    </Box>
                  </StyledSubjectContainer>
                );
              })}
            </Card>
          </>
        )}
      </StyledAccountProductModal>
    </Modal>
  );
});
