import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { TEST_PAGE_ROUTES } from "constants/routes";
import type { ContentAPI } from "generated/types";
import { useReactTooltip } from "hooks/useReactTooltip";
import {
  AssignmentTopicProgressionStatus,
  type AssignmentTopicProgressionStatusType,
} from "models/progress/Progress";

import { buildUrlWithPathAndQueryParams } from "_helpers/utils/urlBuilder";
import { EmbeddedHtml } from "components/EmbeddedHtml";
import { LevelDash } from "components/LevelDash";
import type { AdaptiveTestPageParams } from "pages/StudentEnvironment/AdaptiveTestPage/AdaptiveTestPage.model";

import { CssFlex, CssIcon } from "styles/helpers/layout";
import {
  StyledCorrectTextTopic,
  StyledNeedReviewMessage,
  StyledStatusText,
  StyledTitle,
  StyledTopicList,
} from "./CorrectTextTopic.styled";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { calculateRem } from "styles/mixins/calculateRem";
import improvementChartSvg from "../../../../../../../assets/images/improvement-chart.svg";

export interface CorrectTextTopicProps {
  topic: ContentAPI.TopicListItem;
  subjectId: number;
  isDisabled: boolean;
  progressState: AssignmentTopicProgressionStatusType;
}

export const CorrectTextTopic: React.FC<CorrectTextTopicProps> = ({
  topic,
  subjectId,
  isDisabled,
  progressState,
}) => {
  useReactTooltip();

  const { t } = useTranslation("adaptive-test");
  const { productId, moduleId }: AdaptiveTestPageParams = useParams();
  const history = useHistory();

  const { id, title, relatedTopicsToReview } = topic;
  const topicState: AssignmentTopicProgressionStatusType =
    relatedTopicsToReview && relatedTopicsToReview.length > 0
      ? AssignmentTopicProgressionStatus.FAILED
      : progressState;

  const onTopicClicked = () => {
    if (
      topicState === AssignmentTopicProgressionStatus.READY ||
      topicState === AssignmentTopicProgressionStatus.FAILED
    ) {
      // Track event when user chooses to enter an assignment
      trackPlatformEvent({
        module: STUDENT_MODULES.BASIC_PROGRESS,
        itemId: PLATFORM_EVENT_TYPES.STUDENT_MODULES_EVENT_TYPES.CLICK_PRACTICE_TOPIC,
        elementId: `student-overview-theme-assignment-${id}`,
        value: JSON.stringify({
          productId: +productId,
          moduleId: +moduleId,
          subjectId,
          topicId: id,
          isThemeAssignment: true,
        }),
      });

      history.push(
        buildUrlWithPathAndQueryParams(
          TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
          { productId, moduleId, subjectId },
          { topicId: id },
        ),
      );
    }
  };

  const getTopicStateText = () => {
    switch (topicState) {
      case AssignmentTopicProgressionStatus.COMPLETED:
        return t("correctText.completed");
      case AssignmentTopicProgressionStatus.LOCKED:
        return t("correctText.locked");
      case AssignmentTopicProgressionStatus.FAILED:
        return t("correctText.failed");
      default:
        return t("correctText.unlocked");
    }
  };

  const getTopicStateIcon = () => {
    switch (topicState) {
      case AssignmentTopicProgressionStatus.COMPLETED:
        return <CssIcon data-cy="completed-icon" iconName="fas fa-check-circle" />;
      case AssignmentTopicProgressionStatus.LOCKED:
        return <CssIcon iconName="fas fa-lock" />;
      case AssignmentTopicProgressionStatus.FAILED:
        return <LevelDash level="basics" />;
      default:
        return <CssIcon iconName="fas fa-lock-open" />;
    }
  };

  return (
    <StyledCorrectTextTopic
      alignItems="center"
      data-cy={`correct-text-topic-${id}`}
      gridTemplateColumns="2fr 1fr"
      isDisabled={isDisabled}
      p={"0 1.5rem 0 4rem"}
      topicState={topicState}
      onClick={onTopicClicked}
    >
      <StyledTitle alignItems="center" height={calculateRem(40)}>
        <EmbeddedHtml rawHtml={title} />
      </StyledTitle>
      {!isDisabled && (
        <CssFlex alignItems="center" data-cy="topic-state-text" gap={0.75}>
          {getTopicStateIcon()}
          <StyledStatusText>{getTopicStateText()}</StyledStatusText>
        </CssFlex>
      )}
      {topicState === AssignmentTopicProgressionStatus.FAILED &&
        relatedTopicsToReview &&
        relatedTopicsToReview.length > 0 && (
          <StyledNeedReviewMessage data-cy="topics-needing-review">
            <img
              alt={t("correctText.topicNeedsMorePracticeIconAltText")}
              className="chart-icon"
              src={improvementChartSvg}
            />
            <section>
              <p>{t("correctText.requireMorePractice")}</p>
              <StyledTopicList>
                {relatedTopicsToReview?.map((relatedTopicTitle) => (
                  <EmbeddedHtml key={relatedTopicTitle} rawHtml={relatedTopicTitle} tagName="li" />
                ))}
              </StyledTopicList>
            </section>
          </StyledNeedReviewMessage>
        )}
    </StyledCorrectTextTopic>
  );
};
