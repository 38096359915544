import { useEffect } from "react";
import ReactTooltip from "react-tooltip";

/**
 *  This hook must be used in components that make use of the "data-tip"
 * attribute to display a tooltip using the "react-tooltip" library.
 *
 *  This is necessary in case that we want to use the "TooltipContainer"
 * instance located at "App" component, so listeners are added to effectively
 * listen to the newly rendered items inside of the caller component.
 *
 *  @param {boolean} [shouldTriggerRebuild] - Used to determine whether we want to
 * really trigger a rebuilt or not. This will always be done by default, but you
 * can override this behaviour by setting this parameter to "false" when you have
 * tooltips that appear conditionally and you'd rather do the rebuild only if we
 * really need to render a tip in your component.
 *
 *  @see https://github.com/wwayne/react-tooltip#3-tooltip-not-binding-to-dynamic-content
 */
export const useReactTooltip = (shouldTriggerRebuild = true): void => {
  useEffect(() => {
    if (shouldTriggerRebuild) {
      ReactTooltip.rebuild();
    }
  }, [shouldTriggerRebuild]);
};
