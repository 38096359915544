import styled from "styled-components/macro";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { Link } from "components/Link";
import type { ThemeProps } from "models/styles/theme";

export const StyledNavContainer = styled.section<ThemeProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0 2rem 0;
`;

export const StyledNavLink = styled(Link)`
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: 0.75rem;
  }
`;

interface StyledNavTextProps extends ThemeProps {
  align: "left" | "right";
}

export const StyledNavText = styled.div<StyledNavTextProps>`
  text-align: ${(props: StyledNavTextProps) => props.align};
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};

  > :nth-child(2) {
    font-size: ${(props: ThemeProps) => props.theme.font.size._14};
    color: ${(props: ThemeProps) => props.theme.palette.neutral._400};
  }
`;

export const StyledNavIcon = styled(FontAwesomeIcon)<ThemeProps>`
  width: 3rem;
  height: 3rem;
  display: block;
  line-height: 3rem;
  text-align: center;
  border-radius: 0.25rem;
  vertical-align: middle;
  background: ${(props: ThemeProps) => props.theme.palette.neutral._950};
`;

export const StyledNavPlaceholder = styled.div<ThemeProps>``;
