import { SvgIconReact } from "components/SvgIconReact";
import type React from "react";
import { StyledProgressDotsContainer } from "./progress-dots.styled";

interface ProgressDotsProps {
  count: number;
  activeIndex: number;
}

export const ProgressDots: React.FC<ProgressDotsProps> = ({ count, activeIndex }) => (
  <StyledProgressDotsContainer>
    {[...Array(count)].map((_, index) => (
      // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
      <SvgIconReact
        className={index === activeIndex ? "active" : ""}
        dataCy="dot-icon"
        iconName="dotIcon"
      />
    ))}
  </StyledProgressDotsContainer>
);
