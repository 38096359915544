import { SvgIconReact } from "components/SvgIconReact/SvgIconReact";
import { modalIds } from "constants/modals-constants";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useModal } from "hooks/useModal";
import type React from "react";
import { useTranslation } from "react-i18next";
import { StyledAccessibilityMenuButton } from "./AccessibilityMenuButton.styled";

export const AccessibilityMenuButton: React.FC = () => {
  const { t } = useTranslation("common");
  const { toggleModal } = useModal();
  const { showImmersiveReader } = useFeatureFlags();

  const toggleAccessibilityModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleModal(modalIds.modalAccessibilityMenu);
  };

  if (!showImmersiveReader) return null;

  return (
    <StyledAccessibilityMenuButton
      aria-label={t("header.button.accessibility")}
      data-cy="open-accessibility-menu-button"
      variant="text"
      onClick={toggleAccessibilityModal}
    >
      <SvgIconReact iconName="accessibilityIcon" size={1.5} />
      <span>{t("header.button.label", "Accessibility")}</span>
    </StyledAccessibilityMenuButton>
  );
};
