import styled from "styled-components/macro";

export const StyledWrapper = styled.div`
  background: #e5e5e5;
  height: 41.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 70%;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;

    > h1 {
      padding-top: 2rem;
    }

    > h3 {
      font-weight: 400;
    }
  }
`;

export const StyledHeader = styled.div`
  background: white;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
`;
