import type React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { AdaptiveTestWorkflowType } from "constants/exam-constants";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";

import { buildUrlWithPathAndQueryParams, buildUrlWithPathParams } from "_helpers/utils/urlBuilder";

import { useAdaptivePractice } from "hooks/useAdaptivePractice";

import { Heading2, Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssFlex } from "styles/helpers/layout";

import MixedFlowIconSvg from "assets/images/mixed-flow-icon.svg";
import SequentialFlowIconSvg from "assets/images/sequential-flow-icon.svg";

import type { AdaptiveTestPageParams } from "../AdaptiveTestPage/AdaptiveTestPage.model";
import {
  StyledCard,
  StyledCloseButton,
  StyledPageContainer,
} from "./AdaptiveWorkflowSelectPage.styled";

export const AdaptiveWorkflowSelectPage: React.FC = () => {
  const { t } = useTranslation("adaptive-test");
  const history = useHistory();
  const { productId, moduleId, subjectId }: AdaptiveTestPageParams = useParams();
  const { updateAdaptivePracticeWorkflow } = useAdaptivePractice();

  const onExitButtonClick = useCallback(() => {
    trackPlatformEvent({
      module: STUDENT_MODULES.BASIC_PROGRESS,
      itemId:
        PLATFORM_EVENT_TYPES.STUDENT_ADAPTIVE_PRACTICE_EVENT_TYPES
          .CLICK_EXIT_ADAPTIVE_WORKFLOW_SELECTION,
    });

    // Redirect back to the overview page
    history.push(
      buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, { productId, moduleId }),
    );
  }, [productId, moduleId]);

  const selectAdaptiveWorkflow = useCallback(
    (workflow: AdaptiveTestWorkflowType) => {
      // Call endpoint to store selected workflow in the background
      updateAdaptivePracticeWorkflow(+productId, workflow);

      // Track event in Metabase when user chooses a workflow
      trackPlatformEvent({
        module: STUDENT_MODULES.BASIC_PROGRESS,
        itemId:
          PLATFORM_EVENT_TYPES.STUDENT_ADAPTIVE_PRACTICE_EVENT_TYPES.CLICK_SELECT_ADAPTIVE_WORKFLOW,
        elementId: `${workflow}-adaptive-flow-card`,
        value: JSON.stringify({ productId: +productId, workflow }),
      });

      // Redirect to the adaptive practice page with the right query params depending on selection
      history.push(
        buildUrlWithPathAndQueryParams(
          TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
          { productId, moduleId, subjectId },
          workflow === AdaptiveTestWorkflowType.SEQUENTIAL ? {} : { workflow },
        ),
      );
    },
    [productId, moduleId, subjectId],
  );

  return (
    <StyledPageContainer flexDirection="column">
      <StyledCloseButton
        data-cy="exit-workflow-select-button"
        iconName="far fa-times"
        iconSize="2x"
        onClick={onExitButtonClick}
      />
      <CssFlex
        alignItems="center"
        flexDirection="column"
        flexGrow={1}
        justifyContent="center"
        rowGap={5}
      >
        <Heading2>{t("workflowSelection.header")}</Heading2>
        <CssFlex flexWrap="wrap" gap={2} justifyContent="center">
          <StyledCard
            data-cy="sequential-adaptive-flow-card"
            onClick={() => selectAdaptiveWorkflow(AdaptiveTestWorkflowType.SEQUENTIAL)}
          >
            <img alt={t("workflowSelection.sequentialIconAltText")} src={SequentialFlowIconSvg} />
            <Heading3>{t("workflowSelection.sequentialFlow.title")}</Heading3>
            <ParagraphLarge>{t("workflowSelection.sequentialFlow.description")}</ParagraphLarge>
          </StyledCard>
          <StyledCard
            data-cy="mixed-adaptive-flow-card"
            onClick={() => selectAdaptiveWorkflow(AdaptiveTestWorkflowType.MIXED)}
          >
            <img alt={t("workflowSelection.mixedIconAltText")} src={MixedFlowIconSvg} />
            <Heading3>{t("workflowSelection.mixedFlow.title")}</Heading3>
            <ParagraphLarge>{t("workflowSelection.mixedFlow.description")}</ParagraphLarge>
          </StyledCard>
        </CssFlex>
      </CssFlex>
    </StyledPageContainer>
  );
};
