import { Input } from "components/Input";
import type { ThemeProps } from "models/styles/theme";
import styled, { css } from "styled-components/macro";
import { Heading2, Heading4, Heading4Css } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssGrid } from "styles/helpers/layout";

export const FormStep = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`;

const labelCss = css`
  ${Heading4Css}
  display: block;
  margin: 0.5rem 0;
`;

export const FormLabel = styled.label`
  ${labelCss}
`;

export const FormH4Label = styled.h4`
  ${labelCss}
`;

export const FormCheckboxLabel = styled.label`
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.normal};
`;

export const FormStepHeader = styled.div`
  margin-bottom: 2rem;
`;

export const FormStepHeading = styled(Heading2)`
  margin: 1rem 0;
`;

export const FormStepIntro = styled(ParagraphLarge)``;

export const FormInput = styled(Input)`
  line-height: 1.5rem;
`;

export const StyledTeacherWarning = styled(CssGrid)<ThemeProps>`
  color: #0072c3;
  background: #e5f6ff;

  padding: 1.5rem;
  column-gap: 1rem;
  align-items: center;
  grid-template-areas:
    'info-icon title toggle-icon'
    '. content .';
  grid-template-columns: auto 1fr auto;

  > :first-child {
    grid-area: info-icon;
  }

  > ${Heading4} {
    grid-area: title;
    margin: 0;
    color: #0072c3;
    font-size: ${(props: ThemeProps) => props.theme.font.size._18};
    line-height: 1.5rem;
  }

  > :nth-child(3) {
    grid-area: toggle-icon;
  }

  > div {
    grid-area: content;

    ${ParagraphLarge} {
      color: #0072c3;

      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;
