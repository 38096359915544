import { useTheme } from "styled-components/macro";
import { Box } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface DividerProps {
  width?: string;
}

export const Divider = ({ width = "100%" }: DividerProps): JSX.Element => {
  const theme = useTheme() as ElearningThemeInterface;
  return (
    <Box background={theme.palette.neutral._900} height="1px" margin="0 auto" maxWidth={width} />
  );
};
