import { StatusTag } from "components/StatusTag/StatusTag";
import type React from "react";
import { useTranslation } from "react-i18next";
import type { LogStatusTagProps } from "./LogStatusTag.model";

export const LogStatusTag: React.FC<LogStatusTagProps> = ({ status }) => {
  const { t } = useTranslation("scheduled-tests");

  switch (status) {
    case "termsAccepted":
    case "testing":
      return <StatusTag label={t("liveMonitor.status.testing")} variant="info" />;
    case "finished":
      return <StatusTag label={t("liveMonitor.status.finished")} variant="success" />;
    default:
      return <StatusTag label={t("liveMonitor.status.notStarted")} variant="default" />;
  }
};
