import type { AdaptivePracticeSessionResults } from "hooks/useAdaptivePractice";
import type { StudentSubjectProgress } from "hooks/useProductProgress";
import type { ProductDetails } from "models/product/ProductDetails";
import type { ComparedSessionTopicProgress } from "./AdaptivePracticeSessionResultsPage.model";

export const buildComparedSessionTopicProgress = (
  sessionResults: AdaptivePracticeSessionResults,
  moduleSubjectsProgress: StudentSubjectProgress[],
  productDetails: ProductDetails | undefined,
): ComparedSessionTopicProgress[] => {
  const allModuleTopics = moduleSubjectsProgress
    .flatMap((x) => x.normalTopics)
    .concat(moduleSubjectsProgress.flatMap((x) => x.themeAssignments));

  const practicedTopics: ComparedSessionTopicProgress[] = [];

  for (const topic of sessionResults.initialTopicProgress) {
    const currentProgress = allModuleTopics.find((x) => x.id === topic.id);
    // The case that we don't have updated progress for the topic, namely if the topic in question
    // is a theme assignment
    if (!currentProgress?.progress || !currentProgress?.skillLevel) continue;

    const initialProgress = topic.currentProgress;
    const initialSkillLevel = topic.currentUserLevel?.level;
    const updatedProgress = currentProgress?.progress;
    const updatedSkillLevel = currentProgress?.skillLevel;

    let isImproved = false;

    if (initialSkillLevel && updatedSkillLevel) {
      // user improved skill level during session
      if (updatedSkillLevel > initialSkillLevel) {
        isImproved = true;
      } else if (updatedProgress && updatedSkillLevel === initialSkillLevel) {
        // user just improved percentage progress for skill level during session
        isImproved = updatedProgress > initialProgress;
      }
    }

    practicedTopics.push({
      id: topic.id,
      title: topic.title,
      initialProgress,
      initialSkillLevel,
      updatedProgress,
      updatedSkillLevel,
      isImproved,
      updatedSkillLevelLabel:
        productDetails?.levels.find((x) => x.level === currentProgress?.skillLevel)?.label || "",
    });
  }

  return practicedTopics;
};
