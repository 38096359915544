import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { CssFlex } from "styles/helpers/layout";

import { ReviewDates } from "./components/ReviewDates/ReviewDates";

export const StyledInnerWrapper = styled(CssFlex)<ThemeProps>`
  row-gap: 1rem;
  padding: 1rem 3rem;
  flex-direction: column;
  align-items: flex-start;

  > h3:not(:first-child) {
    margin-top: 1rem;
  }

  button {
    margin: 1rem auto;
  }
`;

export const StyledReviewDateForm = styled(ReviewDates)<ThemeProps>`
  margin: 0;
  border: 0;
  padding: 0;
  text-align: left;

  h4 {
    font-weight: normal;
  }
`;
