import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEST_PAGE_ROUTES } from "constants/routes";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import personThinkingImg from "assets/images/person-thinking.png";
import { useProfile } from "hooks/useProfile";
import { Heading4 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import {
  StyledButton,
  StyledContent,
  StyledDiagnosticTestBanner,
} from "./DiagnosticTestBanner.styled";

export interface PathParams {
  productId: string;
  moduleId: string;
}

interface ComponentProps {
  currentGoalLevel?: number | string;
}

export const DiagnosticTestBanner: React.FC<ComponentProps> = ({ currentGoalLevel }) => {
  const { t } = useTranslation("homepage");
  const { productId, moduleId }: PathParams = useParams();
  const { userDetails } = useProfile();
  const history = useHistory();

  const goToDiagnosticTest = () => {
    trackPlatformEvent({
      module: STUDENT_MODULES.BASIC_PROGRESS,
      itemId: PLATFORM_EVENT_TYPES.STUDENT_MODULES_EVENT_TYPES.CLICK_GO_TO_PRACTICE_TEST,
      elementId: `module-${moduleId}-diagnostic-test-banner-button`,
    });
    history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS, {
        productId: productId || 0,
        moduleId,
      }),
    );
  };

  return (
    <StyledDiagnosticTestBanner>
      <img alt={t("diagnosticTest.banner.altImgText")} src={personThinkingImg} />
      <StyledContent>
        <Heading4>{t("diagnosticTest.banner.heading", { name: userDetails?.firstName })}</Heading4>
        <ParagraphLarge>{t("diagnosticTest.banner.text")}</ParagraphLarge>
        <StyledButton
          dataCy="start-diagnostic-test-button"
          disabled={!currentGoalLevel}
          variant="primary"
          onClick={goToDiagnosticTest}
        >
          {t("diagnosticTest.banner.button")}
        </StyledButton>
      </StyledContent>
    </StyledDiagnosticTestBanner>
  );
};
