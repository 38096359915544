import type { ThemeProps } from "models/styles/theme";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";

interface Props extends ThemeProps {
  $disabled: boolean;
}

export const StyledItemContainer = styled(Link)<Props>`
  width: 100%;
  display: flex;
  column-gap: 5rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  color: ${(props: Props) => props.theme.palette.neutral.black};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "inherit")};
  border-top: ${(props: Props) => `1px solid ${props.theme.palette.neutral._800}`};
  :first {
    border-top: none;
  }
`;

export const StyledTitle = styled(Heading3)`
  margin: 0;
  flex-grow: 1;
`;
