import styled, { css } from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { Box } from "styles/helpers/layout";

export declare type StatusTagVariant = "default" | "info" | "success" | "error";

interface StyledStatusTagProps extends ThemeProps {
  $variant?: StatusTagVariant;
}

export const StyledStatusTag = styled(Box)<StyledStatusTagProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }: StyledStatusTagProps) => theme.font.size._14};
  font-weight: ${({ theme }: StyledStatusTagProps) => theme.font.weight.bold};

  border-radius: 1rem;
  padding: 0.15rem 0.5rem;

  ${({ theme, $variant }: StyledStatusTagProps) => {
    switch ($variant) {
      case "info":
        return css`
          color: ${theme.palette.feedback.info.brand};
          background-color: ${theme.palette.feedback.info._950};
        `;
      case "success":
        return css`
          color: ${theme.palette.feedback.success.brand};
          background-color: ${theme.palette.feedback.success._950};
        `;
      case "error":
        return css`
          color: ${theme.palette.feedback.error.brand};
          background-color: ${theme.palette.feedback.error._950};
        `;
      default:
        return css`
          color: ${theme.palette.neutral._400};
          background-color: ${theme.palette.neutral._950};
        `;
    }
  }};
`;
