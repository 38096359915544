import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Switch, useHistory } from "react-router-dom";

import { modalIds } from "constants/modals-constants";
import {
  AUTH_PAGES_ROUTES,
  PRODUCT_PAGES_ROUTES,
  TEACHER_MONITOR_PAGE_ROUTES,
  TEACHER_SCHEDULED_TEST_PAGE_ROUTES,
} from "constants/routes";

import { useModal } from "hooks/useModal";
import { useProfile } from "hooks/useProfile";
import useRequest from "hooks/user/useRequest";
import { AuthRoute } from "layouts/AuthRoute";

import { BackButton } from "components/BackButton";
import { TeacherHeader } from "../components/Header/TeacherHeader";
import { StyledTeacherMonitorPage } from "./TeacherMonitor.styled";
import { ScheduledTestLiveMonitorPage } from "./subpages/ScheduledTestLiveMonitor/ScheduledTestLiveMonitorPage";

export const TeacherMonitor: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation("scheduled-tests");
  const { fetchUserDetails, userDetails, updateUserDetails } = useProfile();
  const { logout } = useRequest();
  const { toggleModal } = useModal();

  const onToggleSideMenu = () => {
    toggleModal(modalIds.modalSideMenu);
  };

  useEffect(() => {
    if (!userDetails) {
      fetchUserDetails();
    }
  }, [userDetails, fetchUserDetails]);

  const navigateToStudentEnvironment = () => {
    history.push(PRODUCT_PAGES_ROUTES.PRODUCT_LIST);
  };

  return (
    <div data-cy="test-monitor-page">
      <TeacherHeader
        navigateToStudentEnvironment={navigateToStudentEnvironment}
        toggleSideMenu={onToggleSideMenu}
        updateUserDetails={updateUserDetails}
        userDetails={userDetails}
        onLogout={logout}
      />
      <StyledTeacherMonitorPage>
        <BackButton
          dataCy="back-to-overview-button"
          label={t("liveMonitor.button.backToOverview", "Back to overview")}
          redirectUrl={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR_OVERVIEW}
        />
        <Switch>
          <AuthRoute
            exact
            component={ScheduledTestLiveMonitorPage}
            path={TEACHER_MONITOR_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
        </Switch>
      </StyledTeacherMonitorPage>
    </div>
  );
});
