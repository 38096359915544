import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";

import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import { useTally } from "hooks/useTally";
import { useTeacherOverview } from "hooks/useTeacherOverview";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";

import {
  getStoredGoalLevelByProductId,
  storeGoalLevelByProductId,
} from "_helpers/localStorageGoalLevelProvider";
import { TEACHER_HOME_PAGE_ROUTES, TEACHER_INSIGHT_PAGE_ROUTES } from "constants/routes";

import type { OverviewFilters } from "models/progress/Progress";
import { TeacherReportType } from "models/report/Report";

import { Paginator } from "components/PaginatorRemake/Paginator";
import { SkeletonLoader } from "components/SkeletonLoader/SkeletonLoader";
import { TeacherDashboardFilters } from "../../components/TeacherDashboardFilters";
import { StudentProgressOverview } from "./components/StudentProgressOverview/StudentProgressOverview";
import { SubjectKnowledgeOverview } from "./components/SubjectKnowledgeOverview/SubjectKnowledgeOverview";

import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { StyledPageContainer } from "./BasicProgress.styled";

export const BasicProgressOverviewPage: React.FC = observer(() => {
  const { showGoalLevelSelectorOnTeacherDashboard } = useFeatureFlags();

  const {
    studentsProgressOverview,
    subjectsProgressOverview,
    fetchSubjectsProgressOverview,
    fetchStudentsProgressOverview,
  } = useProductProgress();

  const { productDetails, fetchProductDetails } = useProduct();
  useTally();

  const {
    productId,
    page,
    nonOwnedProductError,
    currentEducationTitle,
    currentYearsTitle,
    showEducationFilter,
    showSchoolYearFilter,
    userEducations,
    productSchoolYears,
    paginationFilters,
    buildPaginatedURL,
  } = useTeacherOverview(TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW);

  const selectableGoalLevels = useMemo(
    () => productDetails?.levels.filter(({ selectable }) => selectable) || [],
    [productDetails],
  );
  const selectedGoalLevel = getStoredGoalLevelByProductId(+productId);
  const [targetLevel, setTargetLevel] = useState<number>();

  const onTargetLevelChange = (goalLevelToSet: number) => {
    trackPlatformEvent({
      module: TEACHER_MODULES.BASIC_PROGRESS,
      itemId: PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES.UPDATE_VIEW_BY_TARGET_LEVEL,
      value: JSON.stringify({ from: targetLevel, to: goalLevelToSet }),
    });
    storeGoalLevelByProductId(+productId, goalLevelToSet);
    setTargetLevel(goalLevelToSet);
  };

  const {
    resultsPerPage,
    educationId,
    schoolYearEnd,
    schoolYearStart,
    groupId,
    searchTerm,
  }: OverviewFilters = paginationFilters;

  const numPages =
    subjectsProgressOverview && resultsPerPage
      ? Math.ceil(subjectsProgressOverview.studentIds.length / resultsPerPage)
      : 1;

  useEffect(() => {
    if (!showGoalLevelSelectorOnTeacherDashboard) {
      fetchStudentsProgressOverview(+productId, paginationFilters);
    } else if (targetLevel) {
      fetchStudentsProgressOverview(+productId, { ...paginationFilters, targetLevel });
    }
  }, [
    productId,
    page,
    resultsPerPage,
    educationId,
    schoolYearStart,
    schoolYearEnd,
    groupId,
    targetLevel,
    searchTerm,
  ]);

  useEffect(() => {
    if (!showGoalLevelSelectorOnTeacherDashboard) {
      fetchSubjectsProgressOverview(+productId, paginationFilters);
    } else if (targetLevel) {
      fetchSubjectsProgressOverview(+productId, { ...paginationFilters, targetLevel });
    }
  }, [productId, educationId, schoolYearStart, schoolYearEnd, searchTerm, groupId, targetLevel]);

  // Load product details when they're not yet in memory or product id in the URL has changed
  useEffect(() => {
    if (!productDetails || productDetails.id !== +productId) {
      fetchProductDetails(+productId);
    }
  }, [productDetails, productId]);

  // Initialize target level filter to first selectable goal in the current product
  //  (or the previous selected goal in local storage in case the user had already used the filter)
  useEffect(() => {
    if (!targetLevel && productDetails?.id === +productId) {
      setTargetLevel(selectedGoalLevel || selectableGoalLevels[0]?.level);
    }
  }, [selectedGoalLevel, selectableGoalLevels[0]?.level, productDetails?.id, productId]);

  if (!Number(productId) || nonOwnedProductError) {
    return <Redirect to={TEACHER_HOME_PAGE_ROUTES.DASHBOARD} />;
  }

  return (
    <StyledPageContainer $hasSideFilters>
      <div>
        <TeacherDashboardFilters
          buildPaginatedURL={buildPaginatedURL}
          currentEducationTitle={currentEducationTitle}
          currentYearsTitle={currentYearsTitle}
          productId={+productId}
          productSchoolYears={productSchoolYears}
          selectableGoalLevels={selectableGoalLevels}
          showEducationFilter={showEducationFilter}
          showSchoolYearFilter={showSchoolYearFilter}
          targetLevel={targetLevel}
          teacherReportType={TeacherReportType.BASIC_PROGRESS}
          userEducations={userEducations}
          onTargetLevelChange={onTargetLevelChange}
        />

        {!subjectsProgressOverview || !productDetails ? (
          <SkeletonLoader $marginTop={4} count={5} />
        ) : (
          <SubjectKnowledgeOverview subjects={subjectsProgressOverview.subjects} />
        )}

        {!studentsProgressOverview ? (
          <SkeletonLoader $marginTop={4} count={5} />
        ) : (
          <StudentProgressOverview
            productId={+productId}
            studentsProgress={studentsProgressOverview}
            totalStudents={subjectsProgressOverview?.studentIds.length ?? null}
          />
        )}

        {page && numPages > 1 && (
          <Paginator
            currentPage={page}
            linkBuilderFunction={buildPaginatedURL}
            totalPages={numPages}
          />
        )}
      </div>
    </StyledPageContainer>
  );
});
