import type React from "react";

import { CssIcon, CssLink } from "styles/helpers/layout";

interface BackButtonProps {
  dataCy?: string;
  label: string;
  redirectUrl: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ dataCy, label, redirectUrl }) => (
  <CssLink
    data-cy={dataCy || "back-button"}
    marginBottom="1.5rem"
    role="button"
    tabIndex={0}
    to={redirectUrl}
    variant="default"
  >
    <CssIcon iconName="far fa-angle-left" />
    {label}
  </CssLink>
);
