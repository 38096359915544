import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

export const StyledTable = styled.table<Props>`
  width: 100%;
  border: solid 1px ${(props: Props) => props.theme.palette.neutral._900};
  border-collapse: collapse;

  thead > tr > th {
    padding: 1rem;
    font-weight: bold;
    vertical-align: middle;
  }

  tbody > tr > td {
    padding: 1rem;
    line-height: 2rem;
    vertical-align: top;
  }

  tr > * {
    :first-child {
      width: 11rem;
    }

    :nth-child(2) {
      width: 6rem;
      font-weight: bold;
      text-align: center;
    }

    :nth-child(3) {
      width: auto;
    }

    :nth-child(4) {
      width: 20rem;
    }

    :last-child {
      width: 4rem;
    }
  }
`;
