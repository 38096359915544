import { addMinutes, max } from "date-fns";
import type { ScheduledTestDate } from "models/exam/ScheduledTest";

export const getLatestTestInstance = (moments: ScheduledTestDate[]): Date => {
  // get end times of all the test instances, taking duration into account
  const endTimes = moments.map((moment) => addMinutes(moment.startDate, moment.duration));

  //return the latest one
  return max(endTimes);
};
