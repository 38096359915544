import type React from "react";

import { getCurrentUser } from "persistence/access";

import type { RoleComponentProps } from "./RoleComponent.model";
import notValidRole from "./notValidRole";

/**
 *  This component can be wrapped around content you only want to
 * be available for a given set of roles.
 *
 * @param {string[]} allowedRoles - List of roles that will be allowed to
 *  see the content.
 * @param {JSX.Element} children - Content that should be restricted to the
 *  specified roles.
 * @param {JSX.Element} [fallbackContent] - Content that will be rendered when
 *  the user accessing the page doesn't have the appropiate role. If none is
 *  specified, nothing will be rendered.
 * */
export const RoleComponent: React.FC<RoleComponentProps> = ({
  children,
  allowedRoles = [],
  fallbackContent = null,
}) => {
  const user = getCurrentUser();
  return !user || notValidRole(user.role, allowedRoles) ? fallbackContent : children;
};
