import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { Modal } from "components/Modal";
import { SvgIconReact } from "components/SvgIconReact";
import { modalIds } from "constants/modals-constants";
import { TEST_PAGE_ROUTES } from "constants/routes";
import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useModal } from "hooks/useModal";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { CssFlex } from "styles/helpers/layout";
import type { PathParams } from "./FinishedSubjectModal.model";
import { StyledButton, StyledHeading, StyledParagraph } from "./FinishedSubjectModal.styled";

export const MyFinishedSubjectModal: React.FC = () => {
  const { t } = useTranslation("homepage");
  const history = useHistory();
  const { setMixedExercise, resetCurrentMixedExerciseStats } = useAdaptivePractice();
  const { closeModal, isModalOpen, modalParams } = useModal();
  const { productId, moduleId }: PathParams = useParams();
  const { productGoalLevel } = useProductProgress();
  const { productDetails, fetchProductDetails } = useProduct();
  const completedSubjectModalId = modalIds.completedSubject;
  const keepPracticing = () => {
    closeModal();
    setMixedExercise(true);
    resetCurrentMixedExerciseStats();
    history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST, {
        productId,
        moduleId,
        subjectId: `${modalParams?.subjectId}`,
      }),
    );
  };

  useEffect(() => {
    if (!productDetails || productDetails.id !== +productId) fetchProductDetails(+productId);
  }, [productDetails, productId]);

  const goalLevel =
    productDetails?.levels.find(({ level }) => productGoalLevel?.goalLevel === level)?.label ?? "";

  return (
    <Modal
      wrapInModalBox
      dataCy={completedSubjectModalId}
      isOpen={isModalOpen(completedSubjectModalId)}
      showCloseButton={false}
      onClose={closeModal}
    >
      <SvgIconReact iconName="circleCheckIcon" size={5} />
      <StyledHeading>{t("finishedSubject.modal.title", "Good job!", { goalLevel })}</StyledHeading>
      <StyledParagraph $marginTop={8}>{t("finishedSubject.modal.description")}</StyledParagraph>
      <CssFlex>
        <StyledButton $transparentBackground variant="brand" onClick={closeModal}>
          {t("finishedSubject.modal.backToOverview", "Back to overview")}
        </StyledButton>
        <StyledButton data-cy="keep-practicing" variant="primary" onClick={keepPracticing}>
          {t("finishedSubject.modal.startMixedExercises")}
        </StyledButton>
      </CssFlex>
    </Modal>
  );
};

export const FinishedSubjectModal = React.memo(MyFinishedSubjectModal);
