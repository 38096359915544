import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledWrapper = styled.div<Props>`
  margin-left: auto;
  margin-top: 2rem;
  > div {
    :first-child {
      display: flex;
      justify-content: space-between;

      > h3 {
        font-weight: 400;
      }
    }

    :last-child {
      display: grid;
      gap: 1rem;
      grid-template-columns: ${Array(4).fill("1fr").join(" ")};

      > div {
        padding: 1rem;
        margin-top: 1rem;
        border: 1px solid ${({ theme }: Props) => theme.palette.neutral._900};

        > h4,
        h3 {
          text-align: left;
        }

        > h4 {
          font-weight: 400;
          margin-bottom: 2rem;
        }
      }
    }
  }
`;

export const StyledLevelProgressBarWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    max-width: 70%;
  }
`;
