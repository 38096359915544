import type React from "react";
import { useTranslation } from "react-i18next";

import { modalIds } from "constants/modals-constants";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEST_PAGE_ROUTES } from "constants/routes";
import { useModal } from "hooks/useModal";
import { getDiagnosticModalHasBeenShown, setDiagnosticModalHasBeenShown } from "persistence/exams";
import { useHistory, useParams } from "react-router-dom";
import { Heading1 } from "styles/elements/Headings";
import { SvgIconReact } from "../SvgIconReact";
import type { PathParams } from "./DiagnosticTestModal.model";
import {
  StyledButton,
  StyledDiagnosticTestModal,
  StyledDiagnosticTestModalHeader,
  StyledDiagnosticTestModalParagraph,
} from "./DiagnosticTestModal.styled";

export const DiagnosticTestModal: React.FC = () => {
  const { t } = useTranslation("diagnostic-test");
  const { productId, moduleId }: PathParams = useParams();
  const history = useHistory();
  const { isModalOpen, toggleModal, closeModal } = useModal();

  const goToDiagnosticTest = () => {
    setDiagnosticModalHasBeenShown(true);
    toggleModal(modalIds.diagnosticTestModal);
    history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS, {
        productId: productId || 0,
        moduleId,
      }),
    );
  };

  const onCloseDiagnosticTestModal = () => {
    setDiagnosticModalHasBeenShown(true);
    closeModal();
  };

  const diagnosticModalHasNotBeenShown = !getDiagnosticModalHasBeenShown();

  return (
    <StyledDiagnosticTestModal
      closeOnClickOutside
      dataCy={modalIds.diagnosticTestModal}
      isOpen={diagnosticModalHasNotBeenShown && isModalOpen(modalIds.diagnosticTestModal)}
      onClose={onCloseDiagnosticTestModal}
    >
      <StyledDiagnosticTestModalHeader>
        <SvgIconReact iconName="partyIcon" size={10} />
        <Heading1>{t("modal.title")}</Heading1>
        <SvgIconReact iconName="partyIcon" size={10} />
      </StyledDiagnosticTestModalHeader>
      <StyledDiagnosticTestModalParagraph>
        {t("modal.paragraph")}
      </StyledDiagnosticTestModalParagraph>
      <StyledButton
        dataCy="diagnostic-test-modal-button"
        size="medium"
        variant="primary"
        onClick={goToDiagnosticTest}
      >
        {t("modal.buttonLabel")}
      </StyledButton>
    </StyledDiagnosticTestModal>
  );
};
