import type React from "react";
import type { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProduct } from "hooks/useProduct";

import { SanitizedHtml } from "components/SanitizedHtml";

import type { CongratulationsMessageProps } from "./CongratulationsMessage.model";
import { StyledAchievementToast, StyledMessage } from "./CongratulationsMessage.styled";

type TextView = {
  dataCy: string;
  title: ReactElement;
  description: ReactElement;
};

export const CongratulationsMessage: React.FC<CongratulationsMessageProps> = ({
  currentTopic,
  userName,
  userGoalLevel,
}) => {
  const { t } = useTranslation("adaptive-test");
  const { productDetails } = useProduct();
  const { adaptivePracticeCongratsMessageDuration } = useFeatureFlags();
  const { congratulationsMessage, setCongratulationsMessage } = useAdaptivePractice();

  const onMessageFade = () => {
    setCongratulationsMessage("none");
  };

  const goalLevelName = productDetails?.levels.find(({ level }) => level === userGoalLevel)?.label;
  const chapterTitle = currentTopic?.chapter.title;

  const getTextView = (): TextView | null => {
    switch (congratulationsMessage) {
      case "theme": {
        return {
          dataCy: "chapter-finished-message",
          title: (
            <Trans
              components={{
                userTag: <span data-cy="user-name" />,
                chapterTitleRenderer: (
                  <SanitizedHtml
                    dataCy="chapter-title"
                    dirtyHtml={chapterTitle || ""}
                    tagName="span"
                  />
                ),
              }}
              i18nKey={"mixedFlowCongratulationsBanner.finishedChapter.title"}
              t={t}
              values={{ userName }}
            />
          ),
          description: (
            <Trans
              components={{
                goalLevelTag: <span data-cy="goal-level" />,
                chapterTitleRenderer: (
                  <SanitizedHtml
                    dataCy="chapter-title"
                    dirtyHtml={chapterTitle || ""}
                    tagName="span"
                  />
                ),
              }}
              i18nKey={"mixedFlowCongratulationsBanner.finishedChapter.description"}
              t={t}
              values={{ userName, goalLevel: goalLevelName }}
            />
          ),
        };
      }
      case "theme-assignment-unlocked": {
        return {
          dataCy: "theme-assignment-unlocked-message",
          title: (
            <Trans i18nKey={"mixedFlowCongratulationsBanner.themeAssignmentUnlocked.title"} t={t} />
          ),
          description: (
            <Trans
              components={{
                chapterTitleRenderer: (
                  <SanitizedHtml
                    dataCy="chapter-title"
                    dirtyHtml={chapterTitle || ""}
                    tagName="span"
                  />
                ),
              }}
              i18nKey={"mixedFlowCongratulationsBanner.themeAssignmentUnlocked.description"}
              t={t}
            />
          ),
        };
      }
      case "sub-theme": {
        return {
          dataCy: "topic-finished-message",
          title: (
            <Trans
              components={{
                userTag: <span data-cy="user-name" />,
              }}
              i18nKey={"mixedFlowCongratulationsBanner.finishedTopic.title"}
              t={t}
              values={{ userName }}
            />
          ),
          description: (
            <Trans
              components={{
                goalLevelTag: <span data-cy="goal-level" />,
                topicTitleRenderer: (
                  <SanitizedHtml
                    dataCy="topic-title"
                    dirtyHtml={currentTopic?.title || ""}
                    tagName="span"
                  />
                ),
              }}
              i18nKey={"mixedFlowCongratulationsBanner.finishedTopic.description"}
              t={t}
              values={{ goalLevel: goalLevelName }}
            />
          ),
        };
      }
      default:
        return null;
    }
  };

  const messageContents = getTextView();
  if (!messageContents) {
    return null;
  }

  const { dataCy, title, description } = messageContents;

  // Convert congrats message duration (which is specified in seconds) to milliseconds
  const toastDurationInMs = +adaptivePracticeCongratsMessageDuration * 1000;

  return (
    <StyledAchievementToast
      showCloseButton
      showConfetti
      showCountdown
      dataCy={dataCy}
      duration={toastDurationInMs}
      title={title}
      onFadeOut={onMessageFade}
    >
      <StyledMessage>{description}</StyledMessage>
    </StyledAchievementToast>
  );
};
