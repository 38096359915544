import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { QuestionStatus } from "constants/exam-constants";

import { EmbeddedHtml } from "components/EmbeddedHtml";
import { commonSanitizeOptions } from "layouts/QuestionManager/QuestionManager.constants";

import { useAnswer } from "hooks/useAnswer";
import type { QuestionsManagerPathParams } from "../../QuestionManager.model";
import type { TextAreaQuestionAnswer, TextAreaQuestionProps } from "./TextAreaQuestion.model";
import { StyledTextAreaInput, StyledTextAreaQuestion } from "./TextAreaQuestion.styled";

export const TextAreaQuestion: React.FC<TextAreaQuestionProps> = ({
  answerFeedbackComponent: answerComponent,
  defaultValue = "",
  evaluateAnswer,
  question,
  status,
  isEvaluatingAnswer,
  disableSpellCheck = false,
  prevAnswerValues,
  ariaLabel,
}) => {
  const { t } = useTranslation("skill-determination-test");
  const { setAnswerButtonProps } = useAnswer();
  const prevCurrentAnswers =
    prevAnswerValues && prevAnswerValues.length > 0
      ? prevAnswerValues?.map(({ value }) => value).toString()
      : defaultValue;
  const [currentAnswer, setCurrentAnswer] = useState<TextAreaQuestionAnswer>("");
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const setExerciseIdValue = !exerciseId ? undefined : +exerciseId;

  useEffect(() => {
    setCurrentAnswer(prevCurrentAnswers);
  }, [prevCurrentAnswers]);

  // Set configuration for answer button
  useEffect(() => {
    setAnswerButtonProps({
      label: t("button.checkAnswer.label", "Check answer"),
      isDisabled: currentAnswer === "",
      isHidden: status !== QuestionStatus.INITIAL,
      isLoading: isEvaluatingAnswer || false,
      onClick: (skipAnswerValidation) => {
        evaluateAnswer?.(
          +productId,
          question.id,
          [{ order: 0, value: currentAnswer }],
          setExerciseIdValue,
          skipAnswerValidation,
        );
      },
    });
  }, [currentAnswer, isEvaluatingAnswer, status, setExerciseIdValue, productId, question]);

  return (
    <StyledTextAreaQuestion status={status}>
      <EmbeddedHtml
        rawHtml={question.content}
        sanitizeOptions={commonSanitizeOptions}
        tagName="section"
      />
      <div className="answer-container">
        <StyledTextAreaInput
          aria-label={ariaLabel}
          dataCy="text-area-question"
          id="text-area-question"
          name="text-area-question"
          readOnly={status !== QuestionStatus.INITIAL}
          spellCheck={!disableSpellCheck}
          value={currentAnswer}
          onChange={(e) => setCurrentAnswer(e.target.value)}
        />
        {answerComponent}
      </div>
    </StyledTextAreaQuestion>
  );
};
