import type { Locale } from "date-fns";
import { enGB, nl } from "date-fns/locale";
import { useTranslation } from "react-i18next";

export const useDateLocale = (): Locale => {
  const { i18n } = useTranslation();
  switch (i18n.language) {
    case "nl":
      return nl;
    default:
      return enGB;
  }
};
