import { type FlattenSimpleInterpolation, css } from "styled-components/macro";
import type { ElearningThemeInterface } from "../../../themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
  fontColor?: string;
}

export const fontColorMixin = (props: Props): FlattenSimpleInterpolation => {
  switch (props.fontColor) {
    case "white":
      return css`
        color: ${props.theme.palette.neutral.white};
      `;
    case "primary":
      return css`
        color: ${props.theme.palette.primary.brand};
      `;
    case "secondary":
      return css`
        color: ${props.theme.palette.neutral._950};
      `;
    case "tertiary":
      return css`
        color: ${props.theme.palette.secondary.brand};
      `;
    case "error":
      return css`
        color: ${props.theme.palette.feedback.error._950};
      `;
    case "warning":
      return css`
        color: ${props.theme.palette.feedback.error.brand};
      `;
    case "green":
      return css`
        color: ${props.theme.palette.feedback.success._800};
      `;
    case "greenSuccess":
      return css`
        color: ${props.theme.palette.feedback.success._800};
      `;
    case "grey":
      return css`
        color: ${props.theme.palette.neutral._500};
      `;
    case "greyHigh":
      return css`
        color: ${props.theme.palette.neutral._500};
      `;
    default:
      return css`
        color: ${props.theme.palette.neutral.black};
      `;
  }
};
