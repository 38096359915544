import type { InputWithErrorMessageProps } from "components/InputWithErrorMessage";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components/macro";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssFlex } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import {
  StyledInfoIcon,
  StyledInput,
  StyledInputContainer,
  StyledPasswordIcon,
} from "./PasswordInput.styled";

export const PasswordInput: React.FC<InputWithErrorMessageProps> = (inputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [capsLockOn, setCapsLockOn] = useState(false);
  const [numLockOn, setNumLockOn] = useState(false);
  const { t } = useTranslation("auth-pages");
  const hogesTheme = useTheme() as ElearningThemeInterface;

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.getModifierState) {
        setCapsLockOn(e.getModifierState("CapsLock"));
        setNumLockOn(e.getModifierState("NumLock"));
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return (
    <>
      <StyledInputContainer>
        <StyledInput {...inputProps} type={showPassword ? "text" : "password"} />
        <StyledPasswordIcon
          fixedWidth
          data-cy="toggle-see-password-button"
          iconName={`fas ${!showPassword ? "fa-eye" : "fa-eye-slash"}`}
          onClick={() => setShowPassword(!showPassword)}
        />
      </StyledInputContainer>
      {capsLockOn && (
        <CssFlex alignItems="center" gap={0.5} mt="2px">
          <StyledInfoIcon $color={hogesTheme.palette.yellow._800} iconName="infoIcon" size={0.75} />
          <ParagraphLarge
            color={hogesTheme.palette.yellow._800}
            data-cy="password-input-capslock-message"
            flex={1}
            fontSize="0.75rem"
            textAlign="left"
          >
            {t("createBasicAccountPage.form.fields.password.warnings.capsLockOn", "Capslock is on")}
          </ParagraphLarge>
        </CssFlex>
      )}
      {numLockOn && (
        <CssFlex alignItems="center" gap={0.5} mt="2px">
          <StyledInfoIcon $color={hogesTheme.palette.yellow._800} iconName="infoIcon" size={0.75} />
          <ParagraphLarge
            color={hogesTheme.palette.yellow._800}
            data-cy="password-input-numlock-message"
            flex={1}
            fontSize="0.75rem"
            textAlign="left"
          >
            {t("createBasicAccountPage.form.fields.password.warnings.numLockOn", "Numlock is on")}
          </ParagraphLarge>
        </CssFlex>
      )}
      {inputProps.hasError && (
        <CssFlex alignItems="center" gap={0.5} mt="2px">
          <StyledInfoIcon
            $color={hogesTheme.palette.feedback.error.brand}
            iconName="infoIcon"
            size={0.75}
          />
          <ParagraphLarge
            color={hogesTheme.palette.feedback.error.brand}
            data-cy="password-input-error-message"
            flex={1}
            fontSize="0.75rem"
            textAlign="left"
          >
            {inputProps.errorMessage}
          </ParagraphLarge>
        </CssFlex>
      )}
    </>
  );
};
