import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

interface ProgressBarProps extends Props {
  $currentLevel: number;
  $maxLevel: number;
  $width: number;
  $borderRadius: string;
  $overrideColor?: string;
}

export const StyledProgressBar = styled.div<ProgressBarProps>`
  height: 0.5rem;
  border-radius: ${(props: ProgressBarProps) => props.$borderRadius};
  width: ${(props: ProgressBarProps) => props.$width}%;

  background-color: ${(props: ProgressBarProps) => {
    const currentLevel = props.$currentLevel;
    const maxLevel = props.$maxLevel;
    const percentage = Math.round((currentLevel / maxLevel) * 100);
    const overrideColor = props.$overrideColor;

    if (overrideColor) {
      return overrideColor;
    }

    if (!currentLevel || currentLevel <= 0) {
      return props.theme.palette.neutral._900;
    }

    if (percentage < 25) {
      return props.theme.palette.feedback.error.brand;
    }

    if (percentage < 50) {
      return props.theme.palette.yellow._700;
    }

    if (percentage < 75) {
      return props.theme.palette.feedback.success._800;
    }

    return props.theme.palette.feedback.success._800;
  }};
`;
