import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";

import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import { useProfile } from "hooks/useProfile";
import useRequest from "hooks/user/useRequest";

import { Header } from "components/Header";
import { SvgIconReact } from "components/SvgIconReact";

import type { GeneralHeaderProps } from "./GeneralHeader.model";
import { StyledUserData } from "./GeneralHeader.styled";

export const GeneralHeader: React.FC<GeneralHeaderProps> = observer(
  ({ sideMenuEnabled = true }) => {
    const { toggleModal } = useModal();
    const { fetchUserDetails, userDetails, updateUserDetails } = useProfile();
    const { logout } = useRequest();

    useEffect(() => {
      fetchUserDetails();
    }, [fetchUserDetails]);

    const onToggleSideMenu = () => {
      toggleModal(modalIds.modalSideMenu);
    };

    return (
      <Header
        sideMenuEnabled={sideMenuEnabled}
        toggleSideMenu={onToggleSideMenu}
        updateUserDetails={updateUserDetails}
        userDetails={userDetails}
        onLogout={logout}
      >
        {userDetails && (
          <StyledUserData>
            <SvgIconReact iconName="faceIcon" size={1.5} />
            <span>{userDetails.firstName}</span>
          </StyledUserData>
        )}
      </Header>
    );
  },
);
