import { ToastMethods } from "components/ToastNotification";
import type { TFunction } from "i18next";
import type { ClipboardEvent } from "react";

export const onPaste = async (
  t: TFunction,
  updateEmailList: (emails: string[]) => void,
  event?: ClipboardEvent<HTMLDivElement>,
): Promise<void> => {
  event?.preventDefault();
  if (navigator?.clipboard) {
    const separatorRegExp = new RegExp(/,| |\n|\t|\r\n/g);
    const clipboardText = event
      ? event.clipboardData.getData("text/plain")
      : await navigator.clipboard.readText();
    const emails: string[] = clipboardText.split(separatorRegExp);
    updateEmailList(emails);
  } else {
    ToastMethods.showToast(t("studentLicense.copypaste.error"), "error");
  }
};
