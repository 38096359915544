import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import { LoadingSpinner } from "components/LoadingSpinner/LoadingSpinner";
import { ViewTheoryModal } from "components/ViewTheoryModal";
import { AdaptiveTestWorkflowType, QuestionStatus, TestType } from "constants/exam-constants";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";
import {
  type MixedExerciseForSubject,
  type MixedExerciseForTopic,
  useAdaptivePractice,
} from "hooks/useAdaptivePractice";
import { type EvaluateAnswerParams, useAnswer } from "hooks/useAnswer";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useModal } from "hooks/useModal";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import { useProfile } from "hooks/useProfile";
import { useQueryParam } from "hooks/useQueryParam";
import { textQuestions } from "layouts/HstContentPlayer/HstContentPlayer";
import { QuestionAnswerBox } from "layouts/QuestionAnswerBox/QuestionAnswerBox";
import type { CurrentTopicProgress } from "models/adaptive-practice/AdaptivePractice";
import type { TopicDetails } from "models/product/TopicDetails";
import { Box, CssFlex } from "styles/helpers/layout";
import { SkillLevelChangeMessage } from "../AdaptiveTestPage/components/SkillLevelChangeMessage/SkillLevelChangeMessage";
import type { AdaptiveTestPageParams } from "./MixedTopicPracticePage.model";
import {
  StyledEmptyExamQuestionsMessage,
  StyledPageWrapper,
} from "./MixedTopicPracticePage.styled";
import { CongratulationsMessage } from "./components/CongratulationsMessage/CongratulationsMessage";
import { AdaptiveTestHeader } from "./components/ImprovementTestHeader";
import { SubjectCompleted } from "./components/SubjectCompleted/SubjectCompleted";
import { AdaptiveTestContent } from "./components/TestContent/AdaptiveTestContent";

export const MixedTopicPracticePage: React.FC = observer(() => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [showContinueButton, setShowContinueButton] = useState<boolean>(false);
  const [latestCompletedTopic, setLatestCompletedTopic] = useState<CurrentTopicProgress | null>(
    null,
  );

  const {
    adaptiveTestDetails,
    currentTopic,
    selectedQuestion,
    currentMixedExerciseStats,
    fetchAdaptivePracticeQuestion,
    congratulationsMessage,
    fetchMixedExercise,
    isMixedExerciseActive,
    failedToLoadQuestion,
    setCongratulationsMessage,
    setCurrentMixedExerciseStats,
    resetCurrentMixedExerciseStats,
    nextQuestionIsReady,
    reset,
    setNextQuestionIsReady,
    setSelectedQuestion,
    checkIfNextQuestionIsReady,
    setAdaptivePracticeDetails,
    addSessionQuestionResult,
    updateSessionStreak,
    resetAdaptivePracticeSessionResults,
    fetchNextPracticeTopic,
    subjectCompleted,
    setStartedSessionTimestamp,
  } = useAdaptivePractice();

  const { evaluateAnswer, setQuestionStatus, setQuestionResult } = useAnswer();

  const { productId, subjectId }: AdaptiveTestPageParams = useParams();
  const topicId = useQueryParam("topicId");
  const workflowType = useQueryParam("workflow") || AdaptiveTestWorkflowType.SEQUENTIAL;
  const { closeModal } = useModal();

  const {
    productDetails,
    topicDetails,
    productSubject,
    nonOwnedProductError,
    fetchProductDetails,
    fetchTopicDetails,
    fetchProductSubjectDetails,
  } = useProduct();
  const { productGoalLevel, fetchModuleProgress, fetchProductGoalLevel } = useProductProgress();
  const { userDetails, fetchUserDetails } = useProfile();
  const { questionStatus, questionResult } = useAnswer();
  const { minimumTheoryContentLength } = useFeatureFlags();

  useEffect(() => {
    setQuestionResult(null);
    fetchUserDetails();
    setQuestionStatus(QuestionStatus.INITIAL);
    resetAdaptivePracticeSessionResults();
    setStartedSessionTimestamp(new Date().getTime());

    return () => {
      reset();
      setAdaptivePracticeDetails(null);
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const parsedTopicId = topicId ? Number.parseInt(topicId, 10) : undefined;
    fetchAdaptivePracticeQuestion(
      +productId,
      +subjectId,
      parsedTopicId,
      workflowType as AdaptiveTestWorkflowType,
    );
  }, [workflowType, subjectId]);

  useEffect(() => {
    if (productDetails?.id !== +productId) {
      fetchProductDetails(+productId);
    }
  }, [productDetails, productId]);

  useEffect(() => {
    if (subjectCompleted && failedToLoadQuestion) {
      setCongratulationsMessage("subject");
    }
  }, [failedToLoadQuestion, subjectCompleted]);

  // Reset stats when starting a test for a different subject
  useEffect(() => {
    resetCurrentMixedExerciseStats();
  }, [subjectId, topicId]);

  // Fetch topic details to determine if we can show the theory button or not
  useEffect(() => {
    if (!!productId && !!currentTopic?.id) {
      fetchTopicDetails(+productId, currentTopic?.id);
    }
  }, [productId, currentTopic?.id]);

  useEffect(() => {
    if (productSubject?.moduleId && productId) {
      fetchModuleProgress(+productId, productSubject.moduleId);
    }
  }, [productId, productSubject?.moduleId]);

  useEffect(() => {
    if (productId && (!productGoalLevel || +productId !== +productGoalLevel.productId)) {
      fetchProductGoalLevel(+productId);
    }
  }, [productId]);

  useEffect(() => {
    if (productId && subjectId) {
      fetchProductSubjectDetails(+productId, +subjectId);
    }
  }, [productId, subjectId]);

  const checkCongratulationsMessage = async () => {
    if (!currentTopic) {
      // Should never happen, we should always have a topic to start with
      return;
    }

    if (questionStatus !== QuestionStatus.CORRECT || questionResult?.isAnswerCorrect === false) {
      // We don't want to show confetti if the student got the question wrong
      return;
    }

    const nextTopic = await fetchNextPracticeTopic(+productId, +subjectId, currentTopic.id);

    if (!nextTopic) {
      setCongratulationsMessage("subject");
    } else if (nextTopic && currentTopic.id !== nextTopic.id) {
      // Store data of topic that was just completed
      setLatestCompletedTopic(currentTopic);

      // If next topic belongs to different chapter, we must display chapter finished message
      if (nextTopic.chapterId !== currentTopic.chapter.id) {
        setCongratulationsMessage("theme");
      } else if (nextTopic.isThemeAssignment) {
        // If we're in the same chapter and the next topic is a theme assignment, show assignment unlocked message
        setCongratulationsMessage("theme-assignment-unlocked");
      } else {
        setCongratulationsMessage("sub-theme");
      }
    }

    if (
      workflowType === AdaptiveTestWorkflowType.SEQUENTIAL &&
      currentTopic.currentUserLevel &&
      nextTopic.id === currentTopic.id
    ) {
      const previousSkillLevel = currentTopic.currentUserLevel?.level;
      const newSkillLevel = nextTopic.currentSkillLevel;

      if (previousSkillLevel > newSkillLevel) {
        // TODO: For eventual support of all workflows, here we'd show popup that you've leveled down
      } else if (newSkillLevel > previousSkillLevel) {
        // TODO: For eventual support of all workflows, here we'd show popup that you've leveled up
      }
    }
  };

  useEffect(() => {
    if (nextQuestionIsReady && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      checkCongratulationsMessage();
    }
  }, [nextQuestionIsReady]);

  const onEvaluateAnswer = (params: EvaluateAnswerParams) => {
    evaluateAnswer(params).then((res) => {
      if (!res) return;

      if (res.questionResult.isAnswerCorrect !== null) {
        updateSessionStreak(res.questionResult.isAnswerCorrect);
        if (currentTopic) {
          addSessionQuestionResult(
            params.questionId,
            res.questionResult.isAnswerCorrect,
            currentTopic.id,
          );
        }
      }
      const { questionResult, results, status } = res;
      setSelectedQuestion({
        ...selectedQuestion,
        results,
        status,
      });

      if (isMixedExerciseActive) {
        const { answeredQuestions, correctQuestions, failedQuestions } = currentMixedExerciseStats;

        setCurrentMixedExerciseStats({
          answeredQuestions: answeredQuestions + 1,
          correctQuestions: questionResult.isAnswerCorrect
            ? correctQuestions + 1
            : correctQuestions,
          failedQuestions: questionResult.isAnswerCorrect ? failedQuestions : failedQuestions + 1,
        });
      } else {
        const generatedIntervalId = setInterval(() => checkIfNextQuestionIsReady(), 1000);
        setIntervalId(generatedIntervalId);
      }
    });
  };

  const loadNextQuestion = () => {
    setShowContinueButton(false);
    setNextQuestionIsReady(false);
    if (isMixedExerciseActive) {
      const studentHasClickedOnSubject = !topicId;
      const mixedExerciseParams = studentHasClickedOnSubject
        ? ({ productId: +productId, subjectId: +subjectId } as MixedExerciseForSubject)
        : ({
            productId: +productId,
            subjectId: +subjectId,
            topicId: +topicId,
          } as MixedExerciseForTopic);
      fetchMixedExercise(mixedExerciseParams);
    } else {
      fetchAdaptivePracticeQuestion(
        +productId,
        +subjectId,
        undefined,
        workflowType as AdaptiveTestWorkflowType,
      );
    }
  };

  if (!Number(productId) || nonOwnedProductError) {
    return <Redirect to={PRODUCT_PAGES_ROUTES.PRODUCT_LIST} />;
  }

  const examQuestions = adaptiveTestDetails?.questions ? adaptiveTestDetails.questions : [];
  const examQuestionsIsEmpty = examQuestions.length === 0;

  // We want the student to be able to just keep practicing as uninterrupted as possible, so show most congrats
  // messages as a popup
  const showFullScreenCongratulationsMessage = congratulationsMessage === "subject";
  const showCongratulationsMessage =
    congratulationsMessage === "sub-theme" ||
    congratulationsMessage === "theme" ||
    congratulationsMessage === "theme-assignment-unlocked";
  const showLoadingSpinner = !adaptiveTestDetails || failedToLoadQuestion;

  const playerSelectedQuestion = examQuestions[0] || undefined;

  const hasTheoryToShow = (topicDetails: TopicDetails | undefined): boolean => {
    // Convert the content from html to plain text to evaluate the length of it
    const plainTextContent =
      new DOMParser().parseFromString(topicDetails?.content || "", "text/html").body.textContent ||
      "";

    return (
      (playerSelectedQuestion?.topicId !== null || playerSelectedQuestion?.topicId !== undefined) &&
      plainTextContent.length >= +(Number.parseInt(minimumTheoryContentLength as string) || 0)
    );
  };

  // Show subject completed page if the user has no more topics left to complete
  if (showFullScreenCongratulationsMessage) {
    return <SubjectCompleted productGoalLevel={productGoalLevel?.goalLevel} />;
  }

  return (
    <StyledPageWrapper>
      {topicDetails && <ViewTheoryModal topic={topicDetails} onClose={closeModal} />}
      <AdaptiveTestHeader />
      <Box
        flex={1}
        width={
          playerSelectedQuestion && textQuestions.includes(playerSelectedQuestion.type)
            ? "100vw"
            : ""
        }
      >
        {showCongratulationsMessage && (
          <CongratulationsMessage
            currentTopic={latestCompletedTopic}
            userGoalLevel={productGoalLevel?.goalLevel}
            userName={userDetails?.firstName}
          />
        )}
        {showLoadingSpinner ? (
          <CssFlex justifyContent="center">
            <LoadingSpinner />
          </CssFlex>
        ) : examQuestionsIsEmpty ? (
          <StyledEmptyExamQuestionsMessage />
        ) : (
          <>
            {workflowType === AdaptiveTestWorkflowType.SEQUENTIAL && <SkillLevelChangeMessage />}
            <AdaptiveTestContent
              enableCorrectTextQuestionModal={workflowType === AdaptiveTestWorkflowType.SEQUENTIAL}
              evaluateAnswer={(productId, questionId, answers, skipAnswerValidation) =>
                onEvaluateAnswer({
                  productId,
                  questionId,
                  answers,
                  skipAnswerValidation,
                  testType: TestType.ADAPTIVE_PRACTICE_TEST,
                  testToken: adaptiveTestDetails?.token,
                })
              }
              setShowContinueButton={(val) => setShowContinueButton(val)}
              showContinueButton={showContinueButton}
            />
          </>
        )}
      </Box>
      {playerSelectedQuestion && (
        <QuestionAnswerBox
          loadNextQuestion={loadNextQuestion}
          question={playerSelectedQuestion}
          questionResult={questionResult}
          shouldAllowShowingTheory={hasTheoryToShow(topicDetails)}
          status={questionStatus}
          testType={TestType.ADAPTIVE_PRACTICE_TEST}
        />
      )}
    </StyledPageWrapper>
  );
});
