import { HST_PROCTORING_EVENTS } from "@infinitaslearning/module-he-common";
import { EnvironmentType, serviceENV as getCurrentEnvironment } from "constants/env-constants";
import { ExamRule, TestEventTypes } from "constants/exam-constants";
import { Map as ImmutableMap } from "immutable";
import { useCallback, useEffect, useState } from "react";
import { useFullScreen } from "./useFullScreen";
import { useLogs } from "./useLogs";
import { useScheduledTests } from "./useScheduledTests";
import { useSchoolyear } from "./useSchoolyear";

type RuleResults = ImmutableMap<ExamRule, boolean>;

const allRules: ExamRule[] = Object.values(ExamRule);

// Define rules that must be excluded based on current environment
const excludedRulesPerEnvironment: Record<EnvironmentType, ExamRule[]> = {
  [EnvironmentType.LOCAL]: [ExamRule.FULL_SCREEN],
  [EnvironmentType.DEVELOPMENT]: [ExamRule.FULL_SCREEN],
  [EnvironmentType.STAGING]: [],
  [EnvironmentType.PRODUCTION]: [],
  // Ignore the FULL_SCREEN rule during tests because we cannot put Cypress in fullscreen mode
  [EnvironmentType.TEST]: [ExamRule.FULL_SCREEN],
};

export const useFailingExamRules = (rules: ExamRule[] = allRules): readonly ExamRule[] => {
  const [ruleResults, setRuleResults] = useState<RuleResults>(
    ImmutableMap(allRules.map((rule) => [rule, true])),
  );
  const { trackTestEvent } = useLogs();
  const { scheduledTestContent } = useScheduledTests();
  const { fullScreen } = useFullScreen();

  const {
    fetchIsSchoolyearActive,
    isSchoolyearActive,
    loading: isCheckingSchoolyearActive,
  } = useSchoolyear();

  // Attempt to fetch schoolyear signature to verify if user is currently using Schoolyear app
  useEffect(() => {
    fetchIsSchoolyearActive();
  }, [fetchIsSchoolyearActive]);

  const shouldCheckRule = useCallback(
    (rule: ExamRule) => {
      // If user is currently using Schoolyear, then we will skip all checks (Schoolyear app does this better)
      // Rule checks will be also ignored while we're checking if SY is currently in use
      if (isCheckingSchoolyearActive || isSchoolyearActive) {
        return false;
      }

      const currentEnv = getCurrentEnvironment() as EnvironmentType;
      const envExclusions = excludedRulesPerEnvironment[currentEnv] || [];
      return rules.includes(rule) && !envExclusions.includes(rule);
    },
    [rules, isSchoolyearActive, isCheckingSchoolyearActive],
  );

  // full screen rule
  useEffect(() => {
    if (!shouldCheckRule(ExamRule.FULL_SCREEN)) {
      if (!ruleResults.get(ExamRule.FULL_SCREEN)) {
        setRuleResults(ruleResults.set(ExamRule.FULL_SCREEN, true));
      }
      return () => undefined;
    }

    const onCheckFullscreen = () => {
      const current = ruleResults.get(ExamRule.FULL_SCREEN);
      if (current !== fullScreen?.active) {
        setRuleResults(ruleResults.set(ExamRule.FULL_SCREEN, fullScreen?.active ?? false));
        const eventName = fullScreen?.active
          ? HST_PROCTORING_EVENTS.STUDENT_GOES_FULL_SCREEN
          : HST_PROCTORING_EVENTS.STUDENT_LEAVES_FULL_SCREEN;
        trackTestEvent(eventName, TestEventTypes.PROCTORING, scheduledTestContent?.token || "");
      }
    };

    // add a checker for this rule
    const intervalTimer = setInterval(onCheckFullscreen, 1000);
    onCheckFullscreen();

    return () => {
      // cleanup all handlers this rule
      clearInterval(intervalTimer);
    };
  }, [rules.includes(ExamRule.FULL_SCREEN), ruleResults, fullScreen?.active, shouldCheckRule]);

  const handleMouseExitingWindow = () => {
    trackTestEvent(
      HST_PROCTORING_EVENTS.STUDENT_CURSOR_LEAVES_PAGE,
      TestEventTypes.PROCTORING,
      scheduledTestContent?.token || "",
    );
    setRuleResults(ruleResults.set(ExamRule.MOUSE_INSIDE, false));
  };

  const handleMouseReturningToWindow = () => {
    trackTestEvent(
      HST_PROCTORING_EVENTS.STUDENT_CURSOR_RETURNS_PAGE,
      TestEventTypes.PROCTORING,
      scheduledTestContent?.token || "",
    );
    setRuleResults(ruleResults.set(ExamRule.MOUSE_INSIDE, true));
  };

  // use mouse document leave rule
  useEffect(() => {
    if (!shouldCheckRule(ExamRule.MOUSE_INSIDE)) {
      if (!ruleResults.get(ExamRule.MOUSE_INSIDE)) {
        setRuleResults(ruleResults.set(ExamRule.MOUSE_INSIDE, true));
      }
      return () => undefined;
    }

    const onMouseLeave = () => {
      if (ruleResults.get(ExamRule.MOUSE_INSIDE)) {
        handleMouseExitingWindow();
      }
    };

    const onMouseEnter = () => {
      if (!ruleResults.get(ExamRule.MOUSE_INSIDE)) {
        handleMouseReturningToWindow();
      }
    };

    document.addEventListener("mouseleave", onMouseLeave);
    document.addEventListener("mouseenter", onMouseEnter);

    return () => {
      document.removeEventListener("mouseleave", onMouseLeave);
      document.removeEventListener("mouseenter", onMouseEnter);
    };
  }, [rules.includes(ExamRule.MOUSE_INSIDE), ruleResults, shouldCheckRule]);

  // For a more robust anti cheating solution, and because Firefox does not support mouseleave, we
  // also check if the document has focus. If the document does not have focus, we assume the mouse is outside.
  // https://stackoverflow.com/questions/66245558/mouseenter-and-mouseleave-event-listeners-on-document-not-working-in-firefox

  // We do a useEffect with an interval instead of a custom hook
  // like isFocused = useWindowFocus() because we need the previous value of the ruleResults
  // Things which do NOT fix the issue in Firefox:
  // document.body.addEventListener('mouseout', onMouseOut); (instead of mouseleave)
  // window.addEventListener('mouseout', onMouseOut);
  // document.addEventListener('visibilitychange', event => {
  useEffect(() => {
    const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
    const intervalId = setInterval(() => {
      if (!isFirefox) return;
      // In Chrome, document does not have focus immediately when switching to fullscreen
      // So this will incorrectly trigger the rule
      const hasFocus = document.hasFocus();
      if (!hasFocus) {
        if (ruleResults.get(ExamRule.MOUSE_INSIDE)) {
          // Document is now not focused and mouse was inside, assume cheating
          handleMouseExitingWindow();
        }
      } else if (hasFocus && !ruleResults.get(ExamRule.MOUSE_INSIDE)) {
        // Document is now focused and mouse was outside, setting back to inside
        handleMouseReturningToWindow();
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [ruleResults, setRuleResults]);

  // return only the rules that FAIL
  return ruleResults
    .toArray()
    .filter(([, value]) => !value)
    .map(([key]) => key);
};
