import type { TopicProgressType } from "models/progress/Progress";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

export interface ProgressionBoxProps extends ThemeProps {
  topicProgressType: TopicProgressType;
}

export const StyledTable = styled.table<ThemeProps>`
  width: 100%;
  margin-top: 2rem;
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._900};
  border-radius: 4px;
  border-spacing: 0;

  th,
  td {
    padding: 0.75rem 1rem;
  }

  thead {
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._950};
    th {
      font-weight: bold;
    }
  }

  tbody {
    tr:hover {
      background: ${(props: ThemeProps) => props.theme.palette.neutral._999};
    }
  }
`;
