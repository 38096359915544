import axios, { type AxiosError } from "axios";
import cookieStorage from "persistence";
import { handlingResponse, logError } from "repositories/utils";

import { ERROR_TYPES } from "constants/error-constants";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type { FeatureFlagsSet } from "models/featureFlag/FeatureFlagsSet";

export type ConfigCatClientInterface = {
  fetchAllFeatureFlags: () => Promise<FeatureFlagsSet>;
};

export const createConfigCatRepository = (): ConfigCatClientInterface => {
  const fetchAllFeatureFlags = async (): Promise<FeatureFlagsSet> => {
    try {
      const result = await axios.get("/api/configcat/feature-flags", {
        headers: { Authorization: cookieStorage.getToken() },
      });
      return handlingResponse<FeatureFlagsSet>([200], "Error retrieving features flags")(result);
    } catch (error) {
      const { message, status } = logError(error as AxiosError<ApiRequestError>);
      throw new RequestError({ message, status, type: ERROR_TYPES.GET_ALL_FEATURE_FLAGS });
    }
  };

  return {
    fetchAllFeatureFlags,
  };
};
