import type React from "react";
import { type StatusTagVariant, StyledStatusTag } from "./StatusTag.styled";

export interface StatusTagProps {
  className?: string;
  label: string;
  dataCy?: string;
  variant?: StatusTagVariant;
}

export const StatusTag: React.FC<StatusTagProps> = ({
  className,
  label,
  dataCy,
  variant = "default",
}) => {
  return (
    <StyledStatusTag $variant={variant} className={className} data-cy={dataCy}>
      {label}
    </StyledStatusTag>
  );
};
