import styled from "styled-components/macro";

import { Button } from "components/Button";
import type { ThemeProps } from "models/styles/theme";

export interface AnswerButtonProps extends ThemeProps {
  isCheatButton?: boolean;
}

export const StyledAnswerButton = styled(Button)<AnswerButtonProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  ${({ isCheatButton, theme }: AnswerButtonProps) =>
    isCheatButton && `background: ${theme.palette.feedback.error.brand}`};
`;
