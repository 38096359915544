import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

export const mapProgressToColor = (
  progress: number,
  hogesTheme: ElearningThemeInterface,
): string => {
  if (progress < 25) {
    return hogesTheme.palette.feedback.error.brand;
  }

  if (progress < 50) {
    return hogesTheme.palette.yellow._700;
  }

  if (progress < 75) {
    return hogesTheme.palette.feedback.success._800;
  }

  return hogesTheme.palette.feedback.success._800;
};
