import { ToastMethods } from "components/ToastNotification";
import i18n from "i18n";
import { action, observable } from "mobx";
import type { CreateBasicAccountRequest } from "models/api/users/request";
import type { CreateBasicAccountResponse } from "models/api/users/response";
import type { Registration } from "models/register/Register";
import { registerRepository } from "repositories";

interface RegistrationStore {
  loading: boolean;
  registeredEmail?: string;

  createRegistration: (invitationId: string, payload: Registration) => void;
  createBasicAccount: (payload: CreateBasicAccountRequest) => Promise<boolean>;

  setLoading: (val: boolean) => void;
  setRegisteredEmail: (registeredEmail: string) => void;
}

const initialState = {
  loading: false,
};

const stateSetters = {
  setRegisteredEmail: action((registeredEmail: string) => {
    store.registeredEmail = registeredEmail;
  }),
  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),
};
const apiRequests = {
  createRegistration: action((invitationId: string, payload: Registration) => {
    store.setLoading(true);
    store.setRegisteredEmail("");
    registerRepository
      .createRegistration(invitationId, payload)
      .then((registeredEmail) => {
        store.setRegisteredEmail(registeredEmail);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:admin.createRegistration.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  // Create a new basic account
  createBasicAccount: action((payload: CreateBasicAccountRequest): Promise<boolean> => {
    store.setLoading(true);
    return registerRepository
      .createBasicAccount(payload)
      .then((response: CreateBasicAccountResponse) => {
        if (response.linkedToExistingIdpAccount) {
          ToastMethods.showToast(
            i18n.t("auth-pages:createBasicAccountPage.feedback.idpAccountAlreadyExisted"),
            "warning",
            { autoClose: false },
          );
        }
        return !response.linkedToExistingIdpAccount;
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:registerBasicAccount.error"), "error");
        return false;
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
};

const store: RegistrationStore = observable({
  ...stateSetters,
  ...apiRequests,
  ...initialState,
} as RegistrationStore);

export const useRegistration = (): RegistrationStore => store;
