import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type { StyledBreadcrumbsProps } from "./Breadcrumbs.model";

interface Props extends StyledBreadcrumbsProps {
  theme: ElearningThemeInterface;
}

export const StyledBreadcrumbs = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, auto);
  grid-gap: 0.5rem;
  justify-self: baseline;
  width: max-content;
`;

export const StyledBreadCrumb = styled.div<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral._400};

  a {
    color: ${({ theme }: Props) => theme.palette.neutral._400};
  }
`;
