import { useEffect } from "react";

interface UseOnClickOutsideProps {
  disabled?: boolean;
  ref: React.RefObject<HTMLElement>;
  handler: (event: Event) => void;
}

export const useOnClickOutside = ({
  disabled = false,
  ref,
  handler,
}: UseOnClickOutsideProps): void => {
  useEffect(() => {
    if (disabled || !ref) {
      return undefined;
    }
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler(event);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [disabled, ref, handler]);
};
