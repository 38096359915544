import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledCorrectTextQuestion = styled.div<Props>`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;

  .CorrectTextQuestion-introduction {
    font-size: ${(props: ThemeProps) => props.theme.font.size._24};
    font-weight: ${(props: Props) => props.theme.font.weight.bold};
  }
`;

export const StyledCorrectTextQuestionContent = styled.div<Props>`
  margin-bottom: 4rem;
`;

export const StyledCorrectTextQuestionButtons = styled.div<Props>`
  display: flex;
  justify-content: center;
`;

export const StyledCorrectTextQuestionParagraph = styled.span`
  font-size: ${(props: ThemeProps) => props.theme.font.size._24};
  line-height: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`;
