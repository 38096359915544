import { Button } from "components/Button";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

export const StyledAccessibilityMenuButton = styled(Button)`
  margin: 0 1rem;

  * {
    color: ${(props: ThemeProps) => props.theme.palette.primary.brand};
  }

  svg {
    margin-right: 0.5rem;
  }
`;
