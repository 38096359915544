import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading2, Heading3, Heading4 } from "styles/elements/Headings";
import { calculateRem, calculateRems } from "styles/mixins/calculateRem";

export const StyledUserScheduledTestsGroup = styled.div<ThemeProps>`
  margin-right: 32px;
  width: 100%;
`;

export const StyledWrapper = styled.div<ThemeProps>`
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._900};
  padding: ${calculateRems([32, 60])};
  margin-top: ${calculateRem(44)};

  ${Heading2} {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  }

  ${Heading3} {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
    color: ${(props: ThemeProps) => props.theme.palette.neutral._500};
    margin: ${calculateRem(32)} 0;
  }

  ${Heading4} {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
    color: ${(props: ThemeProps) => props.theme.palette.neutral._600};
  }
`;
