import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import TextareaAutosize from "react-textarea-autosize";

export interface StyledInputProps extends ThemeProps {
  highlight: string;
}

export const StyledHighlightDescription = styled.span<ThemeProps>`
  font-weight: 400;
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  font-size: ${(props: ThemeProps) => props.theme.font.size._12};
  margin-left: 0.6rem;
`;

export const StyledAnswerWrapper = styled.div<ThemeProps>`
  margin-bottom: 2rem;
`;

export const StyledTextArea = styled(TextareaAutosize)<StyledInputProps>`
  width: 95%;
  resize: none;
  overflow: hidden;
  height: 1rem;
  outline: none;
  padding: 1rem;
  border: 1px solid
    ${({ highlight, theme }: StyledInputProps) =>
      highlight === "true" ? theme.palette.primary.brand : theme.palette.neutral._900};
`;

export const StyledButtonWrapper = styled.div<ThemeProps>`
  text-align: right;
  margin-top: 1rem;
`;
