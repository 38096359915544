import styled from "styled-components/macro";

import { Button } from "components/Button";
import { CssFlex } from "styles/helpers/layout";

export const StyledContainer = styled(CssFlex)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 5rem;
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  margin: 3rem;
  top: 0;
  right: 0;
`;
