import type React from "react";
import { useTranslation } from "react-i18next";

import type { OptionProps } from "components/Select/Select.model";
import { SvgIconReact } from "components/SvgIconReact";

import { StyledButton, StyledEducationItem, StyledSelect } from "./EducationItem.styled";

interface EducationItemProps {
  disabled: boolean;
  educationOptions: OptionProps[];
  education: string;
  onDeleteEducation: () => void;
  onUpdateEducation: (educationId: string) => void;
  hasError?: boolean;
  errorMessage?: string;
  id?: string;
  ariaLabel?: string;
}

export const EducationItem: React.FC<EducationItemProps> = ({
  id,
  ariaLabel,
  disabled,
  education,
  hasError,
  errorMessage,
  educationOptions,
  onDeleteEducation,
  onUpdateEducation,
}) => {
  const { t } = useTranslation("common");

  return (
    <StyledEducationItem>
      <StyledSelect
        ariaLabel={ariaLabel || ""}
        dataCy="account-education"
        disabled={disabled}
        errorMessage={errorMessage}
        hasError={!!hasError}
        id={id}
        options={educationOptions}
        value={education}
        onChange={(event) => onUpdateEducation(event.target.value)}
      />
      <StyledButton
        aria-label={t("iconButtonLabel.delete")}
        variant="default"
        onClick={(event) => {
          event.preventDefault();
          onDeleteEducation();
        }}
      >
        <SvgIconReact iconName="trashIcon" />
      </StyledButton>
    </StyledEducationItem>
  );
};
