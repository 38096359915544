import { UsetifulContext } from "contexts/UsetifulProvider/UsetifulProvider";
import { useContext } from "react";

export interface UsetifulHookInterface {
  isUsetifulTourAPIAvailable: () => boolean;
  openTour: (tourId: number) => void;
}

export const useUsetiful = (): UsetifulHookInterface => {
  const usetifulAPI = useContext(UsetifulContext);

  const isUsetifulTourAPIAvailable = () => {
    return !!usetifulAPI?.tour;
  };

  const openTour = (tourId: number) => {
    usetifulAPI?.tour?.start(tourId);
  };

  return {
    openTour,
    isUsetifulTourAPIAvailable,
  };
};
