import { observer } from "mobx-react";
import type React from "react";

import assignmentImg from "assets/images/all-other-assignment-img.svg";
import casusAssignmentImg from "assets/images/casus-assignment-img.svg";
import { StatusTag } from "components/StatusTag/StatusTag";
import { SvgIconReact } from "components/SvgIconReact";
import { QuestionType } from "constants/exam-constants";
import { ExerciseStatus } from "models/writing/WritingDetail";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { Box, CssFlex, CssIcon } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import { getExerciseStatusLabel, getExerciseStatusTagVariant } from "./CommonExerciseUtils";

interface ExerciseElementProps {
  id: number;
  title: string;
  exStatus: ExerciseStatus | undefined;
  handleClick: (exerciseId: number) => void;
  questionCount: number;
  questionType: QuestionType | null;
}

export const ExerciseElement: React.FC<ExerciseElementProps> = observer(
  ({
    id: key,
    handleClick,
    title,
    exStatus = ExerciseStatus.NOT_STARTED,
    questionCount,
    questionType,
  }) => {
    const { t } = useTranslation("writing-module");
    const hogesTheme = useTheme() as ElearningThemeInterface;

    const isCasusType =
      questionType &&
      [QuestionType.PROGRESSIVE_DOCUMENT, QuestionType.PROGRESSIVE_OVERVIEW].includes(questionType);

    const statusLabel = getExerciseStatusLabel(t, exStatus);

    return (
      <CssFlex
        alignItems="center"
        border="1px solid #ccc"
        borderRadius="5px"
        data-cy="exercise-list-item"
        hover={{ boxShadow: "0px 11px 27px 0px rgba(84, 84, 84, 0.20)", cursor: "pointer" }}
        justifyContent="space-between"
        pr={hogesTheme.padding(2)}
        transition="box-shadow .2s ease-in-out"
        onClick={() => handleClick(key)}
      >
        <CssFlex alignItems="center" gap={5}>
          <Box
            backgroundImage={`url(${isCasusType ? casusAssignmentImg : assignmentImg})`}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            data-cy="exercise-list-item-image"
            gridArea="image"
            height="92px"
            width="69px"
          />
          <CssFlex flexDirection="column" gap={0.5}>
            <Heading3 data-cy="exercise-list-item-title" m={0}>
              {title}
            </Heading3>
            <CssFlex alignItems="center" flexDirection="row" gap={0.5}>
              <CssIcon fixedWidth iconName="fas fa-tasks" />
              <ParagraphLarge data-cy="exercise-list-item-question-count">
                {t("topicDetails.summaryBox.questionCount", { count: questionCount || 0 })}
              </ParagraphLarge>
            </CssFlex>
          </CssFlex>
        </CssFlex>
        <CssFlex alignItems="center" gap={2.5}>
          {statusLabel && (
            <StatusTag
              dataCy="exercise-list-item-status"
              label={statusLabel}
              variant={getExerciseStatusTagVariant(exStatus)}
            />
          )}
          <SvgIconReact iconName="rightArrow" />
        </CssFlex>
      </CssFlex>
    );
  },
);
