import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";

import { SanitizedHtml } from "components/SanitizedHtml";
import { SvgIconReact } from "components/SvgIconReact";
import type { AdaptiveTestPageParams } from "pages/StudentEnvironment/AdaptiveTestPage/AdaptiveTestPage.model";

import { AccessibilityMenuButton } from "components/AccessibilityMenuButton/AccessibilityMenuButton";
import { AccessibilityMenuModal } from "components/AccessibilityMenuModal/AccessibilityMenuModal";
import { QuestionType } from "constants/exam-constants";
import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import { useQueryParam } from "hooks/useQueryParam";
import { buildComparedSessionTopicProgress } from "pages/StudentEnvironment/StudentDashboard/subpages/AdaptivePracticeSessionResultsPage/utils";
import { CssFlex } from "styles/helpers/layout";
import { PracticeStatusBar } from "../PracticeStatusBar";
import {
  StyledAdaptiveTestHeader,
  StyledCloseButton,
  StyledCurrentLevel,
  StyledProgressBar,
  StyledSubtitle,
  StyledTitle,
} from "./AdaptiveTestHeader.styled";

export const AdaptiveTestHeader: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation("adaptive-test");
  const { t: tCommon } = useTranslation("common");
  const topicId = useQueryParam("topicId");
  const { moduleSubjectsProgress } = useProductProgress();
  const { productDetails } = useProduct();
  const { adaptivePracticeSessionResults } = useAdaptivePractice();
  const { showAdaptivePracticeSessionResultsPage } = useFeatureFlags();

  const { productId, moduleId }: AdaptiveTestPageParams = useParams();
  const {
    previousTopic,
    currentTopic,
    isMixedExerciseActive,
    adaptiveTestDetails,
    congratulationsMessage,
  } = useAdaptivePractice();

  const getCurrentUserLevelLabelOnTopic = () => {
    if (previousTopic?.currentUserLevel?.label) return previousTopic?.currentUserLevel?.label;
    if (currentTopic?.currentUserLevel?.label) return currentTopic?.currentUserLevel?.label;

    if (!topicId) return "";

    const allTopics = moduleSubjectsProgress
      .flatMap((x) => x.normalTopics)
      .concat(moduleSubjectsProgress.flatMap((x) => x.themeAssignments));
    const topicProgress = allTopics.find((x) => x.id === +topicId);
    const skillLevel = topicProgress?.skillLevel ?? -1;

    return productDetails?.levels.find((x) => x.level === skillLevel)?.label ?? "";
  };

  const isAssignmentTopic = adaptiveTestDetails?.questions
    ? adaptiveTestDetails?.questions[0]?.type === QuestionType.CORRECT_TEXT
    : false;

  const handleExitTestButtonClick = () => {
    const practicedTopics = buildComparedSessionTopicProgress(
      adaptivePracticeSessionResults,
      moduleSubjectsProgress,
      productDetails,
    ).filter((t) =>
      adaptivePracticeSessionResults.answeredQuestions.some((q) => q.topicId === t.id),
    );

    const redirectToModuleOverview =
      isMixedExerciseActive ||
      practicedTopics.length === 0 ||
      !showAdaptivePracticeSessionResultsPage;

    if (redirectToModuleOverview) {
      return history.push(
        buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
          productId,
          moduleId,
        }),
      );
    }
    return history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_SESSION_RESULTS, {
        productId,
        moduleId,
      }),
    );
  };

  return (
    <StyledAdaptiveTestHeader data-cy="adaptive-test-header">
      <AccessibilityMenuModal />
      {/* --- Current topic --- */}
      <CssFlex flexDirection="column" rowGap={0.5}>
        <StyledTitle dataCy="current-subject-title" dirtyHtml={currentTopic?.subject.title || ""} />
        <StyledSubtitle separator=" | ">
          <SanitizedHtml
            dataCy="current-chapter-title"
            dirtyHtml={currentTopic?.chapter.title || ""}
            tagName="b"
          />
          <SanitizedHtml
            dataCy="current-topic-title"
            dirtyHtml={currentTopic?.title || ""}
            tagName="span"
          />
        </StyledSubtitle>
      </CssFlex>
      {/* --- Current progress --- */}
      <CssFlex alignItems="center" flexDirection="column" justifyContent="center" rowGap={0.15}>
        {!isMixedExerciseActive && !isAssignmentTopic && (
          <StyledCurrentLevel>
            <span>{`${t("header.currentLevel", "Current level")}: `}</span>
            <strong data-cy="current-user-level">{getCurrentUserLevelLabelOnTopic() ?? ""}</strong>
          </StyledCurrentLevel>
        )}
        {/* --- Progress bar (won't show in theme assignments) --- */}
        {!isAssignmentTopic &&
          (!isMixedExerciseActive ? (
            <StyledProgressBar
              label={`${congratulationsMessage !== "none" ? 100 : Math.floor(currentTopic?.currentProgress || 0)}%`}
              level={congratulationsMessage !== "none" ? 100 : currentTopic?.currentProgress}
              numberOfDashes={5}
            />
          ) : (
            <PracticeStatusBar />
          ))}
      </CssFlex>
      {/* --- Action buttons --- */}
      <CssFlex alignItems="center" justifyContent="flex-end">
        <AccessibilityMenuButton />
        <StyledCloseButton
          aria-label={tCommon("iconButtonLabel.close")}
          dataCy="exit-test-button"
          variant="text"
          onClick={handleExitTestButtonClick}
        >
          <SvgIconReact iconName="closeX" />
        </StyledCloseButton>
      </CssFlex>
    </StyledAdaptiveTestHeader>
  );
});
