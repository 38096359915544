import type { ContentAPI } from "generated/types";
import type {
  ProctoringSettings,
  ScheduledTestBasePayload,
  ScheduledTestCreatePayload,
  ScheduledTestDetails,
} from "models/exam/ScheduledTest";
import type { ProductTestGroupType } from "models/product/ProductTestGroups";
import type { ScheduledTestRequestFormValues } from "./form/ScheduledTestRequestForm.model";
import type { FormValues } from "./steps/FormStep.model";
import { ProctoringOption } from "./steps/TestOptionsStep/proctoringOptions";

export const parsePayload = (values: ScheduledTestRequestFormValues): ScheduledTestBasePayload => ({
  testId: values.testId as number,
  title: values.title,
  extraTime: values.extraTime || 0,
  reviewStartDate: values.reviewStartDate,
  reviewEndDate: values.reviewEndDate,
  passingScore: values.passingScore,
  password: values.password || null,
  reviewPassword: values.reviewPassword || null,
  cumulative: values.cumulative,
  editableAnswers: values.editableAnswers,
  secureMode: values.proctoringEnabled,
  supportMessage: values.message,
  sebKeyIds: values.sebKeyIds,
  proctoringSettings: {
    programFocused: values.proctoring.includes(ProctoringOption.FOCUS),
    online: values.proctoring.includes(ProctoringOption.ONLINE),
    fullScreen: values.proctoring.includes(ProctoringOption.FULLSCREEN),
    otherSoftwareClosed: values.proctoring.includes(ProctoringOption.OTHER_APPS_CLOSED),
    onlyOneScreen: values.proctoring.includes(ProctoringOption.SINGLE_SCREEN),
    webcam: values.proctoring.includes(ProctoringOption.WEBCAM_ENABLED),
    faceDetection: values.proctoring.includes(ProctoringOption.FACE_DETECTION),
    screenshots: values.proctoring.includes(ProctoringOption.SCREENSHOTS),
  },
  schoolyearEnabled: values.schoolyearEnabled,
});

export const parseCreatePayload = (
  values: ScheduledTestRequestFormValues,
): ScheduledTestCreatePayload => ({
  ...parsePayload(values),
  schedule: values.schedule,
});

export const parseUpdatePayload = (
  values: ScheduledTestRequestFormValues,
): ContentAPI.UpdateScheduledTest => ({
  ...parsePayload(values),
  reviewStartDate: values.reviewStartDate?.toISOString(),
  reviewEndDate: values.reviewEndDate?.toISOString(),
  startDate: values.schedule[0].startDate.toISOString(),
  duration: values.schedule[0].duration,
  estimatedStudents: values.schedule[0].estimatedStudents,
});

const proctoringSettingsToArray = (settings: ProctoringSettings): ProctoringOption[] => {
  const arr: ProctoringOption[] = [];
  if (settings.programFocused) {
    arr.push(ProctoringOption.FOCUS);
  }
  if (settings.online) {
    arr.push(ProctoringOption.ONLINE);
  }
  if (settings.fullScreen) {
    arr.push(ProctoringOption.FULLSCREEN);
  }
  if (settings.otherSoftwareClosed) {
    arr.push(ProctoringOption.OTHER_APPS_CLOSED);
  }
  if (settings.onlyOneScreen) {
    arr.push(ProctoringOption.SINGLE_SCREEN);
  }
  if (settings.webcam) {
    arr.push(ProctoringOption.WEBCAM_ENABLED);
  }
  if (settings.faceDetection) {
    arr.push(ProctoringOption.FACE_DETECTION);
  }
  if (settings.screenshots) {
    arr.push(ProctoringOption.SCREENSHOTS);
  }
  return arr;
};

export const scheduledTestToFormValues = (
  scheduledTest: ScheduledTestDetails,
): ScheduledTestRequestFormValues => ({
  productId: scheduledTest.productId,
  testType: scheduledTest.testType as ProductTestGroupType,
  testId: scheduledTest.testId,
  title: scheduledTest.title,
  estimatedStudents: scheduledTest.estimatedStudents || 0,
  extraTime: scheduledTest.extraTime > 0 ? scheduledTest.extraTime : null,
  useCustomReviewDate: !!scheduledTest.reviewStartDate || !!scheduledTest.reviewEndDate,
  reviewStartDate: scheduledTest.reviewStartDate ? new Date(scheduledTest.reviewStartDate) : null,
  reviewEndDate: scheduledTest.reviewEndDate ? new Date(scheduledTest.reviewEndDate) : null,
  passingScore: scheduledTest.passingScore,
  password: scheduledTest.password === null ? "" : scheduledTest.password,
  reviewPassword: !scheduledTest.reviewPassword ? "" : scheduledTest.reviewPassword,
  cumulative: scheduledTest.cumulative,
  editableAnswers: scheduledTest.editableAnswers,
  proctoringEnabled: scheduledTest.secureMode,
  proctoring: proctoringSettingsToArray(scheduledTest.proctoringSettings),
  sebKeyIds: scheduledTest.sebKeyIds,
  schedule: [
    {
      startDate: new Date(scheduledTest.startDate),
      duration: scheduledTest.duration,
      estimatedStudents: scheduledTest.estimatedStudents,
    },
  ],
  message: "",
  schoolyearEnabled: scheduledTest.schoolyearEnabled || false,
});

export const getDefaultScheduledTestRequestFormValues = (): FormValues => ({
  title: "",
  proctoring: [],
  testType: "summative-test" as ProductTestGroupType,
  schedule: [],
  cumulative: false,
  proctoringEnabled: false,
  editableAnswers: true,
  message: "",
  schoolyearEnabled: false,
});
