import { format } from "date-fns";
import i18n from "i18n";
import * as Yup from "yup";

export interface EditReviewPeriodFormFields {
  testEndDate: Date;
  reviewStartDate: Date;
  reviewEndDate: Date;
  useCustomReviewPassword: boolean;
  reviewPassword?: string;
}

const formatDate = (date: Date) => format(date, "dd-MM-yyyy HH:mm");

export const buildEditReviewPeriodSchema = (): Yup.SchemaOf<EditReviewPeriodFormFields> =>
  Yup.object({
    testEndDate: Yup.date().defined(),
    // Review start date must be higher than end date of the test
    reviewStartDate: Yup.date()
      .defined()
      .label(i18n.t("test-request-form:formFields.reviewStartDate"))
      .required(({ label }: { label: string }) =>
        i18n.t("test-request-form:formErrors.required", { label }),
      )
      .min(Yup.ref("testEndDate"), ({ min, label }: { min: string | Date; label: string }) =>
        i18n.t("test-request-form:formErrors.minDate", { min: formatDate(min as Date), label }),
      ),
    // Review end date must be higher than the review start date
    reviewEndDate: Yup.date()
      .defined()
      .label(i18n.t("test-request-form:formFields.reviewEndDate"))
      .required(({ label }) => i18n.t("test-request-form:formErrors.required", { label }))
      .notOneOf([Yup.ref("reviewStartDate")], ({ label }) =>
        i18n.t("test-request-form:formErrors.dateNotSame", { label }),
      )
      .min(Yup.ref("reviewStartDate"), ({ min, label }) =>
        i18n.t("test-request-form:formErrors.minDate", { min: formatDate(min as Date), label }),
      ),
    // Password should only be required if checkbox is enabled, and have a length between 6 and 30 chars
    useCustomReviewPassword: Yup.boolean().defined(),
    reviewPassword: Yup.string()
      .label(i18n.t("test-request-form:formFields.reviewPassword"))
      .when("useCustomReviewPassword", {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty: <explanation>
        then: Yup.string()
          .min(6, ({ min, label }) =>
            i18n.t("test-request-form:formErrors.minLength", { min, label }),
          )
          .max(30, ({ max, label }) =>
            i18n.t("test-request-form:formErrors.maxLength", { max, label }),
          )
          .defined(),
      }),
  });
