import { observer } from "mobx-react";
// teacher env specific navigation menu
import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NavigationMenu } from "components/NavigationMenu/NavigationMenu";
import { ADMIN_ENVIRONMENT_ROUTES } from "constants/routes";

interface ComponentProps {
  className?: string;
}

export const AdminNavigationMenu: React.FC<ComponentProps> = observer(({ className }) => {
  const { t } = useTranslation("admin-environment");

  const menuItems = useMemo(
    () => [
      {
        id: "admin-users",
        dataCy: "admin-users",
        expandedByDefault: true,
        title: t("adminSideMenu.users.title", "Users"),
        redirectPath: ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE,
      },
      {
        id: "admin-scheduled-test",
        dataCy: "admin-scheduled-test",
        expandedByDefault: true,
        title: t("adminSideMenu.scheduledTests.title", "Scheduled Tests"),
        redirectPath: `${ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS}`,
      },
      {
        id: "admin-schools-list",
        dataCy: "admin-schools-list",
        expandedByDefault: true,
        title: t("adminSideMenu.schoolList.title", "Schools"),
        redirectPath: `${ADMIN_ENVIRONMENT_ROUTES.SCHOOL_LIST}`,
      },
      {
        id: "admin-company-list",
        dataCy: "admin-company-list",
        expandedByDefault: true,
        title: t("adminSideMenu.companyList.title", "Organizations"),
        redirectPath: `${ADMIN_ENVIRONMENT_ROUTES.COMPANY_LIST}`,
      },
      {
        id: "admin-tools",
        dataCy: "admin-tools",
        expandedByDefault: true,
        title: t("adminSideMenu.tools.title", "Tools"),
        redirectPath: `${ADMIN_ENVIRONMENT_ROUTES.USER_TOOLS}`,
      },
      {
        id: "admin-teacher-instruction",
        dataCy: "admin-teacher-instruction",
        expandedByDefault: true,
        title: t("adminSideMenu.teacherInstructions.title", "Teacher instructions"),
        redirectPath: `${ADMIN_ENVIRONMENT_ROUTES.TEACHER_INSTRUCTIONS}`,
      },
    ],
    [t],
  );

  return <NavigationMenu className={className} menuItems={menuItems} />;
});
