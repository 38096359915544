import arrowBackward from "assets/icons/arrow-backward.svg";
import arrowForward from "assets/icons/arrow-forward.svg";
import type React from "react";
import { useCallback, useRef, useState } from "react";
import { CssIcon } from "styles/helpers/layout";
import { CalendarPosition, type DatePickerProps } from "./DatePicker.model";
import {
  StyledDatePicker,
  StyledLeftIcon,
  StyledReactDatePicker,
  StyledRightIcon,
} from "./DatePicker.styled";

export const DatePicker: React.FC<DatePickerProps> = ({
  dataCy,
  hasError = false,
  language,
  value = new Date(),
  disabled,
  // Warning: make sure to also pass minDate if value < today, otherwise the calendar will not behave correctly with `value`
  minDate = new Date(),
  maxDate,
  calendarPosition = CalendarPosition.BOTTOM_LEFT,
  onChange,
}) => {
  const elRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const onCalendarOpen = () => {
    setIsOpen(true);
    if (!elRef.current) {
      return;
    }
    const leftArrowEl = elRef.current.querySelector(
      ".react-calendar__navigation__arrow.react-calendar__navigation__prev-button",
    );
    const rightArrowEl = elRef.current.querySelector(
      ".react-calendar__navigation__arrow.react-calendar__navigation__next-button",
    );
    if (leftArrowEl) {
      leftArrowEl.innerHTML = `<img src="${arrowBackward}" alt="">`;
    }
    if (rightArrowEl) {
      rightArrowEl.innerHTML = `<img src="${arrowForward}" alt="">`;
    }
  };
  const onDateChange = (date: Date) => {
    onChange(date);
  };

  const onCalendarClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      // make sure the calendar is opened wherever you click on the element
      if (!isOpen) {
        const inputs = event.currentTarget.querySelectorAll("input");
        if (inputs.length > 1) {
          inputs[1].focus();
        }
      }
    },
    [isOpen],
  );

  return (
    <div data-cy={dataCy}>
      <StyledDatePicker ref={elRef} onClick={onCalendarClick}>
        <StyledLeftIcon>
          <CssIcon iconName="far fa-calendar-day" iconSize="lg" />
        </StyledLeftIcon>
        <StyledReactDatePicker
          $calendarPosition={calendarPosition}
          $hasError={hasError}
          calendarIcon={null}
          clearIcon={null}
          disabled={disabled}
          format="dd-MM-yyyy"
          isOpen={isOpen}
          locale={language}
          maxDate={maxDate || undefined}
          minDate={minDate || undefined}
          value={value}
          onCalendarClose={() => setIsOpen(false)}
          onCalendarOpen={onCalendarOpen}
          onChange={onDateChange}
        />
        <StyledRightIcon>
          <CssIcon iconName={isOpen ? "fas fa-chevron-up" : "fas fa-chevron-down"} />
        </StyledRightIcon>
      </StyledDatePicker>
    </div>
  );
};
