import { StyledButton } from "components/Button/Button.styled";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

export const DownloadButtonCardMixin = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._999};
  height: 160px;
  width: 768px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: ${({ theme }: ThemeProps) => theme.padding(2)};
  padding: ${({ theme }: ThemeProps) => theme.padding(4)};
  margin-top: ${({ theme }: ThemeProps) => theme.padding(4)};

  h3: {
    margin-bottom: 0;
  }
`;

export const StyledDownloadButton = styled(StyledButton)`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
  &,
  &:hover {
    background-color: transparent;
  }
`;
