import styled from "styled-components/macro";
import { ParagraphMedium } from "styles/elements/Texts";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledLevelDashGroup = styled.div<Props>`
  display: flex;
  align-items: center;

  .level-dashes {
    display: flex;
    align-items: center;

    div:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;

export const StyledLevelLabel = styled(ParagraphMedium)`
  width: max-content;
  margin: 0;
  margin-left: 1rem;
`;
