import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { Button } from "components/Button";
import { ExamStatusTag } from "components/ExamStatusTag";
import { ExamRule, ExamStatus } from "constants/exam-constants";
import { modalIds } from "constants/modals-constants";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";
import type { ContentAPI } from "generated/types";
import { useFailingExamRules } from "hooks/useExamRules";
import { useModal } from "hooks/useModal";
import { useScheduledTests } from "hooks/useScheduledTests";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Heading4 } from "styles/elements/Headings";
import { ExamRuleFailingModal } from "../ExamRuleFailingModal/ExamRuleFailingModal";
import { FinishTestModal } from "../FinishTestModal/FinishTestModal";
import type { TestOverviewProps } from "./TestOverview.model";
import {
  StyledButtonContainer,
  StyledTestOverview,
  StyledTestOverviewListItem,
  StyledTestOverviewParagraph,
} from "./TestOverview.styled";

export const TestOverview: React.FC<TestOverviewProps> = observer(
  ({ code, onCloseTest: onCloseTestConfirm }) => {
    const { t } = useTranslation("test-environment");
    const { toggleModal } = useModal();
    const { setVisitedExam, scheduledTestContent, scheduledTestStatus } = useScheduledTests();
    const failingRules = useFailingExamRules(Object.values(ExamRule));
    const history = useHistory();

    useEffect(() => {
      setVisitedExam(true);
    }, [setVisitedExam]);
    const onCloseTestButtonClick = () => {
      toggleModal(modalIds.finishTest);
    };

    const getExerciseStatus = (exercise: ContentAPI.ExerciseDetailsSummary) => {
      const exerciseStatus = scheduledTestStatus?.find((s) => s.id === exercise.id);
      return exerciseStatus?.status || "notStarted";
    };

    const getExerciseUrl = (exercise: ContentAPI.ExerciseDetailsSummary) => {
      if (
        getExerciseStatus(exercise) === ExamStatus.COMPLETED &&
        scheduledTestContent?.editableAnswers
      ) {
        return buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_REVIEW_PAGE, {
          code,
          productId: scheduledTestContent?.productId,
          exerciseId: exercise.id,
        });
      }
      // the normal exercise page will just show the message 'you have finished this exercise'
      return buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_PAGE, {
        code,
        productId: scheduledTestContent?.productId as number,
        exerciseId: exercise.id,
      });
    };

    const testIsSubmittable = scheduledTestContent?.exercises
      .map((exercise) => getExerciseStatus(exercise))
      .some((status) => status === ExamStatus.COMPLETED);

    return (
      <StyledTestOverview>
        <Trans i18nKey="subjects.description" t={t}>
          <StyledTestOverviewParagraph>
            Choose any subject you’d like to start with. When you’ve completed it, you can check
            your answers by clicking on it again. Of course, you can also move on to the next topic
            right away.
          </StyledTestOverviewParagraph>
        </Trans>
        {scheduledTestContent?.exercises.map((exercise) => {
          const exerciseStatus = getExerciseStatus(exercise);
          const showButton =
            exerciseStatus === ExamStatus.DEFAULT || exerciseStatus === ExamStatus.NOT_STARTED;
          const url = getExerciseUrl(exercise);

          const extraData = showButton ? (
            <Button variant="primary" onClick={() => history.push(url)}>
              {t("testOverview.startExercise.button", "Start exercise")}
            </Button>
          ) : (
            <ExamStatusTag
              dataCy={`exercise-list-item-${exercise.id}-status`}
              status={exerciseStatus}
            />
          );
          return (
            <StyledTestOverviewListItem
              key={exercise.id}
              dataCy={`exercise-list-item-${exercise.id}`}
              extraData={extraData}
              hideArrowIcon={showButton}
              href={url}
              title={exercise.title}
            />
          );
        })}
        <StyledButtonContainer>
          <Heading4 mb={2}>
            {t(
              "testOverview.closeTest.info",
              'Once you have completed all the topics, click on "Complete and Submit Test".',
            )}
          </Heading4>
          <Button
            dataCy="test-overview-close-test-button"
            disabled={!testIsSubmittable}
            variant="primary"
            onClick={onCloseTestButtonClick}
          >
            {t("testOverview.closeTest.button", "Save and close the test")}
          </Button>
        </StyledButtonContainer>
        <FinishTestModal onConfirm={onCloseTestConfirm} />
        <ExamRuleFailingModal failingRules={failingRules} />
      </StyledTestOverview>
    );
  },
);
