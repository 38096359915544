import type React from "react";

import { CssFlex } from "styles/helpers/layout";
import type { CardStatusProps } from "./CardStatus.model";
import {
  StyledButton,
  StyledCardStatus,
  StyledDescription,
  StyledHeading3,
} from "./CardStatus.styled";

export const CardStatus: React.FC<CardStatusProps> = ({
  title,
  description,
  buttonLabel,
  onClickButton,
  circleComponent,
  options,
  dataCy,
  className,
}) => (
  <StyledCardStatus className={className} data-cy={dataCy} options={options}>
    <CssFlex className="tab-title" flexDirection="column" rowGap={0.25}>
      <StyledHeading3 data-cy={`${dataCy}-title`} description={description}>
        {title}
      </StyledHeading3>
      {description && (
        <StyledDescription dataCy={`${dataCy}-description`} dirtyHtml={description} tagName="p" />
      )}
    </CssFlex>
    <div>{circleComponent}</div>
    <StyledButton
      dataCy={`card-status-button-${dataCy}`}
      variant={options?.buttonVariant || "primary"}
      onClick={onClickButton}
    >
      {buttonLabel}
    </StyledButton>
  </StyledCardStatus>
);
