import type { OptionProps } from "components/Select/Select.model";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputAdminSearch } from "../InputAdminSearch/InputAdminSearch";
import { InputSelectAdmin } from "../InputSelectAdmin/InputSelectAdmin";
import {
  StyledInputSelectAdminWrapper,
  StyledScheduledTestsFilters,
} from "./ScheduledTestsFilters.styled";

interface InputsValueProps {
  school?: string;
  education?: string;
  teacher?: string;
  skillLevel?: string;
}

interface ScheduledTestsFiltersProps {
  onInputChange: (inputValue: string, key: string) => void;
  onSelectChange: (selectValue: string, key: string) => void;
  schools: OptionProps[];
  educations: OptionProps[];
  teachers: OptionProps[];
  skillLevels: OptionProps[];
  inputValue?: string;
}

export const ScheduledTestsFilters: React.FC<ScheduledTestsFiltersProps> = ({
  onSelectChange,
  onInputChange,
  schools = [],
  educations = [],
  teachers = [],
  skillLevels = [],
  inputValue,
}) => {
  const { t } = useTranslation("admin-environment");
  const [value, setValue] = useState<InputsValueProps>();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const target = event.target.value;
    const targetValue = target === "all" ? "" : target;
    const isInput = event.target.localName === "input";
    const filterKey = event.target.id;
    setValue({
      ...value,
      [filterKey]: targetValue,
    });
    isInput ? onInputChange(targetValue, filterKey) : onSelectChange(targetValue, filterKey);
  };

  return (
    <StyledScheduledTestsFilters>
      <InputAdminSearch
        dataCy="scheduled-tests-filter-input"
        inputValue={inputValue}
        labelText={t(
          "userFilter.input.scheduled.label",
          "Search on lastname, email or student number",
        )}
        onInputChange={handleChange}
      />

      <StyledInputSelectAdminWrapper>
        <InputSelectAdmin
          ariaLabel="school-selector"
          dataCy="school-selector"
          defaultValue="all"
          id="school"
          options={schools}
          value={value?.school}
          onSelectChange={handleChange}
        />

        <InputSelectAdmin
          ariaLabel="education-selector"
          dataCy="education-selector"
          defaultValue="all"
          id="education"
          options={educations}
          value={value?.education}
          onSelectChange={handleChange}
        />

        <InputSelectAdmin
          ariaLabel="teacher-selector"
          dataCy="teacher-selector"
          defaultValue="all"
          id="teacher"
          options={teachers}
          value={value?.teacher}
          onSelectChange={handleChange}
        />

        <InputSelectAdmin
          ariaLabel="skill-selector"
          dataCy="skill-selector"
          defaultValue="all"
          id="skillLevel"
          options={skillLevels}
          value={value?.skillLevel}
          onSelectChange={handleChange}
        />
      </StyledInputSelectAdminWrapper>
    </StyledScheduledTestsFilters>
  );
};
