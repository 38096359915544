import { Button } from "components/Button";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { IconButton } from "components/IconButton/IconButton";
import { StyledSearchBox } from "components/SearchBox/SearchBox.styled";
import { SortableTable } from "components/SortableTable/SortableTable";
import { StyledHeaderCell } from "components/SortableTable/SortableTable.styled";
import { Tag } from "components/Tag/Tag";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading2 } from "styles/elements/Headings";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledLiveMonitorPage = styled.div``;

export const StyledLiveMonitorStudents = styled.div<ThemeProps>`
  padding-bottom: 15px;
  ${Heading2} {
    font-weight: bold;
  }

  > div {
    border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._900};
    border-radius: 4px;

    > ${StyledSearchBox} {
      margin: 1.5rem;
      > input {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
`;

export const StyledTable = styled(SortableTable)<ThemeProps>`
  ${StyledHeaderCell} {
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 0.5rem;
  }
`;

export const StyledFilterList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const StyledTestVersionTag = styled(Tag)<ThemeProps>`
  border-radius: ${calculateRem(100)};
`;

export const StyledButton = styled(Button)`
  margin-left: 0.5rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: 400;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
`;

export const StyledSearchBoxWrapper = styled.div<ThemeProps>`
  padding: 0.5rem;
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    :first-child {
      width: 50%;
    }
  }

  > button {
    color: ${({ theme }: ThemeProps) => theme.palette.primary.brand};
    border: 1px solid ${({ theme }: ThemeProps) => theme.palette.primary.brand};
    background: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
  }
`;

export const StyledIconButton = styled(IconButton)`
  background: none;
`;
