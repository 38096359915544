import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssGrid } from "styles/helpers/layout";

export const StyledCardLayout = styled(CssGrid)<Props>`
  width: 100%;
  height: 100%;

  gap: 1rem;
  padding: 2.5rem;
  align-items: center;
  justify-content: start;

  grid-template-columns: 3rem 1fr;

  ${Heading3}, ${ParagraphLarge} {
    margin: 0;
  }
`;
