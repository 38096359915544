import { useFormikContext } from "formik";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { modalIds } from "constants/modals-constants";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useModal } from "hooks/useModal";

import { Modal } from "components/Modal";
import type { TestAccessFormFields } from "../TestAccessForm/TestAccessForm.schema";

import { SvgIconReact } from "components/SvgIconReact";
import { Heading3 } from "styles/elements/Headings";
import {
  StyledButton,
  StyledIcon,
  StyledInput,
  StyledParagraph,
  StyledPasswordField,
} from "./PasswordModal.styled";

type Props = {
  onClose?: () => void;
  variant: "enterTest" | "enterScheduleTestResults";
};

export const RequestPasswordModal: React.FC<Props> = ({ onClose, variant }) => {
  const { t } = useTranslation("test-environment");
  const [showPassword, setShowPassword] = useState(false);

  const { isModalOpen, closeModal } = useModal();
  const { getTeacherTermByDomain } = useDomainHandler();

  const formik = useFormikContext<TestAccessFormFields>();

  const passwordRequiredModalId = modalIds.passwordRequiredModal;
  const isPasswordModalOpen = isModalOpen(passwordRequiredModalId);

  // Set flag "requiresPassword" to "true" to ensure users fills in password
  // if the modal happens to be on screen
  useEffect(() => {
    formik.setFieldValue("requiresPassword", isPasswordModalOpen, false);
  }, [isPasswordModalOpen]);

  return (
    <Modal
      wrapInModalBox
      className="customPasswordModal"
      dataCy={passwordRequiredModalId}
      isOpen={isPasswordModalOpen}
      onClose={() => {
        closeModal();
        onClose?.();
        formik.setFieldValue("password", "");
      }}
    >
      <Heading3>
        {t(`passwordRequiredModal.${variant}.title`, "You need a password to open this test")}
      </Heading3>
      <StyledParagraph>
        {t(`passwordRequiredModal.${variant}.description`, {
          teacherTerm: getTeacherTermByDomain(),
        })}
      </StyledParagraph>

      <StyledPasswordField>
        <StyledInput
          dataCy="test-password-input"
          disabled={formik.isSubmitting}
          errorMessage={formik.errors.password}
          hasError={!!(formik.errors.password && formik.touched.password)}
          id="test-password"
          name="password"
          placeholder={t("passwordRequiredModal.fields.password.placeholder", "Password")}
          type={showPassword ? "text" : "password"}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <StyledIcon
          iconName={`fal ${!showPassword ? "fa-eye" : "fa-eye-slash"}`}
          onClick={() => setShowPassword(!showPassword)}
        />
      </StyledPasswordField>

      <StyledButton
        dataCy="submit-password-button"
        type="button"
        variant="primary"
        onClick={() => {
          formik.validateForm().then(() => {
            if (formik.isValid) {
              formik.submitForm();
            }
          });
        }}
      >
        {t(`passwordRequiredModal.${variant}.buttons.submit.label`, "Go to the test")}
        <SvgIconReact iconName="arrowLongRight" />
      </StyledButton>
    </Modal>
  );
};
