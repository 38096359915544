import { Select } from "components/SelectUI/Select";
import type { ProductSkillLevel } from "models/product/ProductDetails";
import type React from "react";
import { type FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, CssLabel } from "styles/helpers/layout";

interface GoalLevelSelectorProps {
  selectableGoalLevels?: ProductSkillLevel[];
  onChange: (targetLevelIndex: number) => void;
  targetLevel?: number;
}

export const GoalLevelSelector: FC<GoalLevelSelectorProps> = ({
  selectableGoalLevels = [],
  onChange,
  targetLevel,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | number>(0);
  const { t } = useTranslation("teacher-dashboard");

  const goalsOptions = useMemo(
    () =>
      selectableGoalLevels.map(({ label, level }) => ({
        value: level,
        label: label,
      })),
    [selectableGoalLevels],
  );

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onChange(+selectedValue);
  };

  useEffect(() => {
    if (goalsOptions.length > 0) {
      setSelectedOption(targetLevel || goalsOptions[0].value);
    }
  }, [targetLevel, goalsOptions]);

  return (
    <Box>
      <CssLabel id="goal-level-selector-label">{t("selectors.goalLevelSelectorLabel")}</CssLabel>
      <Select
        aria-labelledby="goal-level-selector-label"
        dataCy="goal-level-selector"
        options={goalsOptions}
        showPlaceholderInOptionList={false}
        value={selectedOption}
        onChange={handleOnChange}
      />
    </Box>
  );
};
