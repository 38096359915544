import AppRoute, { type AppRouteProps } from "components/AppRoute/AppRoute";
import cookieStorage from "persistence";
import type React from "react";

export interface AuthRouteProps extends AppRouteProps {
  component: React.FC;
}

export const AuthRoute: React.FC<AuthRouteProps> = (props) => (
  <AppRoute {...props} token={cookieStorage.getToken()} />
);
