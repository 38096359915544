import { useSelectedProductId } from "hooks/useSelectedProductId";
import { observer } from "mobx-react";
// teacher env specific navigation menu
import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { NavigationMenu } from "components/NavigationMenu/NavigationMenu";
import { ProductType } from "constants/hst-constants";
import {
  TEACHER_HOME_PAGE_ROUTES,
  TEACHER_INSIGHT_PAGE_ROUTES,
  TEACHER_SCHEDULED_TEST_PAGE_ROUTES,
  TEACHER_SUPPORT_PAGE_ROUTES,
} from "constants/routes";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useModuleAvailability } from "hooks/useModuleAvailability";
import { useProduct } from "hooks/useProduct";
import { useLanguage } from "hooks/useSelectedLanguage";
import type { User } from "models/user/User";

interface ComponentProps {
  className?: string;
  userDetails?: User | null;
}

export const TeacherNavigationMenu: React.FC<ComponentProps> = observer(
  ({ className, userDetails }) => {
    const {
      showTeacherNavigationDashboardOverview,
      showTeacherSupportNavigation,
      showTeacherPracticeInsightsPage,
      showTeacherDiagnosticTestsPage,
      showTeacherInstructions,
    } = useFeatureFlags();
    const { t } = useTranslation("homepage");
    const { currentLanguage } = useLanguage();
    const { getStudentTermByDomain } = useDomainHandler();
    const { productsList, productDetails } = useProduct();
    const isTeacherAndCanInviteStudents = useMemo(
      () => userDetails?.allowedInviteProducts as boolean,
      [userDetails?.allowedInviteProducts],
    );
    const isTeacherABusinessUser = productsList?.every(
      (product) => product.type === ProductType.BUSINESS,
    );
    const productId = useSelectedProductId();

    const { isAdaptiveModuleAvailable, isWritingModuleAvailable } = useModuleAvailability();

    const buildURLWithProductId = (path: string) => {
      return buildUrlWithPathParams(path, { productId: productId || "" });
    };

    const menuItems = useMemo(
      () => [
        ...(showTeacherNavigationDashboardOverview
          ? [
              {
                id: "teacher-dashboard",
                dataCy: "teacher-dashboard",
                expandedByDefault: true,
                title: t("teacherSideMenu.overview.title", "Overview"),
                redirectPath: buildURLWithProductId(TEACHER_HOME_PAGE_ROUTES.DASHBOARD),
              },
            ]
          : []),
        ...(isTeacherAndCanInviteStudents
          ? [
              {
                id: "teacher-student-license",
                dataCy: "teacher-student-license",
                expandedByDefault: false,
                title: t("teacherSideMenu.studentLicense.title", {
                  studentTerm: getStudentTermByDomain(),
                }),
                redirectPath: TEACHER_HOME_PAGE_ROUTES.STUDENT_LICENSE,
              },
            ]
          : []),
        {
          id: "teacher-groups",
          dataCy: "teacher-groups",
          expandedByDefault: false,
          title: t("teacherSideMenu.groups.title", "Groups"),
          redirectPath: TEACHER_HOME_PAGE_ROUTES.GROUPS,
        },
        ...(showTeacherInstructions && productDetails?.hasTeacherInstructions
          ? [
              {
                id: "teacher-instructions",
                dataCy: "teacher-instructions",
                title: t("teacherSideMenu.teacherInstructions.title", "Teacher instructions"),
                redirectPath: buildURLWithProductId(TEACHER_HOME_PAGE_ROUTES.TEACHER_INSTRUCTIONS),
              },
            ]
          : []),
        {
          id: "teacher-insights",
          dataCy: "teacher-insights",
          expandedByDefault: true,
          title: t("teacherSideMenu.insights.title", {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          }),
          subItems: [
            ...(isAdaptiveModuleAvailable
              ? [
                  {
                    id: "teacher-progress-insights",
                    dataCy: "teacher-progress-insights",
                    title: t("teacherSideMenu.insights.progress.title", "Basic skills"),
                    redirectPath: buildURLWithProductId(
                      TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW,
                    ),
                  },
                ]
              : []),
            ...(isWritingModuleAvailable
              ? [
                  {
                    id: "teacher-writing-insights",
                    dataCy: "teacher-writing-insights",
                    title: t("teacherSideMenu.insights.writing.title", "Writing skills"),
                    redirectPath: buildURLWithProductId(TEACHER_INSIGHT_PAGE_ROUTES.WRITING_SKILLS),
                  },
                ]
              : []),
            ...(showTeacherPracticeInsightsPage
              ? [
                  {
                    id: "teacher-activity-insights",
                    dataCy: "teacher-activity-insights",
                    title: t("teacherSideMenu.insights.activity.title", "Practice insights"),
                    redirectPath: buildURLWithProductId(TEACHER_INSIGHT_PAGE_ROUTES.ACTIVITY),
                  },
                ]
              : []),
            ...(showTeacherDiagnosticTestsPage && !isTeacherABusinessUser
              ? [
                  {
                    id: "teacher-diagnostic-test-insights",
                    dataCy: "teacher-diagnostic-test-insights",
                    title: t("teacherSideMenu.insights.diagnosticTest.title", "Practice tests"),
                    redirectPath: buildURLWithProductId(
                      TEACHER_INSIGHT_PAGE_ROUTES.DIAGNOSTIC_TEST,
                    ),
                  },
                ]
              : []),
          ],
        },
        ...(!isTeacherABusinessUser
          ? [
              {
                id: "teacher-testing",
                dataCy: "teacher-testing",
                expandedByDefault: true,
                title: t("teacherSideMenu.testing.title", "Testing"),
                subItems: [
                  {
                    id: "teacher-create-test",
                    dataCy: "teacher-create-test",
                    title: t("teacherSideMenu.testing.createTest.title", "Create a test"),
                    redirectPath: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_OVERVIEW,
                  },
                  {
                    id: "teacher-live-test-monitoring",
                    dataCy: "teacher-live-test-monitoring",
                    title: t("teacherSideMenu.testing.monitoring.title", "Monitor live test"),
                    redirectPath:
                      TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR_OVERVIEW,
                  },
                  {
                    id: "teacher-test-report",
                    dataCy: "teacher-test-report",
                    title: t("teacherSideMenu.testing.report.title", "Test results"),
                    redirectPath:
                      TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_OVERVIEW,
                  },
                ],
              },
            ]
          : []),
        ...(showTeacherSupportNavigation
          ? [
              {
                id: "teacher-support",
                dataCy: "teacher-support",
                expandedByDefault: true,
                title: t("teacherSideMenu.support.title", "Support"),
                subItems: [
                  {
                    id: "teacher-guides",
                    dataCy: "teacher-guides",
                    title: t("teacherSideMenu.support.guides.title", "Help documents"),
                    redirectPath: TEACHER_SUPPORT_PAGE_ROUTES.GUIDES,
                  },
                  {
                    id: "teacher-contact",
                    dataCy: "teacher-contact",
                    title: t("teacherSideMenu.support.contact.title", "Contact"),
                    redirectPath: TEACHER_SUPPORT_PAGE_ROUTES.CONTACT,
                  },
                ],
              },
            ]
          : []),
      ],
      [
        currentLanguage,
        productDetails,
        isAdaptiveModuleAvailable,
        isWritingModuleAvailable,
        isTeacherABusinessUser,
        isTeacherAndCanInviteStudents,
        showTeacherSupportNavigation,
        showTeacherNavigationDashboardOverview,
      ],
    );

    return (
      <aside>
        <NavigationMenu
          className={className}
          menuItems={menuItems.filter((item) => item !== null)}
        />
      </aside>
    );
  },
);
