import styled from "styled-components/macro";

import { Modal } from "components/Modal";
import { Heading2, Heading3 } from "styles/elements/Headings";

import { calculateRem } from "styles/mixins/mixins";

import type { ThemeProps as Props } from "models/styles/theme";

export const StyledModalContainer = styled(Modal)<Props>`
  max-width: ${calculateRem(676)};
  padding: 2rem 2rem 3rem 2rem;
  background-color: ${({ theme }: Props) => theme.palette.neutral.white};
  text-align: center;
`;

export const StyledMessageTitle = styled(Heading2)<Props>`
  margin: 1rem 0 2rem 0;
  font-weight: ${({ theme }: Props) => theme.font.weight.bold};
`;

export const StyledMessageText = styled(Heading3)<Props>`
  font-weight: ${({ theme }: Props) => theme.font.weight.normal};
  line-height: ${calculateRem(32)};
  margin-bottom: ${calculateRem(40)};
`;
