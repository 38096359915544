import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/mixins";

import { Heading2 } from "styles/elements/Headings";

import { Button } from "components/Button";
import { Input } from "components/Input";

export const StyledForm = styled.form<ThemeProps>`
  width: 60rem;
  padding: ${calculateRem(40)};
  height: fit-content;
  border-radius: ${calculateRem(4)};
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
`;

export const StyledTitle = styled(Heading2)<ThemeProps>`
  margin-bottom: 2rem;
`;

export const StyledMessage = styled.p<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  line-height: 150%;
`;

export const StyledFieldRow = styled.div<ThemeProps>`
  display: flex;
  margin-top: 2rem;
`;

export const StyledInput = styled(Input)<ThemeProps>`
  border-right: 0;
  max-height: none;
`;

export const StyledButton = styled(Button)<ThemeProps>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
