import type React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";

import { TEACHER_HOME_PAGE_ROUTES, TEACHER_INSIGHT_PAGE_ROUTES } from "constants/routes";

import { Header } from "components/Header";
import { CssIcon } from "styles/helpers/layout";

import type { TeacherHeaderProps } from "./TeacherHeader.model";

import { StyledButton } from "./TeacherHeader.styled";

import { InsightProductSelector } from "../InsightProductSelector/InsightProductSelector";

interface InsightPagesPathParams {
  productId: string;
}

export const TeacherHeader: React.FC<TeacherHeaderProps> = ({
  userDetails,
  toggleSideMenu,
  updateUserDetails,
  onLogout,
  navigateToStudentEnvironment,
}) => {
  const { t } = useTranslation("homepage");

  const location = useLocation();
  const productSpecificRouteMatch = matchPath<InsightPagesPathParams>(location.pathname, {
    path: [
      TEACHER_INSIGHT_PAGE_ROUTES.BASE_PATH,
      TEACHER_HOME_PAGE_ROUTES.DASHBOARD,
      TEACHER_HOME_PAGE_ROUTES.TEACHER_INSTRUCTIONS,
    ],
  });
  const selectedProductId =
    productSpecificRouteMatch && +productSpecificRouteMatch.params.productId;

  return (
    <Header
      toggleSideMenu={toggleSideMenu}
      updateUserDetails={updateUserDetails}
      userDetails={userDetails}
      onLogout={onLogout}
    >
      {selectedProductId && <InsightProductSelector selectedProductId={selectedProductId} />}
      <StyledButton
        dataCy="teacher-header-go-to-student-env-button"
        size="medium"
        variant="dark"
        onClick={navigateToStudentEnvironment}
      >
        <CssIcon iconName="fad fa-graduation-cap" iconSize="lg" />
        {t("header.toStudentEnvButton.title", "To practice environment")}
      </StyledButton>
    </Header>
  );
};
