import styled, { css } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type { RadioButtonBulletColor, RadioButtonBulletStyle } from "./RadioButton.model";

interface RadioButtonBaseProps {
  disabled: boolean;
  theme: ElearningThemeInterface;
  $bulletColor?: RadioButtonBulletColor;
  $bulletStyle?: RadioButtonBulletStyle;
}

export const StyledCheckmark = styled.div<RadioButtonBaseProps>`
  position: relative;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: ${({ theme }: RadioButtonBaseProps) => theme.palette.neutral.white};
  border: 1px solid ${({ theme }: RadioButtonBaseProps) => theme.palette.neutral._500};
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const StyledLabel = styled.label<RadioButtonBaseProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  > span {
    line-height: 1.5rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ ${StyledCheckmark} {
    background-color: ${({ theme }: RadioButtonBaseProps) => theme.palette.neutral._500};
  }

  input:checked ~ ${StyledCheckmark} {
    background-color: ${({ theme, $bulletColor }: RadioButtonBaseProps) =>
      $bulletColor === "primary" ? theme.palette.primary.brand : theme.palette.secondary.brand};
    border: 1px solid
      ${({ theme, $bulletColor }: RadioButtonBaseProps) =>
        $bulletColor === "primary" ? theme.palette.primary.brand : theme.palette.secondary.brand};
    &:after {
      display: block;
    }
  }

  ${StyledCheckmark}:after {
    ${({ $bulletStyle }: RadioButtonBaseProps) => {
      if ($bulletStyle === "thin") {
        return `
          top: 4px;
          left: 4px;
          width: 14px;
          height: 14px;
        `;
      }

      return `
        top: 7px;
        left: 7px;
        width: 8px;
        height: 8px;
      `;
    }}
    border-radius: 50%;
    background: ${({ theme }: RadioButtonBaseProps) => theme.palette.neutral.white};
  }

  input:disabled ~ ${StyledCheckmark} {
    border-color: #d1d1d1;
    background-color: #d1d1d1;
  }
`;
