import styled from "styled-components/macro";

import { StyledIconButton } from "components/IconButton/IconButton.styled";
import type { ThemeProps } from "models/styles/theme";
import { Heading3 } from "styles/elements/Headings";

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  > ${StyledIconButton} {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._600};
  }
  > ${Heading3} {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
    font-weight: normal;
    font-size: ${(props: ThemeProps) => props.theme.font.size._24};
    margin-bottom: 0;
  }
`;

export const StyledSidebar = styled.div<ThemeProps>`
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
  height: 100vh;
  width: 33vw;
  padding: 2.5rem;
`;
