import { modalIds } from "constants/modals-constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useModal } from "hooks/useModal";

import { EmbeddedHtml } from "components/EmbeddedHtml";
import { ProgressDots } from "components/ProgressDots/progress-dots";
import {
  StyledButton,
  StyledButtonsContainer,
  StyledModal,
  StyledSubtitle,
} from "./ReflectiveQuestionsModal.styled";

interface ReflectiveQuestionsModalProps {
  reflectionQuestions: string[];
  onCompleteReflectionQuestions: () => void;
}

export const MyReflectiveQuestionsModal: React.FC<ReflectiveQuestionsModalProps> = ({
  reflectionQuestions,
  onCompleteReflectionQuestions,
}) => {
  const { t } = useTranslation("modal");
  const { toggleModal, closeModal, isModalOpen } = useModal();
  const [questions, setQuesions] = useState<string[]>([]);
  const [questionIndexToShow, setQuestionIndexToShow] = useState<number>(0);
  const modalId = modalIds.progressiveTextQuestionModal;

  useEffect(() => {
    setQuesions(reflectionQuestions);
  }, [reflectionQuestions]);

  const onClickYes = () => {
    if (!questions.length || questionIndexToShow === questions.length - 1) {
      onCompleteReflectionQuestions();
      toggleModal(modalId);
    }
    setQuestionIndexToShow(questionIndexToShow + 1);
  };

  const onClickNo = () => {
    setQuestionIndexToShow(0);
    toggleModal(modalId);
  };

  return (
    <StyledModal
      wrapInModalBox
      dataCy={modalId}
      isOpen={isModalOpen(modalId)}
      showCloseButton={false}
      onClose={closeModal}
    >
      <StyledSubtitle>
        <EmbeddedHtml
          rawHtml={
            questions[questionIndexToShow] || t("progressive-text-question-modal.defaultQuestion")
          }
        />
      </StyledSubtitle>

      <ProgressDots activeIndex={questionIndexToShow} count={questions.length} />

      <StyledButtonsContainer>
        <StyledButton
          dataCy="progressive-question-modal-yes-button"
          variant="primary"
          onClick={onClickYes}
        >
          {t("progressive-text-question-modal.buttonYes")}
        </StyledButton>
        <StyledButton
          dataCy="progressive-question-modal-no-button"
          variant="secondary"
          onClick={onClickNo}
        >
          {t("progressive-text-question-modal.buttonNo")}
        </StyledButton>
      </StyledButtonsContainer>
    </StyledModal>
  );
};

export const ReflectiveQuestionsModal = React.memo(MyReflectiveQuestionsModal);
