import type React from "react";
import { useTranslation } from "react-i18next";

import { Heading4 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";

import { Box } from "styles/helpers/layout";
import type { ToggleSwitchProps } from "./ToggleSwitch.model";
import { StyledSlider, StyledToggleSwitch, StyledToggleWrapper } from "./ToggleSwitch.styled";

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  isActive,
  onChange,
  className,
  dataCy,
  labelTitle,
  labelSubtitle,
  name,
  isDisabled,
  variant,
  showStatusText = false,
}) => {
  const { t } = useTranslation("common");
  const onText = t("toggle.on", "on");
  const offText = t("toggle.off", "off");

  return (
    <StyledToggleWrapper className={className} variant={isDisabled ? "disabled" : variant}>
      <Box marginBottom={showStatusText ? "0.25rem" : 0}>
        {labelTitle && <Heading4>{labelTitle}</Heading4>}
        {labelSubtitle && <ParagraphLarge>{labelSubtitle}</ParagraphLarge>}
      </Box>
      <StyledToggleSwitch data-active={isActive} data-cy={dataCy} variant={variant}>
        <input
          data-cy={`${dataCy}-input`}
          checked={isActive}
          name={name}
          type="checkbox"
          onChange={onChange}
          disabled={isDisabled}
        />
        <StyledSlider />
        {showStatusText && <ParagraphLarge>{isActive ? onText : offText}</ParagraphLarge>}
      </StyledToggleSwitch>
    </StyledToggleWrapper>
  );
};
