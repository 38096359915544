import { ToastMethods } from "components/ToastNotification";
import i18n from "i18n";
import { action, observable } from "mobx";
import type { Exercise, ExerciseStatus } from "models/exam/Exam";
import { writingRepository } from "repositories";

interface WritingTestStore {
  loading: boolean;
  writingTest: Exercise | null;
  writingTestStatus: ExerciseStatus[];
  setWritingTest: (test: Exercise) => void;
  setWritingTestStatus: (status: ExerciseStatus[]) => void;
  setLoading: (val: boolean) => void;
  fetchWritingTest: (productId: number, exerciseId: number) => void;
}

const initialState = {
  writingTest: null,
  writingTestStatus: [],
  loading: false,
};

const stateSetters = {
  setWritingTest: action((test: Exercise) => {
    store.writingTest = test;
  }),
  setLoading: action((val: boolean) => {
    store.loading = val;
  }),
  setWritingTestStatus: action((status: ExerciseStatus[]) => {
    store.writingTestStatus = status;
  }),
};

const apiRequests = {
  fetchWritingTest: action(async (productId: number, exerciseId: number) => {
    store.setLoading(true);
    try {
      const response = await writingRepository.fetchWritingExercise(productId, exerciseId);
      const statusResponse = await writingRepository.fetchExerciseStatus(
        exerciseId,
        response.token,
      );
      store.setWritingTest(response);
      store.setWritingTestStatus(statusResponse);
    } catch (_e) {
      ToastMethods.showToast(i18n.t("toast:exam.error.fetchExercise"), "error");
    }
    store.setLoading(false);
  }),
};

const store: WritingTestStore = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as WritingTestStore);

export const useWritingTest = (): WritingTestStore => store;
