import type { ThemeProps as Props } from "models/styles/theme";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const GroupsWrapper = styled.main<Props>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  button:hover svg {
    color: ${(props: Props) => props.theme.palette.feedback.error.brand};
  }
`;

export const NewGroup = styled(Link)<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 4px dashed ${(props: Props) => props.theme.palette.neutral._900};
  border-radius: 8px;
  color: ${(props: Props) => props.theme.palette.neutral._900};
  text-decoration: none;

  > span {
    display: block;
    margin-top: 1rem;
    font-size: ${(props: Props) => props.theme.font.size._14};
    color: ${(props: Props) => props.theme.palette.neutral.black};
  }

  &:hover {
    border-color: ${(props: Props) => props.theme.palette.neutral._600};
    color: ${(props: Props) => props.theme.palette.neutral._600};
  }

  &:active {
    border-color: ${(props: Props) => props.theme.palette.neutral.black};
    color: ${(props: Props) => props.theme.palette.neutral.black};
  }
`;
