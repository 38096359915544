import styled, { css } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type FontAwesomeIconProps from "./FontAwesomeIcon.model";

interface Props extends FontAwesomeIconProps {
  theme: ElearningThemeInterface;
}

export const StyledFontAwesomeIcon = styled.i<Props>`
  ${(props: Props) =>
    props.iconColor &&
    css`
      color: ${props.iconColor};
    `};

  ${(props: Props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
`;
