import { EmbeddedHtml } from "components/EmbeddedHtml";
import { InfoText } from "components/InfoText";
import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading1 } from "styles/elements/Headings";

export const StyledModuleDetailsPage = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 4rem;
  text-align: center;
  row-gap: 2.5rem;
`;

export const StyledModuleTitle = styled(Heading1)`
  font-size: ${(props: Props) => props.theme.font.size._32};
  text-align: center;
  margin-bottom: 2rem;
`;

export const StyledModuleDescription = styled(EmbeddedHtml)`
  font-size: ${(props: Props) => props.theme.font.size._16};
`;

export const StyledInfoTextNoLevelSelected = styled(InfoText)`
  margin: 0 auto;
`;
