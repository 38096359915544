import type React from "react";
import {
  Slide,
  type ToastContainerProps,
  type ToastOptions,
  type TypeOptions as ToastTypes,
  toast,
} from "react-toastify";

import { CssIcon } from "styles/helpers/layout";

export const initToast = (config: ToastContainerProps): void =>
  toast.configure({
    transition: Slide,
    ...config,
  });

const ToastIconTypes: Record<ToastTypes, string> = {
  default: "fas fa-info-circle",
  success: "fas fa-check-circle",
  warning: "fas fa-exclamation-circle",
  error: "fas fa-exclamation-circle",
  info: "fas fa-info-circle",
  dark: "fas fa-info-circle",
};

type ToastPanelProps = {
  text: string;
  type: ToastTypes;
};

const ToastPanel: React.FC<ToastPanelProps> = ({ text, type }) => (
  <>
    <CssIcon
      className="info-icon"
      fontSize="1.25rem"
      iconName={ToastIconTypes[type] || ToastIconTypes.default}
    />
    <div>{text}</div>
  </>
);

export const showToast = (
  text = "",
  type: ToastTypes = "default",
  options: ToastOptions = {},
): void => {
  const toastOptions = {
    progress: undefined,
    ...options,
  };

  const triggerToastFn = type === "default" ? toast : toast[type];
  triggerToastFn(<ToastPanel text={text} type={type} />, toastOptions);
};
