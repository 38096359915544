import styled from "styled-components/macro";

import { Heading3 } from "styles/elements/Headings";
import { calculateRem } from "styles/mixins/mixins";

import { SearchBox } from "components/SearchBox";
import type { ThemeProps as Props } from "models/styles/theme";

export const StyledPageContainer = styled.main<Props>`
  margin-bottom: 4rem;
  margin-left: ${calculateRem(32)};
`;

export const StyledContentContainer = styled.section<Props>`
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._900};
  border-radius: 4px;

  ${Heading3} {
    font-weight: normal;
    margin: 2.5rem 2rem 2rem 2rem;
  }
`;

export const StyledFilterSearchBox = styled(SearchBox)`
  min-width: auto;
`;
