import classnames from "classnames";
import type React from "react";

import { ReactComponent as ArrowTriangleDown } from "assets/icons/arrow-triangle-down.svg";
import { ReactComponent as ArrowTriangleUp } from "assets/icons/arrow-triangle-up.svg";
import type { SortArrowsProps } from "./SortArrows.model";
import { StyledArrowButton, StyledArrowContainer } from "./SortArrows.styled";

export const SortArrows: React.FC<SortArrowsProps> = ({
  disabled = false,
  activeDirection = "none",
  onClick,
  children,
}) => (
  <StyledArrowButton disabled={disabled} onClick={onClick}>
    {children}
    <StyledArrowContainer>
      <ArrowTriangleUp className={classnames(activeDirection === "asc" && "active")} />
      <ArrowTriangleDown className={classnames(activeDirection === "desc" && "active")} />
    </StyledArrowContainer>
  </StyledArrowButton>
);
