import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  type EnvironmentType,
  serviceENV as getCurrentEnvironment,
  isLocal,
  isTest,
} from "constants/env-constants";
import { HogesWebshopURL } from "constants/registration-links";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";

import { SvgIconReact } from "components/SvgIconReact";

import { StyledButtons } from "../../ProductsOwnedPage.styled";
import { StyledButton, StyledButtonText, StyledMessage } from "./NoOwnedProducts.styled";

export const NoOwnedProducts: React.FC = () => {
  const { t } = useTranslation("owned-products");

  const history = useHistory();
  const currentEnv = getCurrentEnvironment() as EnvironmentType;

  const redirectToURL = (url: string) => {
    if (!isTest() && !isLocal()) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  return (
    <section data-cy="no-owned-products">
      <StyledMessage>
        {t("noLicenseFound", "Je hebt nog geen licentie voor een leermodule.")}
      </StyledMessage>
      <StyledButtons>
        <StyledButton
          dataCy="go-to-webshop-button"
          fontColor="black"
          variant="secondary"
          onClick={() => redirectToURL(HogesWebshopURL[currentEnv])}
        >
          <SvgIconReact iconName="rightArrow" />
          <StyledButtonText>{t("buttonLabels.buy", "Ge naar webshop")}</StyledButtonText>
        </StyledButton>
        <StyledButton
          dataCy="go-to-test-environment-button"
          fontColor="black"
          variant="secondary"
          onClick={() => history.push(TEST_ENVIRONMENT_ROUTES.LANDING_PAGE)}
        >
          <SvgIconReact iconName="rightArrow" />
          <StyledButtonText>{t("buttonLabels.test", "Ik will allen toetsen")}</StyledButtonText>
        </StyledButton>
      </StyledButtons>
    </section>
  );
};
