import { Button } from "components/Button";
import { TestCompletedMessage } from "layouts/TestCompletedMessage";
import type React from "react";
import { Trans, useTranslation } from "react-i18next";
import type { ExerciseFinishedMessageProps } from "./ExerciseFinishedMessage.model";

export const ExerciseFinishedMessage: React.FC<ExerciseFinishedMessageProps> = ({
  showNextExerciseButton,
  showReviewButton,
  onBackToOverviewButtonClick,
  onNextExerciseButtonClick,
  onReviewButtonClick,
}) => {
  const { t } = useTranslation("test-environment");
  return (
    <TestCompletedMessage
      buttons={
        <>
          {showReviewButton && onReviewButtonClick && (
            <Button
              dataCy="continue-summative-test-button"
              fontColor="white"
              variant="brand"
              onClick={() => onReviewButtonClick()}
            >
              {t("exerciseFinishedMessage.button.reviewQuestions", "Review all questions")}
            </Button>
          )}
          {showNextExerciseButton && onNextExerciseButtonClick && (
            <Button
              dataCy="review-message-next-exercise-button"
              variant="primary"
              onClick={() => onNextExerciseButtonClick()}
            >
              {t("exerciseFinishedMessage.button.nextExercise", "Continue to the next part")}
            </Button>
          )}
          <Button
            dataCy="review-message-back-to-overview-button"
            fontColor="white"
            variant="primary"
            onClick={() => onBackToOverviewButtonClick()}
          >
            {t("exerciseFinishedMessage.button.backToOverview", "Back to test overview")}
          </Button>
        </>
      }
      buttonsTitle=" "
      dataCy="finished-summative-exercise-message"
      description={
        showReviewButton ? (
          <Trans i18nKey="exerciseFinishedMessage.description" t={t}>
            Good job, you’ve completed a part of the test!
            <br />
            Check your answers one more time or go on to the next subject.
          </Trans>
        ) : (
          <Trans i18nKey="exerciseFinishedMessage.descriptionNoReview" t={t}>
            Good job, you’ve completed a part of the test!
            <br />
            Are you ready to continue?
          </Trans>
        )
      }
      iconName="badgeStarIcon"
      title={
        <Trans i18nKey="exerciseFinishedMessage.title" t={t}>
          Well done!
          <br />
          You&quo;ve finished this subject
        </Trans>
      }
    />
  );
};
