import { keyframes } from "styled-components/macro";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const spin = keyframes`
  from { 
    transform: rotate(0deg);

  }
  to {
    transform: rotate(360deg);
  }
`;
