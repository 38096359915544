import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

import { Modal } from "components/Modal";
import { CustomReviewDate } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/TestDatesStep/components/CustomReviewDate/CustomReviewDate";
import { Heading4 } from "styles/elements/Headings";

export const StyledModal = styled(Modal)<Props>`
  width: 65rem;
  min-height: 45rem;
  max-width: 90%;
  padding: 3rem 3rem;
  background-color: ${({ theme }: Props) => theme.palette.neutral.white};
`;

export const StyledSection = styled.section<Props>`
  width: 100%;
  margin: 2rem 0;

  ${Heading4} {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }
`;

export const StyledCustomReviewDate = styled(CustomReviewDate)<Props>`
  padding: 0;

  h4 {
    font-weight: normal;
  }
`;
