import { useEffect, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";

import { trackPageEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { routesForTracking } from "analytics/constants/routesForTracking";

export const useMetaBasePageTracker = (): void => {
  const history = useHistory();
  const [prevLocation, setPrevLocation] = useState<string>();

  // This generates a string in the following format: "param1=value&param2=value"
  const buildIdFromPathParams = (params: Record<string, string> = {}): string =>
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

  /**
   *  Handler that will run when the page URL changes. It will trigger page
   * events whenever the user has moved on to a different page.
   *
   *  Any update to hash and query params will be ignored, as we will consider
   * the user has not changed pages as long as they remain on a same path. For
   * that reason, we won't trigger the page event unless we detect that the
   * value for pathname has changed.
   *
   * @param {object} location - Current user location.
   */
  const onLocationChange = ({ pathname }: Location) => {
    const currentLocation = pathname;

    // Do not trigger duplicated event if user is still in same page (ex.: only used a filter)
    if (currentLocation === prevLocation) {
      return;
    }

    if (!prevLocation || currentLocation !== prevLocation) {
      // biome-ignore lint/suspicious/noImplicitAnyLet: Biome look I'm not sure what to do here
      let pathParams;
      const currentRoute = routesForTracking.find((route) => {
        const path = matchPath(pathname, { path: route.path, exact: true });
        pathParams = path?.params || {};
        return path;
      });

      // Trigger page event only if route is meant to be tracked (exists in "routesForTracking")
      const currentRouteType = currentRoute?.getType?.(pathname) || currentRoute?.type;
      if (currentRouteType && currentRoute) {
        trackPageEvent({
          pageName: pathname,
          type: currentRouteType,
          category: currentRoute.category,
          // Fallback to `undefined` so we don't provide an `id` for routes that have no path params
          id: buildIdFromPathParams(pathParams) || undefined,
        });
      }
    }

    setPrevLocation(currentLocation);
  };

  /**
   *  Hook that adds a listener to router' history to keep track of changes in
   * the URL when the component mounts.
   *
   *  It will run again whenever the one of the values of `prevLocation` changes,
   * or otherwise the listener won't have access but to the initial values that
   * 'prevLocation' had when it was created.
   *
   * @returns {function} Method to stop listening to changes. Will be run when
   *  the component unmounts.
   */

  useEffect(() => {
    // @ts-ignore
    const unlisten = history.listen(onLocationChange);
    return unlisten;
  }, [prevLocation, history.listen]);
};
