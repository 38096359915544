import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";

import { Button } from "components/Button";
import { TestCompletedMessage } from "layouts/TestCompletedMessage";
import type { DiagnosticTestPagePathParams } from "models/path/path-params";

export const DiagnosticTestCompletedMessage: React.FC = () => {
  const { t } = useTranslation("diagnostic-test");
  const history = useHistory();

  const { productId, moduleId, groupId, testId }: DiagnosticTestPagePathParams = useParams();

  return (
    <TestCompletedMessage
      buttons={
        <>
          <Button
            dataCy="see-results-button"
            fontColor="black"
            variant="secondary"
            onClick={() =>
              history.replace(
                buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
                  productId,
                  moduleId,
                }),
              )
            }
          >
            {t("button.takeBreak.label", "Take a break")}
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              history.replace(
                buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_DETAILS, {
                  productId,
                  moduleId,
                  groupId,
                  testId,
                }),
              )
            }
          >
            {t("button.continue.label", "Continue")}
          </Button>
        </>
      }
      buttonsTitle={t("finishedQuestions.title", "Ready for the next topic?")}
      dataCy="finished-questions-diagnostic-test"
      description={t(
        "finishedQuestions.description",
        "You completed a set of questions for the first part. You can now continue with the next part of the test. What would you like to do next?",
      )}
      iconName="badgeIcon"
    />
  );
};
