import styled from "styled-components/macro";
import { ParagraphLarge } from "styles/elements/Texts";
import { Box } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  fontWeight?: number;
  fontSize?: string;
  theme: ElearningThemeInterface;
  active?: boolean;
}

export const ToggleContainer = styled(Box)`
  display: flex;
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._600};
  border-radius: 5px;
`;

export const Button = styled.button<Props>`
  padding: 0 1rem;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  margin: 0.25rem;
  background-color: ${(props: Props) => (props.active ? "lightgray" : "transparent")};
  display: flex;
  align-items: center;
`;

export const StyledParagraph = styled(ParagraphLarge)<Props>`
  font-size: ${(props: Props) => props.fontSize};
  font-weight: ${(props: Props) => props.fontWeight};
  margin: 0;
`;
