import { css } from "styled-components/macro";

export const clearButtonCss = css`
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
