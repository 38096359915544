import {
  AssignmentTopicProgressionStatus,
  type AssignmentTopicProgressionStatusType,
} from "models/progress/Progress";
import styled from "styled-components/macro";
import { CssFlex, CssGrid, CssIcon } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export interface StyledCorrectTextTopicProps extends Props {
  topicState: AssignmentTopicProgressionStatusType;
  isDisabled: boolean;
}

export const StyledCorrectTextTopic = styled(CssGrid)<StyledCorrectTextTopicProps>`
  ${({ theme, isDisabled, topicState }: StyledCorrectTextTopicProps) => {
    if (isDisabled) {
      return `color: ${theme.palette.neutral._600};`;
    }

    switch (topicState) {
      case AssignmentTopicProgressionStatus.LOCKED:
        return `
          color: ${theme.palette.neutral._600};
        `;
      case AssignmentTopicProgressionStatus.READY:
        return `
          color: ${theme.palette.neutral.black};
          cursor: pointer;

          :hover {
            background: #E6E6E6;
          }
        `;
      case AssignmentTopicProgressionStatus.FAILED:
        return `
          :hover {
            background: #E6E6E6;
          }
        `;
      case AssignmentTopicProgressionStatus.COMPLETED:
        return `
          color: ${theme.palette.feedback.success.brand};

          ${CssIcon} {
            color: ${theme.palette.feedback.success._600};
          }
        `;
    }
  }};
`;

export const StyledTitle = styled(CssFlex)<Props>`
  font-weight: bold;
  font-size: ${(props: Props) => props.theme.font.size._16};
`;

export const StyledNeedReviewMessage = styled(CssGrid)<Props>`
  column-gap: 1rem;
  grid-template-columns: auto 1fr;
  justify-items: start;
  grid-column: 1 / span 2;
`;

export const StyledTopicList = styled.ul<Props>`
  padding-left: 1.5rem;
  color: ${(props: Props) => props.theme.palette.neutral.black};

  li {
    text-align: left;
  }
`;

export const StyledStatusText = styled.span<Props>`
  font-size: ${(props: Props) => props.theme.font.size._14};
`;
