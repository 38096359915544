import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { questionSolutionBuilder } from "_helpers/questionHelper";
import { Button } from "components/Button";
import { SanitizedHtml } from "components/SanitizedHtml";
import {
  QuestionStatus,
  QuestionType,
  TestType,
  isEvaluableOpenQuestionType,
  isNotEvaluableQuestionType,
} from "constants/exam-constants";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import type { ExamQuestion, QuestionResult } from "models/exam/Exam";
import { Heading3, Heading4 } from "styles/elements/Headings";
import { Box, CssClickableIcon, CssFlex } from "styles/helpers/layout";
import { StyledAnswerBox, StyledNextButton } from "./QuestionAnswerBox.styled";

import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useAnswer } from "hooks/useAnswer";
import { AnswerButton } from "layouts/QuestionManager/components/AnswerButton/AnswerButton";
import { RelatedTopicList } from "./components/RelatedTopicList";

interface QuestionAnswerBoxProps {
  question: ExamQuestion;
  status: QuestionStatus;
  questionResult: QuestionResult | null;
  testType: TestType;
  loadNextQuestion: () => void;
  shouldAllowShowingTheory: boolean;
}

export const QuestionAnswerBox: React.FC<QuestionAnswerBoxProps> = observer(
  ({ question, status, questionResult, testType, loadNextQuestion, shouldAllowShowingTheory }) => {
    const { t: tAdaptiveTest } = useTranslation("adaptive-test");
    const { t: tSkillDeterminationTest } = useTranslation("skill-determination-test");
    const { toggleModal } = useModal();
    const { nextQuestionIsReady } = useAdaptivePractice();
    const {
      showProgressButton,
      goNextQuestion,
      setGoNextQuestion,
      resetAnswerState,
      answerButtonProps,
    } = useAnswer();

    const {
      isDisabled: isAnswerButtonDisabled,
      isLoading: isAnswerButtonLoading,
      isHidden: shouldHideAnswerButton,
      label: answerButtonContent,
      onClick: onAnswerButtonClick,
    } = answerButtonProps;

    const nextQuestionButtonRef = useRef<HTMLDivElement>(null);

    const isNonEvaluableQuestion = isNotEvaluableQuestionType(question.type);

    useEffect(() => {
      if (testType === TestType.ADAPTIVE_PRACTICE_TEST && !nextQuestionIsReady) return;

      if (goNextQuestion) {
        nextQuestionButtonRef.current?.click();
        setGoNextQuestion(false);
      }
    }, [goNextQuestion, nextQuestionIsReady]);

    // Render nothing is there's no question loaded
    if (!question) return null;

    const answeredCorrectly = status === QuestionStatus.CORRECT;
    const relatedTopicCount = questionResult?.relatedTopics?.length || 0;

    const awaitingProgressUpdate =
      testType === TestType.ADAPTIVE_PRACTICE_TEST &&
      status !== QuestionStatus.INITIAL &&
      !nextQuestionIsReady;

    let footerButton = null;
    if (!shouldHideAnswerButton) {
      // Display button to submit the user answers
      footerButton = (
        <CssFlex ref={nextQuestionButtonRef} alignItems="center" pr={3}>
          <AnswerButton
            disabled={isAnswerButtonDisabled}
            isLoading={isAnswerButtonLoading}
            showConfirmAnswerButton={!shouldHideAnswerButton}
            onSubmitAnswer={(skipAnswerValidation?: boolean) => {
              onAnswerButtonClick?.(skipAnswerValidation);
            }}
          >
            {answerButtonContent && answerButtonContent.length !== 0
              ? answerButtonContent
              : tSkillDeterminationTest("button.checkAnswer.label", "Check answer")}
          </AnswerButton>
        </CssFlex>
      );
    } else {
      // Display button to move on to the next question
      footerButton = (
        <CssFlex ref={nextQuestionButtonRef} alignItems="center" pr={3}>
          <StyledNextButton
            data-cy="go-forward-button"
            disabled={status === QuestionStatus.INITIAL || !showProgressButton}
            isLoading={awaitingProgressUpdate}
            p="1rem"
            status={isNonEvaluableQuestion ? QuestionStatus.INITIAL : status}
            style={{ width: "100%" }}
            variant={
              status === QuestionStatus.CORRECT
                ? "success"
                : status === QuestionStatus.INCORRECT
                  ? "error"
                  : "primary"
            }
            onClick={() => {
              resetAnswerState();
              loadNextQuestion();
            }}
          >
            {tAdaptiveTest("footer.next")}
          </StyledNextButton>
        </CssFlex>
      );
    }

    return (
      <StyledAnswerBox
        data-cy="question-feeedback-bar"
        data-status={status}
        gridTemplateColumns="15rem 1fr minmax(15rem, auto)"
        p={2}
        status={isNonEvaluableQuestion ? QuestionStatus.INITIAL : status}
        width="100%"
      >
        {/* -- Theory link -- */}
        <CssFlex alignItems="center" pt={1}>
          {shouldAllowShowingTheory && (
            <Button
              dataCy="view-theory"
              style={{ width: "100%" }}
              onClick={() =>
                toggleModal(modalIds.viewTheoryModal, { topicId: question.topicId || null })
              }
            >
              <CssClickableIcon iconName="fad fa-book-open" pr={1} width="15%" />
              {tAdaptiveTest("footer.viewTheory.label")}
            </Button>
          )}
        </CssFlex>

        {/* -- Feedback section -- */}
        <Box p={2} width="90%">
          {(status === QuestionStatus.INCORRECT || status === QuestionStatus.CORRECT) &&
            !isNonEvaluableQuestion && (
              <>
                <Heading3 data-cy="footer-feedback" fontSize="1.4rem" marginBottom="1rem">
                  {answeredCorrectly
                    ? tAdaptiveTest("footer.feedback.correct")
                    : tAdaptiveTest("footer.feedback.incorrect")}
                </Heading3>
                {status === QuestionStatus.INCORRECT &&
                  isEvaluableOpenQuestionType(question.type) && (
                    <Heading4 marginBottom="1rem">
                      {tAdaptiveTest("footer.feedback.rightAnswer")}
                      <i data-cy="right-answer-text">
                        {questionSolutionBuilder(questionResult?.results ?? [], question)}
                      </i>
                    </Heading4>
                  )}
                {/* Always show question tip when available in the content */}
                {questionResult?.questionTip && (
                  <SanitizedHtml dataCy="question-tip" dirtyHtml={questionResult?.questionTip} />
                )}
                {/* Show default error feedback when no tip is available or if no related topics are known (for "correct-text" questions only) */}
                {status === QuestionStatus.INCORRECT &&
                  !questionResult?.questionTip &&
                  (question.type !== QuestionType.CORRECT_TEXT ||
                    (question.type === QuestionType.CORRECT_TEXT && !relatedTopicCount)) && (
                    <div data-cy="default-error-feedback">
                      {tAdaptiveTest("questionEvaluationResults.reviewTopic")}
                    </div>
                  )}
                {/* Show list of related topics to review when available (for "correct-text" questions only) */}
                {status === QuestionStatus.INCORRECT &&
                  question.type === QuestionType.CORRECT_TEXT &&
                  relatedTopicCount > 0 && (
                    <RelatedTopicList relatedTopics={questionResult?.relatedTopics || []} />
                  )}
              </>
            )}

          {isNonEvaluableQuestion && status === QuestionStatus.SUBMITTED && (
            <SanitizedHtml
              dataCy="suggested-answer"
              dirtyHtml={
                questionResult?.suggestedAnswer ??
                tAdaptiveTest(
                  "questionEvaluationResults.infoAnsweredQuestion",
                  "Your answer has been sent!",
                )
              }
            />
          )}
        </Box>

        {/* -- Action buttons -- */}
        {footerButton}
      </StyledAnswerBox>
    );
  },
);
