import type React from "react";

import { Card } from "components/Card/Card";
import { EncircledIcon } from "components/EncircledIcon";

import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssFlex } from "styles/helpers/layout";

import type { EncircledIconVariant } from "components/EncircledIcon/EncircledIcon.styled";
import { StyledCardLayout } from "./StatCard.styled";

export interface StatCardProps {
  className?: string;
  dataCy?: string;
  iconName: string;
  iconVariant?: EncircledIconVariant;
  label: string;
  value: React.ReactNode;
}

export const StatCard: React.FC<StatCardProps> = ({
  className,
  dataCy,
  iconName,
  label,
  value,
  iconVariant,
}) => {
  return (
    <Card className={className}>
      <StyledCardLayout>
        <EncircledIcon iconName={iconName} variant={iconVariant} />
        <CssFlex flexDirection="column" rowGap={0.3}>
          <Heading3 data-cy={`${dataCy}-value`}>{value}</Heading3>
          <ParagraphLarge>{label}</ParagraphLarge>
        </CssFlex>
      </StyledCardLayout>
    </Card>
  );
};
