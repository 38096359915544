import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { calculateRems } from "styles/mixins/calculateRem";

import { CardStatus } from "components/CardStatus/CardStatus";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";

export const StyledWritingSubject = styled.div<ThemeProps>`
  text-align: start;
`;

export const StyledCardStatus = styled(CardStatus)<ThemeProps>`
  height: fit-content;
  min-height: 6rem;
  column-gap: 1rem;
  grid-template-columns: 4fr 1fr 1fr;

  button {
    width: 9rem;
    padding: ${calculateRems([11.2, 8])};
    font-size: ${(props: ThemeProps) => props.theme.font.size._14};
    font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold};
  }

  h3,
  p {
    margin-bottom: 0;
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

export const StyledCompletedIcon = styled(FontAwesomeIcon)<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.palette.feedback.success._600};
`;

export const StyledProgressContainer = styled.div<ThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: normal;
  color: ${(props: ThemeProps) => props.theme.palette.neutral.black};

  > span:first-child {
    font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  }

  > span:last-child {
    font-size: ${(props: ThemeProps) => props.theme.font.size._12};
    text-align: center;
  }
`;
