import styled, { css } from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { CssFlex } from "styles/helpers/layout";

interface GroupIconContainerProps extends ThemeProps {
  $ownedBySelf?: boolean;
}

export const StyledGroupIconContainer = styled(CssFlex)<GroupIconContainerProps>`
  border-radius: 50%;

  width: 1.25rem;
  height: 1.25rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._10};

  align-items: center;
  justify-content: center;

  ${({ $ownedBySelf }: GroupIconContainerProps) => {
    if ($ownedBySelf) {
      return css`
        color: #f9b002;
        background: #fff3d5;
      `;
    }
    return css`
        color: #0078f1;
        background: #eef7ff;
      `;
  }};
`;
