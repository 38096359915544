import { observer } from "mobx-react";
// student env specific navigation menu
import type React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useProduct } from "hooks/useProduct";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TestType } from "constants/exam-constants";
import { PRODUCT_PAGES_ROUTES, RESULT_PAGE_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";

import { NavigationMenu } from "components/NavigationMenu/NavigationMenu";
import type { NavigationMenuItem } from "components/NavigationMenu/NavigationMenu.model";

import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { ProductType } from "constants/hst-constants";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useModuleAvailability } from "hooks/useModuleAvailability";
import { TestEnvMenuSection } from "layouts/TestEnvMenuSection";

interface ComponentProps {
  className?: string;
  dataCy?: string;
}

export const StudentNavigationMenu: React.FC<ComponentProps> = observer(({ className, dataCy }) => {
  const { t } = useTranslation("homepage");

  const { productDetails: product, productsList: ownedProducts, fetchProducts } = useProduct();
  const isStudentABusinessUser = ownedProducts?.every(
    (product) => product.type === ProductType.BUSINESS,
  );
  const {
    studentNavigationPersonalProgress,
    studentNavigationResultsWritingExercises,
    studentNavigationDiagnosticTest,
  } = useFeatureFlags();

  const getUrlWithProductId = (placeholderUrl: string) =>
    buildUrlWithPathParams(placeholderUrl, { productId: product?.id as number });

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const { isAdaptiveModuleAvailable, isWritingModuleAvailable } = useModuleAvailability();

  const menuItems = useMemo<NavigationMenuItem[]>(
    () => [
      {
        id: "student-dashboard",
        dataCy: "student-dashboard",
        title: t("sideMenu.dashboard", "Dashboard"),
        redirectPath: getUrlWithProductId(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS),
        onlyActiveOnExactPath: true,
      },
      /* Show module as standalone item if product has only one; otherwise we wrap them into a category */
      ...(product?.modules && product.modules.length === 1
        ? [
            {
              id: `product-module-${product.modules[0].id}`,
              title: product.modules[0].title,
              dataCy: "product-module-item",
              redirectPath: buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
                productId: product.id,
                moduleId: product.modules[0].id,
              }),
              excludeFromActivePath: [
                TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS,
                TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_LIST,
                TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_DETAILS,
              ],
            },
            // Add diagnostic test subitem if necessary
            ...(product.modules[0].hasDiagnosticTest
              ? [
                  {
                    id: `product-module-${product.modules[0].id}-diagnostic-test`,
                    title: t("sideMenu.modules.diagnosticTest"),
                    dataCy: `module-${module.id}-diagnostic-test`,
                    redirectPath: buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS, {
                      productId: product.id,
                      moduleId: product.modules[0].id,
                    }),
                    nestingLevel: 2,
                    eventTrackData: {
                      module: STUDENT_MODULES.BASIC_PROGRESS,
                      itemId:
                        PLATFORM_EVENT_TYPES.STUDENT_MODULES_EVENT_TYPES.CLICK_GO_TO_PRACTICE_TEST,
                      elementId: `student-nav-menu-module-${product.modules[0].id}-diagnostic-test`,
                    },
                  },
                ]
              : []),
          ]
        : [
            {
              id: "product-modules",
              expandedByDefault: true,
              dataCy: "product-module-list",
              title: t("sideMenu.modules.title", "Modules"),
              subItems: product?.modules.flatMap((module) => {
                const defaultModuleMenuItem = {
                  id: `product-module-${module.id}`,
                  title: module.title,
                  dataCy: "module-list-item",
                  redirectPath: buildUrlWithPathParams(
                    PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS,
                    {
                      productId: product.id,
                      moduleId: module.id,
                    },
                  ),
                  excludeFromActivePath: [
                    TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS,
                    TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_LIST,
                    TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_DETAILS,
                  ],
                };

                // If the module has diagnostic tests, show additional item in menu
                if (module.hasDiagnosticTest) {
                  return [
                    defaultModuleMenuItem,
                    {
                      id: `product-module-${module.id}-diagnostic-test`,
                      title: t("sideMenu.modules.diagnosticTest"),
                      dataCy: `module-${module.id}-diagnostic-test`,
                      redirectPath: buildUrlWithPathParams(
                        TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS,
                        {
                          productId: product.id,
                          moduleId: module.id,
                        },
                      ),
                      nestingLevel: 2,
                      eventTrackData: {
                        module: STUDENT_MODULES.BASIC_PROGRESS,
                        itemId:
                          PLATFORM_EVENT_TYPES.STUDENT_MODULES_EVENT_TYPES
                            .CLICK_GO_TO_PRACTICE_TEST,
                        elementId: `student-nav-menu-module-${module.id}-diagnostic-test`,
                      },
                    },
                  ];
                }

                return [defaultModuleMenuItem];
              }),
            },
          ]),
      {
        id: "result-list",
        dataCy: "result-list",
        expandedByDefault: true,
        title: t("sideMenu.results.title", "Results"),
        subItems: [
          ...(ownedProducts && ownedProducts.length > 0
            ? [
                ...(studentNavigationPersonalProgress && isAdaptiveModuleAvailable
                  ? [
                      {
                        id: "results-personal-progress",
                        title: t("sideMenu.results.items.personalProgress", "Basic skills"),
                        dataCy: "results-personal-progress",
                        redirectPath: getUrlWithProductId(RESULT_PAGE_ROUTES.PERSONAL_PROGRESS),
                      },
                    ]
                  : []),
                ...(studentNavigationResultsWritingExercises && isWritingModuleAvailable
                  ? [
                      {
                        id: "results-writing-exercises",
                        title: t("sideMenu.results.items.writingExercises", "Writing skills"),
                        dataCy: "results-writing-exercises",
                        redirectPath: getUrlWithProductId(RESULT_PAGE_ROUTES.WRITING_EXERCISES),
                      },
                    ]
                  : []),
                ...(studentNavigationDiagnosticTest && !isStudentABusinessUser
                  ? [
                      {
                        id: "results-diagnostic-test",
                        dataCy: "results-diagnostic-test",
                        title: t("sideMenu.results.items.diagnosticTest", "Practice Test"),
                        redirectPath: getUrlWithProductId(RESULT_PAGE_ROUTES.DIAGNOSTIC_TEST),
                      },
                    ]
                  : []),
                ...(!isStudentABusinessUser
                  ? [
                      {
                        id: "results-summative-test",
                        dataCy: "results-summative-test",
                        title: t("sideMenu.results.items.summativeTest", "Summative Test"),
                        redirectPath: buildUrlWithPathParams(
                          RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST,
                          {
                            testType: TestType.SUMMATIVE_TEST,
                          },
                        ),
                      },
                      {
                        id: "results-formative-test",
                        dataCy: "results-formative-test",
                        title: t("sideMenu.results.items.formativeTest", "Formative Test"),
                        redirectPath: buildUrlWithPathParams(
                          RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST,
                          {
                            testType: TestType.FORMATIVE_TEST,
                          },
                        ),
                      },
                      {
                        id: "results-freebie-test",
                        dataCy: "results-freebie-test",
                        title: t("sideMenu.results.items.freebieTest", "Freebie Test"),
                        redirectPath: buildUrlWithPathParams(
                          RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST,
                          {
                            testType: TestType.INTAKE_TEST,
                          },
                        ),
                      },
                      {
                        id: "certificate-results",
                        dataCy: "certificate-results",
                        title: t("sideMenu.results.items.certificate", "Certificates"),
                        redirectPath: RESULT_PAGE_ROUTES.OBTAINED_CERTIFICATES,
                      },
                    ]
                  : []),
              ]
            : []),
        ],
      },
    ],
    [t, product],
  );

  return (
    <aside>
      <NavigationMenu className={className} dataCy={dataCy} menuItems={menuItems} />
      {!isStudentABusinessUser && <TestEnvMenuSection />}
    </aside>
  );
});
