import { format } from "date-fns";
import type React from "react";
import { useTranslation } from "react-i18next";

import {
  HST_FRAUD_EVENTS,
  HST_PROCTORING_EVENTS,
  HST_TEST_STATUS_EVENTS,
} from "@infinitaslearning/module-he-common";

import type { ScheduledTestStudentLog } from "models/log/Log";
import {
  StyledEventDescription,
  StyledEventItem,
  StyledEventItemIpAddress,
  StyledEventTime,
} from "./EventListItem.styled";

interface EventListItemProps {
  event: ScheduledTestStudentLog;
}

export const EventListItem: React.FC<EventListItemProps> = ({ event }) => {
  const { t } = useTranslation("scheduled-tests");

  const getEventDescription = (trackedEvent: ScheduledTestStudentLog) => {
    const exerciseTitle = trackedEvent.extraData?.name;

    switch (trackedEvent.eventName) {
      case HST_TEST_STATUS_EVENTS.SCHEDULED_TEST_OPENED:
        return t("liveMonitor.events.status.testOpened", "Opened the test");
      case HST_TEST_STATUS_EVENTS.TERMS_AND_CONDITIONS_ACCEPTED:
        return t("liveMonitor.events.status.termsAccepted", "Accepted terms & conditions");
      case HST_TEST_STATUS_EVENTS.EXERCISE_STARTED:
        return !exerciseTitle
          ? t("liveMonitor.events.status.untitledExerciseStarted", "Started an exercise")
          : t("liveMonitor.events.status.exerciseStarted", { exerciseTitle });
      case HST_TEST_STATUS_EVENTS.EXERCISE_REVIEWED:
        return !exerciseTitle
          ? t("liveMonitor.events.status.untitledExerciseReviewed", "Reviewed an exercise")
          : t("liveMonitor.events.status.exerciseReviewed", { exerciseTitle });
      case HST_TEST_STATUS_EVENTS.SCHEDULED_TEST_FINISHED:
        return t("liveMonitor.events.status.testFinished", "Closed the test");
      case HST_TEST_STATUS_EVENTS.SCHEDULED_TEST_TIME_RAN_OUT:
        return t("liveMonitor.events.status.timeRanOut", "Test time ran out");

      case HST_PROCTORING_EVENTS.STUDENT_CURSOR_LEAVES_PAGE:
        return t("liveMonitor.events.proctoring.mouseOut", "Mouse out of the page");
      case HST_PROCTORING_EVENTS.STUDENT_CURSOR_RETURNS_PAGE:
        return t("liveMonitor.events.proctoring.mouseBack", "Mouse back into page");
      case HST_PROCTORING_EVENTS.STUDENT_LEAVES_FULL_SCREEN:
        return t("liveMonitor.events.proctoring.fullScreenClosed", "Left full screen mode");
      case HST_PROCTORING_EVENTS.STUDENT_GOES_FULL_SCREEN:
        return t("liveMonitor.events.proctoring.fullScreenOpen", "Entered full screen mode");

      case HST_FRAUD_EVENTS.LONG_UNFOCUS_TIME:
        return t("liveMonitor.events.fraud.longUnfocusTime", "Long unfocus time");
      case HST_FRAUD_EVENTS.MULTIPLE_ADDR_ORIGIN:
        return t("liveMonitor.events.fraud.multipleOriginAddr", "Used different IPs");

      default:
        return trackedEvent.eventName;
    }
  };

  return (
    <StyledEventItem data-cy="event-log-item" eventType={event.eventType}>
      <StyledEventDescription eventName={event.eventName}>
        {getEventDescription(event)}
      </StyledEventDescription>
      {event.ipAddress && (
        <StyledEventItemIpAddress>{`IP: ${event.ipAddress}`}</StyledEventItemIpAddress>
      )}
      <StyledEventTime>{format(new Date(event.date), "HH:mm:ss")}</StyledEventTime>
    </StyledEventItem>
  );
};
