import { ToastMethods } from "components/ToastNotification";
import {
  type AdaptiveTestWorkflowType,
  QuestionStatus,
  QuestionType,
  TestType,
} from "constants/exam-constants";
import type { ProgressAPI } from "generated/types";
import i18n from "i18n";
import { action, observable } from "mobx";
import type {
  AdaptivePracticeStats,
  CurrentTopicProgress,
} from "models/adaptive-practice/AdaptivePractice";
import type { GenericTestDetails } from "models/adaptive-practice/AdaptivePractice";
import type { NextPracticeTopic } from "models/adaptive-practice/AdaptivePractice";
import type { SelectedQuestion } from "models/exam/Exam";
import { NormalTopicProgressionStatus } from "models/progress/Progress";
import { adaptivePracticeRepository, progressRepository } from "repositories";
import { fetchNextTopic } from "repositories/AdaptivePracticeRepository/AdaptivePracticeRepository";
import { useProductProgress } from "./useProductProgress";

export type MixedExerciseForSubject = {
  productId: number;
  subjectId: number;
};

export type MixedExerciseForTopic = MixedExerciseForSubject & {
  topicId: number;
};

export interface ErrorResponse {
  status: number;
  message: string;
  type: string;
}

export interface AdaptivePracticeSessionResults {
  initialTopicProgress: CurrentTopicProgress[];
  answeredQuestions: { id: number; isAnswerCorrect: boolean; topicId: number }[];
  currentStreak: number;
  maxStreak: number;
}

export type CongratulationsMessageType =
  | "none"
  | "sub-theme"
  | "theme"
  | "subject"
  | "theme-assignment-unlocked";

export interface AdaptivePracticeStore {
  loading: boolean;
  nextQuestionIsReady: boolean;
  congratulationsMessage: CongratulationsMessageType;
  currentTopic: CurrentTopicProgress | null;
  previousTopic: CurrentTopicProgress | null;
  isMixedExerciseActive: boolean;
  adaptiveTestDetails: GenericTestDetails | null;
  currentMixedExerciseStats: AdaptivePracticeStats;
  selectedQuestion: SelectedQuestion;
  prevQuestionWasFailedTrial: boolean;
  skillLevelHasLowered: boolean | null;
  hasSwappedTopic: boolean;
  subjectCompleted: boolean;
  isReadyForCorrectText: boolean;
  nextTopicId: number | null;
  failedToLoadQuestion: boolean;
  hasPracticed: boolean;
  lastPracticedAdaptiveTopicStatuses: ProgressAPI.LastPracticedAdaptiveTopicStatus[] | null;
  adaptivePracticeSessionResults: AdaptivePracticeSessionResults;
  startedSessionTimestamp: number;

  setCongratulationsMessage: (val: CongratulationsMessageType) => void;
  setLoading: (val: boolean) => void;
  setCurrentTopic: (topicProgress: CurrentTopicProgress | null) => void;
  setPreviousTopic: (topicProgress: CurrentTopicProgress | null) => void;
  setMixedExercise: (isMixedExerciseActive: boolean) => void;
  setAdaptivePracticeDetails: (adaptiveTestDetails: GenericTestDetails | null) => void;
  setNextQuestionIsReady: (nextQuestionIsReady: boolean) => void;
  setCurrentMixedExerciseStats: (currentStats: AdaptivePracticeStats) => void;
  resetCurrentMixedExerciseStats: () => void;
  setSelectedQuestion: (question: SelectedQuestion) => void;
  setPrevQuestionWasFailedTrial: (prevQuestionWasFailedTrial: boolean) => void;
  setSkillLevelHasLowered: (val: boolean | null) => void;
  setHasSwappedTopic: (val: boolean) => void;
  setSubjectCompleted: (val: boolean) => void;
  setIsReadyForCorrectText: (val: boolean) => void;
  setNextTopicId: (val: number | null) => void;
  setFailedToLoadQuestion: (val: boolean) => void;
  setHasPracticed: (val: boolean) => void;
  setLastPracticedAdaptiveTopicStatuses: (
    lastPracticedTopicsPerModule: ProgressAPI.LastPracticedAdaptiveTopicStatus[] | null,
  ) => void;
  setStartedSessionTimestamp: (timestamp: number) => void;
  updateInitialTopicSessionProgress(currentTopic: CurrentTopicProgress): unknown;
  addSessionQuestionResult: (questionId: number, isAnswerCorrect: boolean, topicId: number) => void;
  updateSessionStreak: (isCorrect: boolean) => void;
  resetAdaptivePracticeSessionResults: () => void;

  reset: () => void;
  checkIfNextQuestionIsReady: () => void;
  fetchAdaptivePracticeQuestion: (
    productId: number,
    subjectId: number,
    topicId?: number,
    workflowType?: AdaptiveTestWorkflowType,
  ) => void;
  fetchMixedExercise: (params: MixedExerciseForSubject | MixedExerciseForTopic) => void;
  fetchHasPracticed: () => void;
  fetchLastPracticedAdaptiveTopicStatuses: (productId: number) => void;

  fetchNextPracticeTopic: (
    productId: number,
    subjectId: number,
    topicId?: number,
  ) => Promise<NextPracticeTopic>;
  updateAdaptivePracticeWorkflow: (
    productId: number,
    selectedWorkflow: AdaptiveTestWorkflowType,
  ) => void;
}

const initialState = {
  congratulationsMessage: "none" as CongratulationsMessageType,
  currentTopic: null,
  previousTopic: null,
  adaptiveTestDetails: null,
  isReadyForCorrectText: false,
  subjectCompleted: false,
  hasPracticed: false,
  selectedQuestion: {
    results: [],
    index: 0,
    status: QuestionStatus.INITIAL,
  },
  currentMixedExerciseStats: {
    answeredQuestions: 0,
    failedQuestions: 0,
    correctQuestions: 0,
  },
  loading: false,
  isMixedExerciseActive: false,
  nextQuestionIsReady: false,
  prevQuestionWasFailedTrial: false,
  isEvaluatingAnswer: false,
  skillLevelHasLowered: null,
  hasSwappedTopic: false,
  nextTopicId: null,
  failedToLoadQuestion: false,
  lastPracticedAdaptiveTopicStatuses: [],
  adaptivePracticeSessionResults: {
    initialTopicProgress: [],
    answeredQuestions: [],
    currentStreak: 0,
    maxStreak: 0,
  },
  startedSessionTimestamp: 0,
};

const stateSetters = {
  setCongratulationsMessage: action((val: CongratulationsMessageType) => {
    store.congratulationsMessage = val;
  }),
  setSelectedQuestion: action((question: SelectedQuestion) => {
    store.selectedQuestion = question;
  }),
  setCurrentTopic: action((currentTopic: CurrentTopicProgress | null) => {
    store.currentTopic = currentTopic;
  }),
  setPreviousTopic: action((previousTopic: CurrentTopicProgress | null) => {
    store.previousTopic = previousTopic;
  }),
  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),
  setMixedExercise: action((isMixedExerciseActive: boolean) => {
    store.isMixedExerciseActive = isMixedExerciseActive;
  }),
  setAdaptivePracticeDetails: action((adaptiveTestDetails: GenericTestDetails) => {
    store.adaptiveTestDetails = adaptiveTestDetails;
  }),
  setNextQuestionIsReady: action((nextQuestionIsReady: boolean) => {
    store.nextQuestionIsReady = nextQuestionIsReady;
  }),
  setCurrentMixedExerciseStats: action((currentStats: AdaptivePracticeStats) => {
    store.currentMixedExerciseStats = currentStats;
  }),
  resetCurrentMixedExerciseStats: action(() => {
    store.currentMixedExerciseStats = {
      answeredQuestions: 0,
      correctQuestions: 0,
      failedQuestions: 0,
    };
  }),
  setPrevQuestionWasFailedTrial: action((prevQuestionWasFailedTrial: boolean) => {
    store.prevQuestionWasFailedTrial = prevQuestionWasFailedTrial;
  }),
  setSkillLevelHasLowered: action((val: boolean | null) => {
    store.skillLevelHasLowered = val;
  }),
  setHasSwappedTopic: action((val: boolean) => {
    store.hasSwappedTopic = val;
  }),
  setSubjectCompleted: action((val: boolean) => {
    store.subjectCompleted = val;
  }),
  setIsReadyForCorrectText: action((val: boolean) => {
    store.isReadyForCorrectText = val;
  }),
  setNextTopicId: action((val: number | null) => {
    store.nextTopicId = val;
  }),
  setFailedToLoadQuestion: action((val: boolean) => {
    store.failedToLoadQuestion = val;
  }),
  setHasPracticed: action((val: boolean) => {
    store.hasPracticed = val;
  }),
  setLastPracticedAdaptiveTopicStatuses: action(
    (lastPracticedTopicsPerModule: ProgressAPI.LastPracticedAdaptiveTopicStatus[] | null) => {
      store.lastPracticedAdaptiveTopicStatuses = lastPracticedTopicsPerModule;
    },
  ),
  setStartedSessionTimestamp: action((timestamp: number) => {
    store.startedSessionTimestamp = timestamp;
  }),
  resetAdaptivePracticeSessionResults: action(() => {
    store.adaptivePracticeSessionResults = {
      initialTopicProgress: [],
      answeredQuestions: [],
      currentStreak: 0,
      maxStreak: 0,
    };
  }),
  updateInitialTopicSessionProgress: action((currentTopic: CurrentTopicProgress) => {
    // If this is the first time we've seen the topic during this practice session, save the current progress
    // on that topic so we can compare to it later
    if (
      !store.adaptivePracticeSessionResults.initialTopicProgress.find(
        (t) => t.id === currentTopic.id,
      )
    ) {
      store.adaptivePracticeSessionResults.initialTopicProgress.push(currentTopic);
    }
  }),
  addSessionQuestionResult: action(
    (questionId: number, isAnswerCorrect: boolean, topicId: number) => {
      store.adaptivePracticeSessionResults.answeredQuestions.push({
        id: questionId,
        isAnswerCorrect,
        topicId,
      });
    },
  ),
  updateSessionStreak: action((isCorrect: boolean) => {
    store.adaptivePracticeSessionResults.currentStreak = isCorrect
      ? store.adaptivePracticeSessionResults.currentStreak + 1
      : 0;
    store.adaptivePracticeSessionResults.maxStreak = Math.max(
      store.adaptivePracticeSessionResults.currentStreak,
      store.adaptivePracticeSessionResults.maxStreak,
    );
  }),
  reset: action(() => {
    store.failedToLoadQuestion = false;
    store.currentTopic = null;
    store.previousTopic = null;
    store.nextTopicId = null;
    store.isReadyForCorrectText = false;
    store.congratulationsMessage = "none";
  }),
};

const apiRequests = {
  fetchAdaptivePracticeQuestion: action(
    async (
      productId: number,
      subjectId: number,
      topicId: number,
      workflowType?: AdaptiveTestWorkflowType,
    ) => {
      const { moduleSubjectsProgress } = useProductProgress();
      store.setLoading(true);

      // Remember if previous question was of correct text type and the user failed it
      if (
        store.adaptiveTestDetails &&
        store.adaptiveTestDetails.type === TestType.ADAPTIVE_PRACTICE_TEST
      ) {
        const [prevQuestion] = store.adaptiveTestDetails.questions;
        store.setPrevQuestionWasFailedTrial(
          prevQuestion?.type === QuestionType.CORRECT_TEXT &&
            store.selectedQuestion.status === QuestionStatus.INCORRECT,
        );
      }

      try {
        const { question, currentTopic, token } =
          await adaptivePracticeRepository.fetchAdaptivePracticeQuestion(
            productId,
            subjectId,
            topicId,
            workflowType,
          );

        // Store for streak and session statistics
        store.updateInitialTopicSessionProgress(currentTopic);

        // only if we loaded a new topic, set the previous one
        // dont perform this action if we simply swapped topics, we only need to update it
        const currentTopicHasChanged =
          store.currentTopic && store.currentTopic.id !== currentTopic.id;
        if (currentTopicHasChanged && !store.hasSwappedTopic) {
          store.setPreviousTopic(store.currentTopic);
        }

        store.setAdaptivePracticeDetails({
          questions: question ? [question] : [],
          token,
          type: TestType.ADAPTIVE_PRACTICE_TEST,
        });

        if (currentTopic && store.currentTopic) {
          const { id: oldId, currentUserLevel: oldLevel } = store.currentTopic;
          const { id: currId, currentUserLevel: currLevel } = currentTopic;
          if (oldId === currId && oldLevel && currLevel && oldLevel.level !== currLevel.level) {
            store.setSkillLevelHasLowered(oldLevel.level > currLevel.level);
          } else {
            store.setSkillLevelHasLowered(null);
          }
        }

        store.setNextTopicId(currentTopic.id);
        store.setCurrentTopic(currentTopic);
        store.setHasSwappedTopic(false);
        store.setFailedToLoadQuestion(false);
      } catch (e) {
        store.setLoading(false);
        if (store.adaptiveTestDetails) {
          store.setAdaptivePracticeDetails({
            ...store.adaptiveTestDetails,
            questions: [],
          });
        }

        const { extra, status } = <{ extra?: { nextTopicId: number }; status: number }>e;
        switch (status) {
          case 412: {
            const allTopics = moduleSubjectsProgress
              .flatMap((x) => x.normalTopics)
              .concat(moduleSubjectsProgress.flatMap((x) => x.themeAssignments));
            const topic = allTopics.find((x) => x.id === +topicId);
            const isTopicCompleted = topic?.state === NormalTopicProgressionStatus.COMPLETED;

            if (!isTopicCompleted) {
              store.setNextTopicId(extra?.nextTopicId ?? null);
            }
            store.setFailedToLoadQuestion(true);

            if (!topicId) {
              store.setSubjectCompleted(true);
            }
            break;
          }
          case 404:
            ToastMethods.showToast(
              i18n.t("toast:exam.error.fetchAdaptivePracticeQuestion"),
              "error",
            );
            break;
        }
      } finally {
        store.setLoading(false);
      }
    },
  ),

  fetchNextPracticeTopic: action(
    async (productId: number, subjectId: number, startTopicId?: number) => {
      try {
        const topic = await fetchNextTopic(productId, subjectId, startTopicId);
        return topic;
      } catch (_e) {
        ToastMethods.showToast(i18n.t("toast:exam.error.getNextTopic"), "error");
      }
    },
  ),

  fetchMixedExercise: action(async (params: MixedExerciseForSubject | MixedExerciseForTopic) => {
    store.setLoading(true);

    try {
      const { currentTopic, question, token } =
        await adaptivePracticeRepository.fetchMixedExerciseQuestion(params);

      store.setAdaptivePracticeDetails({
        questions: question ? [question] : [],
        token,
        type: TestType.ADAPTIVE_PRACTICE_TEST,
      });

      store.setCurrentTopic(currentTopic);
      store.setPreviousTopic(currentTopic);
      store.setFailedToLoadQuestion(false);
    } catch (e) {
      if (store.adaptiveTestDetails) {
        const { status } = <ErrorResponse>e;
        if (status !== 404) {
          ToastMethods.showToast(i18n.t("toast:exam.error.fetchMixedExercise"), "error");
        }

        store.setAdaptivePracticeDetails({
          ...store.adaptiveTestDetails,
          questions: [],
          type: TestType.ADAPTIVE_PRACTICE_TEST,
        });
      }
    } finally {
      store.setLoading(false);
    }
  }),

  checkIfNextQuestionIsReady: action(async () => {
    if (!store.adaptiveTestDetails) {
      return;
    }
    try {
      const { ready } = await adaptivePracticeRepository.isNextQuestionReady(
        store.adaptiveTestDetails.token,
      );
      store.setNextQuestionIsReady(ready);
    } catch (_e) {
      store.setNextQuestionIsReady(false);
    }
  }),

  fetchHasPracticed: action(async () => {
    try {
      const { finished } = await adaptivePracticeRepository.hasPracticed();
      store.setHasPracticed(finished);
    } catch (_e) {
      store.setHasPracticed(false);
    }
  }),

  fetchLastPracticedAdaptiveTopicStatuses: action(async (productId: number) => {
    store.setLoading(true);
    try {
      const lastPracticedAdaptiveTopicStatus =
        await progressRepository.fetchLastPracticedAdaptiveTopicStatuses(productId);
      store.setLastPracticedAdaptiveTopicStatuses(lastPracticedAdaptiveTopicStatus);
    } catch (_e) {
      store.setLastPracticedAdaptiveTopicStatuses(null);
      ToastMethods.showToast(
        i18n.t("toast:progress.error.fetchLastPracticedAdaptiveTopicStatuses"),
        "error",
      );
    } finally {
      store.setLoading(false);
    }
  }),

  updateAdaptivePracticeWorkflow: action(
    async (productId: number, selectedWorkflow: AdaptiveTestWorkflowType) => {
      store.setLoading(true);
      try {
        await adaptivePracticeRepository.setAdaptivePracticeWorkflow(productId, selectedWorkflow);
      } finally {
        store.setLoading(false);
      }
    },
  ),
};

const store: AdaptivePracticeStore = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as AdaptivePracticeStore);

export const useAdaptivePractice = (): AdaptivePracticeStore => store;
