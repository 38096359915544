import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

import { Button } from "components/Button";

import { StyledField } from "pages/AdminEnvironment/AdminPage/subpages/UsersPage/components/UsersFilter/UsersFilter.styled";

export const StyledSearchBoxContainer = styled.div`
  position: relative;
`;

export const StyledButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme }: Props) => theme.palette.neutral._500};
  position: absolute;
  right: 1rem;
  top: 0.7rem;
  z-index: 3;

  &:hover {
    background-color: transparent;
  }
`;

export const StyledSearchBoxWithLabel = styled(StyledField)`
  margin: 0;
  margin-bottom: 1.5rem;
`;
