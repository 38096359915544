import i18n from "i18n";
import * as Yup from "yup";

export interface ChangeTestOwnerFormFields {
  targetEmail: string;
  testCode: string;
}

export const buildChangeTestOwnerFormSchema = (): Yup.SchemaOf<ChangeTestOwnerFormFields> =>
  Yup.object({
    targetEmail: Yup.string().required(
      i18n.t(
        "admin-environment:adminTools.changeTestOwner.form.fields.targetEmail.errors.required",
      ),
    ),
    testCode: Yup.string().required(
      i18n.t("admin-environment:adminTools.changeTestOwner.form.fields.testCode.errors.required"),
    ),
  }).required();
