import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";

import { Button } from "components/Button";
import { TestCompletedMessage } from "layouts/TestCompletedMessage";
import type { WritingExercisePagePathParams } from "pages/StudentEnvironment/WritingExercisePage/WritingExercisePage.model";

export const WritingExerciseCompletedMessage: React.FC = () => {
  const { t } = useTranslation("writing-exercise");
  const history = useHistory();

  const { productId, moduleId, subjectId, topicId }: WritingExercisePagePathParams = useParams();

  return (
    <TestCompletedMessage
      hideSubTitle
      buttons={
        <Button
          dataCy="see-results-button"
          fontColor="black"
          variant="secondary"
          onClick={() =>
            history.push(
              buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_TOPIC_DETAILS, {
                productId,
                moduleId,
                subjectId,
                topicId,
              }),
            )
          }
        >
          {t("button.takeBreak.label", "Take a break")}
        </Button>
      }
      dataCy="finished-writing-exercise"
      description={t("finishedQuestions.description", "Good job, you completed this exercise.")}
      iconName="badgeStarIcon"
      title={t("finishedQuestions.title", "You have completed the training.")}
    />
  );
};
