import { Formik, type FormikHelpers } from "formik";
import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { useAdmin } from "hooks/useAdmin";
import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssGrid } from "styles/helpers/layout";
import { StyledField, StyledForm } from "../../UserToolsPage.styled";
import {
  type ChangeTestOwnerFormFields,
  buildChangeTestOwnerFormSchema,
} from "./ChangeTestOwnerForm.schema";

export const ChangeTestOwnerForm: React.FC = observer(() => {
  const { t } = useTranslation("admin-environment");
  const { changeScheduledTestOwner } = useAdmin();

  // Call endpoint to transfer test upon submit
  const onFormSubmit = (
    { testCode, targetEmail }: ChangeTestOwnerFormFields,
    { resetForm, setSubmitting }: FormikHelpers<ChangeTestOwnerFormFields>,
  ) => {
    setSubmitting(true);
    changeScheduledTestOwner(testCode, targetEmail).finally(() => {
      setSubmitting(false);
      resetForm();
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        targetEmail: "",
        testCode: "",
      }}
      validationSchema={buildChangeTestOwnerFormSchema()}
      onSubmit={onFormSubmit}
    >
      {(formik) => (
        <StyledForm
          noValidate
          data-cy="scheduled-test-ownership-transfer-form"
          onSubmit={formik.handleSubmit}
        >
          <Heading3>{t("adminTools.changeTestOwner.title")}</Heading3>
          <ParagraphLarge>{t("adminTools.changeTestOwner.description")}</ParagraphLarge>

          <CssGrid gap={2} gridTemplateColumns="1fr 2fr auto">
            <StyledField>
              <label htmlFor="scheduled-test-ownership-transfer-test-code-input">
                {t("adminTools.changeTestOwner.form.fields.testCode.label")}
              </label>
              <InputWithErrorMessage
                dataCy="test-code"
                errorMessage={formik.errors.testCode}
                hasError={!!(formik.errors.testCode && formik.touched.testCode)}
                id="scheduled-test-ownership-transfer-test-code-input"
                name="testCode"
                value={formik.values.testCode}
                onChange={formik.handleChange}
              />
            </StyledField>

            <StyledField>
              <label htmlFor="scheduled-test-ownership-transfer-target-email-input">
                {t("adminTools.changeTestOwner.form.fields.targetEmail.label")}
              </label>
              <InputWithErrorMessage
                dataCy="target-email"
                errorMessage={formik.errors.targetEmail}
                hasError={!!(formik.errors.targetEmail && formik.touched.targetEmail)}
                id="scheduled-test-ownership-transfer-target-email-input"
                name="targetEmail"
                value={formik.values.targetEmail}
                onChange={formik.handleChange}
              />
            </StyledField>

            <Button
              dataCy="submit-button"
              disabled={!formik.isValid || formik.isSubmitting}
              type="submit"
              variant="primary"
            >
              {t("adminTools.changeTestOwner.form.buttons.submit.label")}
            </Button>
          </CssGrid>
        </StyledForm>
      )}
    </Formik>
  );
});
