import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type LevelDashProps from "./LevelDash.model";

interface Props extends LevelDashProps {
  theme: ElearningThemeInterface;
  $size: number;
  $isStateless?: boolean;
}

export const StyledLevelDash = styled.div<Props>`
  height: 0.5rem;
  width: ${({ $size }) => $size}rem;
  border-radius: 0.375rem;
  background-color: ${(props: Props) => {
    if (props.$isStateless) {
      return props.level === "default"
        ? props.theme.palette.secondary._400
        : props.theme.palette.secondary.brand;
    }

    switch (props.level) {
      case "custom":
        return props.theme.palette.feedback.info.brand;
      case "basics":
        return props.theme.palette.feedback.error.brand;
      case "initial":
        return props.theme.palette.yellow._700;
      case "improvement":
        return props.theme.palette.yellow._800;
      case "good":
        return props.theme.palette.feedback.success._600;
      case "excellent":
        return props.theme.palette.feedback.success._600;
      default:
        return props.theme.palette.neutral._900;
    }
  }};
`;
