import { Button } from "components/Button";
import styled from "styled-components/macro";
import { Heading2, Heading4Css } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { calculateRem } from "styles/mixins/mixins";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledScheduledTestsPage = styled.div<Props>`
  margin-bottom: 4rem;
  margin-left: ${calculateRem(32)};
  text-align: center;
  .content {
    width: 100%;
  }
`;

export const StyledScheduledTestsHeader = styled(Heading2)`
  margin-bottom: ${calculateRem(32)};
  text-align: left;
  &:last-of-type {
    margin-bottom: ${calculateRem(52)};
    margin-top: ${calculateRem(56)};
  }
`;

export const StyledScheduledTestsParagraph = styled(ParagraphLarge)`
  line-height: ${calculateRem(24)};
  margin-bottom: ${calculateRem(36)};
  text-align: left;
`;

export const StyledScheduledTestsButton = styled(Button)`
  ${Heading4Css};
  color: ${(props: Props) => props.theme.palette.neutral.white};
  margin-bottom: ${calculateRem(56)};
  max-width: fit-content;
`;

export const StyledScheduledTestsLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
