import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

import welcomeBoxBgImg from "assets/images/hogeschool-bubbles.png";

export const StyledContainer = styled.main<ThemeProps>`
  padding: 2rem 4rem 5rem 4rem;
`;

export const StyledWelcomeBox = styled.section<ThemeProps>`
  padding: 5rem 4rem;
  display: flex;
  align-items: center;
  column-gap: 3rem;

  background-color: ${(props: ThemeProps) => props.theme.palette.secondary._950};
  background-image: url(${welcomeBoxBgImg});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const StyledMessage = styled.article<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.palette.neutral.black};

  p {
    font-size: ${(props: ThemeProps) => props.theme.font.size._18};
    line-height: 180%;
    white-space: pre-line;
  }

  h1 {
    font-size: 4rem;
    line-height: 125%;
    word-break: normal;
  }
`;

export const StyledPreparationBox = styled.section<ThemeProps>`
  display: flex;
  column-gap: 5rem;
  margin-top: 4.75rem;
  align-items: flex-start;
`;

export const StyledCollage = styled.img<ThemeProps>`
  max-width: 50%;
`;

export const StyledTipContainer = styled.section<ThemeProps>`
  > h3 {
    margin-top: 2rem;
    font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  }
`;
