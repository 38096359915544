import { redirectToIdpLogout } from "_helpers/utils/idp";
import axios, { type AxiosError } from "axios";
import type { Login } from "models/auth/Login";
import { type ApiRequestError, RequestError } from "models/error/Error";
import { clearCookiesLogout, clearUsetifulUserDataOnLogout } from "persistence/access";
import { handlingResponse, logError } from "repositories/utils";

const validateLoginUser = async (code = ""): Promise<Login> => {
  try {
    const result = await axios.post("/api/validate", { code });
    return handlingResponse<Login>([200], "Error validating code")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: "validateUserLoginFailed" });
  }
};

const logout = (): void => {
  clearCookiesLogout();
  clearUsetifulUserDataOnLogout();
  redirectToIdpLogout();
};

export { validateLoginUser, logout };
