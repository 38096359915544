import i18n from "i18n";
import * as Yup from "yup";

export interface TestAccessFormFields {
  code: string;
  password?: string;
  requiresPassword?: boolean;
}

export const buildTestAccessSchema = (): Yup.SchemaOf<TestAccessFormFields> =>
  Yup.object({
    code: Yup.string()
      .trim()
      .required(i18n.t("test-environment:testAccessForm.fields.code.errors.required")),
    password: Yup.string()
      .trim()
      .when("requiresPassword", {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty: <explanation>
        then: (schema) =>
          schema.required(
            i18n.t("test-environment:passwordRequiredModal.fields.password.errors.required"),
          ),
      }),
    requiresPassword: Yup.boolean().default(false),
  });
