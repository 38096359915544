import styled from "styled-components/macro";

import { SvgIconReact } from "components/SvgIconReact";
import type { ThemeProps } from "models/styles/theme";
import { CssFlex } from "styles/helpers/layout";

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem 4rem 0 4rem;
`;

export const StyledLogo = styled(SvgIconReact)`
  height: 4rem;
  width: 12rem;
  margin-right: 5rem;
`;

export const StyledExtraContent = styled.div`
  flex-grow: 1;
  display: flex;
  column-gap: 2.5rem;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledProfileButton = styled(CssFlex)<ThemeProps>`
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: ${(props: ThemeProps) => props.theme.palette.primary.brand};
  border-radius: 3px;
  border: 1px solid ${(props: ThemeProps) => props.theme.palette.primary.brand};
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  p {
    color: ${(props: ThemeProps) => props.theme.palette.primary.brand};
  }
`;
