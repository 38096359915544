import { Link } from "components/Link";
import type React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import type { BreadcrumbsProps } from "./Breadcrumbs.model";
import { StyledBreadCrumb, StyledBreadcrumbs } from "./Breadcrumbs.styled";

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  className,
  routes = [],
  excludeRoutes = [],
  showAllRoutes = true,
  dataCy,
}) => {
  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: !showAllRoutes,
    excludePaths: excludeRoutes,
  });
  return (
    <StyledBreadcrumbs className={className} columns={breadcrumbs.length}>
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <StyledBreadCrumb key={match.url} columns={breadcrumbs.length}>
          <Link data-cy={`link-breadcrumbs-${dataCy}`} to={match.url || ""}>
            {breadcrumb}
          </Link>
          {index < breadcrumbs.length - 1 && "   >"}
        </StyledBreadCrumb>
      ))}
    </StyledBreadcrumbs>
  );
};
