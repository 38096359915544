import { AdaptiveTestWorkflowType } from "constants/exam-constants";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useQueryParam } from "hooks/useQueryParam";
import { AdaptiveTestPage, MixedTopicPracticePage } from "pages/StudentEnvironment";

export const AdaptiveTestPageRenderer = (): JSX.Element => {
  const workflow = useQueryParam("workflow");
  const { enableMixedAdaptiveWorkflow } = useFeatureFlags();

  if (enableMixedAdaptiveWorkflow && workflow === AdaptiveTestWorkflowType.MIXED) {
    return <MixedTopicPracticePage />;
  }

  return <AdaptiveTestPage />;
};
