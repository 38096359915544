import styled from "styled-components/macro";

import { calculateRem } from "styles/mixins/mixins";

import type { ThemeProps } from "models/styles/theme";

import { Button } from "components/Button";
import { CountdownTimer } from "components/CountdownTimer/CountdownTimer";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssGrid } from "styles/helpers/layout";

interface RowProps extends ThemeProps {
  $isOdd: boolean;
}

export const StyledRow = styled.tr<RowProps>`
  background-color: ${({ $isOdd, theme }: RowProps) =>
    $isOdd ? theme.palette.neutral._999 : theme.palette.neutral.white};
`;

export const StyledReviewPeriodNotice = styled.div<ThemeProps>`
  display: flex;
  column-gap: 1rem;
  align-items: center;

  padding: ${calculateRem(12)};
  border-radius: ${calculateRem(2)};
  background: ${(props: ThemeProps) => props.theme.palette.secondary._950};
`;

export const StyledMessage = styled(ParagraphLarge)<ThemeProps>`
  flex-grow: 1;
  font-weight: bold;
`;

export const StyledCountdownTimer = styled(CountdownTimer)<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.palette.secondary._400};
  font-weight: bold;
`;

export const StyledButton = styled(Button)<ThemeProps>`
  padding: 0.25rem 1rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  font-weight: bold;
`;

export const StyledCollapsibleIcon = styled(FontAwesomeIcon)<ThemeProps>`
  padding: 1rem;
  color: ${(props: ThemeProps) => props.theme.palette.neutral._600};

  :hover {
    border-radius: 50%;
    background-color: ${(props: ThemeProps) => props.theme.palette.neutral._950};
  }
`;

export const StyledScoreContainer = styled(CssGrid)<ThemeProps>`
  > :last-child {
    text-align: right;
  }
`;
