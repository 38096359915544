import classnames from "classnames";
import type React from "react";
import type FontAwesomeIconProps from "./FontAwesomeIcon.model";
import { StyledFontAwesomeIcon } from "./FontAwesomeIcon.styled";

/**
 *  Component to display FontAwesome icons.
 *
 * @param iconColor -  additional color style to apply to the icon.
 * @param iconSize -  additional size rem style to apply to the icon.
 * @param fixedWidth - Defines whether the icons will be applied a fixed width
 *  or not. This setting will be enabled by default.
 * @param iconName - Class for the icon to use as it appears in the official
 *  FontAwesome documentation, for example: "fas fa-star". Supported icons are
 *  those available for FontAwesome 5.
 *s
 * @see {@link https://fontawesome.com/icons|FontAwesome icon list}
 */

export const FontAwesomeIcon: React.FC<FontAwesomeIconProps> = ({
  iconName,
  iconColor,
  iconSize = 24,
  fixedWidth = false,
  className,
  ...otherProps
}) => (
  <StyledFontAwesomeIcon
    className={classnames(iconName, fixedWidth && "fa-fw", iconSize && `fa-${iconSize}`, className)}
    iconColor={iconColor}
    {...otherProps}
  />
);
