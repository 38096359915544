import { format } from "date-fns";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import type { ProgressAPI, ResultsAPI } from "generated/types";
import {
  ModuleType,
  type ProductModules,
  type ProductSkillLevel,
} from "models/product/ProductDetails";

import { buildUrlWithPathAndQueryParams, buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { AdaptiveTestWorkflowType } from "constants/exam-constants";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";

import { useDateLocale } from "hooks/useDateLocale";
import { useFeatureFlags } from "hooks/useFeatureFlags";

import { Button } from "components/Button";
import { SanitizedHtml } from "components/SanitizedHtml";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssFlex } from "styles/helpers/layout";
import { LastPracticedTopicProgressBar } from "./LastPracticedTopicProgressBar/LastPracticedTopicProgressBar";
import {
  StyledLastPracticedDate,
  StyledLastPracticedTopicTitle,
  StyledModuleCard,
  StyledProgressBarLabel,
  StyledSubtitle,
} from "./ModuleCard.styled";

interface ModuleCardProps {
  productId: number;
  moduleDetails: ProductModules;
  currentGoalLevel?: ProductSkillLevel | null;
  lastPracticedTopicStatus?:
    | ResultsAPI.LastPracticedWritingTopicStatus
    | ProgressAPI.LastPracticedAdaptiveTopicStatus
    | null;
}

export const ModuleCard: React.FC<ModuleCardProps> = ({
  productId,
  moduleDetails: module,
  currentGoalLevel = null,
  lastPracticedTopicStatus = null,
}) => {
  const history = useHistory();
  const { t } = useTranslation("homepage");
  const dateLocale = useDateLocale();
  const { enableMixedAdaptiveWorkflow } = useFeatureFlags();

  const userHasAlreadyPracticed = !!lastPracticedTopicStatus;
  const lastPracticedAdaptiveTopicStatus =
    lastPracticedTopicStatus as ProgressAPI.LastPracticedAdaptiveTopicStatus;

  // For adaptive modules, we need to check if current user progress exceeds their goal
  const progressExceedsCurrentGoal =
    module.type === ModuleType.ADAPTIVE &&
    currentGoalLevel &&
    lastPracticedAdaptiveTopicStatus &&
    lastPracticedAdaptiveTopicStatus.currentSkillLevel > currentGoalLevel?.level;

  // For adaptive modules, we need to check if user had chosen the mixed or default flow
  const isMixedAdaptiveWorkflowActive =
    enableMixedAdaptiveWorkflow &&
    module.type === ModuleType.ADAPTIVE &&
    lastPracticedAdaptiveTopicStatus &&
    lastPracticedAdaptiveTopicStatus.selectedWorkflow === AdaptiveTestWorkflowType.MIXED;

  const onStartButtonClick = () => {
    history.push(
      buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
        productId,
        moduleId: module.id,
      }),
    );
  };

  const onContinueButtonClick = () => {
    if (!lastPracticedTopicStatus) return;

    const { topicId, subjectId } = lastPracticedTopicStatus;

    // Track continue event in Metabase
    trackPlatformEvent({
      module: STUDENT_MODULES.DASHBOARD,
      itemId: isMixedAdaptiveWorkflowActive
        ? PLATFORM_EVENT_TYPES.STUDENT_DASHBOARD_EVENT_TYPES.CLICK_CONTINUE_PRACTICING_MIX
        : PLATFORM_EVENT_TYPES.STUDENT_DASHBOARD_EVENT_TYPES.CLICK_CONTINUE_PRACTICING_TOPIC,
      value: JSON.stringify({
        productId,
        moduleId: module.id,
        subjectId,
        ...(!isMixedAdaptiveWorkflowActive ? { topicId } : {}),
      }),
    });

    // We build URL to which button shall redirect based on the module type
    let redirectUrl: string;
    const commonPathParams = { productId, moduleId: module.id, subjectId };
    if (module.type === ModuleType.ADAPTIVE) {
      // For additional explanation on why we're doing this, please check out the following
      // discussion: https://github.com/infinitaslearning/app-he-hoges-web/pull/1662#discussion_r1744052599
      const isTopicCompleted = lastPracticedAdaptiveTopicStatus?.progressInCurrentLevel > 99;
      const lastParamRedirect = isTopicCompleted ? undefined : { topicId };
      redirectUrl = buildUrlWithPathAndQueryParams(
        TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
        commonPathParams,
        // For adaptive modules, apply different query param depending on the active workflow
        isMixedAdaptiveWorkflowActive
          ? { workflow: AdaptiveTestWorkflowType.MIXED as string }
          : lastParamRedirect,
      );
    } else {
      redirectUrl = buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_TOPIC_DETAILS, {
        ...commonPathParams,
        topicId,
      });
    }

    history.push(
      buildUrlWithPathParams(redirectUrl, {
        productId,
        moduleId: module.id,
        subjectId,
        topicId,
      }),
    );
  };

  return (
    <StyledModuleCard key={module.id} dataCy="module-card" title={module.title}>
      <CssFlex flexDirection="column" flexGrow={1} overflowY="auto" rowGap={0.5}>
        {/* Show description only if user has not practiced in the module before */}
        {!userHasAlreadyPracticed && module.description && (
          <>
            <StyledSubtitle data-cy="content-subtitle-not-practiced">
              {t("studentDashboard.continueSection.moduleCard.learningGoals")}
            </StyledSubtitle>
            <SanitizedHtml
              dataCy="content-description-not-practiced"
              dirtyHtml={module.description}
            />
          </>
        )}
        {/* If user has already practiced, we show name of the last topic they practiced */}
        {userHasAlreadyPracticed && (
          <>
            <StyledLastPracticedDate alignItems="center" columnGap={0.5}>
              <ParagraphLarge>
                {t("studentDashboard.continueSection.moduleCard.lastPracticedDate")}
                {format(new Date(lastPracticedTopicStatus.lastPracticeDate), "dd-MM-yyyy", {
                  locale: dateLocale,
                })}
              </ParagraphLarge>
            </StyledLastPracticedDate>
            <StyledSubtitle data-cy="content-subtitle-practiced">
              {lastPracticedTopicStatus.subjectTitle}
            </StyledSubtitle>
            {/* Show mix assignments title or topic details depending on selected workflow */}
            {isMixedAdaptiveWorkflowActive ? (
              <StyledLastPracticedTopicTitle
                dataCy="content-topic-mix"
                dirtyHtml={t("studentDashboard.continueSection.moduleCard.mixedMode")}
              />
            ) : (
              <>
                <StyledLastPracticedTopicTitle
                  dataCy="content-topic-title"
                  dirtyHtml={lastPracticedTopicStatus.topicTitle}
                />
                <CssFlex flexDirection="column" flexGrow={1} justifyContent="flex-end">
                  <StyledProgressBarLabel data-cy="progress-bar-label">
                    {module.type === ModuleType.ADAPTIVE &&
                      t("studentDashboard.continueSection.moduleCard.currentLevel", {
                        skillLevelName: progressExceedsCurrentGoal
                          ? currentGoalLevel?.label
                          : lastPracticedAdaptiveTopicStatus.currentSkillLevelName,
                      })}
                    {module.type === ModuleType.WRITING &&
                      t("studentDashboard.continueSection.moduleCard.completedExercises")}
                  </StyledProgressBarLabel>
                  <LastPracticedTopicProgressBar
                    lastPracticedTopicStatus={lastPracticedTopicStatus}
                    moduleType={module.type}
                    progressExceedsCurrentGoal={progressExceedsCurrentGoal}
                  />
                </CssFlex>
              </>
            )}
          </>
        )}
      </CssFlex>
      {!userHasAlreadyPracticed ? (
        <Button dataCy="start-practicing-button" variant="primary" onClick={onStartButtonClick}>
          {t("studentDashboard.continueSection.moduleCard.startButton", {
            moduleTitle: module.title,
          })}
        </Button>
      ) : (
        <Button
          dataCy="continue-practicing-button"
          variant="primary"
          onClick={onContinueButtonClick}
        >
          {t("studentDashboard.continueSection.moduleCard.continueButton")}
        </Button>
      )}
    </StyledModuleCard>
  );
};
