import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useLanguage } from "./useSelectedLanguage";

// This should match the amount of facts in the "fun-facts.json" files in the "locales" folder
const AVAILABLE_FACT_COUNT = 39;

// Returns a random fact in the language of the user
export const useRandomFact = (): string => {
  const { t } = useTranslation("fun-facts");
  const { currentLanguage } = useLanguage();

  const randomFact = useMemo(
    () => t(`${Math.floor(Math.random() * AVAILABLE_FACT_COUNT) + 1}`),
    [currentLanguage],
  );

  return randomFact;
};
