import { parseISO } from "date-fns";
import type React from "react";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEACHER_INSIGHT_PAGE_ROUTES } from "constants/routes";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { Link } from "components/Link";
import type { TableRow } from "components/SortableTable/SortableTable.model";
import type { StudentProgressOverviewProps } from "./StudentProgressOverview.model";

import { Card } from "components/Card/Card";
import { useDomainHandler } from "hooks/useDomainHandler";
import { Heading3 } from "styles/elements/Headings";
import { formatDatesAsPastDateStrings } from "../../../../../../../date-utils";
import { OutlinedLevelProgressBar } from "../OutlinedLevelProgressBar/OutlinedLevelProgressBar";
import { StyledFontAwesomeIcon, StyledSortableTable } from "./StudentProgressOverview.styled";

export const StudentProgressOverview: React.FC<StudentProgressOverviewProps> = ({
  productId,
  studentsProgress = [],
  totalStudents,
}) => {
  const { t } = useTranslation("teacher-dashboard");
  const { getStudentTermByDomain } = useDomainHandler();

  const rowData = useMemo<TableRow[]>(
    () =>
      studentsProgress.map((student) => {
        const rowLink = buildUrlWithPathParams(TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT, {
          productId,
          accountId: student.id,
        });

        return {
          id: student.id,
          link: rowLink,
          cells: [
            {
              id: `student-${student.id}-goal-reached`,
              dataCy: "student-goal-reached",
              content:
                student.avgProgress === 100 ? (
                  <StyledFontAwesomeIcon iconName="far fa-check-circle" />
                ) : (
                  <></>
                ),
            },
            {
              id: `student-${student.id}-name`,
              dataCy: `student-${student.id}-name`,
              content: `${student.lastName}, ${student.firstName} ${student.middleName || ""}`,
            },
            {
              id: `student-${student.id}-goal-level`,
              dataCy: "student-goal-level",
              content: student.goalLevel || "-",
              centerContent: true,
            },
            {
              id: `student-${student.id}-completion-rate`,
              dataCy: "student-completion-rate",
              content: (
                <OutlinedLevelProgressBar
                  showPercentage
                  currentLevel={student.avgProgress}
                  maxLevel={100}
                />
              ),
              centerContent: true,
            },
            {
              id: `student-${student.id}-last-activity`,
              dataCy: "student-last-activity",
              content: student.lastActivityDate
                ? formatDatesAsPastDateStrings(t, parseISO(student.lastActivityDate))
                : "-",
              centerContent: true,
            },
            {
              id: `student-${student.id}-actions`,
              content: (
                <Link
                  aria-label={t("basicProgress.overview.knowledgePerStudent.seeDetails")}
                  data-cy="see-detailed-user-progress-button"
                  to={rowLink}
                >
                  <FontAwesomeIcon iconName="fas fa-chevron-right" />
                </Link>
              ),
              centerContent: true,
            },
          ],
        };
      }),
    [studentsProgress],
  );

  return (
    <Card data-cy="card-students-large">
      <Heading3 fontWeight="normal" marginBottom="1rem" pl={2} pt={2}>
        {totalStudents != null ? (
          <Trans
            components={{ studentCountContainer: <span data-cy="student-count" /> }}
            i18nKey="basicProgress.overview.knowledgePerStudent.title"
            t={t}
            values={{
              studentCount: totalStudents,
              studentTerm: getStudentTermByDomain({ usePlural: true }),
            }}
          />
        ) : (
          <FontAwesomeIcon data-cy="student-count-loader" iconName="fas fa-spinner fa-spin" />
        )}
      </Heading3>
      <StyledSortableTable
        headerCells={[
          {
            id: "goal-reached",
            title: "",
            size: "3rem",
          },
          {
            id: "name",
            title: t("basicProgress.overview.knowledgePerStudent.columns.name", "Name"),
            sortable: true,
            size: "auto",
          },
          {
            id: "goal-level",
            title: t("basicProgress.overview.knowledgePerStudent.columns.goalLevel", "Goal level"),
            size: "auto",
          },
          {
            id: "topic-completion-rate",
            title: t("basicProgress.overview.knowledgePerStudent.columns.progress", "Progress"),
            content: t(
              "basicProgress.overview.knowledgePerStudent.columns.basicSkills",
              "Basic skills",
            ),
            size: "auto",
          },
          {
            id: "student-last-activity",
            title: t(
              "basicProgress.overview.knowledgePerStudent.columns.lastActivity",
              "Last activity",
            ),
            size: "auto",
          },
          {
            id: "action-buttons",
            title: "",
            size: "auto",
          },
        ]}
        rows={rowData}
        width="100%"
      />
    </Card>
  );
};
