import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { RESULT_PAGE_ROUTES } from "constants/routes";

import { CountdownTimer } from "components/CountdownTimer/CountdownTimer";
import { TestHeader } from "pages/TestEnvironment/components/TestHeader/TestHeader";
import type { ResultsHeaderProps } from "./ResultsHeader.model";

export const ResultsHeader: React.FC<ResultsHeaderProps> = ({
  title,
  testType,
  onClose,
  secondsLeft,
}) => {
  const history = useHistory();
  const { t } = useTranslation("test-environment");

  return (
    <TestHeader
      rightComponent={
        <CountdownTimer
          prefix={t("navbar.timeLeft", "Time left: ")}
          secondsLeft={secondsLeft}
          warningTimeSeconds={10 * 60}
          // Redirect user back to test results overview when review time runs out
          onCountdownFinish={() =>
            history.replace(
              buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, { testType }),
            )
          }
        />
      }
      title={title}
      onClose={onClose}
    />
  );
};
