import type React from "react";
import type { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { TEACHER_HOME_PAGE_ROUTES } from "constants/routes";
import type { UsersAPI } from "generated/types";
import { buildUrlWithPathParams } from "../../_helpers/utils/urlBuilder";
import {
  GroupCardAction,
  GroupCardFooter,
  GroupCardHeader,
  GroupCardStudentsIcon,
  GroupCardWrapper,
  StyledRedirectTrigger,
} from "./GroupCard.styled";

const truncate = (str: string, maxLength = 30) => {
  const trimmedString = str.trim();
  if (trimmedString.length > maxLength) {
    return `${trimmedString.slice(0, maxLength)}...`;
  }
  return trimmedString;
};

interface GroupCardProps extends UsersAPI.GroupListItem {
  action?: ReactElement;
  href?: string;
  isActive?: boolean;
}

export const GroupCard: React.FC<GroupCardProps> = ({
  name,
  studentCount,
  action,
  id,
  href,
  isActive = false,
}) => {
  const { t } = useTranslation("groups");

  return (
    <GroupCardWrapper $isActive={isActive} data-cy="group-card">
      {/* -- Invisible clickable area covering the whole card -- */}
      {/* (cannot make the container itself clickable, or that'd trigger "nested-interactive"
          accessibility errors when action area contains buttons or other interactive elements) */}
      <StyledRedirectTrigger
        aria-label={t("goToGroup", { name })}
        data-cy={`group-link-${id}`}
        role="button"
        tabIndex={0}
        to={href || buildUrlWithPathParams(TEACHER_HOME_PAGE_ROUTES.EDIT_GROUP, { groupId: id })}
      />
      {/* -- Visible card content -- */}
      <GroupCardHeader>
        {truncate(name)}
        {action && <GroupCardAction>{action}</GroupCardAction>}
      </GroupCardHeader>
      <GroupCardFooter>
        <GroupCardStudentsIcon iconName="group" size={1} />
        <span>{studentCount}</span>
      </GroupCardFooter>
    </GroupCardWrapper>
  );
};
