import { Button } from "components/Button";
import { InfoTooltip } from "components/InfoTooltip/InfoTooltip";
import type { ThemeProps } from "models/styles/theme";
import styled, { css } from "styled-components/macro";
import { Box } from "styles/helpers/layout";
import { FormInput } from "../FormStep.styled";

const plusIconWidth = 75;

interface DatesRowProps extends ThemeProps {
  $hasIconButton: boolean;
}

const getDatesRowGridTemplateColumns = (hasIconButton: boolean) =>
  hasIconButton
    ? css`
        grid-template-columns: 1fr 1fr 1fr ${plusIconWidth}px;
      `
    : css`
        grid-template-columns: 1fr 1fr 1fr;
      `;

export const DateRowContainer = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

export const DatesRow = styled.div<DatesRowProps>`
  display: grid;
  grid-gap: 1rem;
  ${({ $hasIconButton }: DatesRowProps) => getDatesRowGridTemplateColumns($hasIconButton)}

  &:not(:last-child) {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${(props: DatesRowProps) => props.theme.palette.neutral._500};
  }
`;

export const DatesRowColumn = styled.div`
  padding: 0;
`;

export const TestDurationInput = styled(FormInput)`
  line-height: 1.5rem;
`;

export const DatesRowAddColumn = styled(DatesRowColumn)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StudentCountRowColumn = styled(DatesRowColumn)`
  grid-column: 1 / span 2;
  padding-right: 3rem;

  input {
    width: 50%;
  }
`;

export const AddMomentButton = styled(Button)<ThemeProps>`
  margin: 1.5rem 0.5rem 2rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }: ThemeProps) => theme.palette.secondary.brand};
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  svg {
    margin-right: 0.4rem;
  }
`;

export const InlineInfoTooltip = styled(InfoTooltip)`
  display: inline-block;
  margin-left: 1rem;

  > svg > path {
    fill: ${(props: ThemeProps) => props.theme.palette.neutral._500};
  }
`;

export const StyledRecommendedDuration = styled.div<ThemeProps>`
  text-align: right;
  > p {
    color: ${({ theme }: ThemeProps) => theme.palette.secondary.brand};
    margin-top: 0.2rem;

    a {
      color: ${({ theme }: ThemeProps) => theme.palette.secondary.brand};
      text-decoration: none;
    }

    > i {
      margin-left: 0.5rem;
    }
  }
`;

export const StyledTeacherNoSupportWarning = styled(Box)<ThemeProps>`
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: ${({ theme }: ThemeProps) => theme.palette.yellow._999}; 
  display: flex;
  align-items: center;
  h4 {
    margin-left: 1rem;
  }
`;
