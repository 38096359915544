import { CorrectTextQuestionWordState, type WordAction } from "models/exam/CorrectTextQuestion";
import type { AnswerResult, CorrectTextAnswer } from "models/exam/Exam";
import { useMemo, useState } from "react";
import { wordResultsToWordState } from "../helpers/questionResults";

interface UseHoveredWordProps {
  results?: AnswerResult[];
  getWordAction: (wordId: number) => WordAction;
  getWordUpdatedValue: (wordId: number) => string;
}

interface UseHoverWordHook {
  hoverWordId?: number;
  setHoverWordId: (wordId: number | undefined) => void;
  hoverWordState: CorrectTextQuestionWordState;
  hoverWordSolution?: CorrectTextAnswer;
}

export const useHoveredWord = ({
  results,
  getWordAction,
  getWordUpdatedValue,
}: UseHoveredWordProps): UseHoverWordHook => {
  // move hoverWord to useHoverWord?
  const hasResults = results && results.length > 0;
  const [hoverWordId, setHoverWordId] = useState<number | undefined>();
  const hoverWordState = useMemo<CorrectTextQuestionWordState>(() => {
    if (!hoverWordId || !hasResults) {
      return CorrectTextQuestionWordState.NONE;
    }
    return wordResultsToWordState(
      {
        wordId: hoverWordId,
        wordAction: getWordAction(hoverWordId),
        wordValue: getWordUpdatedValue(hoverWordId),
      },
      results,
    );
  }, [hasResults, hoverWordId, results, getWordAction, getWordUpdatedValue]);

  const hoverWordSolution = useMemo<CorrectTextAnswer | undefined>(() => {
    let hoverWordAnswer: CorrectTextAnswer | undefined;
    if (hoverWordId && hasResults) {
      const wordResult = results?.find((result) => result.order === hoverWordId);
      if (wordResult) {
        const [solution] = wordResult.validAnswers || [];
        if (solution) {
          hoverWordAnswer = {
            actionType: solution.actionType,
            value: solution.value,
            wordId: wordResult.order,
          } as CorrectTextAnswer;
        }
      }
    }
    return hoverWordAnswer;
  }, [hasResults, hoverWordId, results]);

  return {
    hoverWordId,
    setHoverWordId,
    hoverWordState,
    hoverWordSolution,
  };
};
