import styled, { css } from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { CssFlex } from "styles/helpers/layout";

export type EncircledIconSize = "default" | "small" | "medium";
export type EncircledIconVariant = "default" | "primary" | "secondary";

interface Props extends ThemeProps {
  $variant: EncircledIconVariant;
  $size: EncircledIconSize;
}

export const StyledIconContainer = styled(CssFlex)<Props>`
  ${(props: Props) => {
    switch (props.$size) {
      case "small":
        return css`
          width: 1.5rem;
          height: 1.5rem;
          font-size: ${(props: ThemeProps) => props.theme.font.size._14};
        `;
      case "medium":
        return css`
          width: 2rem;
          height: 2rem;
          font-size: ${(props: ThemeProps) => props.theme.font.size._16};
        `;
      default:
        return css`
          width: 3rem;
          height: 3rem;
          font-size: ${(props: ThemeProps) => props.theme.font.size._18};
        `;
    }
  }}

  border-radius: 50%;
  align-items: center;
  justify-content: center;

  ${(props: Props) => {
    switch (props.$variant) {
      case "primary":
        return css`
          background: ${props.theme.palette.primary._900};
          color: ${props.theme.palette.primary.brand};
        `;
      case "secondary":
        return css`
          background: ${props.theme.palette.secondary._900};
          color: ${props.theme.palette.secondary.brand};
        `;
      default:
        return css`
          background: ${props.theme.palette.neutral._950};
          color: ${props.theme.palette.neutral._500};
        `;
    }
  }}
`;
