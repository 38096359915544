import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

export interface StyledTextProps extends ThemeProps {
  isHighlighted: boolean;
}

export const StyledHeadingWrapper = styled.div<ThemeProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

export const StyledHeadingDescription = styled.span<ThemeProps>`
  margin-right: 1rem;
  font-weight: 400;
  font-size: ${(props: ThemeProps) => props.theme.font.size._12};
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
`;

export const StyledTextWrapper = styled.div<ThemeProps>`
  max-height: 40rem;
  overflow-y: auto;
  overflow-wrap: break-word;
  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._950};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._600};
  }
`;

export const StyledText = styled.div<ThemeProps>`
  padding-right: 1rem;
`;

export const StyledInnerText = styled.div<StyledTextProps>`
  margin-bottom: 2rem;
  border-left: 1px solid
    ${({ isHighlighted, theme }: StyledTextProps) =>
      isHighlighted ? theme.palette.primary.brand : theme.palette.neutral._900};
  padding-left: 1rem;
`;
