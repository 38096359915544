import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledSkillDeterminationTest = styled.div<Props>`
  background-color: #f5f7f9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  :first-child {
    display: flex;
    justify-content: center;
  }
`;
