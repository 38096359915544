import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

export const StyledTooltip = styled(ReactTooltip)<ThemeProps>`
  padding: 1rem !important;
  width: fit-content;
  max-width: 20rem;

  z-index: 2000 !important;
  opacity: 0.9 !important;
  color: ${(props: ThemeProps) => props.theme.palette.neutral.white} !important;
  background: ${(props: ThemeProps) => props.theme.palette.neutral.black} !important;

  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  text-align: center;
  font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold} !important;

  ::after {
    display: none;
  }
`;
