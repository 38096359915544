import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { ParagraphLarge } from "styles/elements/Texts";
import { Box } from "styles/helpers/layout";

export interface StyledSubTitleProps extends Props {
  variant?: "thin" | "thick";
}

export const StyledCard = styled(Box)<Props>`
  background: #fdfdfd;
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._900};
  border-radius: 4px;
`;

export const StyledTitle = styled(ParagraphLarge)`
  color: ${({ theme }: Props) => theme.palette.neutral._500};
  font-weight: 700;
`;

export const StyledSubTitle = styled(ParagraphLarge)<StyledSubTitleProps>`
  color: ${({ theme }: StyledSubTitleProps) => theme.palette.neutral.black};
  font-weight: ${({ variant }: StyledSubTitleProps) => (variant === "thick" ? "700" : "400")};
`;

export const StyledHr = styled.hr<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral.black};
`;
