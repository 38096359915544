import type React from "react";
import { useTranslation } from "react-i18next";

import { SvgIconReact } from "components/SvgIconReact";
import { Heading3, Heading4 } from "styles/elements/Headings";
import type { TestHeaderProps } from "./TestHeader.model";
import {
  StyledCenterContent,
  StyledCloseIconButton,
  StyledRightContent,
  StyledTestHeader,
} from "./TestHeader.styled";

export const TestHeader: React.FC<TestHeaderProps> = ({
  dataCy,
  children,
  rightComponent,
  title,
  subtitle,
  showCloseButton,
  onClose,
}) => {
  const { t } = useTranslation("common");

  return (
    <StyledTestHeader data-cy={dataCy}>
      <div>
        {title && <Heading3>{title}</Heading3>}
        {subtitle && <Heading4>{subtitle}</Heading4>}
      </div>
      <StyledCenterContent>{children}</StyledCenterContent>
      <StyledRightContent>
        {rightComponent}
        {showCloseButton && (
          <StyledCloseIconButton
            aria-label={t("iconButtonLabel.close")}
            data-cy="close-test-button"
            onClick={onClose}
          >
            <SvgIconReact iconName="closeX" />
          </StyledCloseIconButton>
        )}
      </StyledRightContent>
    </StyledTestHeader>
  );
};
