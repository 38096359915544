import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";

import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProfile } from "hooks/useProfile";

import { useDomainHandler } from "hooks/useDomainHandler";

import { getSupportWidgetSettings, getSupportWidgetUrl } from "./config";
import type { ZendeskWidgetWindow } from "./models/ZendeskWidget.model";

const SupportWidget = observer(() => {
  const widgetWindow = window as ZendeskWidgetWindow;
  const { getSupportWidgetVariantByDomain } = useDomainHandler();
  const widgetVariant = getSupportWidgetVariantByDomain();

  const { userDetails } = useProfile();
  const { showSupportWidget } = useFeatureFlags();

  // Fetch specific settings for the widget depending on the role of the user
  // This will be "null" for users that do not need to see the widget (like admins)
  // or if the user is still not logged in (in which case we don't know if they should
  // see the teacher or student version of the widget)
  const widgetSettings = useMemo(
    () =>
      showSupportWidget && userDetails
        ? getSupportWidgetSettings(widgetVariant, userDetails.role)
        : null,
    [widgetVariant, userDetails, showSupportWidget],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Man this is too risky, but you're right
  useEffect(() => {
    // If we cannot find any specific config for the widget, we skip its creation
    if (!widgetSettings || !widgetWindow) {
      return;
    }

    // Dynamically create script tag for support widget
    const widgetScript = document.createElement("script");

    widgetScript.src = getSupportWidgetUrl(widgetVariant);
    widgetScript.async = true;
    widgetScript.setAttribute("id", "ze-snippet");

    widgetScript.onload = () => {
      // Configure widget settings depending on current user role
      const { options, suggestions } = widgetSettings;
      widgetWindow.zESettings = options;

      if (widgetWindow.zE) {
        widgetWindow.zE("webWidget", "setLocale", "nl");
        widgetWindow.zE("webWidget", "helpCenter:setSuggestions", suggestions);
      }
    };

    document.body.appendChild(widgetScript);

    return () => {
      document.body.removeChild(widgetScript);
    };
  }, [widgetVariant, widgetSettings]);

  return null;
});

export default SupportWidget;
