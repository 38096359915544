import { CorrectTextQuestionWord } from "components/CorrectTextQuestionWord/CorrectTextQuestionWord";
import { EditWordInput } from "components/EditWordInput/EditWordInput";
import { EmbeddedHtml } from "components/EmbeddedHtml";
import type { Node } from "components/EmbeddedHtml/EmbeddedHtml.model";
import { commonSanitizeOptions } from "layouts/QuestionManager/QuestionManager.constants";
import {
  CorrectTextQuestionWordState,
  CorrectTextViewMode,
  WordAction,
} from "models/exam/CorrectTextQuestion";
import type { ValidAnswer } from "models/exam/Exam";
import type React from "react";
import { type ReactNode, useCallback } from "react";
import { getWordElementId } from "../../helpers/helpers";
import { wordResultsToWordState } from "../../helpers/questionResults";
import type { CorrectTextQuestionEmbeddedHtmlProps } from "./CorrectTextQuestionEmbeddedHtml.model";

const sanitizeOptions = {
  ...commonSanitizeOptions,
  allowedAttributes: {
    ...commonSanitizeOptions.allowedAttributes,
    span: ["id"],
  },
  allowedClasses: {
    ...commonSanitizeOptions.allowedClasses,
    span: ["verbeter_tekst"],
  },
};

export const CorrectTextQuestionEmbeddedHtml: React.FC<CorrectTextQuestionEmbeddedHtmlProps> = ({
  questionId,
  editLimitReached = false,
  htmlContent,
  viewMode,
  selectedWordId,
  selectedWordAction,
  results,
  onWordReplace,
  onEditWordOk,
  onEditWordCancel,
  onWordClick,
  onWordMouseOver,
  onWordMouseOut,
  getWordAction,
  getWordUpdatedValue,
}) => {
  const replaceFunction = useCallback(
    ({ tagType, attributes, innerText }: Node): ReactNode | undefined => {
      if (tagType !== "span" || attributes.class !== "verbeter_tekst") {
        return undefined;
      }
      if (!innerText || ["", "&nbsp;"].includes(innerText)) {
        return <></>;
      }
      const hasResults = !!(results && results.length > 0);
      const wordId = Number.parseInt(attributes.id, 10);
      const wordAction = getWordAction(wordId);
      onWordReplace(wordId, innerText);

      const updatedWordValue = wordAction === WordAction.NONE ? "" : getWordUpdatedValue(wordId);
      const showWord = !(selectedWordId === wordId && selectedWordAction === WordAction.EDIT_WORD);
      const showEditWordInput =
        !hasResults &&
        selectedWordId === wordId &&
        (selectedWordAction === WordAction.ADD_WORD_AFTER ||
          selectedWordAction === WordAction.EDIT_WORD);
      let state =
        wordAction === WordAction.NONE
          ? CorrectTextQuestionWordState.NONE
          : CorrectTextQuestionWordState.CHANGED;

      if (viewMode !== CorrectTextViewMode.COMPARE && hasResults) {
        state = wordResultsToWordState(
          {
            wordId,
            wordAction,
          },
          results,
        );
      } else if (viewMode !== CorrectTextViewMode.COMPARE && selectedWordId === wordId) {
        state = CorrectTextQuestionWordState.ACTIVE;
      }

      const hoverEnabled = hasResults && state !== CorrectTextQuestionWordState.NONE;
      let textContent = innerText;
      const rightAnswers = results?.find((answer) => answer.order === wordId)?.validAnswers || [];

      // Show right solutions when viewing results, and user submitted answer otherwise
      if (rightAnswers.length > 0 && viewMode !== CorrectTextViewMode.COMPARE) {
        const [firstRightAnswer] = rightAnswers;

        // For deleted words, we need to preserve the original text
        if (firstRightAnswer.actionType !== WordAction.REMOVE_WORD) {
          textContent = rightAnswers
            .map((validAnswer: ValidAnswer) => validAnswer.value)
            .join(" / ");
        } else if (state === CorrectTextQuestionWordState.ANSWER_MISSED) {
          state = CorrectTextQuestionWordState.ANSWER_MISSED_DELETE;
        }
      } else if (updatedWordValue && viewMode !== CorrectTextViewMode.RESULTS) {
        textContent = updatedWordValue;
      }

      const interactive =
        !editLimitReached ||
        wordAction !== WordAction.NONE ||
        state !== CorrectTextQuestionWordState.NONE;
      return (
        <>
          {showWord && (
            <CorrectTextQuestionWord
              key={wordId}
              disabled={!interactive}
              id={getWordElementId(questionId, wordId)}
              state={state}
              wordAction={wordAction}
              onClick={() => interactive && onWordClick(wordId, innerText)}
              onMouseOut={hoverEnabled ? () => onWordMouseOut(wordId) : undefined}
              onMouseOver={hoverEnabled ? () => onWordMouseOver(wordId) : undefined}
            >
              {textContent}
            </CorrectTextQuestionWord>
          )}
          {showEditWordInput && selectedWordAction === WordAction.EDIT_WORD && (
            <EditWordInput
              defaultValue={innerText}
              onCancel={() => onEditWordCancel()}
              onOK={(value) => onEditWordOk(value)}
            />
          )}
          {showEditWordInput && selectedWordAction === WordAction.ADD_WORD_AFTER && (
            <EditWordInput
              onCancel={() => onEditWordCancel()}
              onOK={(value) => onEditWordOk(`${innerText} ${value}`)}
            />
          )}
        </>
      );
    },
    [
      getWordAction,
      getWordUpdatedValue,
      onEditWordCancel,
      onEditWordOk,
      onWordClick,
      onWordMouseOut,
      onWordMouseOver,
      onWordReplace,
      results,
      selectedWordAction,
      selectedWordId,
      viewMode,
    ],
  );

  return (
    <EmbeddedHtml
      rawHtml={htmlContent}
      replaceFunction={replaceFunction}
      sanitizeOptions={sanitizeOptions}
      tagName="section"
    />
  );
};
