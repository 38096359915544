import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import type { ScheduledTestResultList } from "models/exam/ScheduledTest";

import { useDomainHandler } from "hooks/useDomainHandler";
import { useScheduledTests } from "hooks/useScheduledTests";

import { SvgIconReact } from "components/SvgIconReact";

import { PurchasePrompt } from "../PurchasePrompt/PurchasePrompt";
import { ScheduledTestResultsTable } from "../ScheduledTestResultsTable/ScheduledTestResultsTable";
import { ScheduledTestResultsTableByProduct } from "../ScheduledTestResultsTableByProduct/ScheduledTestResultsTableByProduct";

import {
  StyledContainer,
  StyledNotice,
  StyledTitle,
} from "./ScheduledTestResultsPageLayout.styled";

interface Props {
  title: string;
  testResults: ScheduledTestResultList[] | null;
  noResultsMessage: string;
  dataCy?: string;
  ownedProducts?: boolean;
}

export const ScheduledTestResultsPageLayout: React.FC<Props> = observer(
  ({ title, testResults = [], noResultsMessage, dataCy, ownedProducts = true }) => {
    const { t } = useTranslation("results");
    const { isBusinessDomain } = useDomainHandler();

    const {
      fetchScheduledTestsReviewPeriodTimer,
      scheduledTestsReviewPeriodTimer,
      setScheduledTestsReviewPeriodTimer,
    } = useScheduledTests();

    useEffect(() => {
      fetchScheduledTestsReviewPeriodTimer();

      const interval = setInterval(() => {
        fetchScheduledTestsReviewPeriodTimer();
      }, 10000);

      return () => {
        clearInterval(interval);
        setScheduledTestsReviewPeriodTimer([]);
      };
    }, [fetchScheduledTestsReviewPeriodTimer]);

    useEffect(() => {
      const interval = setInterval(() => {
        if (scheduledTestsReviewPeriodTimer && scheduledTestsReviewPeriodTimer.length > 0) {
          setScheduledTestsReviewPeriodTimer(
            scheduledTestsReviewPeriodTimer.map((timer) => ({
              ...timer,
              counter: timer.counter - 1,
            })),
          );
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    });

    const latestScore = testResults?.[0]?.score || 0;
    const hasResultsAvailable = testResults && testResults?.length > 0;
    const hasPassedLatest = testResults?.[0]?.hasPassed;

    // TODO: We should check with Jiska or Martin whether the group by product is wanted in both
    // scenarios, not only when you have no licenses. This differentiation made sense before because
    // this page was product scoped, but not anymore: user will always see the list of all their tests,
    // not just for the one they've selected in the dashboard.
    const renderScheduledTestResultsTable = (hasOwnedProducts: boolean) => {
      if (!hasOwnedProducts) {
        return (
          <ScheduledTestResultsTableByProduct
            data={testResults || []}
            timers={scheduledTestsReviewPeriodTimer || []}
          />
        );
      }
      return (
        <ScheduledTestResultsTable
          data={testResults || []}
          timers={scheduledTestsReviewPeriodTimer || []}
        />
      );
    };

    return (
      <StyledContainer data-cy={dataCy}>
        <StyledTitle>{title}</StyledTitle>
        <StyledNotice>
          {hasPassedLatest && (
            <span data-cy="medal-icon">
              <SvgIconReact iconName="medal" size={3} />
            </span>
          )}
          {hasResultsAvailable ? (
            <span data-cy="latest-score-message">
              {t(
                "latestScoreNotice",
                `You scored an average of ${Math.round(latestScore)}% on your last test!`,
                {
                  score: Math.round(latestScore),
                },
              )}
            </span>
          ) : (
            <span data-cy="no-results-message">{noResultsMessage}</span>
          )}
        </StyledNotice>
        {/* Show message prompting users to buy a license (only for non-business users) */}
        {!isBusinessDomain() && <PurchasePrompt parentDataCy={dataCy} />}
        {/* Display table with all test results (if any are available) */}
        {hasResultsAvailable && renderScheduledTestResultsTable(ownedProducts)}
      </StyledContainer>
    );
  },
);
