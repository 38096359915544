import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";

import { useScheduledTests } from "hooks/useScheduledTests";
import { useTestStatusAndTimeLeft } from "hooks/useTestStatusAndTimeLeft";

import { LevelDashGroup } from "components/LevelDashGroup";
import { LoadingSpinner } from "components/LoadingSpinner";
import { StatusBanner } from "components/StatusBanner/StatusBanner";

import { Heading2 } from "styles/elements/Headings";

import { ResultsBreadcrumbs } from "../../components/ResultsBreadcrumbs/ResultsBreadcrumbs";
import { ResultsHeader } from "../../components/ResultsHeader/ResultsHeader";

import type { TestResultsPagePathParams } from "./TestResultsPage.model";

import { modalIds } from "constants/modals-constants";
import { Formik, type FormikHelpers } from "formik";
import { useModal } from "hooks/useModal";
import { RequestPasswordModal } from "pages/TestEnvironment/LandingPage/components/PasswordModal/PasswordModal";
import type { TestAccessFormFields } from "pages/TestEnvironment/LandingPage/components/TestAccessForm/TestAccessForm.schema";
import {
  StyledContainer,
  StyledExerciseList,
  StyledExerciseListItem,
  StyledProgressBar,
} from "./TestResultsPage.styled";

const getExerciseResultsPageUrl = (code: string, exerciseId: number) =>
  buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_RESULTS_PAGE, {
    code,
    exerciseId,
  });

export const TestResultsPage: React.FC = observer(() => {
  const history = useHistory();
  const { toggleModal, closeModal } = useModal();
  const { t } = useTranslation("test-environment");
  const { code }: TestResultsPagePathParams = useParams();
  const {
    scheduledTestScore,
    fetchScheduledTestScore,
    fetchScheduledTestContent,
    scheduledTestContent,
    scheduledTestStatusAndTimeLeft,
    setAuthTestCode,
    authTestCode,
  } = useScheduledTests();

  useEffect(() => {
    if (!scheduledTestContent) {
      fetchScheduledTestContent(code).then(({ accessGranted }) => {
        if (!accessGranted) {
          return toggleModal(modalIds.passwordRequiredModal);
        }
        fetchScheduledTestScore(code);
        setAuthTestCode(code);
      });
    }
  }, [code, scheduledTestContent]);

  useTestStatusAndTimeLeft(code);

  const onFormSubmit = (
    values: TestAccessFormFields,
    actions: FormikHelpers<TestAccessFormFields>,
  ) => {
    fetchScheduledTestContent(values.code, values.password).then((data) => {
      actions.resetForm();

      // Fetch scores and close modal if user entered the right password
      if (data.accessGranted) {
        fetchScheduledTestScore(code).then((success) => {
          if (!success) {
            history.go(-1);
          }
        });

        setAuthTestCode(code);
        closeModal();
      }
    });
  };

  const isAuthorizedToReview = authTestCode === code;

  // If data is still loading and / or we haven't verified user has access to the test,
  // render loader and modal to request password if necessary
  if (!scheduledTestContent || !scheduledTestScore || !isAuthorizedToReview) {
    return (
      <>
        <LoadingSpinner isFullScreen />
        <Formik initialValues={{ code: code, password: "" }} onSubmit={onFormSubmit}>
          <RequestPasswordModal variant="enterScheduleTestResults" onClose={() => history.go(-1)} />
        </Formik>
      </>
    );
  }

  return (
    <>
      <ResultsHeader
        secondsLeft={scheduledTestStatusAndTimeLeft?.counter || 0}
        testType={scheduledTestScore.type}
        title={scheduledTestScore.title}
      />
      <StyledContainer>
        <ResultsBreadcrumbs dataCy="test-results-page-breadcrumbs" />
        <Heading2>{t("testResults.title", "Test results")}</Heading2>
        <StatusBanner
          dataCy="test-status-banner"
          percentage={Math.round(scheduledTestScore.score)}
          status="info"
          title={t("testResults.testCompleted", "You have completed the test!")}
        />
        <StyledExerciseList>
          {scheduledTestContent?.exercises?.map((exercise) => {
            const exerciceTestScore =
              scheduledTestScore.exercises.find((scoreExercise) => scoreExercise.id === exercise.id)
                ?.score || 0;
            return (
              <StyledExerciseListItem
                key={exercise.id}
                dataCy="test-results-exercise-item"
                extraData={
                  <StyledProgressBar>
                    <LevelDashGroup dashSize={1} level={exerciceTestScore} numberOfDashes={5} />
                    {`${Math.round(exerciceTestScore)}%`}
                  </StyledProgressBar>
                }
                href={getExerciseResultsPageUrl(code, exercise.id)}
                title={exercise.title}
              />
            );
          })}
        </StyledExerciseList>
      </StyledContainer>
    </>
  );
});
