import { observer } from "mobx-react";
import queryString from "query-string";
import type React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { type URLFields, buildURLwithQueryParams } from "_helpers/utils/urlBuilder";

import { Paginator } from "components/Paginator/Paginator";

import {
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_ROWS_PER_PAGE,
  PAGE_FILTER_KEY,
} from "constants/misc-constants";
import { ADMIN_ENVIRONMENT_ROUTES } from "constants/routes";

import { useAdmin } from "hooks/useAdmin";
import useOnLocationChange from "hooks/useOnLocationChange";

import type { AdminSchoolsQueryParams } from "./SchoolsPage.model";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { useCurrentPathMatch } from "hooks/useCurrentPathMatch";
import { SchoolType } from "models/auth/Login";
import { CssFlex } from "styles/helpers/layout";
import { useDebounce } from "use-debounce";
import { StyledInput, StyledLink, StyledSchoolsPage } from "./SchoolsPage.styled";
import { SchoolsTable } from "./components/SchoolsTable/SchoolsTable";

function calculateTotalPages(totalResults: number, pageSize: number): number {
  return Math.ceil(totalResults / pageSize);
}

export const SchoolsPage: React.FC = observer(() => {
  const { t } = useTranslation("admin-environment");
  const history = useHistory();
  const location = useLocation();
  const { schoolList: schools, fetchCompanyList, fetchSchoolList, setSchoolList } = useAdmin();
  const { queryParams } = useOnLocationChange(history);
  const { currentRouteMatches } = useCurrentPathMatch();

  // Define which variant of the page we're seeing based on the route
  // This will determine whether we should filter results by school or company type
  const variant: SchoolType = currentRouteMatches(ADMIN_ENVIRONMENT_ROUTES.COMPANY_LIST)
    ? SchoolType.COMPANY
    : SchoolType.SCHOOL;

  const { input = "", page = 1 }: AdminSchoolsQueryParams = queryString.parse(queryParams);

  const [debouncedInput] = useDebounce(input, DEFAULT_DEBOUNCE_TIME);

  const paginationFilters = {
    input: debouncedInput,
    page,
  };

  const currentPath = location.pathname;

  // Load school or company list depending on the current page variant
  useEffect(() => {
    if (variant === SchoolType.SCHOOL) {
      fetchSchoolList({ input: debouncedInput });
    } else {
      fetchCompanyList({ input: debouncedInput });
    }
  }, [debouncedInput, fetchSchoolList, fetchCompanyList, variant]);

  // Reset school list in the store when switching variant
  // biome-ignore lint/correctness/useExhaustiveDependencies: we want this hook called when variant changes
  useEffect(() => {
    setSchoolList([]);
  }, [variant, setSchoolList]);

  const filters = Object.keys(paginationFilters).map((key) => ({
    key,
    value: paginationFilters[key as keyof typeof paginationFilters],
  })) as URLFields[];

  const totalPages = calculateTotalPages(schools.length || 0, DEFAULT_ROWS_PER_PAGE);

  // Generate URLs for pagination links
  const paginatorLinks = useMemo(
    () =>
      [...Array(totalPages)].map((_value, filterPage: number) => {
        const newFilters = filters;
        newFilters[newFilters.findIndex((newFilter) => newFilter.key === "page")].value =
          filterPage + 1;
        const searchURL = buildURLwithQueryParams(currentPath, newFilters);
        return {
          path: searchURL,
          title: filterPage + 1,
        };
      }),
    [currentPath, totalPages, filters],
  );

  // Append filter values to page URL
  const onFilterChange = (filterValue: string | number, key: string) => {
    const newFilters = filters
      .map((filter) => {
        if (filter.key === key) {
          return { ...filter, value: encodeURIComponent(filterValue) };
        }
        if (filter.key === PAGE_FILTER_KEY && key !== PAGE_FILTER_KEY) {
          return { key: PAGE_FILTER_KEY, value: 1 };
        }
        return filter;
      })
      .filter((newFilter) => newFilter.value);

    history.push(buildURLwithQueryParams(currentPath, newFilters));
  };

  const setSearchValue = (searchValue: string) => {
    onFilterChange(searchValue, "input");
  };

  return (
    <StyledSchoolsPage>
      <CssFlex justifyContent="space-between">
        <StyledInput
          data-cy="schools-filter-input"
          id="input"
          value={input}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          placeholder={
            variant === SchoolType.SCHOOL
              ? t("schoolList.searchInput.label", "Search for schools")
              : t("companyList.searchInput.label", "Search for companies")
          }
          aria-label={
            variant === SchoolType.SCHOOL
              ? t("schoolList.searchInput.label", "Search for schools")
              : t("companyList.searchInput.label", "Search for companies")
          }
        />
        <StyledLink
          borderVariant="rounded"
          data-cy="create-school-button"
          to={{
            pathname:
              variant === SchoolType.SCHOOL
                ? ADMIN_ENVIRONMENT_ROUTES.SCHOOL_CREATE
                : ADMIN_ENVIRONMENT_ROUTES.COMPANY_CREATE,
          }}
          variant="button"
        >
          <FontAwesomeIcon iconName="fas fa-plus" />
          {variant === SchoolType.SCHOOL
            ? t("schoolList.newSchoolButton.label", "Create new school")
            : t("companyList.newSchoolButton.label", "Create new company")}
        </StyledLink>
      </CssFlex>
      {schools && <SchoolsTable page={Number(page)} variant={variant} />}
      <Paginator
        currentPage={Number(paginationFilters.page)}
        links={paginatorLinks}
        totalPages={totalPages}
        onChangePage={(selectedPage) => onFilterChange(selectedPage, "page")}
      />
    </StyledSchoolsPage>
  );
});
