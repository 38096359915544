import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";

export const StyledContainer = styled.div`
  > * {
    text-align: center;
  }
`;

export const StyledIconContainer = styled.div`
  width: fit-content;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 50%;
  background: ${(props: Props) => props.theme.palette.feedback.success._950};
`;
