import type { Node } from "components/EmbeddedHtml/EmbeddedHtml.model";
import { QuestionStatus } from "constants/exam-constants";
import { domToReact } from "html-react-parser";
import { commonSanitizeOptions } from "layouts/QuestionManager/QuestionManager.constants";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import type { SelectTextQuestionProps, SelectedAnswerType } from "./SelectTextQuestion.model";

import { useAnswer } from "hooks/useAnswer";
import type { QuestionsManagerPathParams } from "../../QuestionManager.model";
import {
  StyledQuestionIntroText,
  StyledQuestionText,
  StyledSelectTextQuestion,
  StyledSelectTextWord,
} from "./SelectTextQuestion.styled";

const sanitizeOptions = {
  ...commonSanitizeOptions,
  allowedAttributes: {
    ...commonSanitizeOptions.allowedAttributes,
    span: ["id"],
  },
  allowedClasses: {
    span: ["sleep_woord"],
  },
};

export const SelectTextQuestion: React.FC<SelectTextQuestionProps> = ({
  answerFeedbackComponent: answerComponent,
  evaluateAnswer,
  status,
  question,
  results,
  answerOptions,
  isEvaluatingAnswer,
  prevAnswerValues,
}) => {
  const { t } = useTranslation("skill-determination-test");
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const { setAnswerButtonProps } = useAnswer();
  const setExerciseIdValue = !exerciseId ? undefined : +exerciseId;

  const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswerType[]>([]);

  // Reset selected answer when question changes
  useEffect(() => {
    setSelectedAnswers([]);
  }, [question]);

  const submitAnswer = (skipAnswerValidation?: boolean) => {
    const parsedAnswers = selectedAnswers.map((answer) => ({
      order: answer.id,
      value: answer.content,
    }));
    evaluateAnswer?.(
      +productId,
      question.id,
      parsedAnswers,
      setExerciseIdValue,
      skipAnswerValidation,
    );
  };

  // Set configuration for answer button
  useEffect(() => {
    setAnswerButtonProps({
      label: t("button.checkAnswer.label", "Check answer"),
      isDisabled: !selectedAnswers.length,
      isHidden: status !== QuestionStatus.INITIAL,
      isLoading: isEvaluatingAnswer || false,
      onClick: submitAnswer,
    });
  }, [isEvaluatingAnswer, selectedAnswers, status, productId, exerciseId]);

  useEffect(() => {
    if (selectedAnswers.length === 0) {
      return;
    }
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        submitAnswer();
      }
    };
    document.addEventListener("keypress", onKeyPress);
    return () => document.removeEventListener("keypress", onKeyPress);
  }, [selectedAnswers]);

  // Return status for an specific answer based on the obtained results
  const getAnswerStatus = (answerId: number | string, isSelected: boolean): QuestionStatus => {
    if (status === QuestionStatus.INITIAL) {
      return QuestionStatus.INITIAL;
    }

    // Verify if answer is among the accepted solutions
    const isAnswerValid = !!results?.find((result) =>
      result.validAnswers?.find((answer) => answer.value.toString() === answerId.toString()),
    );

    if (isAnswerValid) {
      return isSelected ? QuestionStatus.CORRECT : QuestionStatus.MISSED;
    }

    return isSelected ? QuestionStatus.INCORRECT : QuestionStatus.INITIAL;
  };

  const replaceFunction = ({ tagType, attributes, children }: Node) => {
    const { id: index, class: className } = attributes;
    if (tagType === "span" && className === "sleep_woord") {
      const optionId = answerOptions.find(({ order }) => order === +index)?.id || -1;
      const isSelected = selectedAnswers.some((selectedAnswer) => selectedAnswer.id === +index);

      const prevAnswerId =
        prevAnswerValues &&
        Object.values(prevAnswerValues).find((obj) => +obj.value === optionId && obj)?.value;

      return (
        <StyledSelectTextWord
          className={
            prevAnswerId && +prevAnswerId === +optionId && selectedAnswers.length === 0
              ? "submitted"
              : ""
          }
          data-cy={`select-text-question-field-${question.id}-${index}`}
          selected={isSelected}
          status={getAnswerStatus(optionId, isSelected)}
          onClick={() => {
            if (status === QuestionStatus.INITIAL) {
              if (!isSelected) {
                // Add answer to current selection
                setSelectedAnswers([...selectedAnswers, { id: +index, content: optionId }]);
              } else {
                // Remove answer from current selection
                setSelectedAnswers(
                  selectedAnswers.filter((selectedAnswer) => selectedAnswer.id !== +index),
                );
              }
            }
          }}
        >
          {domToReact(children)}
        </StyledSelectTextWord>
      );
    }
  };

  return (
    <StyledSelectTextQuestion status={status}>
      {question.introduction && (
        <StyledQuestionIntroText
          rawHtml={question.introduction}
          replaceFunction={replaceFunction}
          sanitizeOptions={sanitizeOptions}
        />
      )}

      <StyledQuestionText
        rawHtml={question.content}
        replaceFunction={replaceFunction}
        sanitizeOptions={sanitizeOptions}
      />
      {answerComponent}
    </StyledSelectTextQuestion>
  );
};
