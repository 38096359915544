import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { type FC, useState } from "react";
import { useTheme } from "styled-components/macro";
import { Box, CssClickableIcon, CssFlex, CssGrid } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import { StyledSnackBar } from "./SnackBar.styled";

export interface SnackBarProps {
  text: string;
}

export const SnackBar: FC<SnackBarProps> = ({ text }) => {
  const [showSnackBar, setShowSnackBar] = useState(true);
  const hogesTheme = useTheme() as ElearningThemeInterface;

  if (!showSnackBar) return <></>;

  return (
    <Box data-cy="snackbar" margin="0 auto" minHeight="3rem" width="50%">
      <StyledSnackBar
        backColor={hogesTheme.palette.secondary._950}
        color={hogesTheme.palette.secondary.brand}
      >
        <CssGrid gridGap={2} gridTemplateColumns="auto 1fr auto" padding={1}>
          <CssFlex alignItems="center" marginLeft={1} width="5%">
            <FontAwesomeIcon iconName="fas fa-info-circle" iconSize="2x" />
          </CssFlex>
          <div>{text}</div>
          <div>
            <CssClickableIcon
              data-cy="close-snackbar"
              iconName="fas fa-times"
              onClick={() => setShowSnackBar(false)}
            />
          </div>
        </CssGrid>
      </StyledSnackBar>
    </Box>
  );
};
