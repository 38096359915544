import { copyTextToClipboard } from "_helpers/clipboard";
import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { LoadingSpinner } from "components/LoadingSpinner";
import { SeparatorLine } from "components/SeparatorLine";
import { ShowPasswordModal } from "components/ShowPasswordModal";
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from "constants/routes";
import { useScheduledTests } from "hooks/useScheduledTests";
import { observer } from "mobx-react";
import { ScheduledTestStatus, type ScheduledTestSummary } from "models/exam/ScheduledTest";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  StyledScheduledTestsButton,
  StyledScheduledTestsHeader,
  StyledScheduledTestsLoader,
  StyledScheduledTestsPage,
  StyledScheduledTestsParagraph,
  StyledViewAllButton,
} from "./ScheduledTestsPage.styled";
import { ScheduledTestItem } from "./components/ScheduledTestItem";

export const ScheduledTestsPage: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation("scheduled-tests");
  const { fetchScheduledTests, setScheduledTest, scheduledTests } = useScheduledTests();
  const [passwordCopied, setPasswordCopied] = useState(false);

  const onCopyPassword = (password: string) => {
    copyTextToClipboard(password);
    setPasswordCopied(true);
  };

  useEffect(() => {
    // make sure no scheduled test is loaded so the form is empty when the user wants to create a new one
    setScheduledTest(null);
    fetchScheduledTests(ScheduledTestStatus.UPCOMING);
  }, []);

  const onGoToCreateTestWizard = () => {
    history.push(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_REQUEST);
  };

  const editScheduledTest = (scheduledTest: ScheduledTestSummary) => {
    // go to scheduled test edit page with scheduledTestId
    history.push(
      buildUrlWithPathParams(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_EDIT_REQUEST, {
        scheduledTestId: scheduledTest.id,
      }),
    );
  };

  return (
    <StyledScheduledTestsPage>
      <StyledScheduledTestsHeader>
        {t("overview.createTest.header", "Create a test")}
      </StyledScheduledTestsHeader>
      <StyledScheduledTestsParagraph>
        {t("overview.createTest.description", "With this form you can schedule a test.")}
      </StyledScheduledTestsParagraph>
      <StyledScheduledTestsButton
        data-cy="go-to-create-test-wizard-button"
        variant="primary"
        onClick={onGoToCreateTestWizard}
      >
        {t("overview.createTest.button.label", "Create a test")}
      </StyledScheduledTestsButton>
      <SeparatorLine direction="horizontal" />
      <section>
        <StyledScheduledTestsHeader>
          {t("overview.testList.header", "Upcoming tests")}
        </StyledScheduledTestsHeader>
        {scheduledTests?.map((test) => (
          <ScheduledTestItem
            key={test.id}
            data-cy={`scheduled-test-${test.id}`}
            scheduledTest={test}
            onEdit={editScheduledTest}
          />
        ))}
        {!scheduledTests && (
          <StyledScheduledTestsLoader data-cy="scheduled-tests-overview-loader">
            <LoadingSpinner />
          </StyledScheduledTestsLoader>
        )}
        {scheduledTests && scheduledTests.length === 0 && (
          <p data-cy="no-scheduled-tests-message">
            {t("overview.testList.noResults", "You haven't created any test yet")}
          </p>
        )}
        {scheduledTests && (
          <StyledViewAllButton
            dataCy="view-prev-tests-button"
            variant="secondary"
            onClick={() =>
              history.push(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_OVERVIEW)
            }
          >
            {t("overview.testList.button.viewAll", "View past tests")}
          </StyledViewAllButton>
        )}
      </section>
      <ShowPasswordModal
        headingTitle={t("scheduledTestList.item.passwordModal.heading", "Password")}
        onCopyPassword={(password) => onCopyPassword(password)}
      />
      <ReactTooltip
        getContent={() =>
          passwordCopied
            ? t("scheduledTestList.item.passwordModal.copiedToClipboardTooltip", "Password copied")
            : t("scheduledTestList.item.passwordModal.copyToClipboardTooltip", "Copy to clipboard")
        }
        id="password-copy-tooltip"
      />
    </StyledScheduledTestsPage>
  );
});
