import logoWaterMark from "assets/images/logo-watermark.svg";
import { SvgIconReact } from "components/SvgIconReact";
import type { ThemeProps } from "models/styles/theme";
import styled, { css } from "styled-components/macro";
import { ParagraphLarge } from "styles/elements/Texts";

const fullPageCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
`;

export const StyledDownloadCertificatePage = styled.div<ThemeProps>`
  --a4-ratio: 1.4142;
  --a4-width: 1330px;
  ${fullPageCss}
  pointer-events: none;
  padding: 12px;
  opacity: 0;
`;

export const StyledPage = styled.div`
  opacity: 0;
  ${fullPageCss}
  > div {
    /** actual a4 page size */
    width: var(--a4-width);
    height: calc(var(--a4-width) * var(--a4-ratio));
    padding: 50px 50px;
  }
`;

export const StyledCertificateMainWrapper = styled.div<ThemeProps>`
  padding: 12px;
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._600};
  width: 100%;
`;

export const StyledCertificateWrapper = styled.div<ThemeProps>`
  padding: 8px;
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._600};
  background-image: url(${logoWaterMark});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 561px 556px;
  width: 100%;
`;

export const StyledBody = styled.div<ThemeProps>`
  padding-top: 78px;
  text-align: center;

  h1,
  h3 {
    font-family: ${({ theme }: ThemeProps) => theme.font.family.secondary};
  }

  h3,
  h4,
  h5 {
    margin: 40px auto;
  }

  h5,
  h4 {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.normal};
  }

  h5:last-child {
    max-width: 690px;
    line-height: 24px;
  }
`;

export const StyledFooterWrapper = styled.div<ThemeProps>`
  margin-top: 59px;
  position: relative;

  & > small {
    font-size: 8px;
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._600};
    margin-left: 12px;
  }
`;

export const StyledFooter = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: start;
  align-items: start;
  --bs-gutter-x: 2rem;
  margin-bottom: 40px;

  & > div {
    flex: 0 0 auto;
    width: 20%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    text-align: center;

    img {
      margin: 0 auto;
    }

    ${ParagraphLarge} {
      font-size: ${({ theme }: ThemeProps) => theme.font.size._12};
      max-width: 143px;
      margin: 0 auto;
    }
  }

  div:nth-child(2) {
    svg:first-child {
      width: 195px;
      margin-bottom: 16px;
    }

    svg:last-child {
      width: 52px;
    }
  }

  div:last-child {
    padding-top: 47px;
    position: relative;

    img {
      position: absolute;
      left: 70px;
      top: -10px;
      width: 100px;
      height: auto;
    }
  }
`;

export const StyledIcon = styled(SvgIconReact)`
  margin: 0 auto;
  padding: 0;
`;

export const StyledStroke = styled.div<ThemeProps>`
  width: 100%;
  height: 1px;
  margin-bottom: 8px;
  background: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
`;

export const StyledLoader = styled.div<ThemeProps>`
  background: ${({ theme }: ThemeProps) => theme.palette.neutral._950};
  border-radius: 4px;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
