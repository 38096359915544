import type React from "react";
import type SeparatorLineProps from "./SeparatorLine.model";
import { StyledSeparatorLine } from "./SeparatorLine.styled";

export const SeparatorLine: React.FC<SeparatorLineProps> = ({
  direction = "vertical",
  color,
  height,
  width,
}) => <StyledSeparatorLine color={color} direction={direction} height={height} width={width} />;
