import { observer } from "mobx-react";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { modalIds } from "constants/modals-constants";
import { useDomainHandler } from "hooks/useDomainHandler";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { FormDivider } from "components/SteppedForm/SteppedForm.styled";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";
import { TooltipModal } from "../TooltipModal/TooltipModal";
import { StyledSYCheckBoxWrapper } from "./FormSYIntegration.styled";

export interface FormSchoolyearIntegration {
  currentValue: boolean;
  onChange: (activated: boolean) => void;
  enabledSEB: boolean;
  setSecureTestOptionSelected: (safeExamOptions: {
    syEnabled: boolean;
    sebEnabled: boolean;
  }) => void;
}

export const FormSchoolyearIntegration: React.FC<FormSchoolyearIntegration> = observer(
  ({ currentValue, onChange, enabledSEB, setSecureTestOptionSelected }) => {
    const [enableSY, setEnableSY] = useState(currentValue);
    const { getCustomNameByDomain } = useDomainHandler();
    const { t } = useTranslation("scheduled-tests");

    const trackedOnChange = () => {
      const toggledEnableSY = !enableSY;
      trackPlatformEvent({
        module: TEACHER_MODULES.SCHEDULED_TESTS,
        itemId: PLATFORM_EVENT_TYPES.SCHOOLYEAR.TOGGLE_SCHOOLYEAR_SCHEDULE_TEST,
        value: JSON.stringify({
          enabled: toggledEnableSY,
        }),
        elementId: "toggle-schoolyear-test",
      });
      setSecureTestOptionSelected({ syEnabled: toggledEnableSY, sebEnabled: enabledSEB });
      setEnableSY(toggledEnableSY);
      onChange(toggledEnableSY);
    };

    return (
      <>
        <FormDivider />
        <StyledSYCheckBoxWrapper>
          <span>{t("testOptionsStep.schoolyearIntegration.modal.title")}</span>
          <>
            <ToggleSwitch
              dataCy="toggle-schoolyear-test"
              isActive={enableSY}
              variant="primary"
              isDisabled={enabledSEB}
              onChange={trackedOnChange}
            />
            <TooltipModal
              content={{
                title: t("testOptionsStep.schoolyearIntegration.modal.title"),
                paragraph: t("testOptionsStep.schoolyearIntegration.modal.paragraph", {
                  appName: getCustomNameByDomain(),
                }),
              }}
              dataCy="schoolyear-integration-modal"
              modalId={modalIds.schoolyearModal}
            />
          </>
        </StyledSYCheckBoxWrapper>
      </>
    );
  },
);
