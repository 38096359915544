import type { ThemeProps } from "models/styles/theme";
import styled, { css } from "styled-components/macro";

import { SingleSelectQuestion } from "layouts/QuestionManager/components/SingleSelectQuestion";

interface Props extends ThemeProps {
  $answerIsCorrect?: boolean;
  $index?: number;
}

export const StyledQuestionContainer = styled.div<Props>`
  display: flex;
  column-gap: 1.5rem;
  align-items: flex-start;
  border-top: 1px solid ${({ theme }: Props) => theme.palette.neutral._900};
  padding: 1.5rem 0;

  > :last-child {
    flex-grow: 1;

    .question-wrapper {
      text-align: left;
      color: ${({ theme }: Props) => theme.palette.neutral._500};

      > b {
        color: ${({ theme }: Props) => theme.palette.neutral.black};
      }

      ${({ theme, $index }: Props) =>
        $index
          ? css`
              ::before {
                font-weight: bold;
                color: ${theme.palette.neutral.black};
                content: '${$index}. ';
              }
            `
          : ""}
    }
  }
`;

export const StyledResultCircle = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${({ theme, $answerIsCorrect }: Props) =>
    $answerIsCorrect ? theme.palette.feedback.success._800 : theme.palette.feedback.error.brand};
  color: ${({ theme }: Props) => theme.palette.neutral.white};
`;

export const StyledSingleSelectQuestion = styled(SingleSelectQuestion)<Props>`
  > * {
    align-self: flex-start;
  }

  ul {
    width: auto;
    margin-top: 1.5rem;
  }
`;
