import type React from "react";

import { Breadcrumbs } from "components/Breadcrumbs";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";
import { useDiagnosticTest } from "hooks/useDiagnosticTest";
import { observer } from "mobx-react";
import type { DiagnosticTestPagePathParams } from "models/path/path-params";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const DiagnosticTestBreadcrumbs: React.FC = observer(() => {
  const { t } = useTranslation("diagnostic-test");
  const { groupId }: DiagnosticTestPagePathParams = useParams();
  const { diagnosticTestGroups, diagnosticTestDetails } = useDiagnosticTest();
  const titleSelectedGroup =
    diagnosticTestGroups.find((group) => group.id === +groupId)?.title || "";
  const routesBreadcrumbs = [
    {
      path: PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS,
      breadcrumb: t("breadcrumbs.module", "Basics"),
    },
    {
      path: TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS,
      breadcrumb: t("breadcrumbs.diagnosticTest", "Diagnostic Test"),
    },
    { path: TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_LIST, breadcrumb: titleSelectedGroup },
    { path: TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_DETAILS, breadcrumb: diagnosticTestDetails.title },
  ];
  return <Breadcrumbs dataCy="diagnostic-page" routes={routesBreadcrumbs} showAllRoutes={false} />;
});
