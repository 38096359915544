import { Button } from "components/Button";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { Box } from "styles/helpers/layout";

export const StyledDiagnosticTestBanner = styled.div<ThemeProps>`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props: ThemeProps) => props.theme.palette.secondary._950};
`;

export const StyledContent = styled(Box)`
  text-align: start;
  padding: 2rem;
  h4 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  width: clamp(2rem, 12rem, 13rem);
  padding: 0.8rem;
`;
