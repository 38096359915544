import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";

import { useWriting } from "hooks/useWriting";

import { Heading1 } from "styles/elements/Headings";

import { BackButton } from "components/BackButton";
import { EmbeddedHtml } from "components/EmbeddedHtml";
import { LoadingSpinner } from "components/LoadingSpinner";

import { ChapterList } from "./components/ChapterList/ChapterList";

import { ImmersiveReaderButton } from "components/ImmersiveReaderButton/ImmersiveReaderButton";
import {
  StyleHeader,
  StyledContainer,
  StyledDescription,
  StyledWritingDetail,
} from "./WritingSubjectDetailsPage.styled";

export interface PathParams {
  productId: string;
  moduleId: string;
  subjectId: string;
}

export const WritingSubjectDetailsPage: React.FC = observer(() => {
  const { t } = useTranslation("writing-module");
  const { getWritingDetail, writingSubjectDetails } = useWriting();
  const { productId, moduleId, subjectId }: PathParams = useParams();

  const backButtonRedirectURL = useMemo(
    () =>
      buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
        productId,
        moduleId,
      }),
    [productId, moduleId],
  );

  useEffect(() => {
    getWritingDetail(+productId, +moduleId, +subjectId);
  }, [productId, moduleId, subjectId]);

  if (!writingSubjectDetails) {
    return <LoadingSpinner />;
  }
  const IR_TARGET_SELECTOR = "writting-subject-immersive-reader";

  return (
    <StyledWritingDetail>
      <BackButton
        dataCy="writing-subject-back-btn"
        label={t("subjectDetails.button.back.label", "Back")}
        redirectUrl={backButtonRedirectURL}
      />
      <StyleHeader className={IR_TARGET_SELECTOR}>
        <Heading1 data-cy="writing-subject-title">
          <ImmersiveReaderButton targetIndex={0} targetSelector={`.${IR_TARGET_SELECTOR}`} />
          {writingSubjectDetails.title}
        </Heading1>
        {writingSubjectDetails.description && (
          <StyledDescription data-cy="writing-subject-description">
            <EmbeddedHtml rawHtml={writingSubjectDetails?.description} />
          </StyledDescription>
        )}
      </StyleHeader>
      <StyledContainer>
        {writingSubjectDetails?.chapters?.map((chapter) => (
          <ChapterList
            key={chapter.id}
            id={chapter.id}
            items={chapter.topics}
            title={chapter.title}
          />
        ))}
      </StyledContainer>
    </StyledWritingDetail>
  );
});
