import styled, { css } from "styled-components/macro";

import { StyledSvgIconReact } from "components/SvgIconReact/SvgIconReact.styled";
import type { ThemeProps } from "models/styles/theme";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type { TagFillType, TagVariant } from "./Tag.model";

interface Props extends ThemeProps {
  $variant: TagVariant;
  $fillType: TagFillType;
  $boldText: boolean;
}

const getTagVariantCss = (
  variant: TagVariant,
  fillType: TagFillType,
  theme: ElearningThemeInterface,
) => {
  switch (true) {
    case variant === "default" && fillType === "bordered":
      return css`
        color: ${theme.palette.neutral._500};
        border: 1px solid ${theme.palette.neutral._900};
      `;
    case variant === "default" && fillType === "filled":
      return css`
        border: 1px solid ${theme.palette.neutral._900};
        background-color: ${theme.palette.neutral._900};
        color: ${theme.palette.neutral.black};
      `;
    case variant === "secondary" && fillType === "bordered":
      return css`
        color: ${theme.palette.secondary.brand};
        border: 1px solid ${theme.palette.secondary.brand};
      `;
    case variant === "secondary" && fillType === "filled":
      return css`
        border: 1px solid ${theme.palette.secondary.brand};
        background-color: ${theme.palette.secondary.brand};
        color: ${theme.palette.neutral.white};
      `;
    case variant === "dark" && fillType === "bordered":
      return css`
        border: 1px solid ${theme.palette.neutral.black};
        color: ${theme.palette.neutral.black};
      `;
    case variant === "dark" && fillType === "filled":
      return css`
        border: 1px solid ${theme.palette.neutral.black};
        background-color: ${theme.palette.neutral.black};
        color: ${theme.palette.neutral.white};
      `;
    case variant === "success" && fillType === "bordered":
      return css`
        color: ${theme.palette.feedback.success._800};
        border: 1px solid ${theme.palette.feedback.success._800};
      `;
    case variant === "success" && fillType === "filled":
      return css`
        border: 1px solid ${theme.palette.feedback.success._800};
        background-color: ${theme.palette.feedback.success._800};
        color: ${theme.palette.neutral.black};
      `;
    case variant === "error" && fillType === "bordered":
      return css`
        color: ${theme.palette.feedback.error.brand};
        border: 1px solid ${theme.palette.feedback.error.brand};
      `;
    case variant === "error" && fillType === "filled":
      return css`
        border: 1px solid ${theme.palette.feedback.error.brand};
        background-color: ${theme.palette.feedback.error.brand};
        color: ${theme.palette.neutral.white};
      `;
    default:
      return null;
  }
};

export const StyledTag = styled.span<Props>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 0.25rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  background-color: ${({ theme }: Props) => theme.palette.neutral.white};
  font-weight: ${({ $boldText, theme }: Props) => ($boldText ? theme.font.weight.bold : "normal")};
  ${({ theme, $variant, $fillType }) => getTagVariantCss($variant, $fillType, theme)}
  > ${StyledSvgIconReact} {
    margin-right: 0.25rem;
    padding-top: 0.25rem;
  }
`;
