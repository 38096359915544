import {
  getStoredGoalLevelByProductId,
  storeGoalLevelByProductId,
} from "_helpers/localStorageGoalLevelProvider";
import { getCurrentSchoolYearStart } from "_helpers/schoolYear";
import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import type { BreadcrumbsRoute } from "components/Breadcrumbs/Breadcrumbs.model";
import { SkeletonLoader } from "components/SkeletonLoader/SkeletonLoader";
import { TEACHER_INSIGHT_PAGE_ROUTES } from "constants/routes";
import { SkillsSelectorType } from "constants/teacher-constants";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import { useProfile } from "hooks/useProfile";
import { observer } from "mobx-react";
import { ModuleType } from "models/product/ProductDetails";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CssFlex, CssGrid } from "styles/helpers/layout";
import { ForAccountHeading } from "../../components/ForAccountHeading/ForAccountHeading";
import type { BasicProgressForAccountPagePathParams } from "./BasicProgressForAccount.model";
import { AccountGroupedProgress } from "./components/AccountGroupedProgress/AccountGroupedProgress";
import { AccountOverallProgress } from "./components/AccountOverallProgress/AccountOverallProgress";
import { AccountSubjectProgress } from "./components/AccountSubjectProgress/AccountSubjectProgress";
import { AccountSubjectProgressOverTime } from "./components/AccountSubjectProgressOverTime/AccountSubjectProgressOverTime";
import { DetailedProgressModal } from "./components/DetailedProgressModal/DetailedProgressModal";

export const BasicProgressForAccountPage: React.FC = observer(() => {
  const { showGoalLevelSelectorOnTeacherDashboard, showStudentProgressOverTimeChart } =
    useFeatureFlags();
  const { productId, accountId }: BasicProgressForAccountPagePathParams = useParams();
  const { productDetails, fetchProductDetails } = useProduct();
  const selectableGoalLevels = useMemo(
    () => productDetails?.levels.filter(({ selectable }) => selectable) || [],
    [productDetails],
  );
  const selectedGoalLevel = getStoredGoalLevelByProductId(+productId);
  const [targetLevel, setTargetLevel] = useState<number>();
  const { t } = useTranslation("teacher-dashboard");

  const onTargetLevelChange = (goalLevelToSet: number) => {
    trackPlatformEvent({
      module: TEACHER_MODULES.BASIC_PROGRESS_FOR_ACCOUNT,
      itemId:
        PLATFORM_EVENT_TYPES.BASIC_PROGRESS_FOR_ACCOUNT_EVENT_TYPES.UPDATE_VIEW_BY_TARGET_LEVEL,
      value: JSON.stringify({ from: targetLevel, to: goalLevelToSet }),
    });
    storeGoalLevelByProductId(+productId, goalLevelToSet);
    setTargetLevel(goalLevelToSet);
  };

  const {
    subjectsProgressOverview,
    productProgressForAccount,
    answeredQuestionsForAccount,
    fetchSubjectsProgressOverview,
    fetchProductProgressForAccount,
    fetchAnsweredQuestionsForAccount,
    fetchStudentSubjectProgressOverTime,
    studentSubjectProgressOverTime,
  } = useProductProgress();

  const { userDetails } = useProfile();

  useEffect(() => {
    if (+productId) {
      // We fetch the product again if the language has changed, because the
      // descriptions coming from the backend will be with the new language
      fetchProductDetails(+productId);
    }
  }, [userDetails?.language]);

  useEffect(() => {
    if (!productDetails || productDetails.id !== +productId) {
      fetchProductDetails(+productId);
    }
  }, [productId]);

  useEffect(() => {
    if (!showGoalLevelSelectorOnTeacherDashboard) {
      fetchProductProgressForAccount(+productId, +accountId);
    } else if (targetLevel) {
      fetchProductProgressForAccount(+productId, +accountId, +targetLevel);

      // By default, chart should include data for on-going school year
      if (showStudentProgressOverTimeChart) {
        const schoolYearStart = getCurrentSchoolYearStart();
        fetchStudentSubjectProgressOverTime(+productId, +accountId, +targetLevel, {
          startDate: schoolYearStart.toISOString(),
        });
      }
    }

    fetchAnsweredQuestionsForAccount(+productId, +accountId);
  }, [productId, accountId, targetLevel]);

  useEffect(() => {
    if (!subjectsProgressOverview) {
      fetchSubjectsProgressOverview(+productId, {});
    }
  }, [subjectsProgressOverview, productId]);

  // Initialize target level filter to first selectable goal in the current product
  //  (or the previous selected goal in local storage in case the user had already used the filter)
  useEffect(() => {
    if (!targetLevel && productDetails?.id === +productId) {
      setTargetLevel(selectedGoalLevel || selectableGoalLevels[0]?.level);
    }
  }, [selectedGoalLevel, selectableGoalLevels[0]?.level, productDetails?.id, productId]);

  if (!productProgressForAccount?.account || !answeredQuestionsForAccount) {
    return <SkeletonLoader $marginTop={3} count={10} />;
  }
  const { account, avgProgress, subjects } = productProgressForAccount;
  const fullName = account.middleName
    ? `${account.firstName} ${account.middleName} ${account.lastName}`
    : `${account.firstName} ${account.lastName}`;

  const breadCrumbRoutes: BreadcrumbsRoute[] = [
    {
      path: TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW,
      breadcrumb: t("basicProgress.overview.title"),
    },
    {
      path: TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT,
      breadcrumb: fullName,
    },
  ];

  return (
    <ForAccountHeading
      breadCrumbRoutes={breadCrumbRoutes}
      email={account.email}
      fullName={fullName}
      selectableGoalLevels={selectableGoalLevels}
      skillType={SkillsSelectorType.BASIC_SKILLS}
      studentIds={subjectsProgressOverview?.studentIds}
      studentNumber={account?.studentNumber ?? ""}
      targetLevel={targetLevel}
      onTargetLevelChange={onTargetLevelChange}
    >
      <CssFlex flexDirection="column" mb={3} mt={3} rowGap={1.5}>
        {/* Progress summary boxes */}
        <CssGrid gap={1.5} gridTemplateColumns="repeat(3,1fr)">
          <AccountOverallProgress
            accountId={+accountId}
            avgProgress={avgProgress}
            goalLevel={account.goalLevel ?? "-"}
            productId={+productId}
            subjects={subjects}
          />
        </CssGrid>

        {/* Current progress of student in each subject */}
        <AccountSubjectProgress goalLevel={account.goalLevel ?? "-"} subjects={subjects} />

        {/* Only show chart if goal selector for teacher is enabled, as it depends on teacher selection */}
        {showGoalLevelSelectorOnTeacherDashboard && showStudentProgressOverTimeChart && (
          <AccountSubjectProgressOverTime
            studentSubjectProgressOverTime={studentSubjectProgressOverTime}
          />
        )}

        {/* Progress with detailed progress depends on teacher selected level, so render it only when target selection is enabled */}
        {showGoalLevelSelectorOnTeacherDashboard && (
          <DetailedProgressModal
            accountId={+accountId}
            moduleId={productDetails?.modules.find((m) => m.type === ModuleType.ADAPTIVE)?.id}
            productId={+productId}
            subjects={subjectsProgressOverview}
            targetLevel={selectableGoalLevels.find((gl) => gl.level === targetLevel)}
          />
        )}

        {/* Answered questions stats */}
        <CssGrid gap={1.5} gridTemplateColumns="repeat(2,1fr)">
          <AccountGroupedProgress subjects={subjects} />
        </CssGrid>
      </CssFlex>
    </ForAccountHeading>
  );
});
