import * as Yup from "yup";

export interface TeacherInstructionFormFields {
  title: string;
  productId?: string | number;
  moduleId?: string | number;
  targetTeacherType?: string;
  pdfFile: File | null;
}

export const buildTeacherInstructionsSchema = (): Yup.SchemaOf<TeacherInstructionFormFields> =>
  Yup.object().shape({
    title: Yup.string().required(),
    moduleId: Yup.string().required(),
    targetTeacherType: Yup.string().required(),
    pdfFile: Yup.mixed().nullable(),
    productId: Yup.string().required(),
  });
