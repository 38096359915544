import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BusinessDomainList, CustomDomains, ProductType } from "constants/hst-constants";
import { SupportWidgetVariant } from "supportWidget/models/variant.model";

import BeterschrijvenFavicon from "assets/favicon/favicon_beterschrijven.png";
import HogeschooltaalFavicon from "assets/favicon/favicon_hogeschooltaal.png";
import type { IconComponentName } from "components/SvgIconReact/IconNames";

interface TermGetterOptions {
  usePlural?: boolean;
}

export interface useDomainHandlerInterface {
  isBusinessDomain: () => boolean;
  getCustomLogoByDomain: () => IconComponentName;
  getCustomFaviconByDomain: () => string;
  getCustomNameByDomain: () => string;
  getCustomSupportEmailByDomain: () => string;
  getCustomSupportPhoneByDomain: () => string;
  getCustomSupportPhoneLabelByDomain: () => string;
  getStudentTypeByDomain: () => string;
  getTeacherTypeByDomain: () => string;
  getSupportWidgetVariantByDomain: () => SupportWidgetVariant;
  getStudentTermByDomain: (options?: TermGetterOptions) => string;
  getTeacherTermByDomain: (options?: TermGetterOptions) => string;
  getDefaultProductTypeByDomain: () => ProductType;
}

export const useDomainHandler = (): useDomainHandlerInterface => {
  const { t } = useTranslation("common");
  const { customCurrentHost } = useFeatureFlags();

  // Optionally override current domain based on feature flag value
  const currentDomain = useMemo(() => {
    if (customCurrentHost && customCurrentHost !== "null") {
      return customCurrentHost as string;
    }
    return window.location.host;
  }, [window.location.host, customCurrentHost]);

  // Verifies if current domain belongs to the business proposition
  const isBusinessDomain = (): boolean => BusinessDomainList.includes(currentDomain);

  const getCustomLogoByDomain = (): IconComponentName => {
    switch (currentDomain) {
      case CustomDomains.UNIVERSITEITSTAAL:
        return "logoUniversiteitstaalWithText";
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return "logoBeterschrijvenWithText";
      default:
        return "logoHogesWithText";
    }
  };

  const getCustomFaviconByDomain = (): string => {
    switch (currentDomain) {
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return BeterschrijvenFavicon;
      default:
        return HogeschooltaalFavicon;
    }
  };

  const getCustomNameByDomain = (): string => {
    switch (currentDomain) {
      case CustomDomains.UNIVERSITEITSTAAL:
        return "Universiteitstaal";
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return "Beter Schrijven";
      default:
        return "Hogeschooltaal";
    }
  };

  const getCustomSupportEmailByDomain = (): string => {
    switch (currentDomain) {
      case CustomDomains.UNIVERSITEITSTAAL:
        return "support-universiteitstaal@noordhoff.nl";
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return "support-beterschrijven@noordhoff.nl";
      default:
        return "support-hogeschooltaal@noordhoff.nl";
    }
  };

  const getCustomSupportPhoneByDomain = (): string => {
    switch (currentDomain) {
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return "+31885226840";
      default:
        return "+31885226822";
    }
  };

  const getCustomSupportPhoneLabelByDomain = (): string => {
    switch (currentDomain) {
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return "088 522 68 40";
      default:
        return "088 522 68 22";
    }
  };

  const getStudentTypeByDomain = (): string => {
    switch (currentDomain) {
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return "business";
      case CustomDomains.UNIVERSITEITSTAAL:
        return "university";
      default:
        return "higherEducation";
    }
  };

  const getTeacherTypeByDomain = (): string => {
    switch (currentDomain) {
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return "business";
      case CustomDomains.UNIVERSITEITSTAAL:
        return "university";
      default:
        return "higherEducation";
    }
  };

  const getSupportWidgetVariantByDomain = (): SupportWidgetVariant => {
    switch (currentDomain) {
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return SupportWidgetVariant.BETERSCHRIJVEN;
      default:
        return SupportWidgetVariant.DEFAULT;
    }
  };

  /* -- Methods to fetch localized terms to use based on domain -- */
  const getLocalizedText = (
    textKey: string,
    options: TermGetterOptions = { usePlural: false },
  ): string => {
    const { usePlural } = options;
    return t(textKey, { count: usePlural ? 2 : 1 });
  };

  const getStudentTermByDomain = (options?: TermGetterOptions): string =>
    getLocalizedText(`studentType.${getStudentTypeByDomain()}`, options);

  const getTeacherTermByDomain = (options?: TermGetterOptions): string =>
    getLocalizedText(`teacherType.${getTeacherTypeByDomain()}`, options);

  const getDefaultProductTypeByDomain = (): ProductType => {
    switch (currentDomain) {
      case CustomDomains.BETERSCHRIJVEN:
      case CustomDomains.BETERSCHRIJVEN_STG:
        return ProductType.BUSINESS;
      default:
        return ProductType.REGULAR;
    }
  };

  return {
    isBusinessDomain,
    getCustomLogoByDomain,
    getCustomFaviconByDomain,
    getCustomNameByDomain,
    getCustomSupportEmailByDomain,
    getCustomSupportPhoneByDomain,
    getCustomSupportPhoneLabelByDomain,
    getStudentTypeByDomain,
    getTeacherTypeByDomain,
    getSupportWidgetVariantByDomain,
    getStudentTermByDomain,
    getTeacherTermByDomain,
    getDefaultProductTypeByDomain,
  };
};
