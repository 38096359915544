import type React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  type EnvironmentType,
  serviceENV as getCurrentEnvironment,
  isLocal,
  isTest,
} from "constants/env-constants";
import { HogesWebshopURL } from "constants/registration-links";
import { AUTH_PAGES_ROUTES } from "constants/routes";
import { useDomainHandler } from "hooks/useDomainHandler";

import { Heading3, Heading4 } from "styles/elements/Headings";

import { TermsFooter } from "../components/TermsFooter/TermsFooter";
import {
  StyledAvailabilityNote,
  StyledButton,
  StyledCard,
  StyledContainer,
  StyledHeader,
  StyledLinkButton,
  StyledLoginSection,
  StyledLogo,
  StyledPageLayout,
  StyledSeparator,
  StyledTitle,
} from "./RegistrationPage.styled";

export const RegistrationPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation("auth-pages");
  const { getCustomNameByDomain, getCustomLogoByDomain } = useDomainHandler();

  const currentEnv = getCurrentEnvironment() as EnvironmentType;

  return (
    <StyledPageLayout>
      <StyledHeader>
        <StyledLogo iconName={getCustomLogoByDomain()} />
        <StyledLoginSection>
          {t("registrationPage.header.alreadyHaveAccount", "Already have an account?")}
          <StyledButton
            dataCy="go-to-login-button"
            variant="primary"
            onClick={() => history.push(AUTH_PAGES_ROUTES.LOG_IN)}
          >
            {t("registrationPage.header.button.login", "Login")}
          </StyledButton>
        </StyledLoginSection>
      </StyledHeader>
      <StyledContainer>
        <StyledTitle>
          <Heading3>{t("registrationPage.main.title", "Don't have an account (yet)?")}</Heading3>
          <p>
            {t(
              "registrationPage.main.subtitle",
              "Then create a practice account or a basic account.",
            )}
          </p>
        </StyledTitle>
        <StyledCard>
          <section>
            <Heading4>
              {t("registrationPage.main.practiceAccount.tile", "Practice account")}
            </Heading4>
            <Trans i18nKey="registrationPage.main.practiceAccount.description" t={t}>
              <p>
                If you want to create a practice account, first purchase a practice license in the
                webshop. After that, you can immediately start using{" "}
                {{ appName: getCustomNameByDomain() }} and benefit from all the advantages:
              </p>
              <ul>
                <li>Exercises that match your level of knowledge</li>
                <li>Practice tests to test yourself</li>
                <li>Immediate insight into your progress</li>
              </ul>
            </Trans>
            <StyledLinkButton external={!isTest() && !isLocal()} to={HogesWebshopURL[currentEnv]}>
              <StyledButton dataCy="go-to-webshop-button" variant="primary">
                {t(
                  "registrationPage.main.practiceAccount.button.purchaseLicense",
                  "Buy your practice license",
                )}
              </StyledButton>
            </StyledLinkButton>
            <StyledAvailabilityNote>
              {t(
                "registrationPage.main.practiceAccount.availability",
                "Your practice account is immediately ready for use!",
              )}
            </StyledAvailabilityNote>
          </section>
          <StyledSeparator>{t("registrationPage.main.separator.text", "or")}</StyledSeparator>
          <section>
            <Heading4>{t("registrationPage.main.basicAccount.tile", "Basic account")}</Heading4>
            <Trans i18nKey="registrationPage.main.basicAccount.description" t={t}>
              <p>
                {
                  "Don't want to create a practice account? With a basic account you can simply take your "
                }
                <b>test</b>
                {", but you will not have access to the practice environment. "}
                You can always buy a practice license later and convert your account to a practice
                account.
              </p>
            </Trans>
            <StyledLinkButton to={AUTH_PAGES_ROUTES.CREATE_BASIC_ACCOUNT}>
              <StyledButton dataCy="go-to-register-form-button" variant="brand">
                {t(
                  "registrationPage.main.basicAccount.button.createAccount",
                  "Create basic account",
                )}
              </StyledButton>
            </StyledLinkButton>
          </section>
        </StyledCard>
      </StyledContainer>
      <TermsFooter />
    </StyledPageLayout>
  );
};
