import type { TFunction } from "i18next";
import { CorrectTextQuestionWordState, WordAction } from "models/exam/CorrectTextQuestion";
import type React from "react";
import { useTranslation } from "react-i18next";
import type { WordActionFeedbackProps } from "./WordActionFeedback.model";
import { StyledWordActionFeedback } from "./WordActionFeedback.styled";

const getMissedAnswer = (t: TFunction, wordAction?: WordAction, solutionValue?: string) => {
  switch (wordAction) {
    case WordAction.EDIT_WORD:
      return `${t("wordActionFeedback.answerFeedback.answerMissedEdit")} ${solutionValue || ""}`;
    case WordAction.REMOVE_WORD:
      return `${t("wordActionFeedback.answerFeedback.answerMissedRemove")}.`;
    case WordAction.ADD_WORD_AFTER:
      return `${t("wordActionFeedback.answerFeedback.answerMissedAddAfter")} ${solutionValue || ""}.`;
    case WordAction.MERGE_WORDS:
      return `${t("wordActionFeedback.answerFeedback.answerMissedMerge")} ${solutionValue || ""}.`;
    case WordAction.SWAP_WORDS:
      return `${t("wordActionFeedback.answerFeedback.answerMissedSwap")} ${solutionValue || ""}.`;
    default:
      return "";
  }
};

export const WordActionFeedback: React.FC<WordActionFeedbackProps> = ({
  state,
  originalWord,
  solution,
}) => {
  const { t } = useTranslation("correct-text-question");

  if (
    state === CorrectTextQuestionWordState.NONE ||
    state === CorrectTextQuestionWordState.CHANGED
  ) {
    return <></>;
  }

  const originalWordEl = (
    <div>
      <br />
      <span>{t("wordActionFeedback.answerFeedback.original")}:</span>
      <br />
      <span>
        <strong>{originalWord}</strong>
      </span>
    </div>
  );

  return (
    <StyledWordActionFeedback data-cy="word-action-feedback">
      {state === CorrectTextQuestionWordState.ANSWER_CORRECT && (
        <div>
          <span>{t("wordActionFeedback.answerFeedback.answerCorrect")}</span>
          {originalWordEl}
        </div>
      )}
      {state === CorrectTextQuestionWordState.ANSWER_INCORRECT && (
        <div>
          <span>
            {t("wordActionFeedback.answerFeedback.answerIncorrect")}{" "}
            {getMissedAnswer(t, solution?.actionType, solution?.value)}
          </span>
          {originalWordEl}
        </div>
      )}
      {state === CorrectTextQuestionWordState.ANSWER_MISSED && (
        <div>
          <span>
            {t("wordActionFeedback.answerFeedback.answerMissed")}{" "}
            {getMissedAnswer(t, solution?.actionType, solution?.value)}
          </span>
        </div>
      )}
      {state === CorrectTextQuestionWordState.ANSWER_UNNECESSARY && (
        <div>
          <span>{t("wordActionFeedback.answerFeedback.answerUnnecessary")}</span>
          {originalWordEl}
        </div>
      )}
    </StyledWordActionFeedback>
  );
};
