import { Button } from "components/Button";
import { SvgIconReact } from "components/SvgIconReact";
import type React from "react";
import { Heading2, Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import type { AlertMessageProps } from "./AlertMessage.model";
import {
  StyledAlertMessage,
  StyledAlertMessageLeftColumn,
  StyledAlertMessageRightColumn,
} from "./AlertMessage.styled";

export const AlertMessage: React.FC<AlertMessageProps> = ({
  dataCy,
  title,
  subTitle,
  alertIconName = "warningExclamationTriangle",
  alertMessage,
  preText,
  buttonTitle,
  buttonDisabled = false,
  onButtonClick,
}) => (
  <StyledAlertMessage data-cy={dataCy}>
    <StyledAlertMessageLeftColumn>
      <SvgIconReact iconName={alertIconName} size={3.5} />
      <Heading3>{alertMessage}</Heading3>
    </StyledAlertMessageLeftColumn>
    <StyledAlertMessageRightColumn>
      {preText && <span className="error">{preText}</span>}
      <Heading2>{title}</Heading2>
      {subTitle && <ParagraphLarge>{subTitle}</ParagraphLarge>}
      <Button disabled={buttonDisabled} variant="primary" onClick={onButtonClick}>
        {buttonTitle}
      </Button>
    </StyledAlertMessageRightColumn>
  </StyledAlertMessage>
);
