import styled, { css } from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { SortableTable } from "components/SortableTable/SortableTable";
import { StyledCell, StyledHeaderCell } from "components/SortableTable/SortableTable.styled";
import { CssFlex } from "styles/helpers/layout";

export const StyledTable = styled(SortableTable)<ThemeProps>`
  margin-bottom: 4rem;

  ${StyledHeaderCell} {
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 1.5rem;
  }

  ${StyledCell} {
    padding: 0.75rem 1rem;
    word-break: break-word;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
`;

interface GroupIconContainerProps extends ThemeProps {
  $ownedBySelf?: boolean;
}

// IDEA: if you find yourself duplicating this, it might be a good idea to create a shared component
export const StyledGroupIconContainer = styled(CssFlex)<GroupIconContainerProps>`
  border-radius: 50%;

  width: 1.25rem;
  height: 1.25rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._10};

  align-items: center;
  justify-content: center;

  ${({ $ownedBySelf }: GroupIconContainerProps) => {
    if ($ownedBySelf) {
      return css`
        color: #f9b002;
        background: #fff3d5;
      `;
    }
    return css`
        color: #0078f1;
        background: #eef7ff;
      `;
  }};
`;
