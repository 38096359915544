import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import type { ResultsAPI } from "generated/types";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEACHER_INSIGHT_PAGE_ROUTES } from "constants/routes";

import { useDomainHandler } from "hooks/useDomainHandler";
import { useReactTooltip } from "hooks/useReactTooltip";

import { Card } from "components/Card/Card";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";

import { StyledHeading3 } from "../../WritingResults.styled";
import {
  StyledFontAwesomeIcon,
  StyledTable,
  StyledTableRow,
} from "./WritingStudentsResultsOverview.styled";

export interface WritingStudentsResultsOverviewProps {
  students?: ResultsAPI.WritingStudentsDetails[];
  studentCount: number | undefined;
  productId: number;
}

export const WritingStudentsResultsOverview: React.FC<WritingStudentsResultsOverviewProps> = ({
  students = [],
  studentCount,
  productId,
}) => {
  useReactTooltip();
  const { t } = useTranslation("teacher-dashboard");
  const history = useHistory();
  const { getStudentTermByDomain } = useDomainHandler();

  return (
    <Card layout={{ mt: 2, p: 0 }}>
      {studentCount === undefined ? (
        <FontAwesomeIcon iconName="fas fa-spinner fa-spin" />
      ) : (
        <StyledHeading3 p={2}>
          {t("writingResults.student.title", {
            studentCount,
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
        </StyledHeading3>
      )}
      <StyledTable>
        <thead>
          <tr>
            <th />
            <th>{t("writingResults.student.name")}</th>
            <th>{t("writingResults.student.subjectsFinished")}</th>
            <th>{t("writingResults.student.exercisesFinished")}</th>
            <th>{t("writingResults.student.casesFinished")}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <StyledTableRow
              key={student.id}
              $isClickable={student.totalExercises !== 0}
              data-cy={`writing-results-for-account-${student.id}`}
              onClick={() => {
                if (student.totalExercises !== 0) {
                  history.push(
                    buildUrlWithPathParams(
                      TEACHER_INSIGHT_PAGE_ROUTES.WRITING_RESULTS_FOR_ACCOUNT,
                      {
                        productId,
                        accountId: student.id,
                      },
                    ),
                  );
                }
              }}
            >
              <td>
                {student.totalExercises === 0 && (
                  <StyledFontAwesomeIcon
                    data-cy={`tooltip-${student.id}`}
                    data-tip={t("writingResults.noResultsFound", {
                      studentTerm: getStudentTermByDomain(),
                    })}
                    iconName="fal fa-exclamation-circle"
                    iconSize="2x"
                  />
                )}
              </td>
              <td data-cy={`td-name-${student.firstName}-${student.lastName}`}>
                {`${student.lastName}, ${student.firstName} ${
                  student.middleName ? student.middleName.split("")[0] : ""
                }`}
              </td>
              <td>{`${student.subjectsCompleted}/${student.totalSubjects}`}</td>
              <td>{`${student.exercisesCompleted}/${student.totalExercises}`}</td>
              <td>{`${student.casesCompleted}/${student.totalCases}`}</td>
              <td>
                {student.totalExercises !== 0 && (
                  <FontAwesomeIcon
                    data-cy={`writing-results-arrow-for-account-${student.id}`}
                    iconName="fas fa-chevron-right"
                  />
                )}
              </td>
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>
    </Card>
  );
};
