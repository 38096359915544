export enum WordAction {
  NONE = "none",
  EDIT_WORD = "edit",
  REMOVE_WORD = "remove",
  ADD_WORD_AFTER = "add-after",
  MERGE_WORDS = "merge",
  SWAP_WORDS = "swap",
}

export enum CorrectTextQuestionWordState {
  NONE = 0,
  ACTIVE = 1,
  CHANGED = 2,
  ANSWER_CORRECT = 3,
  ANSWER_INCORRECT = 4,
  ANSWER_MISSED = 5,
  ANSWER_UNNECESSARY = 6,
  ANSWER_MISSED_DELETE = 7,
}

export enum CorrectTextViewMode {
  EDITING = 0,
  RESULTS = 1,
  COMPARE = 2,
}

export type WordActionUpdate = {
  wordId: number;
  action: Omit<WordAction, WordAction.NONE>;
  originalValue: string;
  updatedValue: string;
};
