import { Select } from "components/SelectUI/Select";
import type { SkillsSelectorType } from "constants/teacher-constants";
import type React from "react";
import { type FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, CssLabel } from "styles/helpers/layout";

interface SkillsSelectorProps {
  skillTypes?: SkillsSelectorType[];
  onChange: (skillsSelectorType: SkillsSelectorType) => void;
}

export const SkillsSelector: FC<SkillsSelectorProps> = ({ skillTypes = [], onChange }) => {
  const [selectedOption, setSelectedOption] = useState("0");
  const { t } = useTranslation("teacher-dashboard");

  const skillsOptions = useMemo(
    () =>
      skillTypes.map((skill, index) => ({
        value: index,
        label: t(skill.toString()),
      })),
    [skillTypes],
  );

  return (
    <Box>
      <CssLabel id="skill-insight-type-selector-label">
        {t("selectors.skillSelectorLabel")}
      </CssLabel>
      <Select
        aria-labelledby="skill-insight-type-selector-label"
        dataCy="skill-insight-type-selector"
        options={skillsOptions}
        showPlaceholderInOptionList={false}
        value={selectedOption}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          const selectedValue = event.target.value;
          setSelectedOption(selectedValue);
          onChange(skillTypes[+selectedValue]);
        }}
      />
    </Box>
  );
};
