import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathAndQueryParams, buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";

import { Button } from "components/Button";
import { SvgIconReact } from "components/SvgIconReact";
import type { AdaptiveTestPageParams } from "pages/StudentEnvironment/AdaptiveTestPage/AdaptiveTestPage.model";

import { AdaptiveTestWorkflowType } from "constants/exam-constants";
import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useAnswer } from "hooks/useAnswer";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import { TestCompletedMessage } from "layouts/TestCompletedMessage";
import { buildComparedSessionTopicProgress } from "pages/StudentEnvironment/StudentDashboard/subpages/AdaptivePracticeSessionResultsPage/utils";
import { Box } from "styles/helpers/layout";
import { StyledCloseButton, StyledContainer } from "./SubjectCompleted.styled";

interface SubjectCompletedProps {
  productGoalLevel: number | undefined;
}

export const SubjectCompleted: React.FC<SubjectCompletedProps> = observer(
  ({ productGoalLevel }) => {
    const history = useHistory();
    const { t } = useTranslation("adaptive-test");
    const { t: tCommon } = useTranslation("common");
    const { productDetails, moduleDetails, fetchModuleDetails } = useProduct();
    const { showAdaptivePracticeSessionResultsPage } = useFeatureFlags();

    const { productId, moduleId, subjectId }: AdaptiveTestPageParams = useParams();
    const { moduleSubjectsProgress, fetchModuleProgress } = useProductProgress();

    const {
      isMixedExerciseActive,
      adaptivePracticeSessionResults,
      setCongratulationsMessage,
      setNextQuestionIsReady,
      setAdaptivePracticeDetails,
    } = useAdaptivePractice();

    const { resetAnswerState } = useAnswer();

    useEffect(() => {
      if (!moduleDetails || !(+moduleId === moduleDetails?.id)) {
        fetchModuleDetails(+productId, +moduleId);
      }

      if (productId && moduleId) {
        fetchModuleProgress(+productId, +moduleId);
      }
    }, [productId, moduleId]);

    const goalLevelName = productDetails?.levels.find(
      ({ level }) => level === productGoalLevel,
    )?.label;
    const subjectName = moduleDetails?.subjects.find((x) => x.id === +subjectId)?.title;
    const nextSubject = moduleSubjectsProgress.find((subject) => subject.progress < 100);

    const handleExitTestButtonClick = () => {
      const practicedTopics = buildComparedSessionTopicProgress(
        adaptivePracticeSessionResults,
        moduleSubjectsProgress,
        productDetails,
      ).filter((t) =>
        adaptivePracticeSessionResults.answeredQuestions.some((q) => q.topicId === t.id),
      );

      const redirectToModuleOverview =
        isMixedExerciseActive ||
        practicedTopics.length === 0 ||
        !showAdaptivePracticeSessionResultsPage;

      if (redirectToModuleOverview) {
        return history.push(
          buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
            productId,
            moduleId,
          }),
        );
      }
      return history.push(
        buildUrlWithPathParams(TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_SESSION_RESULTS, {
          productId,
          moduleId,
        }),
      );
    };

    const handleBackToOverviewClick = () => {
      return history.push(
        buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
          productId,
          moduleId,
        }),
      );
    };

    const handleNextSubjectClick = () => {
      // Should never happen, since we don't render the button if there is no next subject
      if (!nextSubject) return;

      // Reset relevant state variables before moving on to next subject
      setAdaptivePracticeDetails(null);
      setCongratulationsMessage("none");
      setNextQuestionIsReady(false);
      resetAnswerState();

      if (!nextSubject.isStarted) {
        return history.push(
          buildUrlWithPathAndQueryParams(TEST_PAGE_ROUTES.SKILL_DETERMINATION_TEST, {
            productId,
            moduleId,
            subjectId: nextSubject.id,
          }),
        );
      }

      return history.push(
        buildUrlWithPathAndQueryParams(
          TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
          {
            productId,
            moduleId,
            subjectId: nextSubject.id,
          },
          {
            workflow: AdaptiveTestWorkflowType.MIXED,
          },
        ),
      );
    };

    return (
      <StyledContainer alignItems="center" height="100%" justifyContent="center" width="100%">
        <StyledCloseButton
          aria-label={tCommon("iconButtonLabel.close")}
          dataCy="exit-test-button"
          variant="text"
          onClick={handleExitTestButtonClick}
        >
          <SvgIconReact iconName="closeX" />
        </StyledCloseButton>
        <TestCompletedMessage
          hideSubTitle
          buttons={
            nextSubject ? (
              <>
                <Button
                  dataCy="subject-completed-back-to-overview-button-secondary"
                  variant="secondary"
                  onClick={handleBackToOverviewClick}
                >
                  {t("finishedSubjectMixedFlow.backToOverviewButton", "Back to overview")}
                </Button>
                <Button
                  dataCy="continue-adaptive-test-topic-button"
                  variant="primary"
                  onClick={handleNextSubjectClick}
                >
                  {t("finishedSubjectMixedFlow.nextSubjectButton", "Continue with mixed exercises")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  dataCy="subject-completed-back-to-overview-button-primary"
                  variant="primary"
                  onClick={handleBackToOverviewClick}
                >
                  {t("finishedSubjectMixedFlow.backToOverviewButton", "Back to overview")}
                </Button>
              </>
            )
          }
          dataCy="mixed-topic-subject-completed"
          description={
            <>
              <Box mb={8}>
                <Trans
                  components={{
                    finishedSubjectTag: <span data-cy="completed-subject-title" />,
                    goalLevelTag: <span data-cy="goal-level" />,
                  }}
                  i18nKey={"finishedSubjectMixedFlow.description"}
                  t={t}
                  values={{ subjectName, goalLevel: goalLevelName }}
                />
              </Box>
              {nextSubject && (
                <Box>
                  <Trans
                    components={{ nextSubjectTag: <span data-cy="next-subject-title" /> }}
                    i18nKey={"finishedSubjectMixedFlow.nextSubjectText"}
                    t={t}
                    values={{ nextSubjectName: nextSubject.title }}
                  />
                </Box>
              )}
            </>
          }
          iconName="trophyAltIcon"
          title={
            <Trans
              components={{ finishedSubjectTag: <span data-cy="completed-subject-title" /> }}
              i18nKey={"finishedSubjectMixedFlow.title"}
              t={t}
              values={{ subjectName }}
            />
          }
        />
      </StyledContainer>
    );
  },
);
