import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { Select } from "components/SelectUI/Select";
import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";

export const StyledTitle = styled(Heading3)`
  margin-top: 1.5rem;
  margin-left: 0.5rem;
  text-align: left;
`;

export const StyledSelect = styled(Select)`
  width: 13rem;
`;

export const StyledStudentListSectionWrapper = styled.div`
  height: 30rem;
  overflow-y: auto;
  margin: 1rem;

  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }: Props) => theme.palette.neutral._950};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }: Props) => theme.palette.neutral._600};
  }
`;

export const StyledCloseButton = styled(FontAwesomeIcon)<Props>`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
`;
