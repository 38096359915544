import type React from "react";
import { useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";

import { EmbeddedHtml } from "components/EmbeddedHtml";
import { ExamStatusTag } from "components/ExamStatusTag";
import { ImmersiveReaderButton } from "components/ImmersiveReaderButton/ImmersiveReaderButton";
import { ListNavigationItem } from "components/ListNavigationItem/ListNavigationItem";
import { Box } from "styles/helpers/layout";
import type { WritingSubjectDetailsPageParams } from "../../WritingSubjectDetailsPage.model";
import type { ChapterListProps } from "./ChapterList.model";
import { StyleHeader, StyledCard } from "./ChapterList.styled";

export const ChapterList: React.FC<ChapterListProps> = ({ title, items = [], id }) => {
  const { productId, moduleId, subjectId }: WritingSubjectDetailsPageParams = useParams();
  const IR_TARGET_SELECTOR = `subject-immersive-reader-${id}`;

  return (
    <StyledCard className={IR_TARGET_SELECTOR}>
      <StyleHeader data-cy="chapter-list-item">
        <Box>
          <ImmersiveReaderButton targetIndex={0} targetSelector={`.${IR_TARGET_SELECTOR}`} />
        </Box>
        <EmbeddedHtml rawHtml={title} />
      </StyleHeader>
      <div>
        {items.map((item) => (
          <ListNavigationItem
            key={item.id}
            dataCy="topic-list-item"
            extraData={<ExamStatusTag status={item.status} />}
            href={buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_TOPIC_DETAILS, {
              productId,
              moduleId,
              subjectId,
              topicId: item.id,
            })}
            title={item.title}
          />
        ))}
      </div>
    </StyledCard>
  );
};
