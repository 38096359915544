import { CorrectTextQuestionWordState, WordAction } from "models/exam/CorrectTextQuestion";
import type { AnswerResult } from "models/exam/Exam";

interface WordResult {
  wordId: number;
  wordAction?: WordAction;
  wordValue?: string;
}

export const wordResultsToWordState = (
  { wordId, wordAction = WordAction.NONE }: WordResult,
  results: AnswerResult[],
): CorrectTextQuestionWordState => {
  const wordSolution = results.find((solution) => solution.order === wordId);

  if (wordSolution && wordAction !== WordAction.NONE) {
    if (wordSolution.answerCorrect) {
      // modifyType and answer string solution matches
      return CorrectTextQuestionWordState.ANSWER_CORRECT;
    }

    // no valid answers exists for the word in solution, meaning it shouldn't have been modified
    if (wordSolution.validAnswers?.length === 0) {
      return CorrectTextQuestionWordState.ANSWER_UNNECESSARY;
    }

    // this word should have been changed, but the change made was incorrect
    return CorrectTextQuestionWordState.ANSWER_INCORRECT;
  }

  // this word was changed, but shouldn't have been
  if (!wordSolution && wordAction !== WordAction.NONE) {
    return CorrectTextQuestionWordState.ANSWER_UNNECESSARY;
  }

  if (wordSolution) {
    // there is a correction for this word, but the user hasn't changed it
    return CorrectTextQuestionWordState.ANSWER_MISSED;
  }

  return CorrectTextQuestionWordState.NONE;
};
