import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useProduct } from "hooks/useProduct";
import { useProfile } from "hooks/useProfile";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";
import { AccessPageLayout } from "layouts/AccessPageLayout";

import { ROLES } from "@infinitaslearning/module-he-common";
import { StudentNoOwnedDashboard } from "../StudentNoOwnedDashboard/StudentNoOwnedDashboard";
import { NoOwnedProducts, OwnedProductList } from "./components";

export const ProductsOwnedPage: React.FC = observer(() => {
  const { fetchProducts, productsList: ownedProducts } = useProduct();
  const { userDetails, fetchUserDetails } = useProfile();
  const isStudent = userDetails?.role === ROLES.STUDENT;

  useEffect(() => {
    fetchUserDetails();
    fetchProducts();
  }, [fetchProducts, fetchUserDetails]);

  if (ownedProducts?.length === 1) {
    const productDetailsUrl = buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, {
      productId: ownedProducts[0].id,
    });

    return <Redirect to={productDetailsUrl} />;
  }

  const renderStudentNoOwnedDashboard = (student: boolean) => {
    if (student) return <StudentNoOwnedDashboard />;
    return (
      <AccessPageLayout>
        <NoOwnedProducts />
      </AccessPageLayout>
    );
  };

  return (
    <>
      {ownedProducts && ownedProducts.length > 0 ? (
        <AccessPageLayout>
          <OwnedProductList />
        </AccessPageLayout>
      ) : (
        renderStudentNoOwnedDashboard(isStudent)
      )}
    </>
  );
});
