import * as Yup from "yup";

export interface StudentTargetFormSchema {
  targetLevel: number | string;
}

export const buildStudentTargetFormSchema = (): Yup.SchemaOf<StudentTargetFormSchema> =>
  Yup.object({
    targetLevel: Yup.number().required(),
  }).required();
