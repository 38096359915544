export const DEFAULT_ROWS_PER_PAGE = 25;

export const DEFAULT_PAGE = 1;

export const ROWS_PER_PAGE = [25, 50, 100, 200];

export const PAGE_FILTER_KEY = "page";

// Debounce time will default to 1 second
export const DEFAULT_DEBOUNCE_TIME = 1000;
