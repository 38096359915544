/**
 *  Generate a string in HH:MM format from a date object.
 *  Used to generate values for the `TimePicker` component.
 *
 * @param {object} date - Date from which we want to extract the time.
 * @returns {string} Time in HH:mm format.
 */
export const dateToTimePickerString = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return [hours < 10 ? `0${hours}` : `${hours}`, minutes < 10 ? `0${minutes}` : `${minutes}`].join(
    ":",
  );
};

/**
 *  Builds a date object based on a base date and specific time parameters to override the hour of the
 * incoming date object. Resulting date would inherit the day, month and year from the incoming object,
 * and build the time based on the remaining parameters.
 *
 *  Used to reconstruct a date object based on the values of a set of `DatePicker` and `TimePicker` components.
 *
 * @param {object} date
 * @param {number} hours
 * @param {number} minutes
 * @param {number} [seconds]
 * @returns {object} Date object built by merging the data in the incoming parameters.
 */
export const updateDateTime = (date: Date, hours: number, minutes: number, seconds = 0): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds, 0);
