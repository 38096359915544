import { css } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}
export const GlobalToastCss = css`
  .toast {
    color: ${(props: Props) => props.theme.palette.neutral.white};
    background-color: ${(props: Props) => props.theme.palette.neutral._500};
    text-align: center;
  }

  .toast-error {
    color: ${(props: Props) => props.theme.palette.neutral.white};
    background-color: ${(props: Props) => props.theme.palette.feedback.error.brand};
    text-align: center;
  }

  .toast-warning {
    color: ${(props: Props) => props.theme.palette.neutral.white};
    background-color: ${(props: Props) => props.theme.palette.feedback.error.brand};
    text-align: center;
  }
`;
