import type React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "components/SelectUI/Select";
import type { Education } from "models/auth/Login";
import { Box, CssLabel } from "styles/helpers/layout";

interface EducationSelectorProps {
  educations?: Education[];
  onChange: (selectedEducationId: string | null) => void;
}

export const EducationSelector: React.FC<EducationSelectorProps> = ({
  educations = [],
  onChange,
}) => {
  const { t } = useTranslation("teacher-dashboard");

  const [selectedEducationId, setSelectedEducationId] = useState("all");

  const educationOptions = useMemo(
    () => [
      {
        value: "all",
        label: t("basicProgress.overview.filters.education.defaultOption", "All educations"),
      },
      ...educations.map(({ id, title }) => ({
        value: id,
        label: title,
      })),
    ],
    [educations],
  );

  return (
    <Box>
      <CssLabel id="education-selector-label">{t("selectors.educationSelectorLabel")}</CssLabel>
      <Select
        showSearchBox
        aria-labelledby="education-selector-label"
        dataCy="education-selector"
        options={educationOptions}
        showPlaceholderInOptionList={false}
        value={selectedEducationId}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          const selectedValue = event.target.value;
          setSelectedEducationId(selectedValue);
          onChange(selectedValue !== "all" ? selectedValue : null);
        }}
      />
    </Box>
  );
};
