import { Input } from "components/Input";
import type React from "react";
import type { InputWithErrorMessageProps } from "./InputWithErrorMessage.model";
import { StyledErrorMessage } from "./InputWithErrorMessage.styled";

export const InputWithErrorMessage: React.FC<InputWithErrorMessageProps> = ({
  dataCy,
  hasError,
  errorMessage,
  ...inputProps
}) => (
  <>
    <Input dataCy={dataCy} hasError={hasError} {...inputProps} />
    {hasError && (
      <StyledErrorMessage data-cy={`error-${dataCy}`}>{errorMessage}</StyledErrorMessage>
    )}
  </>
);
