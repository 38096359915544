import { Button } from "components/Button";
import { Link } from "components/Link";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { CssFlex } from "styles/helpers/layout";

export const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
`;

export const StyledSettingsContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  margin-top: 3.5rem;
`;

export const StyledExtraOptionsList = styled.ul`
  padding: 0;
  list-style-type: none;
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};

  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  li {
    display: flex;
    column-gap: 1rem;
    align-items: center;

    b {
      font-weight: normal;
      color: ${(props: ThemeProps) => props.theme.palette.neutral._400};
    }
  }
`;

export const StyledProctoringList = styled.ul`
  padding: 0;
  list-style-type: none;

  li {
    line-height: 2rem;
  }
`;

export const StyledReviewPeriodHeaderContainer = styled(CssFlex)`
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  > h4 {
    margin-bottom: 0;
  }
`;

export const StyledEditReviewPeriodButton = styled(Button)`
  .content {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
  }
`;

export const StyledLink = styled(Link)`
  column-gap: 0.5rem;
  font-weight: bold;`;
