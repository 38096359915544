import { ToastMethods } from "components/ToastNotification";
import { QuestionStatus, type TestType } from "constants/exam-constants";
import type { ResultsAPI } from "generated/types";
import i18n from "i18n";
import { action, observable } from "mobx";
import type { RequestErrorModel } from "models/error/Error";
import type {
  AnswerResult,
  QuestionResult,
  QuestionResultCorrectText,
  UserAnswer,
} from "models/exam/Exam";
import { resultsRepository } from "repositories";
import type { ErrorResponse } from "./useAdaptivePractice";

export interface EvaluateAnswerParams {
  testType: TestType;
  testToken: string;
  productId: number;
  questionId: number;
  answers: UserAnswer[];
  exerciseId?: number | undefined;
  skipAnswerValidation?: boolean | undefined;
}

export interface EvaluateAnswerResponse {
  questionResult: QuestionResult;
  status: QuestionStatus;
  results: AnswerResult[];
}

interface AnswerButtonProps {
  label: string;
  isDisabled: boolean;
  isHidden: boolean;
  isLoading?: boolean;
  onClick: (skipAnswerValidation?: boolean) => void;
}

export interface AnswerStoreState {
  questionTip: string | null;
  suggestedAnswer: string | null;
  showProgressButton: boolean;
  questionResult: QuestionResult | QuestionResultCorrectText | null;
  questionStatus: QuestionStatus;
  isEvaluatingAnswer: boolean;
  answerButtonProps: AnswerButtonProps;
}

interface AnswerStore extends AnswerStoreState {
  error: RequestErrorModel | null;
  goNextQuestion: boolean;
  setQuestionTip: (val: string | null) => void;
  setSuggestedAnswer: (val: string | null) => void;
  setShowProgressButton: (showBtn: boolean) => void;
  setQuestionResult: (questionResult: QuestionResult | QuestionResultCorrectText | null) => void;
  setQuestionStatus: (status: QuestionStatus) => void;
  setIsEvaluatingAnswer: (val: boolean) => void;
  setError: (error: RequestErrorModel | null) => void;
  setGoNextQuestion: (val: boolean) => void;
  setAnswerState: (initData: Omit<AnswerStoreState, "answerButtonProps">) => void;
  resetAnswerState: () => void;
  resetAnswerButtonProps: () => void;
  evaluateAnswer: (params: EvaluateAnswerParams) => Promise<EvaluateAnswerResponse | null>;
  setAnswerButtonProps: (props: AnswerButtonProps) => void;
}

const defaultAnswerButtonProps: AnswerButtonProps = {
  label: "",
  isDisabled: true,
  isLoading: false,
  isHidden: false,
  onClick: () => 0,
};

const initialState = {
  questionTip: null,
  suggestedAnswer: null,
  questionResult: null,
  error: null,
  showProgressButton: false,
  isEvaluatingAnswer: false,
  goNextQuestion: false,
  questionStatus: QuestionStatus.INITIAL,
  answerButtonProps: defaultAnswerButtonProps,
};

const stateSetters = {
  setQuestionTip: action((val: string | null) => {
    store.questionTip = val;
  }),
  setSuggestedAnswer: action((val: string | null) => {
    store.suggestedAnswer = val;
  }),
  setShowProgressButton: action((showBtn: boolean) => {
    store.showProgressButton = showBtn;
  }),
  setGoNextQuestion: action((val: boolean) => {
    store.goNextQuestion = val;
  }),
  setQuestionResult: action((questionResult: QuestionResult | QuestionResultCorrectText | null) => {
    store.questionResult = questionResult;
  }),
  setQuestionStatus: action((status: QuestionStatus) => {
    store.questionStatus = status;
  }),
  setIsEvaluatingAnswer: action((val: boolean) => {
    store.isEvaluatingAnswer = val;
  }),
  setError: action((val: RequestErrorModel | null) => {
    store.error = val;
  }),

  // Shortcut method to assign values to all variables related to the answer state
  setAnswerState: action(
    ({
      questionResult,
      questionTip,
      showProgressButton,
      isEvaluatingAnswer,
      suggestedAnswer,
      questionStatus,
    }: Omit<AnswerStoreState, "answerButtonProps">) => {
      store.setQuestionResult(questionResult);
      store.setQuestionTip(questionTip);
      store.setShowProgressButton(showProgressButton);
      store.setSuggestedAnswer(suggestedAnswer);
      store.setQuestionStatus(questionStatus);
      store.setIsEvaluatingAnswer(isEvaluatingAnswer);
    },
  ),

  // Reset answer state variables to their initial value
  resetAnswerState: action(() => {
    store.setAnswerState({
      isEvaluatingAnswer: false,
      questionResult: null,
      questionStatus: QuestionStatus.INITIAL,
      questionTip: null,
      showProgressButton: false,
      suggestedAnswer: null,
    });

    // Clear answer button props
    store.resetAnswerButtonProps();
  }),
  setAnswerButtonProps: action((updatedAnswerButtonProps: AnswerButtonProps) => {
    store.answerButtonProps = updatedAnswerButtonProps;
  }),
  resetAnswerButtonProps: action(() => {
    store.setAnswerButtonProps(defaultAnswerButtonProps);
  }),
};

const apiRequests = {
  evaluateAnswer: action(
    async (params: EvaluateAnswerParams): Promise<EvaluateAnswerResponse | null> => {
      const {
        answers,
        productId,
        exerciseId,
        questionId,
        testType,
        testToken,
        skipAnswerValidation,
      } = params;
      const payload: ResultsAPI.SubmittedAnswer = {
        solution: answers,
        exerciseId,
        questionId,
        skipAnswerValidation,
      };

      // Clear error before attempting a new request
      store.setError(null);
      store.setIsEvaluatingAnswer(true);

      try {
        const res = await resultsRepository.evaluateQuestion(
          productId,
          testType,
          testToken,
          payload,
        );
        const results = !res.results || !Array.isArray(res.results) ? [] : res.results;

        let status: QuestionStatus;
        switch (res.isAnswerCorrect) {
          case true:
            status = QuestionStatus.CORRECT;
            break;
          case false:
            status = QuestionStatus.INCORRECT;
            break;
          default:
            status = QuestionStatus.SUBMITTED;
        }

        if (skipAnswerValidation) {
          store.setGoNextQuestion(true);
        }

        // Update answer state based on the returned feedback
        store.setAnswerState({
          isEvaluatingAnswer: false,
          questionResult: res,
          questionStatus: status,
          questionTip: res.questionTip,
          showProgressButton: true,
          suggestedAnswer: res.suggestedAnswer,
        });

        // Make answer button hide, so "Next" button appears when user sees feedback
        store.setAnswerButtonProps({
          ...store.answerButtonProps,
          isHidden: true,
        });

        return { questionResult: res, status, results };
      } catch (e) {
        ToastMethods.showToast(i18n.t("toast:exam.error.evaluateAnswer"), "error");
        const { message, status, type } = <ErrorResponse>e;
        store.setError({ message, status, type });
        store.setIsEvaluatingAnswer(false);
        store.setQuestionStatus(QuestionStatus.INITIAL);
        return null;
      }
    },
  ),
};

const store: AnswerStore = observable({
  ...stateSetters,
  ...initialState,
  ...apiRequests,
} as AnswerStore);

export const useAnswer = (): AnswerStore => store;
