interface FontSizes {
  _10: string;
  _12: string;
  _14: string;
  _16: string;
  _18: string;
  _24: string;
  _32: string;
  _48: string;
  _56: string;
  _64: string;
}

export const TextSizeSmall = {
  _10: "0.4rem",
  _12: "0.5rem",
  _14: "0.75rem",
  _16: "0.8755rem",
  _18: "1rem",
  _24: "1.25rem",
  _32: "1.5rem",
  _48: "2rem",
  _56: "2.5rem",
  _64: "3rem",
};

export const TextSizeMedium = {
  _10: "0.5rem",
  _12: "0.75rem",
  _14: "0.875rem",
  _16: "1rem",
  _18: "1.125rem",
  _24: "1.5rem",
  _32: "2rem",
  _48: "2.5rem",
  _56: "3rem",
  _64: "3.5rem",
};

export const TextSizeLarge = {
  _10: "0.75rem",
  _12: "1rem",
  _14: "1.25rem",
  _16: "1.5rem",
  _18: "1.75rem",
  _24: "2rem",
  _32: "2.5rem",
  _48: "3rem",
  _56: "3.5rem",
  _64: "4rem",
};

interface ThemeTextSize {
  font: {
    size: FontSizes;
  };
}

export const getThemeTextSize = (textSize: string | undefined): ThemeTextSize => {
  let size: FontSizes;

  if (textSize === "small") {
    size = TextSizeSmall;
  } else if (textSize === "large") {
    size = TextSizeLarge;
  }
  // default, medium
  else {
    size = TextSizeMedium;
  }

  return {
    font: {
      size,
    },
  };
};
