import styled, { css } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type { StyledInfoCircleProps } from "./InfoCircle.model";

interface Props extends StyledInfoCircleProps {
  theme: ElearningThemeInterface;
}

const infoCircleVariant = {
  default: css`
    .InfoCircle--circle {
      background-color: ${(props: Props) => props.theme.palette.neutral._900};
    }
    .InfoCircle--subtext {
      color: ${(props: Props) => props.theme.palette.neutral._400};
    }
  `,
  primary: css`
    color: ${(props: Props) => props.theme.palette.primary.brand};
    .InfoCircle--circle {
      background-color: ${(props: Props) => props.theme.palette.neutral._950};
    }
    .InfoCircle--subtext {
      color: ${(props: Props) => props.theme.palette.primary.brand};
    }
  `,
  success: css`
    .InfoCircle--circle {
      background-color: ${(props: Props) => props.theme.palette.feedback.success._800};
    }
    .InfoCircle--subtext {
      color: ${(props: Props) => props.theme.palette.neutral._400};
    }
  `,
  warning: css`
    .InfoCircle--circle {
      background-color: ${(props: Props) => props.theme.palette.yellow._800};
    }
    .InfoCircle--subtext {
      color: ${(props: Props) => props.theme.palette.neutral._400};
    }
  `,
};
export const StyledInfoCircle = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => infoCircleVariant[props.variant || "default"]};
  .InfoCircle--circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: ${(props: Props) => (props.radius ?? 24) * 2}px;
    height: ${(props: Props) => (props.radius ?? 24) * 2}px;

    .InfoCircle--value {
      font-weight: bold;
      font-size: ${(props: Props) => props.radius ?? 16}px;
    }
  }

  .InfoCircle--subtext {
    margin-top: 8px;
  }
`;
