import format from "date-fns/format";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ADMIN_ENVIRONMENT_ROUTES } from "constants/routes";
import { useAdmin } from "hooks/useAdmin";
import { useParams } from "react-router-dom";

import { Heading2, Heading3, Heading4 } from "styles/elements/Headings";
import { UserAdminBreadcrumbs } from "../../components/UserAdminBreadcrumbs/UserAdminBreadcrumbs";
import { StyledUserScheduledTestsGroup, StyledWrapper } from "./UserScheduledTestsGroup.styled";
import { CardScheduledTest } from "./components/CardScheduledTest/CardScheduledTest";

export interface PathParams {
  date: string;
}

export const UserScheduledTestsGroup: React.FC = observer(() => {
  const { t } = useTranslation("admin-environment");
  const { date }: PathParams = useParams();

  const dateFormat = format(new Date(date), "Y-MM-dd");

  const { fetchScheduledTestsByDate, scheduledTestsByDate } = useAdmin();

  useEffect(() => {
    fetchScheduledTestsByDate(dateFormat);
  }, [date]);

  return (
    <StyledUserScheduledTestsGroup>
      <UserAdminBreadcrumbs
        currentPageLabel={t("scheduledTestGroup.breadcrumb.details", "Details")}
        currentPagePath={ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS_GROUP}
        previousAdminUrl={ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS}
        previousRoutePath={ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS}
        previousRoutePathLabel={t(
          "scheduledTestGroup.breadcrumb.scheduledTests",
          "Scheduled tests",
        )}
        previousText={t("scheduledTestGroup.breadcrumb.backButton", "Back")}
      />
      <StyledWrapper data-cy="scheduled-grouped-tests">
        <Heading2>{t("scheduledTestGroup.header", "Test scheduled")}</Heading2>
        <Heading4 data-cy="date-scheduled-test">{date}</Heading4>
        {Object.values(scheduledTestsByDate).map(({ schoolName, scheduledTests }) => (
          <section key={`row-${schoolName}`} data-cy="school-test-group">
            <Heading3>
              {schoolName || t("scheduledTestGroup.defaultSchoolName", "Unknown school")}
            </Heading3>
            {scheduledTests?.map((test) => (
              <CardScheduledTest key={test.id} {...test} />
            ))}
          </section>
        ))}
      </StyledWrapper>
    </StyledUserScheduledTestsGroup>
  );
});
