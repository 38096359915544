import { Modal } from "components/Modal";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { ParagraphLarge } from "styles/elements/Texts";

export interface StyledAbsoluteToolTipProps extends ThemeProps {
  $left: number;
  $top: number;
}

export const StyledAccessibilityMenu = styled(Modal)`
  top: 3rem;
  right: 15rem;
  margin-top: 1rem;
  padding: 1rem;
  width: 30rem;
  background: ${({ theme }) => theme.palette.neutral._999 as string};
  border-radius: 0.5rem;
  position: fixed;
`;

export const StyledParagraph = styled(ParagraphLarge)`
  margin-right: 1rem;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;

  button {
    margin-left: auto;
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  }
`;
