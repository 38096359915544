import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledAdminPage = styled.div`
  display: flex;
  margin-top: ${calculateRem(80)};

  .aside-menu {
    padding-right: 2rem;
  }

  main {
    max-width: 70rem;
  }
`;
