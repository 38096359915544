import { useDomainHandler } from "hooks/useDomainHandler";
import type React from "react";
import { useTranslation } from "react-i18next";
import { Heading2 } from "styles/elements/Headings";
import { StyledWrapper } from "../InviteStudent/InviteStudent.styled";
import { type EmailType, MultipleEmailInput } from "../MultipleEmailInput/MultipleEmailInput";
import { StyledText } from "./CreateAccount.styled";

export interface CreateAccountProps {
  selectedEmails: EmailType[];
  onSelectedEmailsChanged: (emails: EmailType[]) => void;
}

export const CreateAccount: React.FC<CreateAccountProps> = ({
  selectedEmails = [],
  onSelectedEmailsChanged,
}) => {
  const { t } = useTranslation("teacher-dashboard");
  const { getCustomNameByDomain, getStudentTermByDomain } = useDomainHandler();

  return (
    <StyledWrapper>
      <Heading2>{t("studentLicense.createAccounts.title")}</Heading2>
      <StyledText>
        {t("studentLicense.createAccounts.createAccountsAction.description", {
          appName: getCustomNameByDomain(),
          studentTerm: getStudentTermByDomain({ usePlural: true }),
        })}
      </StyledText>
      <MultipleEmailInput
        selectedEmails={selectedEmails}
        width="75%"
        onSelectedEmailsChanged={onSelectedEmailsChanged}
      />
    </StyledWrapper>
  );
};
