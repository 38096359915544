import { ROLES } from "@infinitaslearning/module-he-common";

import type { SupportWidgetVariantConfig } from "../../models/variant.model";
import { commonWidgetOptions } from "./common";

const BusinessSupportWidgetConfig: SupportWidgetVariantConfig = {
  apiKey: "5010cab9-2d0d-46cd-8de0-71a2d60e5a2e",
  configPerRole: {
    [ROLES.STUDENT]: {
      options: commonWidgetOptions,
      suggestions: {},
    },
    [ROLES.TEACHER]: {
      options: commonWidgetOptions,
      suggestions: {},
    },
  },
};

export default BusinessSupportWidgetConfig;
