import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

import { calculateRem } from "styles/mixins/calculateRem";

import { Breadcrumbs } from "components/Breadcrumbs";
import { Button } from "components/Button";

export const StyledUserAdminBreadcrumbs = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)<ThemeProps>`
  div:last-of-type a {
    color: ${({ theme }: ThemeProps) => theme.palette.secondary.brand};
  }
`;

export const StyledButton = styled(Button)<ThemeProps>`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  margin-right: 32px;

  svg {
    margin-right: ${calculateRem(16)};
  }
`;
