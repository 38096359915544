import { QuestionStatus } from "constants/exam-constants";
import { useAnswer } from "hooks/useAnswer";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProfile } from "hooks/useProfile";
import { observer } from "mobx-react";
import type React from "react";
import { type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CssFlex } from "styles/helpers/layout";
import { StyledAnswerButton } from "./AnswerButton.styled";

export interface AnswerButtonProps {
  children?: React.ReactNode;
  disabled: boolean;
  isLoading?: boolean;
  showConfirmAnswerButton: boolean;
  onSubmitAnswer: (skipAnswerValidation?: boolean) => void;
}

export const AnswerButton: FC<AnswerButtonProps> = observer(
  ({ children, disabled, isLoading, showConfirmAnswerButton, onSubmitAnswer }) => {
    const { t } = useTranslation("skill-determination-test");
    const { alphaTesterIds } = useFeatureFlags();
    const { userDetails, fetchUserDetails } = useProfile();
    const { questionStatus } = useAnswer();

    useEffect(() => {
      if (!userDetails) {
        fetchUserDetails();
      }
    }, [userDetails]);

    const canSkipAnswerValidation = userDetails
      ? alphaTesterIds.toString().split(",").includes(userDetails.id.toString())
      : false;

    return (
      <CssFlex gap={1}>
        {showConfirmAnswerButton && (
          <StyledAnswerButton
            dataCy="check-answer-button"
            disabled={disabled}
            isLoading={isLoading}
            variant="primary"
            onClick={() => onSubmitAnswer()}
          >
            {children || t("button.checkAnswer.label", "Check answer")}
          </StyledAnswerButton>
        )}
        {canSkipAnswerValidation && questionStatus === QuestionStatus.INITIAL && (
          <StyledAnswerButton
            isCheatButton
            dataCy="check-bypassed-answer-button"
            isLoading={isLoading}
            variant="brand"
            onClick={() => onSubmitAnswer(true)}
          >
            {t("button.checkAnswerBypass.label", "Cheat")}
          </StyledAnswerButton>
        )}
      </CssFlex>
    );
  },
);
