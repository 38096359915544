import type React from "react";
import { useTranslation } from "react-i18next";

import { IconButton } from "components/IconButton/IconButton";
import { useModal } from "hooks/useModal";
import type { ToolTipModalProps } from "./TooltipModal.model";
import { StyledHeading, StyledParagraph, StyledTooltipModal } from "./TooltipModal.styled";

export const TooltipModal: React.FC<ToolTipModalProps> = ({ dataCy, modalId, content }) => {
  const { t } = useTranslation("common");
  const { closeModal, isModalOpen, toggleModal } = useModal();
  const { title, paragraph } = content;

  return (
    <>
      <IconButton
        aria-label={t("iconButtonLabel.info")}
        dataCy={`open-tooltip-modal-${dataCy}`}
        fontColor="grey"
        iconName="infoIcon"
        iconSize={1.25}
        onClick={() => toggleModal(modalId)}
      />
      <StyledTooltipModal
        closeOnClickOutside
        wrapInModalBox
        dataCy={dataCy}
        isOpen={isModalOpen(modalId)}
        onClose={closeModal}
      >
        <StyledHeading>{title}</StyledHeading>
        <StyledParagraph>{paragraph}</StyledParagraph>
      </StyledTooltipModal>
    </>
  );
};
