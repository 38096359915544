import React from "react";
import { useTranslation } from "react-i18next";

import { SvgIconReact } from "components/SvgIconReact";
import type { SearchBoxProps } from "./SearchBox.model";
import { StyledInput, StyledSearchBox } from "./SearchBox.styled";

const MySearchBox = (
  {
    className,
    dataCy,
    name,
    placeholder,
    value,
    onBlur,
    onChange,
    onEnter,
    onFocus,
  }: SearchBoxProps,
  ref?: React.Ref<HTMLDivElement>,
) => {
  const { t } = useTranslation("common");
  return (
    <StyledSearchBox ref={ref} className={className}>
      <SvgIconReact iconName="searchIcon" />
      <StyledInput
        dataCy={dataCy || "search-box"}
        name={name || "search-box"}
        placeholder={placeholder || t("searchBox.placeholder")}
        value={value}
        onBlur={(e) => onBlur?.(e.target.value)}
        onChange={(e) => onChange?.(e.target.value)}
        onFocus={(e) => onFocus?.(e.target.value)}
        onKeyUp={(e) => e.key === "Enter" && onEnter && onEnter()}
      />
    </StyledSearchBox>
  );
};

export const SearchBox = React.forwardRef<HTMLDivElement, SearchBoxProps>(MySearchBox);
