import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";

import { ParagraphLarge } from "styles/elements/Texts";

export const StyledNotice = styled.div<Props>`
  padding: 2rem;
  background-color: ${(props: Props) => props.theme.palette.feedback.info._950};
  margin-bottom: 2rem;
`;

export const StyledButtonContainer = styled.div`
  text-align: right;
`;

export const StyledParagraph = styled(ParagraphLarge)`
  line-height: 150%;
`;
