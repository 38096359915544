import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { Button } from "components/Button";
import { TEACHER_INSIGHT_PAGE_ROUTES } from "constants/routes";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import { observer } from "mobx-react";
import { type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Heading3, Heading4 } from "styles/elements/Headings";
import { LevelProgressBar } from "../../../BasicProgress/components/LevelProgressBar/LevelProgressBar";
import { StyledLevelProgressBarWrapper, StyledWrapper } from "./AvgKnowledgeLevel.styled";

export interface AverageKnowledgeLevelProps {
  productId: number;
}

export const AvgKnowledgeLevel: FC<AverageKnowledgeLevelProps> = observer(({ productId }) => {
  const history = useHistory();
  const { t } = useTranslation("teacher-dashboard");
  const { productDetails, fetchProductDetails } = useProduct();
  const { getStudentTermByDomain } = useDomainHandler();

  const { averageKnowledgeDetails, fetchAverageKnowledgeDetails } = useProductProgress();

  useEffect(() => {
    fetchProductDetails(productId);
  }, [productId]);

  useEffect(() => {
    if (productDetails?.id) {
      fetchAverageKnowledgeDetails(productDetails.id);
    }
  }, [productDetails?.id]);

  return (
    <StyledWrapper>
      <div>
        <Heading3>
          {t("homePage.avgKnowledgeLevel.title", {
            totalStudents: averageKnowledgeDetails?.totalStudents ?? 0,
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
        </Heading3>
        <Button
          dataCy="view-basic-progress-page"
          variant="border"
          onClick={() =>
            history.push(
              buildUrlWithPathParams(TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW, {
                productId: productDetails?.id ?? 0,
              }),
            )
          }
        >
          {t("homePage.avgKnowledgeLevel.button")}
        </Button>
      </div>
      <div data-cy="avg-knowledge-levels">
        {averageKnowledgeDetails?.avgKnowledgeLevels?.map((subject) => (
          <div key={subject.title}>
            <Heading4>{subject.title}</Heading4>
            <Heading3>
              {
                productDetails?.levels.find(
                  ({ level }) => level === Math.round(subject.avgSkillLevel),
                )?.label
              }
            </Heading3>
            <StyledLevelProgressBarWrapper>
              <LevelProgressBar currentLevel={subject.avgSkillLevel} />
              <span>{`${Math.round(subject.progress)}%`}</span>
            </StyledLevelProgressBarWrapper>
          </div>
        ))}
      </div>
    </StyledWrapper>
  );
});
