import type React from "react";
import { Cell, Pie, PieChart } from "recharts";
import { useTheme } from "styled-components/macro";
import { CssFlex } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import {
  type CustomLabelProps,
  PROGRESS_TYPE,
  type SubjectDoughnutChartProps,
} from "./SubjectDoughnutChart.model";

export const SubjectDoughnutChart: React.FC<SubjectDoughnutChartProps> = ({
  subjectDataForChart,
}) => {
  const theme = useTheme() as ElearningThemeInterface;
  const CustomLabel = ({ cx, cy, value, type, title }: CustomLabelProps) => {
    if (type === PROGRESS_TYPE.UNCOMPLETED) return null;
    return (
      <>
        <text
          data-cy="average-progress"
          dy={8}
          fill={theme.palette.text.secondary}
          fontWeight="bold"
          textAnchor="middle"
          x={cx}
          y={cy}
        >
          {`${value}%`}
        </text>
        <text
          dy={8}
          fill={theme.palette.text.secondary}
          fontWeight="bold"
          textAnchor="middle"
          x={cx}
          y={+cy + 70}
        >
          {title}
        </text>
      </>
    );
  };

  return (
    <CssFlex data-cy="card-large" flexWrap="wrap" justifyContent="space-evenly" p={20}>
      {subjectDataForChart.map((sd) => (
        // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
        <PieChart height={180} width={220}>
          <Pie
            aria-label={sd[0].subjectTitle}
            cx={100}
            cy={70}
            data={sd}
            dataKey="value"
            endAngle={-270}
            innerRadius={45}
            isAnimationActive={false}
            label={({ cx, cy, value, type, subjectTitle }) => (
              <CustomLabel cx={cx} cy={cy} title={subjectTitle} type={type} value={value} />
            )}
            labelLine={false}
            outerRadius={60}
            startAngle={90}
          >
            {sd.map(({ color }) => (
              // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
              <Cell fill={color} stroke={color} />
            ))}
          </Pie>
        </PieChart>
      ))}
    </CssFlex>
  );
};
