import { useCallback, useMemo } from "react";

import { useProduct } from "hooks/useProduct";
import { ModuleType } from "models/product/ProductDetails";

interface ModuleAvailabilityInterface {
  isAdaptiveModuleAvailable: boolean;
  isWritingModuleAvailable: boolean;
}

// Return some flags to easily know if current product contains module of a specific type
export const useModuleAvailability = (): ModuleAvailabilityInterface => {
  const { productDetails } = useProduct();

  const isModuleTypeAvailable = useCallback(
    (moduleType: ModuleType) =>
      !!productDetails && productDetails.modules.some(({ type }) => type === moduleType),
    [productDetails],
  );

  const isAdaptiveModuleAvailable = useMemo(
    () => isModuleTypeAvailable(ModuleType.ADAPTIVE),
    [isModuleTypeAvailable],
  );

  const isWritingModuleAvailable = useMemo(
    () => isModuleTypeAvailable(ModuleType.WRITING),
    [isModuleTypeAvailable],
  );

  return {
    isAdaptiveModuleAvailable,
    isWritingModuleAvailable,
  };
};
