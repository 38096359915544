import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import type { ExamQuestion } from "models/exam/Exam";

import type { ExerciseQuestionResultsProps } from "./ExerciseQuestionResults.model";
import { StyledFilterButton, StyledFilterButtonContainer } from "./ExerciseQuestionResults.styled";
import { QuestionWithResult } from "./components/QuestionWithResult/QuestionWithResult";

export const ExerciseQuestionResults: React.FC<ExerciseQuestionResultsProps> = ({
  exerciseQuestions,
  exerciseQuestionResults,
}) => {
  const { t } = useTranslation("test-environment");
  const [showFailedOnly, setShowFailedOnly] = useState(false);

  return (
    <div data-cy="question-container">
      <StyledFilterButtonContainer>
        <StyledFilterButton
          data-cy="show-all-questions-button"
          variant={!showFailedOnly ? "brand" : "secondary"}
          onClick={() => setShowFailedOnly(false)}
        >
          {t("testResults.exerciseAnswerFilters.all", "All answers")}
        </StyledFilterButton>
        <StyledFilterButton
          data-cy="show-failed-questions-button"
          variant={showFailedOnly ? "brand" : "secondary"}
          onClick={() => setShowFailedOnly(true)}
        >
          {t("testResults.exerciseAnswerFilters.failed", "Wrong answers")}
        </StyledFilterButton>
      </StyledFilterButtonContainer>
      {exerciseQuestions.map((question: ExamQuestion, index) => {
        const questionResult = exerciseQuestionResults.find(({ id }) => id === question.id);
        const isAnswerIncorrect = !questionResult || !questionResult.answerCorrect;

        if (showFailedOnly && !isAnswerIncorrect) {
          return null;
        }

        return (
          <QuestionWithResult
            key={question.id}
            index={index + 1}
            isAnswerIncorrect={isAnswerIncorrect}
            question={question}
            questionResult={questionResult}
          />
        );
      })}
    </div>
  );
};
