import styled, { css } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
  basic?: boolean;
  collapsed: boolean;
}

export const StyledAccordionItem = styled.div<Props>`
  width: 100%;
  overflow: hidden;
  border: ${(props: Props) => (props.basic ? "none" : `1px solid ${props.theme.palette.neutral._900}`)};

  .tab-label {
    border: 0;
    width: 100%;
    background: none;
    outline: none;
    display: flex;
    padding: 1.5rem;
    align-items: center;
    text-align: left;
    font-weight: ${(props: Props) => props.theme.font.weight.normal};
  }

  .tab-label::before {
    content: '\\276F';
    color: ${(props: Props) => props.theme.palette.neutral.black};
    font-size: ${(props: Props) => props.theme.font.size._14};
    width: 1rem;
    height: 100%;
    margin-right: 1.5rem;
    text-align: center;
    transition: all 0.35s;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
  }

  .tab-label .tab-title .tab-title-text {
    font-weight: ${(props: Props) => (props.basic ? props.theme.font.weight.normal : props.theme.font.weight.bold)};
    font-size: ${(props: Props) => (props.basic ? props.theme.font.size._14 : props.theme.font.size._24)};
    color: ${(props: Props) => props.theme.palette.neutral.black};
    margin: 0;
  }

  .tab-label .tab-title .description {
    font-weight: ${(props: Props) => props.theme.font.weight.normal};
    color: ${(props: Props) => props.theme.palette.neutral.black};
    font-size: ${(props: Props) => props.theme.font.size._14};
  }

  .tab-content {
    max-height: 0;
    padding: ${(props: Props) => (props.basic ? "0 2rem" : "0 1.5rem 0 4rem")};
    background: ${(props: Props) => props.theme.palette.neutral.white};
    transition: all 0.35s ease;
  }

  ${(props: Props) =>
    !props.collapsed &&
    css`
      .tab-label {
        &::before {
          transform: rotate(-90deg);
        }
      }

      .tab-content {
        max-height: 100%;
        padding: ${(props: Props) => (props.basic ? "0 2rem" : "0 1.5rem 0 4rem")};
        overflow: auto;
      }
    `}
`;

export const StyledAccordionHeader = styled.div`
  position: relative;
`;

export const StyledAccordionTrigger = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
`;
