import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CorrectTextQuestion } from "layouts/QuestionManager/components/CorrectTextQuestion/CorrectTextQuestion";
import type { CorrectTextQuestionProps } from "layouts/QuestionManager/components/CorrectTextQuestion/CorrectTextQuestion.model";
import { CssFlex } from "styles/helpers/layout";
import { StyledButton, StyledButtonWrapper } from "./CompareCorrectTextQuestionResult.styled";

// Not destructured on purpose, we pass them as it is to the lower component. Only note that `isComparingAnswers` is
// the only thing that is overriden
export const ReviewAndCompareCorrectTextQuesiton: React.FC<CorrectTextQuestionProps> = (
  correctTextQuestionProps,
) => {
  const { t } = useTranslation("results");
  const [enableCompareMode, setEnabledCompareMode] = useState(false);
  const toggleQuestionFlag = () => {
    setEnabledCompareMode(!enableCompareMode);
  };

  return (
    <CssFlex flexDirection="column">
      <CorrectTextQuestion {...correctTextQuestionProps} isComparingAnswers={enableCompareMode} />
      <StyledButtonWrapper>
        <StyledButton
          key="compare-answerbutton"
          dataCy="compare-answer-button"
          style={{ marginBottom: "2rem" }}
          variant="secondary"
          onClick={() => toggleQuestionFlag()}
        >
          {t("button.compareAnswer")}
        </StyledButton>
      </StyledButtonWrapper>
    </CssFlex>
  );
};
