import type React from "react";
import { useTranslation } from "react-i18next";

import type { Payload as LegendPayload } from "recharts/types/component/DefaultLegendContent";

import { Heading4 } from "styles/elements/Headings";
import { CssIcon } from "styles/helpers/layout";
import { StyledLegend } from "./RechartsCustomLegend.styled";

interface RechartsCustomLegendProps {
  dataCy?: string;
  title?: string | null;
  payload?: LegendPayload[];
  centerHorizontally?: boolean;
}

export const RechartsCustomLegend: React.FC<RechartsCustomLegendProps> = ({
  dataCy = "chart-legend",
  title,
  payload = [],
  centerHorizontally = false,
}) => {
  const { t } = useTranslation("common");
  const showHeading = title !== null;

  return (
    <StyledLegend $centerHorizontally={centerHorizontally}>
      {showHeading && <Heading4>{title || t("chart.legend")}</Heading4>}
      <ul data-cy={dataCy}>
        {payload.map(({ dataKey, value, color }, index) => (
          <li
            key={dataKey ? dataKey.toString() : index}
            data-cy={`${dataCy}-item`}
            data-value={dataKey}
          >
            <CssIcon iconColor={color} iconName="fas fa-circle" />
            {value}
          </li>
        ))}
      </ul>
    </StyledLegend>
  );
};
