import { useEffect, useState } from "react";
import type { RouteComponentProps } from "react-router-dom";

import type { LocationChangeParams } from "models/params/location-change-params";

const useOnLocationChange = (history: RouteComponentProps["history"]): LocationChangeParams => {
  const { location } = history;
  const [locationPath, setLocationPath] = useState(location.pathname);
  const [queryParams, setQueryParams] = useState(location.search);

  const onLocationChange = (newLocation: RouteComponentProps["location"]) => {
    const locationPathName = newLocation.pathname;
    setLocationPath(locationPathName);
    const queryParameters = newLocation.search;
    setQueryParams(queryParameters);
  };

  useEffect(() => {
    onLocationChange(location);
  }, [history.location]);
  return { locationPath, queryParams };
};

export default useOnLocationChange;
