import { HST_ERROR_CODES } from "@infinitaslearning/module-he-common";
//TODO: import { useDomainHandler } from 'hooks/useDomainHandler' and convert this in a hook with shared context among other hooks of this kind;
import { getStudentTermByDomain } from "_helpers/domainHandler";
import { action, observable } from "mobx";

import type { ContentAPI } from "generated/types";

import i18n from "i18n";
import type { ModuleDetails, ModuleSubject } from "models/product/ModuleDetails";
import type { ProductDetails } from "models/product/ProductDetails";
import type { ProductItem } from "models/product/ProductItem";
import type { ProductTestGroup, ProductTestGroupType } from "models/product/ProductTestGroups";
import type { SchoolYear } from "models/product/SchoolYear";
import type { TopicDetails } from "models/product/TopicDetails";
import { productsListRepository } from "repositories";

import { ToastMethods } from "components/ToastNotification";
import type {
  InvitationDetails,
  InvitationList,
  InvitationListFilter,
  StudentInvitation,
  StudentInvitationResponse,
} from "models/product/StudentInvitation";

interface ProductsStoreInterface {
  loading: boolean;
  topicDetails?: TopicDetails;
  moduleDetails?: ModuleDetails;
  productsList?: ProductItem[];
  productDetails?: ProductDetails;
  productSubjects?: ModuleSubject[];
  productSubject?: ContentAPI.SubjectDetails;
  productTestGroups?: ProductTestGroup[];
  productSchoolYears?: SchoolYear[];
  nonOwnedProductError?: boolean;
  studentInvitationDetails?: StudentInvitationResponse;
  error?: Error | null;
  productInvitationAccepted: boolean;
  invitationDetails?: InvitationDetails;
  invitationList: InvitationList;
  fetchProducts: () => void;
  fetchProductDetails: (id: number) => void;
  fetchModuleDetails: (producId: number, moduleId: number) => void;
  fetchTopicDetails: (productId: number, topicId: number) => void;
  fetchProductSubjects: (productId: number) => void;
  fetchProductSubjectDetails: (productId: number, subjectId: number) => void;
  fetchProductTestGroups: (productId: number, testType: ProductTestGroupType) => void;
  fetchProductSchoolYears: (productId: number) => void;
  fetchInvitationDetails: (invitationId: string) => void;
  deleteInviteById: (invitationId: string) => Promise<boolean>;
  setLoading: (isLoading: boolean) => void;
  setProductList: (products: ProductItem[]) => void;
  setProductDetails: (productDetails: ProductDetails | undefined) => void;
  setModuleDetails: (moduleDetails: ModuleDetails) => void;
  setTopicDetails: (topicDetails: TopicDetails) => void;
  setProductSubjects: (subjects: ModuleSubject[]) => void;
  setProductSubject: (subjects: ContentAPI.SubjectDetails) => void;
  setProductTestGroups: (testGroups: ProductTestGroup[]) => void;
  setProductSchoolYears: (schoolYears: SchoolYear[]) => void;
  setNonOwnedProductError: (nonOwnedProductError: boolean) => void;
  setProductInvitationAccepted: (productInvitationAccepted: boolean) => void;
  setStudentInvitationDetails: (studentInvitationDetails: StudentInvitationResponse) => void;
  setInvitationList: (invitationList: InvitationList) => void;
  setInvitationDetails: (invitationDetails: InvitationDetails) => void;
  postInviteStudents: (studentInvitation: StudentInvitation) => void;
  postAcceptProductInvitation: (invitationId: string) => void;
  fetchInvitationList: (filters: InvitationListFilter) => void;
}

// initial state should be undefined because they are not loaded yet...
const initialState = {
  loading: false,
  productInvitationAccepted: false,
  invitationList: {
    totalInvitations: 0,
    invitations: [],
  },
};

const stateSetters = {
  setTopicDetails: action((topicDetails: TopicDetails) => {
    store.topicDetails = topicDetails;
  }),
  setModuleDetails: action((moduleDetails: ModuleDetails) => {
    store.moduleDetails = moduleDetails;
  }),
  setProductList: action((productsList: ProductItem[]) => {
    store.productsList = productsList;
  }),
  setProductDetails: action((productDetails: ProductDetails) => {
    store.productDetails = productDetails;
  }),
  setProductSubjects: action((subjects: ModuleSubject[]) => {
    store.productSubjects = subjects;
  }),
  setProductSubject: action((subject: ContentAPI.SubjectDetails) => {
    store.productSubject = subject;
  }),
  setProductTestGroups: action((testGroups: ProductTestGroup[]) => {
    store.productTestGroups = testGroups;
  }),
  setProductSchoolYears: action((schoolYears: SchoolYear[]) => {
    store.productSchoolYears = schoolYears;
  }),
  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),
  setNonOwnedProductError: action((nonOwnedProductError: boolean) => {
    store.nonOwnedProductError = nonOwnedProductError;
  }),
  setStudentInvitationDetails: action((studentInvitationDetails: StudentInvitationResponse) => {
    store.studentInvitationDetails = studentInvitationDetails;
  }),
  setProductInvitationAccepted: action((productInvitationAccepted: boolean) => {
    store.productInvitationAccepted = productInvitationAccepted;
  }),
  setInvitationDetails: action((invitationDetails: InvitationDetails) => {
    store.invitationDetails = invitationDetails;
  }),
  setInvitationList: action((invitationList: InvitationList) => {
    store.invitationList = invitationList;
  }),
};

const apiRequests = {
  fetchProducts: action(() => {
    // for students it will return the student's active products
    // for teachers this will return all products
    store.setLoading(true);
    productsListRepository
      .getProductList()
      .then((products: ProductItem[]) => {
        store.setProductList(products);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:profile.getproducts.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  fetchProductDetails: action((id: number) => {
    store.setLoading(true);
    productsListRepository
      .getProductDetails(id)
      .then((productDetails: ProductDetails) => {
        store.setProductDetails(productDetails);
      })
      .catch((error) => {
        ToastMethods.showToast(i18n.t("toast:profile.getproducts.error"), "error");
        if (error.errCode === HST_ERROR_CODES.NOT_OWNED_PRODUCT) {
          store.setNonOwnedProductError(true);
        }
      })
      .finally(() => {
        store.setNonOwnedProductError(false);
        store.setLoading(false);
      });
  }),
  fetchProductSubjects: action((productId: number) => {
    // fetch all subjects inside all modules of this product
    store.setLoading(true);
    productsListRepository
      .getProductDetails(productId)
      .then((product) =>
        Promise.all(
          product.modules.map((module) =>
            productsListRepository.getModuleDetails(productId, module.id),
          ),
        ),
      )
      .then((modules) => {
        store.setProductSubjects(
          modules.reduce(
            (subjects, module) => subjects.concat(module.subjects),
            [] as ModuleSubject[],
          ),
        );
      })
      .catch((error) => {
        if (error.errCode === HST_ERROR_CODES.NOT_OWNED_PRODUCT) {
          store.setNonOwnedProductError(true);
        }
        ToastMethods.showToast(i18n.t("toast:product.getsubjects.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
        store.setNonOwnedProductError(false);
      });
  }),
  fetchProductSubjectDetails: action((productId: number, subjectId: number) => {
    // fetch all subjects inside all modules of this product
    store.setLoading(true);
    productsListRepository
      .getProductSubjectDetails(productId, subjectId)
      .then((subjectDetails: ContentAPI.SubjectDetails) => {
        store.setProductSubject(subjectDetails);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:product.getsubjects.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  fetchProductTestGroups: action((productId: number, testType: ProductTestGroupType) => {
    store.setLoading(true);
    productsListRepository
      .getProductTestsBySkillLevel(productId, testType)
      .then((testGroups: ProductTestGroup[]) => {
        store.setProductTestGroups(testGroups);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:product.gettestgroups.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  fetchModuleDetails: action((productId: number, moduleId: number) => {
    store.setLoading(true);
    productsListRepository
      .getModuleDetails(productId, moduleId)
      .then((moduleDetails: ModuleDetails) => {
        store.setModuleDetails(moduleDetails);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:profile.getmodules.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  fetchTopicDetails: action((productId: number, topicId: number) => {
    store.setLoading(true);
    productsListRepository
      .getTopicDetails(productId, topicId)
      .then((topicDetails: TopicDetails) => {
        store.setTopicDetails(topicDetails);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:profile.gettopic.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  fetchProductSchoolYears: action((productId: number) => {
    store.setLoading(true);
    productsListRepository
      .getProductSchoolYears(productId)
      .then((schoolYears: SchoolYear[]) => {
        store.setProductSchoolYears(schoolYears);
      })
      .catch((error) => {
        if (error.errCode === HST_ERROR_CODES.NOT_OWNED_PRODUCT) {
          store.setNonOwnedProductError(true);
        }
        store.setProductSchoolYears([]);
        ToastMethods.showToast(i18n.t("toast:product.getProductSchoolYears.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
        store.setNonOwnedProductError(false);
      });
  }),
  fetchInvitationDetails: action((invitationId: string) => {
    store.setLoading(true);
    productsListRepository
      .getInvitationDetails(invitationId)
      .then((invitationDetails: InvitationDetails) => {
        store.setInvitationDetails(invitationDetails);
      })
      .catch(() =>
        ToastMethods.showToast(i18n.t("toast:product.getInvitationDetails.error"), "error"),
      )
      .finally(() => store.setLoading(false));
  }),
  // Delete an existing invite based on its id
  deleteInviteById: action((invitationId: string): Promise<boolean> => {
    store.setLoading(true);
    return productsListRepository
      .deleteInviteById(invitationId)
      .then(() => {
        ToastMethods.showToast(i18n.t("toast:product.deletePendingInvite.success"), "success");
        return Promise.resolve(true);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:product.deletePendingInvite.error"), "error");
        return Promise.resolve(false);
      })
      .finally(() => store.setLoading(false));
  }),
  fetchInvitationList: action((filters: InvitationListFilter) => {
    store.setLoading(true);
    productsListRepository
      .fetchInvitationList(filters)
      .then((invitationList: InvitationList) => {
        store.setInvitationList(invitationList);
      })
      .catch(() =>
        ToastMethods.showToast(i18n.t("toast:product.getInvitationDetails.error"), "error"),
      )
      .finally(() => store.setLoading(false));
  }),
  postInviteStudents: action((studentInvitation: StudentInvitation) => {
    store.setLoading(true);
    productsListRepository
      .postInviteStudents(studentInvitation)
      .then((studentInvitationResponse: StudentInvitationResponse) => {
        store.setStudentInvitationDetails(studentInvitationResponse);
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:product.postInviteStudents.error", {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          }),
          "error",
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  postAcceptProductInvitation: action((invitationId: string) => {
    store.setLoading(true);
    productsListRepository
      .postAcceptProductInvitation(invitationId)
      .then(() => {
        store.setProductInvitationAccepted(true);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:studentLicense.acceptInvite.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
};

const store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as ProductsStoreInterface);

export const useProduct = (): ProductsStoreInterface => store;
