import type React from "react";
import { useTranslation } from "react-i18next";

import { SvgIconReact } from "components/SvgIconReact";
import type TestCompletedMessageProps from "./TestCompletedMessage.model";
import {
  StyledButtonsSection,
  StyledHeaderFinishedQuestion,
  StyledParagraphFinishedQuestion,
  StyledTestCompletedMessage,
  StyledTitle,
} from "./TestCompletedMessage.styled";

export const TestCompletedMessage: React.FC<TestCompletedMessageProps> = ({
  dataCy,
  title,
  iconName,
  description,
  buttonsTitle,
  buttons,
  className,
  hideSubTitle,
}) => {
  const { t } = useTranslation("skill-determination-test");
  return (
    <StyledTestCompletedMessage className={className} data-cy={dataCy}>
      <StyledTitle>{title || t("finishedQuestions.title", "Well done!")}</StyledTitle>
      <SvgIconReact iconName={iconName} size={14} />
      {description && (
        <StyledParagraphFinishedQuestion>{description}</StyledParagraphFinishedQuestion>
      )}
      {buttons && (
        <StyledHeaderFinishedQuestion>
          {!hideSubTitle &&
            (buttonsTitle || t("finishedQuestions.subtitle", "What would you like to do next?"))}
        </StyledHeaderFinishedQuestion>
      )}
      <StyledButtonsSection>{buttons}</StyledButtonsSection>
    </StyledTestCompletedMessage>
  );
};
