import { ToastMethods } from "components/ToastNotification";
import i18n from "i18n";
import { action, observable } from "mobx";
import type {
  TeacherInstructionCreateBody,
  TeacherInstructionEditBody,
  TeacherInstructionsModel,
  TeacherTypesForInstructions,
} from "models/product/TeacherInstructions";
import { teacherInstructionsRepository, uploadRepository } from "repositories";

interface TeacherInstructionsStoreInterface {
  loading: boolean;
  teacherInstructions: TeacherInstructionsModel[];
  selectedEditTeacherInstruction: TeacherInstructionsModel | null;
  setTeacherInstructions: (teacherInstructions: TeacherInstructionsModel[]) => void;
  setSelectedEditTeacherInstruction: (teacherInstruction: TeacherInstructionsModel) => void;
  resetSelectedEditTeacherInstruction: () => void;
  setLoading: (isLoading: boolean) => void;
  updateTeacherInstructions: (
    teacherInstruction: TeacherInstructionEditBody,
    instructionId: number,
  ) => Promise<void>;
  createTeacherInstructions: (teacherInstruction: TeacherInstructionCreateBody) => Promise<void>;
  deleteTeacherInstructions: (teacherInstructionId: number) => void;
  getTeacherInstructions: (
    productId: number,
    targetTeacherType?: TeacherTypesForInstructions,
    searchTerm?: string,
  ) => void;
}

const initialState = {
  teacherInstructions: [] as TeacherInstructionsModel[],
  loading: false,
  selectedEditTeacherInstruction: null,
};

const stateSetters = {
  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),
  setTeacherInstructions: action((teacherInstructions: TeacherInstructionsModel[]) => {
    store.teacherInstructions = teacherInstructions;
  }),
  setSelectedEditTeacherInstruction: action((teacherInstruction: TeacherInstructionsModel) => {
    store.selectedEditTeacherInstruction = teacherInstruction;
  }),
  resetSelectedEditTeacherInstruction: action(() => {
    store.selectedEditTeacherInstruction = null;
  }),
};

const apiRequests = {
  getTeacherInstructions: action(
    (
      productId: number,
      targetTeacherType?: TeacherTypesForInstructions,
      searchTerm?: string,
      moduleId?: number,
    ) => {
      store.setLoading(true);
      teacherInstructionsRepository
        .getTeacherInstructions(productId, targetTeacherType, searchTerm, moduleId)
        .then((instructions) => {
          store.setTeacherInstructions(instructions);
        })
        .catch(() => {
          ToastMethods.showToast(i18n.t("toast:product.getTeacherInstructions.error"), "error");
        })
        .finally(() => {
          store.setLoading(false);
        });
    },
  ),
  updateTeacherInstructions: action(
    async (instructionsDetail: TeacherInstructionsModel, instructionId: number) => {
      store.setLoading(true);
      const { productId, targetTeacherType, moduleId } = instructionsDetail;
      const updateInstructionBody = {
        title: instructionsDetail.title,
        documentUrl: instructionsDetail.documentUrl,
        moduleId: +instructionsDetail.moduleId,
        targetTeacherType: instructionsDetail.targetTeacherType,
        productId: instructionsDetail.productId,
        languages: instructionsDetail.languages,
      };
      if (instructionsDetail.pdfFile) {
        const fileUrl = await uploadRepository.uploadFile(
          instructionsDetail.pdfFile,
          { productId, targetTeacherType, moduleId },
          true, // Overwrite true
          instructionsDetail.documentUrl, // File path
        );
        updateInstructionBody.documentUrl = fileUrl.downloadUrl;
      }
      await teacherInstructionsRepository
        .updateTeacherInstructions(updateInstructionBody, instructionId)
        .then((res) => {
          store.getTeacherInstructions(res.productId);
        })
        .catch(() => {
          ToastMethods.showToast(i18n.t("toast:product.updateTeacherInstructions.error"), "error");
        })
        .finally(() => {
          store.setLoading(false);
        });
    },
  ),
  createTeacherInstructions: action((instructionsDetail: TeacherInstructionsModel) => {
    const { productId, targetTeacherType, moduleId, languages, title } = instructionsDetail;
    store.setLoading(true);
    if (!instructionsDetail.pdfFile) {
      return ToastMethods.showToast(
        i18n.t("toast:product.updateTeacherInstructions.error"),
        "error",
      );
    }
    uploadRepository
      .uploadFile(instructionsDetail.pdfFile, { productId, targetTeacherType, moduleId })
      .then((res) => {
        const createInstructionBody = {
          title,
          documentUrl: res.downloadUrl,
          moduleId,
          targetTeacherType,
          productId,
          languages,
        };

        teacherInstructionsRepository
          .createTeacherInstructions(createInstructionBody)
          .then((res) => {
            store.getTeacherInstructions(res.productId);
          });
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:product.updateTeacherInstructions.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  deleteTeacherInstructions: action((instructionId: number) => {
    store.setLoading(true);
    teacherInstructionsRepository
      .deleteTeacherInstructions(instructionId)
      .then((res) => {
        uploadRepository.deleteFile(res.documentUrl);
        store.getTeacherInstructions(res.productId);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:product.updateTeacherInstructions.error"), "error");
      });
  }),
};

const store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as TeacherInstructionsStoreInterface);

export const useTeacherInstructions = (): TeacherInstructionsStoreInterface => store;
