import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { CssFlex, CssGrid } from "styles/helpers/layout";

import type { ThemeProps } from "models/styles/theme";
export const StyledEmailPreview = styled(CssGrid)`
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.218231);
`;

export const StyledEmailTitle = styled(CssFlex)`
  background: ${(props: Props) => props.theme.palette.neutral._950};
`;

export const StyledEmailMetadata = styled(CssGrid)`
  border-bottom: 1px solid ${(props: Props) => props.theme.palette.neutral._950};

  > :first-child {
    color: ${(props: Props) => props.theme.palette.neutral._500};
  }
`;

export const StyledEmailButtonBar = styled(CssFlex)`
  button {
    background-color: #b20b87 !important;

    .content {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
      justify-content: space-between;
      font-size: ${(props: ThemeProps) => props.theme.font.size._12};
      font-weight: normal;
      line-height: 1em;
    }
  }

  i {
    cursor: not-allowed;
  }
`;
