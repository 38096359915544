import {
  type TooltipCallbackFunction,
  type TooltipPosition,
  tooltipPosition,
} from "_helpers/tooltip";
import { useEffect } from "react";

export const useTooltip = ({
  isEnabled = true,
  target,
  tooltip,
  preferredPosition = {
    x: "right",
    y: "bottom",
  },
  container,
  callback,
}: {
  isEnabled?: boolean;
  target: HTMLElement | null;
  tooltip?: HTMLElement | null;
  preferredPosition: TooltipPosition;
  container?: HTMLElement;
  callback: TooltipCallbackFunction;
}): void => {
  useEffect(() => {
    if (!isEnabled || !tooltip || !target) {
      return undefined;
    }
    const cleanupFn = tooltipPosition(
      {
        container,
        target,
        tooltip,
        preferredPosition,
      },
      callback,
    );
    return () => {
      cleanupFn();
    };
  }, [isEnabled, container, target, tooltip, preferredPosition, callback]);
};
