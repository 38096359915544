import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import type React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledErrorRendererContainer,
  StyledErrorRendererDetails,
  StyledErrorRendererTitle,
} from "./ErrorRenderer.styled";

interface ErrorRendererProps {
  message?: string;
}

// Simple component to use in an ErrorBoundary to show a message when a render error occurs
export const ErrorRenderer: React.FC<ErrorRendererProps> = ({ message }) => {
  const { t } = useTranslation("common");

  const errorMessage = message || t("renderError.title");

  return (
    <StyledErrorRendererContainer>
      <StyledErrorRendererTitle>
        <FontAwesomeIcon className="status-icon" iconName="fas fa-times" />{" "}
        {t("renderError.error", "Error")}
      </StyledErrorRendererTitle>
      <StyledErrorRendererDetails>{errorMessage}</StyledErrorRendererDetails>
    </StyledErrorRendererContainer>
  );
};
