import { Form, useFormikContext } from "formik";
import type React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import type { PrevExamQuestion } from "models/exam/Exam";
import { Heading3, Heading4 } from "styles/elements/Headings";

import { StyledFragment } from "../../OverviewQuestion.styled";
import { StyledHeadingWrapper, StyledTextWrapper } from "../OverviewLetter/OverviewLetter.styled";
import {
  StyledAnswerWrapper,
  StyledButtonWrapper,
  StyledHighlightDescription,
  StyledTextArea,
} from "./OverviewAnswer.styled";

export interface OverviewAnswerProps {
  prevQuestions: PrevExamQuestion[];
  highlightedQuestionId: number;
  setHighlightedQuestionId: (id: number) => void;
}

export const OverviewAnswer: React.FC<OverviewAnswerProps> = ({
  prevQuestions,
  highlightedQuestionId,
  setHighlightedQuestionId,
}) => {
  const { t } = useTranslation("test-environment");
  const formik = useFormikContext<Record<string, string>>();

  return (
    <StyledFragment>
      <StyledHeadingWrapper>
        <Heading3>{t("progressiveTest.overviewAnswer.title")}</Heading3>
      </StyledHeadingWrapper>

      <Form noValidate onSubmit={formik.handleSubmit}>
        <StyledTextWrapper data-cy="textarea-parent">
          {prevQuestions.map((prevQuestion) => {
            const fieldName = `answer-${prevQuestion.id}`;
            const isHighlighted = prevQuestion.id === highlightedQuestionId;
            return (
              <StyledAnswerWrapper key={`answer-wrapper-${prevQuestion.id}`}>
                <Heading4 key={`header-${prevQuestion.id}`}>
                  <label htmlFor={`question-${prevQuestion.id}-textarea`}>
                    {prevQuestion.topic}
                  </label>
                  {isHighlighted && (
                    <StyledHighlightDescription key={`desc-${prevQuestion.id}`}>
                      {t("progressiveTest.overviewAnswer.edit")}
                    </StyledHighlightDescription>
                  )}
                </Heading4>
                <StyledTextArea
                  key={`textarea-${prevQuestion.id}`}
                  highlight={isHighlighted ? "true" : "false"}
                  id={`question-${prevQuestion.id}-textarea`}
                  name={fieldName}
                  spellCheck={false}
                  value={formik.values[fieldName]}
                  onBlur={() => setHighlightedQuestionId(-1)}
                  onChange={formik.handleChange}
                  onFocus={() => setHighlightedQuestionId(prevQuestion.id)}
                />
              </StyledAnswerWrapper>
            );
          })}
        </StyledTextWrapper>
        <StyledButtonWrapper>
          <Button dataCy="submit-button" type="submit" variant="primary">
            {t("progressiveTest.send")}
          </Button>
        </StyledButtonWrapper>
      </Form>
    </StyledFragment>
  );
};
