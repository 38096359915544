import _ from "lodash";
import { hogesTheme } from "themes/hoges";

const blackColor = "#292F3B";
const specificBusinessThemeProperties = {
  name: "hogesBusiness",
  palette: {
    primary: {
      brand: "#F9B000",
      _900: "#F86B01",
      _400: "#FBD14e",
      _200: "#FDEAB2",
    },
    secondary: {
      brand: "#DCDAD6",
      _900: "#1D1C19",
      _700: "#5C5B57",
      _400: "#B8B6B2",
      _200: "#ECEAE6",
    },
    text: {
      primary: blackColor,
    },
  },
};

export const hogesBusinessTheme = _.merge({}, hogesTheme, specificBusinessThemeProperties);
