import { HogesPrivacyURL, HogesTermsURL } from "constants/registration-links";
import { useDomainHandler } from "hooks/useDomainHandler";

interface Link {
  url: string;
  label: string;
  external: boolean;
  staticLabel?: boolean;
}

interface LinksGroup {
  title: string;
  links: Link[];
}

interface FooterConstantsInterface {
  genericLinks: LinksGroup;
  contactLinks: LinksGroup;
}

export const FooterConstants = (): FooterConstantsInterface => {
  // Use the useDomainHandler hook to get domain-specific data
  const {
    getCustomSupportEmailByDomain,
    getCustomSupportPhoneByDomain,
    getCustomSupportPhoneLabelByDomain,
  } = useDomainHandler();

  /**
   *  IMPORTANT! Don't remove these lines!
   *  This is required for the "i18next-scanner" command to recognize these locale strings.
   *
   * t('common:footer.genericLinks.title')
   * t('common:footer.genericLinks.items.terms')
   * t('common:footer.genericLinks.items.agreement')
   * t('common:footer.genericLinks.items.privacy')
   * */
  const genericLinks = {
    title: "footer.genericLinks.title",
    links: [
      {
        url: HogesTermsURL,
        label: "footer.genericLinks.items.terms",
        external: true,
      },
      /* TODO: restore this link when we know the URL to which it should point to
      {
        url: '/agreement',
        label: 'footer.genericLinks.items.agreement',
      }, */
      {
        url: HogesPrivacyURL,
        label: "footer.genericLinks.items.privacy",
        external: true,
      },
    ],
  };

  // t('common:footer.contactLinks.title')
  const contactLinks = {
    title: "footer.contactLinks.title",
    links: [
      {
        url: `mailto: ${getCustomSupportEmailByDomain()}`,
        label: getCustomSupportEmailByDomain(),
        external: true,
        staticLabel: true,
      },
      {
        url: `tel:${getCustomSupportPhoneByDomain()}`,
        label: getCustomSupportPhoneLabelByDomain(),
        external: true,
        staticLabel: true,
      },
    ],
  };

  return { genericLinks, contactLinks };
};
