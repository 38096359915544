import { format } from "date-fns";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useModal } from "hooks/useModal";
import { useReactTooltip } from "hooks/useReactTooltip";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { SvgIconReact } from "components/SvgIconReact";
import { modalIds } from "constants/modals-constants";

import type { ScheduledTestItemProps } from "./ScheduledTestItem.model";
import {
  PasswordButton,
  StyledCollapseButton,
  StyledDateMetadata,
  StyledDetailedInformation,
  StyledEditButton,
  StyledGeneralInformation,
  StyledInfoRow,
  StyledInformationBlock,
  StyledInformationItem,
  StyledMetadataContainer,
  StyledProductFlag,
  StyledScheduledTestItem,
  StyledTestCode,
  StyledTestLevel,
  StyledTestName,
  StyledTimeMetadata,
} from "./ScheduledTestItem.styled";

export const ScheduledTestItem: React.FC<ScheduledTestItemProps> = ({ scheduledTest, onEdit }) => {
  useReactTooltip();
  const {
    cumulative,
    accessCodes,
    duration,
    extraTime,
    password,
    startDate: startDateString,
    testLevel,
    testType,
    title,
    productId,
  } = scheduledTest;
  const { t } = useTranslation("scheduled-tests");
  const { t: tCommon } = useTranslation("common");
  const [collapsed, setCollapsed] = useState(false);
  const { toggleModal } = useModal();

  const testTypeTitle =
    testType === "summative-test"
      ? t("scheduledTestList.item.testType.summative", "Summative test")
      : t("scheduledTestList.item.testType.formative", "Formative test");

  const startDate = new Date(startDateString);

  const onEditScheduledTest = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onEdit(scheduledTest);
  };

  const onRevealPasswordClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleModal(modalIds.showPasswordModal, { password });
  };

  return (
    <StyledScheduledTestItem data-cy="scheduled-test-item">
      <StyledProductFlag productId={productId} />
      <StyledCollapseButton
        aria-label={
          collapsed ? tCommon("iconButtonLabel.expand") : tCommon("iconButtonLabel.collapse")
        }
        data-cy="scheduled-test-collapse-button"
        iconName={!collapsed ? "rightArrow" : "downArrow"}
        iconSize={1.5}
        onClick={() => setCollapsed(!collapsed)}
      />
      <StyledGeneralInformation>
        <StyledInfoRow>
          <StyledTestName data-cy="scheduled-test-name">{title}</StyledTestName>
          <StyledMetadataContainer>
            <StyledTestLevel data-cy="scheduled-test-level">
              <SvgIconReact iconName="graduationCapIcon" size={1.5} />
              {testLevel}
            </StyledTestLevel>
            <StyledDateMetadata data-cy="scheduled-test-date">
              <SvgIconReact iconName="calendarIcon" size={1.5} />
              {format(startDate, "dd MMM yyyy")}
            </StyledDateMetadata>
            <StyledTimeMetadata data-cy="scheduled-test-time">
              <SvgIconReact iconName="clockIcon" size={1.5} />
              <span data-cy="scheduled-test-time">{format(startDate, "HH:mm")}</span>
              {" | "}
              <span data-cy="scheduled-test-duration">{`${duration} min`}</span>
            </StyledTimeMetadata>
          </StyledMetadataContainer>
          <StyledTestCode data-cy="scheduled-test-code">
            <SvgIconReact iconName="codeIcon" size={2} />
            {accessCodes.standard}
          </StyledTestCode>
        </StyledInfoRow>
        {extraTime > 0 && (
          <StyledInfoRow>
            <StyledTestName>
              {t("scheduledTestList.item.extraTimeVersion", "Additional time version")}
            </StyledTestName>
            <StyledMetadataContainer>
              <StyledTimeMetadata data-cy="scheduled-test-extra-time">
                <SvgIconReact iconName="clockIcon" size={1.5} />
                {`+${extraTime} min`}
              </StyledTimeMetadata>
            </StyledMetadataContainer>
            <StyledTestCode data-cy="scheduled-test-extra-time-code">
              <SvgIconReact iconName="codeIcon" size={2} />
              {accessCodes.withExtraTime}
            </StyledTestCode>
          </StyledInfoRow>
        )}
      </StyledGeneralInformation>

      {collapsed && (
        <StyledDetailedInformation>
          <StyledInformationBlock>
            <StyledInformationItem data-cy="scheduled-test-type">
              <SvgIconReact iconName="checkIcon" size={1.5} />
              {testTypeTitle}
            </StyledInformationItem>
            {password && (
              <StyledInformationItem data-cy="scheduled-test-password">
                <SvgIconReact iconName="checkIcon" size={1.5} />
                <PasswordButton
                  data-cy="scheduled-test-password-button"
                  data-tip={t("scheduledTestList.item.tooltip.password", "Click to view password")}
                  type="button"
                  onClick={onRevealPasswordClick}
                >
                  {t("scheduledTestList.item.hasPassword", "Password added")}
                </PasswordButton>
              </StyledInformationItem>
            )}
            {cumulative && (
              <StyledInformationItem data-cy="scheduled-test-cumulative">
                <SvgIconReact iconName="checkIcon" size={1.5} />
                {t("scheduledTestList.item.cumulative", "Cumulative Calculation")}
              </StyledInformationItem>
            )}
          </StyledInformationBlock>
          <StyledEditButton
            dataCy="scheduled-test-edit-button"
            variant="secondary"
            onClick={onEditScheduledTest}
          >
            <FontAwesomeIcon iconName="far fa-pencil" />
            {t("scheduledTestList.button.editTest", "Edit test")}
          </StyledEditButton>
        </StyledDetailedInformation>
      )}
    </StyledScheduledTestItem>
  );
};
