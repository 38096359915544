import React from "react";
import ReactDOM from "react-dom";

// @ts-ignore
import { debugContextDevtool } from "react-context-devtool";

import { ToastMethods } from "components/ToastNotification";

import "./i18n";
import App from "./App";

ToastMethods.initToast({});

const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container,
);

// Attach root container
debugContextDevtool(container, {});
