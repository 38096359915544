import styled from "styled-components/macro";

import { Button } from "components/Button";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

export interface Props {
  theme: ElearningThemeInterface;
}

export const StyledProductButton = styled(Button)<Props>`
  padding: 0.8rem 1rem;
  background: ${(props: Props) => props.theme.palette.neutral._950};

  .content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const StyledProductName = styled.span<Props>`
  flex-grow: 1;
`;

export const StyledMessage = styled.p<Props>`
  margin-bottom: 2rem;
`;
