import type { QuestionStatus } from "constants/exam-constants";
import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

import { bgColorCalc } from "_helpers/statusColorCalc";
import iconDragImg from "assets/icons/drag.svg";
import { calculateRems } from "styles/mixins/calculateRem";

interface AnswerFieldProps {
  theme: ElearningThemeInterface;
  status: QuestionStatus;
  dropAnswer?: boolean;
}

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledDragAndDropQuestion = styled.div<AnswerFieldProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledDragAndDropList = styled.ul`
  width: 100%;
  padding: 0;
  margin: 50px;
  list-style-type: none;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 1rem;
`;

export const StyledDragAndDropOption = styled.li`
  display: flex;
  align-items: center;
  background-color: ${(props: Props) => props.theme.palette.neutral._999};
  min-width: 50px;
  height: 48px;
  text-align: center;
  width: auto;
  padding: ${calculateRems([12, 30])};
  box-shadow: ${(props: Props) => props.theme.palette.shadow._06};
  border-radius: 4px;
  font-weight: ${(props: Props) => props.theme.font.weight.bold};
  background-image: url(${iconDragImg});
  background-repeat: no-repeat;
  background-position: 0.8rem, center;
  &:hover {
    background-color: ${(props: Props) => props.theme.palette.neutral._950};
  }

  &.success {
    color: ${(props: Props) => props.theme.palette.neutral.black};
    background: ${(props: Props) => props.theme.palette.feedback.success._950};
    border-color: ${(props: Props) => props.theme.palette.feedback.success._800};
  }

  &.error {
    color: ${(props: Props) => props.theme.palette.neutral.black};
    background: ${(props: Props) => props.theme.palette.feedback.error._950};
    border-color: ${(props: Props) => props.theme.palette.feedback.error._950};
  }

  &.submitted {
    background-color: ${(props: Props) => props.theme.palette.secondary._950};
    border-color: ${(props: Props) => props.theme.palette.secondary.brand};
  }
`;

export const StyledAnswerField = styled.span<AnswerFieldProps>`
  background-color: ${(props: AnswerFieldProps) => bgColorCalc(props)};
  width: auto;
  min-width: 5rem;
  padding: 0.5rem 1rem;
  font-weight: ${(props: Props) => props.theme.font.weight.bold};
  border: 1px dashed ${(props: AnswerFieldProps) => props.theme.palette.secondary.brand};
  color: ${(props: AnswerFieldProps) => !props.dropAnswer && props.theme.palette.secondary.brand};
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  vertical-align: middle;
`;
