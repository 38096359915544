import { observer } from "mobx-react";
import type React from "react";
import { Trans, useTranslation } from "react-i18next";

import landingPageImg from "assets/images/landing-page-img.png";

import { Footer } from "components/Footer";

import { useProfile } from "hooks/useProfile";
import { GeneralHeader, TestAccessForm } from "./components";

import { Heading1, Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import {
  StyledCollage,
  StyledContainer,
  StyledMessage,
  StyledPreparationBox,
  StyledTipContainer,
  StyledWelcomeBox,
} from "./LandingPage.styled";

export const LandingPage: React.FC = observer(() => {
  const { t } = useTranslation("test-environment");
  const { userDetails } = useProfile();

  return (
    <>
      <GeneralHeader />
      <StyledContainer>
        <StyledWelcomeBox>
          <StyledMessage>
            <Heading1 marginBottom="2rem">
              {t("welcomeBox.title", { name: userDetails?.firstName })}
            </Heading1>
            <Trans i18nKey="welcomeBox.description" t={t}>
              <ParagraphLarge>
                Enter your test code on the right and press the button to continue. After that, you
                can take the test. Good luck!
              </ParagraphLarge>
              <ParagraphLarge>
                Arrived a little too early? Scroll down and check out our test tips. With this
                wisdom, you’ll be set to succeed.
              </ParagraphLarge>
            </Trans>
          </StyledMessage>
          <TestAccessForm />
        </StyledWelcomeBox>

        <StyledPreparationBox>
          <StyledCollage alt="" src={landingPageImg} />
          <StyledMessage>
            <Heading1 marginBottom="2rem">
              {t("preparationBox.title", "Our 5 best test tips")}
            </Heading1>
            <StyledTipContainer>
              <Trans i18nKey="preparationBox.description" t={t}>
                <ParagraphLarge>
                  Got some time left before the test starts? We’ve listed our 5 best test tips for
                  you.
                </ParagraphLarge>
                <Heading3>1. Collect everything you need</Heading3>
                <ParagraphLarge>
                  Do you have everything you need to take the test? If you take the test at home,
                  make sure you’re in a quiet place with a stable internet connection. Saves you a
                  lot of hassle!
                </ParagraphLarge>
                <Heading3>2. Read the questions carefully</Heading3>
                <ParagraphLarge>
                  People often times make mistakes because they read the questions too quickly. Such
                  a shame! Take time to read through the question so you’ll have a bigger chance at
                  giving the right answer.
                </ParagraphLarge>
                <Heading3>3. Make sure to always answer something</Heading3>
                <ParagraphLarge>
                  It’s okay to have doubts or not know the answer at all. As long as you fill in
                  something, there’s always a chance you’ll have it right.
                </ParagraphLarge>
                <Heading3>4. Use all your time</Heading3>
                <ParagraphLarge>
                  If you finish early, it’s tempting to hand in your test right away. Instead, go
                  through your answers again. You can adjust them until the very last moment.
                </ParagraphLarge>
                <Heading3>5. Take a deep breath</Heading3>
                <ParagraphLarge>
                  It’s no use trying to practice all of the theory right before the test starts. So
                  take a deep breath, relax and clear your head. You’ve got this!
                </ParagraphLarge>
              </Trans>
            </StyledTipContainer>
          </StyledMessage>
        </StyledPreparationBox>
      </StyledContainer>
      <Footer />
    </>
  );
});
