import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import type { ResultsAPI } from "generated/types";
import { type ApiRequestError, RequestError } from "models/error/Error";
import cookieStorage from "persistence";
import { handlingResponse, logError } from "repositories/utils";

export const fetchAvailableKeys = async (
  scheduledTestId: number | null,
): Promise<ResultsAPI.SEBKey[]> => {
  try {
    const availableKeys = await axios.get(
      `/api/seb-keys${scheduledTestId ? `/?scheduledTestId=${scheduledTestId}` : ""}`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<ResultsAPI.SEBKey[]>(
      [200],
      "Error getting the available seb keys",
    )(availableKeys);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_AVAILABLE_SEB_KEYS_FAILED });
  }
};

export const fetchSEBHeaderKey = async (): Promise<string> => {
  try {
    const sebHeaderKey = await axios.get("/api/seb-keys/header", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<string>([200], "Error getting the header key")(sebHeaderKey);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SEB_HEADER_KEY });
  }
};

export const fetchSEBAuthorization = async (
  scheduledTestId: number,
  sebHeaderKey: string,
): Promise<ResultsAPI.SEBAuthorization> => {
  try {
    const sebAuthorization = await axios.post(
      `/api/seb-keys/test/${scheduledTestId}/authorize`,
      { sebHeaderKey, basePath: `${window.location.origin}/test` },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<ResultsAPI.SEBAuthorization>(
      [200],
      "Error authenticating SEB",
    )(sebAuthorization);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SEB_AUTHORIZATION });
  }
};

export const insertKey = async (
  key: ResultsAPI.InsertSEBKey,
): Promise<ResultsAPI.UpsertedSEBKey> => {
  try {
    const upsertedKey = await axios.post(
      "/api/seb-keys",
      { ...key },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<ResultsAPI.UpsertedSEBKey>(
      [200],
      "Error inserting a seb key",
    )(upsertedKey);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPSERT_SEB_KEY_FAILED });
  }
};

export const updateKey = async (
  key: ResultsAPI.UpdateSEBKey,
): Promise<ResultsAPI.UpsertedSEBKey> => {
  try {
    const upsertedKey = await axios.put(
      `/api/seb-keys/${key.id}`,
      { ...key },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<ResultsAPI.UpsertedSEBKey>(
      [200],
      "Error inserting a seb key",
    )(upsertedKey);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPSERT_SEB_KEY_FAILED });
  }
};
