export enum TeacherTypesForInstructions { // Created enum for TeacherTypes
  GENERIC = "generic",
  LANGUAGE = "language",
}

export interface TeacherInstructionsModel {
  id: number;
  title: string;
  documentUrl: string;
  moduleId: number;
  targetTeacherType: TeacherTypesForInstructions;
  moduleTitle: string;
  productId: number;
  pdfFile?: File;
  languages: string[];
}

export interface SaveTeacherInstructionRequestBody {
  title: string;
  documentUrl: string;
  moduleId: number;
  targetTeacherType: TeacherTypesForInstructions;
  productId: number;
  languages: string[];
}

export interface TeacherInstructionCreateBody
  extends Omit<SaveTeacherInstructionRequestBody, "documentUrl"> {
  pdfFile?: File;
}

export interface TeacherInstructionEditBody extends SaveTeacherInstructionRequestBody {
  pdfFile?: File;
}
