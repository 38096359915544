import {
  type SupportWidgetConfig,
  SupportWidgetVariant,
  type SupportWidgetVariantConfig,
  type WidgetUserRoles,
  isValidWidgetUserRole,
} from "../models/variant.model";

import businessWidgetVariantSettings from "./variants/business";
import defaultWidgetVariantSettings from "./variants/default";

const SupportWidgetVariantSettings: Record<SupportWidgetVariant, SupportWidgetVariantConfig> = {
  [SupportWidgetVariant.DEFAULT]: defaultWidgetVariantSettings,
  [SupportWidgetVariant.BETERSCHRIJVEN]: businessWidgetVariantSettings,
};

export const getSupportWidgetUrl = (variant: SupportWidgetVariant): string => {
  const apiKey = SupportWidgetVariantSettings[variant].apiKey;
  return `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
};

export const getSupportWidgetSettings = (
  variant: SupportWidgetVariant,
  userRole: WidgetUserRoles,
): SupportWidgetConfig | null => {
  if (!isValidWidgetUserRole(userRole)) {
    return null;
  }

  const variantSettings = SupportWidgetVariantSettings[variant];
  return variantSettings.configPerRole[userRole];
};
