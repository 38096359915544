import type React from "react";
import { useMemo } from "react";

import { RadioButton } from "components/RadioButton/RadioButton";

import type { RadioInputProps, RadioInputVariantProps } from "./RadioInput.model";

export const RadioInput: React.FC<RadioInputProps> = ({
  name,
  value: currentValue,
  dataCy,
  options,
  variant = "default",
  onChange,
}) => {
  const isOptionChecked = (optionValue: string | number) => `${currentValue}` === `${optionValue}`;

  const variantProps = useMemo<RadioInputVariantProps>(() => {
    switch (variant) {
      case "secondary":
        return {
          bulletColor: "secondary",
          bulletStyle: "thick",
        };
      default:
        return {
          bulletColor: "primary",
          bulletStyle: "thin",
        };
    }
  }, [variant]);

  return (
    <>
      {options.map((option) => (
        <RadioButton
          {...variantProps}
          key={option.id}
          checked={isOptionChecked(option.value)}
          dataCy={dataCy}
          description={option.description || ""}
          label={option.label}
          name={name}
          value={option.value}
          onChange={onChange}
        />
      ))}
    </>
  );
};
