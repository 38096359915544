import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
  $warning: boolean;
  $format: string;
}

export const StyledCountdownTimer = styled.span<Props>`
  color: ${({ theme, $warning, $format }: Props) => {
    if ($format === "examTimer") {
      return theme.palette.neutral.black;
    }
    if ($warning) return theme.palette.feedback.error.brand;
    return theme.palette.neutral._400;
  }};
  font-weight: ${({ theme, $format }: Props) => $format === "examTimer" && theme.font.weight.bold};
  font-size: ${({ theme, $format }: Props) => $format === "examTimer" && theme.font.size._64};
`;
