import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledCard = styled.div`
  width: 100%;
  margin-bottom: 40px;
  border: ${(props: Props) => `1px solid ${props.theme.palette.neutral._900}`};
`;

export const StyleHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: ${(props: Props) => props.theme.font.size._24};
  background-color: ${(props: Props) => props.theme.palette.neutral._950};
  h3 {
    margin: unset;
  }
`;
