import type { ThemeProps } from "models/styles/theme";
import styled, { css } from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  $hasIcon?: boolean;
  theme: ElearningThemeInterface;
}

const getGridColumns = ({ $hasIcon }: Props) =>
  $hasIcon
    ? css`
        grid-template-columns: auto 1fr auto;
      `
    : css`
        grid-template-columns: 1fr auto;
      `;

export const StyledActionBanner = styled.div<Props>`
  width: 100%;
  display: grid;
  ${(props) => getGridColumns(props)};
  grid-column-gap: 2rem;

  padding: 1.5rem 2rem;
  background-color: #fcf9f4;

  > div {
    display: flex;
    align-items: center;
    column-gap: 2rem;

    svg {
      color: ${({ theme }: Props) => theme.palette.secondary.brand};
    }

    button {
      font-size: ${(props: ThemeProps) => props.theme.font.size._16};
      font-weight: bold;
      padding: 0.7rem 1rem;
    }
  }
`;

export const StyleTitle = styled(Heading3)`
  margin-bottom: 0.5rem;
`;

export const StyledParagraph = styled(ParagraphLarge)`
  margin-bottom: 0;
  text-align: left;
  letter-spacing: 0;
  line-height: 1.5rem;
  font-size: ${(props: Props) => props.theme.font.size._14};
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
  margin: 0;
`;
