import type React from "react";

import { NavigationMenuItem } from "../NavigationMenuItem/NavigationMenuItem";
import type { NavigationMenuProps } from "./NavigationMenu.model";
import { StyledNavigationItemGroup, StyledNavigationMenu } from "./NavigationMenu.styled";

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ className, dataCy, menuItems }) => (
  <StyledNavigationMenu className={className} data-cy={dataCy}>
    {menuItems.map((menuItem) => {
      if (menuItem.redirectPath) {
        return (
          <NavigationMenuItem
            key={menuItem.id}
            dataCy={menuItem.dataCy}
            eventTrackData={menuItem.eventTrackData}
            excludeFromActivePath={menuItem.excludeFromActivePath}
            nestingLevel={menuItem.nestingLevel}
            onlyActiveOnExactPath={menuItem.onlyActiveOnExactPath}
            redirectPath={menuItem.redirectPath}
          >
            {menuItem.title}
          </NavigationMenuItem>
        );
      }
      if (menuItem.subItems && menuItem.subItems.length > 0) {
        return (
          <StyledNavigationItemGroup
            key={menuItem.id}
            basic
            dataCy={menuItem.dataCy}
            expandedByDefault={menuItem.expandedByDefault}
            title={menuItem.title}
          >
            {menuItem.subItems?.map((subItem) => (
              <NavigationMenuItem
                key={subItem.id}
                dataCy={subItem.dataCy}
                eventTrackData={subItem.eventTrackData}
                excludeFromActivePath={subItem.excludeFromActivePath}
                nestingLevel={subItem.nestingLevel}
                redirectPath={subItem.redirectPath}
              >
                {subItem.title}
              </NavigationMenuItem>
            ))}
          </StyledNavigationItemGroup>
        );
      }
      // no redirect path and also no sub items, don't show this item
      return null;
    })}
  </StyledNavigationMenu>
);
