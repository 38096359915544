import type React from "react";
import { useState } from "react";

import {
  StyledCloseButton,
  StyledContainer,
  StyledMessage,
  StyledTitle,
} from "./DismissableMessage.styled";

interface DismissableMessageProps {
  dataCy?: string;
  children: React.ReactNode;
  className?: string;
  title?: string;
  onClose?: () => void;
}

export const DismissableMessage: React.FC<DismissableMessageProps> = ({
  className,
  children,
  dataCy,
  title,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) {
    return null;
  }

  const onCloseButtonClick = () => {
    setIsVisible(false);

    // Execute close event handler in parent if required
    onClose?.();
  };

  return (
    <StyledContainer className={className} data-cy={dataCy}>
      <StyledCloseButton
        data-cy={`${dataCy}-close-button`}
        iconName="fal fa-times"
        iconSize="lg"
        onClick={onCloseButtonClick}
      />
      {title && <StyledTitle>{title}</StyledTitle>}
      <StyledMessage>{children}</StyledMessage>
    </StyledContainer>
  );
};
