import type React from "react";
import { useTranslation } from "react-i18next";

import { QuestionStatus } from "constants/exam-constants";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { SanitizedHtml } from "components/SanitizedHtml";
import type { AnswerBoxProps } from "./AnswerBox.model";
import { StyledAnswerBox, StyledTitle } from "./AnswerBox.styled";

export const AnswerBox: React.FC<AnswerBoxProps> = ({ status, solution = "" }) => {
  const { t } = useTranslation("skill-determination-test");
  const isAnswerCorrect = status === QuestionStatus.CORRECT;

  const getAnswerTextComponent = () => {
    if (!isAnswerCorrect && solution !== "") {
      return (
        <>
          <span className="title">
            {t("questionEvaluationResults.correctAnswer", "Correct answer")}
          </span>
          <span className="solution">
            <SanitizedHtml dirtyHtml={solution} />
          </span>
        </>
      );
    }
    if (!isAnswerCorrect) {
      return (
        <span className="title">
          {t("questionEvaluationResults.wrongAnswer", "Your answer was incorrect")}
        </span>
      );
    }
    return <span className="title">{t("questionEvaluationResults.congratulations")}</span>;
  };

  return (
    <StyledAnswerBox data-cy="answer-box-wrapper" status={status as QuestionStatus}>
      <StyledTitle status={status as QuestionStatus}>
        {isAnswerCorrect && (
          <FontAwesomeIcon className="status-icon" iconName="fas fa-check-circle" />
        )}
        <div className="title-container">{getAnswerTextComponent()}</div>
      </StyledTitle>
    </StyledAnswerBox>
  );
};
