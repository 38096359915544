import styled, { css } from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import partySvg from "../../assets/icons/party.svg";
import starsSvg from "../../assets/icons/stars.svg";
import pencilSvg from "../../assets/images/pencil.svg";
import type { StatusBannerProps } from "./StatusBanner.model";

interface Props extends ThemeProps {
  $status: StatusBannerProps["status"];
}

const getBackgroundGradientCssFromStatus = (status: StatusBannerProps["status"]) => {
  switch (status) {
    case "success":
      return css`
        background-image: linear-gradient(0deg, #f7fffa 0%, #edfff3 100%);
      `;
    case "warning":
      return css`
        background-image: linear-gradient(0deg, #fdf6e9 0%, #fff4e0 100%);
      `;
    case "error":
      return css`
        background: linear-gradient(0deg, #fff5f5 0%, #fee8e8 100%);
      `;
    case "info":
      return css`
        background: linear-gradient(0deg, #f0f8ff 0%, #e5f4ff 100%);
      `;
    default:
      return null;
  }
};

const getBackgroundCssFromStatus = (status: StatusBannerProps["status"]) => {
  switch (status) {
    case "success":
      return css`
        position: relative;
        background-image: url(${partySvg});
        background-position: top left;
        background-repeat: no-repeat;
        background-size: contain;
        &::before {
          // Add the background image again and flip it
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background-image: url(${partySvg});
          background-repeat: no-repeat;
          background-size: contain;
          right: 0;
          top: 0;
          transform: scaleX(-1);
        }
      `;
    case "warning":
      return css`
        background-image: url(${starsSvg});
        background-position: top left;
        background-repeat: no-repeat;
        background-size: contain;
      `;
    case "info":
      return css`
        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url(${pencilSvg});
          background-position: top right;
          background-repeat: no-repeat;
          background-size: contain;
          opacity: 0.5;
        }
      `;
    default:
      return null;
  }
};

export const StyledStatusBanner = styled.div<Props>`
  position: relative;
  padding: 4.5rem;
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._900};
  ${({ $status }) => getBackgroundGradientCssFromStatus($status)};
`;

export const StyledBannerContent = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ $status }) => getBackgroundCssFromStatus($status)}
  > * {
    // make sure all elements are above the background icons
    z-index: 1;
  }
`;

export const StyledStatusBannerTitle = styled.span<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._48};
  text-align: center;
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  margin-bottom: 1.5rem;
`;
