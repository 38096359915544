import type React from "react";

import type { ProgressAPI, ResultsAPI } from "generated/types";
import { ModuleType } from "models/product/ProductDetails";

import { CssProgressBar } from "styles/helpers/layout";
import { StyledProgressBarWrapper } from "./LastPracticedTopicProgressBar.styled";

interface LastPracticedTopicProgressBarProps {
  moduleType: ModuleType;
  lastPracticedTopicStatus:
    | ResultsAPI.LastPracticedWritingTopicStatus
    | ProgressAPI.LastPracticedAdaptiveTopicStatus;
  progressExceedsCurrentGoal?: boolean | null;
}

export const LastPracticedTopicProgressBar: React.FC<LastPracticedTopicProgressBarProps> = ({
  moduleType,
  lastPracticedTopicStatus,
  progressExceedsCurrentGoal,
}) => {
  if (moduleType === ModuleType.ADAPTIVE) {
    const { progressInCurrentLevel } =
      lastPracticedTopicStatus as ProgressAPI.LastPracticedAdaptiveTopicStatus;

    return (
      <StyledProgressBarWrapper alignItems="center" columnGap={1} justifyContent="space-between">
        <CssProgressBar currentValue={!progressExceedsCurrentGoal ? progressInCurrentLevel : 100} />
        <span data-cy="progress-in-current-level">
          {!progressExceedsCurrentGoal ? `${progressInCurrentLevel}%` : "100%"}
        </span>
      </StyledProgressBarWrapper>
    );
  }

  const { completedExercises, totalExercises } =
    lastPracticedTopicStatus as ResultsAPI.LastPracticedWritingTopicStatus;
  return (
    <StyledProgressBarWrapper alignItems="center" columnGap={1} justifyContent="space-between">
      <CssProgressBar currentValue={completedExercises} maxValue={totalExercises} />
      <span data-cy="completed-exercises">{`${completedExercises} / ${totalExercises}`}</span>
    </StyledProgressBarWrapper>
  );
};
