// TODO: This file should eventually be deleted and replaced by "useDomainHandler"
// @see https://github.com/infinitaslearning/he-hst-project-boards/issues/253

import { CustomDomains } from "constants/hst-constants";
import i18n from "i18n";

export const getStudentTypeByDomain = (): string => {
  switch (window.location.host) {
    case CustomDomains.BETERSCHRIJVEN:
    case CustomDomains.BETERSCHRIJVEN_STG:
      return "business";
    case CustomDomains.UNIVERSITEITSTAAL:
      return "university";
    default:
      return "higherEducation";
  }
};

export const getTeacherTypeByDomain = (): string => {
  switch (window.location.host) {
    case CustomDomains.BETERSCHRIJVEN:
    case CustomDomains.BETERSCHRIJVEN_STG:
      return "business";
    case CustomDomains.UNIVERSITEITSTAAL:
      return "university";
    default:
      return "higherEducation";
  }
};

/* -- Methods to fetch localized terms to use based on domain -- */
interface TermGetterOptions {
  usePlural?: boolean;
}

const getLocalizedText = (
  textKey: string,
  options: TermGetterOptions = { usePlural: false },
): string => {
  const { usePlural } = options;
  return i18n.t(textKey, { count: usePlural ? 2 : 1 });
};

export const getStudentTermByDomain = (options?: TermGetterOptions): string =>
  getLocalizedText(`common:studentType.${getStudentTypeByDomain()}`, options);

export const getTeacherTermByDomain = (options?: TermGetterOptions): string =>
  getLocalizedText(`common:teacherType.${getTeacherTypeByDomain()}`, options);
