import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";

import { ROLES } from "@infinitaslearning/module-he-common";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";
import type { ROLE } from "persistence";

import { useRedirect } from "hooks/useRedirect";

import { RoleComponent } from "layouts/RoleComponent";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { StyledButton, StyledSection, StyledText } from "./TestEnvMenuSection.styled";

export const TestEnvMenuSection: React.FC = observer(() => {
  const { redirectToUrl } = useRedirect();
  const { t } = useTranslation("homepage");

  return (
    <RoleComponent allowedRoles={[ROLES.STUDENT as ROLE, ROLES.TEACHER as ROLE]}>
      <StyledSection>
        <StyledText>
          {t("sideMenu.testEnvResults.title", "Do you need to access a test")}
        </StyledText>
        <StyledButton
          dataCy="test-env-button"
          variant="brand"
          onClick={() => {
            trackPlatformEvent({
              module: STUDENT_MODULES.DASHBOARD,
              itemId: PLATFORM_EVENT_TYPES.STUDENT_DASHBOARD_EVENT_TYPES.CLICK_GO_TEST_ENVIRONMENT,
              elementId: "test-env-button-sidebar",
            });
            redirectToUrl(TEST_ENVIRONMENT_ROUTES.LANDING_PAGE);
          }}
        >
          {t("sideMenu.testEnvResults.button", "Go to test environment")}
        </StyledButton>
      </StyledSection>
    </RoleComponent>
  );
});
