import styled, { css } from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

import { Heading4 } from "styles/elements/Headings";

interface StyledLegendProps extends Props {
  $centerHorizontally?: boolean;
}

export const StyledLegend = styled.legend<StyledLegendProps>`
  ${(props: StyledLegendProps) =>
    props.$centerHorizontally &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `};

  ${Heading4} {
    margin-bottom: 1.25rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    display: flex;
    column-gap: 3rem;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;

      font-size: ${(props: Props) => props.theme.font.size._12};
      color: ${(props: Props) => props.theme.palette.neutral.black};
    }
  }
`;
