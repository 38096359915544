import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";

import { SvgIconReact } from "components/SvgIconReact";
import type { AdaptiveTestPageParams } from "pages/StudentEnvironment/AdaptiveTestPage/AdaptiveTestPage.model";

import { AccessibilityMenuButton } from "components/AccessibilityMenuButton/AccessibilityMenuButton";
import { AccessibilityMenuModal } from "components/AccessibilityMenuModal/AccessibilityMenuModal";
import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useProduct } from "hooks/useProduct";
import { useProductProgress } from "hooks/useProductProgress";
import { StyledCloseButton } from "pages/StudentEnvironment/AdaptiveTestPage/components/ImprovementTestHeader/AdaptiveTestHeader.styled";
import { buildComparedSessionTopicProgress } from "pages/StudentEnvironment/StudentDashboard/subpages/AdaptivePracticeSessionResultsPage/utils";
import { CssFlex } from "styles/helpers/layout";
import { StyledAdaptiveTestHeader, StyledSubtitle, StyledTitle } from "./AdaptiveTestHeader.styled";
import {
  StyledPageMetaContainer,
  StyledSessionStatisticsContainer,
} from "./AdaptiveTestHeader.styled";

export const AdaptiveTestHeader: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation("adaptive-test");
  const { t: tCommon } = useTranslation("common");
  const { moduleSubjectsProgress } = useProductProgress();
  const { productDetails } = useProduct();
  const { adaptivePracticeSessionResults } = useAdaptivePractice();

  const { productId, moduleId }: AdaptiveTestPageParams = useParams();
  const { currentTopic, isMixedExerciseActive } = useAdaptivePractice();

  const handleExitTestButtonClick = () => {
    const practicedTopics = buildComparedSessionTopicProgress(
      adaptivePracticeSessionResults,
      moduleSubjectsProgress,
      productDetails,
    ).filter((t) =>
      adaptivePracticeSessionResults.answeredQuestions.some((q) => q.topicId === t.id),
    );

    const redirectToModuleOverview = isMixedExerciseActive || practicedTopics.length === 0;

    return history.push(
      buildUrlWithPathParams(
        redirectToModuleOverview
          ? PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS
          : TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_SESSION_RESULTS,
        { productId, moduleId },
      ),
    );
  };

  return (
    <StyledAdaptiveTestHeader data-cy="adaptive-test-header">
      <AccessibilityMenuModal />
      {/* --- Current subject --- */}
      <CssFlex flexDirection="column" rowGap={0.5}>
        <StyledTitle dataCy="current-subject-title" dirtyHtml={currentTopic?.subject.title || ""} />
        <StyledSubtitle separator="">{t("header.mixedWorkflow")}</StyledSubtitle>
      </CssFlex>
      <CssFlex alignItems="center" flexDirection="column" justifyContent="center" rowGap={0.15} />
      {/* --- Session stats --- */}
      <StyledPageMetaContainer>
        <StyledSessionStatisticsContainer>
          <CssFlex alignItems="center" gap={2} justifyContent="center">
            <CssFlex alignItems="center" data-cy="mixed-topics-practice-answered-questions">
              <SvgIconReact iconName="itemListIcon" />{" "}
              {adaptivePracticeSessionResults.answeredQuestions.length || 0}
            </CssFlex>
            <CssFlex alignItems="center" data-cy="mixed-topics-practice-streak">
              <SvgIconReact iconName="flameIcon" />{" "}
              {adaptivePracticeSessionResults.currentStreak || 0}
            </CssFlex>
          </CssFlex>
        </StyledSessionStatisticsContainer>
        <AccessibilityMenuButton />
        <StyledCloseButton
          aria-label={tCommon("iconButtonLabel.close")}
          dataCy="exit-test-button"
          variant="text"
          onClick={handleExitTestButtonClick}
        >
          <SvgIconReact iconName="closeX" />
        </StyledCloseButton>
      </StyledPageMetaContainer>
    </StyledAdaptiveTestHeader>
  );
});
