import { Link } from "components/Link";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledUsersPage = styled.div`
  margin-right: 32px;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  align-items: flex-end;
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
  display: flex;
  margin: ${calculateRem(56)} 0 ${calculateRem(56)} auto;
  width: fit-content;
`;
