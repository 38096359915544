import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { Box } from "styles/helpers/layout";

import { Button } from "components/Button";
import { LevelDashGroup } from "components/LevelDashGroup";
import { SanitizedHtml } from "components/SanitizedHtml";

export const StyledAdaptiveTestHeader = styled.div<ThemeProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 1rem 4rem 2rem 4rem;
`;

export const StyledTitle = styled(SanitizedHtml)<ThemeProps>`
  font-weight: bold;
  font-size: ${(props: ThemeProps) => props.theme.font.size._24};
`;

interface StyledSubtitleProps extends ThemeProps {
  separator: string;
}

export const StyledSubtitle = styled(Box)<StyledSubtitleProps>`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};

  > :not(:first-child)::before {
    width: auto;
    content: '${({ separator }: StyledSubtitleProps) => separator}';
  }
`;

export const StyledCurrentLevel = styled(Box)<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};

  > :first-child {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
  }
`;

export const StyledProgressBar = styled(LevelDashGroup)<ThemeProps>`
  .level-dashes > * {
    width: 4.5rem;
    margin-left: 0.65rem !important;

    :last-child {
      margin-right: 0.65rem;
    }
  }

  h6 {
    margin-left: 0;
    color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
  }
`;

export const StyledCloseButton = styled(Button)<ThemeProps>`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  margin-right: 2rem;
  &:hover {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._300};
  }
  margin-right: 2rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
