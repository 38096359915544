import type { StatusTagVariant } from "components/StatusTag/StatusTag.styled";
import { ExerciseStatus, type TopicExerciseStatus } from "models/writing/WritingDetail";
import type { TFunction } from "react-i18next";

export const getExerciseStatus = (
  exerciseId: number,
  topicExerciseStatus: TopicExerciseStatus[],
): ExerciseStatus | null => {
  const selectedExerciseStatus = topicExerciseStatus.find(
    (exerciseStatus) => exerciseStatus.exerciseId === exerciseId,
  );

  if (!selectedExerciseStatus || selectedExerciseStatus.status === ExerciseStatus.NOT_STARTED) {
    return null;
  }

  return selectedExerciseStatus.status;
};

export const getExerciseStatusLabel = (
  t: TFunction<"writing-module">,
  status: ExerciseStatus,
): string | null => {
  if (status === ExerciseStatus.NOT_STARTED) {
    return null;
  }

  const statusLabels = {
    [ExerciseStatus.CORRECT]: t("topicDetails.exerciseStatus.passed", "Passed"),
    [ExerciseStatus.INCORRECT]: t("topicDetails.exerciseStatus.failed", "Failed"),
    [ExerciseStatus.IN_PROGRESS]: t("topicDetails.exerciseStatus.inProgress", "In progress"),
  };

  return statusLabels[status];
};

export const getExerciseStatusTagVariant = (status: ExerciseStatus): StatusTagVariant => {
  switch (status) {
    case ExerciseStatus.CORRECT:
      return "success";
    case ExerciseStatus.INCORRECT:
      return "error";
    default:
      return "info";
  }
};
