import { QuestionStatus } from "constants/exam-constants";
import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { CssButton, CssGrid } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

export interface StyledAnswerBoxProps extends Props {
  status: QuestionStatus;
}

const handleBackgroundColor = (status: QuestionStatus, theme: ElearningThemeInterface) => {
  switch (status) {
    case QuestionStatus.CORRECT:
      return theme.palette.feedback.success._950;
    case QuestionStatus.INCORRECT:
      return theme.palette.feedback.error._950;
    default:
      return "transparent";
  }
};

const handleBarColor = (status: QuestionStatus, theme: ElearningThemeInterface) => {
  switch (status) {
    case QuestionStatus.CORRECT:
      return theme.palette.feedback.success.brand;
    case QuestionStatus.INCORRECT:
      return theme.palette.feedback.error.brand;
    default:
      return theme.palette.secondary._400;
  }
};

export const StyledAnswerBox = styled(CssGrid)<StyledAnswerBoxProps>`
  border-top: 1px solid ${({ status, theme }: StyledAnswerBoxProps) => handleBarColor(status, theme)};
  background: ${({ status, theme }: StyledAnswerBoxProps) => handleBackgroundColor(status, theme)};
  bottom: 0;
  > :first-child {
    > button {
      background: ${({ status, theme }: StyledAnswerBoxProps) => handleBackgroundColor(status, theme)};
    }
  }
`;

export const StyledNextButton = styled(CssButton)<StyledAnswerBoxProps>`
  &:disabled {
    opacity: 0.3;
  }
`;
