import styled from "styled-components/macro";

import { AccordionItem } from "components/AccordionItem";
import type { ThemeProps } from "models/styles/theme";

// same width as header brand column (as minimum width)
export const StyledNavigationMenu = styled.nav<ThemeProps>`
  padding-left: 2rem;
  min-width: 23rem;
`;

export const StyledNavigationItemGroup = styled(AccordionItem)`
  .tab-label .tab-title .tab-title-text {
    font-size: ${(props: ThemeProps) => props.theme.font.size._16};
    letter-spacing: initial;
  }
`;
