import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { Heading4 } from "styles/elements/Headings";

import { Button } from "components/Button";
import { Dropdown } from "components/Dropdown";
import { Input } from "components/Input/Input";
import { Label } from "styles/elements/Texts";

export const StyledTitle = styled(Heading4)`
  margin: 0;
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  line-height: 100%;
`;

export const StyledLabel = styled(Label)`
  margin-top: 1.5rem;
  font-weight: ${(props: ThemeProps) => props.theme.font.weight.normal};
`;

export const StyledDropdown = styled(Dropdown)`
  .dropdown-header {
    height: 48px;
    width: 100%;
    padding: 1rem;
    justify-content: space-between;
    border: 1px solid ${(props: ThemeProps) => props.theme.palette.neutral._600};
    border-radius: 2px;
  }

  .dropdown-title {
    font-weight: ${(props: ThemeProps) => props.theme.font.weight.normal};
  }

  .dropdown-list {
    top: 3.363rem;
    button {
      margin: 0;
      font-weight: ${(props: ThemeProps) => props.theme.font.weight.normal};
    }
  }
`;

export const StyledInput = styled(Input)`
  margin-top: 1.5rem;
  color: ${(props: ThemeProps) => props.theme.palette.neutral._500};
`;

export const StyledTipMessage = styled.span`
  margin-top: 0.5rem;
  color: ${(props: ThemeProps) => props.theme.palette.neutral._400};
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
`;

export const StyledButton = styled(Button)`
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  margin-top: 2rem;
`;
