import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { COMMON } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import MultipleOptionToggle from "components/MultipleOptionToggle/MultipleOptionToggle";
import { SvgIconReact } from "components/SvgIconReact";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import { useProfile } from "hooks/useProfile";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Heading3 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";
import type { AccessibilityMenuProps } from "./AccessibilityMenuModal.model";
import {
  StyledAccessibilityMenu,
  StyledHeaderContainer,
  StyledParagraph,
} from "./AccessibilityMenuModal.styled";

export type AccessibilityTextSize = "medium" | "large";

export const AccessibilityMenuModal: React.FC<AccessibilityMenuProps> = () => {
  const { closeModal, isModalOpen } = useModal();
  const [activeTextSize, setActiveTextSize] = useState("");
  const [activeToggle, setActiveToggle] = useState(false);
  const { t: tModal } = useTranslation("modal");
  const { userDetails, updateHogesUserProfile } = useProfile();
  useEffect(() => {
    if (userDetails) {
      setActiveTextSize(userDetails.textSize);
      setActiveToggle(userDetails.isImmersiveReaderEnabled);
    }
  }, [userDetails]);

  const handleSelectText = (selectedText: AccessibilityTextSize) => {
    updateHogesUserProfile({ textSize: selectedText });
    setActiveTextSize(selectedText);

    const sizeToEventMap = {
      medium: PLATFORM_EVENT_TYPES.ACCESSIBILITY.UPDATE_TEXT_SIZE_MEDIUM,
      large: PLATFORM_EVENT_TYPES.ACCESSIBILITY.UPDATE_TEXT_SIZE_LARGE,
    };

    const eventType = sizeToEventMap[selectedText] || null;
    if (eventType) {
      trackPlatformEvent({
        module: COMMON.ACCESSIBILITY,
        itemId: eventType,
      });
    }
  };
  const handleReaderToggle = (activeToggle: boolean) => {
    if (userDetails) {
      trackPlatformEvent({
        module: COMMON.ACCESSIBILITY,
        itemId: !activeToggle
          ? PLATFORM_EVENT_TYPES.ACCESSIBILITY.UPDATE_IR_TOGGLE_ON
          : PLATFORM_EVENT_TYPES.ACCESSIBILITY.UPDATE_IR_TOGGLE_OFF,
      });
      updateHogesUserProfile({ isImmersiveReaderEnabled: !activeToggle });
      setActiveToggle(!activeToggle);
    }
  };

  return (
    <StyledAccessibilityMenu
      closeOnClickOutside
      dataCy={modalIds.modalAccessibilityMenu}
      isOpen={isModalOpen(modalIds.modalAccessibilityMenu)}
      modalAnimation="slideRight"
      modalPosition={["center", "right"]}
      showCloseButton={true}
      onClose={closeModal}
    >
      <StyledHeaderContainer>
        <SvgIconReact iconName="accessibilityIcon" size={1.5} />
        <Heading3 marginLeft={"0.75rem"}>
          {tModal("accessibilityMenuModal.title", "Toegankelijkheidsopties")}
        </Heading3>
      </StyledHeaderContainer>
      <CssFlex flexDirection="column" gap={1.5}>
        <StyledParagraph>
          {tModal(
            "accessibilityMenuModal.paragraph",
            "Hier kun je de instellingen voor de toegankelijkheid aanpassen:",
          )}
        </StyledParagraph>
        <CssFlex alignItems={"center"}>
          <StyledParagraph>
            {tModal("accessibilityMenuModal.textSize", "Lettersizing")}
          </StyledParagraph>
          <MultipleOptionToggle activeButton={activeTextSize} onClick={handleSelectText} />
        </CssFlex>
        {/* IR toggle will only be visible who have permissions to use this feature */}
        {userDetails?.canUseImmersiveReader && (
          <CssFlex alignItems={"center"}>
            <StyledParagraph>
              {tModal("accessibilityMenuModal.immersiveReaderToggle", "Lees luidop")}
            </StyledParagraph>
            <ToggleSwitch
              dataCy="reader-toggle"
              isActive={activeToggle}
              variant="primary"
              onChange={() => handleReaderToggle(activeToggle)}
            />
          </CssFlex>
        )}
      </CssFlex>
    </StyledAccessibilityMenu>
  );
};
