import { Packer } from "docx";
import saveAs from "file-saver";
import { useWriting } from "hooks/useWriting";
import { observer } from "mobx-react";
import { type FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { EmbeddedHtml } from "components/EmbeddedHtml";
import { SvgIconReact } from "components/SvgIconReact";
import { useWritingTest } from "hooks/useWritingTest";
import type { QuestionsManagerPathParams } from "layouts/QuestionManager/QuestionManager.model";
import type { ProgressiveDownloadQuestion } from "models/exam/Exam";
import {
  DownloadButtonCardMixin,
  StyledDownloadButton,
} from "./ProgressiveDocumentQuestion.styled";
import { createDocx } from "./docx-generator";

interface ProgressiveDocumentQuestionProps {
  question: ProgressiveDownloadQuestion;
}

export const ProgressiveDocumentQuestion: FC<ProgressiveDocumentQuestionProps> = observer(
  ({ question }) => {
    const { overviewQuestionResults, fetchOverviewQuestionResults, submitOverviewQuestionResults } =
      useWriting();
    const { writingTest, fetchWritingTest } = useWritingTest();
    const { productId, exerciseId }: QuestionsManagerPathParams = useParams();

    const [hasClickedDownload, setHasClickedDownload] = useState(false);
    const { fileName } = question.metadata;

    useEffect(() => {
      productId && exerciseId && fetchWritingTest(+productId, +exerciseId);
    }, [productId, exerciseId]);

    useEffect(() => {
      fetchOverviewQuestionResults(+productId, question.id, writingTest?.token || "");
    }, [question.id, writingTest]);

    const { t } = useTranslation("skill-determination-test");

    const onSavedDocx = () => {
      setHasClickedDownload(true);
      writingTest &&
        submitOverviewQuestionResults(
          +productId,
          writingTest.id,
          question.id,
          [],
          writingTest?.token || "",
        );
    };

    const saveDocx = (onSaved: () => void) => {
      if (!question?.prevQuestions) return;

      const doc = createDocx(overviewQuestionResults.flatMap((a) => a.answers));
      Packer.toBlob(doc).then((blob: Blob) => {
        saveAs(blob, fileName);
        onSaved();
      });
    };

    return (
      <>
        <EmbeddedHtml rawHtml={question.content} />
        <DownloadButtonCardMixin>
          <SvgIconReact iconName="fileWord" size={4.5} />
          <div>
            <h3>{fileName}</h3>
            <span>{t("word.download")}</span>
          </div>
          <StyledDownloadButton
            aria-label={t("word.download")}
            data-cy="download-progressive-question"
            onClick={() => saveDocx(onSavedDocx)}
          >
            {hasClickedDownload ? (
              <SvgIconReact
                dataCy="download-clicked-check-icon"
                iconName="greenCircleCheckIcon"
                size={2.5}
              />
            ) : (
              <SvgIconReact iconName="downloadIcon" size={2.5} />
            )}
          </StyledDownloadButton>
        </DownloadButtonCardMixin>
      </>
    );
  },
);
