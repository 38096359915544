import { type URLFields, buildUrlWithPathAndQueryParams } from "_helpers/utils/urlBuilder";
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE, PAGE_FILTER_KEY } from "constants/misc-constants";
import type { Education } from "models/auth/Login";
import type { ProductDetails } from "models/product/ProductDetails";
import type { SchoolYear } from "models/product/SchoolYear";
import type { OverviewFilters, SubjectsProgressOverviewFilters } from "models/progress/Progress";
import type {
  BasicProgressOverviewPagePathParams,
  BasicProgressOverviewPageQueryParams,
} from "pages/TeacherEnvironment/TeacherDashboard/subpages/BasicProgress/BasicProgress.model";
import queryString from "query-string";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useFeatureFlags } from "./useFeatureFlags";
import useOnLocationChange from "./useOnLocationChange";
import { useProduct } from "./useProduct";
import { useProfile } from "./useProfile";

interface TeacherOverviewInterface {
  page: number;
  productId: string;
  showEducationFilter: boolean | undefined;
  showSchoolYearFilter: boolean | undefined;
  nonOwnedProductError: boolean | undefined;
  currentEducationTitle: string | undefined;
  currentYearsTitle: string | undefined;
  buildPaginatedURL: (key: string, value?: string | number | string[]) => string;
  productDetails: ProductDetails | undefined;
  productSchoolYears: SchoolYear[] | undefined;
  userEducations: Education[] | undefined;
  paginationFilters: OverviewFilters | SubjectsProgressOverviewFilters;
}

export const useTeacherOverview = (route: string): TeacherOverviewInterface => {
  const { t } = useTranslation("teacher-dashboard");
  const history = useHistory();
  const { productId }: BasicProgressOverviewPagePathParams = useParams();
  const { queryParams } = useOnLocationChange(history);
  const { showSchoolYearFilter: enableSchoolYearFilter } = useFeatureFlags();

  const {
    page = DEFAULT_PAGE,
    resultsPerPage = DEFAULT_ROWS_PER_PAGE,
    educationId,
    schoolYearStart,
    schoolYearEnd,
    searchTerm,
    groupId,
  }: BasicProgressOverviewPageQueryParams = queryString.parse(queryParams, { parseNumbers: true });

  const paginationFilters: OverviewFilters = {
    page,
    resultsPerPage,
    educationId,
    schoolYearStart,
    schoolYearEnd,
    groupId,
    searchTerm,
  };

  const filters = Object.keys(paginationFilters).map((key) => ({
    key,
    value: paginationFilters[key as keyof typeof paginationFilters],
  })) as URLFields[];

  // Method to generate the URLs that will be used when clicking on the pagination controls
  const buildPaginatedURL = (key: string, value?: string | number | string[]) => {
    const newPageFilters = filters
      .flatMap((filter) => {
        if (key === "schoolYear" && filter.key === "schoolYearStart" && Array.isArray(value)) {
          return { key: "schoolYearStart", value: value[0] };
        }
        if (key === "schoolYear" && filter.key === "schoolYearEnd" && Array.isArray(value)) {
          return { key: "schoolYearEnd", value: value[1] };
        }
        if (key === filter.key && !Array.isArray(value)) {
          return { ...filter, value };
        }
        if (filter.key === PAGE_FILTER_KEY && key !== PAGE_FILTER_KEY) {
          return { key: PAGE_FILTER_KEY, value: 1 };
        }
        return filter;
      })
      .filter((newFilter) => newFilter.value)
      // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
      .reduce((accumFilters, filter) => ({ ...accumFilters, [filter.key]: filter.value }), {});

    return buildUrlWithPathAndQueryParams(route, { productId }, newPageFilters);
  };

  const { userEducations, fetchUserEducations } = useProfile();

  const {
    productDetails,
    productSchoolYears,
    fetchProductDetails,
    fetchProductSchoolYears,
    nonOwnedProductError,
  } = useProduct();

  useEffect(() => {
    fetchUserEducations();
  }, []);

  // Fetch list of available school years for the selected product
  // only if the school selector filter feature is enabled
  useEffect(() => {
    if (enableSchoolYearFilter && productId) {
      fetchProductSchoolYears(+productId);
    }
  }, [enableSchoolYearFilter, productId]);

  useEffect(() => {
    if (productDetails?.id !== +productId) fetchProductDetails(+productId);
  }, [productDetails, productId]);

  const showEducationFilter = userEducations && userEducations.length > 0;

  // Should only show school year filter if that feature is enabled and there is at least
  // one school year available for the selected product
  const showSchoolYearFilter =
    !!enableSchoolYearFilter && productSchoolYears && productSchoolYears.length > 0;

  const currentEducationTitle = paginationFilters?.educationId
    ? userEducations?.find((d) => d.id === paginationFilters.educationId)?.title
    : t("basicProgress.overview.filters.education.defaultOption");
  const currentYearsTitle = paginationFilters?.schoolYearStart
    ? `${new Date(paginationFilters.schoolYearStart).getFullYear()} / ${new Date(
        paginationFilters.schoolYearEnd ?? "",
      ).getFullYear()}`
    : t("basicProgress.overview.filters.schoolYear.defaultOption");

  return {
    page,
    productId,
    productDetails,
    nonOwnedProductError,
    showEducationFilter,
    showSchoolYearFilter,
    currentEducationTitle,
    currentYearsTitle,
    productSchoolYears,
    userEducations,
    paginationFilters,
    buildPaginatedURL,
  };
};
