import React from "react";
import type { AbsolutePositionedViewProps } from "./AbsolutePositionedView.model";
import { StyledAbsolutePositionedView } from "./AbsolutePositionedView.styled";

const AbsolutePositionedView = React.forwardRef<HTMLDivElement, AbsolutePositionedViewProps>(
  ({ children, ...props }, ref) => (
    <StyledAbsolutePositionedView ref={ref} {...props}>
      {children}
    </StyledAbsolutePositionedView>
  ),
);
AbsolutePositionedView.displayName = "AbsolutePositionedView";

export { AbsolutePositionedView };
