import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { CssClickableIcon } from "styles/helpers/layout";

export const StyledEditIcon = styled(CssClickableIcon)<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  transition: 0.2s ease-in-out;

  :hover {
    color: ${(props: ThemeProps) => props.theme.palette.secondary.brand};
  }
`;
