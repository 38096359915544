import { addDays, addMinutes } from "date-fns";
import { Formik, type FormikHelpers } from "formik";
import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { AvailableLanguage } from "constants/language-constants";
import { modalIds } from "constants/modals-constants";

import { useModal } from "hooks/useModal";

import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox/Checkbox";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { StyledErrorMessage } from "components/InputWithErrorMessage/InputWithErrorMessage.styled";
import { SvgIconReact } from "components/SvgIconReact";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useScheduledTests } from "hooks/useScheduledTests";
import { Heading3, Heading4 } from "styles/elements/Headings";
import {
  type EditReviewPeriodFormFields,
  buildEditReviewPeriodSchema,
} from "./EditReviewPeriod.schema";
import type { EditReviewPeriodModalProps } from "./EditReviewPeriodModal.model";
import { StyledCustomReviewDate, StyledModal, StyledSection } from "./EditReviewPeriodModal.styled";

export const EditReviewPeriodModal: React.FC<EditReviewPeriodModalProps> = ({ scheduledTest }) => {
  const { t, i18n } = useTranslation("teacher-dashboard");
  const { closeModal, isModalOpen } = useModal();
  const { showPasswordForReviewPeriod } = useFeatureFlags();

  const { updateScheduledTest, fetchScheduledTest } = useScheduledTests();

  const testReviewPeriodHasPassed =
    scheduledTest.reviewEndDate && new Date() > new Date(scheduledTest.reviewEndDate);

  // Initialize form values with information on the selected test
  //  If currently configured review period is already in the past, then we'd
  // default the review date fields to the next 24 hours
  const initialValues: EditReviewPeriodFormFields = useMemo(
    () => ({
      testEndDate: addMinutes(
        new Date(scheduledTest.startDate),
        scheduledTest.duration + scheduledTest.extraTime,
      ),
      reviewStartDate:
        scheduledTest.reviewStartDate && !testReviewPeriodHasPassed
          ? new Date(scheduledTest.reviewStartDate)
          : new Date(),
      reviewEndDate:
        scheduledTest.reviewEndDate && !testReviewPeriodHasPassed
          ? new Date(scheduledTest.reviewEndDate)
          : addDays(new Date(), 1),
      useCustomReviewPassword: !!scheduledTest.reviewPassword,
      reviewPassword: scheduledTest.reviewPassword || "",
    }),
    [scheduledTest, isModalOpen],
  );

  // Track usage of the save button
  const onSaveClick = () => {
    trackPlatformEvent({
      module: TEACHER_MODULES.SCHEDULED_TESTS,
      itemId: !testReviewPeriodHasPassed
        ? PLATFORM_EVENT_TYPES.SCHEDULED_TEST_EVENT_TYPES.EDIT_SCHEDULED_TEST_REVIEW_PERIOD_BUTTON
        : PLATFORM_EVENT_TYPES.SCHEDULED_TEST_EVENT_TYPES
            .CREATE_SCHEDULED_TEST_REVIEW_PERIOD_BUTTON,
      value: JSON.stringify(scheduledTest),
    });
  };

  // When form is submitted, trigger request to update test data in the server
  const onFormSubmit = (
    values: EditReviewPeriodFormFields,
    actions: FormikHelpers<EditReviewPeriodFormFields>,
  ) => {
    updateScheduledTest(scheduledTest.id, {
      reviewStartDate: values.reviewStartDate.toISOString(),
      reviewEndDate: values.reviewEndDate.toISOString(),
      reviewPassword: values.useCustomReviewPassword ? values.reviewPassword : null,
    }).then(() => {
      actions.setSubmitting(false);
      fetchScheduledTest(scheduledTest.id);
      closeModal();
    });
  };

  return (
    <StyledModal
      dataCy="edit-review-period-modal"
      isOpen={isModalOpen(modalIds.editReviewPeriodModal)}
      onClose={closeModal}
    >
      <Heading3>
        {testReviewPeriodHasPassed
          ? t("ownedScheduledTestResults.details.reviewPeriodModal.titlePast")
          : t("ownedScheduledTestResults.details.reviewPeriodModal.titleFuture")}
      </Heading3>
      <p>
        {testReviewPeriodHasPassed
          ? t("ownedScheduledTestResults.details.reviewPeriodModal.descriptionPast")
          : t("ownedScheduledTestResults.details.reviewPeriodModal.descriptionFuture")}
      </p>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={buildEditReviewPeriodSchema()}
        onSubmit={onFormSubmit}
      >
        {(formik) => (
          <form noValidate onSubmit={formik.handleSubmit}>
            {/* -- Review dates -- */}
            <StyledSection>
              <Heading4>
                <SvgIconReact iconName="calendarIcon" />{" "}
                {t("ownedScheduledTestResults.details.reviewPeriodModal.timeInputTitle")}
              </Heading4>

              <StyledCustomReviewDate
                hideParagraph
                dataCy="edit-review-period-date-fields"
                hasError={!!(formik.errors.reviewStartDate || formik.errors.reviewEndDate)}
                language={i18n.language as AvailableLanguage}
                reviewEndDateValue={formik.values.reviewEndDate}
                reviewStartDateValue={formik.values.reviewStartDate}
                onReviewEndDateChange={(updatedValue) => {
                  formik.setFieldValue("reviewEndDate", updatedValue);
                }}
                onReviewStartDateChange={(updatedValue) => {
                  formik.setFieldValue("reviewStartDate", updatedValue);
                }}
              />

              {(formik.errors.reviewStartDate || formik.errors.reviewEndDate) && (
                <StyledErrorMessage>
                  {formik.errors.reviewStartDate || formik.errors.reviewEndDate}
                </StyledErrorMessage>
              )}
            </StyledSection>

            {/* -- Review password -- */}
            {showPasswordForReviewPeriod && (
              <StyledSection>
                <Heading4>
                  <SvgIconReact iconName="lockIcon" />{" "}
                  {t("ownedScheduledTestResults.details.reviewPeriodModal.addPasswordTitle")}
                </Heading4>
                <p>
                  {t("ownedScheduledTestResults.details.reviewPeriodModal.addPasswordDescription")}
                </p>
                <Checkbox
                  checked={formik.values.useCustomReviewPassword}
                  dataCy="review-period-password-checkbox"
                  id="review-period-password-checkbox"
                  label={t(
                    "ownedScheduledTestResults.details.reviewPeriodModal.passwordInputLabel",
                  )}
                  name="useCustomReviewPassword"
                  onChange={formik.handleChange}
                />
                {formik.values.useCustomReviewPassword && (
                  <InputWithErrorMessage
                    aria-label={t(
                      "ownedScheduledTestResults.details.reviewPeriodModal.passwordInputLabel",
                    )}
                    dataCy="review-period-password-input"
                    disabled={formik.isSubmitting}
                    errorMessage={formik.errors.reviewPassword}
                    hasError={!!(formik.errors.reviewPassword && formik.touched.reviewPassword)}
                    name="reviewPassword"
                    value={formik.values.reviewPassword}
                    onChange={formik.handleChange}
                  />
                )}
              </StyledSection>
            )}

            <Button
              dataCy="save-review-period-button"
              disabled={formik.isSubmitting}
              type="submit"
              variant="primary"
              onClick={onSaveClick}
            >
              {t(
                "ownedScheduledTestResults.details.reviewPeriodModal.buttons.savePeriodButton.label",
                "Inzage opslaan",
              )}
            </Button>
          </form>
        )}
      </Formik>
    </StyledModal>
  );
};
