import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { SortableTable } from "components/SortableTable/SortableTable";
import { StyledCell, StyledHeaderCell } from "components/SortableTable/SortableTable.styled";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { CssIcon } from "styles/helpers/layout";

export const StyledTable = styled(SortableTable)<ThemeProps>`
  ${StyledHeaderCell} {
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
  }

  ${StyledCell} {
    padding: 0.75rem 1rem;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
`;

export const StyledScoreContainer = styled.div<ThemeProps>`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledExtraTimeLabel = styled.span<ThemeProps>`
  display: block;
  margin-left: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._12};
  text-align: center;
  background: ${(props: ThemeProps) => props.theme.palette.neutral._900};
`;

export const StyledIsInvalidLabel = styled.span<ThemeProps>`
  display: block;
  margin-left: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._12};
  text-align: center;
  color: ${(props: ThemeProps) => props.theme.palette.feedback.error.brand};
  background: ${(props: ThemeProps) => props.theme.palette.feedback.error._950};
`;

export const StyledCertificateIcon = styled(CssIcon)<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.palette.feedback.success._800};
`;
