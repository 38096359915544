import { Formik } from "formik";
import type React from "react";
import { useTranslation } from "react-i18next";

import { modalIds } from "constants/modals-constants";
import type { ResultsAPI } from "generated/types";
import { useDomainHandler } from "hooks/useDomainHandler";

import { useModal } from "hooks/useModal";

import { Button } from "components/Button";
import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { Modal } from "components/Modal";

import { CssLabel } from "styles/helpers/layout";
import { buildSEBKeySchema } from "./SEBKeyUpsertDialog.schema";
import {
  StyledButtonWrapper,
  StyledDialog,
  StyledInnerDialog,
  StyledInputsWrapper,
  StyledSpacer,
  StyledTitle,
} from "./SEBKeyUpsertDialog.styled";

export interface SEBKeyUpsertDialogProps {
  editSEBKey: ResultsAPI.SEBKey | null;
  onSubmit: (key: ResultsAPI.InsertSEBKey | ResultsAPI.UpdateSEBKey) => void;
}

export const SEBKeyUpsertDialog: React.FC<SEBKeyUpsertDialogProps> = ({
  editSEBKey: sebKey,
  onSubmit,
}) => {
  const { t } = useTranslation("scheduled-tests");
  const { isModalOpen, closeModal } = useModal();
  const { getCustomNameByDomain } = useDomainHandler();

  const title = sebKey
    ? t("testOptionsStep.sebKeys.modal.upsert.editTitle")
    : t("testOptionsStep.sebKeys.modal.upsert.createTitle");
  const showDescription = sebKey === null;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: sebKey?.name ?? "",
        key: sebKey?.key ?? "",
      }}
      validationSchema={buildSEBKeySchema()}
      onSubmit={(values, { resetForm }) => {
        onSubmit(
          sebKey?.id
            ? ({
                id: sebKey.id,
                name: values.name,
                key: values.key,
              } as ResultsAPI.UpdateSEBKey)
            : ({
                name: values.name,
                key: values.key,
              } as ResultsAPI.InsertSEBKey),
        );
        resetForm({ values: { key: "", name: "" } });
      }}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <Modal
            showCloseButton
            isOpen={isModalOpen(modalIds.sebKeysModal)}
            onClose={() => closeModal()}
          >
            <StyledDialog>
              <StyledInnerDialog>
                <StyledTitle>{title}</StyledTitle>
                {showDescription &&
                  t("testOptionsStep.sebKeys.modal.upsert.description", {
                    appName: getCustomNameByDomain(),
                  })}
                <StyledInputsWrapper>
                  <CssLabel htmlFor="seb-key-popup-key-name-input">
                    {t("testOptionsStep.sebKeys.modal.upsert.name")}
                  </CssLabel>
                  <InputWithErrorMessage
                    dataCy="name-input"
                    errorMessage={formik.errors.name}
                    hasError={!!(formik.errors.name && formik.touched.name)}
                    id="seb-key-popup-key-name-input"
                    name="name"
                    placeholder={t("testOptionsStep.sebKeys.modal.upsert.nameLabel")}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  <StyledSpacer />
                  <CssLabel htmlFor="seb-key-popup-key-input">
                    {t("testOptionsStep.sebKeys.modal.upsert.key")}
                  </CssLabel>
                  <InputWithErrorMessage
                    dataCy="key-input"
                    errorMessage={formik.errors.key}
                    hasError={!!(formik.errors.key && formik.touched.key)}
                    id="seb-key-popup-key-input"
                    name="key"
                    placeholder={t("testOptionsStep.sebKeys.modal.upsert.keyLabel")}
                    value={formik.values.key}
                    onChange={formik.handleChange}
                  />
                  <StyledSpacer />
                  <StyledButtonWrapper>
                    <Button
                      dataCy="cancel"
                      type="button"
                      variant="default"
                      onClick={() => closeModal()}
                    >
                      {t("testOptionsStep.sebKeys.modal.upsert.cancel")}
                    </Button>
                    <Button dataCy="submit" type="submit" variant="primary">
                      {t("testOptionsStep.sebKeys.modal.upsert.submit")}
                    </Button>
                  </StyledButtonWrapper>
                </StyledInputsWrapper>
              </StyledInnerDialog>
            </StyledDialog>
          </Modal>
        </form>
      )}
    </Formik>
  );
};
