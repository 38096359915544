import type { ThemeProps } from "models/styles/theme";
import { ParagraphLarge } from "styles/elements/Texts";
import { Box } from "styles/helpers/layout";

import { Button } from "components/Button";
import styled from "styled-components";

export const UploadContainer = styled(Box)`
  border: 2px dashed ${({ theme }: ThemeProps) => theme.palette.neutral._800};
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._999};;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._950};;
  }
  
`;

export const UploadButton = styled(Button)`
  background-color: transparent;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  border: solid 1px ${({ theme }: ThemeProps) => theme.palette.secondary.brand};;
`;

export const FileInput = styled.input`
  display: none;
`;

export const StyledParagraph = styled(ParagraphLarge)`
   color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
`;
export const StyledTitle = styled(ParagraphLarge)`
   color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
   font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold};
`;
