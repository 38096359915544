export interface StudentInvitation {
  licenseExpirationDate: string;
  productNames: string;
  studentsEmailList: string[];
  productsList: number[];
  initialSkillLevel?: number;
}

export interface StudentInvitationResponse {
  ownersList: string[];
  ownersAmount: number;
  notOwnersAmount: number;
}

export interface InvitationDetails {
  id: string;
  productNames: string[];
}

export enum InvitationStatus {
  ACCEPTED = "accepted",
  PENDING = "awaiting",
}

export interface Invitation {
  id: string;
  email: string;
  status: InvitationStatus;
  products: string;
  createdOn: string;
}

export interface InvitationList {
  totalInvitations: number;
  invitations: Invitation[];
}

export interface InvitationListFilter {
  email: string | null;
  accepted?: boolean;
  page: number;
  resultsPerPage: number;
}
