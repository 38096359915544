import styled from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledMessageText = styled(Heading3)<Props>`
  font-weight: ${({ theme }: Props) => theme.font.weight.normal};
`;
