import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/calculateRem";

interface TabProps extends ThemeProps {
  $selected: boolean;
}

export const Tab = styled.div<TabProps>`
  border-bottom: ${calculateRem(2)} solid
    ${({ theme, $selected }: TabProps) => ($selected ? theme.palette.secondary.brand : theme.palette.neutral._600)};
  color: ${({ theme, $selected }: TabProps) => ($selected ? theme.palette.neutral.black : theme.palette.neutral._600)};
  margin: 0 ${calculateRem(9)};
  padding-bottom: ${calculateRem(9)};
  font-size: ${({ theme }: TabProps) => theme.font.size._16};
  font-weight: ${({ theme }: TabProps) => theme.font.weight.bold};
  text-align: center;
  width: ${calculateRem(255)};
  cursor: pointer;
`;
