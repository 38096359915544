import styled from "styled-components/macro";

import { Button } from "components/Button";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import type { ThemeProps } from "models/styles/theme";
import { ParagraphLarge } from "styles/elements/Texts";

export const StyledParagraph = styled(ParagraphLarge)`
  color: ${(props: ThemeProps) => props.theme.palette.neutral._500};
  line-height: 24px;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const StyledButton = styled(Button)`
  display: flex;
  width: fit-content;
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: bold;
  padding: 0.5rem 1rem;
  .content {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const StyledPasswordField = styled.div`
  width: 23rem;
  margin-bottom: 2.5rem;
  position: relative;
`;

export const StyledInput = styled(InputWithErrorMessage)`
  padding-right: 3rem;
`;

export const StyledIcon = styled(FontAwesomeIcon)<ThemeProps>`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  margin-top: 1px;
  color: ${(props: ThemeProps) => props.theme.palette.neutral._600};
`;
