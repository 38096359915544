import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { STUDENT_MODULES, type TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { SvgIconReact } from "components/SvgIconReact/SvgIconReact";
import { useImmersiveReader } from "hooks/useImmersiveReader";
import type React from "react";
import { useTranslation } from "react-i18next";
import { StyledImmersiveReaderButton } from "./ImmersiveReaderButton.styled";

interface Props {
  targetIndex: number;
  targetSelector: string;
  trackingValue?: {
    module: STUDENT_MODULES | TEACHER_MODULES;
  };
  isBlock?: boolean;
}

export const ImmersiveReaderButton: React.FC<Props> = ({
  targetIndex,
  targetSelector,
  trackingValue,
  isBlock = false,
}) => {
  const { t } = useTranslation("common");
  const ir = useImmersiveReader(targetSelector);

  if (!ir.irEnabled) {
    return null;
  }

  const handleClick = () => {
    if (trackingValue) {
      trackPlatformEvent({
        module: STUDENT_MODULES.BASIC_PROGRESS,
        itemId: PLATFORM_EVENT_TYPES.ACCESSIBILITY.CLICK_INIT_IMMERSIVE_READER,
      });
    }

    return ir.handleClickImmersiveReader(targetIndex);
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === "Space") {
      handleClick();
    }
  };

  return (
    <StyledImmersiveReaderButton
      aria-label={t("iconButtonLabel.immersiveReader")}
      data-cy="immersive-reader-button"
      isBlock={isBlock}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      className="irSkip"
    >
      <SvgIconReact iconName={"immersiveReader"} size={2} />
    </StyledImmersiveReaderButton>
  );
};
