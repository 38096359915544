import { parseISO } from "date-fns";
import i18n from "i18n";
import type React from "react";

import type { AvailableLanguage } from "constants/language-constants";
import { CustomReviewDate } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/TestDatesStep/components/CustomReviewDate/CustomReviewDate";

export interface ReviewDatesProps {
  className?: string;
  startDate: string;
  endDate: string;
  onReviewEndDateChange: (updatedValue: string) => void;
  onReviewStartDateChange: (updatedValue: string) => void;
}

export const ReviewDates: React.FC<ReviewDatesProps> = ({
  className,
  startDate,
  endDate,
  onReviewEndDateChange,
  onReviewStartDateChange,
}) => {
  const parsedStartDate = parseISO(startDate);
  const parsedEndDate = parseISO(endDate);

  return (
    <CustomReviewDate
      hideParagraph
      isEditingScheduledTest
      alignment="vertical"
      className={className}
      dataCy="admin-review-date"
      language={i18n.language as AvailableLanguage}
      reviewEndDateValue={parsedEndDate}
      reviewStartDateValue={parsedStartDate}
      onReviewEndDateChange={(updatedEndDate) =>
        onReviewEndDateChange(updatedEndDate.toISOString())
      }
      onReviewStartDateChange={(updatedStartDate) =>
        onReviewStartDateChange(updatedStartDate.toISOString())
      }
    />
  );
};
