import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";

import { serviceENV } from "constants/env-constants";
import { useProduct } from "hooks/useProduct";
import { useProfile } from "hooks/useProfile";

import { ModuleType } from "models/product/ProductDetails";
import type { UsetifulContextValue, UsetifulWindow } from "./Usetiful.model";

export const UsetifulContext: React.Context<UsetifulContextValue> =
  React.createContext<UsetifulContextValue>(null);

interface ProductMetadata {
  productId: number;
  productTitle: string;
  adaptiveModuleId?: number;
  writingModuleId?: number;
}

const usetifulToken = process.env.REACT_APP_USETIFUL_TOKEN;

export const UsetifulProvider: React.FC = observer(({ children }) => {
  const [usetifulAPI, setUsetifulAPI] = useState<UsetifulContextValue>(null);

  const { userDetails } = useProfile();
  const { productDetails } = useProduct();

  // Initialize Usetiful on page load
  useEffect(() => {
    // Don't attempt to inject usetiful scripts if we're missing a token
    if (!usetifulToken) {
      return;
    }

    const usetifulScriptTag = document.createElement("script");

    usetifulScriptTag.async = true;
    usetifulScriptTag.src = "https://www.usetiful.com/dist/usetiful.js";
    usetifulScriptTag.setAttribute("id", "usetifulScript");
    usetifulScriptTag.dataset.token = usetifulToken;

    document.head.append(usetifulScriptTag);

    return () => {
      document.head.removeChild(usetifulScriptTag);
    };
  }, []);

  // Generate an object with product metadata that we'd need to use in Usetiful
  const currentProductMetadata: ProductMetadata | null = useMemo(() => {
    if (!productDetails) {
      return null;
    }

    const adaptiveModule = productDetails.modules.find(({ type }) => type === ModuleType.ADAPTIVE);
    const writingModule = productDetails.modules.find(({ type }) => type === ModuleType.WRITING);

    return {
      productId: productDetails.id,
      productTitle: productDetails.title,
      adaptiveModuleId: adaptiveModule?.id,
      writingModuleId: writingModule?.id,
    } as ProductMetadata;
  }, [productDetails]);

  // Override segment metadata based on current user and product
  // @see https://help.usetiful.com/support/solutions/articles/77000504889-using-tags-to-segment-users-and-customize-tours
  useEffect(() => {
    if (usetifulAPI?.user) {
      usetifulAPI.user.setTags({
        env: serviceENV(),
        role: userDetails?.role,
        userId: userDetails?.id,
        language: userDetails?.language,
        ...(currentProductMetadata || {}),
      });
    }
  }, [usetifulAPI, userDetails, currentProductMetadata]);

  // Store some product data in Usetiful's script dataset to allow redirecting to dynamic URLs
  // @see https://help.usetiful.com/support/solutions/articles/77000502838-how-set-up-a-redirect-step-for-dynamic-url-s
  useEffect(() => {
    const usetifulScriptTag = document.getElementById("usetifulScript");
    if (usetifulAPI && usetifulScriptTag) {
      const { productId, adaptiveModuleId, writingModuleId } = currentProductMetadata || {};
      usetifulScriptTag.dataset.productId = productId?.toString() || "null";
      usetifulScriptTag.dataset.adaptiveModuleId = adaptiveModuleId?.toString() || "null";
      usetifulScriptTag.dataset.writingModuleId = writingModuleId?.toString() || "null";
    }
  }, [usetifulAPI, currentProductMetadata]);

  // Store reference to USETIFUL object to call API methods on demand
  useEffect(() => {
    setUsetifulAPI((window as UsetifulWindow).USETIFUL || null);
  }, [(window as UsetifulWindow).USETIFUL]);

  return <UsetifulContext.Provider value={usetifulAPI}>{children}</UsetifulContext.Provider>;
});
