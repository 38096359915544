import { ADMIN_ENVIRONMENT_ROUTES } from "constants/routes";
import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useCurrentPathMatch } from "hooks/useCurrentPathMatch";
import { SchoolType } from "models/auth/Login";

import { SvgIconReact } from "components/SvgIconReact";
import { SchoolUpdateForm } from "./components/SchoolUpdateForm/SchoolUpdateForm";

import { StyledBackButton, StyledSchoolDetailsPage } from "./SchoolDetails.styled";

export const SchoolDetailsPage: React.FC = observer(() => {
  const { t } = useTranslation("admin-environment");
  const history = useHistory();
  const { currentRouteMatches } = useCurrentPathMatch();

  // Define which variant of the page we're seeing based on the route
  // This will determine whether we should filter results by school or company type
  const variant: SchoolType = currentRouteMatches(ADMIN_ENVIRONMENT_ROUTES.COMPANY_DETAILS)
    ? SchoolType.COMPANY
    : SchoolType.SCHOOL;

  return (
    <StyledSchoolDetailsPage>
      <StyledBackButton
        dataCy="previous-page-button"
        variant="default"
        onClick={() => {
          history.push(
            variant === SchoolType.SCHOOL
              ? ADMIN_ENVIRONMENT_ROUTES.SCHOOL_LIST
              : ADMIN_ENVIRONMENT_ROUTES.COMPANY_LIST,
          );
        }}
      >
        <SvgIconReact iconName="leftArrowIcon" />
        {t("schoolDetails.backButtonText", "Back")}
      </StyledBackButton>
      <SchoolUpdateForm />
    </StyledSchoolDetailsPage>
  );
});
