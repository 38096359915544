import styled, { css } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
  $isActive?: boolean;
}

export const StyledResultsContainer = styled.div<Props>`
  transition-duration: 500ms;
  background: ${({ theme }: Props) => theme.palette.neutral.white};
  padding: 1.5rem;
  position: absolute;
  z-index: 5;
  left: 0;
  top: 3rem;
  border-radius: 4px;
  box-shadow: 0px 3px 3px 0px rgba(200, 200, 200, 1);
  width: 100%;
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._950};

  opacity: 1;
  height: fit-content;

  ${(props: Props) =>
    !props.$isActive &&
    css`
      opacity: 0;
      height: 0;
    `};
`;

export const StyledResultList = styled.ul<Props>`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    :not(:last-child) {
      margin-bottom: 1rem;
    }

    a {
      font-size: 1rem;
      flex-direction: column;

      span {
        color: ${({ theme }: Props) => theme.palette.neutral._500};
        margin-left: 1rem;
        font-size: ${({ theme }: Props) => theme.font.size._14};
      }
    }
  }
`;

export const StyledNoResultsMessage = styled.div<Props>`
  text-align: center;
  width: 100%;
  margin: 4rem 0;

  & p {
    margin: 1rem auto 0 auto;
  }
`;
