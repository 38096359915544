import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { Breadcrumbs } from "components/Breadcrumbs";
import { Button } from "components/Button";
import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TextArea } from "components/TextArea";
import { TEACHER_HOME_PAGE_ROUTES } from "constants/routes";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useGroups } from "hooks/useGroups";
import { Heading2 } from "styles/elements/Headings";

import {
  FormGroupName,
  FormGroupNameLine,
  FormStudentsWrapper,
  FormWrapper,
  NotAddedStudentsWrapper,
} from "./components/Form.styled";
import { StudentsList } from "./components/StudentsList";

export const EditGroup: React.FC = observer(() => {
  const {
    groupName,
    changeGroupName,
    savingGroup,
    getGroup,
    loading,
    currentGroup,
    editGroupName,
    addStudentsToGroup,
    studentsToAdd,
    addingStudents,
    removeStudentsFromGroup,
    removingStudents,
    setStudentsToAdd,
    notAddedStudents,
    setNotAddedStudents,
  } = useGroups();
  const { t } = useTranslation("groups");
  const { isBusinessDomain, getStudentTermByDomain, getTeacherTermByDomain } = useDomainHandler();
  const { groupId }: { groupId: string } = useParams();

  const onEditNameFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    editGroupName(Number.parseInt(groupId, 10));
  };

  const onAddStudentsFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    addStudentsToGroup(Number.parseInt(groupId, 10));
  };

  const onRemoveStudent = (studentId: number) => {
    removeStudentsFromGroup(Number.parseInt(groupId, 10), [studentId]);
  };

  const changeStudentsToAdd = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStudentsToAdd(evt.target.value);
  };

  React.useEffect(() => {
    getGroup(Number.parseInt(groupId, 10));
    return () => {
      setStudentsToAdd("");
      setNotAddedStudents([]);
    };
  }, [getGroup, groupId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!currentGroup) {
    return <div>{t("groupNotFound")}</div>;
  }

  return (
    <>
      <Breadcrumbs
        excludeRoutes={[
          "/",
          TEACHER_HOME_PAGE_ROUTES.EDIT_GROUP.replace(":groupId", ""),
          buildUrlWithPathParams(TEACHER_HOME_PAGE_ROUTES.EDIT_GROUP, { groupId }),
        ]}
        routes={[
          {
            path: TEACHER_HOME_PAGE_ROUTES.HOME_PAGE,
            breadcrumb: t("breadcrumbs.teacher", { teacherTerm: getTeacherTermByDomain() }),
          },
          { path: TEACHER_HOME_PAGE_ROUTES.GROUPS, breadcrumb: t("breadcrumbs.groups") },
        ]}
      />
      <Heading2>{currentGroup.name}</Heading2>
      <FormWrapper>
        <FormGroupName onSubmit={onEditNameFormSubmit}>
          <label htmlFor="group-name">{t("groupName")}</label>
          <FormGroupNameLine>
            <InputWithErrorMessage
              dataCy="group-name"
              hasError={false}
              id="group-name"
              name="group-name"
              value={groupName}
              onChange={changeGroupName}
            />
            <Button
              data-cy="edit-group-name-submit-btn"
              disabled={
                groupName.trim() === "" || savingGroup || groupName.trim() === currentGroup?.name
              }
              isLoading={savingGroup}
              type="submit"
              variant="primary"
            >
              {t("editGroupName")}
            </Button>
          </FormGroupNameLine>
        </FormGroupName>
        <hr />
        <FormStudentsWrapper $hasEmptyStudentsList={currentGroup.students.length === 0}>
          <div>
            <form onSubmit={onAddStudentsFormSubmit}>
              <h3>
                {t("addStudentsTitle", {
                  studentTerm: getStudentTermByDomain({ usePlural: true }),
                })}
              </h3>
              <p>
                {!isBusinessDomain()
                  ? t("addStudentsDescription")
                  : t("addStudentsDescriptionBusiness")}
              </p>
              <TextArea
                data-cy="add-group-students-textarea"
                placeholder={t("addStudentsTextareaPlaceholder")}
                rows={10}
                value={studentsToAdd}
                onChange={changeStudentsToAdd}
              />
              {notAddedStudents?.length > 0 && (
                <NotAddedStudentsWrapper data-cy="not-addedd-group-students">
                  <p>
                    <b>
                      {t("headsUp")}
                      :&nbsp;
                    </b>
                    {t("notFoundStudents", {
                      studentTerm: getStudentTermByDomain({ usePlural: true }),
                    })}
                  </p>
                  <ul>
                    {notAddedStudents.map((student) => (
                      <li key={student}>{student}</li>
                    ))}
                  </ul>
                </NotAddedStudentsWrapper>
              )}
              <Button
                data-cy="add-group-students-submit-btn"
                disabled={studentsToAdd.trim() === ""}
                isLoading={addingStudents}
                type="submit"
                variant="primary"
              >
                {t("addStudents", { studentTerm: getStudentTermByDomain({ usePlural: true }) })}
              </Button>
            </form>
          </div>
          {currentGroup.students.length > 0 && (
            <div>
              <h3>
                {t("studentsList", { studentTerm: getStudentTermByDomain({ usePlural: true }) })}
              </h3>
              <StudentsList
                removingStudentsIds={removingStudents}
                students={currentGroup.students}
                onRemove={onRemoveStudent}
              />
            </div>
          )}
        </FormStudentsWrapper>
      </FormWrapper>
    </>
  );
});
