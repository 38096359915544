import { Checkbox } from "components/Checkbox/Checkbox";
import { DatePicker } from "components/DatePicker/DatePicker";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { IconButton } from "components/IconButton/IconButton";
import { InfoTooltip } from "components/InfoTooltip/InfoTooltip";
import { SvgIconReact } from "components/SvgIconReact";
import { TimePicker } from "components/TimePicker/TimePicker";
import type { AvailableLanguage } from "constants/language-constants";
import { ZENDESK_ROUTES } from "constants/routes";
import { add, addDays, isSameDay } from "date-fns";
import type { ScheduledTestDate } from "models/exam/ScheduledTest";
import { FormErrorMessage } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/components/FormErrorMessage/FormErrorMessage";
import { useFormUpdate } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/hooks/useFormUpdate";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Heading4 } from "styles/elements/Headings";

import { useDomainHandler } from "hooks/useDomainHandler";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useLanguage } from "hooks/useSelectedLanguage";

import { dateToTimePickerString, updateDateTime } from "_helpers/datePickerHelper";

import { ParagraphLarge, ParagraphMedium } from "styles/elements/Texts";
import { CssClickableIcon, CssFlex, CssGrid } from "styles/helpers/layout";
import {
  FormInput,
  FormLabel,
  FormStep,
  FormStepHeader,
  FormStepHeading,
  FormStepIntro,
  StyledTeacherWarning,
} from "../FormStep.styled";
import type { TestDatesStepFormValues, TestDatesStepProps } from "./TestDatesStep.model";
import {
  AddMomentButton,
  DateRowContainer,
  DatesRow,
  DatesRowAddColumn,
  DatesRowColumn,
  InlineInfoTooltip,
  StudentCountRowColumn,
  StyledRecommendedDuration,
  StyledTeacherNoSupportWarning,
  TestDurationInput,
} from "./TestDatesStep.styled";
import { createValidationSchema } from "./TestDatesStep.validation";
import { CustomReviewDate } from "./components/CustomReviewDate/CustomReviewDate";
import { getLatestTestInstance } from "./utils";

export const TestDatesStep: React.FC<TestDatesStepProps> = ({
  formValues,
  formErrors,
  isEditingScheduledTest = false,
  isDisabled,
  onFormUpdate,
}) => {
  const { t, i18n } = useTranslation("scheduled-tests");
  const {
    showPasswordForReviewPeriod,
    showSupportUnavailabilityNotice,
    supportUnavailabilityNoticeMessage,
  } = useFeatureFlags();
  const { currentLanguage } = useLanguage();
  const { getStudentTermByDomain } = useDomainHandler();

  const supportUnavailableMessage =
    showSupportUnavailabilityNotice &&
    JSON.parse(supportUnavailabilityNoticeMessage)[currentLanguage];

  const [isInfoMessageOpen, setIsInfoMessageOpen] = useState(false);
  const [values, setValues] = useState<TestDatesStepFormValues>(
    (({
      schedule = [],
      extraTime = null,
      useCustomReviewDate = false,
      reviewStartDate = null,
      reviewEndDate = null,
      reviewPassword = "",
      estimatedStudents,
    }) => ({
      schedule,
      extraTime,
      useCustomReviewDate,
      reviewStartDate,
      reviewEndDate,
      reviewPassword,
      estimatedStudents,
    }))(formValues) as TestDatesStepFormValues,
  );

  const [useCustomReviewPassword, setUseCustomReviewPassword] = useState(!!values.reviewPassword);

  useEffect(() => {
    const { schedule, reviewStartDate, reviewEndDate } = values;
    if (schedule && reviewStartDate && reviewEndDate) {
      const endDate = add(schedule[0].startDate, { minutes: schedule[0].duration });
      if (
        endDate.toISOString() === reviewStartDate?.toISOString() &&
        add(reviewStartDate, { days: 1 }).toISOString() === reviewEndDate?.toISOString()
      ) {
        setValues({
          ...values,
          useCustomReviewDate: false,
        });
      }
    }
  }, []);

  const validateValues = useMemo(
    () => ({
      ...values,
      useCustomReviewPassword,
    }),
    [values, useCustomReviewPassword],
  );
  const validationSchema = createValidationSchema(isEditingScheduledTest);
  useFormUpdate<typeof validationSchema>({
    values: validateValues,
    validationSchema,
    onFormUpdate: (v, e) => {
      const { useCustomReviewPassword, ...otherValues } = v;
      if (!useCustomReviewPassword) {
        otherValues.reviewPassword = "";
      }
      onFormUpdate(otherValues, e);
    },
  });

  const addRemoveDatesEnabled = !isEditingScheduledTest;

  const addEmptyDate = (copyLast = false) => {
    const dummyDate =
      copyLast && values.schedule.length > 0
        ? values.schedule[values.schedule.length - 1]
        : {
            startDate: addDays(new Date(), 1),
            duration: 60,
            estimatedStudents: 0,
          };
    setValues({
      ...values,
      schedule: [...values.schedule, dummyDate],
    });
  };

  useEffect(() => {
    if (values.schedule.length === 0) {
      // always make sure there is one (empty) test date row
      addEmptyDate();
    }
  }, [values]);

  const onAddDateRowClick = (event: React.MouseEvent) => {
    event.preventDefault();
    // copy the last date to the new row,
    // because it's likely that it's on or around the same date
    // with a different time
    addEmptyDate(true);
  };

  const onRemoveDateRowClick = (event: React.MouseEvent, index: number) => {
    event.preventDefault();
    // 'remove' date (but actually keep it so we can give each date a unique key)
    setValues({
      ...values,
      schedule: values.schedule.filter((_, i) => i !== index),
    });
  };

  const updateRowDateValue = (
    index: number,
    propName: keyof ScheduledTestDate,
    value: Date | number,
  ) => {
    setValues((previous) => {
      const newSchedule = previous.schedule.map((date, i) => {
        if (i === index) {
          return {
            ...date,
            [propName]: value,
          };
        }
        return date;
      });

      const latestTestInstance = getLatestTestInstance(newSchedule);

      return {
        ...previous,
        schedule: newSchedule,
        reviewStartDate: latestTestInstance,
        reviewEndDate: addDays(latestTestInstance, 1),
      };
    });
  };

  const onRowDateChange = (date: Date, index: number) => {
    // update date (but not time!)
    const currentDate = values.schedule[index]?.startDate;
    if (!currentDate || isSameDay(currentDate, date)) {
      // not actually changed, skip
      return;
    }
    const newDate = updateDateTime(date, currentDate.getHours(), currentDate.getMinutes());
    updateRowDateValue(index, "startDate", newDate);
  };

  const onRowTimeChange = (hours: number, minutes: number, index: number) => {
    // update the current date at this index, but time only
    const currentDate = values.schedule[index]?.startDate;
    if (
      !currentDate ||
      (currentDate.getHours() === hours && currentDate.getMinutes() === minutes)
    ) {
      // not actually changed, skip
      return;
    }
    updateRowDateValue(index, "startDate", updateDateTime(currentDate, hours, minutes));
  };

  const onRowDurationChange = (event: React.ChangeEvent<HTMLInputElement>, key: number) => {
    updateRowDateValue(key, "duration", Number.parseInt(event.target.value, 10) || 1);
  };

  const onRowEstimatedStudentsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number,
  ) => {
    updateRowDateValue(key, "estimatedStudents", Number.parseInt(event.target.value, 10));
  };

  const onUseCustomReviewDateChange = () => {
    setValues((previous) => {
      const enableCustomReviewDates = !values.useCustomReviewDate;
      const latestTestInstance = getLatestTestInstance(previous.schedule);

      // The review start and end times are always a date, but just not shown when useCustomReviewDate is false
      return {
        ...previous,
        useCustomReviewDate: enableCustomReviewDates,
        reviewStartDate: latestTestInstance,
        reviewEndDate: addDays(latestTestInstance, 1),
      };
    });
  };

  const onToggleExtraTime = () => {
    setValues({ ...values, extraTime: values.extraTime === null ? 20 : null });
  };

  const onExtraTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      extraTime: Number.parseInt(event.target.value, 10),
    });
  };

  const onReviewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, reviewPassword: event.target.value.trim() });
  };

  return (
    <FormStep>
      <FormStepHeader>
        <FormStepHeading>{t("testDateStep.header", "Datum en tijd  - stap 3")}</FormStepHeading>
        <FormStepIntro>
          {t("testDateStep.intro", { studentTerm: getStudentTermByDomain({ usePlural: true }) })}
        </FormStepIntro>
        <StyledTeacherWarning data-cy="teacher-warning-message" rowGap={isInfoMessageOpen ? 1 : 0}>
          <FontAwesomeIcon iconName="far fa-info-circle" iconSize="lg" />
          <Heading4>{t("testDateStep.warning.title")}</Heading4>
          <CssClickableIcon
            fixedWidth
            data-cy="toggle-teacher-warning-message-button"
            iconName={isInfoMessageOpen ? "fas fa-minus" : "fas fa-plus"}
            iconSize="lg"
            onClick={() => setIsInfoMessageOpen(!isInfoMessageOpen)}
          />
          {isInfoMessageOpen && (
            <div>
              <ParagraphLarge>
                <Trans
                  components={{ highlightedText: <b /> }}
                  i18nKey={"testDateStep.warning.description"}
                  t={t}
                />
              </ParagraphLarge>
            </div>
          )}
        </StyledTeacherWarning>
        {supportUnavailableMessage && (
          <StyledTeacherNoSupportWarning data-cy="unavailability-notice">
            <FontAwesomeIcon iconName="far fa-info-circle" iconSize="lg" />
            <Heading4>{supportUnavailableMessage}</Heading4>
          </StyledTeacherNoSupportWarning>
        )}
      </FormStepHeader>
      <CssFlex flexDirection="column" rowGap={1}>
        <div>
          <DateRowContainer>
            {React.Children.toArray(
              values.schedule.map((item, index) => {
                // get actual index of this date item in values.schedule,
                // because that is the array we are validating with Yup
                const startDateError = formErrors[`schedule[${index}].startDate`];
                const durationError = formErrors[`schedule[${index}].duration`];
                const estimatedStudentsError = formErrors[`schedule[${index}].estimatedStudents`];
                return (
                  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
                  <DatesRow $hasIconButton={addRemoveDatesEnabled} data-cy="test-dates-row">
                    <DatesRowColumn>
                      <FormLabel>{t("testDateStep.dateInput.label", "Date of the test")}</FormLabel>
                      <DatePicker
                        dataCy="start-date-picker"
                        disabled={isDisabled}
                        hasError={!!startDateError}
                        language={i18n.language as AvailableLanguage}
                        minDate={!isEditingScheduledTest ? new Date() : null}
                        value={item.startDate}
                        onChange={(date) => onRowDateChange(date, index)}
                      />
                      <FormErrorMessage errors={startDateError?.errors} />
                    </DatesRowColumn>
                    <DatesRowColumn>
                      <FormLabel>
                        {t("testDateStep.timeInput.label", "Start time of the test")}
                      </FormLabel>
                      <TimePicker
                        dataCy="start-time-picker"
                        disabled={isDisabled}
                        hasError={!!startDateError}
                        language={i18n.language as AvailableLanguage}
                        value={dateToTimePickerString(item.startDate)}
                        onChange={(hours, minutes) => onRowTimeChange(hours, minutes, index)}
                      />
                    </DatesRowColumn>
                    <DatesRowColumn>
                      <FormLabel htmlFor={`scheduled-test-wizard-moment-${index}-duration-input`}>
                        {t("testDateStep.durationInput.label", "Duration of the test")}
                      </FormLabel>
                      <TestDurationInput
                        disabled={isDisabled}
                        hasError={!!durationError}
                        id={`scheduled-test-wizard-moment-${index}-duration-input`}
                        min="1"
                        postfixLabel={t("testDateStep.durationInput.postfix.label", "minutes")}
                        step="1"
                        style={{ lineHeight: "1.5rem" }}
                        type="number"
                        value={item.duration}
                        onChange={(event) => onRowDurationChange(event, index)}
                      />
                      <StyledRecommendedDuration>
                        <ParagraphMedium>
                          <Link
                            target="_blank"
                            to={{ pathname: ZENDESK_ROUTES.RECOMMENDED_TEST_DURATION }}
                          >
                            {t(
                              "testDateStep.durationInput.extra",
                              "What is the recommended timespan?",
                            )}
                          </Link>
                          <FontAwesomeIcon iconName="fas fa-external-link-alt" />
                        </ParagraphMedium>
                      </StyledRecommendedDuration>
                      <FormErrorMessage errors={durationError?.errors} />
                    </DatesRowColumn>
                    {addRemoveDatesEnabled && (
                      <DatesRowAddColumn>
                        {!(index === values.schedule.length - 1) && (
                          <IconButton
                            aria-label={t("testDateStep.deleteAdditionalDate.label")}
                            dataCy="test-dates-remove-date-button"
                            disabled={isDisabled}
                            iconName="trashIcon"
                            variant="secondary"
                            onClick={(event) => onRemoveDateRowClick(event, index)}
                          />
                        )}
                      </DatesRowAddColumn>
                    )}
                    <StudentCountRowColumn>
                      <FormLabel>
                        {t("testDateStep.estimatedStudents.label", {
                          studentTerm: getStudentTermByDomain({ usePlural: true }),
                        })}
                        <InlineInfoTooltip
                          tooltipContent={t(
                            "testDateStep.estimatedStudents.infoTooltip",
                            "We'll use this to how many members of our support team you may need during the test.",
                          )}
                        />
                      </FormLabel>
                      <FormInput
                        dataCy="test-request-estimated-students-input"
                        disabled={isDisabled}
                        hasError={!!estimatedStudentsError}
                        min="1"
                        placeholder={t("testDateStep.estimatedStudents.placeholder", "e.g. 12")}
                        type="number"
                        value={Number.isNaN(item.estimatedStudents) ? "" : item.estimatedStudents}
                        onChange={(event) => onRowEstimatedStudentsChange(event, index)}
                      />
                      <FormErrorMessage
                        dataCy="test-request-estimated-students-errors"
                        errors={estimatedStudentsError?.errors}
                      />
                    </StudentCountRowColumn>
                  </DatesRow>
                );
              }),
            )}
          </DateRowContainer>
          <AddMomentButton
            dataCy="test-dates-add-date-button"
            disabled={isDisabled}
            variant="text"
            onClick={onAddDateRowClick}
          >
            <SvgIconReact iconName="plusIcon" />
            {t("testDateStep.addAdditionalDate.button", "Add extra moment")}
          </AddMomentButton>
        </div>
        {/* -- Extra time configuration -- */}
        <CssGrid gridTemplateColumns="2fr 1fr">
          <CssFlex alignItems="flex-start" columnGap={1}>
            <Checkbox
              checked={values.extraTime !== null}
              dataCy="test-request-extra-time-checkbox"
              disabled={isDisabled}
              id="test-request-extra-time"
              label={t("testDateStep.extraTime.label", "Add extra time")}
              type="checkbox"
              onChange={onToggleExtraTime}
            />
            <InfoTooltip
              tooltipContent={t("testDateStep.extraTime.infoTooltip", {
                studentTerm: getStudentTermByDomain({ usePlural: true }),
              })}
            />
          </CssFlex>
          {values.extraTime !== null && (
            <CssFlex flexDirection="column">
              <FormInput
                aria-label={t("testDateStep.extraTime.label", "Add extra time")}
                dataCy="test-request-extra-time-input"
                disabled={isDisabled}
                hasError={!!formErrors.extraTime}
                min="1"
                postfixLabel={t("testDateStep.extraTime.postfix.label", "minutes")}
                type="number"
                value={Number.isNaN(values.extraTime) ? "" : values.extraTime}
                onChange={onExtraTimeChange}
              />
              <FormErrorMessage
                dataCy="test-request-extra-time-errors"
                errors={formErrors.extraTime?.errors}
              />
            </CssFlex>
          )}
        </CssGrid>
        {/* -- Dates for review period -- */}
        <CssGrid>
          <CssFlex alignItems="flex-start" columnGap={1}>
            <Checkbox
              checked={values.useCustomReviewDate}
              dataCy="test-request-custom-review-date-checkbox"
              disabled={isDisabled}
              id="custom_review_date"
              label={t("testDateStep.customReviewDate.label", "Custom review date")}
              type="checkbox"
              onChange={onUseCustomReviewDateChange}
            />
            <InfoTooltip
              tooltipContent={t("testDateStep.customReviewDate.infoTooltip", {
                studentTerm: getStudentTermByDomain({ usePlural: true }),
              })}
            />
          </CssFlex>
          {values.useCustomReviewDate && (
            <>
              <CustomReviewDate
                hideParagraph
                dataCy="test-request-custom-review-date-fields"
                hasError={!!(formErrors.reviewStartDate || formErrors.reviewEndDate)}
                isDisabled={isDisabled}
                isEditingScheduledTest={isEditingScheduledTest}
                language={i18n.language as AvailableLanguage}
                reviewEndDateValue={values.reviewEndDate || new Date()}
                reviewStartDateValue={values.reviewStartDate || new Date()}
                onReviewEndDateChange={(updatedReviewEndDate) =>
                  setValues((prevValues) => ({
                    ...prevValues,
                    reviewEndDate: updatedReviewEndDate,
                  }))
                }
                onReviewStartDateChange={(updatedReviewStartDate) =>
                  setValues((prevValues) => ({
                    ...prevValues,
                    reviewStartDate: updatedReviewStartDate,
                  }))
                }
              />
              <FormErrorMessage
                errors={(formErrors.reviewStartDate?.errors || []).concat(
                  formErrors.reviewEndDate?.errors || [],
                )}
              />
            </>
          )}
        </CssGrid>
        {/* -- Password for review period -- */}
        {showPasswordForReviewPeriod && (
          <CssGrid gridTemplateColumns="2fr 1fr">
            <CssFlex alignItems="flex-start" columnGap={1}>
              <Checkbox
                checked={useCustomReviewPassword}
                dataCy="test-request-custom-review-password-checkbox"
                disabled={isDisabled}
                id="use-custom-review-password"
                label={t("testDateStep.customReviewPassword.label", "Add review password")}
                type="checkbox"
                onChange={(e) => setUseCustomReviewPassword(e.target.checked)}
              />
              <InfoTooltip
                tooltipContent={t("testDateStep.customReviewPassword.infoTooltip", {
                  studentTerm: getStudentTermByDomain({ usePlural: true }),
                })}
              />
            </CssFlex>
            {useCustomReviewPassword && (
              <CssFlex flexDirection="column">
                <FormInput
                  dataCy="test-review-password-input"
                  disabled={isDisabled}
                  hasError={!!formErrors.reviewPassword}
                  placeholder={t("testDateStep.customReviewPassword.placeholder", "password")}
                  value={values.reviewPassword}
                  onChange={onReviewPasswordChange}
                />
                <FormErrorMessage
                  dataCy="test-review-password-errors"
                  errors={formErrors.reviewPassword?.errors}
                />
              </CssFlex>
            )}
          </CssGrid>
        )}
      </CssFlex>
    </FormStep>
  );
};
