import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import type { AdaptiveTestWorkflowType } from "constants/exam-constants";
import type { ContentAPI } from "generated/types";
import type {
  AdaptivePracticeQuestion,
  HasPracticed,
  MixedExerciseForSubject,
  MixedExerciseForTopic,
  NextPracticeTopic,
  NextQuestionIsReadyResponse,
} from "models/adaptive-practice/AdaptivePractice";
import { type ApiRequestError, RequestError } from "models/error/Error";
import cookieStorage from "persistence";
import { handlingResponse, logError } from "repositories/utils";

export const fetchAdaptivePracticeQuestion = async (
  productId: number,
  subjectId: number,
  topicId?: number,
  workflow?: AdaptiveTestWorkflowType,
): Promise<AdaptivePracticeQuestion> => {
  try {
    const result = await axios.get(
      `/api/products/${productId}/subjects/${subjectId}/adaptive-practice`,
      {
        headers: { Authorization: cookieStorage.getToken() },
        params: { topicId, workflow },
      },
    );
    return handlingResponse<AdaptivePracticeQuestion>(
      [200],
      "Error retrieving question",
      "adaptivePracticeTestError",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_ADAPTIVE_PRACTICE_FAILED,
      extra: (error as AxiosError<ContentAPI.ErrorMessage>)?.response?.data?.extra,
    });
  }
};

export const fetchNextTopic = async (
  productId: number,
  subjectId: number,
  startTopicId?: number,
): Promise<NextPracticeTopic> => {
  try {
    const result = await axios.get(
      `/api/products/${productId}/subjects/${subjectId}/adaptive-practice/next-topic`,
      {
        headers: { Authorization: cookieStorage.getToken() },
        params: {
          startTopicId,
        },
      },
    );
    return handlingResponse<NextPracticeTopic>(
      [200, 204],
      "Error retrieving next topic",
      "nextTopicError",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_NEXT_PRACTICE_TOPIC_FAILED,
      extra: (error as AxiosError<ContentAPI.ErrorMessage>)?.response?.data?.extra,
    });
  }
};

export const fetchMixedExerciseQuestion = async (
  params: MixedExerciseForSubject | MixedExerciseForTopic,
): Promise<AdaptivePracticeQuestion> => {
  const { productId, subjectId } = params;
  try {
    const result = await axios.get(
      `/api/products/${productId}/subjects/${subjectId}/mixed-exercise`,
      {
        headers: { Authorization: cookieStorage.getToken() },
        params: { topicId: (params as MixedExerciseForTopic)?.topicId },
      },
    );
    return handlingResponse<AdaptivePracticeQuestion>(
      [200],
      "Error retrieving test",
      "mixedExerciseTestError",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_MIXED_EXERCISE_FAILED });
  }
};

export const isNextQuestionReady = async (
  testToken: string,
): Promise<NextQuestionIsReadyResponse> => {
  try {
    const result = await axios.get("/api/tests/next-question-ready", {
      headers: {
        Authorization: cookieStorage.getToken(),
        "hst-content-test-token": testToken,
      },
    });
    return handlingResponse<NextQuestionIsReadyResponse>(
      [200],
      "Error checking for progress status",
      "nextQuestionReadyError",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.IS_NEXT_QUESTION_READY_FAILED });
  }
};

export const hasPracticed = async (): Promise<HasPracticed> => {
  try {
    const result = await axios.get("/api/has-practiced", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<HasPracticed>(
      [200],
      "Error checking if user has practiced",
      "hasPracticedError",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_HAS_PRACTICED });
  }
};

export const setAdaptivePracticeWorkflow = async (
  productId: number,
  selectedWorkflow: AdaptiveTestWorkflowType,
): Promise<unknown> => {
  try {
    const result = await axios.put(
      `/api/products/${productId}/adaptive-practice/workflow`,
      { selectedWorkflow },
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<unknown>(
      [200],
      "Error saving selected workflow",
      "adaptiveWorkflowSelectError",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.SET_ADAPTIVE_PRACTICE_WORKFLOW_FAILED,
    });
  }
};
