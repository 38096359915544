enum BASIC_PROGRESS_OVERVIEW_EVENT_TYPES {
  UPDATE_VIEW_BY_TARGET_LEVEL = "update-view-by-target-level",
  UPDATE_VIEW_BY_GROUP = "update-view-by-group",
  UPDATE_VIEW_BY_EDUCATION = "update-view-by-education",
  CLICK_DOWNLOAD_REPORT = "click-download-report",
  FILTER_USERS_BY_NAME = "filter-users-by-name",
  UPDATE_GOAL_LEVEL = "update-goal-level",
  CLICK_DETAILED_STUDENT_PROGRESS_MODAL = "click-detailed-student-progress-modal",
  CLICK_TOGGLE_STUDENT_PROGRESS_SUBJECT = "click-toggle-student-progress-subject",
  CLICK_CLOSE_STUDENT_PROGRESS_SUBJECTS_MODAL = "click-close-student-progress-subjects-modal",
  CLICK_ON_NEXT_STUDENT_BUTTON = "click-on-next-student-button",
  CLICK_ON_PREVIOUS_STUDENT_BUTTON = "click-on-previous-student-button",
}

enum BASIC_PROGRESS_FOR_ACCOUNT_EVENT_TYPES {
  UPDATE_VIEW_BY_TARGET_LEVEL = "update-view-by-target-level",
  UPDATE_STUDENT_DETAILS_AS_TEACHER = "update-student-details-as-teacher",
  UPDATE_STUDENT_GOAL_LEVEL_AS_TEACHER = "update-student-goal-level-as-teacher",
  UPDATE_VIEW_BY_SKILL_TYPE = "update-view-by-skill-type",
}

enum SCHEDULED_TEST_EVENT_TYPES {
  EDIT_SCHEDULED_TEST_REVIEW_PERIOD_BUTTON = "edit-scheduled-test-review-period-button",
  CREATE_SCHEDULED_TEST_REVIEW_PERIOD_BUTTON = "create-scheduled-test-review-period-button",
}

enum SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES {
  RESET_FILTERS = "reset-scheduled-test-results-overview-filters",
  FILTER_BY_NAME = "filter-scheduled-tests-by-name",
  FILTER_BY_TYPE = "filter-scheduled-tests-by-type",
  FILTER_BY_PRODUCT = "filter-scheduled-tests-by-product",
  FILTER_BY_SKILL_LEVEL = "filter-scheduled-tests-by-skill-level",
  FILTER_BY_OWNER = "filter-scheduled-tests-by-owner",
  FILTER_BY_START_DATE = "filter-scheduled-tests-by-start-date",
}

enum STUDENT_DASHBOARD_EVENT_TYPES {
  CLICK_BEGIN_ONBOARDING = "click-begin-onboarding",
  CLICK_CONTINUE_PRACTICING_TOPIC = "click-continue-practicing-topic",
  CLICK_CONTINUE_PRACTICING_MIX = "click-continue-practicing-mix",
  CLICK_START_PRACTICING_MODULE = "click-start-practicing-module",
  CLICK_GO_TEST_ENVIRONMENT = "click-go-test-environment",
}

// biome-ignore lint/correctness/noUnusedVariables: lil bro but not the values used inside :galaxy_brain:
enum STUDENT_ADAPTIVE_PRACTICE_EVENT_TYPES {
  ENDED_ADAPTIVE_PRACTICE = "ended-adaptive-practice",
  CLICK_BACK_TO_OVERVIEW = "click-back-to-overview-agaptive-practice-results",
}

enum STUDENT_MODULES_EVENT_TYPES {
  CLICK_START_DETERMINATION_TEST = "click-start-determination-test",
  CLICK_CONTINUE_DETERMINATION_TEST = "click-continue-determination-test",
  CLICK_PRACTICE_SUBJECT = "click-start-practicing-subject",
  CLICK_PRACTICE_TOPIC = "click-start-practicing-topic",
  CLICK_GO_TO_PRACTICE_TEST = "click-go-to-practice-test",
}

enum STUDENT_ADAPTIVE_PRACTICE_EVENT_TYPES {
  CLICK_SELECT_ADAPTIVE_WORKFLOW = "click-select-adaptive-workflow",
  CLICK_EXIT_ADAPTIVE_WORKFLOW_SELECTION = "click-exit-adaptive-workflow-selection",
}

enum STUDENT_TEST_RESULT_EVENT_TYPES {
  CLICK_CLOSE_PURCHASE_PROMPT = "click-close-purchase-prompt",
  CLICK_PURCHASE_PROMPT_WEBSHOP = "click-purchase-prompt-webshop-link",
}

enum ACCESSIBILITY {
  CLICK_INIT_IMMERSIVE_READER = "click-init-immersive-reader",
  UPDATE_IR_TOGGLE_ON = "ir-toggle-on",
  UPDATE_IR_TOGGLE_OFF = "ir-toggle-off",
  UPDATE_TEXT_SIZE_MEDIUM = "update-text-size-medium",
  UPDATE_TEXT_SIZE_LARGE = "update-text-size-large",
}

enum TEACHER_INSTRUCTIONS {
  PREVIEW_TEACHER_INSTRUCTIONS_DOCUMENT = "preview-teacher-instructions",
}

enum SCHOOLYEAR {
  CLICK_OPEN_DASHBOARD = "click-open-schoolyear-dashboard",
  TOGGLE_SCHOOLYEAR_SCHEDULE_TEST = "toggle-schoolyear-integration-schedule-test",
}

export const PLATFORM_EVENT_TYPES = {
  ACCESSIBILITY,
  BASIC_PROGRESS_FOR_ACCOUNT_EVENT_TYPES,
  BASIC_PROGRESS_OVERVIEW_EVENT_TYPES,
  SCHEDULED_TEST_EVENT_TYPES,
  SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES,
  STUDENT_DASHBOARD_EVENT_TYPES,
  STUDENT_MODULES_EVENT_TYPES,
  STUDENT_ADAPTIVE_PRACTICE_EVENT_TYPES,
  STUDENT_TEST_RESULT_EVENT_TYPES,
  TEACHER_INSTRUCTIONS,
  SCHOOLYEAR,
};
