import { Button } from "components/Button";
import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledButton = styled(Button)`
  font-size: ${(props: Props) => props.theme.font.size._18};
`;
