import { useDiagnosticTest } from "hooks/useDiagnosticTest";
import { observer } from "mobx-react";
import type { DiagnosticTest } from "models/exam/DiagnosticTest";
import type { DiagnosticTestPagePathParams } from "models/path/path-params";
import type React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { DiagnosticTestBreadcrumbs } from "../components/DiagnosticTestBreadcrumbs/DiagnosticTestBreadcrumbs";
import { DiagnosticTestContent } from "../components/DiagnosticTestContent/DiagnosticTestContent";
import { StyledDiagnosticTestListPage } from "./DiagnosticTestListPage.styled";
import { DiagnosticTestListItem } from "./components/DiagnosticTestListItem/DiagnosticTestListItem";

export const DiagnosticTestListPage: React.FC = observer(() => {
  const {
    diagnosticTestGroups,
    diagnosticTestList,
    diagnosticTestListResults,
    fetchDiagnosticTestList,
    fetchDiagnosticTestListResults,
  } = useDiagnosticTest();

  const { productId, moduleId, groupId }: DiagnosticTestPagePathParams = useParams();
  const titleSelectedGroup =
    diagnosticTestGroups.find((group) => group.id === +groupId)?.title || "";
  useEffect(() => {
    if (groupId) {
      fetchDiagnosticTestList(+productId, +moduleId, +groupId);
      fetchDiagnosticTestListResults(+productId, +moduleId, +groupId);
    }
  }, [groupId]);

  return (
    <StyledDiagnosticTestListPage>
      <DiagnosticTestBreadcrumbs />
      <DiagnosticTestContent title={titleSelectedGroup}>
        {diagnosticTestList.map((test: DiagnosticTest) => (
          <DiagnosticTestListItem
            key={test.id}
            item={test}
            status={
              diagnosticTestListResults.find((result) => result.id === test.id)?.status || "default"
            }
            testId={test.id}
            title={test.title}
          />
        ))}
      </DiagnosticTestContent>
    </StyledDiagnosticTestListPage>
  );
});
