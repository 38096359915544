import { ROLES } from "@infinitaslearning/module-he-common";
import { Formik } from "formik";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { modalIds } from "constants/modals-constants";

import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Heading3 } from "styles/elements/Headings";

import { useAdmin } from "hooks/useAdmin";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useModal } from "hooks/useModal";
import { useUserForm } from "hooks/useUserForm";
import type { AccountDetailsResponse } from "models/admin/Admin";

import { buildUpdateFormSchema } from "./UserUpdateForm.schema";

import { AddProductModal } from "../../../../components/AddProductModal/AddProductModal";
import { EducationItem } from "../../../../components/EducationItem/EducationItem";
import { ProductItem } from "../../../../components/ProductItem/ProductItem";
import { UpdateProductModal } from "../../../../components/UpdateProductModal/UpdateProductModal";

import {
  StyledBasicInfo,
  StyledButton,
  StyledField,
  StyledInput,
  StyledLoadingWrapper,
  StyledSelect,
  StyledToggleSwitch,
} from "./UserUpdateForm.styled";

interface PathParams {
  userId: string;
}

export const UserUpdateForm: React.FC = observer(() => {
  const { t } = useTranslation("admin-environment");
  const { accountDetails, updateAccountDetails, fetchAccountDetails, setAccountDetails } =
    useAdmin();
  const { toggleModal } = useModal();
  const { userId }: PathParams = useParams();
  const { getEducations, getProductTitle, schools } = useUserForm();
  const { isBusinessDomain } = useDomainHandler();

  useEffect(() => {
    fetchAccountDetails(userId);
    return () => setAccountDetails({} as AccountDetailsResponse);
  }, [userId, fetchAccountDetails]);

  if (Object.keys(accountDetails).length > 0) {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          id: accountDetails.id,
          firstName: accountDetails.firstName,
          middleName: accountDetails.middleName,
          lastName: accountDetails.lastName,
          email: accountDetails.email || "",
          products: accountDetails.products,
          studentNumber: accountDetails.studentNumber,
          school: accountDetails.schools[0]?.id || "",
          educations: accountDetails.schools[0]?.educations.map((education) => education.id) || [
            "",
          ],
          allowedInviteProducts: accountDetails.allowedInviteProducts,
          canSetStudentTargetLevel: accountDetails.canSetStudentTargetLevel,
        }}
        validationSchema={buildUpdateFormSchema()}
        onSubmit={(values, { setSubmitting }) => {
          const commonPayload = {
            id: values.id,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            email: values.email,
            educations: values.educations,
            products: values.products,
            studentNumber: values.studentNumber,
          };
          if (accountDetails.role === ROLES.STUDENT) {
            updateAccountDetails(
              { ...commonPayload, studentNumber: values.studentNumber },
              setSubmitting,
            );
          } else {
            updateAccountDetails(
              {
                ...commonPayload,
                allowedInviteProducts: values.allowedInviteProducts,
                canSetStudentTargetLevel: values.canSetStudentTargetLevel,
              },
              setSubmitting,
            );
          }
        }}
      >
        {(formik) => (
          <form noValidate onSubmit={formik.handleSubmit}>
            <StyledBasicInfo>
              <StyledField>
                <label htmlFor="user-admin-edit-form-first-name-input">
                  {t("accountDetails.firstName.label", "First Name")}
                </label>
                <InputWithErrorMessage
                  dataCy="account-first-name"
                  errorMessage={formik.errors.firstName}
                  hasError={!!(formik.errors.firstName && formik.touched.firstName)}
                  id="user-admin-edit-form-first-name-input"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                />
              </StyledField>
              <StyledField>
                <label htmlFor="user-admin-edit-form-middle-name-input">
                  {t("accountDetails.middleName.label", "Middle Name")}
                </label>
                <InputWithErrorMessage
                  dataCy="account-middle-name"
                  errorMessage={formik.errors.middleName}
                  hasError={!!(formik.errors.middleName && formik.touched.middleName)}
                  id="user-admin-edit-form-middle-name-input"
                  name="middleName"
                  value={formik.values.middleName}
                  onChange={formik.handleChange}
                />
              </StyledField>
              <StyledField>
                <label htmlFor="user-admin-edit-form-last-name-input">
                  {t("accountDetails.lastName.label", "Last Name")}
                </label>
                <InputWithErrorMessage
                  dataCy="account-last-name"
                  errorMessage={formik.errors.lastName}
                  hasError={!!(formik.errors.lastName && formik.touched.lastName)}
                  id="user-admin-edit-form-last-name-input"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
              </StyledField>
            </StyledBasicInfo>
            <StyledField>
              <label htmlFor="user-admin-edit-form-email-input">
                {t("accountDetails.email.label", "Email Address")}
              </label>
              <StyledInput
                dataCy="account-email"
                errorMessage={formik.errors.email}
                hasError={!!(formik.errors.email && formik.touched.email)}
                id="user-admin-edit-form-email-input"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <p>
                {t("accountDetails.passwordChange.message", "To edit a password, go to Muneris")}
              </p>
            </StyledField>
            {accountDetails.role === ROLES.STUDENT && !isBusinessDomain() && (
              <StyledField>
                <label htmlFor="user-admin-edit-form-student-number-input">
                  {t("accountDetails.studentNumber.label", "Student Number")}
                </label>
                <StyledInput
                  dataCy="account-studentNumber"
                  errorMessage={formik.errors.studentNumber}
                  hasError={!!(formik.errors.studentNumber && formik.touched.studentNumber)}
                  id="user-admin-edit-form-student-number-input"
                  name="studentNumber"
                  value={formik.values.studentNumber}
                  onChange={formik.handleChange}
                />
              </StyledField>
            )}
            <StyledField>
              <label htmlFor="user-admin-edit-form-school-selector">
                {t("accountDetails.school.label", "School")}
              </label>
              <StyledSelect
                ariaLabel="account-school"
                dataCy="account-school"
                id="user-admin-edit-form-school-selector"
                name="school"
                options={schools}
                value={formik.values.school}
                onChange={(event) => {
                  formik.setFieldValue("school", event.target.value);
                  formik.setFieldValue("educations", [""]);
                }}
              />
            </StyledField>
            <StyledField>
              <label htmlFor="user-admin-edit-form-education-selector">
                {t("accountDetails.education.label", "Education")}
              </label>
              {formik.values.educations.map((education, index) => (
                <EducationItem
                  key={education}
                  ariaLabel={t("accountDetails.education.label", "Education")}
                  disabled={!formik.values.school}
                  education={education}
                  educationOptions={getEducations(
                    formik.values.school,
                    formik.values.educations,
                    education,
                  )}
                  errorMessage={t("admin-environment:accountDetails.educations.errors.required")}
                  hasError={!!formik.errors.educations}
                  id={`user-admin-edit-form-education-selector-${index}`}
                  onDeleteEducation={() => {
                    const { values, setFieldValue } = formik;
                    if (values.educations.length > 1) {
                      setFieldValue(
                        "educations",
                        values.educations.filter(
                          (_value, educationIndex) => educationIndex !== index,
                        ),
                      );
                    } else {
                      setFieldValue("educations", [""]);
                    }
                  }}
                  onUpdateEducation={(updatedEducation) => {
                    const { values, setFieldValue } = formik;
                    const { educations } = values;
                    educations[index] = updatedEducation;
                    setFieldValue("educations", educations);
                  }}
                />
              ))}
              {accountDetails.role === ROLES.TEACHER && (
                <StyledButton
                  dataCy="add-education"
                  disabled={
                    formik.values.educations.length + 1 ===
                      getEducations(formik.values.school).length ||
                    !formik.values.school ||
                    formik.values.educations.some((education) => !education)
                  }
                  variant="default"
                  onClick={(event) => {
                    const { setFieldValue, values } = formik;
                    event.preventDefault();
                    setFieldValue("educations", [...values.educations, ""]);
                  }}
                >
                  {t("accountDetails.button.addEducation", "+ Add education")}
                </StyledButton>
              )}
            </StyledField>
            <Heading3>{t("accountDetails.products.header", "Products")}</Heading3>
            {formik.values.products?.map((product) => (
              <ProductItem
                key={product.id}
                product={{
                  id: product.id,
                  title: getProductTitle(product.id) || "",
                  type: product.type,
                  validSinceDate: product.validSinceDate ? product.validSinceDate : null,
                  validUntilDate: product.validUntilDate ? product.validUntilDate : null,
                }}
                onDeleteProduct={(productId) => {
                  const { values, setFieldValue } = formik;
                  setFieldValue(
                    "products",
                    values.products?.filter((selectedProduct) => selectedProduct.id !== productId),
                  );
                }}
              />
            ))}
            <StyledField>
              <StyledButton
                dataCy="add-account-product-button"
                variant="default"
                onClick={(event) => {
                  event.preventDefault();
                  toggleModal(modalIds.addAccountProductModal);
                }}
              >
                {t("accountDetails.button.addProduct", "+ Add product")}
              </StyledButton>
            </StyledField>
            {accountDetails.role === ROLES.TEACHER && (
              <>
                <Heading3>{t("accountDetails.teacherRights.header", "Teacher rights:")}</Heading3>
                <StyledToggleSwitch
                  dataCy="toggle-teacher-invite-student-permission"
                  isActive={formik.values.allowedInviteProducts || false}
                  labelSubtitle={t(
                    "accountDetails.teacherRights.toggle.canInviteStudents",
                    "Teacher can invite students",
                  )}
                  name="allowedInviteProducts"
                  variant="primary"
                  onChange={formik.handleChange}
                />
                <StyledToggleSwitch
                  dataCy="toggle-teacher-set-student-level-permission"
                  isActive={formik.values.canSetStudentTargetLevel || false}
                  labelSubtitle={t(
                    "accountDetails.teacherRights.toggle.canSetStudentTargetLevel",
                    "Teacher can set goal level of student",
                  )}
                  name="canSetStudentTargetLevel"
                  variant="primary"
                  onChange={formik.handleChange}
                />
              </>
            )}
            <StyledButton
              dataCy="save-account-button"
              disabled={!!Object.keys(formik.errors).length || formik.isSubmitting}
              type="submit"
              variant="primary"
            >
              {t("accountDetails.button.save", "Save account")}
            </StyledButton>

            <StyledButton
              dataCy="clear-account-button"
              variant="secondary"
              onClick={(event) => {
                event.preventDefault();
                formik.resetForm();
              }}
            >
              {t("accountDetails.button.reset", "Reset form")}
            </StyledButton>

            <UpdateProductModal
              onChangeValidUntilDate={(updatedProduct) => {
                const { values, setFieldValue } = formik;
                const selectedProductIndex = values.products?.findIndex(
                  (product) => product.id === updatedProduct.id,
                );
                values.products[selectedProductIndex] = {
                  ...values.products[selectedProductIndex],
                  ...updatedProduct,
                };
                setFieldValue("products", values.products);
              }}
            />

            <AddProductModal
              addedProducts={formik.values.products}
              onAddNewProduct={(newProduct) => {
                const { values, setFieldValue } = formik;
                setFieldValue("products", [...values.products, newProduct]);
              }}
            />
          </form>
        )}
      </Formik>
    );
  }

  return (
    <StyledLoadingWrapper>
      <LoadingSpinner />
    </StyledLoadingWrapper>
  );
});
