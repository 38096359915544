import styled from "styled-components/macro";

import { Button } from "components/Button";
import { Select } from "components/Select";
import type { ThemeProps } from "models/styles/theme";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledAccountProductModal = styled.div<ThemeProps>`
  background: ${({ theme }: ThemeProps) => theme.palette.neutral._950};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding: ${calculateRem(32)};
  width: ${calculateRem(576)};
`;

export const StyledButton = styled(Button)`
  margin: auto;
  width: ${calculateRem(240)};
`;

export const StyledInformationLabel = styled.div`
  margin-bottom: ${calculateRem(5)};
`;

export const StyledInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${calculateRem(10)} 0;
`;

export const StyledInformation = styled.div`
  border: 2px solid ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  padding: ${calculateRem(12)} ${calculateRem(16)};
  width: ${calculateRem(228)};
`;

export const StyledSelect = styled(Select)`
  background-color: transparent;
`;
