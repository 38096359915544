import { SeparatorLineText } from "components/SeparatorLineText/SeparatorLineText";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

export const Separator: FC = () => {
  const { t } = useTranslation("auth-pages");

  return (
    <SeparatorLineText
      direction="vertical"
      height={22}
      text={t("invitationPage.separator")}
      verticalOffset={2}
      width={1}
    />
  );
};
