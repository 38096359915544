import type React from "react";

import { StyledContainer, StyledIcon, StyledMessage, StyledTitle } from "./InfoMessage.styled";

interface InfoMessageProps {
  dataCy?: string;
  icon?: string;
  children: React.ReactNode;
  className?: string;
  title?: string;
}

export const InfoMessage: React.FC<InfoMessageProps> = ({
  icon = "fa fa-info",
  className,
  children,
  dataCy,
  title,
}) => (
  <StyledContainer className={className} data-cy={dataCy}>
    <StyledIcon iconName={icon} />
    {title && <StyledTitle>{title}</StyledTitle>}
    <StyledMessage>{children}</StyledMessage>
  </StyledContainer>
);
