import { Modal } from "components/Modal";
import styled from "styled-components/macro";

export const StyledModal = styled(Modal)`
  > div {
    padding: 1rem;
    text-align: center;
    width: 55%;
    margin: auto;

    > div {
      width: 70%;
      > ul {
        text-align: left;
        list-style-type: none;
        margin-left: 1rem;
        overflow-y: auto;
        max-height: 25rem;
      }
    }
  }
`;
