import { StyledLabel } from "components/Checkbox/Checkbox.styled";
import styled from "styled-components/macro";

export const Content = styled.div`
  max-width: 50rem;
`;

export const OptionsRow = styled.div`
  display: flex;
  margin: 0.5rem 0;
  ${StyledLabel} {
    margin: 0.75rem 0.75rem 0.75rem 0;
  }
`;

export const OptionLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: 50px;
`;
