import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { LoadingSpinner } from "components/LoadingSpinner";
import { QuestionStatus, TestType } from "constants/exam-constants";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";
import { useAnswer } from "hooks/useAnswer";
import { useDiagnosticTest } from "hooks/useDiagnosticTest";
import { useProduct } from "hooks/useProduct";
import { EmptyExamQuestionsMessage } from "layouts/EmptyExamQuestionsMessage/EmptyExamQuestionsMessage";
import { FixedTestHeader } from "layouts/FixedTestHeader";
import { HstContentPlayer } from "layouts/HstContentPlayer/HstContentPlayer";
import { QuestionManager } from "layouts/QuestionManager";
import type { DiagnosticTestPagePathParams } from "models/path/path-params";
import { Box } from "styles/helpers/layout";
import { CompletedQuestions } from "../components/CompletedQuestions/CompletedQuestions";
import { StyledDiagnosticTestExamPage } from "./DiagnosticTestExamPage.styled";

export const DiagnosticTestExamPage: React.FC = observer(() => {
  const { selectedQuestionIndex, setSelectedQuestionIndex, diagnosticTest, fetchDiagnosticTest } =
    useDiagnosticTest();
  const { questionStatus, evaluateAnswer } = useAnswer();
  const history = useHistory();
  const { t } = useTranslation("diagnostic-test");
  const { productId, exerciseId, moduleId, groupId, testId }: DiagnosticTestPagePathParams =
    useParams();
  const { topicDetails, fetchTopicDetails, nonOwnedProductError } = useProduct();
  const [previousTopicId, setPreviousTopicId] = useState<number>(-1);

  useEffect(() => {
    if (productId && exerciseId) {
      fetchDiagnosticTest(+productId, +exerciseId);
    }
  }, [productId, exerciseId]);

  useEffect(() => {
    const currentTopicForQuestion: number | null | undefined =
      diagnosticTest?.questions[selectedQuestionIndex]?.topicId;
    if (!!productId && !!currentTopicForQuestion && previousTopicId !== currentTopicForQuestion) {
      setPreviousTopicId(currentTopicForQuestion);
      fetchTopicDetails(+productId, +currentTopicForQuestion);
    }
  }, [productId, selectedQuestionIndex, diagnosticTest?.questions]);

  if (!Number(productId) || nonOwnedProductError) {
    return <Redirect to={PRODUCT_PAGES_ROUTES.PRODUCT_LIST} />;
  }

  if (!diagnosticTest) return <LoadingSpinner isFullScreen />;

  const loadNextQuestion = () => {
    setSelectedQuestionIndex(selectedQuestionIndex + 1);
  };

  const onClose = () => {
    history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_DETAILS, {
        productId,
        moduleId,
        groupId,
        testId,
      }),
    );
  };

  const { questions } = diagnosticTest;

  const allQuestionsFinished = selectedQuestionIndex === questions.length;
  const examIsEmpty = questions.length === 0;

  return (
    <StyledDiagnosticTestExamPage>
      <HstContentPlayer
        content={
          <Box margin="auto" width="80%">
            {questions?.[selectedQuestionIndex] && (
              <QuestionManager
                evaluateAnswer={(
                  productId,
                  questionId,
                  answers,
                  exerciseId,
                  skipAnswerValidation,
                ) => {
                  evaluateAnswer({
                    productId,
                    questionId,
                    answers,
                    exerciseId,
                    skipAnswerValidation,
                    testType: TestType.DIAGNOSTIC_TEST,
                    testToken: diagnosticTest?.token ?? "",
                  });
                }}
                flags={[]}
                prevAnswerValues={[]}
                question={questions[selectedQuestionIndex]}
                showTheoryButton={false}
                showTip={questionStatus !== QuestionStatus.INITIAL}
              />
            )}
            {!examIsEmpty && allQuestionsFinished && (
              <CompletedQuestions testType={TestType.DIAGNOSTIC_TEST} />
            )}
            {examIsEmpty && <EmptyExamQuestionsMessage />}
          </Box>
        }
        header={
          <FixedTestHeader
            currentQuestion={questions[selectedQuestionIndex]}
            options={{ showTheory: false }}
            selectedQuestionIndex={selectedQuestionIndex}
            subtitle={t("header.title", "Practice test")}
            title={diagnosticTest.title}
            totalQuestionCount={questions.length}
            onClose={onClose}
          />
        }
        loadNextQuestion={loadNextQuestion}
        selectedQuestion={questions[selectedQuestionIndex]}
        testType={TestType.DIAGNOSTIC_TEST}
        topicDetails={topicDetails}
      />
    </StyledDiagnosticTestExamPage>
  );
});
