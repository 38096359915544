import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { modalIds } from "constants/modals-constants";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useFeatureFlags } from "hooks/useFeatureFlags";

import { Checkbox } from "components/Checkbox/Checkbox";
import { Input } from "components/Input";
import { OptionsDropdown } from "components/OptionsDropdown/OptionsDropdown";
import type { Option } from "components/OptionsDropdown/OptionsDropdown.model";
import { HstProducts } from "constants/hst-constants";
import { useProfile } from "hooks/useProfile";
import { ProductTestGroupType } from "models/product/ProductTestGroups";
import { FormErrorMessage } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/components/FormErrorMessage/FormErrorMessage";
import { useFormUpdate } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/hooks/useFormUpdate";
import type { ScheduledTestRequestPageParams } from "../../ScheduledTestRequestPage.model";
import type { FormStepProps } from "../FormStep.model";
import {
  FormH4Label,
  FormStep,
  FormStepHeader,
  FormStepHeading,
  FormStepIntro,
} from "../FormStep.styled";
import type { TestOptionsStepFormValues } from "./TestOptionsStep.model";
import { Content, OptionLabel, OptionsRow } from "./TestOptionsStep.styled";
import { createValidationSchema } from "./TestOptionsStep.validation";
import { FormSEBListStep } from "./components/FormSEBListStep/FormSEBListStep";
import { FormSchoolyearIntegration } from "./components/FormSYIntegration/FormSYIntegration";
import { TooltipModal } from "./components/TooltipModal/TooltipModal";
import { type ProctoringSelectOption, getDefaultProctoringOptionsState } from "./proctoringOptions";

const DEFAULT_PASSING_SCORE = 80;

interface TestOptionsStepProps {
  isEditingScheduledTest: boolean;
}

export const TestOptionsStep: React.FC<FormStepProps & TestOptionsStepProps> = ({
  formValues,
  formErrors,
  isDisabled = false,
  onFormUpdate,
  isEditingScheduledTest,
}) => {
  const { t } = useTranslation("scheduled-tests");
  const { getStudentTermByDomain } = useDomainHandler();
  const { userDetails, fetchUserDetails } = useProfile();
  const [secureTestOptionSelected, setSecureTestOptionSelected] = useState<{
    syEnabled: boolean;
    sebEnabled: boolean;
  }>({ syEnabled: false, sebEnabled: false });

  useEffect(() => {
    if (!userDetails) {
      fetchUserDetails();
    }
  }, [userDetails, fetchUserDetails]);

  const isCumulativeSettingSupported =
    formValues.productId === HstProducts.DUTCH &&
    formValues.testType === ProductTestGroupType.SUMMATIVE;
  const [values, setValues] = useState<TestOptionsStepFormValues>(
    (({
      password = "",
      passingScore = null,
      cumulative,
      proctoringEnabled = (formValues.proctoring &&
        (formValues.proctoring as string[]).length > 0) ||
        false,
      editableAnswers = true,
      proctoring = [],
      schoolyearEnabled = false,
    }) => ({
      password,
      passingScore,
      cumulative: isEditingScheduledTest
        ? isCumulativeSettingSupported
          ? cumulative
          : false
        : isCumulativeSettingSupported,
      proctoringEnabled,
      editableAnswers,
      proctoring,
      schoolyearEnabled,
    }))(formValues) as TestOptionsStepFormValues,
  );

  const { showCheckboxProctoringTestOptionStep, enableSchoolyearIntegration } = useFeatureFlags();
  const { scheduledTestId }: ScheduledTestRequestPageParams = useParams();

  const [useCustomPassword, setUseCustomPassword] = useState(!!values.password);
  const [useCustomPassingScore, setUseCustomPassingScore] = useState(
    values.passingScore ? values.passingScore !== DEFAULT_PASSING_SCORE : false,
  );
  const [proctoringOptions, setProctoringOptions] = useState<readonly ProctoringSelectOption[]>(
    () => getDefaultProctoringOptionsState(values.proctoring),
  );

  useEffect(() => {
    // update values.proctoring whenever proctoringOptions changes (only a list of enabled proctoring keys)
    setValues((currentState) => ({
      ...currentState,
      proctoring: proctoringOptions.filter((o) => o.isChecked).map((o) => o.value),
    }));
  }, [proctoringOptions]);

  // to be able to use all features of Yup validation, we need to know if password and custom norm
  // checkboxes are checked, so we can change validation rules based on that information
  const validateValues = useMemo(
    () => ({
      ...values,
      useCustomPassword,
      useCustomPassingScore,
    }),
    [values, useCustomPassword, useCustomPassingScore],
  );
  const validationSchema = createValidationSchema();
  useFormUpdate<typeof validationSchema>({
    values: validateValues,
    validationSchema,
    onFormUpdate: (v, e) => {
      // remove useCustomPassword and useCustomPassingScore from the values again
      const { useCustomPassword: a, useCustomPassingScore: b, ...otherValues } = v;
      otherValues.passingScore =
        otherValues.passingScore === null ? DEFAULT_PASSING_SCORE : otherValues.passingScore;
      onFormUpdate(otherValues, e);
    },
  });

  const onToggleProctoringOption = (option: Option) => {
    setProctoringOptions(
      proctoringOptions.map((o) =>
        o.value === option.value ? { ...o, isChecked: !o.isChecked } : o,
      ),
    );
  };

  const onUseCustomPasswordToggle = () => {
    if (useCustomPassword) {
      // will be toggled OFF, so empty
      setValues({ ...values, password: "" });
    }
    setUseCustomPassword(!useCustomPassword);
  };

  const onUseCustomPassingScoreToggle = () => {
    if (useCustomPassingScore) {
      // will be toggled OFF, so set to null
      setValues({ ...values, passingScore: null });
    } else {
      setValues({ ...values, passingScore: DEFAULT_PASSING_SCORE });
    }
    setUseCustomPassingScore(!useCustomPassingScore);
  };

  const toggleValueProp = (propName: string) => () => {
    setValues({ ...values, [propName]: !values[propName] });
  };

  const onCustomPassingGradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value && !Number.isNaN(Number.parseInt(value, 10))) {
      setValues({ ...values, passingScore: Number.parseInt(value, 10) });
    } else {
      setValues({ ...values, passingScore: null });
    }
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, password: event.target.value.trim() });
  };

  const modalContent = {
    customPassingGrade: {
      title: t("testOptionsStep.useCustomPassingGrade.modal.title", "Adjust standardization"),
      paragraph: t(
        "testOptionsStep.useCustomPassingGrade.modal.paragraph",
        "The standard norm for Dutch is at least 80% for the topic verb spelling.",
      ),
    },
    cumulative: {
      title: t("testOptionsStep.cumulative.modal.title", "Cumulative"),
      paragraph: t(
        "testOptionsStep.cumulative.modal.paragraph",
        "With a cumulative calculation, you include all previously obtained test results and the highest result per topic remains.",
      ),
    },
    customPassword: {
      title: t("testOptionsStep.useCustomPassword.modal.title", "Add password"),
      paragraph: t("testOptionsStep.useCustomPassword.modal.paragraph", {
        studentTerm: getStudentTermByDomain({ usePlural: true }),
      }),
    },
    overviewEnabled: {
      title: t("testOptionsStep.overviewEnabled.modal.title", "Edit answers later"),
      paragraph: t("testOptionsStep.overviewEnabled.modal.paragraph", {
        studentTerm: getStudentTermByDomain({ usePlural: true }),
      }),
    },
    proctoringEnabled: {
      title: t("testOptionsStep.proctoringEnabled.modal.title", "Proctoring support"),
      paragraph: t(
        "testOptionsStep.proctoringEnabled.modal.paragraph",
        "May we help you supervise the test remotely?",
      ),
    },
  };

  return (
    <FormStep>
      <FormStepHeader>
        <FormStepHeading>{t("testOptionsStep.header", "Opties - stap 4")}</FormStepHeading>
        <FormStepIntro>{t("testOptionsStep.intro")}</FormStepIntro>
      </FormStepHeader>
      <Content>
        <div>
          <FormH4Label>
            {t("testOptionsStep.options.label", "Select the options you need")}
          </FormH4Label>
          <div>
            <OptionsRow>
              <OptionLabel>
                <Checkbox
                  checked={useCustomPassingScore}
                  dataCy="test-request-custom-passing-grade-checkbox"
                  disabled={isDisabled}
                  id="norm-checkbox"
                  label={t(
                    "testOptionsStep.useCustomPassingGrade.label",
                    "Edit required passing score",
                  )}
                  onChange={onUseCustomPassingScoreToggle}
                />
                <TooltipModal
                  content={modalContent.customPassingGrade}
                  dataCy={modalIds.customPassingGradeModal}
                  modalId={modalIds.customPassingGradeModal}
                />
              </OptionLabel>
              {useCustomPassingScore && (
                <Input
                  aria-label={t(
                    "testOptionsStep.useCustomPassingGrade.label",
                    "Edit required passing score",
                  )}
                  dataCy="test-request-custom-passing-grade-input"
                  disabled={isDisabled}
                  hasError={!!formErrors.passingScore}
                  max="100"
                  min="1"
                  postfixLabel="%"
                  type="number"
                  value={values.passingScore ?? ""}
                  onChange={onCustomPassingGradeChange}
                />
              )}
            </OptionsRow>
            <FormErrorMessage errors={formErrors.passingScore?.errors} />
            {isCumulativeSettingSupported && (
              <OptionsRow>
                <OptionLabel>
                  <Checkbox
                    checked={values.cumulative}
                    dataCy="test-request-cumulative-checkbox"
                    disabled={isDisabled}
                    id="cumulative-checkbox"
                    label={t("testOptionsStep.cumulative.label", "Cumulative")}
                    onChange={toggleValueProp("cumulative")}
                  />
                  <TooltipModal
                    content={modalContent.cumulative}
                    dataCy={modalIds.cumulativeCheckboxModal}
                    modalId={modalIds.cumulativeCheckboxModal}
                  />
                </OptionLabel>
              </OptionsRow>
            )}
            <OptionsRow>
              <OptionLabel>
                <Checkbox
                  checked={useCustomPassword}
                  dataCy="test-request-custom-password-checkbox"
                  disabled={isDisabled}
                  id="password-checkbox"
                  label={t("testOptionsStep.useCustomPassword.label", "Add password")}
                  onChange={onUseCustomPasswordToggle}
                />
                <TooltipModal
                  content={modalContent.customPassword}
                  dataCy={modalIds.customPasswordModal}
                  modalId={modalIds.customPasswordModal}
                />
              </OptionLabel>
              {useCustomPassword && (
                <Input
                  dataCy="test-request-custom-password-input"
                  hasError={!!formErrors.password}
                  placeholder={t("testOptionsStep.passwordInput.placeholder", "password")}
                  value={values.password}
                  onChange={onPasswordChange}
                />
              )}
            </OptionsRow>
            <FormErrorMessage errors={formErrors.password?.errors} />
            <OptionsRow>
              <OptionLabel>
                <Checkbox
                  checked={values.editableAnswers}
                  dataCy="test-request-overview-enabled-checkbox"
                  disabled={isDisabled}
                  id="overview-checkbox"
                  label={t("testOptionsStep.overviewEnabled.label", "Review questions")}
                  onChange={toggleValueProp("editableAnswers")}
                />
                <TooltipModal
                  content={modalContent.overviewEnabled}
                  dataCy={modalIds.overviewEnabledModal}
                  modalId={modalIds.overviewEnabledModal}
                />
              </OptionLabel>
            </OptionsRow>
            {showCheckboxProctoringTestOptionStep && (
              <OptionsRow>
                <OptionLabel>
                  <Checkbox
                    checked={values.proctoringEnabled}
                    dataCy="test-request-proctoring-enabled-checkbox"
                    disabled={isDisabled}
                    id="proctoring-checkbox"
                    label={t("testOptionsStep.proctoringEnabled.label", "Enable proctoring")}
                    onChange={toggleValueProp("proctoringEnabled")}
                  />
                  <TooltipModal
                    content={modalContent.proctoringEnabled}
                    dataCy={modalIds.proctoringEnabledModal}
                    modalId={modalIds.proctoringEnabledModal}
                  />
                </OptionLabel>
                {values.proctoringEnabled && (
                  <OptionsDropdown
                    dataCy="test-request-proctoring-dropdown"
                    disabled={isDisabled}
                    options={proctoringOptions}
                    title={t("testOptionsStep.proctoringSelect.placeholder", "Proctoring settings")}
                    onOptionToggle={onToggleProctoringOption}
                  />
                )}
              </OptionsRow>
            )}
            {userDetails?.canUseSEBIntegration && (
              <FormSEBListStep
                scheduledTestId={scheduledTestId ? +scheduledTestId : null}
                onKeysSelected={(sebKeyIds) => setValues({ ...values, sebKeyIds })}
                enabledSY={secureTestOptionSelected.syEnabled}
                setSecureTestOptionSelected={setSecureTestOptionSelected}
              />
            )}
            {enableSchoolyearIntegration && userDetails?.canUseSchoolyearIntegration && (
              <FormSchoolyearIntegration
                currentValue={values.schoolyearEnabled}
                onChange={(isActive) => setValues({ ...values, schoolyearEnabled: isActive })}
                enabledSEB={secureTestOptionSelected.sebEnabled}
                setSecureTestOptionSelected={setSecureTestOptionSelected}
              />
            )}
          </div>
        </div>
      </Content>
    </FormStep>
  );
};
