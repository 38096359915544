import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

import { Heading2, Heading4 } from "styles/elements/Headings";

import { Modal } from "components/Modal";

export const StyledModal = styled(Modal)<Props>`
  width: 40rem;
  max-width: 90%;
  padding: 2.5rem 2rem;
  background-color: ${({ theme }: Props) => theme.palette.neutral.white};

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.5rem;
`;

export const StyledTitle = styled(Heading2)<Props>`
  padding: 0 2rem;
  text-align: center;
  margin-bottom: 1rem;
`;

export const StyledSubtitle = styled(Heading4)<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral._500};
`;

export const StyledSection = styled.section<Props>`
  width: 100%;

  p {
    color: black;
    text-align: justify;
  }
`;

export const StyledDateRangeForm = styled.div<Props>`
  display: grid;
  column-gap: 1rem;
  row-gap: 0.5rem;
  grid-template-columns: 1fr max-content 1fr;

  grid-template-areas:
    'start-label . end-label'
    'start-datepicker separator end-datepicker';

  margin: 1rem 0;

  > label {
    color: ${({ theme }: Props) => theme.palette.neutral._500};
  }

  > :first-child {
    grid-area: start-label;
  }

  > :nth-child(2) {
    grid-area: start-datepicker;
  }

  > :nth-child(3) {
    grid-area: separator;
    align-self: center;
  }

  > :nth-child(4) {
    grid-area: end-label;
  }

  > :last-child {
    grid-area: end-datepicker;
  }
`;
