import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
  barWidth?: number;
}

export const StyleProgress = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  flex: 1;
  p {
    color: ${(props: Props) => props.theme.palette.neutral.black};
    text-align: center;
    margin-bottom: 0.5rem;
  }
`;

export const StyleProgressBar = styled.div<Props>`
  width: 26rem;
  height: 0.5rem;
  border-radius: 6px;
  background-color: ${(props: Props) => props.theme.palette.neutral._900};
  position: relative;

  span {
    position: absolute;
    height: 100%;
    width: ${(props: Props) => props.barWidth}%;
    border-radius: 6px;
    background-color: ${(props: Props) => props.theme.palette.secondary.brand};
  }
`;
