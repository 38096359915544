import { GENERIC_ROUTES } from "constants/routes";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useProduct } from "hooks/useProduct";
import { observer } from "mobx-react";
import cookieStorage from "persistence";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { StyledLogo } from "../RegistrationPage/RegistrationPage.styled";
import { StyledBody, StyledForm, StyledHeader, StyledInnerBody } from "./InvitationPage.styled";
import { AccountExists } from "./components/AccountExists/AccountExists";
import { CreateAccount } from "./components/CreateAccount/CreateAccount";
import { Separator } from "./components/Separator/Separator";

interface ProductInvitationPathParams {
  invitationId: string;
}

export const InvitationPage: React.FC = observer(() => {
  const { t } = useTranslation("auth-pages");
  const history = useHistory();
  const { isBusinessDomain, getCustomLogoByDomain } = useDomainHandler();

  const { invitationId }: ProductInvitationPathParams = useParams();
  const {
    invitationDetails,
    productInvitationAccepted,
    postAcceptProductInvitation,
    setProductInvitationAccepted,
    fetchInvitationDetails,
  } = useProduct();

  useEffect(() => {
    if (cookieStorage.getToken()) {
      postAcceptProductInvitation(invitationId);
    }
  }, [cookieStorage, postAcceptProductInvitation]);

  useEffect(() => {
    if (productInvitationAccepted) {
      history.push(GENERIC_ROUTES.HOME_PAGE);
    }

    return () => {
      setProductInvitationAccepted(false);
    };
  }, [productInvitationAccepted, setProductInvitationAccepted]);

  useEffect(() => {
    fetchInvitationDetails(invitationId);
  }, []);
  return (
    <>
      <StyledHeader>
        <Link aria-label={t("invitationPage.goToHomePage")} to={GENERIC_ROUTES.HOME_PAGE}>
          <StyledLogo iconName={getCustomLogoByDomain()} />
        </Link>
      </StyledHeader>
      <StyledBody>
        <StyledInnerBody>
          <Heading3>{t("invitationPage.title")}</Heading3>
          <ParagraphLarge>
            {t("invitationPage.description", {
              productNames: invitationDetails?.productNames ?? "",
            })}
          </ParagraphLarge>
          <StyledForm>
            {/* remove accountExists component on business product domain */}
            {!isBusinessDomain() && (
              <>
                <AccountExists />
                <Separator />
              </>
            )}
            <CreateAccount />
          </StyledForm>
        </StyledInnerBody>
      </StyledBody>
    </>
  );
});
