import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { mapProgressToColor } from "color-utils";
import { Button } from "components/Button";
import { Card } from "components/Card/Card";
import { Divider } from "components/Divider/Divider";
import { modalIds } from "constants/modals-constants";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useModal } from "hooks/useModal";
import type { UserProgressPerSubject } from "models/progress/Progress";
import { SubjectDoughnutChart } from "pages/TeacherEnvironment/components/TeacherSection/components/SubjectDoughnutChart/SubjectDoughnutChart";
import { PROGRESS_TYPE } from "pages/TeacherEnvironment/components/TeacherSection/components/SubjectDoughnutChart/SubjectDoughnutChart.model";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components/macro";
import { Heading3, Heading4 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

export interface AccountSubjectProgressProps {
  subjects: UserProgressPerSubject[];
  goalLevel: string;
}

export const AccountSubjectProgress: React.FC<AccountSubjectProgressProps> = ({
  subjects,
  goalLevel,
}) => {
  const theme = useTheme() as ElearningThemeInterface;
  const { t } = useTranslation("teacher-dashboard");
  const { openModal } = useModal();
  const { getStudentTermByDomain } = useDomainHandler();
  const { showGoalLevelSelectorOnTeacherDashboard } = useFeatureFlags();

  const subjectDataForChart = subjects.map((subject) => [
    {
      subjectTitle: subject.title,
      type: PROGRESS_TYPE.COMPLETED,
      value: subject.progress,
      color: mapProgressToColor(subject.progress, theme),
    },
    {
      subjectTitle: subject.title,
      type: PROGRESS_TYPE.UNCOMPLETED,
      value: 100 - subject.progress,
      color: theme.palette.neutral._950,
    },
  ]);

  return (
    <Card layout={{ p: 2 }}>
      <CssFlex alignItems="flex-start" columnGap={1} justifyContent="space-between">
        <section>
          <Heading3 fontWeight="normal">
            {t("basicProgress.forAccount.knowledgePerSubject.title")}
          </Heading3>
          <Heading4 fontWeight="normal" mb="2rem">
            {t("basicProgress.forAccount.knowledgePerSubject.subTitle", {
              goalLevel,
              studentTerm: getStudentTermByDomain(),
            })}
          </Heading4>
        </section>
        {/* If target selection is enabled, show button to allow users to see detailed insights */}
        {showGoalLevelSelectorOnTeacherDashboard && (
          <Button
            dataCy="show-detailed-student-progress-modal-button"
            fontWeight="normal"
            variant="primary-outline"
            onClick={() => {
              trackPlatformEvent({
                module: TEACHER_MODULES.BASIC_PROGRESS,
                itemId:
                  PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES
                    .CLICK_DETAILED_STUDENT_PROGRESS_MODAL,
                elementId: "show-detailed-student-progress-modal-button",
              });
              openModal(modalIds.detailedStudentProgressModal);
            }}
          >
            {t(
              "basicProgress.forAccount.knowledgePerSubject.showModalButtonLabel",
              "Show detailed progress",
            )}
          </Button>
        )}
      </CssFlex>
      <Divider />
      <SubjectDoughnutChart subjectDataForChart={subjectDataForChart} />
    </Card>
  );
};
