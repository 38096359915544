import { Modal } from "components/Modal";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import type React from "react";
import { Heading2 } from "styles/elements/Headings";

import type { ShowPasswordModalProps } from "./ShowPasswordModal.model";
import { StyledPasswordWrapper } from "./ShowPasswordModal.styled";

export const ShowPasswordModal: React.FC<ShowPasswordModalProps> = ({
  headingTitle,
  onCopyPassword,
}) => {
  const { closeModal, isModalOpen, modalParams } = useModal();

  return (
    <Modal
      dataCy="scheduled-test-password-modal"
      isOpen={isModalOpen(modalIds.showPasswordModal)}
      onClose={closeModal}
    >
      <StyledPasswordWrapper>
        <Heading2>{headingTitle}</Heading2>
        <button
          data-class="modal-tooltip"
          data-for="password-copy-tooltip"
          data-tip=""
          type="button"
          onClick={() => onCopyPassword(`${modalParams?.password}`)}
        >
          {modalParams?.password}
        </button>
      </StyledPasswordWrapper>
    </Modal>
  );
};
