import { LoadingSpinner } from "components/LoadingSpinner";
import type React from "react";
import styled from "styled-components/macro";

interface GraphLoadingProps {
  hasLoadingError?: boolean;
  dataCy?: string;
}

const StyledGraphLoading = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GraphLoading: React.FC<GraphLoadingProps> = ({ hasLoadingError = false, dataCy }) => (
  <StyledGraphLoading data-cy={dataCy}>
    {hasLoadingError ? <p>Error loading data for this graph.</p> : <LoadingSpinner />}
  </StyledGraphLoading>
);
