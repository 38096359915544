import { Button } from "components/Button";
import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledHeaderWrapper = styled.div<Props>`
  margin: auto;
  display: flex;
  justify-content: space-between;

  > * {
    :nth-child(2) {
      color: ${({ theme }: Props) => theme.palette.feedback.error.brand};
      display: flex;
      align-items: center;
      margin-right: 3rem;
    }

    :nth-child(3) {
      text-align: right;
      color: ${({ theme }: Props) => theme.palette.neutral._600};
      cursor: pointer;
    }
  }
`;

export const StyledBodyWrapper = styled.div<Props>`
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledSubmitButton = styled(Button)<Props>`
  background: ${({ theme }: Props) => theme.palette.feedback.error.brand};
  color: #ffffff;
  margin-left: 1rem;

  :hover {
    background: ${({ theme }: Props) => theme.palette.feedback.error.brand};
    color: #ffffff;
  }
`;

export const StyledCancelButton = styled(Button)<Props>`
  color: ${({ theme }: Props) => theme.palette.primary.brand};
  background: #ffffff;
`;
