import styled from "styled-components/macro";

import { Button } from "components/Button";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";
import type { ThemeProps } from "models/styles/theme";
import { Heading3, Heading4 } from "styles/elements/Headings";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledBasicInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const StyledPageTitle = styled(Heading3)`
  margin: 1rem 0;
`;

export const StyledForm = styled.form`
  border: 1px solid #E4E4E4;
  margin: 2.75rem 0 4.5rem;
  padding: 2rem 2.75rem;
  width: 100%;
`;

export const StyledButton = styled(Button)<ThemeProps>`
  font-size: ${({ theme }: ThemeProps) => theme.font.size._16};
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  margin-top: 2rem;
  margin-bottom: 2rem;

  &:first-of-type {
    margin-right: ${calculateRem(20)};
  }
`;

export const StyledEducationHeader = styled(Heading4)`
  margin-top: 2rem;
`;

export const StyledField = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  margin-top: 21px;

  label {
    margin-bottom: 5px;
  }

  p {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
    font-size: ${({ theme }: ThemeProps) => theme.font.size._14};
  }

  ${StyledButton} {
    width: fit-content;
  }
`;

export const StyledLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledToggleSwitch = styled(ToggleSwitch)`
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 1rem 0;
`;

export const StyledSchoolyearWrapper = styled(StyledField)`
  margin-top: 1.5rem;
  input {
    max-width: 20rem;
  }
`;
