import { Button } from "components/Button";
import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { modalIds } from "constants/modals-constants";
import { Formik } from "formik";
import { useAdmin } from "hooks/useAdmin";
import { useModal } from "hooks/useModal";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssGrid } from "styles/helpers/layout";
import { StyledField, StyledForm } from "../../UserToolsPage.styled";
import { TransferResultsModal } from "../TransferResultsModal/TransferResultsModal";
import { type Emails, buildTransferResultsSchema } from "./TransferResults.schema";

export const TransferResults: React.FC = observer(() => {
  const [emails, setEmails] = useState<Emails>();
  const { t } = useTranslation("admin-environment");
  const { openModal, closeModal } = useModal();
  const { allParticipatedTestCodes, transferResults, fetchAllParticipatedTestCodes } = useAdmin();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Adding openModal to the deps array causes infinite loop
  useEffect(() => {
    if (allParticipatedTestCodes.length > 0) {
      openModal(modalIds.transferResultsModal);
    }
  }, [allParticipatedTestCodes]);

  return (
    <>
      <TransferResultsModal
        onSubmit={(codes) => {
          codes.map(
            (testCode) =>
              emails?.sourceEmail && emails.targetEmail && transferResults({ ...emails, testCode }),
          );
          closeModal();
        }}
      />
      <Formik
        enableReinitialize
        initialValues={{
          sourceEmail: "",
          targetEmail: "",
          testCode: "",
        }}
        validationSchema={buildTransferResultsSchema()}
        onSubmit={({ sourceEmail, targetEmail, testCode }) => {
          setEmails({ sourceEmail, targetEmail });
          testCode === ""
            ? fetchAllParticipatedTestCodes(sourceEmail)
            : transferResults({ sourceEmail, targetEmail, testCode });
        }}
      >
        {(formik) => (
          <StyledForm
            noValidate
            data-cy="scheduled-test-session-transfer-form"
            onSubmit={formik.handleSubmit}
          >
            <Heading3>{t("adminTools.transferResults.title")}</Heading3>
            <ParagraphLarge>{t("adminTools.transferResults.description")}</ParagraphLarge>

            <CssGrid gap={2} gridTemplateColumns="2fr 1fr 2fr auto">
              <StyledField>
                <label htmlFor="scheduled-test-session-transfer-source-email-input">
                  {t("accountDetails.sourceEmail", "From Email")}
                </label>
                <InputWithErrorMessage
                  dataCy="source-email"
                  errorMessage={formik.errors.sourceEmail}
                  hasError={!!(formik.errors.sourceEmail && formik.touched.sourceEmail)}
                  id="scheduled-test-session-transfer-source-email-input"
                  name="sourceEmail"
                  value={formik.values.sourceEmail}
                  onChange={formik.handleChange}
                />
              </StyledField>

              <StyledField>
                <label htmlFor="scheduled-test-session-transfer-test-code-input">
                  {t("accountDetails.testCode.lable", "Test Code")}
                </label>
                <InputWithErrorMessage
                  dataCy="test-code"
                  errorMessage={formik.errors.testCode}
                  hasError={!!(formik.errors.testCode && formik.touched.testCode)}
                  id="scheduled-test-session-transfer-test-code-input"
                  name="testCode"
                  value={formik.values.testCode}
                  onChange={formik.handleChange}
                />
              </StyledField>

              <StyledField>
                <label htmlFor="scheduled-test-session-transfer-target-email-input">
                  {t("accountDetails.targetEmail", "From Email")}
                </label>
                <InputWithErrorMessage
                  dataCy="target-email"
                  errorMessage={formik.errors.targetEmail}
                  hasError={!!(formik.errors.targetEmail && formik.touched.targetEmail)}
                  id="scheduled-test-session-transfer-target-email-input"
                  name="targetEmail"
                  value={formik.values.targetEmail}
                  onChange={formik.handleChange}
                />
              </StyledField>

              <Button dataCy="submit" type="submit" variant="primary">
                {formik.values.testCode.length > 0
                  ? t("adminTools.transferResults.save")
                  : t("adminTools.transferResults.fetchCodes")}
              </Button>
            </CssGrid>
          </StyledForm>
        )}
      </Formik>
    </>
  );
});
