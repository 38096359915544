import type React from "react";
import { useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";

import { SvgIconReact } from "components/SvgIconReact";
import { StyledLink, StyledText } from "./CertificateNoResultsPage.styled";

interface CertificateNoResultsPageProps {
  noResultsMessage: string;
  buttonText: string;
}

interface PathParams {
  productId: string;
}

export const CertificateNoResultsPage: React.FC<CertificateNoResultsPageProps> = ({
  noResultsMessage,
  buttonText,
}) => {
  const { productId }: PathParams = useParams();

  return (
    <>
      <SvgIconReact dataCy="certificates-no-results-icon" iconName="medalsIcon" size={22} />
      <StyledText data-cy="certificates-no-results-text">{noResultsMessage}</StyledText>
      <StyledLink
        borderVariant="rounded"
        data-cy="certificates-no-results-button"
        fontColor="white"
        to={{
          pathname: !productId
            ? `${PRODUCT_PAGES_ROUTES.PRODUCT_LIST}`
            : `${buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, { productId })}`,
        }}
        variant="button"
      >
        {buttonText}
      </StyledLink>
    </>
  );
};
