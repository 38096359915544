import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledEditWordInput = styled.div<Props>`
  display: inline-block;
`;

export const EditWordInputPlaceholder = styled.div<Props>`
  width: 300px;
`;

export const EditWordInputContent = styled.div<Props>`
  position: absolute;
  margin-top: -32px;

  padding: 8px;
  background: ${(props: Props) => props.theme.palette.neutral.white};

  border: 1px solid ${(props: Props) => props.theme.palette.neutral._900};
  box-sizing: border-box;
  box-shadow: ${(props: Props) => props.theme.palette.shadow._06};
  border-radius: 4px;

  z-index: 1001;
  position: absolute;

  display: flex;
  flex-direction: column;

  input {
    box-sizing: border-box;
    font-size: ${(props: ThemeProps) => props.theme.font.size._16};
    border: 1px solid;
    border-color: ${(props: Props) => props.theme.palette.neutral._500};
    padding: 10px 8px;
  }

  .buttons {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
`;
