import type React from "react";

import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useLanguage } from "hooks/useSelectedLanguage";
import type { TranslationMessage } from "models/featureFlag/FeatureFlagsSet";

import { AccessPageLayout } from "layouts/AccessPageLayout";
import { Heading2 } from "styles/elements/Headings";
import { StyledMessageText } from "./PlatformDownMessagePage.styled";

export const PlatformDownPage: React.FC = () => {
  const { platformDownMessage } = useFeatureFlags();
  const { currentLanguage } = useLanguage();

  const { title, body } = JSON.parse<TranslationMessage>(platformDownMessage)[currentLanguage];

  return (
    <AccessPageLayout>
      <div data-cy="platform-down-message-modal">
        <Heading2>{title}</Heading2>
        <StyledMessageText>
          {body.split("\n").map((line) => (
            <p key={line}>{line}</p>
          ))}
        </StyledMessageText>
      </div>
    </AccessPageLayout>
  );
};
