import { Select } from "components/Select";
import type React from "react";
import type { SelectWithErrorMessageProps } from "./SelectWithErrorMessage.model";
import { StyledErrorMessage, StyledSelectWrapper } from "./SelectWithErrorMessage.styled";

export const SelectWithErrorMessage: React.FC<SelectWithErrorMessageProps> = ({
  dataCy,
  hasError,
  errorMessage,
  ...selectProps
}) => (
  <StyledSelectWrapper>
    <Select dataCy={dataCy} {...selectProps} />
    {hasError && (
      <StyledErrorMessage data-cy={`error-${dataCy}`}>{errorMessage}</StyledErrorMessage>
    )}
  </StyledSelectWrapper>
);
