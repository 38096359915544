import { WordAction } from "models/exam/CorrectTextQuestion";
import { useMemo } from "react";

type UseWordActionsProps = {
  activeWord?: string | null;
  customEnabledWordActions?: WordAction[];
};

export const useWordActions = ({
  activeWord,
  customEnabledWordActions,
}: UseWordActionsProps): WordAction[] =>
  useMemo(() => {
    let availableWordActions = [
      WordAction.EDIT_WORD,
      WordAction.ADD_WORD_AFTER,
      WordAction.MERGE_WORDS,
      WordAction.REMOVE_WORD,
      WordAction.SWAP_WORDS,
    ];
    if (Array.isArray(customEnabledWordActions) && customEnabledWordActions.length > 0) {
      availableWordActions = customEnabledWordActions;
    }
    if (activeWord) {
      // if the word starts or ends with any of the following characters
      // filter out actions that have to do with the next word
      // see: https://regex101.com/r/0KUldX/1
      const punctuations = ",.:;!?()";
      const punctuationRegex = new RegExp(`^[${punctuations}]\\s*|[${punctuations}]\\s*$`, "gm");
      if (punctuationRegex.test(activeWord)) {
        availableWordActions = availableWordActions.filter(
          (wordAction) =>
            ![WordAction.MERGE_WORDS, WordAction.SWAP_WORDS, WordAction.ADD_WORD_AFTER].includes(
              wordAction,
            ),
        );
      }
    }
    return availableWordActions;
  }, [customEnabledWordActions, activeWord]);
