import { useDiagnosticTest } from "hooks/useDiagnosticTest";
import { observer } from "mobx-react";
import type { DiagnosticTestGroup } from "models/exam/DiagnosticTest";
import type { DiagnosticTestPagePathParams } from "models/path/path-params";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DiagnosticTestBreadcrumbs } from "../components/DiagnosticTestBreadcrumbs/DiagnosticTestBreadcrumbs";
import { DiagnosticTestContent } from "../components/DiagnosticTestContent/DiagnosticTestContent";
import { StyledDiagnosticTestGroupsPage } from "./DiagnosticTestGroupsPage.styled";
import { DiagnosticTestGroupItem } from "./components/DiagnosticTestGroupItem/DiagnosticTestGroupItem";

export const DiagnosticTestGroupsPage: React.FC = observer(() => {
  const { fetchDiagnosticTestGroups, diagnosticTestGroups } = useDiagnosticTest();
  const { t } = useTranslation("diagnostic-test");
  const { productId, moduleId }: DiagnosticTestPagePathParams = useParams();

  useEffect(() => {
    if (productId && moduleId) {
      fetchDiagnosticTestGroups(+productId, +moduleId);
    }
  }, [productId, moduleId]);

  return (
    <StyledDiagnosticTestGroupsPage>
      <DiagnosticTestBreadcrumbs />
      <DiagnosticTestContent title={t("diagnosticTestGroups.title", "Diagnostic tests")}>
        {diagnosticTestGroups.map((group: DiagnosticTestGroup) => (
          <DiagnosticTestGroupItem
            key={group.id}
            groupId={group.id}
            item={group}
            title={group.title}
          />
        ))}
      </DiagnosticTestContent>
    </StyledDiagnosticTestGroupsPage>
  );
});
