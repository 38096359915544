import React from "react";
import { useTranslation } from "react-i18next";

import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import type { FinishTestModalProps } from "./FinishTestModal.model";
import {
  StyledButton,
  StyledHeading,
  StyledModal,
  StyledParagraph,
} from "./FinishTestModal.styled";

const MyFinishTestModal: React.FC<FinishTestModalProps> = ({ onConfirm }) => {
  const { t } = useTranslation("test-environment");

  const { closeModal, isModalOpen } = useModal();
  const finishTestModalId = modalIds.finishTest;

  return (
    <StyledModal
      wrapInModalBox
      dataCy={finishTestModalId}
      isOpen={isModalOpen(finishTestModalId)}
      showCloseButton={true}
      onClose={closeModal}
    >
      <StyledHeading>{t("finishTestModal.title", "Are you sure?")}</StyledHeading>
      <StyledParagraph>
        {t(
          "finishTestModal.description",
          "Have you completed all the subjects in the test? The test will be submitted and you will no longer be able to change anything.",
        )}
      </StyledParagraph>
      <div>
        <StyledButton dataCy="finish-test-modal-no-button" variant="secondary" onClick={closeModal}>
          {t("finishTestModal.cancel", "Cancel")}
        </StyledButton>
        <StyledButton dataCy="finish-test-modal-yes-button" variant="primary" onClick={onConfirm}>
          {t("finishTestModal.finishTest", "Yes, I am sure")}
        </StyledButton>
      </div>
    </StyledModal>
  );
};

export const FinishTestModal = React.memo(MyFinishTestModal);
