import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

import { IconComponent } from "./IconComponent";
import type SvgIconReactProps from "./SvgIconReact.model";

interface Props extends SvgIconReactProps {
  theme: ElearningThemeInterface;
}

export const StyledSvgIconReact = styled(IconComponent)<Props>`
  height: ${(props: Props) => props.size}rem;
  width: ${(props: Props) => props.size}rem;
`;
