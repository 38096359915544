//TODO: import { useDomainHandler } from 'hooks/useDomainHandler' and convert this in a hook with shared context among other hooks of this kind;
import { getStudentTermByDomain } from "_helpers/domainHandler";
import { ToastMethods } from "components/ToastNotification";
import type { UsersAPI } from "generated/types";
import i18n from "i18n";
import { action, observable } from "mobx";
import { groupsRepository } from "repositories";
import type { AddedStudentsData, Group } from "../models/groups/Group";

interface GroupsStoreInterface {
  loading: boolean;
  teacherGroups: UsersAPI.GroupListItem[];
  error?: Error | null;
  savingGroup: boolean;
  addingStudents: boolean;
  removingStudents: number[];
  notAddedStudents: string[];
  groupName: string;
  studentsToAdd: string;
  currentGroup: Group | null;
  deletingGroup: number | null;
  getTeacherGroups: (ownedOnly?: boolean) => void;
  getGroup: (groupId: number) => void;
  deleteGroup: () => void;
  setLoading: (isLoading: boolean) => void;
  setTeacherGroups: (groups: UsersAPI.GroupListItem[]) => void;
  setCurrentGroup: (group: Group | null) => void;
  setGroupName: (groupName: string) => void;
  setStudentsToAdd: (studentsText: string) => void;
  editGroupName: (groupId: number) => void;
  setSavingGroup: (isLoading: boolean) => void;
  setAddingStudents: (isLoading: boolean) => void;
  setRemovingStudents: (removingStudentsIds: number[]) => void;
  setDeletingGroup: (groupId: number | null) => void;
  changeGroupName: (evt: React.FormEvent<HTMLInputElement>) => void;
  createGroup: () => Promise<string>;
  resetGroupName: () => void;
  addStudentsToGroup: (groupId: number) => void;
  removeStudentsFromGroup: (groupId: number, studentIds: number[]) => void;
  setNotAddedStudents: (students: string[]) => void;
}
const initialState = {
  loading: true,
  teacherGroups: [] as UsersAPI.GroupListItem[],
  groupName: "",
  studentsToAdd: "",
  currentGroup: null,
  removingStudents: [],
  addingStudents: false,
  savingGroup: false,
  deletingGroup: null,
  notAddedStudents: [],
};

const stateSetters = {
  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),
  setAddingStudents: action((loading: boolean) => {
    store.addingStudents = loading;
  }),
  setRemovingStudents: action((removingStudents: number[]) => {
    store.removingStudents = removingStudents;
  }),
  setTeacherGroups: action((groups: UsersAPI.GroupListItem[]) => {
    store.teacherGroups = groups;
  }),
  changeGroupName: action((evt: React.FormEvent<HTMLInputElement>) => {
    store.setGroupName(evt.currentTarget.value);
  }),
  setGroupName: action((groupName = "") => {
    store.groupName = groupName;
  }),
  resetGroupName: action(() => {
    store.setGroupName("");
  }),
  setSavingGroup: action((saving: boolean) => {
    store.savingGroup = saving;
  }),
  setCurrentGroup: action((group: Group | null) => {
    store.currentGroup = group;
  }),
  setStudentsToAdd: action((studentsText = "") => {
    store.setNotAddedStudents([]);
    store.studentsToAdd = studentsText;
  }),
  setDeletingGroup: action((groupId: number | null) => {
    store.deletingGroup = groupId;
  }),
  setNotAddedStudents: action((notAddedStudents: string[]) => {
    store.notAddedStudents = notAddedStudents;
  }),
};

const apiRequests = {
  // Return list of all groups accessible by the calling teacher
  getTeacherGroups: action((ownedOnly?: boolean) => {
    store.setLoading(true);
    groupsRepository
      .getTeacherGroups(ownedOnly)
      .then((groups: UsersAPI.GroupListItem[]) => {
        store.setTeacherGroups(groups);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:groups.getTeacherGroups.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  // Fetch details for a specific group
  getGroup: action((groupId: number) => {
    store.setLoading(true);
    groupsRepository
      .getGroup(groupId)
      .then((group: Group) => {
        store.setCurrentGroup(group);
        store.setGroupName(group.name);
      })
      .catch(() => {
        store.setCurrentGroup(initialState.currentGroup);
        store.resetGroupName();
        ToastMethods.showToast(i18n.t("toast:groups.getGroup.error"), "error");
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  deleteGroup: action(() => {
    groupsRepository
      .deleteGroup(store.deletingGroup as number)
      .then(() => {
        store.setTeacherGroups(
          store.teacherGroups.filter((group) => group.id !== store.deletingGroup),
        );
        ToastMethods.showToast(i18n.t("toast:groups.deleteGroup.success"), "success");
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:groups.deleteGroup.error"), "error");
      })
      .finally(() => {
        store.setDeletingGroup(null);
      });
  }),
  // biome-ignore lint/suspicious/noConfusingVoidType: This includes a type changes that would propagate to other files
  createGroup: action(async (): Promise<string | void> => {
    store.setSavingGroup(true);
    return groupsRepository
      .createGroup(store.groupName.trim())
      .then((newGroupId) => {
        store.resetGroupName();
        ToastMethods.showToast(i18n.t("toast:groups.createGroup.success"), "success");
        return newGroupId;
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:groups.createGroup.error"), "error");
      })
      .finally(() => {
        store.setSavingGroup(false);
      });
  }),
  editGroupName: action((groupId: number) => {
    store.setSavingGroup(true);
    groupsRepository
      .editGroupName(groupId, store.groupName.trim())
      .then(() => {
        const updatedGroup = {
          ...store.currentGroup,
          name: store.groupName,
        };
        store.setCurrentGroup(updatedGroup as Group);
        ToastMethods.showToast(i18n.t("toast:groups.editGroupName.success"), "success");
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:groups.editGroupName.error"), "error");
      })
      .finally(() => {
        store.setSavingGroup(false);
      });
  }),
  addStudentsToGroup: action((groupId: number) => {
    store.setAddingStudents(true);
    groupsRepository
      .addStudentsToGroup(groupId, store.studentsToAdd.replace(/\s+/g, " ").trim().split(" "))
      .then((data: AddedStudentsData) => {
        store.setStudentsToAdd("");
        store.setNotAddedStudents(data.notAddedStudents);

        if (data.addedStudents.length === 0) {
          return;
        }

        const updatedStudents = [...(store.currentGroup?.students || []), ...data.addedStudents]
          // deduping (i.e. teacher adding same students multiple times)
          .filter(
            (student, idx, self) =>
              self.findIndex((studentInner) => studentInner.id === student.id) === idx,
          );

        const updatedGroup = {
          ...store.currentGroup,
          students: updatedStudents,
        };
        store.setCurrentGroup(updatedGroup as Group);
        ToastMethods.showToast(
          i18n.t("toast:groups.addStudentsToGroup.success", {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          }),
          "success",
        );
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:groups.addStudentsToGroup.error", {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          }),
          "error",
        );
      })
      .finally(() => {
        store.setAddingStudents(false);
      });
  }),
  removeStudentsFromGroup: action((groupId: number, studentsToRemove: number[]) => {
    store.setRemovingStudents(studentsToRemove);
    groupsRepository
      .removeStudentsFromGroup(groupId, studentsToRemove)
      .then(() => {
        const updatedGroup = {
          ...store.currentGroup,
          students: store.currentGroup?.students.filter(
            (student) => !studentsToRemove.includes(student.id),
          ),
        };
        store.setCurrentGroup(updatedGroup as Group);
        ToastMethods.showToast(
          i18n.t("toast:groups.removeStudentsFromGroup.success", {
            studentTerm: getStudentTermByDomain(),
          }),
          "success",
        );
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:groups.removeStudentsFromGroup.error", {
            studentTerm: getStudentTermByDomain(),
          }),
          "error",
        );
      })
      .finally(() => {
        store.setRemovingStudents([]);
      });
  }),
};

const store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as GroupsStoreInterface);

export const useGroups = (): GroupsStoreInterface => store;
