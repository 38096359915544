import type React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "components/SelectUI/Select";
import type { SchoolYear } from "models/product/SchoolYear";
import { Box, CssLabel } from "styles/helpers/layout";

interface SchoolYearSelectorProps {
  schoolYears?: SchoolYear[];
  onChange: (selectedSchoolYear: SchoolYear | null) => void;
}

export const SchoolYearSelector: React.FC<SchoolYearSelectorProps> = ({
  schoolYears = [],
  onChange,
}) => {
  const { t } = useTranslation("teacher-dashboard");

  const [selectedOption, setSelectedOption] = useState("all");

  const schoolYearOptions = useMemo(
    () => [
      {
        value: "all",
        label: t("basicProgress.overview.filters.schoolYear.defaultOption", "All school years"),
      },
      ...schoolYears.map(({ startDate, endDate }, index) => ({
        value: index,
        label: `${new Date(startDate).getFullYear()} / ${new Date(endDate).getFullYear()}`,
      })),
    ],
    [schoolYears],
  );

  return (
    <Box>
      <CssLabel id="school-year-selector-label">{t("selectors.schoolYearSelectorLabel")}</CssLabel>
      <Select
        showSearchBox
        aria-labelledby="school-year-selector-label"
        dataCy="school-year-selector"
        options={schoolYearOptions}
        showPlaceholderInOptionList={false}
        value={selectedOption}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          const selectedValue = event.target.value;
          setSelectedOption(selectedValue);
          onChange(selectedValue !== "all" ? schoolYears[+selectedValue] : null);
        }}
      />
    </Box>
  );
};
