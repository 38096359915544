import styled, { css } from "styled-components/macro";
import { type SpaceProps, type TypographyProps, space, typography } from "styled-system";
import type { Layout } from "styles/elements/ElementsInterface";

export const Heading1Css = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.bold};
  font-size: ${(props: Layout) => props.theme.font.size._40};
  margin: 0;
  line-height: ${(props: Layout) => props.theme.font.lineHeight._130};
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
  letter-spacing: -0.1rem;
`;

export const Heading1 = styled.h1<Layout & SpaceProps & TypographyProps>`
  ${Heading1Css}
  ${space}
  ${typography}
`;

export const Heading2Css = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.bold};
  font-size: ${(props: Layout) => props.theme.font.size._32};
  margin: 0;
  line-height: ${(props: Layout) => props.theme.font.lineHeight._130};
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
  letter-spacing: -0.1rem;
`;

export const Heading2 = styled.h2<Layout & SpaceProps & TypographyProps>`
  ${Heading2Css}
  ${space}
  ${typography}
`;

export const Heading3Css = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.bold};
  font-size: ${(props: Layout) => props.theme.font.size._24};
  margin: 0;
  line-height: ${(props: Layout) => props.theme.font.lineHeight._130};
  letter-spacing: -0.05rem;
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
`;

export const Heading3 = styled.h3<Layout & SpaceProps & TypographyProps>`
  ${Heading3Css}
  ${space}
  ${typography}
`;

export const Heading4Css = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.bold};
  font-size: ${(props: Layout) => props.theme.font.size._16};
  margin: 0;
  line-height: ${(props: Layout) => props.theme.font.lineHeight._150};
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
`;

export const Heading4 = styled.h4<Layout & SpaceProps & TypographyProps>`
  ${Heading4Css}
  ${space}
  ${typography}
`;

export const Heading5Css = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.bold};
  font-size: ${(props: Layout) => props.theme.font.size._14};
  margin: 0;
  line-height: ${(props: Layout) => props.theme.font.size._14};
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
`;

export const Heading5 = styled.h5<Layout & SpaceProps & TypographyProps>`
  ${Heading5Css}
  ${space}
  ${typography}
`;

export const Heading6Css = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.bold};
  font-size: ${(props: Layout) => props.theme.font.size._12};
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
`;

export const Heading6 = styled.h6<Layout & SpaceProps & TypographyProps>`
  ${Heading6Css}
  ${space}
  ${typography}
`;
