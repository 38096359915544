import { getCorrectTestHeader } from "_helpers/tests";
import { Card } from "components/Card/Card";
import { SvgIconReact } from "components/SvgIconReact";
import { format } from "date-fns";
import { useDomainHandler } from "hooks/useDomainHandler";
import type { ScheduledTestDetails } from "models/exam/ScheduledTest";
import type React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Heading3, Heading4 } from "styles/elements/Headings";
import {
  StyledEditReviewPeriodButton,
  StyledExtraOptionsList,
  StyledGridContainer,
  StyledLink,
  StyledProctoringList,
  StyledReviewPeriodHeaderContainer,
  StyledSettingsContainer,
} from "./TestFeatureBox.styled";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import ClickToCopy from "components/ClickToCopy/ClickToCopy";
import { modalIds } from "constants/modals-constants";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useModal } from "hooks/useModal";
import type { SchoolyearDashboard } from "models/schoolyear/Schoolyear";
import { Box } from "styles/helpers/layout";
import { TestMetadata } from "../TestMetadata/TestMetadata";

interface TestFeatureBoxProps {
  scheduledTest: ScheduledTestDetails;
  schoolyearDashboard?: SchoolyearDashboard;
}

export const TestFeatureBox: React.FC<TestFeatureBoxProps> = ({
  scheduledTest,
  schoolyearDashboard,
}) => {
  const { t } = useTranslation("teacher-dashboard");
  const { getStudentTermByDomain } = useDomainHandler();
  const { showPasswordForReviewPeriod } = useFeatureFlags();

  const startDate = new Date(scheduledTest.startDate);

  const { toggleModal } = useModal();

  const testReviewDateHasPassed =
    scheduledTest.reviewEndDate && new Date() > new Date(scheduledTest.reviewEndDate);

  return (
    <Card layout={{ mt: 2, p: 2 }}>
      <Heading3 fontWeight="normal">
        {t("ownedScheduledTestResults.details.testFeatures.title", "Test features")}
      </Heading3>
      <StyledGridContainer>
        <TestMetadata
          iconName="graduationCapIcon"
          label={t(
            "ownedScheduledTestResults.details.testFeatures.metadata.skillLevel.label",
            "Skill level",
          )}
          value={scheduledTest.testLevel}
        />
        <TestMetadata
          iconName="studyIcon"
          label={t(
            "ownedScheduledTestResults.details.testFeatures.metadata.testVersion.label",
            "Version",
          )}
          value={scheduledTest.testVersion}
        />
        <TestMetadata
          iconName="studyIcon"
          label={t(
            "ownedScheduledTestResults.details.testFeatures.metadata.testType.label",
            "Type",
          )}
          value={getCorrectTestHeader(t, scheduledTest.testType)}
        />
        <TestMetadata
          iconName="graduationCapIcon"
          label={t(
            "ownedScheduledTestResults.details.testFeatures.metadata.estimatedStudents.label",
            {
              studentTerm: getStudentTermByDomain({ usePlural: true }),
            },
          )}
          value={scheduledTest.estimatedStudents}
        />
        <TestMetadata
          iconName="calendarIcon"
          label={t(
            "ownedScheduledTestResults.details.testFeatures.metadata.startDate.label",
            "Date",
          )}
          value={format(startDate, "dd MMM yyyy")}
        />
        <TestMetadata
          iconName="clockIcon"
          label={t(
            "ownedScheduledTestResults.details.testFeatures.metadata.startTime.label",
            "Start time",
          )}
          value={format(startDate, "HH:mm")}
        />
        <TestMetadata
          iconName="clockIcon"
          label={t(
            "ownedScheduledTestResults.details.testFeatures.metadata.duration.label",
            "Duration",
          )}
          value={`${scheduledTest.duration} min`}
        />
        <TestMetadata
          iconName="clockIcon"
          label={t(
            "ownedScheduledTestResults.details.testFeatures.metadata.extraTime.label",
            "Extra time",
          )}
          value={`+ ${scheduledTest.extraTime} min`}
        />
      </StyledGridContainer>
      <StyledSettingsContainer>
        <section>
          <Heading4 mb={3}>
            {t(
              "ownedScheduledTestResults.details.testFeatures.extraOptions.title",
              "Chosen extra options",
            )}
          </Heading4>
          <StyledExtraOptionsList>
            <li>
              <SvgIconReact iconName="checkIcon" />
              <span>
                <Trans
                  i18nKey="ownedScheduledTestResults.details.testFeatures.extraOptions.settings.passingScore"
                  t={t}
                >
                  {"Required passing score: "}
                  <b data-cy="required-passing-score">
                    {{ passingScore: scheduledTest.passingScore }}%
                  </b>
                </Trans>
              </span>
            </li>
            {scheduledTest.cumulative && (
              <li>
                <SvgIconReact iconName="checkIcon" />
                <span data-cy="is-cumulative">
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.extraOptions.settings.cumulative",
                    "Cumulative",
                  )}
                </span>
              </li>
            )}
            {scheduledTest.editableAnswers && (
              <li>
                <SvgIconReact iconName="checkIcon" />
                <span data-cy="editable-answers">
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.extraOptions.settings.editableAnswers",
                    "User can edit answers later",
                  )}
                </span>
              </li>
            )}
            {scheduledTest.schoolyearEnabled && schoolyearDashboard && (
              <li>
                <SvgIconReact iconName="checkIcon" />
                <span data-cy="schoolyear-enabled">
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.extraOptions.settings.schoolyearEnabled.label",
                    "Schoolyear browser",
                  )}
                </span>
                <StyledLink
                  external
                  aria-label={t(
                    "ownedScheduledTestResults.details.testFeatures.extraOptions.settings.schoolyearEnabled.link",
                  )}
                  dataCy="test-results-schoolyear-dashboard-button"
                  to={schoolyearDashboard.url}
                  variant="tertiary"
                  size="small"
                  onClick={() => {
                    trackPlatformEvent({
                      module: TEACHER_MODULES.LIVE_MONITORING,
                      itemId: PLATFORM_EVENT_TYPES.SCHOOLYEAR.CLICK_OPEN_DASHBOARD,
                      elementId: "test-results-schoolyear-dashboard-button",
                    });
                  }}
                >
                  <SvgIconReact iconName="externalLinkIcon" size={0.8} />
                  <span>
                    {t(
                      "ownedScheduledTestResults.details.testFeatures.extraOptions.settings.schoolyearEnabled.link",
                      "Open in Schoolyear",
                    )}
                  </span>
                </StyledLink>
              </li>
            )}
          </StyledExtraOptionsList>
        </section>
        {scheduledTest.secureMode && (
          <section data-cy="proctoring-settings">
            <Heading4 mb={3}>
              {t(
                "ownedScheduledTestResults.details.testFeatures.proctoring.title",
                "Proctoring settings",
              )}
            </Heading4>
            <StyledProctoringList>
              {scheduledTest.proctoringSettings.programFocused && (
                <li>
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.proctoring.programFocused",
                    "Program must be on focus",
                  )}
                </li>
              )}
              {scheduledTest.proctoringSettings.online && (
                <li>
                  {t("ownedScheduledTestResults.details.testFeatures.proctoring.online", "Online")}
                </li>
              )}
              {scheduledTest.proctoringSettings.fullScreen && (
                <li>
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.proctoring.fullScreen",
                    "Full screen",
                  )}
                </li>
              )}
              {scheduledTest.proctoringSettings.otherSoftwareClosed && (
                <li>
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.proctoring.otherSoftwareClosed",
                    "Other applications must be closed",
                  )}
                </li>
              )}
              {scheduledTest.proctoringSettings.onlyOneScreen && (
                <li>
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.proctoring.onlyOneScreen",
                    "Max. 1 screen",
                  )}
                </li>
              )}
              {scheduledTest.proctoringSettings.webcam && (
                <li>
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.proctoring.webcam",
                    "Webcam must be enabled",
                  )}
                </li>
              )}
              {scheduledTest.proctoringSettings.faceDetection && (
                <li>
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.proctoring.faceDetection",
                    "Face detection enabled",
                  )}
                </li>
              )}
              {scheduledTest.proctoringSettings.screenshots && (
                <li>
                  {t(
                    "ownedScheduledTestResults.details.testFeatures.proctoring.screenshots",
                    "Screen observation",
                  )}
                </li>
              )}
            </StyledProctoringList>
          </section>
        )}
      </StyledSettingsContainer>
      {scheduledTest.reviewStartDate && scheduledTest.reviewEndDate && (
        <Box mt={4}>
          <StyledReviewPeriodHeaderContainer>
            <Heading4 mb={3}>
              {t(
                "ownedScheduledTestResults.details.testFeatures.reviewPeriod.title",
                "Review period",
              )}
            </Heading4>
            <StyledEditReviewPeriodButton
              dataCy="edit-review-period-button"
              fontColor="primary"
              size="medium"
              variant="text"
              onClick={() => toggleModal(modalIds.editReviewPeriodModal)}
            >
              <SvgIconReact iconName="pencilIcon" />{" "}
              {testReviewDateHasPassed
                ? t(
                    "ownedScheduledTestResults.details.reviewPeriodModal.buttons.addTimePeriod.label",
                  )
                : t(
                    "ownedScheduledTestResults.details.reviewPeriodModal.buttons.editTimePeriod.label",
                  )}
            </StyledEditReviewPeriodButton>
          </StyledReviewPeriodHeaderContainer>
          <StyledGridContainer>
            <TestMetadata
              dataCy="review-start-time"
              iconName="calendarIcon"
              label={t(
                "ownedScheduledTestResults.details.testFeatures.reviewPeriod.reviewStartTime",
                "Review start time",
              )}
              value={format(new Date(scheduledTest.reviewStartDate), "dd MMM yyyy HH:mm")}
            />
            <TestMetadata
              dataCy="review-end-time"
              iconName="calendarIcon"
              label={t(
                "ownedScheduledTestResults.details.testFeatures.reviewPeriod.reviewEndTime",
                "Review end time",
              )}
              value={format(new Date(scheduledTest.reviewEndDate), "dd MMM yyyy HH:mm")}
            />
            {showPasswordForReviewPeriod && scheduledTest.reviewPassword && (
              <TestMetadata
                dataCy="review-password"
                iconName="lockIcon"
                label={t(
                  "ownedScheduledTestResults.details.testFeatures.reviewPeriod.password",
                  "Password",
                )}
                value={<ClickToCopy text={scheduledTest.reviewPassword} />}
              />
            )}
          </StyledGridContainer>
        </Box>
      )}
    </Card>
  );
};
