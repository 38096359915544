import type { Location } from "history";
import type React from "react";
import {
  Redirect,
  Route,
  type RouteComponentProps,
  type RouteProps,
  useLocation,
} from "react-router-dom";

export interface AppRouteProps extends RouteProps {
  token?: string;
  publicRoute?: boolean;
  redirectPath?: string;
  component: React.FC<RouteComponentProps>;
}

interface Options {
  pathname: string;
  search?: string;
}

const redirect = (path: string, location: Location) => {
  let options: Options = { pathname: path };
  if (location) {
    options = {
      ...options,
      search: location.pathname !== "/" ? `?state=${location.pathname}` : location.search,
    };
  }
  return <Redirect to={options} />;
};

const AppRoute: React.FC<AppRouteProps> = ({
  component: Component,
  redirectPath = "",
  token,
  publicRoute,
  ...rest
}) => {
  const location = useLocation();
  if (publicRoute) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return (
    <Route
      {...rest}
      render={(props) => (!token ? redirect(redirectPath, location) : <Component {...props} />)}
    />
  );
};

export default AppRoute;
