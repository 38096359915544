import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import type { ThemeProps } from "models/styles/theme";
import { Heading3 } from "styles/elements/Headings";

export const StyledContainer = styled.section<Props>`
  padding: 5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = styled(FontAwesomeIcon)<Props>`
  margin-bottom: 1.5rem;
  font-size: 10rem;
  color: ${(props: Props) => props.theme.palette.secondary._950};
`;

export const StyledTitle = styled(Heading3)<Props>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  font-weight: bold;
  margin: 0;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  color: ${(props: Props) => props.theme.palette.secondary.brand};
`;

export const StyledMessage = styled.article<Props>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  text-align: center;
  color: ${(props: Props) => props.theme.palette.neutral._500};
`;
