import type React from "react";

import { CssIcon } from "styles/helpers/layout";

import type InfoTextProps from "./InfoText.model";
import { StyledInfoText } from "./InfoText.styled";

export const InfoText: React.FC<InfoTextProps> = ({ children, dataCy, className }) => (
  <StyledInfoText
    className={className}
    columnGap={1}
    data-cy={dataCy}
    gridTemplateColumns="auto 1fr"
  >
    <CssIcon className="info-icon" iconName="fas fa-info-circle" iconSize="lg" />
    {children}
  </StyledInfoText>
);

export default InfoText;
