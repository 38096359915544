import type {
  FeatureFlagsSet,
  TranslatedString,
  TranslationMessage,
} from "models/featureFlag/FeatureFlagsSet";

// Default values for all feature flags
export const featureFlagDefaultValues: FeatureFlagsSet = {
  showCheckboxProctoringTestOptionStep: false,
  showTeacherNavigationDashboardOverview: false,
  showTeacherSupportNavigation: false,
  studentNavigationPersonalProgress: false,
  studentNavigationResultsWritingExercises: false,
  studentNavigationDiagnosticTest: false,
  showPlatformIsDownMessageModal: false,
  customCurrentHost: "null",
  platformDownMessage: JSON.stringify<TranslationMessage>({
    en: {
      title: "We're great with languages...",
      body: " ...but less so with malfunctions. We'll get our website back online as soon as possible. Come back later?",
    },
    nl: {
      title: "We zijn even uit de lucht",
      body: "Niet voor lang, hoor. We halen onze website zo snel mogelijk weer online. Kom je zo terug?",
    },
  }),
  showPlatformUnderConstructionModal: false,
  showDiagnosticTestProgressOverview: false,
  showAdaptivePracticeFlow: true,
  enableMigrateLegacyAccount: false,
  showSupportUnavailabilityNotice: false,
  showDeleteAccountsTool: false,
  alphaTesterIds: "",
  supportUnavailabilityNoticeMessage: JSON.stringify<TranslatedString>({
    nl: "Let op: 29 juni zijn wij gesloten. Wij zijn deze dag niet bereikbaar voor toetsondersteuning.",
    en: "Please note: We are closed on June 29th. We are not available for test support on this day.",
  }),
  tallyForm: {
    en: "wzMJKq",
    nl: "wzMJKq",
  },
  isBusinessThemeActive: false,
  minimumTheoryContentLength: 0,
  showStudentGoalLevelSelector: false,
  showSupportWidget: false,
  showTeacherPracticeInsightsPage: false,
  showTeacherDiagnosticTestsPage: false,
  showGoalLevelSelectorOnTeacherDashboard: false,
  showSchoolYearFilter: false,
  showStudentProgressOverTimeChart: false,
  showAdaptivePracticeSessionResultsPage: false,
  enableMixedAdaptiveWorkflow: false,
  adaptivePracticeCongratsMessageDuration: 5,
  showPasswordForReviewPeriod: false,
  showImmersiveReader: false,
  showTeacherInstructions: false,
  showTeacherInstructionsFilters: false,
  enableSchoolyearIntegration: false,
};
