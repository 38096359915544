import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { TeacherTypesForInstructions } from "constants/hst-constants";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";

import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useLanguage } from "hooks/useSelectedLanguage";
import { useSelectedProductId } from "hooks/useSelectedProductId";
import { useTeacherInstructions } from "hooks/useTeacherInstructions";

import type { TeacherInstructionsModel } from "models/product/TeacherInstructions";

import { Select } from "components/SelectUI/Select";

import { Heading1, Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { Box, CssFlex, CssLabel } from "styles/helpers/layout";

import noResultsImage from "assets/images/no-results.svg";

import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import {
  StyledDataContainer,
  StyledHeader,
  StyledSortableTable,
} from "./TeacherInstructions.styled";

interface Module {
  moduleId: number;
  moduleTitle: string;
}

export const TeacherInstructions: React.FC = observer(() => {
  const { currentLanguage } = useLanguage();
  const { showTeacherInstructionsFilters } = useFeatureFlags();
  const { t } = useTranslation("teacher-dashboard");

  const [filteredInstructions, setFilteredUInstructions] = useState<TeacherInstructionsModel[]>([]);
  const [selectedOptions, setSelectedOptions] = useState({
    module: "allModules",
    teacherType: "allTeacherTypes",
  });

  const { getTeacherInstructions, teacherInstructions } = useTeacherInstructions();
  const selectedProductId = useSelectedProductId();

  // Endpoint should be called whenever we change the app language or we select a new product
  //  (endpoint returns only instructions available in your language)
  useEffect(() => {
    if (selectedProductId) {
      getTeacherInstructions(selectedProductId);
    }
  }, [selectedProductId, getTeacherInstructions, currentLanguage]);

  useEffect(() => {
    if (teacherInstructions) {
      setFilteredUInstructions(teacherInstructions);
    }
  }, [teacherInstructions]);

  const moduleOptions = useMemo(() => {
    const uniqueModules: Module[] = [];
    const moduleList = filteredInstructions.reduce<Module[]>((acc, { moduleId, moduleTitle }) => {
      const exists = acc.find((module) => module.moduleId === +moduleId);
      if (!exists) {
        acc.push({ moduleId: +moduleId, moduleTitle });
      }
      return acc;
    }, uniqueModules);
    return [
      { value: "allModules", label: t("teacherInstructions.filters.module.defaultOption") },
      ...moduleList.map(({ moduleTitle }) => ({
        value: moduleTitle,
        label: moduleTitle,
      })),
    ];
  }, [filteredInstructions]);

  const teacherTypeOptions = [
    { value: "allTeacherTypes", label: t("teacherInstructions.filters.teacherType.defaultOption") },
    { value: TeacherTypesForInstructions.GENERIC, label: t("teacherInstructions.genericTeacher") },
    {
      value: TeacherTypesForInstructions.LANGUAGE,
      label: t("teacherInstructions.languageTeacher"),
    },
  ];

  const rowData = useMemo(() => {
    const { module, teacherType } = selectedOptions;

    const trackPreviewBtn = (instruction: TeacherInstructionsModel) => {
      // Track event on button click
      trackPlatformEvent({
        module: TEACHER_MODULES.TEACHER_INSTRUCTIONS,
        itemId: PLATFORM_EVENT_TYPES.TEACHER_INSTRUCTIONS.PREVIEW_TEACHER_INSTRUCTIONS_DOCUMENT,
        elementId: `preview-teacher-instructions-${instruction.id}-button`,
        type: "button",
        value: JSON.stringify({
          productId: selectedProductId,
          moduleId: instruction.moduleId,
          targetTeacherType: instruction.targetTeacherType,
          documentUrl: instruction.documentUrl,
          documentName: instruction.title,
        }),
      });
    };

    return teacherInstructions
      .filter(
        (instruction) =>
          (module === "allModules" || instruction.moduleTitle === module) &&
          (teacherType === "allTeacherTypes" || instruction.targetTeacherType === teacherType),
      )
      .map((instruction) => ({
        id: instruction.id,
        cells: [
          {
            id: "teacher-instructions-title",
            dataCy: "teacher-instructions-title",
            content: instruction.title,
          },
          {
            id: "teacher-instructions-module",
            dataCy: "teacher-instructions-module",
            content: instruction.moduleTitle,
          },
          {
            id: "teacher-instructions-teacher-type",
            dataCy: "teacher-instructions-teacher-type",
            content:
              instruction.targetTeacherType === TeacherTypesForInstructions.LANGUAGE
                ? t("teacherInstructions.languageTeacher")
                : t("teacherInstructions.genericTeacher"),
          },
          {
            id: "teacher-instructions-preview",
            dataCy: "teacher-instructions-preview",
            content: (
              <a
                href={instruction.documentUrl}
                target="_blank"
                rel="noreferrer"
                aria-label={t("teacherInstructions.previewBtn")}
              >
                <FontAwesomeIcon
                  iconName="fas fa-eye"
                  onClick={() => trackPreviewBtn(instruction)}
                />
              </a>
            ),
          },
        ],
      }));
  }, [teacherInstructions, selectedOptions]);

  const headerCells = [
    {
      id: "name",
      title: t("teacherInstructions.documentName"),
      size: "auto",
    },
    {
      id: "module",
      title: t("teacherInstructions.module"),
      size: "auto",
    },
    {
      id: "teacherType",
      title: t("teacherInstructions.teacherType"),
      size: "auto",
    },
    {
      id: "preview-button",
      title: "",
      size: "auto",
    },
  ];

  const handleOnChange =
    (type: "module" | "teacherType") => (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedOptions((prev) => ({ ...prev, [type]: event.target.value }));
    };

  return (
    <div>
      <Heading1 marginBottom="1rem">{t("teacherInstructions.title", "Docentinstructies")}</Heading1>
      <ParagraphLarge marginBottom="3rem">{t("teacherInstructions.description")}</ParagraphLarge>
      {showTeacherInstructionsFilters && (
        <CssFlex flexDirection="row" alignItems="end" columnGap={1} margin="1rem 0">
          <Box width="14rem">
            <CssLabel id="teacher-type-selector-label">
              {t("teacherInstructions.filters.teacherType.label")}
            </CssLabel>
            <Select
              aria-labelledby="teacher-type-selector-label"
              dataCy="teacher-type-selector"
              options={teacherTypeOptions}
              value={selectedOptions.teacherType}
              onChange={handleOnChange("teacherType")}
            />
          </Box>
          <Box width="14rem">
            <CssLabel id="module-selector-label">
              {t("teacherInstructions.filters.module.label")}
            </CssLabel>
            <Select
              aria-labelledby="module-selector-label"
              dataCy="module-selector"
              options={moduleOptions}
              value={selectedOptions.module}
              onChange={handleOnChange("module")}
            />
          </Box>
        </CssFlex>
      )}
      {rowData.length > 0 && (
        <StyledDataContainer>
          <StyledHeader>
            <Heading3>{`${t("teacherInstructions.tableTitle")} (${
              teacherInstructions.length
            })`}</Heading3>
          </StyledHeader>

          <StyledSortableTable
            dataCy="teacher-instructions-table"
            headerCells={headerCells}
            rows={rowData}
          />
        </StyledDataContainer>
      )}
      {rowData.length === 0 && (
        <CssFlex
          data-cy="no-results-message"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
          marginTop="3rem"
        >
          <img alt={t("teacherInstructions.noResultsFound")} src={noResultsImage} />
          <ParagraphLarge>{t("teacherInstructions.noResultsFound")}</ParagraphLarge>
        </CssFlex>
      )}
    </div>
  );
});
