import { Formik, type FormikHelpers } from "formik";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { modalIds } from "constants/modals-constants";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useModal } from "hooks/useModal";
import { useQueryParam } from "hooks/useQueryParam";
import { useRedirect } from "hooks/useRedirect";
import { useScheduledTests } from "hooks/useScheduledTests";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";

import { type TestAccessFormFields, buildTestAccessSchema } from "./TestAccessForm.schema";

import { RequestPasswordModal } from "../PasswordModal/PasswordModal";

import {
  StyledButton,
  StyledFieldRow,
  StyledForm,
  StyledInput,
  StyledMessage,
  StyledTitle,
} from "./TestAccessForm.styled";

export const TestAccessForm: React.FC = () => {
  const { t } = useTranslation("test-environment");
  const history = useHistory();
  const { openModal } = useModal();
  const testCode = useQueryParam("code");
  const { redirectToUrl } = useRedirect();
  const { getTeacherTermByDomain } = useDomainHandler();
  const { fetchScheduledTestContent } = useScheduledTests();

  const onFormSubmit = (
    values: TestAccessFormFields,
    actions: FormikHelpers<TestAccessFormFields>,
  ) => {
    fetchScheduledTestContent(values.code, values.password).then(
      ({ accessGranted, requestPassword, isTestFinished, redirectUrl }) => {
        // If user was denied access and url is specified, user will be redirected to it (for Schoolyear tests)
        if (!accessGranted && redirectUrl) {
          redirectToUrl(redirectUrl);
          return;
        }

        if (requestPassword) {
          actions.setSubmitting(false);
          openModal(modalIds.passwordRequiredModal);
        } else {
          actions.resetForm();
          if (accessGranted) {
            const destinationURL = isTestFinished
              ? TEST_ENVIRONMENT_ROUTES.TEST_COMPLETED_PAGE
              : TEST_ENVIRONMENT_ROUTES.TEST_DETAILS_PAGE;
            history.push(buildUrlWithPathParams(destinationURL, { code: values.code.trim() }));
          }
        }
      },
    );
  };

  return (
    <Formik
      initialValues={{ code: testCode || "", password: "" } as TestAccessFormFields}
      validationSchema={buildTestAccessSchema()}
      onSubmit={onFormSubmit}
    >
      {(formik) => (
        <StyledForm noValidate onSubmit={formik.handleSubmit}>
          <StyledTitle>{t("testAccessForm.title", "Do you have a test code?")}</StyledTitle>
          <StyledMessage>
            {t("testAccessForm.description", { teacherTerm: getTeacherTermByDomain() })}
          </StyledMessage>
          <StyledFieldRow>
            <StyledInput
              dataCy="test-code-input"
              disabled={formik.isSubmitting}
              hasError={!!(formik.errors.code && formik.touched.code)}
              id="test-code"
              name="code"
              placeholder={t("testAccessForm.fields.code.placeholder", "Enter your test code")}
              value={formik.values.code}
              onChange={formik.handleChange}
            />
            <StyledButton
              dataCy="access-test-button"
              disabled={formik.isSubmitting}
              type="submit"
              variant="primary"
            >
              {t("testAccessForm.buttons.submit.label", "Go to test")}
            </StyledButton>
          </StyledFieldRow>
          <RequestPasswordModal variant="enterTest" />
        </StyledForm>
      )}
    </Formik>
  );
};
