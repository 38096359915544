import * as Yup from "yup";

interface RequiredItem {
  requiredMessage: string;
}

interface InvalidEmail {
  invalidEmailMessage: string;
}

export interface SigninPropsInterface {
  password: RequiredItem;
  username: RequiredItem;
}

export type SigninInterface = {
  password: string;
  username: string;
};

export const signin = ({
  password,
  username,
}: SigninPropsInterface): Yup.SchemaOf<SigninInterface> =>
  Yup.object({
    password: Yup.string().trim().required(password.requiredMessage),
    username: Yup.string().trim().required(username.requiredMessage),
  });

export interface VerifyParamsInterface {
  email: RequiredItem & InvalidEmail;
}

export type VerifyInterface = {
  email: string;
};

export const verify = ({ email }: VerifyParamsInterface): Yup.SchemaOf<VerifyInterface> =>
  Yup.object({
    email: Yup.string().trim().email(email.invalidEmailMessage).required(email.requiredMessage),
  });
