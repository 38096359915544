import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";

import { Heading3 } from "styles/elements/Headings";
import { CssFlex, CssGrid } from "styles/helpers/layout";

export const StyledBox = styled(CssGrid)<Props>`
  height: 100%;
  padding: 1.5rem;
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._900};
  border-radius: ${(props: Props) => props.theme.borderRadius.rounded};

  ${Heading3} {
    margin: 0;
    align-self: center;
  }

  ${CssFlex} {
    align-items: center;
    justify-content: center;
  }
`;
