import type React from "react";
import { useTranslation } from "react-i18next";

import { ModalRenderType } from "components/Modal/Modal.model";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";

import { Modal } from "components/Modal";
import { Heading2 } from "styles/elements/Headings";
import { UpdateScheduledTest } from "../../../UserToolsPage/components/UpdateScheduledTest/UpdateScheduledTest";

export interface ModifyScheduledTestModalProps {
  id: number;
}

export const ModifyScheduledTestModal: React.FC<ModifyScheduledTestModalProps> = ({ id }) => {
  const { isModalOpen, closeModal, modalParams } = useModal();
  const { t } = useTranslation("admin-environment");

  if (!modalParams || modalParams.id !== id) return <></>;

  return (
    <Modal
      closeOnClickOutside
      showCloseButton
      dataCy="modify-scheduled-test-modal"
      isOpen={isModalOpen(modalIds.updateScheduledTest)}
      renderToElementType={ModalRenderType.REACT_PORTAL}
      onClose={closeModal}
    >
      <Heading2 m={3} mb={2}>
        {t("adminTools.scheduledTest.title", { testCode: modalParams.testCode })}
      </Heading2>
      <UpdateScheduledTest
        cumulative={(modalParams.cumulative as boolean) ?? false}
        id={Number(modalParams.id) ?? 0}
        passingScore={Number(modalParams.passingScore) ?? 80}
        reviewEndDate={modalParams.reviewEndDate?.toString() ?? new Date().toISOString()}
        reviewStartDate={modalParams.reviewStartDate?.toString() ?? new Date().toISOString()}
        testType={modalParams.testType?.toString() ?? ""}
      />
    </Modal>
  );
};
