import type { ThemeProps as Props } from "models/styles/theme";
import { FullScreen } from "react-full-screen";
import styled from "styled-components/macro";

export const StyledContainer = styled.div<Props>`
  max-width: 976px;
  min-height: 294px;
  width: 70%;
  margin: 40px auto 0 auto;
  padding-bottom: 40px;
  h2 {
    font-weight: 700;
    text-align: center;
  }
`;

export const StyledFullScreen = styled(FullScreen)<Props>`
  background: ${({ theme }: Props) => theme.palette.neutral.white};
  overflow: auto;
`;

// We need the Immersive Reader to take up the whole viewport, overlaid on top of the fullscreen content
// when active. Otherwise it should not take up any space on the page.
export const StyledFullScreenImmersiveReaderContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0;
  height: 0;
  overflow: hidden;

  &:empty {
    width: 0;
    height: 0;
  }

  &:not(:empty) {
    width: 100vw;
    height: 100vh;
  }
`;
