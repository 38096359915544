export interface Login {
  id: number;
  jwt: string;
  refreshToken: string;
  email: string;
  role: string;
  schoolNumber?: string;
}

export interface RequestAccessRequest {
  username: string;
  password: string;
  schoolDomain: string;
}

export interface RequestAccessResponse {
  code: string | null;
  accountExists: boolean;
  accountValidationCode: string | null;
}

export interface UpdateAccountUserResponse {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
}

export interface ProvideAccessResponse {
  code: string;
  accountExists: boolean;
}

export type ProvideAccessRequest = {
  code: string;
  email: string;
  password: string | null;
};

export interface Education {
  id: string;
  title: string;
  archived?: boolean;
}

export enum SchoolType {
  COMPANY = "company",
  SCHOOL = "school",
}

export interface School {
  id: string;
  title: string;
  type?: SchoolType;
  educations: Education[];
  domain?: string;
}

export interface RegistrationDetails {
  ready: boolean | null;
}

export type RegistrationDetailsResponse = string;
