import { endOfDay } from "date-fns";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { useProduct } from "hooks/useProduct";
import { useScheduledTests } from "hooks/useScheduledTests";
import { useLanguage } from "hooks/useSelectedLanguage";

import { TestType } from "constants/exam-constants";
import type { AvailableLanguage } from "constants/language-constants";
import { DEFAULT_DEBOUNCE_TIME } from "constants/misc-constants";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";

import { DatePicker } from "components/DatePicker/DatePicker";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Select } from "components/SelectUI/Select";

import { Heading2, Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { Box, CssButton, CssGrid, CssIcon, CssLabel } from "styles/helpers/layout";

import type { ProductTestGroupType } from "models/product/ProductTestGroups";
import type { OwnedScheduledTestResultsOverviewFilters } from "./OwnedScheduledTestResultsOverview.model";

import { OwnedTestResultsTable } from "./components/OwnedTestResultsTable/OwnedTestResultsTable";
import { StudentSearchBox } from "./components/StudentSearchBox";

import {
  StyledContentContainer,
  StyledFilterSearchBox,
  StyledPageContainer,
} from "./OwnedScheduledTestResultsOverview.styled";

// TODO: Optionally add tracking to reset button and all filters
export const OwnedScheduledTestResultsOverviewPage: React.FC = observer(() => {
  const { t, i18n } = useTranslation("teacher-dashboard");

  const [searchTerm, setSearchTerm] = useState("");
  const [currentFilters, setCurrentFilters] = useState<OwnedScheduledTestResultsOverviewFilters>(
    {},
  );

  const { currentLanguage } = useLanguage();
  const {
    fetchProducts,
    productsList,
    fetchProductTestGroups,
    productTestGroups,
    setProductTestGroups,
  } = useProduct();
  const {
    loading: isLoading,
    ownedScheduledTestResultsOverview,
    fetchScheduledTestResultsOverview,
  } = useScheduledTests();

  // Define debounced callback method to avoid too many calls to endpoint while user is typing in search box
  const refreshTestResultsData = useDebouncedCallback(
    () => fetchScheduledTestResultsOverview(currentFilters),
    DEFAULT_DEBOUNCE_TIME,
  );

  // Fetch overview of results for all tests matching the selected criteria
  useEffect(() => {
    refreshTestResultsData();
  }, [currentFilters]);

  // Fetch list of products to populate product selector
  useEffect(() => {
    if (!productsList) {
      fetchProducts();
    }
  }, []);

  // Fetch list of availablew test groups only if test type and product are specified
  // Otherwise, automatically clear that filter
  useEffect(() => {
    const { productId, testType } = currentFilters;
    if (productId && testType) {
      fetchProductTestGroups(productId, testType);
    } else {
      setProductTestGroups([]);
      setCurrentFilters((prevFilters) => ({ ...prevFilters, skillLevel: undefined }));
    }
  }, [currentFilters.productId, currentFilters.testType]);

  const today = new Date();

  // Values for test owner selector
  const testOwnerSelectOptions = useMemo(
    () => [
      {
        label: t("ownedScheduledTestResults.overview.filters.testOwner.options.all", "Any"),
        value: "all",
      },
      {
        label: t("ownedScheduledTestResults.overview.filters.testOwner.options.self", "Me"),
        value: "self",
      },
    ],
    [currentLanguage],
  );

  // Values for test type selector
  const testTypeSelectOptions = useMemo(
    () => [
      {
        label: t("ownedScheduledTestResults.overview.filters.testType.options.all", "Any"),
        value: "all",
      },
      {
        label: t(
          "ownedScheduledTestResults.overview.filters.testType.options.summative",
          "Summative test",
        ),
        value: TestType.SUMMATIVE_TEST,
      },
      {
        label: t(
          "ownedScheduledTestResults.overview.filters.testType.options.formative",
          "Formative test",
        ),
        value: TestType.FORMATIVE_TEST,
      },
      {
        label: t(
          "ownedScheduledTestResults.overview.filters.testType.options.freebie",
          "Intake test",
        ),
        value: TestType.FREEBIE_TEST,
      },
    ],
    [currentLanguage],
  );

  // Values for product selector
  const productSelectOptions = useMemo(
    () => [
      {
        label: t("ownedScheduledTestResults.overview.filters.product.options.all", "Any"),
        value: "all",
      },
      ...(productsList?.map(({ id, title }) => ({
        value: id,
        label: title,
      })) || []),
    ],
    [currentLanguage, productsList],
  );

  // Values for test group selector
  const skillLevelSelectOptions = useMemo(
    () => [
      {
        label: t("ownedScheduledTestResults.overview.filters.skillLevel.options.all", "Any"),
        value: "all",
      },
      ...(productTestGroups?.map(({ level, label }) => ({
        value: level,
        label,
      })) || []),
    ],
    [currentLanguage, productTestGroups],
  );

  // Return filters to their initial state
  const resetFilters = () => {
    setSearchTerm("");
    setCurrentFilters({});

    // Track usage of reset filter button
    trackPlatformEvent({
      module: TEACHER_MODULES.SCHEDULED_TESTS,
      itemId: PLATFORM_EVENT_TYPES.SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES.RESET_FILTERS,
      type: "button",
      elementId: "test-results-overview-reset-filters-button",
    });
  };

  return (
    <StyledPageContainer>
      <CssGrid alignItems="end" gridTemplateColumns="1fr auto">
        <Heading2>{t("ownedScheduledTestResults.overview.title", "Test results")}</Heading2>
        <StudentSearchBox />
      </CssGrid>

      {/* -- Filter area -- */}
      <CssGrid gap={1} gridTemplateColumns="repeat(auto-fit, 13rem)" marginY="1rem">
        <Box>
          <ParagraphLarge fontSize="0.75rem">
            {t("ownedScheduledTestResults.overview.filters.testSearchBox.label", "Test name")}
          </ParagraphLarge>
          <StyledFilterSearchBox
            placeholder={t(
              "ownedScheduledTestResults.overview.filters.testSearchBox.placeholder",
              "Search test name",
            )}
            value={searchTerm}
            onChange={(value) => {
              setSearchTerm(value);
              setCurrentFilters((prevSelectedFilters) => ({
                ...prevSelectedFilters,
                searchTerm: value.trim() || undefined,
              }));

              // Track usage of filter by name searchbox
              trackPlatformEvent({
                module: TEACHER_MODULES.SCHEDULED_TESTS,
                itemId:
                  PLATFORM_EVENT_TYPES.SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES.FILTER_BY_NAME,
                type: "input",
                elementId: "test-results-overview-test-name-searchbox",
                value,
              });
            }}
          />
        </Box>
        <Box>
          <CssLabel id="test-type-selector-label">
            {t("ownedScheduledTestResults.overview.filters.testType.label", "Test type")}
          </CssLabel>
          <Select
            aria-labelledby="test-type-selector-label"
            dataCy="test-type-selector"
            options={testTypeSelectOptions}
            showPlaceholderInOptionList={false}
            value={currentFilters.testType || "all"}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              const selectedValue = event.target.value;
              setCurrentFilters((prevSelectedFilters) => ({
                ...prevSelectedFilters,
                testType:
                  selectedValue !== "all" ? (selectedValue as ProductTestGroupType) : undefined,
              }));

              // Track filter usage
              trackPlatformEvent({
                module: TEACHER_MODULES.SCHEDULED_TESTS,
                itemId:
                  PLATFORM_EVENT_TYPES.SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES.FILTER_BY_TYPE,
                type: "select",
                elementId: "test-results-overview-test-type-selector",
                value: selectedValue,
              });
            }}
          />
        </Box>
        <Box>
          <CssLabel id="product-selector-label">
            {t("ownedScheduledTestResults.overview.filters.product.label", "Product")}
          </CssLabel>
          <Select
            aria-labelledby="product-selector-label"
            dataCy="product-selector"
            options={productSelectOptions}
            showPlaceholderInOptionList={false}
            value={currentFilters.productId || "all"}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              const selectedValue = event.target.value;
              setCurrentFilters((prevSelectedFilters) => ({
                ...prevSelectedFilters,
                productId: selectedValue !== "all" ? +selectedValue : undefined,
              }));

              // Track filter usage
              trackPlatformEvent({
                module: TEACHER_MODULES.SCHEDULED_TESTS,
                itemId:
                  PLATFORM_EVENT_TYPES.SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES.FILTER_BY_PRODUCT,
                type: "select",
                elementId: "test-results-overview-product-selector",
                value: selectedValue,
              });
            }}
          />
        </Box>
        <Box>
          <CssLabel id="skill-level-selector-label">
            {t("ownedScheduledTestResults.overview.filters.skillLevel.label", "Skill level")}
          </CssLabel>
          <Select
            aria-labelledby="skill-level-selector-label"
            dataCy="skill-level-selector"
            disabled={!currentFilters.productId || !currentFilters.testType}
            options={skillLevelSelectOptions}
            showPlaceholderInOptionList={false}
            value={currentFilters.skillLevel || "all"}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              const selectedValue = event.target.value;
              setCurrentFilters((prevSelectedFilters) => ({
                ...prevSelectedFilters,
                skillLevel: selectedValue !== "all" ? +selectedValue : undefined,
              }));

              // Track filter usage
              trackPlatformEvent({
                module: TEACHER_MODULES.SCHEDULED_TESTS,
                itemId:
                  PLATFORM_EVENT_TYPES.SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES
                    .FILTER_BY_SKILL_LEVEL,
                type: "select",
                elementId: "test-results-overview-skill-level-selector",
                value: selectedValue,
              });
            }}
          />
        </Box>
        <Box>
          <CssLabel id="test-ownership-selector-label">
            {t("ownedScheduledTestResults.overview.filters.testOwner.label", "Test scheduled by")}
          </CssLabel>
          <Select
            aria-labelledby="test-ownership-selector-label"
            dataCy="test-ownership-selector"
            options={testOwnerSelectOptions}
            showPlaceholderInOptionList={false}
            value={currentFilters.onlyOwnedBySelf ? "self" : "all"}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              const selectedValue = event.target.value;
              setCurrentFilters((prevSelectedFilters) => ({
                ...prevSelectedFilters,
                onlyOwnedBySelf: selectedValue === "self",
              }));

              // Track filter usage
              trackPlatformEvent({
                module: TEACHER_MODULES.SCHEDULED_TESTS,
                itemId:
                  PLATFORM_EVENT_TYPES.SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES.FILTER_BY_OWNER,
                type: "select",
                elementId: "test-results-overview-test-ownership-selector",
                value: selectedValue,
              });
            }}
          />
        </Box>
        <Box>
          <CssLabel>
            {t("ownedScheduledTestResults.overview.filters.minStartDate.label", "From")}
          </CssLabel>
          <DatePicker
            dataCy="min-start-date-picker"
            language={i18n.language as AvailableLanguage}
            maxDate={today}
            minDate={null}
            value={currentFilters.minStartDate || null}
            onChange={(date) => {
              setCurrentFilters((prevSelectedFilters) => ({
                ...prevSelectedFilters,
                minStartDate: date,
              }));

              // Track filter usage
              trackPlatformEvent({
                module: TEACHER_MODULES.SCHEDULED_TESTS,
                itemId:
                  PLATFORM_EVENT_TYPES.SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES
                    .FILTER_BY_START_DATE,
                type: "date-picker",
                elementId: "test-results-overview-min-start-date-picker",
                value: date.toISOString(),
              });
            }}
          />
        </Box>
        <Box>
          <CssLabel>
            {t("ownedScheduledTestResults.overview.filters.maxStartDate.label", "Till")}
          </CssLabel>
          <DatePicker
            dataCy="max-start-date-picker"
            language={i18n.language as AvailableLanguage}
            maxDate={today}
            minDate={null}
            value={currentFilters.maxStartDate || today}
            onChange={(date) => {
              setCurrentFilters((prevSelectedFilters) => ({
                ...prevSelectedFilters,
                maxStartDate: endOfDay(date),
              }));

              // Track filter usage
              trackPlatformEvent({
                module: TEACHER_MODULES.SCHEDULED_TESTS,
                itemId:
                  PLATFORM_EVENT_TYPES.SCHEDULED_TEST_RESULT_OVERVIEW_EVENT_TYPES
                    .FILTER_BY_START_DATE,
                type: "date-picker",
                elementId: "test-results-overview-max-start-date-picker",
                value: date.toISOString(),
              });
            }}
          />
        </Box>
        <CssButton
          dataCy="reset-filters-button"
          fontColor="tertiary"
          marginTop="1rem"
          variant="text"
          onClick={resetFilters}
        >
          <CssIcon iconName="fal fa-undo" />
          {` ${t("ownedScheduledTestResults.overview.filters.resetButton.label", "Reset filters")}`}
        </CssButton>
      </CssGrid>

      {/* -- Result panel -- */}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <StyledContentContainer>
          <Heading3>
            <Trans i18nKey="ownedScheduledTestResults.overview.subtitle" t={t}>
              All tests (
              <span data-cy="test-count">
                {{ testCount: ownedScheduledTestResultsOverview?.length || 0 }}
              </span>
              )
            </Trans>
          </Heading3>

          {/* -- Table with returned test results -- */}
          <OwnedTestResultsTable
            displayOwnershipIcon
            scoreIsAverage
            language={i18n.language as AvailableLanguage}
            ownedScheduledTestResults={ownedScheduledTestResultsOverview || []}
          />
        </StyledContentContainer>
      )}
    </StyledPageContainer>
  );
});
