import classnames from "classnames";
import type React from "react";
import { useContext } from "react";
import { ThemeContext } from "styled-components/macro";

import { LoadingSpinner } from "components/LoadingSpinner";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type { ButtonProps, Variant } from "./Button.model";
import { StyledButton } from "./Button.styled";

const getLoadingSpinnerColor = (theme: ElearningThemeInterface, variant: Variant) => {
  switch (variant) {
    case "primary":
      return theme.palette.neutral.white;
    default:
      return theme.palette.neutral._600;
  }
};

export const Button: React.FC<ButtonProps> = ({
  fontColor,
  fontWeight = "bold",
  children,
  isBlock = false,
  isNarrow = false,
  isLoading = false,
  size = "default",
  variant = "default",
  hasIconLeft = false,
  hasIconRight = false,
  dataCy,
  disabled,
  ...otherProps
}) => {
  const theme = useContext<ElearningThemeInterface>(ThemeContext);
  return (
    <StyledButton
      $fontColor={fontColor}
      $fontWeight={fontWeight}
      $isBlock={isBlock}
      $isLoading={isLoading}
      $isNarrow={isNarrow}
      $size={size}
      $variant={variant}
      className={classnames(hasIconLeft && "icon-left", hasIconRight && "icon-right")}
      data-cy={dataCy}
      disabled={disabled || isLoading}
      {...otherProps}
    >
      <span className="content">{children}</span>
      {isLoading && (
        <span className="spinner">
          <LoadingSpinner color={getLoadingSpinnerColor(theme, variant)} size={24} />
        </span>
      )}
    </StyledButton>
  );
};
