import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { Heading2 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { calculateRem } from "styles/mixins/mixins";

import { Button } from "components/Button";
import { ProductFlag } from "components/ProductFlag/ProductFlag";

export const StyledScheduledTestItem = styled.div<ThemeProps>`
  border: 1px solid ${(props: ThemeProps) => props.theme.palette.neutral._900};
  display: flex;
  flex-direction: column;
  margin-bottom: ${calculateRem(48)};
  padding: 2rem;
  padding-right: 5rem;
  position: relative;
`;

export const StyledProductFlag = styled(ProductFlag)`
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
`;

export const StyledGeneralInformation = styled.div`
  display: flex;
  flex-grow: 1;
  row-gap: 2rem;
  flex-direction: column;
`;

export const StyledInfoRow = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledMetadataContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledTestName = styled(Heading2)`
  text-align: left;
  line-height: 150%;
`;

export const StyledParagraph = styled(ParagraphLarge)`
  text-align: left;
  line-height: 150%;
`;

const StyledMetadata = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  column-gap: ${calculateRem(10)};
  width: ${calculateRem(144)};

  > svg {
    flex-shrink: 0;
  }
`;

export const StyledTestMetadata = styled(StyledMetadata)`
  flex-grow: 1;
  flex-basis: 0;

  > svg {
    padding: 0.5rem 0;
    box-sizing: content-box;
  }
`;

export const StyledTestCode = styled(StyledMetadata)<ThemeProps>`
  width: fit-content;
  min-width: 6rem;
  column-gap: 0;
  display: flex;
  align-items: center;
  color: ${(props: ThemeProps) => props.theme.palette.secondary.brand};

  > svg > path {
    fill: ${(props: ThemeProps) => props.theme.palette.secondary.brand};
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInfoContent = styled(ParagraphLarge)<ThemeProps>`
  font-size: ${({ theme }: ThemeProps) => theme.font.size._14};
  line-height: ${calculateRem(24)};
  margin-bottom: 0;
`;

export const StyledInfoHeader = styled(StyledInfoContent)<ThemeProps>`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._400};
`;

export const StyledButton = styled(Button)`
  display: flex;
  margin-left: auto;
  font-size: ${({ theme }: ThemeProps) => theme.font.size._16};
  width: ${calculateRem(215)};

  svg {
    margin-right: ${calculateRem(10)};
  }
`;
