import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";

import type { RecentProgress } from "models/results/Results";

import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssFlex, CssGrid } from "styles/helpers/layout";

import { GoalLevelBox } from "../GoalLevelBox/GoalLevelBox";
import { ProgressStatBox } from "../ProgressStatBox/ProgressStatBox";

interface RecentProgressProps {
  recentProgress?: RecentProgress | null;
}

export const RecentProgressSummary: React.FC<RecentProgressProps> = observer(
  ({ recentProgress }) => {
    const { t } = useTranslation("homepage");

    return (
      <CssGrid gap={1} gridTemplateColumns="1.5fr 1.5fr">
        <GoalLevelBox />
        <CssGrid data-cy="recent-progress-box" gap={1} gridTemplateColumns="repeat(2, 1fr)">
          <ProgressStatBox
            title={t("subjects.recentProgress.totalAnswered", "Totaal beantwoorde vragen")}
            value={
              <Heading3 data-cy="total-answered-questions">
                {recentProgress?.totalAnswered || 0}
              </Heading3>
            }
          />
          <ProgressStatBox
            title={t(
              "subjects.recentProgress.sessionCorrectAnswers",
              "Goede antwoorden in deze sessie",
            )}
            value={
              <CssFlex flexDirection="row">
                <Heading3 data-cy="correct-answers-in-current-session">
                  {recentProgress?.currentSessionCorrect || 0}
                </Heading3>
                <ParagraphLarge data-cy="total-answers-in-current-session">
                  {`/${recentProgress?.currentSessionQuestions || 0}`}
                </ParagraphLarge>
              </CssFlex>
            }
          />
        </CssGrid>
      </CssGrid>
    );
  },
);
