import { observer } from "mobx-react";
import type React from "react";

import { PRODUCT_PAGES_ROUTES } from "constants/routes";
import { useProduct } from "hooks/useProduct";

import { Breadcrumbs } from "components/Breadcrumbs";

export const TopicDetailsBreadcrumb: React.FC = observer(() => {
  const { topicDetails } = useProduct();

  if (!topicDetails) {
    return null;
  }

  const { module, subject } = topicDetails;

  return (
    <Breadcrumbs
      dataCy="topic-details-page"
      routes={[
        { path: PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, breadcrumb: module.title },
        { path: PRODUCT_PAGES_ROUTES.PRODUCT_WRITING_MODULE_DETAILS, breadcrumb: subject.title },
      ]}
      showAllRoutes={false}
    />
  );
});
