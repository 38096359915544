import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

import { Heading3 } from "styles/elements/Headings";
import { CssClickableIcon, CssGrid } from "styles/helpers/layout";

export const StyledContainer = styled(CssGrid)<ThemeProps>`
  width: 100%;
  padding: 1rem;

  border-radius: 4px;
  border: solid 1px ${(props: ThemeProps) => props.theme.palette.neutral._900};
  background: ${(props: ThemeProps) => props.theme.palette.neutral.white};

  row-gap: 0.5rem;
  column-gap: 1rem;
  grid-template-areas:
    'title close-button'
    'content content';
  grid-template-columns: 1fr auto;
`;

export const StyledTitle = styled(Heading3)<ThemeProps>`
  grid-area: title;
  margin: 0;
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
`;

export const StyledMessage = styled.div<ThemeProps>`
  grid-area: content;
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
`;

export const StyledCloseButton = styled(CssClickableIcon)<ThemeProps>`
  grid-area: close-button;

  :hover {
    transition: color 0.3s;
    color: ${(props: ThemeProps) => props.theme.palette.neutral._600};
  }
`;
