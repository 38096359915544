import type React from "react";
import { useHistory } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TestType } from "constants/exam-constants";
import { PRODUCT_PAGES_ROUTES, TEST_PAGE_ROUTES } from "constants/routes";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import type { ModuleDetails } from "models/product/ModuleDetails";
import { DiagnosticTestCompletedMessage } from "pages/StudentEnvironment/DiagnosticTestExamPage/components/DiagnosticTestCompletedMessage/DiagnosticTestCompletedMessage";
import { SkillTestCompletedMessage } from "pages/StudentEnvironment/SkillDeterminationTestPage/components/SkillTestCompletedMessage/SkillTestCompletedMessage";
import type { ProductInfo } from "pages/StudentEnvironment/SkillDeterminationTestPage/components/SkillTestCompletedMessage/SkillTestCompletedMessage.model";
import { WritingExerciseCompletedMessage } from "pages/StudentEnvironment/WritingExercisePage/components/WritingExerciseCompletedMessage/WritingExerciseCompletedMessage";

interface CompletedQuestionsDetermination {
  productInfo: ProductInfo;
  moduleDetails: ModuleDetails;
}

export interface CompletedQuestionsProps {
  data?: CompletedQuestionsDetermination | never;
  testType: TestType;
}

export const CompletedQuestions: React.FC<CompletedQuestionsProps> = ({ testType, data }) => {
  const history = useHistory();
  const { enableMixedAdaptiveWorkflow } = useFeatureFlags();

  switch (testType) {
    case TestType.SKILL_DETERMINATION_TEST: {
      if (data) {
        const { productInfo, moduleDetails } = data;
        const { module, product, subject } = productInfo;
        return (
          <SkillTestCompletedMessage
            onSeeResults={() =>
              history.push(
                buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
                  productId: product.id,
                  moduleId: moduleDetails.id,
                }),
              )
            }
            onStartPracticing={() =>
              // Depending on whether the mixed flow is enabled, redirect to workflow selection or straight to practice page
              history.push(
                buildUrlWithPathParams(
                  enableMixedAdaptiveWorkflow
                    ? TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_WORKFLOW_SELECT
                    : TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
                  {
                    productId: product.id,
                    moduleId: module.id,
                    subjectId: subject.id,
                  },
                ),
              )
            }
          />
        );
      }
      return null;
    }
    case TestType.WRITING_EXERCISE:
      return <WritingExerciseCompletedMessage />;
    case TestType.DIAGNOSTIC_TEST:
      return <DiagnosticTestCompletedMessage />;
    default:
      return null;
  }
};
