import { Input } from "components/Input";
import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";

export const StyledWrapper = styled.div<Props>`
  margin-top: 2rem;
  margin-left: 1rem;
`;

export const StyledDescription = styled.div<Props>`
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 80%;

  > ${Heading3} {
    font-weight: normal;
    margin-bottom: 0.1rem;
  }
`;

export const StyledInputWrapper = styled.div<Props>`
  margin-top: 2rem;
  width: 40%;
`;

export const StyledInput = styled(Input)`
  border: none;
  padding-left: 0;
  text-align: left;
`;
