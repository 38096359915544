import { ToastMethods } from "components/ToastNotification";
import i18n from "i18n";

// Automatically trigger download of an excel file generated from base64
export const downloadExcelFile = (fileName: string, base64: string): void => {
  const a = document.createElement("a");
  a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
  a.download = fileName;
  a.click();
};

// Automatically trigger download from a file located on a external url
export const downloadFileFromUrl = async (
  fileName: string,
  downloadUrl: string,
): Promise<boolean> => {
  const response = await fetch(downloadUrl);

  if (!response.ok) {
    ToastMethods.showToast(i18n.t("toast:fileDownload.error"), "error");
    return false;
  }

  const blob = await response.blob();

  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  a.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(a.href);
  }, 0);

  return true;
};
