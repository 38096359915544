import { observer } from "mobx-react";
import type React from "react";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { DEFAULT_ROWS_PER_PAGE } from "constants/misc-constants";
import { ADMIN_ENVIRONMENT_ROUTES } from "constants/routes";
import { SchoolType } from "models/auth/Login";

import { EncircledIcon } from "components/EncircledIcon";
import { Link } from "components/Link";
import type { TableRow } from "components/SortableTable/SortableTable.model";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { useAdmin } from "hooks/useAdmin";

import { CssFlex } from "styles/helpers/layout";
import {
  StyledHeader,
  StyledIcon,
  StyledSortableTable,
  StyledTableContainer,
  StyledTableHeader,
} from "./SchoolsTable.styled";

interface SchoolTableProps {
  page: number;
  variant: SchoolType;
}

export const SchoolsTable: React.FC<SchoolTableProps> = observer(({ page, variant }) => {
  const { t } = useTranslation("admin-environment");
  const { schoolList: schools = [] } = useAdmin();

  const startIndex = (page - 1) * DEFAULT_ROWS_PER_PAGE;
  const endIndex = startIndex + DEFAULT_ROWS_PER_PAGE;
  const paginatedItems = schools?.slice(startIndex, endIndex);

  // Note: there is no pagination on this endpoint, so we do client side pagination.
  // The pagination component assumes server-side pagination, so it's a bit awkward to
  // have to grab it from the URL and pass it in as a prop, then slice the data.

  const rowData = useMemo<TableRow[]>(
    () =>
      paginatedItems.map((school) => ({
        id: school.id,
        cells: [
          {
            id: `school-${school.id}-name`,
            data: school.title,
            dataCy: "school-name",
            content: (
              <CssFlex flexDirection="row" gap={1}>
                {school.type === SchoolType.SCHOOL ? (
                  <EncircledIcon iconName="fas fa-graduation-cap" size="medium" variant="primary" />
                ) : (
                  <EncircledIcon iconName="fas fa-briefcase" size="medium" variant="secondary" />
                )}
                {`${school.title}`}
              </CssFlex>
            ),
          },
          {
            id: `school-${school.id}-domain`,
            dataCy: "school-domain",
            data: school.domain,
            content: `${school.domain}`,
          },
          {
            id: `school-${school.id}-actions`,
            content: (
              <Link
                data-cy={`school-${school.id}-details`}
                aria-label={`Edit ${school.title}`}
                to={{
                  pathname: `${buildUrlWithPathParams(
                    variant === SchoolType.SCHOOL
                      ? ADMIN_ENVIRONMENT_ROUTES.SCHOOL_DETAILS
                      : ADMIN_ENVIRONMENT_ROUTES.COMPANY_DETAILS,
                    {
                      schoolId: school.id,
                    },
                  )}`,
                }}
              >
                <StyledIcon iconName="pencilIcon" size={1} />
              </Link>
            ),
          },
        ],
      })),
    [paginatedItems],
  );

  const headerCells = [
    {
      id: "name",
      title: t("schoolTableColumns.name", "Name"),
      size: "1.5fr",
    },
    {
      id: "domain",
      title: t("schoolTableColumns.domain", "Domain"),
      size: "1.5fr",
    },
    {
      id: "actions",
      title: " ",
      size: "auto",
    },
  ];

  return (
    <StyledTableContainer>
      <StyledTableHeader>
        <StyledHeader>
          <Trans
            i18nKey={variant === SchoolType.SCHOOL ? "schoolList.title" : "companyList.title"}
            t={t}
          >
            Schools (<span data-cy="school-count">{{ schoolCount: schools.length }}</span>)
          </Trans>
        </StyledHeader>
      </StyledTableHeader>
      <StyledSortableTable headerCells={headerCells} rows={rowData} />
    </StyledTableContainer>
  );
});
