import { Formik } from "formik";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Heading4 } from "styles/elements/Headings";

import { useAdmin } from "hooks/useAdmin";
import type { SchoolResponse } from "models/admin/Admin";

import { type SchoolUpdateFormFields, buildUpdateFormSchema } from "./SchoolUpdateForm.schema";

import { Button } from "components/Button";
import { EncircledIcon } from "components/EncircledIcon";
import { ADMIN_ENVIRONMENT_ROUTES } from "constants/routes";
import { useCurrentPathMatch } from "hooks/useCurrentPathMatch";
import { SchoolType } from "models/auth/Login";
import { ParagraphMedium } from "styles/elements/Texts";
import { CssGrid } from "styles/helpers/layout";
import {
  StyledBasicInfo,
  StyledButton,
  StyledEducationHeader,
  StyledField,
  StyledForm,
  StyledLoadingWrapper,
  StyledPageTitle,
  StyledSchoolyearWrapper,
  StyledToggleSwitch,
} from "./SchoolUpdateForm.styled";

interface PathParams {
  schoolId: string;
}

export const SchoolUpdateForm: React.FC = observer(() => {
  const { t } = useTranslation("admin-environment");
  const {
    fetchSchoolDetails,
    schoolDetails: school,
    setSchoolDetails,
    createSchool,
    updateSchool,
  } = useAdmin();
  const { schoolId }: PathParams = useParams();
  const history = useHistory();
  const { currentRouteMatches } = useCurrentPathMatch();

  const isSchool =
    school.type === SchoolType.SCHOOL ||
    (schoolId === "new" && currentRouteMatches(ADMIN_ENVIRONMENT_ROUTES.SCHOOL_CREATE));

  useEffect(() => {
    if (schoolId !== "new") {
      fetchSchoolDetails(schoolId);
    }
    return () => setSchoolDetails({} as SchoolResponse);
  }, [schoolId, setSchoolDetails, fetchSchoolDetails]);

  const onFormSubmit = (values: SchoolUpdateFormFields) => {
    const commonPayload = {
      name: values.title,
      domain: values.domain,
      educations: values.educations.map((education) => ({
        id: education.id || undefined,
        name: education.title,
        archived: education.archived,
      })),
      immersiveReaderEnabled: values.immersiveReaderEnabled,
      sebEnabled: values.sebEnabled,
      schoolyearKey: values.schoolyearEnabled ? values.schoolyearKey : null,
      type: school?.type || (isSchool ? SchoolType.SCHOOL : SchoolType.COMPANY),
    };

    // Call different endpoint depending on whether we're creating a new school
    // or updating an existing one
    if (schoolId === "new") {
      return createSchool(commonPayload).then(() => {
        history.push(
          isSchool ? ADMIN_ENVIRONMENT_ROUTES.SCHOOL_LIST : ADMIN_ENVIRONMENT_ROUTES.COMPANY_LIST,
        );
      });
    }

    return updateSchool(school.id, commonPayload);
  };

  if (Object.keys(school).length > 0 || schoolId === "new") {
    return (
      <>
        <StyledPageTitle>
          {isSchool
            ? t("schoolDetails.pageTitleSchool", "School details")
            : t("schoolDetails.pageTitleOrganization", "Organization details")}
        </StyledPageTitle>
        <ParagraphMedium>
          {isSchool
            ? t("schoolDetails.pageDescriptionSchool", "Edit school details")
            : t("schoolDetails.pageDescriptionOrganization", "Edit organization details")}
        </ParagraphMedium>
        <Formik
          enableReinitialize
          initialValues={{
            id: school.id,
            title: school.title || "",
            domain: school.domain || "",
            educations:
              school?.educations?.map((education) => ({
                id: education.id,
                title: education.title,
                archived: education.archived,
              })) || [],
            immersiveReaderEnabled:
              school.immersiveReaderEnabled === false ? school.immersiveReaderEnabled : true,
            sebEnabled: school.sebEnabled || false,
            schoolyearEnabled: !!school.schoolyearKey || false,
            schoolyearKey: school.schoolyearKey || "",
          }}
          validationSchema={buildUpdateFormSchema()}
          onSubmit={onFormSubmit}
        >
          {(formik) => (
            <StyledForm noValidate onSubmit={formik.handleSubmit}>
              <Heading4>
                {isSchool
                  ? t("schoolDetails.form.basicInfoHeaderSchool", "Basic information")
                  : t("schoolDetails.form.basicInfoHeaderOrganization", "Organization information")}
              </Heading4>
              <StyledBasicInfo>
                <StyledField>
                  <label htmlFor="school-admin-edit-form-title">
                    {t("schoolDetails.form.name.label", "Name")}
                  </label>
                  <InputWithErrorMessage
                    dataCy="school-name"
                    errorMessage={formik.errors.title}
                    hasError={!!(formik.errors.title && formik.touched.title)}
                    id="school-admin-edit-form-title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                  />
                </StyledField>
                <StyledField>
                  <label htmlFor="school-admin-edit-form-domain">
                    {t("schoolDetails.form.domain.label", "Domain")}
                  </label>
                  <InputWithErrorMessage
                    dataCy="school-domain"
                    errorMessage={formik.errors.domain}
                    hasError={!!(formik.errors.domain && formik.touched.domain)}
                    id="school-admin-edit-form-domain"
                    name="domain"
                    value={formik.values.domain}
                    onChange={formik.handleChange}
                  />
                </StyledField>
              </StyledBasicInfo>

              <StyledField data-cy="school-educations">
                <StyledEducationHeader>
                  {isSchool
                    ? t("schoolDetails.form.educationsHeader", "Educations")
                    : t("schoolDetails.form.departmentsHeader", "Departments")}
                </StyledEducationHeader>

                {formik.values.educations.map((education, index) => (
                  <CssGrid
                    key={`${education.id || index}`}
                    data-cy={`education-row-${index}`}
                    gridTemplateColumns="1fr auto"
                    alignItems="center"
                    columnGap={1}
                  >
                    <StyledField>
                      <label htmlFor={`school-admin-edit-form-education-${index}`}>
                        {isSchool
                          ? t("schoolDetails.form.education.name.label", "Education")
                          : t("schoolDetails.form.department.name.label", "Department")}
                      </label>
                      <InputWithErrorMessage
                        dataCy="education-name-input"
                        errorMessage={t("schoolDetails.form.education.name.errors.required")}
                        hasError={education.title === ""}
                        disabled={education.archived}
                        id={`school-admin-edit-form-education-${index}`}
                        name="education"
                        value={education.title}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "educations",
                            formik.values.educations.map((item, i) => {
                              if (index === i) {
                                return {
                                  ...item,
                                  title: e.target.value,
                                };
                              }
                              return item;
                            }),
                          );
                        }}
                      />
                    </StyledField>
                    {/* Allow archiving / restoring educations for existing school */}
                    {schoolId !== "new" && (
                      <StyledField style={education.title ? { marginTop: "3rem" } : {}}>
                        {!education.archived ? (
                          <Button
                            aria-label={t("schoolDetails.form.education.archive.label")}
                            dataCy={"education-archive-button"}
                            id={`school-admin-edit-form-education-${index}-archive-button`}
                            onClick={(e) => {
                              e.preventDefault();
                              formik.setFieldValue(
                                "educations",
                                formik.values.educations.map((item, i) => {
                                  if (index === i) {
                                    return {
                                      ...item,
                                      archived: true,
                                    };
                                  }
                                  return item;
                                }),
                              );
                            }}
                          >
                            <EncircledIcon iconName="fas fa-archive" variant="secondary" />
                          </Button>
                        ) : (
                          <Button
                            aria-label={t("schoolDetails.form.education.restore.label")}
                            dataCy={"education-restore-button"}
                            id={`school-admin-edit-form-education-${index}-restore-button`}
                            onClick={(e) => {
                              e.preventDefault();
                              formik.setFieldValue(
                                "educations",
                                formik.values.educations.map((item, i) => {
                                  if (index === i) {
                                    return {
                                      ...item,
                                      archived: false,
                                    };
                                  }
                                  return item;
                                }),
                              );
                            }}
                          >
                            <EncircledIcon iconName="fas fa-undo" variant="default" />
                          </Button>
                        )}
                      </StyledField>
                    )}
                  </CssGrid>
                ))}

                <StyledButton
                  dataCy="add-education"
                  variant="default"
                  onClick={(event) => {
                    const { setFieldValue, values } = formik;
                    event.preventDefault();
                    setFieldValue("educations", [...values.educations, { id: null, title: "" }]);
                  }}
                >
                  {isSchool
                    ? t("schoolDetails.form.addEducationButton.labelSchool", "+ Add education")
                    : t(
                        "schoolDetails.form.addEducationButton.labelOrganization",
                        "+ Add department",
                      )}
                </StyledButton>
              </StyledField>

              {/* --- Additional settings --- */}
              <Heading4>{t("schoolDetails.form.settingsHeaderLabel", "Settings")}</Heading4>
              <StyledToggleSwitch
                dataCy="toggle-immersive-reader-enabled"
                isActive={formik.values.immersiveReaderEnabled}
                labelSubtitle={t("schoolDetails.form.irToggle.label", "Immersive reader available")}
                name="immersiveReaderEnabled"
                variant="primary"
                onChange={formik.handleChange}
              />
              {isSchool && (
                <>
                  <StyledToggleSwitch
                    dataCy="toggle-seb-enabled"
                    isActive={formik.values.sebEnabled || false}
                    labelSubtitle={t("schoolDetails.form.sebToggle.label", "SEB enabled")}
                    name="sebEnabled"
                    variant="primary"
                    onChange={formik.handleChange}
                  />
                  <StyledToggleSwitch
                    dataCy="toggle-schoolyear-enabled"
                    isActive={formik.values.schoolyearEnabled || false}
                    labelSubtitle={t(
                      "schoolDetails.form.schoolyearToggle.label",
                      "Schoolyear enabled",
                    )}
                    name="schoolyearEnabled"
                    variant="primary"
                    onChange={formik.handleChange}
                  />
                  {formik.values.schoolyearEnabled && (
                    <StyledSchoolyearWrapper>
                      <label htmlFor="school-admin-edit-form-domain">
                        {t("schoolDetails.form.schoolyearKey.label", "Domain")}
                      </label>
                      <InputWithErrorMessage
                        dataCy="schoolyear-key"
                        errorMessage={formik.errors.schoolyearKey}
                        hasError={!!(formik.errors.schoolyearKey && formik.touched.schoolyearKey)}
                        id="school-admin-edit-form-schoolyear-key"
                        name="schoolyearKey"
                        value={formik.values.schoolyearKey}
                        onChange={formik.handleChange}
                      />
                    </StyledSchoolyearWrapper>
                  )}
                </>
              )}

              <StyledButton
                dataCy="save-school-button"
                disabled={formik.isSubmitting}
                type="submit"
                variant="primary"
              >
                {t("schoolDetails.form.saveButton.label", "Save")}
              </StyledButton>
            </StyledForm>
          )}
        </Formik>
      </>
    );
  }

  return (
    <StyledLoadingWrapper>
      <LoadingSpinner />
    </StyledLoadingWrapper>
  );
});
