import img from "assets/images/bg-sign-up-blue.jpg";
import type { ElearningThemeInterface } from "./elearningInterface.theme";

const blackColor = "#1C1C1C";
const whiteColor = "#FFFFFF";
export const hogesTheme: ElearningThemeInterface = {
  name: "hoges",
  base: "16px",
  grid: 5,
  // Colors -----------
  palette: {
    feedback: {
      // green
      success: {
        _999: "#F2FFFB",
        _950: "#DEFBE6",
        _900: "#A7F0BA",
        _800: "#6FDC8C",
        _700: "#42BE65",
        _600: "#24A148",
        brand: "#198038",
        _400: "#0E6027",
        _300: "#044317",
        _200: "#022D0D",
        _100: "#071908",
      },
      // red
      error: {
        _999: "#FDFCFC",
        _950: "#FFF1F1",
        _900: "#FFD7D9",
        _800: "#FFB3B8",
        _700: "#FF8389",
        _600: "#FA4D56",
        brand: "#DA1E28",
        _400: "#A2191F",
        _300: "#750E13",
        _200: "#520408",
        _100: "#2D0709",
      },
      // Info blue
      info: {
        _999: "#F1FEFE",
        _950: "#E5F6FF",
        _900: "#BAE6FF",
        _800: "#82CFFF",
        _700: "#33B1FF",
        _600: "#1192E8",
        brand: "#0072C3",
        _400: "#00539A",
        _300: "#003A6D",
        _200: "#012749",
        _100: "#061727",
      },
    },
    // pink
    primary: {
      _999: "#FEFBFE",
      _950: "#F5EEF0",
      _900: "#F4DDE3",
      _800: "#EAB9D6",
      _700: "#E891BA",
      _600: "#E86198",
      brand: "#DB2A7E",
      _400: "#BB0056",
      _300: "#8E101F",
      _200: "#FFE9F5",
      _100: "#680119",
    },
    // blue
    secondary: {
      _999: "#F7FCFC",
      _950: "#E2F4F7",
      _900: "#C8E9F1",
      _800: "#99CFE1",
      _700: "#67B6D4",
      _600: "#1F9BC9",
      brand: "#0E7DC3",
      _400: "#206297",
      _300: "#1B4976",
      _200: "#143154",
      _100: "#0A1C36",
    },
    text: {
      primary: whiteColor,
      secondary: blackColor,
    },
    // gray
    neutral: {
      _999: "#FBFCFC",
      _950: "#F0F1F1",
      _900: "#E4E4E4",
      _800: "#C9C9C9",
      _700: "#AEAEAE",
      _600: "#929292",
      _500: "#777777",
      _400: "#5F5F60",
      _300: "#454545",
      _200: "#2F2F2F",
      black: blackColor,
      white: whiteColor,
    },
    yellow: {
      _999: "#FFFFB6",
      _950: "#FFF294",
      _900: "#FFE16A",
      _800: "#F4C012",
      _700: "#E0A503",
      _600: "#CA8400",
      brand: "#AB6C02",
      _400: "#935000",
      _300: "#6D3A0C",
      _200: "#422C12",
      _100: "#221A19",
    },
    shadow: {
      _06: "0px 2px 6px rgba(0, 0, 0, 0.218231)",
      _11: "0px 3px 11px rgba(0, 0, 0, 0.3)",
    },
  },

  // Typography -----------

  font: {
    family: {
      main: "Inter, Helvetica, Arial, sans-serif",
      secondary: "Px Grotesk, Helvetica, Arial, sans-serif",
    },
    weight: {
      normal: 400,
      bold: 700,
    },
    size: {
      _10: "0.625rem", // 10px
      _12: "0.75rem", // 12px,
      _14: "0.875rem", // 14px,
      _16: "1rem", // 16px base
      _18: "1.125rem", // 18px
      _24: "1.5rem", // 24px
      _32: "2rem", // 32px
      _40: "2.5rem", // 40px
      _48: "3rem", // 48px
      _64: "4rem", // 64px
    },
    lineHeight: {
      _150: "150%",
      _130: "130%",
    },
  },
  // Responsive -----------
  breakpoints: {
    mobile: "425px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1440px",
  },

  // Border radius -----------
  borderRadius: {
    rounded: ".25rem",
    roundedPill: "50rem",
  },

  pageLayout: {
    bottomRowHeight: "200px",
    topRowHeight: "5rem",
    numberOfColumns: 12,
    columnWidth: "1fr",
  },

  // Background images
  backgroundPages: {
    loginValidate: img,
  },

  // Margins and paddings ----------
  margin: (value: number) => `${([0, 0.5, 1, 1.5, 2][value] ?? 3).toString()}rem`,
  padding: (value: number) => `${([0, 0.5, 1, 1.5, 2][value] ?? 3).toString()}rem`,
  space: ["0", "0.5rem", "1rem", "1.5rem", "2rem", "3rem"], // shorthand used by styled-system props
};
