import { SvgIconReact } from "components/SvgIconReact";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledClickToCopy,
  StyledCopiedSuccessText,
  StyledCopyableText,
} from "./ClickToCopy.styled";

interface ClickToCopyProps {
  text: string;
}

const ClickToCopy: React.FC<ClickToCopyProps> = ({ text }) => {
  const { t } = useTranslation("common");
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <StyledClickToCopy onClick={handleCopy}>
      <StyledCopyableText>{text}</StyledCopyableText>
      {copied ? (
        <StyledCopiedSuccessText>{t("clickToCopy.success", "Copied!")}</StyledCopiedSuccessText>
      ) : (
        <SvgIconReact iconName="copyIcon" size={1.5} />
      )}
    </StyledClickToCopy>
  );
};

export default ClickToCopy;
