import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { Breadcrumbs } from "components/Breadcrumbs";
import { Button } from "components/Button";
import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { TEACHER_HOME_PAGE_ROUTES } from "constants/routes";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useGroups } from "hooks/useGroups";
import { Heading2 } from "styles/elements/Headings/index";
import { FormGroupName, FormGroupNameLine, FormWrapper } from "./components/Form.styled";

export const NewGroup: React.FC = observer(() => {
  const { groupName, changeGroupName, createGroup, savingGroup, resetGroupName } = useGroups();
  const { t } = useTranslation("groups");
  const { getTeacherTermByDomain } = useDomainHandler();

  const history = useHistory();

  const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const newGroupId = await createGroup();
    if (newGroupId) {
      history.replace(
        buildUrlWithPathParams(TEACHER_HOME_PAGE_ROUTES.EDIT_GROUP, { groupId: newGroupId }),
      );
    }
  };
  React.useEffect(() => {
    resetGroupName();
  }, []);

  return (
    <>
      <Breadcrumbs
        dataCy="new-group-page"
        excludeRoutes={["/", TEACHER_HOME_PAGE_ROUTES.NEW_GROUP]}
        routes={[
          {
            path: TEACHER_HOME_PAGE_ROUTES.HOME_PAGE,
            breadcrumb: t("breadcrumbs.teacher", { teacherTerm: getTeacherTermByDomain() }),
          },
          { path: TEACHER_HOME_PAGE_ROUTES.GROUPS, breadcrumb: t("breadcrumbs.groups") },
        ]}
      />
      <Heading2>{t("newGroup")}</Heading2>
      <FormWrapper>
        <FormGroupName onSubmit={onFormSubmit}>
          <label htmlFor="group-name">{t("groupName")}</label>
          <FormGroupNameLine>
            <InputWithErrorMessage
              // https://stackoverflow.com/questions/2180645/is-automatically-assigning-focus-bad-for-accessibility
              // Only element on the page, so it's fine to autofocus
              autoFocus
              dataCy="group-name"
              hasError={false}
              id="group-name"
              name="group-name"
              value={groupName}
              onChange={changeGroupName}
            />
            <Button
              data-cy="create-group-submit-btn"
              disabled={groupName.trim() === "" || savingGroup}
              isLoading={savingGroup}
              type="submit"
              variant="primary"
            >
              {t("createGroup")}
            </Button>
          </FormGroupNameLine>
        </FormGroupName>
      </FormWrapper>
    </>
  );
});
