import { format } from "date-fns";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";
import type { ScheduledTestResultList } from "models/exam/ScheduledTest";

import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { CssFlex } from "styles/helpers/layout";

import { useScheduledTests } from "hooks/useScheduledTests";
import {
  StyledButton,
  StyledCollapsibleIcon,
  StyledCountdownTimer,
  StyledMessage,
  StyledReviewPeriodNotice,
  StyledRow,
  StyledScoreContainer,
} from "./ScheduledTestResultRow.styled";

interface ScheduledTestResultRowProps {
  isOddRow?: boolean;
  reviewPeriodTimeLeft: number;
  scheduledTestOverview: ScheduledTestResultList;
}

export const ScheduledTestResultRow: React.FC<ScheduledTestResultRowProps> = ({
  isOddRow = false,
  reviewPeriodTimeLeft,
  scheduledTestOverview,
}) => {
  const { t } = useTranslation("results");
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const { setAuthTestCode, setScheduledTestContent } = useScheduledTests();

  const { code, exercises, hasPassed } = scheduledTestOverview;

  const startDate = new Date(scheduledTestOverview.startDate);
  const isReviewPeriodOpen = !!reviewPeriodTimeLeft;

  const avgScore = scheduledTestOverview.score || 0;
  return (
    <>
      <StyledRow $isOdd={isOddRow} data-cy="test-result-row">
        <td data-cy="test-start-date">{format(startDate, "dd-MM-yyyy HH:mm")}</td>
        <td data-cy="test-skill-level">{scheduledTestOverview.skillLevel}</td>
        <td data-cy="test-title">{scheduledTestOverview.title}</td>
        <td data-cy="test-scores">
          <StyledScoreContainer
            alignItems="center"
            columnGap={1}
            gridTemplateColumns="1fr 6rem 3rem"
          >
            <b>{t("testResultOverviewTable.finalScore", "Final score")}</b>
            <ProgressBar
              currentValue={avgScore}
              dataCy="test-score-progress-bar"
              maxValue={100}
              variant={hasPassed ? "success" : "error"}
            />
            <b data-cy="avg-test-score">{`${Math.round(avgScore)}%`}</b>
          </StyledScoreContainer>
          {!collapsed &&
            exercises.map(({ id: exerciseId, title, score }) => (
              <CssFlex key={exerciseId} columnGap={1} justifyContent="space-between">
                <span data-cy="test-exercise-title">{title}</span>
                <span data-cy="test-exercise-score">{`${Math.round(score)}%`}</span>
              </CssFlex>
            ))}
        </td>
        <td>
          <StyledCollapsibleIcon
            data-cy={`test-${collapsed ? "expand" : "collapse"}-button`}
            iconName={collapsed ? "fas fa-chevron-down" : "fas fa-chevron-up"}
            onClick={() => setCollapsed(!collapsed)}
          />
        </td>
      </StyledRow>
      {isReviewPeriodOpen && (
        <StyledRow $isOdd={isOddRow} data-cy="test-review-period-message" data-test-code={code}>
          <td colSpan={6}>
            <StyledReviewPeriodNotice>
              <StyledMessage>
                {t(
                  "testResultOverviewTable.reviewPeriodActive",
                  "Your test has been graded! View your results.",
                )}
              </StyledMessage>
              <StyledCountdownTimer secondsLeft={reviewPeriodTimeLeft} />
              <StyledButton
                dataCy="view-detailed-test-results-button"
                variant="primary"
                onClick={() => {
                  setAuthTestCode("");
                  setScheduledTestContent(null);
                  history.push(
                    buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PAGE, { code }),
                  );
                }}
              >
                {t("button.viewResults", "View results")}
              </StyledButton>
            </StyledReviewPeriodNotice>
          </td>
        </StyledRow>
      )}
    </>
  );
};
