import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { calculateRem } from "styles/mixins/mixins";

import { Heading4 } from "styles/elements/Headings";

import { Button } from "components/Button";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { InputWithErrorMessage } from "components/InputWithErrorMessage";
import { Link } from "components/Link";

export const StyledButton = styled(Button)`
  margin-top: 2rem;
`;

export const StyledField = styled.div`
  margin-top: 2rem;

  ${Heading4} {
    margin-bottom: 0.25rem;
  }
`;

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled(InputWithErrorMessage)`
  padding: 1rem 1rem 1rem 3rem;
`;

export const StyledIcon = styled(FontAwesomeIcon)<ThemeProps>`
  position: absolute;
  top: 1rem;
  left: 1rem;
  margin-top: 1px;
  color: ${(props: ThemeProps) => props.theme.palette.neutral._600};
`;

export const StyledLink = styled(Link)<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._10};
  color: ${({ theme }: ThemeProps) => theme.palette.primary.brand};
  margin-top: ${calculateRem(12)};
  justify-content: flex-end;
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.normal};
`;
