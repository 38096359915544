import { observer } from "mobx-react";
import type React from "react";

import { SeparatorLine } from "components/SeparatorLine";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { CssFlex } from "styles/helpers/layout";
import { ChangeTestOwnerForm } from "./components/ChangeTestOwnerForm/ChangeTestOwnerForm";
import { DeleteAccounts } from "./components/DeleteAccounts/DeleteAccounts";
import { TransferResults } from "./components/TransferResults/TransferResults";

export const UserToolsPage: React.FC = observer(() => {
  const { showDeleteAccountsTool } = useFeatureFlags();

  return (
    <CssFlex flexDirection="column" gap={2}>
      <TransferResults />
      <SeparatorLine direction="horizontal" />
      <ChangeTestOwnerForm />
      {showDeleteAccountsTool && (
        <>
          <SeparatorLine direction="horizontal" />
          <DeleteAccounts />
        </>
      )}
    </CssFlex>
  );
});
