import { observer } from "mobx-react";
import queryString from "query-string";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { AUTH_PAGES_ROUTES } from "constants/routes";

import { useLogin } from "hooks/useLogin";

import { SvgIconReact } from "components/SvgIconReact";
import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";

import { StyledButton } from "../../MigrateAccessPage.styled";
import { StyledContainer, StyledIconContainer } from "./MigrationSuccess.styled";

export const MigrationSuccess: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation("auth-pages");

  const { userCode } = useLogin();

  const performLogin = () => {
    const { state } = queryString.parse(window.location.search);
    const extraURL = state ? `&state=${state}` : "";
    history.push(`${AUTH_PAGES_ROUTES.VALIDATE}?code=${userCode}${extraURL}`);
  };

  return (
    <StyledContainer data-cy="migration-success-message">
      <Heading3>{t("migrateAccountPage.migrationSuccess.title")}</Heading3>
      <ParagraphLarge>
        {t("migrateAccountPage.migrationSuccess.description.success")}
      </ParagraphLarge>
      <StyledIconContainer>
        <SvgIconReact iconName="partyIcon" size={10} />
      </StyledIconContainer>
      <StyledButton isBlock dataCy="direct-login-button" variant="primary" onClick={performLogin}>
        {t("migrateAccountPage.migrationSuccess.button.login")}
      </StyledButton>
    </StyledContainer>
  );
});
