import { RadioButton } from "components/RadioButton/RadioButton";
import type { ProductSkillLevel } from "models/product/ProductDetails";
import type React from "react";
import { Box } from "styles/helpers/layout";

export interface SkillLevelSelectorProps {
  availableSkillLevels: ProductSkillLevel[];
  selectedRadioButtonIndex: number;
  onRadioButtonChange: (radioButtonIndex: number, selectedSkillLevel: number) => void;
}

export const SkillLevelSelector: React.FC<SkillLevelSelectorProps> = ({
  availableSkillLevels,
  selectedRadioButtonIndex,
  onRadioButtonChange,
}) => {
  return (
    <Box>
      {availableSkillLevels.map((productSkillLevel, index) => {
        return (
          <Box key={productSkillLevel.id} margin={3}>
            <RadioButton
              bulletColor="primary"
              bulletStyle="thin"
              checked={selectedRadioButtonIndex === index}
              dataCy={`skill-level-radio-button-${productSkillLevel.id}`}
              description={productSkillLevel.description || ""}
              disabled={false}
              label={productSkillLevel.label}
              name={`skill-level-desired-${productSkillLevel.id}`}
              value={productSkillLevel.level}
              onChange={() => {
                onRadioButtonChange(index, productSkillLevel.level);
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};
