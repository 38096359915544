import { Button } from "components/Button";
import { SearchBox } from "components/SearchBox";
import { SortableTable } from "components/SortableTable/SortableTable";
import { StyledCell, StyledHeaderCell } from "components/SortableTable/SortableTable.styled";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components";
import { Box } from "styles/helpers/layout";

export const StyledDataContainer = styled(Box)`
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  border-radius: 4px;
  margin-top: 2rem;
`;
export const StyledHeader = styled(Box)`
  padding: 1.5rem 2rem;
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._950};
`;

export const StyledSortableTable = styled(SortableTable)<ThemeProps>`
  margin-top: 0;
  ${StyledHeaderCell} {
    align-items: flex-start;
    padding: 1rem 2rem;
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._900};
  }
  ${StyledCell} {
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._999};
    padding: 1rem 2rem;
    a {
      text-decoration: none;
      color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
    }
  }
`;

export const StyledSearchBox = styled(SearchBox)`
  width: 5rem;
`;

export const StyledActionButton = styled(Button)`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
`;
