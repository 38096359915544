import { HST_TEST_STATUS_EVENTS } from "@infinitaslearning/module-he-common";
import { buildURLwithQueryParams, buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ScheduledTestTimeLeftStatus, TestEventTypes, TestType } from "constants/exam-constants";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";
import { useFullScreen } from "hooks/useFullScreen";
import { useLogs } from "hooks/useLogs";
import { useRedirect } from "hooks/useRedirect";
import { useSEBKeys } from "hooks/useSEBKeys";
import { useScheduledTests } from "hooks/useScheduledTests";
import { useTestStatusAndTimeLeft } from "hooks/useTestStatusAndTimeLeft";
import { observer } from "mobx-react";
import { ScheduledTestHeader } from "pages/TestEnvironment/TestPage/components/ScheduledTestHeader/ScheduledTestHeader";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Heading2 } from "styles/elements/Headings";
import type { TestPagePathParams } from "./TestOverviewPage.model";
import { StyledContainer } from "./TestOverviewPage.styled";
import { ImportantNotice } from "./components/ImportantNotice/ImportantNotice";
import { Rules } from "./components/Rules/Rules";
import { TestOverview } from "./components/TestOverview/TestOverview";
import { TimeLeft } from "./components/TimeLeft/TimeLeft";
import { stepsConfig } from "./stepsConfig";

export const TestOverviewPage: React.FC = observer(() => {
  const [hasAuthorizedSEBKey, setHasAuthorizedSEBKey] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("test-environment");
  const { code }: TestPagePathParams = useParams();
  const { redirectToUrl } = useRedirect();

  const { trackTestEvent } = useLogs();
  const {
    visitedExam,
    scheduledTestContent,
    fetchScheduledTestContent,
    fetchScheduledTestStatus,
    finishScheduledTest,
    fetchScheduledTestStatusAndTimeLeft,
    scheduledTestStatusAndTimeLeft,
  } = useScheduledTests();
  const { authorizeTestWithSebKey, fetchSEBHeaderKey } = useSEBKeys();
  const { fullScreen } = useFullScreen();
  useTestStatusAndTimeLeft(code);

  const iniStep = visitedExam ? 2 : 0;
  const startDate = new Date(scheduledTestContent?.startDate || new Date());

  const [step, setStep] = useState(iniStep);
  const [isTestStarted, setTestStarted] = useState(false);

  useEffect(() => {
    if (scheduledTestStatusAndTimeLeft?.status === ScheduledTestTimeLeftStatus.ON_REVIEW) {
      history.replace(
        buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_COMPLETED_PAGE, { code }),
      );
    }
  }, [scheduledTestStatusAndTimeLeft]);

  useEffect(() => {
    if (scheduledTestContent?.testId) {
      trackTestEvent(
        HST_TEST_STATUS_EVENTS.SCHEDULED_TEST_OPENED,
        TestEventTypes.STATUS,
        scheduledTestContent.token,
      );
      fetchScheduledTestStatus(code);
    }
  }, [fetchScheduledTestStatus, scheduledTestContent]);

  useEffect(() => {
    if (!scheduledTestContent?.id) return;

    fetchSEBHeaderKey((sebHeaderKey) => {
      authorizeTestWithSebKey(scheduledTestContent?.id, sebHeaderKey, () =>
        setHasAuthorizedSEBKey(true),
      );
    });
  }, [scheduledTestContent?.id]);

  useEffect(() => {
    if (scheduledTestContent?.testId) {
      return;
    }

    fetchScheduledTestContent(code).then(({ accessGranted, isTestFinished, redirectUrl }) => {
      // Redirect user out of the test overview page if they haven't been granted access
      if (!accessGranted) {
        if (redirectUrl) {
          // If a custom url is specified, user will be redirected to it (for Schoolyear tests)
          redirectToUrl(redirectUrl);
        } else {
          // In any other case, return to landing page
          history.replace(
            buildURLwithQueryParams(TEST_ENVIRONMENT_ROUTES.LANDING_PAGE, [
              { key: "code", value: code },
            ]),
          );
        }
      }

      // If test is already finished, then go straight to completed page
      if (isTestFinished) {
        history.replace(
          buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_COMPLETED_PAGE, { code }),
        );
      }
    });
  }, [scheduledTestContent]);

  useEffect(() => {
    if (scheduledTestContent && scheduledTestContent?.testType !== TestType.SUMMATIVE_TEST) {
      setStep(2);
    }
  }, [scheduledTestContent]);

  const onCloseTest = () => {
    // request to content API to close the test
    const testToken = scheduledTestContent?.token || "";
    finishScheduledTest(code, testToken);
    trackTestEvent(
      HST_TEST_STATUS_EVENTS.SCHEDULED_TEST_FINISHED,
      TestEventTypes.STATUS,
      testToken,
    );
    // then redirect to test completed page
    history.replace(buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_COMPLETED_PAGE, { code }));
  };

  const goToNextStep = () => {
    if (step === 1) {
      const testToken = scheduledTestContent?.token || "";
      trackTestEvent(
        HST_TEST_STATUS_EVENTS.TERMS_AND_CONDITIONS_ACCEPTED,
        TestEventTypes.STATUS,
        testToken,
      );
    }
    setStep(step + 1);
  };

  const getCurrentStep = () => {
    switch (step) {
      case 0:
        return <ImportantNotice onAccept={goToNextStep} />;
      case 1:
        return <Rules onAccept={goToNextStep} />;
      case 2:
        return (
          <TestOverview
            code={code}
            isFullScreen={fullScreen?.active ?? false}
            onCloseTest={onCloseTest}
          />
        );
      default:
        return null;
    }
  };

  const showCountdownTimer = useCallback(() => {
    if (scheduledTestStatusAndTimeLeft?.status === ScheduledTestTimeLeftStatus.ONGOING) {
      return true;
    }

    if (
      scheduledTestStatusAndTimeLeft?.status === ScheduledTestTimeLeftStatus.NOT_STARTED &&
      scheduledTestStatusAndTimeLeft?.counter === 0
    ) {
      fetchScheduledTestStatusAndTimeLeft(code);
      return true;
    }

    if (
      scheduledTestStatusAndTimeLeft?.counter &&
      scheduledTestStatusAndTimeLeft?.status === ScheduledTestTimeLeftStatus.NOT_STARTED
    ) {
      return scheduledTestStatusAndTimeLeft.counter < 600;
    }
    return false;
  }, [scheduledTestStatusAndTimeLeft?.counter, scheduledTestStatusAndTimeLeft?.status]);

  if (!scheduledTestContent) {
    return <LoadingSpinner isFullScreen />;
  }

  if (!isTestStarted && !visitedExam) {
    // only show timer when test is in less than 10 minutes

    return (
      <TimeLeft
        currentStatus={scheduledTestStatusAndTimeLeft?.status}
        hasAuthorizedSEBKey={hasAuthorizedSEBKey}
        secondsLeft={scheduledTestStatusAndTimeLeft?.counter || 0}
        showCountdownTimer={showCountdownTimer()}
        startDate={startDate}
        onBackToHomepage={() => history.push(TEST_ENVIRONMENT_ROUTES.LANDING_PAGE)}
        onStartTestClick={() => setTestStarted(true)}
      />
    );
  }

  const stepSubTitle =
    scheduledTestContent?.title && step === 2
      ? scheduledTestContent.title
      : t(stepsConfig[step].subTitle);

  return (
    <>
      <ScheduledTestHeader
        counter={scheduledTestStatusAndTimeLeft?.counter || 0}
        scheduledTestContent={scheduledTestContent}
        title={t(stepsConfig[step].title)}
      />
      <StyledContainer>
        <Heading2 marginBottom="2rem">{stepSubTitle}</Heading2>
        {getCurrentStep()}
      </StyledContainer>
    </>
  );
});
