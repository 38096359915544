import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { useState } from "react";
import type { DragEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  FileInput,
  StyledParagraph,
  StyledTitle,
  UploadButton,
  UploadContainer,
} from "./FileUpload.styled";

interface FileUploadProps {
  setFieldValue: (field: string, value: File | null) => void;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  file: File | null;
}

const FileUpload: React.FC<FileUploadProps> = ({ setFieldValue, setFile, file }) => {
  const [, setDragOver] = useState(false);
  const { t } = useTranslation("common");

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files.length > 0) {
      const uploadedFile = event.dataTransfer.files[0];
      setFile(uploadedFile);
      setFieldValue("pdfFile", uploadedFile);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const uploadedFile = event.target.files[0];
      setFile(uploadedFile);
      setFieldValue("pdfFile", uploadedFile);
    }
  };

  return (
    <UploadContainer onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
      <FontAwesomeIcon iconName="fas fa-cloud-upload-alt" iconSize="2x" iconColor={"gray"} />
      <StyledTitle>{t("uploadFile.paragraph")}</StyledTitle>
      <StyledParagraph>PDF, max 15 MB.</StyledParagraph>
      <label htmlFor="fileInput">
        <FileInput
          id="fileInput"
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          data-cy="teacher-intruction-file-input"
        />
        <UploadButton
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            const fileInput = e.currentTarget.previousElementSibling as HTMLInputElement;
            fileInput.click();
          }}
        >
          {t("uploadFile.searchFile")}
        </UploadButton>
      </label>
      {file && (
        <StyledParagraph>
          {t("uploadFile.selectedFile")} {file.name}
        </StyledParagraph>
      )}
    </UploadContainer>
  );
};

export default FileUpload;
