import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { modalIds } from "constants/modals-constants";
import { useAdmin } from "hooks/useAdmin";
import { useModal } from "hooks/useModal";

import { Heading3 } from "styles/elements/Headings";
import { Box, CssButton, CssFlex } from "styles/helpers/layout";

import {
  type EmailType,
  MultipleEmailInput,
} from "pages/TeacherEnvironment/TeacherDashboard/subpages/StudentLicense/subpages/CreateInviteForm/components/MultipleEmailInput/MultipleEmailInput";
import { DeleteAccountsModal } from "../DeleteAccountsModal/DeleteAccountsModal";

export const DeleteAccounts: FC = () => {
  const [emails, setEmails] = useState<EmailType[]>([]);

  const { openModal } = useModal();
  const { deleteAccounts } = useAdmin();
  const { t } = useTranslation("admin-environment");

  const emailsArr = emails.map((x) => x.email);

  return (
    <div>
      <Heading3 mb={2}>{t("adminTools.deleteAccounts.title")}</Heading3>

      <CssFlex mb={2}>
        <Box width="100%">
          <MultipleEmailInput
            selectedEmails={emails}
            width="90%"
            onSelectedEmailsChanged={setEmails}
          />
        </Box>
        <CssButton
          dataCy="delete-account"
          disabled={emails.length === 0}
          height="3rem"
          ml={5}
          mt={1}
          p={0}
          variant="primary"
          width="25%"
          onClick={() => openModal(modalIds.deleteAccountsModal)}
        >
          {t("adminTools.deleteAccounts.action")}
        </CssButton>
        <DeleteAccountsModal
          emails={emailsArr}
          onSubmit={() => emails.length > 0 && deleteAccounts(emailsArr)}
        />
      </CssFlex>
    </div>
  );
};
