import type { Variant as ButtonVariant } from "components/Button/Button.model";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

export interface ButtonPalette {
  color: {
    default: string;
    hover: string;
    focus: string;
  };
  background: {
    default: string;
    hover: string;
    focus: string;
  };
}

export const getButtonColorPalette = (
  theme: ElearningThemeInterface,
  variant: ButtonVariant,
  disabled: boolean,
): ButtonPalette => {
  switch (variant) {
    case "dark":
      return disabled
        ? {
            color: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
            background: {
              default: "#5F5F60",
              hover: theme.palette.neutral._500,
              focus: theme.palette.neutral._500,
            },
          }
        : {
            color: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
            background: {
              default: "#5F5F60",
              hover: theme.palette.neutral._500,
              focus: theme.palette.neutral._500,
            },
          };
    case "primary":
      return disabled
        ? {
            color: {
              default: theme.palette.text.primary,
              hover: theme.palette.text.primary,
              focus: theme.palette.text.primary,
            },
            background: {
              default: theme.palette.primary._800,
              hover: theme.palette.primary._800,
              focus: theme.palette.primary._800,
            },
          }
        : {
            color: {
              default: theme.palette.text.primary,
              hover: theme.palette.text.primary,
              focus: theme.palette.text.primary,
            },
            background: {
              default: theme.palette.primary.brand,
              hover: theme.palette.primary._400,
              focus: theme.palette.primary.brand,
            },
          };
    case "primary-outline":
      return disabled
        ? {
            color: {
              default: theme.palette.primary._400,
              hover: theme.palette.primary._400,
              focus: theme.palette.primary._400,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
          }
        : {
            color: {
              default: theme.palette.primary._400,
              hover: theme.palette.primary._400,
              focus: theme.palette.primary._400,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral.white,
            },
          };
    case "secondary":
      return disabled
        ? {
            color: {
              default: theme.palette.neutral._600,
              hover: theme.palette.neutral._600,
              focus: theme.palette.neutral._600,
            },
            background: {
              default: theme.palette.neutral._950,
              hover: theme.palette.neutral._950,
              focus: theme.palette.neutral._950,
            },
          }
        : {
            color: {
              default: theme.palette.text.secondary,
              hover: theme.palette.text.secondary,
              focus: theme.palette.text.secondary,
            },
            background: {
              default: theme.palette.neutral._900,
              hover: theme.palette.neutral._800,
              focus: theme.palette.neutral._900,
            },
          };
    case "border":
      return disabled
        ? {
            color: {
              default: theme.palette.neutral._900,
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral._900,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral.white,
            },
          }
        : {
            color: {
              default: theme.palette.text.secondary,
              hover: theme.palette.text.secondary,
              focus: theme.palette.text.secondary,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral._950,
            },
          };
    case "brand":
      return disabled
        ? {
            color: {
              default: theme.palette.text.primary,
              hover: theme.palette.text.primary,
              focus: theme.palette.text.primary,
            },
            background: {
              default: theme.palette.secondary.brand,
              hover: theme.palette.secondary.brand,
              focus: theme.palette.secondary.brand,
            },
          }
        : {
            color: {
              default: theme.palette.text.primary,
              hover: theme.palette.text.primary,
              focus: theme.palette.text.primary,
            },
            background: {
              default: theme.palette.secondary.brand,
              hover: theme.palette.secondary._400 || theme.palette.secondary._950,
              focus: theme.palette.secondary.brand,
            },
          };
    case "success":
      return disabled
        ? {
            color: {
              default: theme.palette.neutral._600,
              hover: theme.palette.neutral._600,
              focus: theme.palette.neutral._600,
            },
            background: {
              default: theme.palette.neutral._900,
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral._900,
            },
          }
        : {
            color: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
            background: {
              default: theme.palette.feedback.success.brand,
              hover: theme.palette.feedback.success._400,
              focus: theme.palette.feedback.success._400,
            },
          };
    case "error":
      return disabled
        ? {
            color: {
              default: theme.palette.neutral._600,
              hover: theme.palette.neutral._600,
              focus: theme.palette.neutral._600,
            },
            background: {
              default: theme.palette.neutral._900,
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral._900,
            },
          }
        : {
            color: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
            background: {
              default: theme.palette.feedback.error.brand,
              hover: theme.palette.feedback.error._400,
              focus: theme.palette.feedback.error._400,
            },
          };
    case "info":
      return disabled
        ? {
            color: {
              default: theme.palette.neutral._600,
              hover: theme.palette.neutral._600,
              focus: theme.palette.neutral._600,
            },
            background: {
              default: theme.palette.neutral._900,
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral._900,
            },
          }
        : {
            color: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
            background: {
              default: theme.palette.feedback.info.brand,
              hover: theme.palette.feedback.info._400,
              focus: theme.palette.feedback.info._400,
            },
          };
    default:
      return disabled
        ? {
            color: {
              default: theme.palette.neutral._900,
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral._900,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral.white,
            },
          }
        : {
            color: {
              default: theme.palette.secondary.brand,
              hover: theme.palette.secondary.brand,
              focus: theme.palette.secondary.brand,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
          };
  }
};
