import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledScheduledTestsFilters = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${calculateRem(42)};
`;

export const StyledInputSelectAdminWrapper = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
`;
