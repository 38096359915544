import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";
import { calculateRem } from "styles/mixins/mixins";

import { Select } from "components/SelectUI/Select";
import { CssFlex } from "styles/helpers/layout";

export const StyledSelect = styled(Select)<Props>`
  width: ${calculateRem(396)};
`;

export const StyledSubtitle = styled.span<Props>`
  font-size: ${(props: Props) => props.theme.font.size._18};
  color: ${(props: Props) => props.theme.palette.neutral._500};
`;

export const StyledMainSection = styled(CssFlex)<Props>`
  max-width: max-content;
`;

export const StyledTestEnvCard = styled(CssFlex)<Props>`
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  width: 21rem;
  min-height: 15rem;
  max-height: 25rem;
  border: solid 1px;
  border-color: ${(props: Props) => props.theme.palette.secondary.brand};
  background-color: ${(props: Props) => props.theme.palette.secondary._950};
  line-height: 0;
  border-radius: 4px;
  img {
    height: 8rem;
    margin-bottom: -1rem;
  }
  button {
    padding: 0.6875rem 1.125rem;
  }
`;
