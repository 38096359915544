import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { Box } from "styles/helpers/layout";

import { SanitizedHtml } from "components/SanitizedHtml";

export const StyledAdaptiveTestHeader = styled.div<ThemeProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 1rem 4rem 2rem 4rem;
`;

export const StyledTitle = styled(SanitizedHtml)<ThemeProps>`
  font-weight: bold;
  font-size: ${(props: ThemeProps) => props.theme.font.size._24};
`;

interface StyledSubtitleProps extends ThemeProps {
  separator: string;
}

export const StyledSubtitle = styled(Box)<StyledSubtitleProps>`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};

  > :not(:first-child)::before {
    width: auto;
    content: '${({ separator }: StyledSubtitleProps) => separator}';
  }
`;

export const StyledPageMetaContainer = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const StyledSessionStatisticsContainer = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._600};
  font-size: ${(props: ThemeProps) => props.theme.font.size._24};
  margin-right: 1rem;
`;
