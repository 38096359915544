import { HST_TEST_STATUS_EVENTS } from "@infinitaslearning/module-he-common";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { ActionBanner } from "components/ActionBanner/ActionBanner";
import { Button } from "components/Button";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ToastMethods } from "components/ToastNotification";
import { TestEventTypes, TestType } from "constants/exam-constants";
import { TEST_ENVIRONMENT_ROUTES } from "constants/routes";
import { useAnswer } from "hooks/useAnswer";
import { useFailingExamRules } from "hooks/useExamRules";
import { useLogs } from "hooks/useLogs";
import { useScheduledTests } from "hooks/useScheduledTests";
import { GeneralHeader } from "pages/TestEnvironment/LandingPage/components";
import { TextQuizText } from "pages/TestEnvironment/TestPage/components/TextQuizText/TextQuizText";
import { useTranslation } from "react-i18next";
import { ExamRuleFailingModal } from "../TestOverviewPage/components/ExamRuleFailingModal/ExamRuleFailingModal";
import type { PagePathParams } from "./ReviewExercisePage.model";
import {
  StyledAnswersContainer,
  StyledReviewExercisePage,
  StyledTextQuizContainer,
  StyledTextQuizText,
} from "./ReviewExercisePage.styled";
import { ReviewExerciseAnswers } from "./components/ReviewExerciseAnswers";

const getTestDetailsUrl = (code: string) =>
  buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_DETAILS_PAGE, { code });

export const ReviewExercisePage: React.FC = observer(() => {
  const { t } = useTranslation("test-environment");
  const history = useHistory();
  const failingRules = useFailingExamRules();
  const { code, exerciseId, productId }: PagePathParams = useParams();
  const {
    scheduledTestContent,
    scheduledTestExercise: exercise,
    scheduledTestExerciseStatus: exerciseStatus,
    fetchScheduledTestExercise: fetchExercise,
    fetchScheduledTestExerciseAnswers: fetchExcerciseAnswers,
    scheduledTestExerciseAnswers: exerciseAnswers,
    setScheduledTestExerciseAnswers: setExerciseAnswers,
  } = useScheduledTests();
  const { trackTestEvent } = useLogs();
  const isLoading = !exercise || !exerciseAnswers;
  const testToken = scheduledTestContent?.token || "";
  const { isEvaluatingAnswer, evaluateAnswer, resetAnswerState } = useAnswer();
  const [quizTextExpanded, setQuizTextExpanded] = useState(false);

  useEffect(() => {
    if (!scheduledTestContent) {
      // not validated, redirect back to landing page
      history.replace(TEST_ENVIRONMENT_ROUTES.LANDING_PAGE);
      return;
    }
    if (!scheduledTestContent.editableAnswers) {
      // cannot edit answers for this scheduled test, redirect back
      history.replace(getTestDetailsUrl(code));
    }
  }, [scheduledTestContent]);

  useEffect(() => {
    if (
      exerciseStatus &&
      exerciseStatus.answeredQuestions.length !== exerciseStatus.totalQuestions
    ) {
      // not really completed, redirect back to exercise page so user can complete it
      history.replace(
        buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_PAGE, {
          code,
          productId,
          exerciseId,
        }),
      );
    }
  }, [exerciseStatus]);

  useEffect(() => {
    if (!exercise || exercise.id !== +exerciseId) {
      // load exercise
      fetchExercise({
        testCode: code,
        exerciseId: +exerciseId,
        testToken,
        randomizeQuestions: true,
      });
    }
  }, [exercise, +exerciseId]);

  useEffect(() => {
    fetchExcerciseAnswers(+exerciseId, testToken);
    return () => {
      // always unload the exercise answers on unload
      setExerciseAnswers(null);
      resetAnswerState();
    };
  }, []);

  const onActionBannerButtonClick = () => {
    trackTestEvent(HST_TEST_STATUS_EVENTS.EXERCISE_REVIEWED, TestEventTypes.STATUS, testToken, {
      exerciseId: +exerciseId,
      name: exercise?.title || "",
    });
    history.replace(getTestDetailsUrl(code));
  };

  return (
    <>
      <GeneralHeader />
      <StyledReviewExercisePage>
        <ActionBanner
          actionButtonDisabled={false}
          actionButtonTitle={t("reviewExercise.actionBanner.button.label", "Back to overview")}
          dataCy="review-answers-action-banner"
          text={t(
            "reviewExercise.actionBanner.text",
            "Make any necessary adjustments before moving on to the next part.",
          )}
          title={t("reviewExercise.actionBanner.title", "Review your answers")}
          onActionButtonClick={onActionBannerButtonClick}
        />
        {isLoading ? (
          <LoadingSpinner isFullScreen />
        ) : (
          <StyledAnswersContainer>
            {exercise?.isTextQuiz && (
              <StyledTextQuizContainer>
                <StyledTextQuizText $isExpanded={quizTextExpanded} data-cy="text-quiz-text-content">
                  <TextQuizText text={exercise.text || ""} />
                </StyledTextQuizText>
                <Button
                  dataCy="text-quiz-text-expand-button"
                  variant="secondary"
                  onClick={() => setQuizTextExpanded(!quizTextExpanded)}
                >
                  {quizTextExpanded
                    ? t("reviewExercise.textQuiz.hideButton.label", "Hide text")
                    : t("reviewExercise.textQuiz.showButton.label", "Show text")}
                </Button>
              </StyledTextQuizContainer>
            )}
            <ReviewExerciseAnswers
              answers={exerciseAnswers}
              evaluateAnswer={(productId, questionId, answers) => {
                evaluateAnswer({
                  productId,
                  questionId,
                  answers,
                  testToken,
                  exerciseId: +exerciseId,
                  testType: (scheduledTestContent?.testType as TestType) || TestType.UNDEFINED,
                }).then(() =>
                  ToastMethods.showToast(t("feedback.answerUpdated.success"), "success"),
                );
              }}
              isEvaluatingAnswer={isEvaluatingAnswer}
              questions={exercise?.questions}
            />
          </StyledAnswersContainer>
        )}
        {/* Modal to display when the user violates any of the proctoring rules (full screen mode, mouse inside the page) */}
        <ExamRuleFailingModal failingRules={failingRules} />
      </StyledReviewExercisePage>
    </>
  );
});
