import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Link } from "components/Link";
import { LoadingSpinner } from "components/LoadingSpinner";
import { SearchBox } from "components/SearchBox";
import type { TableRow } from "components/SortableTable/SortableTable.model";
import { Tag } from "components/Tag/Tag";
import { ScheduledTestTimeLeftStatus } from "constants/exam-constants";
import { modalIds } from "constants/modals-constants";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useModal } from "hooks/useModal";
import { useScheduledTests } from "hooks/useScheduledTests";
import { observer } from "mobx-react";
import type { ScheduledTestLog, ScheduledTestLogStatus } from "models/log/Log";

import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { SvgIconReact } from "components/SvgIconReact";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useSchoolyear } from "hooks/useSchoolyear";
/**
 *  IMPORTANT! Don't remove these lines!
 *  This is required for the "i18next-scanner" command to recognize these locale strings.
 *
 * t('scheduled-tests:liveMonitor.status.notStarted')
 * t('scheduled-tests:liveMonitor.status.termsAccepted')
 * t('scheduled-tests:liveMonitor.status.testing')
 * t('scheduled-tests:liveMonitor.status.finished')
 * */
import type React from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ThemeContext } from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type { ScheduledTestLiveMonitorPageParams } from "./ScheduledTestLiveMonitorPage.model";
import {
  StyledFilterList,
  StyledIconButton,
  StyledLiveMonitorPage,
  StyledLiveMonitorStudents,
  StyledSearchBoxWrapper,
  StyledTable,
  StyledTestVersionTag,
} from "./ScheduledTestLiveMonitorPage.styled";
import { EventsFilter } from "./components/EventsFilter/EventsFilter";
import { LogStatusTag } from "./components/LogStatusTag/LogStatusTag";
import { ScheduledTestDetails } from "./components/ScheduledTestDetails/ScheduledTestDetails";
import { StudentLogsSideModal } from "./components/StudentLogsSideModal/StudentLogsSideModal";

const logStatusOrder: Record<ScheduledTestLogStatus, number> = {
  notStarted: 1,
  termsAccepted: 2,
  testing: 3,
  finished: 4,
};

export const ScheduledTestLiveMonitorPage: React.FC = observer(() => {
  const { toggleModal } = useModal();
  const { getStudentTermByDomain } = useDomainHandler();

  const { t } = useTranslation("scheduled-tests");

  const theme = useContext<ElearningThemeInterface>(ThemeContext);
  const {
    scheduledTest,
    fetchScheduledTest,
    scheduledTestLiveData,
    fetchScheduledTestLiveData,
    updateScheduledTest,
    fetchScheduledTestStatusAndTimeLeft,
    scheduledTestStatusAndTimeLeft,
    setScheduledTest,
    setScheduledTestStatusAndTimeLeft,
    allowStudentsToReEnter,
  } = useScheduledTests();

  const { schoolyearDashboard, fetchSchoolyearDashboard } = useSchoolyear();
  const { enableSchoolyearIntegration } = useFeatureFlags();

  const { scheduledTestId }: ScheduledTestLiveMonitorPageParams = useParams();

  const [searchValue, setSearchValue] = useState<string>("");
  const [activeStudentId, setActiveStudentId] = useState<number | null>(null);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [activeStudentName, setActiveStudentName] = useState<string>("");
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);

  const totalFinishedStudents =
    scheduledTestLiveData?.logs.filter(({ status }) => status === "finished").length ?? 0;

  useEffect(() => {
    enableSchoolyearIntegration &&
      scheduledTest?.schoolyearEnabled &&
      fetchSchoolyearDashboard(Number.parseInt(scheduledTestId, 10));
  }, [
    fetchSchoolyearDashboard,
    scheduledTestId,
    enableSchoolyearIntegration,
    scheduledTest?.schoolyearEnabled,
  ]);

  useEffect(() => {
    if (scheduledTest?.id !== Number.parseInt(scheduledTestId, 10)) {
      fetchScheduledTest(Number.parseInt(scheduledTestId, 10));
    }
  }, [scheduledTest]);

  useEffect(
    () => () => {
      setScheduledTest(null);
    },
    [],
  );

  useEffect(() => {
    if (
      !scheduledTestLiveData ||
      scheduledTestLiveData.scheduledTestId !== Number.parseInt(scheduledTestId, 10)
    ) {
      fetchScheduledTestLiveData(Number.parseInt(scheduledTestId, 10));
    }

    const interval = setInterval(() => {
      if (scheduledTestStatusAndTimeLeft?.status === ScheduledTestTimeLeftStatus.ONGOING) {
        fetchScheduledTestLiveData(Number.parseInt(scheduledTestId, 10));
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [scheduledTestLiveData]);

  useEffect(
    () => () => {
      setScheduledTestStatusAndTimeLeft(null);
    },
    [],
  );

  const onViewStudentLogs = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // use data-student-id attribute so we can use one single callback for all buttons and cache the function
      const studentId = Number.parseInt(event.currentTarget.dataset.studentId as string, 10);
      setActiveStudentId(studentId);
      const studentName = event.currentTarget.dataset.studentName as string;
      setActiveStudentName(studentName);
    },
    [searchValue, scheduledTest, scheduledTestLiveData],
  );

  const isExtraTimeVersion = (testCode: string) =>
    scheduledTest && testCode === scheduledTest.accessCodes.withExtraTime;

  const filterStudentsByValue = (
    logs: ScheduledTestLog[],
  ): Record<string, () => ScheduledTestLog[]> => ({
    all: () => logs,
    possibleFraud: () => logs.filter((log) => log.possibleFraud),
    extraTimeVersion: () => logs.filter((log) => isExtraTimeVersion(log.testCode)),
    termsAccepted: () => logs.filter((log) => log.status === "termsAccepted"),
    testing: () => logs.filter((log) => log.status === "testing"),
    finished: () => logs.filter((log) => log.status === "finished"),
  });

  const statusFilters = useMemo<Record<string, number>>(
    () => ({
      all: scheduledTestLiveData?.logs.length || 0,
      possibleFraud:
        filterStudentsByValue(scheduledTestLiveData?.logs || []).possibleFraud().length || 0,
      extraTimeVersion:
        filterStudentsByValue(scheduledTestLiveData?.logs || []).extraTimeVersion().length || 0,
      termsAccepted:
        filterStudentsByValue(scheduledTestLiveData?.logs || []).termsAccepted().length || 0,
      testing: filterStudentsByValue(scheduledTestLiveData?.logs || []).testing().length || 0,
      finished: filterStudentsByValue(scheduledTestLiveData?.logs || []).finished().length || 0,
    }),
    [scheduledTestLiveData],
  );

  const rowData = useMemo<TableRow[]>(() => {
    if (!scheduledTest || !scheduledTestLiveData) {
      return [];
    }

    const getFullName = ({ firstName, middleName, lastName }: ScheduledTestLog) =>
      !middleName ? `${firstName} ${lastName}` : `${firstName} ${middleName} ${lastName}`;

    let { logs } = scheduledTestLiveData;

    if (searchValue.trim() !== "") {
      logs = logs.filter((log) => {
        // search through student names
        const name = getFullName(log);
        return name.toLowerCase().includes(searchValue.trim().toLowerCase());
      });
    }

    return filterStudentsByValue(logs)
      [selectedFilter]()
      .map((log) => {
        const usedIpAddresses: string = log.usedIpAddresses.join(", ");

        return {
          id: log.accountId,
          highlightColor: log.possibleFraud ? theme.palette.feedback.error._950 : undefined,
          cells: [
            {
              id: `select-${log.accountId}`,
              dataCy: `checkbox-${log.accountId}`,
              content: (
                <Checkbox
                  aria-label={t("liveMonitor.students.table.selectOne", {
                    studentName: getFullName(log),
                  })}
                  checked={selectedStudents.includes(log.accountId)}
                  disabled={log.status !== "finished"}
                  id={log.accountId.toString()}
                  onChange={(e) => {
                    if (e.target.checked) setSelectedStudents([...selectedStudents, log.accountId]);
                    else setSelectedStudents(selectedStudents.filter((x) => x !== log.accountId));
                  }}
                />
              ),
            },
            {
              id: `name-${log.accountId}`,
              content: getFullName(log),
            },
            {
              id: `ip-address-${log.accountId}`,
              content: usedIpAddresses,
              sortValue: usedIpAddresses,
            },
            {
              id: `status-${log.accountId}`,
              content: <LogStatusTag status={log.status} />,
              sortValue: logStatusOrder[log.status] ?? 0,
            },
            {
              id: `fraud-${log.accountId}`,
              content: log.possibleFraud ? (
                <Tag boldText fillType="filled" variant="error">
                  {t("liveMonitor.students.table.fraud")}
                </Tag>
              ) : (
                ""
              ),
              sortValue: log.possibleFraud ? 0 : 1,
            },
            {
              id: `version-${log.accountId}`,
              content: (
                <StyledTestVersionTag fillType="filled" variant="default">
                  {!isExtraTimeVersion(log.testCode)
                    ? t("liveMonitor.students.testVersion.default", "Default")
                    : t("liveMonitor.students.testVersion.extraTime", "Additional time")}
                </StyledTestVersionTag>
              ),
            },
            {
              id: `logs-${log.accountId}`,
              content: (
                <StyledIconButton
                  aria-label={t("liveMonitor.students.viewLogs")}
                  data-student-id={log.accountId}
                  data-student-name={getFullName(log)}
                  dataCy="view-student-logs-button"
                  iconName="eyeIcon"
                  isCircle={false}
                  onClick={onViewStudentLogs}
                />
              ),
              centerContent: true,
            },
          ],
        };
      });
  }, [onViewStudentLogs, selectedFilter, selectedStudents]);

  if (!scheduledTest || !scheduledTestLiveData) {
    return <LoadingSpinner isFullScreen />;
  }

  const onChangeTestDuration = (duration: number) => {
    updateScheduledTest(Number.parseInt(scheduledTestId, 10), {
      duration,
      testId: scheduledTest.testId,
      title: scheduledTest.title,
    });
    fetchScheduledTest(Number.parseInt(scheduledTestId, 10));
    fetchScheduledTestStatusAndTimeLeft(scheduledTest.accessCodes.withExtraTime);
    toggleModal(modalIds.updateTestTimeModal);
  };

  const onAllowStudentsToReEnter = () => {
    allowStudentsToReEnter(+scheduledTestId, selectedStudents);
    setSelectedStudents([]);
  };

  const totalStudents = scheduledTestLiveData?.logs.length || 0;

  return (
    <StyledLiveMonitorPage>
      <ScheduledTestDetails
        scheduledTest={scheduledTest}
        onChangeTestDuration={onChangeTestDuration}
      />
      <StyledLiveMonitorStudents>
        <Heading3>
          {t("liveMonitor.students.headingTitle", {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
          {` (${totalStudents})`}
        </Heading3>
        <div>
          <StyledFilterList>
            {Object.keys(statusFilters).map((filter) => (
              <EventsFilter
                key={filter}
                filter={filter}
                isFilterSelected={selectedFilter === filter}
                totalStudents={statusFilters[filter]}
                onSelectFilter={setSelectedFilter}
              />
            ))}
          </StyledFilterList>
          <StyledSearchBoxWrapper>
            <SearchBox value={searchValue} onChange={setSearchValue} />
            {enableSchoolyearIntegration && schoolyearDashboard && (
              <Link
                external
                aria-label={t("liveMonitor.students.schoolyearDashboard")}
                dataCy="live-monitor-schoolyear-dashboard-button"
                to={schoolyearDashboard.url}
                variant="primary"
                size="medium"
                onClick={() => {
                  trackPlatformEvent({
                    module: TEACHER_MODULES.LIVE_MONITORING,
                    itemId: PLATFORM_EVENT_TYPES.SCHOOLYEAR.CLICK_OPEN_DASHBOARD,
                    elementId: "live-monitor-schoolyear-dashboard-button",
                  });
                }}
              >
                <SvgIconReact iconName="externalLinkIcon" size={1} />
                <span>{t("liveMonitor.students.schoolyearDashboard", "Open in Schoolyear")}</span>
              </Link>
            )}
            <Button
              dataCy="allow-students-button"
              disabled={selectedStudents.length === 0}
              variant="primary"
              onClick={onAllowStudentsToReEnter}
            >
              {t("liveMonitor.students.rejoin")}
            </Button>
          </StyledSearchBoxWrapper>
          <StyledTable
            flexStartCellHeaders
            headerCells={[
              {
                id: "select",
                title: "",
                content: (
                  <Checkbox
                    aria-label={t("liveMonitor.students.table.selectAll")}
                    checked={totalFinishedStudents === selectedStudents.length}
                    dataCy="select-all"
                    id="select-all"
                    onChange={(e) => {
                      if (e.target.checked)
                        setSelectedStudents([
                          ...scheduledTestLiveData.logs
                            .filter(({ status }) => status === "finished")
                            .map(({ accountId }) => accountId),
                        ]);
                      else setSelectedStudents([]);
                    }}
                  />
                ),
                sortable: false,
                size: "auto",
              },
              {
                id: "name",
                title: t("liveMonitor.students.table.name"),
                sortable: true,
              },
              {
                id: "ip",
                title: t("liveMonitor.students.table.ipAddress"),
                sortable: true,
              },
              {
                id: "status",
                title: t("liveMonitor.students.table.status"),
                sortable: true,
              },
              {
                id: "fraud",
                title: t("liveMonitor.students.table.fraud"),
                sortable: true,
              },
              {
                id: "version",
                title: t("liveMonitor.students.table.version"),
                sortable: true,
              },
              {
                id: "logs",
                title: t("liveMonitor.students.table.logs"),
                sortable: false,
                size: "auto",
              },
            ]}
            rows={rowData}
          />
        </div>
      </StyledLiveMonitorStudents>
      <StudentLogsSideModal
        closeOnClickOutside
        isOpen={!!activeStudentId}
        scheduledTestId={+scheduledTestId}
        studentId={activeStudentId}
        title={activeStudentName}
        onClose={() => setActiveStudentId(null)}
      />
    </StyledLiveMonitorPage>
  );
});
