import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useDomainHandler } from "hooks/useDomainHandler";
import { useProduct } from "hooks/useProduct";
import { useLanguage } from "hooks/useSelectedLanguage";
import { useWriting } from "hooks/useWriting";

import type { ModuleSubject } from "models/product/ModuleDetails";

import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { DiagnosticTestModal } from "components/DiagnosticTestModal";
import { ImmersiveReaderButton } from "components/ImmersiveReaderButton/ImmersiveReaderButton";
import { ProductType } from "constants/hst-constants";
import { HstProducts } from "constants/hst-constants";
import { modalIds } from "constants/modals-constants";
import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import { useDeterminationTest } from "hooks/useDeterminationTest";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useModal } from "hooks/useModal";
import { useProductProgress } from "hooks/useProductProgress";
import { useProductResults } from "hooks/useProductResults";
import { useTally } from "hooks/useTally";
import {
  StyledInfoTextNoLevelSelected,
  StyledModuleDescription,
  StyledModuleDetailsPage,
  StyledModuleTitle,
} from "./ModuleDetailsPage.styled";
import { AdaptiveSubject } from "./components/AdaptiveSubject/AdaptiveSubject";
import { DiagnosticTestBanner } from "./components/DiagnosticTestBanner/DiagnosticTestBanner";
import { FinishedSubjectModal } from "./components/FinishedSubjectModal/FinishedSubjectModal";
import { RecentProgressSummary } from "./components/RecentProgressSummary/RecentProgressSummary";
import { WritingSubject } from "./components/WritingSubject/WritingSubject";

export interface PathParams {
  productId: string;
  moduleId: string;
}

const IR_TARGET_SELECTOR = "subject-list-immersive-reader";

export const ModuleDetailsPage: React.FC = observer(() => {
  const { t } = useTranslation("homepage");
  const { productId, moduleId }: PathParams = useParams();
  const { isModalOpen, toggleModal } = useModal();
  const { currentLanguage } = useLanguage();
  const { getTeacherTermByDomain } = useDomainHandler();
  const { fetchModuleDetails, moduleDetails, productsList, productDetails } = useProduct();
  const { fetchWritingSubjectsStatus, writingSubjectsStatus } = useWriting();
  const { fetchModuleProgress, moduleSubjectsProgress, productGoalLevel, fetchProductGoalLevel } =
    useProductProgress();
  const { getRecentProgress, recentProgress } = useProductResults();
  const { fetchSkillDeterminationStatus, resetDeterminationStatus } = useDeterminationTest();
  const { showStudentGoalLevelSelector } = useFeatureFlags();

  const {
    setCongratulationsMessage,
    setMixedExercise,
    setCurrentTopic,
    setPreviousTopic,
    setSkillLevelHasLowered,
  } = useAdaptivePractice();
  useTally();

  const goalLevel = productGoalLevel?.goalLevel;

  const shouldShowModal: boolean = useMemo(() => {
    if (moduleDetails && productGoalLevel && +productId === HstProducts.BETER_SCHRIJVEN) {
      // this goalLevel is of type number, the one defined at a bigger scope is of type number | undefined
      const goalLevel = productGoalLevel.goalLevel;
      return moduleDetails.subjects.some((subject: ModuleSubject) => {
        return subject.chapters.some((chapter) =>
          chapter.topics.some(
            (topic) =>
              topic.isTrialTopic &&
              goalLevel >= topic.minSkillLevel &&
              goalLevel <= topic.maxSkillLevel,
          ),
        );
      });
    }
    return true;
  }, [productId, productGoalLevel, moduleDetails]);

  useEffect(() => {
    if (moduleSubjectsProgress.length > 0 && moduleDetails) {
      const anySubjectIncomplete = moduleSubjectsProgress.some(({ progress }) => progress !== 100);
      if (shouldShowModal && moduleDetails.hasDiagnosticTest && !anySubjectIncomplete) {
        // Attempt to toggle modal if it's not open yet (we don't want it to close automatically)
        if (!isModalOpen(modalIds.diagnosticTestModal)) {
          toggleModal(modalIds.diagnosticTestModal);
        }
      }
    }
  }, [shouldShowModal, moduleDetails, moduleSubjectsProgress]);

  useEffect(() => {
    setCongratulationsMessage("none");
    setCurrentTopic(null);
    setPreviousTopic(null);
    setMixedExercise(false);
    setSkillLevelHasLowered(null);
  }, []);

  const isAdaptiveSubject = (subject: ModuleSubject) => subject.type === "adaptive";
  const isWritingSubject = (subject: ModuleSubject) => subject.type === "writing";

  const subjectList = moduleDetails?.subjects || [];

  const hasAdaptiveSubject = subjectList.some(isAdaptiveSubject);
  const hasWritingSubject = subjectList.some(isWritingSubject);
  const isMissingWritingExercises = writingSubjectsStatus?.length === 0;

  useEffect(() => {
    if (productId && moduleId) {
      fetchModuleDetails(+productId, +moduleId);
    }
  }, [productId, moduleId, currentLanguage]);

  useEffect(() => {
    if (productId && hasAdaptiveSubject) {
      getRecentProgress(+productId);
      fetchProductGoalLevel(+productId);
    }
  }, [productId, hasAdaptiveSubject]);

  useEffect(() => {
    if (hasAdaptiveSubject) {
      fetchModuleProgress(+productId, +moduleId);
    }
  }, [productId, moduleId, productGoalLevel, hasAdaptiveSubject]);

  useEffect(() => {
    if (subjectList.length > 0 && hasWritingSubject) {
      fetchWritingSubjectsStatus(+productId, +moduleId);
    }
  }, [subjectList, productId, moduleId, hasWritingSubject, currentLanguage]);

  useEffect(() => {
    fetchSkillDeterminationStatus(+productId, +moduleId);
    return () => resetDeterminationStatus();
  }, [productId, moduleId]);

  // Goal level should be set by teacher in business products if feature is enabled
  const isGoalLevelSetByTeacher = useMemo(() => {
    const isBusinessProduct = productDetails?.type === ProductType.BUSINESS;
    return showStudentGoalLevelSelector && isBusinessProduct;
  }, [productDetails, showStudentGoalLevelSelector]);

  const isOnlyWritingSubjects = useMemo(() => {
    return subjectList.every(isWritingSubject);
  }, [subjectList]);

  // Do not request selection of goal level if no adaptive subjects are available in page
  const forceGoalLevelSelection = hasAdaptiveSubject && !goalLevel;
  const isUserABusinessUser = productsList?.every(
    (product) => product.type === ProductType.BUSINESS,
  );

  return (
    <StyledModuleDetailsPage className={IR_TARGET_SELECTOR}>
      {shouldShowModal && <DiagnosticTestModal />}
      <FinishedSubjectModal />
      <section>
        <StyledModuleTitle data-cy="module-title">
          {isOnlyWritingSubjects && (
            <ImmersiveReaderButton
              targetIndex={0}
              targetSelector={`.${IR_TARGET_SELECTOR}`}
              trackingValue={{ module: STUDENT_MODULES.BASIC_PROGRESS }}
            />
          )}
          {moduleDetails?.title}
        </StyledModuleTitle>
        {moduleDetails?.description && (
          <StyledModuleDescription
            dataCy="module-description"
            rawHtml={moduleDetails?.description}
          />
        )}
      </section>
      {hasAdaptiveSubject && <RecentProgressSummary recentProgress={recentProgress} />}
      {/* Show warning if user needs to set their initial goal level */}
      {forceGoalLevelSelection && (
        <StyledInfoTextNoLevelSelected dataCy="select-goal-level-info">
          {!isGoalLevelSetByTeacher
            ? t("missingGoalLevelPrompt.setBySelf")
            : t("missingGoalLevelPrompt.setByTeacher", { teacherTerm: getTeacherTermByDomain() })}
        </StyledInfoTextNoLevelSelected>
      )}
      {/* Show warning if there's no content available for writing subjects */}
      {hasWritingSubject && isMissingWritingExercises && (
        <StyledInfoTextNoLevelSelected dataCy="missing-writing-exercises-info">
          {t("missingWritingExercisesPrompt")}
        </StyledInfoTextNoLevelSelected>
      )}
      {/* List of subjects within the module */}
      <section data-cy="module-subjects-container">
        {subjectList.map((subject: ModuleSubject) =>
          isAdaptiveSubject(subject) ? (
            <AdaptiveSubject
              key={subject.id}
              moduleId={+moduleId}
              productId={+productId}
              subject={subject}
            />
          ) : (
            <WritingSubject key={subject.id} subject={subject} />
          ),
        )}
      </section>
      {moduleDetails?.hasDiagnosticTest && goalLevel && !isUserABusinessUser && (
        <section data-cy="diagnostic-test-banner-container">
          <DiagnosticTestBanner currentGoalLevel={goalLevel} />
        </section>
      )}
    </StyledModuleDetailsPage>
  );
});
