import { ROLES } from "@infinitaslearning/module-he-common";

// User types assigned to each role in Metabase
type MetabaseUserTypes =
  | "administrator"
  | "assessor"
  | "author"
  | "editor"
  | "instructor"
  | "learner"
  | "manager"
  | "support"
  | "user"
  | undefined;

export const USER_TYPE_PER_ROLE: Record<string, MetabaseUserTypes> = {
  [ROLES.ADMIN]: "administrator",
  [ROLES.STUDENT]: "learner",
  [ROLES.TEACHER]: "instructor",
};
