import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading1 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";

export const StyledViewTheory = styled.div`
  background-color: ${(props: ThemeProps) => props.theme.palette.neutral.white};
  border-radius: 0.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1060;

  button {
    position: absolute;
    right: 4.375rem;
    top: 2.5rem;
    @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.tablet}) {
      top: 4rem;
      right: 2rem;
      background-color: transparent;
    }
  }

  .subjects {
    background-color: ${(props: ThemeProps) => props.theme.palette.neutral._950};
    padding: 2rem 4rem;
    width: 30%;

    @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.tablet}) {
      width: 100%;
      padding: 1rem 2rem;
    }
  }

  .theory {
    padding: 2rem 20% 4rem 20%;

    @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.tablet}) {
      padding: 1rem 2rem;
    }

    .breadcrumbs {
      color: ${(props: ThemeProps) => props.theme.palette.neutral._600};

      > * {
        display: inline;
      }

      .selectedChapter {
        color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
      }
    }

    h1 {
      font-size: ${(props: ThemeProps) => props.theme.font.size._48};

      @media (max-width: ${(props: ThemeProps) => props.theme.breakpoints.tablet}) {
        font-size: ${(props: ThemeProps) => props.theme.font.size._24};
      }
    }
  }
`;

export const StyledContentContainer = styled(CssFlex)`
  height: auto;
  flex-grow: 1;
  overflow-y: auto;
`;

export const StyledViewTheoryHeading = styled(Heading1)`
  margin-top: 1rem;
  margin-bottom: 2rem;
  > div {
    display: inline;
  }
`;
