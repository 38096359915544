import { Card } from "components/Card/Card";
import { Divider } from "components/Divider/Divider";
import type { ScheduledTestExerciseAvgResult } from "models/exam/ScheduledTest";
import type { CustomTickProps } from "pages/TeacherEnvironment/TeacherDashboard/subpages/WritingProgress/components/SubjectOverview/WritingSubjectsResultsOverview";
import type React from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  type BarProps,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useTheme } from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface ExerciseSummaryProps {
  exerciseResults: ScheduledTestExerciseAvgResult[];
}

export const ExerciseSummary: React.FC<ExerciseSummaryProps> = ({ exerciseResults = [] }) => {
  const theme = useTheme() as ElearningThemeInterface;
  const { t } = useTranslation("teacher-dashboard");
  const exerciseResultsForChart = exerciseResults.map((exercise) => ({
    subject: exercise.title,
    answered: exercise.score,
    failed: 100 - exercise.score,
  }));

  const CustomBarShape = (props: BarProps) => {
    const { fill, x, y, width, height } = props;
    return (
      <rect
        data-cy="results-per-exercise-row"
        fill={fill}
        height={height}
        width={width}
        x={x}
        y={y}
      />
    );
  };
  const CustomTick = (props: CustomTickProps) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text fill={theme.palette.text.secondary}>{payload.value}</text>
      </g>
    );
  };

  return (
    <Card layout={{ mt: 2, p: 2 }}>
      <Heading3 fontWeight="normal" mb="2rem">
        {t("ownedScheduledTestResults.details.resultsPerExercise.title", "Results")}
      </Heading3>
      <Divider />
      <div data-cy="exercises-per-subject-graph">
        <ResponsiveContainer debounce={300} height={300} width="100%">
          <BarChart
            barSize={30}
            data={exerciseResultsForChart}
            layout="vertical"
            margin={{
              top: 5,
              right: 50,
              left: 120,
              bottom: 5,
            }}
          >
            <XAxis hide type="number" />
            <YAxis
              axisLine={false}
              dataKey="subject"
              tick={(props: CustomTickProps) => (
                <CustomTick payload={props.payload} x={props.x} y={props.y} />
              )}
              tickLine={false}
              tickMargin={170}
              type="category"
            />
            <Bar
              dataKey="answered"
              fill={theme.palette.feedback.success._800}
              shape={(props: BarProps) => <CustomBarShape {...props} />}
              stackId="a"
            >
              <LabelList
                dataKey="answered"
                fill={theme.palette.text.secondary}
                formatter={(value: number) => (value > 0 ? `${value}%` : "")}
                position="insideRight"
              />
            </Bar>
            <Bar dataKey="failed" fill={theme.palette.feedback.error.brand} stackId="a" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};
