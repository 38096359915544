import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { QuestionStatus } from "constants/exam-constants";
import { modalIds } from "constants/modals-constants";

import { useAnswer } from "hooks/useAnswer";
import { useModal } from "hooks/useModal";

import { EmbeddedHtml } from "components/EmbeddedHtml";
import { commonSanitizeOptions } from "layouts/QuestionManager/QuestionManager.constants";
import type { UserAnswer } from "models/exam/Exam";
import type { QuestionsManagerPathParams } from "../../QuestionManager.model";
import type {
  ProgressiveTextQuestionAnswer,
  ProgressiveTextQuestionProps,
} from "./ProgressiveTextQuestion.model";
import {
  StyledProgressiveTextQuestion,
  StyledTextAreaInput,
} from "./ProgressiveTextQuestion.styled";
import { ReflectiveQuestionsModal } from "./components/ReflectiveQuestionsModal";

export const ProgressiveTextQuestion: React.FC<ProgressiveTextQuestionProps> = ({
  answerFeedbackComponent: answerComponent,
  evaluateAnswer,
  question,
  status,
  reflectionQuestions = [],
  prevAnswerValues,
}) => {
  const { t } = useTranslation("skill-determination-test");
  const { t: tCommon } = useTranslation("common");
  const { toggleModal } = useModal();
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const setExerciseIdValue = !exerciseId ? undefined : +exerciseId;
  const prevCurrentAnswers =
    prevAnswerValues && prevAnswerValues.length > 0
      ? prevAnswerValues?.map(({ value }) => value as string)
      : "" || [];
  const [currentAnswer, setCurrentAnswer] =
    useState<ProgressiveTextQuestionAnswer>(prevCurrentAnswers);

  const { isEvaluatingAnswer, setAnswerButtonProps } = useAnswer();

  // Set configuration for answer button
  useEffect(() => {
    setAnswerButtonProps({
      label: t("button.checkAnswer.label", "Check answer"),
      isDisabled: currentAnswer.length === 0,
      isHidden: status !== QuestionStatus.INITIAL,
      isLoading: isEvaluatingAnswer || false,
      onClick: () => {
        toggleModal(modalIds.progressiveTextQuestionModal);
      },
    });
  }, [isEvaluatingAnswer, currentAnswer, status, toggleModal]);

  const onSubmitAnswers = () => {
    const userAnswer: UserAnswer[] = [{ order: 0, value: currentAnswer.toString() }];
    evaluateAnswer?.(+productId, question.id, userAnswer, setExerciseIdValue);
  };

  return (
    <StyledProgressiveTextQuestion status={status}>
      <ReflectiveQuestionsModal
        reflectionQuestions={reflectionQuestions}
        onCompleteReflectionQuestions={onSubmitAnswers}
      />
      <EmbeddedHtml rawHtml={question.content} sanitizeOptions={commonSanitizeOptions} />
      <div className="answer-container">
        <StyledTextAreaInput
          aria-label={tCommon("questionType.progressiveText.textarea.label")}
          dataCy="text-area-progress-question"
          id="text-area-question"
          name="text-area-question"
          readOnly={status !== QuestionStatus.INITIAL}
          value={currentAnswer}
          onChange={(e) => setCurrentAnswer(e.target.value)}
        />
      </div>
      {answerComponent}
    </StyledProgressiveTextQuestion>
  );
};
