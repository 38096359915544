import { Button } from "components/Button";
import { ListNavigationItem } from "components/ListNavigationItem/ListNavigationItem";
import styled from "styled-components/macro";
import { Heading3, Heading4Css } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { calculateRem } from "styles/mixins/calculateRem";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledTestOverview = styled.div`
  margin-bottom: 4rem;
  a {
    margin-top: ${calculateRem(16)};
    :last-child {
      margin-bottom: ${calculateRem(64)};
    }
  }
`;

export const StyledTestOverviewHeader = styled(Heading3)`
  margin: ${calculateRem(24)} 0 ${calculateRem(56)} 0;
  text-align: center;
`;

export const StyledTestOverviewParagraph = styled(ParagraphLarge)<Props>`
  color: ${(props: Props) => props.theme.palette.neutral._400};
  line-height: ${calculateRem(24)};
  padding: 0 ${calculateRem(95)};
  text-align: center;
`;

export const StyledTestOverviewButton = styled(Button)<Props>`
  ${Heading4Css};
  color: ${(props: Props) => props.theme.palette.neutral.white};
  float: right;
  margin-top: ${calculateRem(64)};
  max-width: fit-content;
`;

export const StyledTestOverviewListItem = styled(ListNavigationItem)<Props>``;

export const StyledButtonContainer = styled.div`
  margin-top: 2rem;
  text-align: left;
`;
