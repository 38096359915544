import styled from "styled-components/macro";
import { calculateRem, calculateRems } from "styles/mixins/calculateRem";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledNoOwnedHomePage = styled.main<Props>`
  min-height: calc(100vh - ${calculateRem(312)});
  background-color: rgb(255, 255, 255);
  border: none;
  padding: ${calculateRems([64, 64, 16, 32])};
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: ${calculateRem(62)};

  & > section {
    margin-top: ${calculateRem(40)};
  }
`;

export const StyledContainer = styled.div`
  max-width: 70rem;
  text-align: center;
`;

export const StyledImage = styled.img`
  margin: ${calculateRems([42, 0, 24, 0])};
  max-width: ${calculateRem(249)};
`;
