import type React from "react";
import type { ReactNode } from "react";

import type { IconComponentName } from "components/SvgIconReact/IconNames";

import { StyledContainer, StyledIcon, StyledLabel, StyledValue } from "./TestMetadata.styled";

interface TestMetadataProps {
  label: string;
  value: string | number | ReactNode;
  iconName?: IconComponentName;
  dataCy?: string;
}

export const TestMetadata: React.FC<TestMetadataProps> = ({
  iconName = "infoIcon",
  label,
  value,
  dataCy,
}) => (
  <StyledContainer data-cy={dataCy}>
    <StyledIcon iconName={iconName} size={1.5} />
    <StyledLabel>{label}</StyledLabel>
    <StyledValue>{value}</StyledValue>
  </StyledContainer>
);
