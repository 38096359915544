import type React from "react";

import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { SvgIconReact } from "components/SvgIconReact";
import type { IconComponentName } from "components/SvgIconReact/IconNames";

import type { ComparedSessionTopicProgress } from "../../AdaptivePracticeSessionResultsPage.model";
import { StyledTopicContainer } from "../../AdaptivePracticeSessionResultsPage.styled";

import { SanitizedHtml } from "components/SanitizedHtml";
import { CssGrid } from "styles/helpers/layout";

interface PracticedTopicItemProps {
  iconName: IconComponentName;
  goalLevel?: number;
  goalLevelName?: string;
  practicedTopicStats: ComparedSessionTopicProgress;
}

export const PracticedTopicItem: React.FC<PracticedTopicItemProps> = ({
  iconName,
  goalLevel,
  goalLevelName,
  practicedTopicStats: topic,
}) => {
  const exceedsCurrentGoal =
    topic.updatedSkillLevel && goalLevel && topic.updatedSkillLevel > goalLevel;

  // Depending on whether real progress is higher than goal, we show either real progress or 100% of goal
  const progressToDisplay = exceedsCurrentGoal ? 100 : topic.updatedProgress;
  const skillLevelToDisplay = exceedsCurrentGoal ? goalLevelName : topic.updatedSkillLevelLabel;

  return (
    <StyledTopicContainer key={topic.id} data-cy={`practice-session-topic-${topic.id}`}>
      <SanitizedHtml dirtyHtml={topic.title} />
      <CssGrid alignItems="center" gap={1} gridTemplateColumns="auto 1fr 5rem">
        <SvgIconReact iconName={iconName} />
        <ProgressBar currentValue={progressToDisplay || 0} />
        {progressToDisplay}% {skillLevelToDisplay}
      </CssGrid>
    </StyledTopicContainer>
  );
};
