import type React from "react";
import { Trans, useTranslation } from "react-i18next";
import type { ExamProgressBarProps } from "./ExamProgressBar.model";
import { StyleProgress, StyleProgressBar } from "./ExamProgressBar.styled";

export const ExamProgressBar: React.FC<ExamProgressBarProps> = ({
  totalQuestionCount,
  selectedQuestionIndex,
}) => {
  const { t } = useTranslation("exam");
  const currentProgress = ((selectedQuestionIndex + 1) * 100) / totalQuestionCount;
  const allQuestionsFinished = selectedQuestionIndex === totalQuestionCount;
  return (
    <StyleProgress>
      {!allQuestionsFinished && (
        <>
          <p>
            <Trans i18nKey="header.progress" t={t}>
              {"Question "}
              <span data-cy="current-question-number">
                {{ current: selectedQuestionIndex + 1 }}
              </span>
              {" of "}
              <span data-cy="total-questions">{{ total: totalQuestionCount }}</span>
            </Trans>
          </p>
          <StyleProgressBar barWidth={currentProgress}>
            <span />
          </StyleProgressBar>
        </>
      )}
    </StyleProgress>
  );
};
