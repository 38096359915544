import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { Heading2, Heading3, Heading4 } from "styles/elements/Headings";
import { CssFlex, CssIcon } from "styles/helpers/layout";

export const StyledHeaderContainer = styled.section<ThemeProps>`
  padding: 1rem 0rem;
`;

export const StyledSummary = styled(CssFlex)`
  border: solid 1px #ada689;
  border-radius: 4px;
  background: transparent;
  padding: 1.5rem;
  margin-bottom: 3rem;

  ${CssIcon} {
    color: #767d83;
  }
`;

export const StyledSummaryTitle = styled(Heading3)`
  margin-top: 0;
  font-size: 1.3rem;
  font-weight: bold;
`;

export const StyledSummarySubtitle = styled(Heading4)`
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  font-weight: bold;
  border-top: 1px #eeeeee solid;
  padding-top: 1.7rem;
  margin-top: 1.7rem;
`;

export const StyledTopicDetailHeader = styled(Heading2)`
  margin: 1.5rem 0;
`;
