import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { AUTH_PAGES_ROUTES, TEST_ENVIRONMENT_ROUTES } from "constants/routes";
import { useFullScreen } from "hooks/useFullScreen";
import { AuthRoute } from "layouts/AuthRoute";
import { observer } from "mobx-react";
import { type FC, useEffect } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { Redirect, Switch, useParams } from "react-router-dom";

import { ExercisePage, ReviewExercisePage, TestCompletedPage, TestOverviewPage } from "./subpages";
import type { TestPagePathParams } from "./subpages/TestOverviewPage/TestOverviewPage.model";
import {
  StyledFullScreen,
  StyledFullScreenImmersiveReaderContainer,
} from "./subpages/TestOverviewPage/TestOverviewPage.styled";

export const TestPage: FC = observer(() => {
  const { code }: TestPagePathParams = useParams();
  const fsHandle = useFullScreenHandle();
  const { setFullScreen } = useFullScreen();

  useEffect(() => {
    setFullScreen(fsHandle);
  }, [fsHandle]);

  return (
    <StyledFullScreen handle={fsHandle}>
      <StyledFullScreenImmersiveReaderContainer id="irFullScreenContainer" />
      <Switch>
        <AuthRoute
          exact
          component={TestOverviewPage}
          path={TEST_ENVIRONMENT_ROUTES.TEST_DETAILS_PAGE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={ExercisePage}
          path={TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_PAGE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={ReviewExercisePage}
          path={TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_REVIEW_PAGE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={TestCompletedPage}
          path={TEST_ENVIRONMENT_ROUTES.TEST_COMPLETED_PAGE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        {/* --- Default path --- */}
        <Redirect
          to={buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_DETAILS_PAGE, { code })}
        />
      </Switch>
    </StyledFullScreen>
  );
});
