import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledSingleSelectQuestion = styled.div<Props>`
  display: flex;
  align-items: center;
  flex-direction: column;

  // IDEA: add font size from theme here

  .question-wrapper {
    text-align: center;

    .opdracht {
      text-align: left;
      color: ${(props: Props) => props.theme.palette.neutral.black};
      font-weight: ${(props: Props) => props.theme.font.weight.bold};
      font-size: ${(props: Props) => props.theme.font.size._24};
    }

    p {
      color: ${(props: Props) => props.theme.palette.neutral.black};
    }
  }

  ul {
    padding: 0;
    list-style: none;
    counter-reset: answer-counter;

    min-width: 25rem;

    li > div {
      position: relative;
      counter-increment: answer-counter;

      outline: none;
      border: solid 2px;
      margin-bottom: 1rem;

      ::before {
        content: counter(answer-counter);
        color: ${(props: Props) => props.theme.palette.neutral.black};
        background: ${(props: Props) => props.theme.palette.neutral.white};
        border-radius: ${(props: Props) => props.theme.borderRadius.rounded};

        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        bottom: 0.5rem;
        width: 2.5rem;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      padding: 1rem;
      cursor: pointer;
      font-weight: bold;
      text-align: center;
      padding-left: 3rem;
      box-sizing: border-box;
      font-weight: ${(props: Props) => props.theme.font.weight.normal};
      color: ${(props: Props) => props.theme.palette.neutral.black};
      background: ${(props: Props) => props.theme.palette.neutral._950};
      border-radius: ${(props: Props) => props.theme.borderRadius.rounded};
      border-color: ${(props: Props) => props.theme.palette.neutral._900};

      > div {
        padding: 0 1rem;
      }

      &.selected {
        font-weight: ${(props: Props) => props.theme.font.weight.bold};
      }

      &.disabled {
        pointer-events: none;
      }

      &.success {
        background: ${(props: Props) => props.theme.palette.feedback.success._950};
        border-color: ${(props: Props) => props.theme.palette.feedback.success._800};
      }

      &.error {
        background: ${(props: Props) => props.theme.palette.primary._900};
        border-color: ${(props: Props) => props.theme.palette.feedback.error.brand};
      }

      &.submitted {
        background: ${(props: Props) => props.theme.palette.secondary._950};
        border-color: ${(props: Props) => props.theme.palette.secondary.brand};
      }
    }
  }
`;
