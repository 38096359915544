import styled from "styled-components/macro";
import { Heading1, Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { calculateRem } from "styles/mixins/mixins";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledTestCompletedMessage = styled.div<Props>`
  margin: auto;
  display: grid;
  justify-items: center;
  max-width: ${calculateRem(640)};
  padding: 1rem;
`;

export const StyledButtonsSection = styled.section<Props>`
  display: grid;
  grid-auto-flow: column;
  gap: 2rem;
`;

export const StyledParagraphFinishedQuestion = styled(ParagraphLarge)<Props>`
  margin-top: 2rem;
  line-height: 150%;
  color: ${(props: Props) => props.theme.palette.neutral.black};
  text-align: center;
`;

export const StyledHeaderFinishedQuestion = styled(Heading3)<Props>`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledTitle = styled(Heading1)<Props>`
  line-height: 4rem;
  text-align: center;
  margin-bottom: 2rem;
`;
