import type React from "react";

import type LoadingSpinnerProps from "./LoadingSpinner.model";
import { LoadingSpinnerWrapper } from "./LoadingSpinner.styled";

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 80,
  color,
  stroke,
  isFullScreen = false,
}) => (
  <LoadingSpinnerWrapper
    $isFullScreen={isFullScreen}
    color={color}
    data-cy="loading-spinner"
    size={size}
    stroke={stroke ?? Math.ceil(size / 10)}
  >
    <div />
  </LoadingSpinnerWrapper>
);
