import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { DEFAULT_DEBOUNCE_TIME } from "constants/misc-constants";

import { useDomainHandler } from "hooks/useDomainHandler";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useProfile } from "hooks/useProfile";

import { SearchBox } from "components/SearchBox";
import { SvgIconReact } from "components/SvgIconReact";
import { SearchResultsList } from "./components/SearchResultsList";

import {
  StyledButton,
  StyledSearchBoxContainer,
  StyledSearchBoxWithLabel,
} from "./StudentSearchBox.styled";

export const StudentSearchBox: React.FC = observer(() => {
  const { t } = useTranslation("teacher-dashboard");
  const { getStudentTermByDomain } = useDomainHandler();

  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const elRef = useRef<HTMLDivElement>(null);

  const {
    fetchUsersByEducationsForTeacher,
    usersByEducations: searchResults,
    setUsersByEducations,
    isLoading,
  } = useProfile();

  // Define debounced callback method to avoid too many calls to endpoint while user is typing in search box
  const fetchMatchingUsers = useDebouncedCallback(
    () => fetchUsersByEducationsForTeacher(searchValue.trim()),
    DEFAULT_DEBOUNCE_TIME,
  );

  useEffect(() => {
    if (searchValue.trim()) {
      fetchMatchingUsers();
    } else {
      setUsersByEducations([]);
    }
  }, [searchValue]);

  useOnClickOutside({
    ref: elRef,
    handler: () => setIsOpen(false),
  });

  return (
    <StyledSearchBoxWithLabel>
      <label htmlFor="student-search-box">
        {t("ownedScheduledTestResults.overview.filters.studentSearchBox.label", {
          studentTerm: getStudentTermByDomain(),
        })}
      </label>
      <StyledSearchBoxContainer>
        <SearchBox
          ref={elRef}
          dataCy="student-search-box"
          name="student-search-box"
          placeholder={t(
            "ownedScheduledTestResults.overview.filters.studentSearchBox.placeholder",
            {
              studentTerm: getStudentTermByDomain(),
            },
          )}
          value={searchValue}
          onChange={(value) => setSearchValue(value)}
          onFocus={() => setIsOpen(true)}
        />
        {isOpen && (
          <>
            <StyledButton
              dataCy="student-search-results-close-button"
              onClick={() => setIsOpen(false)}
            >
              <SvgIconReact iconName="closeX" />
            </StyledButton>
            <SearchResultsList
              isActive={!!searchValue && !!searchResults}
              isLoading={isLoading}
              results={searchResults}
            />
          </>
        )}
      </StyledSearchBoxContainer>
    </StyledSearchBoxWithLabel>
  );
});
