import { getYear } from "date-fns";
import type React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { FooterConstants } from "footer-utils";

import { Link } from "components/Link";

import { ParagraphSmall } from "styles/elements/Texts";
import { StyledBottomBar } from "./CopyrightBottomBar.styled";

interface CopyrightBottomBarProps {
  className?: string;
}

export const CopyrightBottomBar: React.FC<CopyrightBottomBarProps> = ({ className }) => {
  const { t } = useTranslation("common");
  const { genericLinks } = FooterConstants();

  const today = new Date();

  return (
    <StyledBottomBar
      alignItems="center"
      className={className}
      columnGap={3}
      justifyContent="space-between"
    >
      {/* Copyright notice */}
      <ParagraphSmall>
        {t("footer.copyrightNotice", { currentYear: getYear(today) })}
      </ParagraphSmall>
      {/* Miscellaneous links */}
      <span>
        {genericLinks.links.map((link, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: Can't tell me what to do
          <Fragment key={index}>
            {index > 0 && " | "}
            <Link external={link.external} size="xs" to={link.url}>
              {!link.staticLabel ? t(link.label) : link.label}
            </Link>
          </Fragment>
        ))}
      </span>
    </StyledBottomBar>
  );
};
