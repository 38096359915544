import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ADMIN_ENVIRONMENT_ROUTES } from "constants/routes";
import { useAdmin } from "hooks/useAdmin";
import { Heading2, Heading3 } from "styles/elements/Headings";

import { UserAdminBreadcrumbs } from "../../components/UserAdminBreadcrumbs/UserAdminBreadcrumbs";
import { NewUserForm } from "./components/NewUserForm/NewUserForm";

import { StyledNewUser, StyledNewUserPage } from "./NewUserPage.styled";

export const NewUserPage: React.FC = observer(() => {
  const { t } = useTranslation("admin-environment");
  const { createUserSuccess, setCreateUserSuccess } = useAdmin();
  const history = useHistory();

  useEffect(() => {
    if (createUserSuccess) {
      history.push(ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE);
    }

    return () => {
      setCreateUserSuccess(false);
    };
  }, [createUserSuccess, history, setCreateUserSuccess]);

  return (
    <StyledNewUserPage>
      <UserAdminBreadcrumbs
        currentPageLabel={t("newAccount.breadcrumb.newAccount", "New user")}
        currentPagePath={ADMIN_ENVIRONMENT_ROUTES.USER_DETAILS_PAGE}
        previousAdminUrl={ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE}
        previousRoutePath={ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE}
        previousRoutePathLabel={t("accountDetails.breadcrumb.accountsList", "Accounts list")}
        previousText={t("accountDetails.backButton.label", "Previous")}
      />
      <StyledNewUser>
        <Heading2>{t("accountDetails.newUser.header", "New user")}</Heading2>
        <Heading3>{t("accountDetails.updateUser.title", "User Profile:")}</Heading3>
        <NewUserForm />
      </StyledNewUser>
    </StyledNewUserPage>
  );
});
