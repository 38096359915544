import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { AchievementToast } from "components/AchievementToast";
import { useAdaptivePractice } from "hooks/useAdaptivePractice";
import type { SkillLevel } from "models/adaptive-practice/AdaptivePractice";
import { StyledMessage } from "./SkillLevelChangeMessage.styled";

export const SkillLevelChangeMessage: React.FC = observer(() => {
  const [levelHistory, setLevelHistory] = useState<SkillLevel[]>([]);
  const { t } = useTranslation("adaptive-test");

  const { currentTopic, skillLevelHasLowered } = useAdaptivePractice();

  const currentLevel = currentTopic?.currentUserLevel;

  useEffect(() => {
    if (
      currentLevel?.id &&
      currentLevel.label !== levelHistory?.at(levelHistory.length - 1)?.label
    ) {
      setLevelHistory([...levelHistory, currentLevel]);
    }
  }, [currentLevel?.id]);

  // Should not display message unless we're still on the same topic and the level has changed
  if (skillLevelHasLowered === null) {
    return null;
  }

  if (skillLevelHasLowered) {
    return (
      <StyledMessage data-cy="skill-level-down-message" type="warning">
        <Trans i18nKey="progressInfo.levelDown" t={t}>
          {"Whoops! Let's try some easier "}
          <span data-cy="current-skill-level">{{ newLevel: currentLevel?.label }}</span>
          {" questions again."}
        </Trans>
      </StyledMessage>
    );
  }

  return (
    <AchievementToast
      showConfetti
      dataCy="skill-level-up-message"
      duration={3000}
      title={
        <Trans i18nKey="progressInfo.levelUpMessage.title" t={t}>
          {"Nice job! You finished "}
          <span data-cy="prev-skill-level">
            {{ prevLevel: levelHistory[levelHistory.length - 2]?.label }}
          </span>
        </Trans>
      }
    >
      <Trans i18nKey="progressInfo.levelUpMessage.subtitle" t={t}>
        {"Let's continue with "}
        <span data-cy="current-skill-level">{{ newLevel: currentLevel?.label }}</span>
        {" questions"}
      </Trans>
    </AchievementToast>
  );
});
