import { EmbeddedHtml } from "components/EmbeddedHtml";
import { SvgIconReact } from "components/SvgIconReact";
import type React from "react";
import type { ListNavigationProps } from "./ListNavigationItem.model";
import { StyledItemContainer, StyledTitle } from "./ListNavigationItem.styled";

export const ListNavigationItem: React.FC<ListNavigationProps> = ({
  className,
  title,
  dataCy,
  disabled = false,
  href,
  extraData,
  hideArrowIcon = false,
}) => (
  <StyledItemContainer $disabled={disabled} className={className} data-cy={dataCy} to={href}>
    <StyledTitle>
      <EmbeddedHtml rawHtml={title} />
    </StyledTitle>
    {extraData}
    {!hideArrowIcon && <SvgIconReact iconName="rightArrow" />}
  </StyledItemContainer>
);
