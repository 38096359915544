import {
  type GenericValidator,
  validateObject,
} from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/validate";
import { useEffect } from "react";
import type { FormValues, ValidationError } from "../steps/FormStep.model";

interface UseFormUpdateProps<T extends GenericValidator> {
  validationSchema: T;
  onFormUpdate: (values: FormValues, errors: ValidationError[]) => void;
  values: FormValues;
}

export const useFormUpdate = <T extends GenericValidator>({
  validationSchema,
  onFormUpdate,
  values,
}: UseFormUpdateProps<T>): void => {
  useEffect(() => {
    const handleUpdate = async () => {
      const errors = await validateObject<T>(validationSchema, values);
      onFormUpdate(values, errors);
    };
    handleUpdate();
  }, [values]);
};
