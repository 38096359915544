import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import {
  AUTH_PAGES_ROUTES,
  PRODUCT_PAGES_ROUTES,
  TEACHER_HOME_PAGE_ROUTES,
  TEACHER_INSIGHT_PAGE_ROUTES,
  TEACHER_SCHEDULED_TEST_PAGE_ROUTES,
  TEACHER_SUPPORT_PAGE_ROUTES,
} from "constants/routes";
import { useCurrentPathMatch } from "hooks/useCurrentPathMatch";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProduct } from "hooks/useProduct";
import { AuthRoute } from "layouts/AuthRoute";

import { UnderConstructionMessage } from "components/UnderConstructionMessage/UnderConstructionMessage";
import { TeacherPageLayout } from "../components/TeacherPageLayout/TeacherPageLayout";

import { LoadingSpinner } from "components/LoadingSpinner";
import { useSelectedProductId } from "hooks/useSelectedProductId";
import {
  BasicProgressForAccountPage,
  BasicProgressOverviewPage,
  DiagnosticPage,
  EditAccountPage,
  EditGroup,
  Groups,
  LiveMonitorOverviewPage,
  NewGroup,
  OwnedScheduledTestResultsOverviewPage,
  ScheduledTestRequestPage,
  ScheduledTestResultsForAccountPage,
  ScheduledTestResultsStudent,
  ScheduledTestsPage,
  StudentLicense,
  TeacherHomePage,
  WritingResultsForAccount,
  WritingResultsOverviewPage,
} from "./subpages";
import { TeacherInstructions } from "./subpages/TeacherInstructions/TeacherInstructions";

// Pages where the side menu should not appear
const routesWithoutSideMenu: string[] = [
  TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_FOR_STUDENT,
];

export const TeacherDashboard: React.FC = observer(() => {
  const { productsList, productDetails, fetchProducts, fetchProductDetails } = useProduct();
  const selectedProductId = useSelectedProductId();
  const { currentRouteMatchesAny } = useCurrentPathMatch();
  const {
    showDiagnosticTestProgressOverview,
    showTeacherNavigationDashboardOverview,
    showTeacherInstructions,
  } = useFeatureFlags();

  useEffect(() => {
    if (!productsList) {
      fetchProducts();
    }
  }, [productsList, fetchProducts]);

  // We need to make sure the product details are always loaded, as part of the menu items
  // are dependant on this data (ex.: teacher instructions or basic progress)
  useEffect(() => {
    if (selectedProductId && productDetails?.id !== +selectedProductId) {
      fetchProductDetails(+selectedProductId);
    }
  }, [productDetails, selectedProductId]);

  const buildURLWithProductIdOrFallbackToNoLicensesRoute = (path: string) =>
    selectedProductId
      ? buildUrlWithPathParams(path, { productId: `${+selectedProductId}` })
      : PRODUCT_PAGES_ROUTES.PRODUCT_LIST;

  if (!productsList) {
    return <LoadingSpinner isFullScreen />;
  }

  const shouldShowSideNav = !currentRouteMatchesAny(routesWithoutSideMenu);

  return (
    <TeacherPageLayout withMenu={shouldShowSideNav}>
      <Switch>
        <AuthRoute
          exact
          component={() => (
            <Redirect
              to={
                showTeacherNavigationDashboardOverview
                  ? buildURLWithProductIdOrFallbackToNoLicensesRoute(
                      TEACHER_HOME_PAGE_ROUTES.DASHBOARD,
                    )
                  : buildURLWithProductIdOrFallbackToNoLicensesRoute(
                      TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW,
                    )
              }
            />
          )}
          path={TEACHER_HOME_PAGE_ROUTES.HOME_PAGE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        <AuthRoute
          exact
          component={
            showTeacherNavigationDashboardOverview
              ? TeacherHomePage
              : () => (
                  <Redirect
                    to={buildURLWithProductIdOrFallbackToNoLicensesRoute(
                      TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW,
                    )}
                  />
                )
          }
          path={TEACHER_HOME_PAGE_ROUTES.DASHBOARD}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        <AuthRoute
          component={StudentLicense}
          path={TEACHER_HOME_PAGE_ROUTES.STUDENT_LICENSE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        {/* --- Insights --- */}
        <AuthRoute
          exact
          component={BasicProgressOverviewPage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={BasicProgressForAccountPage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={EditAccountPage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.EDIT_ACCOUNT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={UnderConstructionMessage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.ACTIVITY}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={WritingResultsOverviewPage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.WRITING_SKILLS}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={WritingResultsForAccount}
          path={TEACHER_INSIGHT_PAGE_ROUTES.WRITING_RESULTS_FOR_ACCOUNT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={showDiagnosticTestProgressOverview ? DiagnosticPage : UnderConstructionMessage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.DIAGNOSTIC_TEST}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        {/* --- Testing --- */}
        <AuthRoute
          exact
          component={ScheduledTestsPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_OVERVIEW}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={ScheduledTestRequestPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_REQUEST}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={ScheduledTestRequestPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_EDIT_REQUEST}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={LiveMonitorOverviewPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR_OVERVIEW}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={OwnedScheduledTestResultsOverviewPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_OVERVIEW}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        <AuthRoute
          exact
          component={ScheduledTestResultsStudent}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_FOR_STUDENT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        <AuthRoute
          exact
          component={ScheduledTestResultsForAccountPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_RESULTS_FOR_STUDENT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        {/* --- Support --- */}
        <AuthRoute
          exact
          component={UnderConstructionMessage}
          path={TEACHER_SUPPORT_PAGE_ROUTES.GUIDES}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={UnderConstructionMessage}
          path={TEACHER_SUPPORT_PAGE_ROUTES.CONTACT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        {/* --- Group Management --- */}
        <AuthRoute
          exact
          component={Groups}
          path={TEACHER_HOME_PAGE_ROUTES.GROUPS}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={NewGroup}
          path={TEACHER_HOME_PAGE_ROUTES.NEW_GROUP}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={EditGroup}
          path={TEACHER_HOME_PAGE_ROUTES.EDIT_GROUP}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        {/* --- Teacher Instructions --- */}
        {showTeacherInstructions && (
          <AuthRoute
            exact
            component={TeacherInstructions}
            path={TEACHER_HOME_PAGE_ROUTES.TEACHER_INSTRUCTIONS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
        )}
        {/* TODO: Add a 404 page here, currently it renders nothing if no match  */}
      </Switch>
    </TeacherPageLayout>
  );
});
