import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import type React from "react";
import { useEffect, useState } from "react";
import { Heading2 } from "styles/elements/Headings";

import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { useAdmin } from "hooks/useAdmin";
import {
  StyledDialog,
  StyledInnerDialog,
} from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/TestOptionsStep/components/FormSEBListStep/components/SEBKeyUpsertDialog/SEBKeyUpsertDialog.styled";
import { useTranslation } from "react-i18next";
import { ParagraphLarge } from "styles/elements/Texts";
import { StyledModal } from "./TransferResultsModal.styled";

export interface TransferResultsModalProps {
  onSubmit: (selectedTestCodes: string[]) => void;
}

export const TransferResultsModal: React.FC<TransferResultsModalProps> = ({ onSubmit }) => {
  const [selectedTestCodes, setSelectedTestCodes] = useState<string[]>([]);
  const { closeModal, isModalOpen } = useModal();
  const { allParticipatedTestCodes } = useAdmin();
  const { t } = useTranslation("admin-environment");

  const isOpen = isModalOpen(modalIds.transferResultsModal);

  useEffect(() => {
    if (isOpen) setSelectedTestCodes([]);
  }, [isOpen]);

  const onCheckboxChange = (code: string, checked: boolean) => {
    checked
      ? setSelectedTestCodes([...selectedTestCodes, code])
      : setSelectedTestCodes(selectedTestCodes.filter((x) => x !== code));
  };

  return (
    <StyledModal
      closeOnClickOutside
      dataCy="transfer-results-modal"
      isOpen={isModalOpen(modalIds.transferResultsModal)}
      onClose={closeModal}
    >
      <StyledDialog>
        <StyledInnerDialog>
          <Heading2>{t("adminTools.transferResults.modal.title")}</Heading2>
          <ParagraphLarge>{t("adminTools.transferResults.modal.description")}</ParagraphLarge>
          <ul>
            {allParticipatedTestCodes.map((code) => (
              <li key={code}>
                {" "}
                <Checkbox
                  checked={selectedTestCodes.includes(code)}
                  dataCy={code}
                  id={code}
                  label={code}
                  onChange={(e) => onCheckboxChange(code, e.target.checked)}
                />
              </li>
            ))}
          </ul>
          <Button
            dataCy="submit-transfer-results"
            disabled={selectedTestCodes.length === 0}
            variant="primary"
            onClick={() => onSubmit(selectedTestCodes)}
          >
            {t("adminTools.transferResults.save")}
          </Button>
        </StyledInnerDialog>
      </StyledDialog>
    </StyledModal>
  );
};
