import { format } from "date-fns";
import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useReactTooltip } from "hooks/useReactTooltip";

import { getCorrectTestHeader } from "_helpers/tests";
import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";

import type { AvailableLanguage } from "constants/language-constants";
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from "constants/routes";

import { getPassingScoreColor } from "_helpers/colorProgressLevel";
import type { OwnedScheduledTestResultsOverviewItem } from "models/exam/ScheduledTest";

import { LevelDash } from "components/LevelDash";
import type { TableRow } from "components/SortableTable/SortableTable.model";

import { Card } from "components/Card/Card";
import { CssFlex, CssIcon } from "styles/helpers/layout";
import { StyledGroupIconContainer, StyledIcon, StyledTable } from "./OwnedTestResultsTable.styled";

interface OwnedTestResultsTableProps {
  ownedScheduledTestResults: OwnedScheduledTestResultsOverviewItem[];
  language: AvailableLanguage;
  scoreIsAverage: boolean;
  specificStudentId?: number;
  displayOwnershipIcon?: boolean;
}

export const OwnedTestResultsTable: React.FC<OwnedTestResultsTableProps> = ({
  ownedScheduledTestResults = [],
  language,
  scoreIsAverage,
  specificStudentId,
  displayOwnershipIcon = false,
}) => {
  useReactTooltip();
  const { t } = useTranslation("teacher-dashboard");

  const rowData = useMemo<TableRow[]>(() => {
    if (!ownedScheduledTestResults) {
      return [];
    }

    return ownedScheduledTestResults?.map(
      (scheduledTest: OwnedScheduledTestResultsOverviewItem) => {
        const { id: scheduledTestId, avgScore, requiredPassingScore } = scheduledTest;

        const rowLink = !specificStudentId
          ? buildUrlWithPathParams(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS, {
              scheduledTestId,
            })
          : buildUrlWithPathParams(
              TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_FOR_STUDENT,
              {
                scheduledTestId,
                studentId: specificStudentId,
              },
            );

        return {
          id: scheduledTestId,
          link: rowLink,
          cells: [
            ...(displayOwnershipIcon
              ? [
                  {
                    id: `scheduled-test-${scheduledTestId}-ownership`,
                    dataCy: "scheduled-test-ownership",
                    content: (
                      <StyledGroupIconContainer $ownedBySelf={scheduledTest.ownedBySelf}>
                        {scheduledTest.ownedBySelf ? (
                          <CssIcon
                            data-cy="test-owner-icon"
                            data-tip={t("ownedScheduledTestResults.overview.tooltip.createdBySelf")}
                            iconName="fas fa-star"
                          />
                        ) : (
                          <CssIcon
                            data-cy="shared-test-icon"
                            data-tip={t(
                              "ownedScheduledTestResults.overview.tooltip.createdByAnother",
                            )}
                            iconName="fal fa-users"
                          />
                        )}
                      </StyledGroupIconContainer>
                    ),
                    centerContent: true,
                  },
                ]
              : []),
            {
              id: `scheduled-test-${scheduledTestId}-title`,
              dataCy: "scheduled-test-title",
              content: scheduledTest.title,
            },
            {
              id: `scheduled-test-${scheduledTestId}-type`,
              dataCy: "scheduled-test-type",
              content: getCorrectTestHeader(t, scheduledTest.type),
            },
            {
              id: `scheduled-test-${scheduledTestId}-skill-level`,
              dataCy: "scheduled-test-skill-level",
              content: scheduledTest.skillLevel,
            },
            {
              id: `scheduled-test-${scheduledTestId}-start-date`,
              dataCy: "scheduled-test-start-date",
              content: format(new Date(scheduledTest.startDate), "dd-MM-yyyy HH:mm"),
            },
            {
              id: `scheduled-test-${scheduledTestId}-avg-score`,
              dataCy: "scheduled-test-avg-score",
              content: (
                <CssFlex alignItems="center" gap={1}>
                  <LevelDash
                    level={getPassingScoreColor(avgScore, requiredPassingScore).levelType}
                  />
                  <b>{avgScore !== null ? `${avgScore}%` : ""}</b>
                </CssFlex>
              ),
              sortValue: avgScore !== null ? avgScore : -1,
            },
            {
              id: `scheduled-test-${scheduledTestId}-actions`,
              content: (
                <Link
                  aria-label={t("ownedScheduledTestResults.overview.goToSelectedTestResults")}
                  data-cy="see-scheduled-test-results"
                  to={rowLink}
                >
                  <StyledIcon iconName="fas fa-chevron-right" />
                </Link>
              ),
              centerContent: true,
            },
          ],
        };
      },
    );
  }, [ownedScheduledTestResults, language]);

  return (
    <Card layout={{ mt: "2" }}>
      <StyledTable
        headerCells={[
          ...(displayOwnershipIcon
            ? [
                {
                  id: "ownership-status",
                  title: "",
                  size: "auto",
                },
              ]
            : []),
          {
            id: "title",
            title: t("ownedScheduledTestResults.overview.columns.title", "Test"),
            sortable: true,
            size: "4fr",
          },
          {
            id: "type",
            title: t("ownedScheduledTestResults.overview.columns.type", "Type"),
            sortable: true,
            size: "2fr",
          },
          {
            id: "skillLevel",
            title: t("ownedScheduledTestResults.overview.columns.skillLevel", "Level"),
            sortable: true,
            size: "1fr",
          },
          {
            id: "startDate",
            title: t("ownedScheduledTestResults.overview.columns.startDate", "Date and time"),
            sortable: true,
            size: "2.5fr",
          },
          {
            id: "avgScore",
            title: t(
              `ownedScheduledTestResults.overview.columns.${scoreIsAverage ? "avgScore" : "score"}`,
              "Average score",
            ),
            sortable: true,
            size: "1.5fr",
          },
          {
            id: "action-buttons",
            title: "",
            size: "auto",
          },
        ]}
        rows={rowData}
      />
    </Card>
  );
};
