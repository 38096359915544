import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

export const StyledProgressDotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 1rem;

  svg {
    circle {
      transition: all 0.5s ease-out;
      fill: ${(props: ThemeProps) => props.theme.palette.neutral._900};
    }

    &.active {
      circle {
        fill: ${(props: ThemeProps) => props.theme.palette.primary.brand};
      }
    }
  }
`;
