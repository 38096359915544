import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { fontColorMixin } from "styles/mixins/mixins";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type { DOMLinkProps } from "./Link.model";

interface Props extends DOMLinkProps {
  theme: ElearningThemeInterface;
}

export const StyledLink = styled(Link)<Props>`
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  font-weight: ${(props: Props) => props.theme.font.weight.normal};
  transition: all 0.5s ease;
  display: inline-flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  ${(props: Props) =>
    props.$isBlock &&
    css`
      width: 100%;
      display: flex;
    `}

  ${(props: Props) => {
    switch (props.$variant) {
      case "default":
        return css`
          color: ${props.theme.palette.neutral.black};
          &:hover {
            color: ${props.theme.palette.neutral._600};
          }
        `;
      case "primary":
        return css`
          color: ${props.theme.palette.primary.brand};

          &:hover {
            color: ${props.theme.palette.primary._400};
          }
        `;
      case "button":
        return css`
          background-color: ${props.theme.palette.primary.brand};
          padding: 0.6875rem 2.125rem;

          &:hover {
            background-color: ${props.theme.palette.primary._400};
          }
        `;
      case "secondary":
        return css`
          background-color: ${props.theme.palette.neutral._950};
          padding: 0.6875rem 2.125rem;
          &:hover {
            background-color: ${props.theme.palette.neutral._600};
          }
        `;
      case "tertiary":
        return css`
          color: ${props.theme.palette.secondary.brand};
          &:hover {
            color: ${props.theme.palette.secondary.brand};
          }
        `;
      case "border":
        return css`
          border: 2px solid ${props.theme.palette.neutral._950};
          background-color: ${props.theme.palette.neutral.white};
          padding: 0.6875rem 2.125rem;
          &:hover {
            background-color: ${props.theme.palette.neutral._900};
          }
        `;
      case "white":
        return css`
          background-color: ${props.theme.palette.neutral.white};
          padding: 0.6875rem 2.125rem;
          &:hover {
            background-color: ${props.theme.palette.neutral._900};
          }
        `;
      case "underline":
        return css`
          color: ${props.theme.palette.feedback.info.brand};
          text-decoration: underline;
          &:hover {
            color: ${props.theme.palette.feedback.info._300};
          }
        `;
      case "block":
        return css`
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid black;
          width: 20rem;
          height: 5rem;
          text-decoration: none;
          font-weight: ${({ theme }: Props) => theme.font.weight.normal} !important;
          line-height: 1.8rem;
          color: ${({ theme }: Props) => theme.palette.neutral.black} !important;

          &:hover {
            background-color: #f3f5f8 !important;
          }

          &:focus {
            outline: none;
            background-color: ${({ theme }: Props) => theme.palette.neutral._999} !important;
            box-shadow: 0px 0px 4px 2px ${({ theme }: Props) => theme.palette.neutral._900} !important;
          }
        `;

      default:
        return "";
    }
  }}
  ${(props: Props) => props.$fontColor && fontColorMixin}
  ${(props: Props) => {
    switch (props.$borderVariant) {
      case "rounded":
        return css`
          border-radius: ${props.theme.borderRadius.rounded};
        `;
      case "roundedPill":
        return css`
          border-radius: ${props.theme.borderRadius.roundedPill};
        `;
      default:
        return "";
    }
  }}

  ${(props: Props) => {
    switch (props.$size) {
      case "xs":
        return css`
          font-size: ${props.theme.font.size._12};
        `;
      case "small":
        return css`
          font-size: ${props.theme.font.size._14};
        `;
      case "medium":
        return css`
          font-size: ${props.theme.font.size._18};
        `;
      case "large":
        return css`
          font-size: ${props.theme.font.size._24};
        `;
      default:
        return css`
          font-size: ${props.theme.font.size._16};
        `;
    }
  }}

${(props: Props) => {
  if (props.$disabled) {
    switch (props.$variant) {
      case "default":
        return css`
            color: ${props.theme.palette.neutral._900};
            cursor: not-allowed;

            &:hover {
              text-decoration: none;
              color: ${props.theme.palette.neutral._900};
            }
          `;
      case "button":
      case "primary":
      case "secondary":
        return css`
            background: ${props.theme.palette.neutral._900};
            color: ${props.theme.palette.neutral.white};
            cursor: not-allowed;
          `;
      case "border":
        return css`
            background: ${props.theme.palette.neutral.white};
            color: ${props.theme.palette.neutral._900};
            border: 2px solid ${props.theme.palette.neutral._900};
            cursor: not-allowed;
          `;
      case "white":
        return css`
            background: ${props.theme.palette.neutral.white};
            color: ${props.theme.palette.neutral._900};
            cursor: not-allowed;
          `;

      default:
        return "";
    }
  }
  return "";
}}
`;
