import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type {
  SaveTeacherInstructionRequestBody,
  TeacherInstructionsModel,
  TeacherTypesForInstructions,
} from "models/product/TeacherInstructions";
import cookieStorage from "persistence";
import { handlingResponse, logError } from "repositories/utils";

const getTeacherInstructions = async (
  productId: number,
  targetTeacherType?: TeacherTypesForInstructions,
  searchTerm?: string,
  moduleId?: number,
): Promise<TeacherInstructionsModel[]> => {
  try {
    const response = await axios.get(`/api/v1/products/${productId}/teacher-instructions`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: { targetTeacherType, searchTerm, moduleId },
    });
    return handlingResponse<TeacherInstructionsModel[]>([200])(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_TEACHER_INSTRUCTIONS_FAILED,
    });
  }
};
const createTeacherInstructions = async (
  instructionsDetail: SaveTeacherInstructionRequestBody,
): Promise<TeacherInstructionsModel> => {
  try {
    const response = await axios.post("/api/v1/teacher-instructions", instructionsDetail, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<TeacherInstructionsModel>([200])(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.UPDATE_TEACHER_INSTRUCTIONS_FAILED,
    });
  }
};

const updateTeacherInstructions = async (
  instructionsDetail: SaveTeacherInstructionRequestBody,
  instructionId: number,
): Promise<TeacherInstructionsModel> => {
  try {
    const response = await axios.patch(
      `/api/v1/teacher-instructions/${instructionId}`,
      instructionsDetail,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<TeacherInstructionsModel>([200])(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.UPDATE_TEACHER_INSTRUCTIONS_FAILED,
    });
  }
};
const deleteTeacherInstructions = async (
  teacherInstructionDocumentId: number,
): Promise<TeacherInstructionsModel> => {
  try {
    const response = await axios.delete(
      `/api/v1/teacher-instructions/${teacherInstructionDocumentId}`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<TeacherInstructionsModel>([200])(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.DELETE_TEACHER_INSTRUCTIONS_FAILED,
    });
  }
};

export {
  getTeacherInstructions,
  updateTeacherInstructions,
  deleteTeacherInstructions,
  createTeacherInstructions,
};
