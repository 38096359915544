import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import type { ProgressBarVariant } from "./ProgressBar.model";

interface ProgressBarProps extends ThemeProps {
  $variant: ProgressBarVariant;
  $width: number;
}

export const StyledProgressBar = styled.div<ThemeProps>`
  height: 0.5rem;
  min-width: 3rem;
  border-radius: 1rem;
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._900};
`;

export const StyledProgressBarActiveSegment = styled.div<ProgressBarProps>`
  height: 100%;
  width: ${({ $width }: ProgressBarProps) => $width || 0}%;

  border-radius: 1rem;
  background-color: ${({ theme, $variant }: ProgressBarProps) => {
    switch ($variant) {
      case "primary":
        return theme.palette.primary.brand;
      case "success":
        return theme.palette.feedback.success._800;
      case "error":
        return theme.palette.feedback.error.brand;
      default:
        return theme.palette.secondary.brand;
    }
  }};
`;
