import styled from "styled-components/macro";

import { Button } from "components/Button";
import { motion } from "framer-motion";
import type { ThemeProps } from "models/styles/theme";
import type { ModalPosition } from "./Modal.model";

interface Props extends ThemeProps {
  $position: [ModalPosition, ModalPosition];
}

export const StyledOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
`;

const flexPositionFromPosition = (position: ModalPosition) => {
  switch (position) {
    case "top":
      return "flex-start";
    case "bottom":
      return "flex-end";
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
};

export const StyledModalContainer = styled(motion.div)<Props>`
  position: fixed;
  z-index: 1041;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: ${({ $position }: Props) => flexPositionFromPosition($position[1])};
  align-items: ${({ $position }: Props) => flexPositionFromPosition($position[0])};
`;

export const StyledModal = styled.div`
  position: relative;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  border-radius: 4px;
  background: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
`;

export const StyledModalBox = styled.div`
  padding: 2.5rem;
  width: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 3.5rem;
  &.customPasswordModal {
    justify-content: flex-start;
    align-items: baseline;
  }
`;

export const StyledButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._600};
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  &:hover {
    background-color: transparent;
  }
`;
