import styled from "styled-components/macro";

import { TextArea } from "components/TextArea";

import type { StyledProgressiveTextQuestionProps as Props } from "./ProgressiveTextQuestion.model";

export const StyledProgressiveTextQuestion = styled.div<Props>`
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .opdracht {
    text-align: left;
    color: ${(props: Props) => props.theme.palette.neutral.black};
    font-weight: ${(props: Props) => props.theme.font.weight.bold};
    font-size: ${(props: Props) => props.theme.font.size._24};
    line-height: 2rem;
  }

  p {
    color: ${(props: Props) => props.theme.palette.neutral.black};
  }

  .answer-container {
    display: grid;
    grid-gap: 2rem;
    width: 100%;
  }
`;

export const StyledTextAreaInput = styled(TextArea)`
  height: 12rem;
`;
