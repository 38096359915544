import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/Modal";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import { Heading3 } from "styles/elements/Headings";
import {
  StyledSkillDeterminationButton,
  StyledSkillDeterminationParagraph,
} from "./SkillDeterminationModal.styled";

export const MySkillDeterminationModal: React.FC = () => {
  const { t } = useTranslation("skill-determination-test");
  const { toggleModal, closeModal, isModalOpen } = useModal();
  const skillDeterminationModalId = modalIds.skillDeterminationTestModal;
  return (
    <Modal
      wrapInModalBox
      dataCy={skillDeterminationModalId}
      isOpen={isModalOpen(skillDeterminationModalId)}
      showCloseButton={false}
      onClose={closeModal}
    >
      <Heading3>{t("modal.title")}</Heading3>
      <StyledSkillDeterminationParagraph>{t("modal.paragraph")}</StyledSkillDeterminationParagraph>
      <StyledSkillDeterminationButton
        dataCy="determination-modal-button"
        variant="primary"
        onClick={() => toggleModal(skillDeterminationModalId)}
      >
        {t("modal.buttonLabel")}
      </StyledSkillDeterminationButton>
    </Modal>
  );
};

export const SkillDeterminationModal = React.memo(MySkillDeterminationModal);
