import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";

export const StyledFooter = styled.footer<ThemeProps>`
  width: 100%;
  color: ${(props: ThemeProps) => props.theme.palette.neutral._400};
  background: ${(props: ThemeProps) => props.theme.palette.neutral.white};
  padding: 1rem 3rem;
  text-align: center;
  font-size: ${(props: ThemeProps) => props.theme.font.size._12};

  > a {
    font-size: ${(props: ThemeProps) => props.theme.font.size._12};
  }
`;
