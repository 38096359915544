import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { trackPlatformEvent } from "analytics/MetabaseTracker/metabase-tracker";
import { TEACHER_MODULES } from "analytics/constants/pageTypes";
import { PLATFORM_EVENT_TYPES } from "analytics/constants/platformEventTypes";
import { TEACHER_INSIGHT_PAGE_ROUTES, TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from "constants/routes";
import { SkillsSelectorType } from "constants/teacher-constants";
import { useDomainHandler } from "hooks/useDomainHandler";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import type { BasicProgressForAccountPagePathParams } from "../../BasicProgressForAccount.model";
import {
  StyledButtonWrapper,
  StyledFontAwesomeIcon,
  StyledHeading4,
  StyledNavigationWrapper,
} from "./StudentNavigation.styled";

export interface StudentNavigationProps {
  studentIds: number[];
  skillType: SkillsSelectorType;
}

type NavigationType = "previous" | "next";

export const StudentNavigation: FC<StudentNavigationProps> = ({ studentIds = [], skillType }) => {
  const history = useHistory();
  const { t } = useTranslation("teacher-dashboard");
  const { productId, accountId }: BasicProgressForAccountPagePathParams = useParams();
  const { getStudentTermByDomain } = useDomainHandler();

  const currentStudentIndex = useMemo(
    () => studentIds.findIndex((studentId) => studentId === +accountId),
    [accountId],
  );

  const onStudentNavigationChanged = (navigationType: NavigationType) => {
    const currIndex = currentStudentIndex;
    const updatedAccountId =
      navigationType === "previous" ? studentIds[currIndex - 1] : studentIds[currIndex + 1];
    let updatedUrl: string | null = null;

    trackPlatformEvent({
      module: TEACHER_MODULES.BASIC_PROGRESS,
      itemId:
        navigationType === "previous"
          ? PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES
              .CLICK_ON_PREVIOUS_STUDENT_BUTTON
          : PLATFORM_EVENT_TYPES.BASIC_PROGRESS_OVERVIEW_EVENT_TYPES.CLICK_ON_NEXT_STUDENT_BUTTON,
      elementId: `${navigationType}-student-button`,
    });

    switch (skillType) {
      case SkillsSelectorType.BASIC_SKILLS:
        updatedUrl = buildUrlWithPathParams(TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT, {
          productId,
          accountId: updatedAccountId,
        });
        break;
      case SkillsSelectorType.WRITING_SKILLS:
        updatedUrl = buildUrlWithPathParams(
          TEACHER_INSIGHT_PAGE_ROUTES.WRITING_RESULTS_FOR_ACCOUNT,
          {
            productId,
            accountId: updatedAccountId,
          },
        );
        break;
      case SkillsSelectorType.SCHEDULED_TEST_RESULTS:
        updatedUrl = buildUrlWithPathParams(
          TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_RESULTS_FOR_STUDENT,
          {
            accountId: updatedAccountId,
          },
        );
        break;
      default:
        break;
    }

    if (updatedUrl) {
      history.replace(updatedUrl);
    }
  };

  return (
    <StyledNavigationWrapper>
      <StyledButtonWrapper>
        {currentStudentIndex > 0 && (
          <>
            <StyledFontAwesomeIcon
              data-cy="previous-student-button"
              iconName="fas fa-chevron-left"
              onClick={() => onStudentNavigationChanged("previous")}
            />
            <StyledHeading4 marginLeft="1rem">
              {t("basicProgress.forAccount.navigation.previousStudent", {
                studentTerm: getStudentTermByDomain(),
              })}
            </StyledHeading4>
          </>
        )}
      </StyledButtonWrapper>
      <StyledButtonWrapper>
        {currentStudentIndex < studentIds.length - 1 && (
          <>
            <StyledHeading4 marginRight="1rem">
              {t("basicProgress.forAccount.navigation.nextStudent", {
                studentTerm: getStudentTermByDomain(),
              })}
            </StyledHeading4>
            <StyledFontAwesomeIcon
              data-cy="next-student-button"
              iconName="fas fa-chevron-right"
              onClick={() => onStudentNavigationChanged("next")}
            />
          </>
        )}
      </StyledButtonWrapper>
    </StyledNavigationWrapper>
  );
};
