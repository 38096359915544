import { StyledButton } from "components/Button/Button.styled";
import { StyledSvgIconReact } from "components/SvgIconReact/SvgIconReact.styled";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading2, Heading3 } from "styles/elements/Headings";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledAlertMessage = styled.div`
  display: grid;
  grid-template-columns: 290px 1fr;
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
`;

export const StyledAlertMessageLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8rem 4rem;
  background-color: ${({ theme }: ThemeProps) => theme.palette.feedback.error._950};
  color: ${({ theme }: ThemeProps) => theme.palette.feedback.error.brand};

  ${Heading3} {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
    line-height: ${calculateRem(32)};
    text-align: center;
    margin-top: 1.5rem;
  }

  ${StyledSvgIconReact} {
    display: block;
  }
`;

export const StyledAlertMessageRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8rem 4rem;
  text-align: center;

  span.error {
    color: ${({ theme }: ThemeProps) => theme.palette.feedback.error.brand};
    font-size: ${(props: ThemeProps) => props.theme.font.size._14};
    line-height: ${calculateRem(24)};
  }

  ${Heading2} {
    line-height: ${calculateRem(40)};
    margin: 1rem;
  }

  ${StyledButton} {
    display: block;
    margin: 2.5rem auto;
  }
`;
