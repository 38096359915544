import i18n from "i18n";
import * as Yup from "yup";

export interface TransferResult {
  sourceEmail: string;
  targetEmail: string;
  testCode: string | undefined;
}

export type Emails = Omit<TransferResult, "testCode">;

export const buildTransferResultsSchema = (): Yup.SchemaOf<TransferResult> =>
  Yup.object({
    sourceEmail: Yup.string().required(
      i18n.t("admin-environment:accountDetails.email.errors.required"),
    ),
    targetEmail: Yup.string().required(
      i18n.t("admin-environment:accountDetails.email.errors.required"),
    ),
    testCode: Yup.string(),
  }).required();
