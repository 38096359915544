import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EmbeddedHtml } from "components/EmbeddedHtml";
import type { Node } from "components/EmbeddedHtml/EmbeddedHtml.model";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import { SanitizedHtml } from "components/SanitizedHtml";
import { SvgIconReact } from "components/SvgIconReact";

import type { ParsedEmail } from "../../EmailQuestion.model";
import { emailPreviewSanitizeOptions } from "./EmailPreview.constants";
import type { EmailPreviewProps } from "./EmailPreview.model";

import { ParagraphLarge } from "styles/elements/Texts";
import { CssButton, CssFlex } from "styles/helpers/layout";
import {
  StyledEmailButtonBar,
  StyledEmailMetadata,
  StyledEmailPreview,
  StyledEmailTitle,
} from "./EmailPreview.styled";

export const EmailPreview: React.FC<EmailPreviewProps> = ({ exercise, questionAnswerText }) => {
  const { t } = useTranslation("writing-exercise");

  const emailTemplateWithHtml = useMemo<ParsedEmail>(() => {
    const emailTemplate = exercise.metadata?.emailTemplate || {};

    const replaceQuestionIds = (sourceText?: string) => {
      if (!sourceText) {
        return "";
      }

      return sourceText.replace(/{{\d+}}/g, (matchingValue: string) => {
        const questionId = matchingValue.slice(2, -2);
        return `<span name="answer-preview" data-question-id="${questionId}"></span>`;
      });
    };

    return {
      body: replaceQuestionIds(emailTemplate.body),
      to: replaceQuestionIds(emailTemplate.to),
      subject: replaceQuestionIds(emailTemplate.subject),
    };
  }, [exercise]);

  const replaceFunction = ({ tagType, attributes }: Node) => {
    if (tagType === "span" && attributes.name === "answer-preview") {
      const questionId = +attributes["data-question-id"];
      return (
        <SanitizedHtml
          dataCy={`email-question-${questionId}-answer-preview`}
          dirtyHtml={questionAnswerText[questionId] || ""}
          tagName="span"
        />
      );
    }
  };

  return (
    <StyledEmailPreview
      gridTemplateRows="auto 1fr auto"
      height="fit-content"
      marginBottom="1rem"
      minHeight="25rem"
      minWidth="35rem"
      rowGap={1}
      width="50%"
    >
      {/* Title bar (UI only) */}
      <StyledEmailTitle alignItems="center" justifyContent="space-between" padding="1rem">
        <ParagraphLarge>{t("composeEmail.preview.title", "Nieuw bericht")}</ParagraphLarge>
        <SvgIconReact iconName="closeX" />
      </StyledEmailTitle>
      {/* Email contents */}
      <CssFlex flexDirection="column" padding="0 1rem" rowGap={1}>
        <StyledEmailMetadata columnGap={2} gridTemplateColumns="1fr 4fr">
          <ParagraphLarge>{t("composeEmail.preview.recipient", "Aan")}</ParagraphLarge>
          <EmbeddedHtml
            dataCy="email-recipient"
            rawHtml={emailTemplateWithHtml.to || ""}
            replaceFunction={replaceFunction}
            sanitizeOptions={emailPreviewSanitizeOptions}
            tagName="div"
          />
        </StyledEmailMetadata>
        <StyledEmailMetadata columnGap={2} gridTemplateColumns="1fr 4fr">
          <ParagraphLarge>{t("composeEmail.preview.subject", "Onderwerp")}</ParagraphLarge>
          <EmbeddedHtml
            dataCy="email-subject"
            rawHtml={emailTemplateWithHtml.subject || ""}
            replaceFunction={replaceFunction}
            sanitizeOptions={emailPreviewSanitizeOptions}
            tagName="div"
          />
        </StyledEmailMetadata>
        <CssFlex data-cy="email-body" flexDirection="column" rowGap={1}>
          <EmbeddedHtml
            rawHtml={emailTemplateWithHtml.body || ""}
            replaceFunction={replaceFunction}
            sanitizeOptions={emailPreviewSanitizeOptions}
            tagName="div"
          />
        </CssFlex>
      </CssFlex>
      {/* Bottom bar with fake email controls (UI only, these are not meant to be interacted with) */}
      <StyledEmailButtonBar alignItems="center" columnGap={1} flexDirection="row" padding="1rem">
        <CssButton disabled padding="0.75rem 1rem" variant="primary">
          <span>{t("composeEmail.preview.send", "Send")}</span>
          <FontAwesomeIcon fixedWidth iconName="fas fa-paper-plane" />
        </CssButton>
        <FontAwesomeIcon fixedWidth iconName="fas fa-font" />
        <FontAwesomeIcon fixedWidth iconName="fas fa-paperclip" />
        <FontAwesomeIcon fixedWidth iconName="fas fa-link" />
        <FontAwesomeIcon fixedWidth iconName="far fa-smile" />
        <FontAwesomeIcon fixedWidth iconName="far fa-image" />
        <FontAwesomeIcon fixedWidth iconName="fas fa-pencil" />
        <FontAwesomeIcon fixedWidth iconName="fas fa-ellipsis-v" />
        <CssFlex flexDirection="row" flexGrow={1} justifyContent="flex-end">
          <FontAwesomeIcon fixedWidth iconName="fas fa-trash" />
        </CssFlex>
      </StyledEmailButtonBar>
    </StyledEmailPreview>
  );
};
