import ReactTimePicker from "react-time-picker";
import styled from "styled-components/macro";
import { errorInputCss } from "styles/helpers/errors";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
  $hasError?: boolean;
}

const paddingHeight = "0.75rem";
const inputLineHeight = "1.4rem";

export const StyledLeftIcon = styled.div`
  color: ${({ theme }: Props) => theme.palette.neutral.black};
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${paddingHeight};
  margin-top: 0.85rem;
  width: ${inputLineHeight};
  height: ${inputLineHeight};
  > svg {
    z-index: 1001;
  }
`;

export const StyledReactTimePicker = styled(ReactTimePicker)<Props>`
  width: 100%;
  .react-time-picker__wrapper {
    padding: ${paddingHeight} 2.7rem;
    border: 1px solid ${({ theme }: Props) => theme.palette.neutral._600};
    ${({ $hasError }) => $hasError && errorInputCss}
    input {
      line-height: 1.4rem;
      color: ${({ theme }: Props) => theme.palette.neutral.black}!important;
    }
  }
`;
