import format from "date-fns/format";
import type React from "react";
import { useTranslation } from "react-i18next";

import { SvgIconReact } from "components/SvgIconReact";
import { Heading3 } from "styles/elements/Headings";

import { Button } from "components/Button";
import { modalIds } from "constants/modals-constants";
import type { ContentAPI } from "generated/types";
import { useModal } from "hooks/useModal";
import { SimulateLoginButton } from "../../../../components/SimulateLoginButton/SimulateLoginButton";
import { ModifyScheduledTestModal } from "../ModifyScheduledTestModal/ModifyScheduledTestModal";
import {
  StyledICardScheduledTest,
  StyledProductFlag,
  StyledTag,
  StyledText,
  StyledTextTime,
  WrapperInline,
} from "./CardScheduledTest.styled";

export const CardScheduledTest: React.FC<ContentAPI.ScheduledTestGroupedData> = ({
  id,
  title,
  date,
  estimatedStudents,
  teacherName,
  teacherLastName,
  code,
  skillLevel,
  duration,
  testType,
  testVersion,
  productId,
  teacherId,
  cumulative,
  reviewStartDate,
  reviewEndDate,
  passingScore,
}) => {
  const { t } = useTranslation("admin-environment");
  const { openModal } = useModal();
  const teacherInformation = teacherName.concat(" ", `${teacherLastName}`);
  const formatedDate = format(new Date(date), "dd-MM-Y");
  const formatedTime = format(new Date(date), "HH:mm:ss");
  const dateAndTime = formatedDate.concat(
    `${t("scheduledTestDateList.card.time", " at ")}`,
    formatedTime,
  );

  return (
    <StyledICardScheduledTest data-cy="card-scheduled-test">
      <ModifyScheduledTestModal id={id} />
      <WrapperInline>
        <Heading3>
          {title}
          <StyledProductFlag productId={productId} />
        </Heading3>
        <StyledTag testType={testType}>{testType}</StyledTag>
      </WrapperInline>

      <WrapperInline alignStart>
        <StyledTextTime>{dateAndTime}</StyledTextTime>
        <StyledText>
          <strong>{t("scheduledTestDateList.card.teacher", "Teacher: ")}</strong>
          {teacherInformation}
        </StyledText>
        <SimulateLoginButton accountId={teacherId} activeStateVariant="default" variant="default" />
      </WrapperInline>

      <StyledText>
        <strong>
          {t("scheduledTestDateList.card.estimatedStudents", "Estimated amount of Students: ")}
        </strong>
        {estimatedStudents}
      </StyledText>
      <WrapperInline>
        <StyledText>
          <strong>{t("scheduledTestDateList.card.skillLevel", "Skill level: ")}</strong>
          {skillLevel}
        </StyledText>
        <StyledText>
          <strong>{t("scheduledTestDateList.card.testVersion", "Test version: ")}</strong>
          {testVersion}
        </StyledText>
      </WrapperInline>
      <WrapperInline>
        <StyledText data-cy="card-scheduled-test-duration">
          <strong>{t("scheduledTestDateList.card.duration", "Duration: ")}</strong>
          {`${duration} min`}
        </StyledText>
        <StyledText data-cy="card-scheduled-test-code" fontColor="tertiary">
          <SvgIconReact iconName="codeIcon" size={2} />
          {code}
        </StyledText>
      </WrapperInline>
      <Button
        dataCy={`modify-scheduled-test-${id}`}
        variant="primary"
        onClick={() =>
          openModal(modalIds.updateScheduledTest, {
            cumulative,
            reviewStartDate,
            reviewEndDate,
            passingScore,
            testCode: code,
            testType,
            id,
          })
        }
      >
        {t("adminTools.scheduledTest.action")}
      </Button>
    </StyledICardScheduledTest>
  );
};
