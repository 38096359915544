import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { TeacherTypesForInstructions } from "constants/hst-constants";

import { useLanguage } from "hooks/useSelectedLanguage";
import { useTeacherInstructions } from "hooks/useTeacherInstructions";

import type { TeacherInstructionsModel } from "models/product/TeacherInstructions";

import { Select } from "components/SelectUI/Select";
import { SvgIconReact } from "components/SvgIconReact";

import { Heading1, Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { Box, CssFlex, CssLabel } from "styles/helpers/layout";

import noResultsImage from "assets/images/no-results.svg";

import { Button } from "components/Button";
import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import type { SelectOptions } from "components/SelectUI/Select.model";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import { useProduct } from "hooks/useProduct";
import { TeacherInstructionsModal } from "../../components/TeacherInstructionsModal/TeacherInstructionsModal";
import {
  StyledActionButton,
  StyledDataContainer,
  StyledHeader,
  StyledSortableTable,
} from "./TeacherInstructions.styled";

interface Module {
  moduleId: number;
  moduleTitle: string;
}

const defaultSelectorOptions: {
  module: string;
  teacherType: string;
  product?: string;
} = {
  module: "allModules",
  teacherType: "allTeacherTypes",
  product: undefined,
};

export const TeacherInstructions: React.FC = observer(() => {
  const { currentLanguage } = useLanguage();
  const { t } = useTranslation("admin-environment");
  const { toggleModal } = useModal();

  const [filteredInstructions, setFilteredUInstructions] = useState<TeacherInstructionsModel[]>([]);
  const [selectedOptions, setSelectedOptions] = useState(defaultSelectorOptions);

  const {
    getTeacherInstructions,
    teacherInstructions,
    deleteTeacherInstructions,
    setSelectedEditTeacherInstruction,
    resetSelectedEditTeacherInstruction,
  } = useTeacherInstructions();
  const { productsList, fetchProducts } = useProduct();

  useEffect(() => {
    if (!productsList) {
      fetchProducts();
    }
  }, [productsList, fetchProducts]);

  // Endpoint should be called whenever we change the app language or we select a new product
  //  (endpoint returns only instructions available in your language)
  useEffect(() => {
    if (selectedOptions.product) {
      getTeacherInstructions(+selectedOptions.product);
    }
  }, [selectedOptions.product, getTeacherInstructions]);

  useEffect(() => {
    if (teacherInstructions) {
      setFilteredUInstructions(teacherInstructions);
    }
  }, [teacherInstructions]);

  // Initialize options for product selector
  const productOptions: SelectOptions[] = useMemo(() => {
    const dropdownOptions =
      productsList?.map(({ id, title }) => ({
        value: id,
        label: title,
      })) || [];

    // Automatically select first product in the list
    setSelectedOptions((prev) => ({ ...prev, product: dropdownOptions[0]?.value.toString() }));

    return dropdownOptions;
  }, [productsList]);

  // Initialize options for module filter
  const moduleOptions: SelectOptions[] = useMemo(() => {
    const uniqueModules: Module[] = [];
    const moduleList = filteredInstructions.reduce<Module[]>((acc, { moduleId, moduleTitle }) => {
      const exists = acc.find((module) => module.moduleId === +moduleId);
      if (!exists) {
        acc.push({ moduleId: +moduleId, moduleTitle });
      }
      return acc;
    }, uniqueModules);
    return [
      { value: "allModules", label: t("teacherInstructions.filters.module.defaultOption") },
      ...moduleList.map(({ moduleTitle }) => ({
        value: moduleTitle,
        label: moduleTitle,
      })),
    ];
  }, [filteredInstructions, currentLanguage]);

  // Initialize options for target teacher type filter
  const teacherTypeOptions: SelectOptions[] = useMemo(
    () => [
      {
        value: "allTeacherTypes",
        label: t("teacherInstructions.filters.teacherType.defaultOption"),
      },
      {
        value: TeacherTypesForInstructions.GENERIC,
        label: t("teacherInstructions.genericTeacher"),
      },
      {
        value: TeacherTypesForInstructions.LANGUAGE,
        label: t("teacherInstructions.languageTeacher"),
      },
    ],
    [currentLanguage],
  );

  const rowData = useMemo(() => {
    const { module, teacherType, product } = selectedOptions;
    const onEditButtonClick = (instruction: TeacherInstructionsModel) => {
      if (product) {
        setSelectedEditTeacherInstruction({ ...instruction, productId: +product });
        toggleModal(modalIds.teacherInstructionsModal);
      }
    };

    const onDeleteButtonClick = (instructionId: number) => {
      deleteTeacherInstructions(instructionId);
    };

    return teacherInstructions
      .filter(
        (instruction) =>
          (module === "allModules" || instruction.moduleTitle === module) &&
          (teacherType === "allTeacherTypes" || instruction.targetTeacherType === teacherType),
      )
      .map((instruction) => ({
        id: instruction.id,
        cells: [
          {
            id: "teacher-instructions-title",
            dataCy: "teacher-instructions-title",
            content: instruction.title,
          },
          {
            id: "teacher-instructions-module",
            dataCy: "teacher-instructions-module",
            content: instruction.moduleTitle,
          },
          {
            id: "teacher-instructions-teacher-type",
            dataCy: "teacher-instructions-teacher-type",
            content:
              instruction.targetTeacherType === TeacherTypesForInstructions.LANGUAGE
                ? t("teacherInstructions.languageTeacher")
                : t("teacherInstructions.genericTeacher"),
          },
          {
            id: "teacher-instructions-manage-options",
            dataCy: "teacher-instructions-manage-options",
            content: (
              <CssFlex flexDirection="row" columnGap={1} margin="0.5rem 0">
                <StyledActionButton
                  aria-label={t("teacherInstructions.previewBtn")}
                  variant="text"
                  onClick={() => window.open(instruction.documentUrl, "_blank", "noreferrer")}
                  dataCy="teacher-instructions-preview"
                >
                  <SvgIconReact iconName="eyeIcon" size={1.5} />
                </StyledActionButton>
                <StyledActionButton
                  aria-label={t("teacherInstructions.editBtn")}
                  variant="text"
                  onClick={() => onEditButtonClick(instruction)}
                  dataCy="teacher-instructions-edit"
                >
                  <SvgIconReact iconName="pencilIcon" size={1.5} />
                </StyledActionButton>
                <StyledActionButton
                  aria-label={t("teacherInstructions.trashBtn")}
                  variant="text"
                  onClick={() => onDeleteButtonClick(instruction.id)}
                  dataCy="teacher-instructions-delete"
                >
                  <SvgIconReact iconName="trashIcon" size={1.5} />
                </StyledActionButton>
              </CssFlex>
            ),
          },
        ],
      }));
  }, [teacherInstructions, selectedOptions, t]);

  const headerCells = [
    {
      id: "name",
      title: t("teacherInstructions.documentName"),
      size: "1.5fr",
    },
    {
      id: "module",
      title: t("teacherInstructions.module"),
      size: "1.5fr",
    },
    {
      id: "teacherType",
      title: t("teacherInstructions.teacherType"),
      size: "1fr",
    },
    {
      id: "manage-options",
      title: "",
      size: "auto",
    },
  ];

  const handleOnChange =
    (type: "module" | "teacherType" | "product") =>
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedOptions((prev) => ({ ...prev, [type]: event.target.value }));
    };

  return (
    <Box width="70rem">
      <Heading1 marginBottom="1rem">{t("teacherInstructions.title", "Docentinstructies")}</Heading1>
      <ParagraphLarge marginBottom="3rem">{t("teacherInstructions.description")}</ParagraphLarge>
      <CssFlex flexDirection="row" alignItems="end" margin="1rem 0" justifyContent="space-between">
        <CssFlex flexDirection="row" alignItems="end" columnGap={1}>
          <Box width="14rem">
            <CssLabel id="product-selector-label">
              {t("teacherInstructions.filters.product.label")}
            </CssLabel>
            <Select
              aria-labelledby="product-selector-label"
              dataCy="product-selector"
              options={productOptions}
              value={selectedOptions.product}
              onChange={handleOnChange("product")}
            />
          </Box>
          <Box width="14rem">
            <CssLabel id="module-selector-label">
              {t("teacherInstructions.filters.module.label")}
            </CssLabel>
            <Select
              aria-labelledby="module-selector-label"
              dataCy="module-selector"
              options={moduleOptions}
              value={selectedOptions.module}
              onChange={handleOnChange("module")}
            />
          </Box>
          <Box width="14rem">
            <CssLabel id="teacher-type-selector-label">
              {t("teacherInstructions.filters.teacherType.label")}
            </CssLabel>
            <Select
              aria-label="teacher-type-selector-label-selector"
              dataCy="teacher-type-selector"
              options={teacherTypeOptions}
              value={selectedOptions.teacherType}
              onChange={handleOnChange("teacherType")}
            />
          </Box>
        </CssFlex>
        <CssFlex>
          <Button
            variant="primary"
            dataCy="create-teacher-intruction-btn"
            disabled={!selectedOptions.product}
            onClick={() => {
              resetSelectedEditTeacherInstruction();
              if (selectedOptions.product) {
                toggleModal(modalIds.teacherInstructionsModal, {
                  productId: selectedOptions.product,
                });
              }
            }}
          >
            <FontAwesomeIcon iconName="fas fa-plus" /> {t("teacherInstructions.submit")}
          </Button>
        </CssFlex>
      </CssFlex>
      {rowData.length > 0 && (
        <StyledDataContainer>
          <StyledHeader>
            <Heading3>{`${t("teacherInstructions.tableTitle")} (${
              teacherInstructions.length
            })`}</Heading3>
          </StyledHeader>

          <StyledSortableTable
            dataCy="teacher-instructions-table"
            headerCells={headerCells}
            rows={rowData}
          />
        </StyledDataContainer>
      )}
      {rowData.length === 0 && (
        <CssFlex
          data-cy="no-results-message"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
          marginTop="3rem"
        >
          <img alt={t("teacherInstructions.noResultsFound")} src={noResultsImage} />
          <ParagraphLarge>{t("teacherInstructions.noResultsFound")}</ParagraphLarge>
        </CssFlex>
      )}
      <TeacherInstructionsModal
        onProductInstructionsSubmit={(productId) => {
          // Automatically change selected product after editing / creating an instruction
          setSelectedOptions((prev) => ({ ...prev, product: productId?.toString() }));
        }}
      />
    </Box>
  );
});
