import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, Legend, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useTheme } from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import { StyledGraphTitle } from "../../PersonalProgressPage.styled";
import { EmptyGraphPlaceholder } from "../EmptyGraphPlaceholder/EmptyGraphPlaceholder";
import { GraphLoading } from "../GraphLoading/GraphLoading";
import type { MultiBarGraphItem } from "./ProgressBySubjectGraph.model";
import type { ProgressBySubjectGraphProps } from "./ProgressBySubjectGraph.model";

export const MyProgressBySubjectGraph: React.FC<ProgressBySubjectGraphProps> = ({
  hasLoadingError,
  productDetails,
  productProgressSubjects,
  productGoalLevel,
  subjects,
  onRenderComplete,
}) => {
  const theme = useTheme() as ElearningThemeInterface;
  const elRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("results");

  const subjectProgressData = useMemo<MultiBarGraphItem[] | null>(() => {
    if (!subjects || !productDetails) {
      return null;
    }
    // filter only results for adaptive subjects
    const productSubjects = productProgressSubjects?.filter((subjectProgress) =>
      subjects.some((s) => s.id === subjectProgress.id),
    );
    return (productSubjects ?? []).map((subjectProgress) => {
      const subject = subjects.find((s) => s.id === subjectProgress.id);
      return {
        subjectLabel: subject?.title as string,
        startLevel: subjectProgress.initialSkillLevel,
        currentLevel: subjectProgress.currentSkillLevel,
      };
    });
  }, [productProgressSubjects, subjects]);

  const yAxisTicks = !productDetails
    ? []
    : Array.from({ length: productDetails.levels.length + 1 }, (_, i) => i);
  const formatTick = (tickItem: number): string => {
    if (!productDetails?.levels) {
      return `${tickItem}`;
    }
    const labels = ["", ...productDetails.levels.map((level) => level.label)];
    return labels[tickItem];
  };
  const activeLevel = productGoalLevel?.goalLevel;
  const noResultsFound = subjectProgressData?.length === 0;
  const loadingProgress = !subjectProgressData;

  useEffect(() => {
    if (noResultsFound && onRenderComplete && elRef.current) {
      onRenderComplete(elRef.current);
    }
  }, [noResultsFound, onRenderComplete]);

  return (
    <div ref={elRef}>
      <StyledGraphTitle data-cy="progress-by-subject-graph-title">
        {t("graphTitle.progressOverTime", "Progress by subject over time")}
      </StyledGraphTitle>
      {(loadingProgress || hasLoadingError) && (
        <GraphLoading
          dataCy="progress-by-subject-graph-loading"
          hasLoadingError={hasLoadingError}
        />
      )}
      {!hasLoadingError && noResultsFound && (
        <EmptyGraphPlaceholder>{t("placeholder.notEnoughGraphData")}</EmptyGraphPlaceholder>
      )}
      {subjectProgressData && !noResultsFound && (
        <div data-cy="progress-by-subject-graph">
          <ResponsiveContainer debounce={300} height={450} width="100%">
            <BarChart
              barSize={30}
              data={subjectProgressData}
              margin={{
                top: 20,
                right: 5,
                left: 5,
                bottom: 5,
              }}
            >
              <XAxis dataKey="subjectLabel" />
              <YAxis tickFormatter={formatTick} ticks={yAxisTicks} />
              <ReferenceLine stroke={theme.palette.feedback.error.brand} y={activeLevel} />
              <Legend />
              <Bar
                dataKey="startLevel"
                fill={theme.palette.neutral._900}
                name={`${t("graph.label.initialLevel", "Start level")}`}
              />
              <Bar
                dataKey="currentLevel"
                fill={theme.palette.secondary.brand}
                name={`${t("graph.label.currentLevel", "Current level")}`}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export const ProgressBySubjectGraph = React.memo(MyProgressBySubjectGraph);
