import type React from "react";
import { Fragment } from "react";

import type {
  ScheduledTestResultList,
  ScheduledTestReviewPeriodTimer,
} from "models/exam/ScheduledTest";

import { Heading3 } from "styles/elements/Headings";
import { ScheduledTestResultsTable } from "../ScheduledTestResultsTable/ScheduledTestResultsTable";

interface ScheduledTestResultsTableProps {
  data: ScheduledTestResultList[];
  timers: ScheduledTestReviewPeriodTimer[];
}

/**
 *  Renders multiple tables with results for all tests the student has participating, grouping
 * them by product.
 */
export const ScheduledTestResultsTableByProduct: React.FC<ScheduledTestResultsTableProps> = ({
  data = [],
  timers = [],
}) => {
  const groupBy = (arr: ScheduledTestResultList[]) =>
    arr.reduce(
      (result: Record<string, ScheduledTestResultList[]>, product: ScheduledTestResultList) => {
        const key = "productName";
        return Object.assign(result, {
          [product[key]]: (result[product[key]] || []).concat(product),
        });
      },
      {},
    );

  const groupedElements = groupBy(data);

  return (
    <>
      {Object.entries(groupedElements).map(([productName]) => (
        <Fragment key={productName}>
          <Heading3 margin={0}>{productName}</Heading3>
          <ScheduledTestResultsTable data={data} timers={timers} />
        </Fragment>
      ))}
    </>
  );
};
