import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useProduct } from "hooks/useProduct";
import { useLanguage } from "hooks/useSelectedLanguage";
import { useWriting } from "hooks/useWriting";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";

import { BackButton } from "components/BackButton";
import { EmbeddedHtml } from "components/EmbeddedHtml";
import { SanitizedHtml } from "components/SanitizedHtml";
import { TheoryRenderer } from "components/TheoryRenderer";
import { contentSanitizeOptions } from "components/TheoryRenderer/TheoryRenderer.constants";

import { Box, CssFlex, CssIcon } from "styles/helpers/layout";
import {
  StyledHeaderContainer,
  StyledSummary,
  StyledSummarySubtitle,
  StyledSummaryTitle,
  StyledTopicDetailHeader,
} from "./TopicDetailsPage.styled";

import { ImmersiveReaderButton } from "components/ImmersiveReaderButton/ImmersiveReaderButton";
import type { TopicDetailsPagePathParams as PathParams } from "./TopicDetailsPage.model";
import { ExerciseSelector, TopicDetailsBreadcrumb, TopicNavigation } from "./components";

const IR_TARGET_SELECTOR = "topic-details-immersive-reader";

export const TopicDetailsPage: React.FC = observer(() => {
  const { t } = useTranslation("writing-module");
  const { productId, subjectId, moduleId, topicId }: PathParams = useParams();

  const { topicDetails: topic, fetchTopicDetails } = useProduct();
  const { fetchTopicExerciseStatus } = useWriting();
  const { currentLanguage } = useLanguage();

  const backButtonRedirectURL = useMemo(
    () =>
      buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_WRITING_MODULE_DETAILS, {
        productId,
        moduleId,
        subjectId,
      }),
    [productId, moduleId, subjectId],
  );

  useEffect(() => {
    fetchTopicDetails(+productId, +topicId);
    fetchTopicExerciseStatus(+productId, +topicId);
  }, [productId, topicId, currentLanguage]);

  const totalQuestions = topic?.exercises?.reduce(
    (total, { questionCount }) => total + questionCount,
    0,
  );

  return !topic ? null : (
    <Box>
      <BackButton
        dataCy="topic-back-btn"
        label={t("topicDetails.button.back.label", "Back")}
        redirectUrl={backButtonRedirectURL}
      />
      <TopicDetailsBreadcrumb />

      <StyledHeaderContainer>
        <ImmersiveReaderButton isBlock targetIndex={0} targetSelector={`.${IR_TARGET_SELECTOR}`} />
        <StyledTopicDetailHeader data-cy="topic-title">
          <SanitizedHtml dirtyHtml={topic.title || ""} />
        </StyledTopicDetailHeader>
      </StyledHeaderContainer>

      <Box className={IR_TARGET_SELECTOR}>
        {/* Summary of the topic including duration and learning goals */}
        <StyledSummary flexDirection="column">
          <StyledSummaryTitle>{t("topicDetails.summaryBox.title")}</StyledSummaryTitle>
          <CssFlex alignItems="center" flexDirection="row" gap={1.5}>
            {!!topic.estimatedDuration && (
              <>
                <CssFlex alignItems="center" flexDirection="row" gap={0.5}>
                  <CssIcon fixedWidth iconName="fas fa-clock" />
                  <span>{t("topicDetails.summaryBox.estimatedDuration")}</span>
                  <b data-cy="topic-estimated-duration">
                    {t("topicDetails.summaryBox.minuteCount", { count: topic.estimatedDuration })}
                  </b>
                </CssFlex>
                <span>|</span>
              </>
            )}
            <CssFlex alignItems="center" flexDirection="row" gap={0.5}>
              <CssIcon fixedWidth iconName="fas fa-tasks" />
              <span>{t("topicDetails.summaryBox.numberOfQuestions")}</span>
              <b data-cy="topic-question-count">
                {t("topicDetails.summaryBox.questionCount", { count: totalQuestions || 0 })}
              </b>
            </CssFlex>
          </CssFlex>
          {topic.learningGoals && (
            <section data-cy="topic-learning-goals">
              <StyledSummarySubtitle>
                {t("topicDetails.summaryBox.learningGoals")}
              </StyledSummarySubtitle>
              <EmbeddedHtml
                rawHtml={topic.learningGoals}
                sanitizeOptions={contentSanitizeOptions}
              />
            </section>
          )}
        </StyledSummary>

        {/* Detailed topic information */}
        <TheoryRenderer content={topic.content} productId={topic.product.id} />
      </Box>
      <ExerciseSelector topic={topic} />

      <TopicNavigation />
    </Box>
  );
});
