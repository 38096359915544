import { ROLES } from "@infinitaslearning/module-he-common";
import i18n from "i18n";
import * as Yup from "yup";

import type { HogeschooltaalMetadata } from "models/admin/Admin";

export interface NewUserFormFields {
  role: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  password?: string;
  metadata: HogeschooltaalMetadata;
}

export const buildNewUserFormSchema = (): Yup.SchemaOf<NewUserFormFields> =>
  Yup.object({
    firstName: Yup.string().required(
      i18n.t("admin-environment:accountDetails.firstName.errors.required"),
    ),
    lastName: Yup.string().required(
      i18n.t("admin-environment:accountDetails.lastName.errors.required"),
    ),
    middleName: Yup.string(),
    role: Yup.string().oneOf([ROLES.TEACHER, ROLES.STUDENT]).required(),
    password: Yup.string(),
    email: Yup.string().required(i18n.t("admin-environment:accountDetails.email.errors.required")),
    metadata: Yup.object({
      studentNumber: Yup.string(),
      educations: Yup.array()
        .of(Yup.string().required())
        .min(1, i18n.t("admin-environment:accountDetails.educations.errors.required"))
        .required(),
      products: Yup.array()
        .of(
          Yup.object({
            id: Yup.number().required(),
            type: Yup.string(),
            validSinceDate: Yup.date().required(),
            validUntilDate: Yup.date().required(),
          }),
        )
        .required(),
    }),
  }).required();
