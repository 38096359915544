import styled from "styled-components/macro";

import type { InfoTooltipProps } from "./InfoTooltip.model";

export const StyledInfoTooltip = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  > * {
    pointer-events: none;
  }
  > svg path {
    fill: ${(props: InfoTooltipProps) => (props.color ? props.color : props.theme?.palette.neutral._500)};
  }
`;
