import { ToastContainer, type ToastContainerProps } from "react-toastify";
import styled from "styled-components/macro";

import { CssIcon } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props extends ToastContainerProps {
  theme: ElearningThemeInterface;
}

// Define custom styling for all toast types supported by the library
// @see https://www.figma.com/design/4ahztxAectHZ7Rjmj8kl9s/HE-%2F-DS-%2F-%F0%9F%9F%A2--%5BWIP%5D--HST-DESIGN-SYSTEM?node-id=6059-960
export const StyledToastNotification = styled(ToastContainer)<Props>`
  padding: 1rem 2rem;
  box-sizing: content-box;
  width: 30rem;
  max-width: 100%;

  .Toastify__toast {
    padding: 1rem 1.5rem;
    border: solid 2px;
    border-radius: 0.5rem;

    color: ${({ theme }: Props) => theme.palette.neutral.black};
    border-color: ${({ theme }: Props) => theme.palette.neutral.black};
    background-color: ${({ theme }: Props) => theme.palette.neutral._950};

    ${CssIcon} {
      line-height: 2;
    }
  }

  .Toastify__toast-body {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    align-items: center;
  }

  .Toastify__close-button {
    display: block;
    margin-left: 1rem;

    svg {
      margin-right: -0.5rem;
      color: ${({ theme }: Props) => theme.palette.neutral._400};
    }
  }

  .Toastify__toast--success {
    border-color: ${({ theme }: Props) => theme.palette.feedback.success.brand};
    background-color: ${({ theme }: Props) => theme.palette.feedback.success._950};

    ${CssIcon} {
      color: ${({ theme }: Props) => theme.palette.feedback.success.brand};
    }
  }

  .Toastify__toast--warning {
    border-color: ${({ theme }: Props) => theme.palette.yellow.brand};
    background-color: ${({ theme }: Props) => theme.palette.yellow._950};

    ${CssIcon} {
      color: ${({ theme }: Props) => theme.palette.yellow.brand};
    }
  }

  .Toastify__toast--error {
    border-color: ${({ theme }: Props) => theme.palette.feedback.error.brand};
    background-color: ${({ theme }: Props) => theme.palette.feedback.error._950};

    ${CssIcon} {
      color: ${({ theme }: Props) => theme.palette.feedback.error.brand};
    }
  }

  .Toastify__toast--info {
    border-color: ${({ theme }: Props) => theme.palette.secondary.brand};
    background-color: ${({ theme }: Props) => theme.palette.secondary._950};

    ${CssIcon} {
      color: ${({ theme }: Props) => theme.palette.secondary.brand};
    }
  }

  .Toastify__toast--dark {
    border-color: ${({ theme }: Props) => theme.palette.neutral.black};
    background-color: ${({ theme }: Props) => theme.palette.neutral._950};

    ${CssIcon} {
      color: ${({ theme }: Props) => theme.palette.neutral.black};
    }
  }
`;
