import type React from "react";
import { useEffect } from "react";

import { ActionMessageModal } from "components/ActionMessage/ActionMessageModal";
import { GroupCard } from "components/GroupCard/GroupCard";
import { IconButton } from "components/IconButton/IconButton";
import { LoadingSpinner } from "components/LoadingSpinner/LoadingSpinner";
import { SvgIconReact } from "components/SvgIconReact";
import { modalIds } from "constants/modals-constants";
import { TEACHER_HOME_PAGE_ROUTES } from "constants/routes";
import { useGroups } from "hooks/useGroups";
import { useModal } from "hooks/useModal";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Heading2 } from "styles/elements/Headings";

import { GroupsWrapper, NewGroup } from "./Groups.styled";

export const Groups: React.FC = observer(() => {
  const { t } = useTranslation(["common", "modal", "groups"]);
  const { toggleModal } = useModal();
  const { teacherGroups, getTeacherGroups, loading, deleteGroup, deletingGroup, setDeletingGroup } =
    useGroups();

  // Fetch list of groups created by the current user
  useEffect(() => {
    getTeacherGroups(true);
  }, []);

  const modalId = modalIds.deleteGroupModal;

  const onDeleteGroup = (evt: React.MouseEvent<HTMLButtonElement>, groupId: number) => {
    evt.stopPropagation();
    setDeletingGroup(groupId);
    toggleModal(modalId);
  };

  const onConfirm = () => {
    deleteGroup();
    toggleModal(modalId);
  };

  const onCancel = () => {
    setDeletingGroup(null);
    toggleModal(modalId);
  };

  return (
    <>
      <Heading2 marginBottom="2rem">
        {t("allGroupsTitle", { ns: "groups" })}
        {teacherGroups.length > 0 ? `(${teacherGroups.length})` : ""}
      </Heading2>
      <GroupsWrapper>
        <NewGroup data-cy="create-new-group-link" to={TEACHER_HOME_PAGE_ROUTES.NEW_GROUP}>
          <SvgIconReact iconName="plusIcon" size={1} />
          <span>{t("addNewGroup", { ns: "groups" })}</span>
        </NewGroup>
        {loading && <LoadingSpinner />}
        {!loading &&
          teacherGroups.length > 0 &&
          teacherGroups.map((group) => (
            <GroupCard
              {...group}
              key={group.id}
              action={
                deletingGroup === group.id ? (
                  <LoadingSpinner size={18} stroke={2} />
                ) : (
                  <IconButton
                    aria-label={t("iconButtonLabel.delete")}
                    dataCy={`delete-group-${group.id}-button`}
                    fontColor="grey"
                    iconName="trashIcon"
                    onClick={(evt) => onDeleteGroup(evt, group.id)}
                  />
                )
              }
            />
          ))}
      </GroupsWrapper>
      <ActionMessageModal
        actionButtonClick={onConfirm}
        actionButtonLabel={t("deleteGroup.actionButtonTitle", { ns: "modal" })}
        cancelButtonClick={onCancel}
        cancelButtonLabel={t("deleteGroup.cancelButtonTitle", { ns: "modal" })}
        iconName="warningExclamationTriangle"
        modalId={modalIds.deleteGroupModal}
        title={t("deleteGroup.title", "Are you sure you want to delete the group?", {
          ns: "modal",
        })}
      />
    </>
  );
});
