import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { STUDENT_MODULES } from "analytics/constants/pageTypes";
import { ImmersiveReaderButton } from "components/ImmersiveReaderButton/ImmersiveReaderButton";
import { ExerciseType, QuestionFlag, QuestionType } from "constants/exam-constants";
import { useAnswer } from "hooks/useAnswer";
import type {
  ExamQuestionWithAnswers,
  ProgressiveDownloadQuestion,
  ProgressiveOverviewQuestion,
} from "models/exam/Exam";
import type { QuestionManagerProps, QuestionsManagerPathParams } from "./QuestionManager.model";
import { StyledQuestion, StyledQuestionManager } from "./QuestionManager.styled";
import { CorrectTextQuestion } from "./components/CorrectTextQuestion/CorrectTextQuestion";
import { DragAndDropQuestion } from "./components/DragAndDropQuestion";
import { EmailQuestion } from "./components/EmailQuestion/EmailQuestion";
import { InputQuestion } from "./components/InputQuestion";
import { MultiInputQuestion } from "./components/MultiInputQuestion";
import { ProgressiveTextQuestion } from "./components/ProgressiveTextQuestion/ProgressiveTextQuestion";
import { ProgressiveDocumentQuestion } from "./components/ProgressiveTextQuestion/components/ProgressiveDocumentQuestion/ProgressiveDocumentQuestion";
import { OverviewQuestion } from "./components/ProgressiveTextQuestion/components/ProgressiveOverviewQuestion/OverviewQuestion";
import { SelectTextQuestion } from "./components/SelectTextQuestion";
import { SingleSelectQuestion } from "./components/SingleSelectQuestion";
import { TextAreaQuestion } from "./components/TextAreaQuestion";
import type { QuestionPropsInterface } from "./interfaces/question";

// IDEA: if we want to track IR usage for specific pages, we'll need to pass a prop from above

const IR_TARGET_SELECTOR = "question-manager-immersive-reader";

export const QuestionManager: React.FC<QuestionManagerProps> = observer(
  ({
    question,
    evaluateAnswer,
    flags,
    hideAnswers = false,
    singleSelectShowLoadingButton = true,
    prevAnswerValues,
    exercise,
  }) => {
    const { t } = useTranslation("common");
    const { questionStatus, questionResult, isEvaluatingAnswer, setQuestionStatus } = useAnswer();

    useEffect(() => {
      setQuestionStatus?.(questionStatus);
    }, [questionStatus]);

    const { productId }: QuestionsManagerPathParams = useParams();
    const getQuestionView = () => {
      const commonProps: QuestionPropsInterface = {
        answerFeedbackComponent: null,
        hideAnswers,
        evaluateAnswer,
        question,
        results: questionResult?.results ?? [],
        status: questionStatus,
        isEvaluatingAnswer,
        prevAnswerValues,
      };

      switch (question.type) {
        case QuestionType.SINGLE_INPUT:
          return <InputQuestion key={question.id} {...commonProps} />;
        case QuestionType.SINGLE_SELECT:
          if (exercise?.type === ExerciseType.COMPOSE_EMAIL) {
            return (
              <EmailQuestion
                key={question.id}
                answerOptions={(question as ExamQuestionWithAnswers).options}
                exercise={exercise}
                showLoadingButton={singleSelectShowLoadingButton}
                {...commonProps}
              />
            );
          }
          return (
            <SingleSelectQuestion
              key={question.id}
              answerOptions={(question as ExamQuestionWithAnswers).options}
              showLoadingButton={singleSelectShowLoadingButton}
              {...commonProps}
            />
          );
        case QuestionType.DRAG:
          return (
            <DragAndDropQuestion
              key={question.id}
              answerOptions={(question as ExamQuestionWithAnswers).options}
              {...commonProps}
            />
          );
        case QuestionType.TEXT_AREA:
          return <TextAreaQuestion key={question.id} {...commonProps} />;
        case QuestionType.MULTIPLE_TEXT_INPUT:
          return <MultiInputQuestion key={question.id} {...commonProps} />;
        case QuestionType.SELECT_TEXT:
          return (
            <SelectTextQuestion
              key={question.id}
              answerOptions={(question as ExamQuestionWithAnswers).options}
              {...commonProps}
            />
          );
        case QuestionType.CORRECT_TEXT:
          return (
            <CorrectTextQuestion
              key={question.id}
              answerCount={question.metadata?.answerCount || 0}
              availableActions={question.metadata?.availableActions}
              isComparingAnswers={flags.includes(QuestionFlag.CORRECT_TEXT_COMPARE_MODE)}
              {...commonProps}
              userAnswers={prevAnswerValues}
            />
          );
        case QuestionType.CORRECT_SENTENCE:
          return (
            <TextAreaQuestion
              key={question.id}
              {...commonProps}
              disableSpellCheck
              ariaLabel={t("questionType.correctSentence.textarea.label")}
              // conditional for avoid issues with determination test
              defaultValue={
                prevAnswerValues && prevAnswerValues.length > 0
                  ? prevAnswerValues.map(({ value }) => value).toString()
                  : question.metadata?.defaultValue
              }
            />
          );
        case QuestionType.PROGRESSIVE_TEXT:
          return (
            <ProgressiveTextQuestion
              key={question.id}
              reflectionQuestions={question.metadata?.reflectionQuestions}
              {...commonProps}
            />
          );
        case QuestionType.PROGRESSIVE_OVERVIEW:
          return (
            <OverviewQuestion
              productId={+productId}
              question={question as ProgressiveOverviewQuestion}
            />
          );
        case QuestionType.PROGRESSIVE_DOCUMENT:
          return <ProgressiveDocumentQuestion question={question as ProgressiveDownloadQuestion} />;
        default:
          return null;
      }
    };

    return (
      <StyledQuestionManager>
        <StyledQuestion
          className={IR_TARGET_SELECTOR}
          data-cy="question-wrapper"
          data-question-id={question.id}
        >
          <ImmersiveReaderButton
            targetIndex={0}
            targetSelector={`.${IR_TARGET_SELECTOR}`}
            trackingValue={{
              module: STUDENT_MODULES.BASIC_PROGRESS,
            }}
          />
          {getQuestionView()}
        </StyledQuestion>
      </StyledQuestionManager>
    );
  },
);
