import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { CssButton, CssFlex } from "styles/helpers/layout";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledButtonWrapper = styled(CssFlex)`
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const StyledButton = styled(CssButton)`
  font-size: ${({ theme }: Props) => theme.font.size._18};
  font-weight: bold;

  &.showTip {
    padding: 0 1rem;
    color: ${({ theme }: Props) => theme.palette.neutral.black};
    &:hover {
      background-color: ${({ theme }: Props) => theme.palette.neutral._950};
    }
    span {
      font-size: ${({ theme }: Props) => theme.font.size._16};
      font-weight: bold;
      margin-left: ${calculateRem(14)};
    }
  }
`;
