import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { Button } from "components/Button";
import { SelectWithErrorMessage } from "components/SelectWithErrorMessage";

export const StyledEducationItem = styled.div`
  display: flex;
`;

export const StyledSelect = styled(SelectWithErrorMessage)`
  width: 410px;
`;

export const StyledButton = styled(Button)`
  border-radius: ${({ theme }: ThemeProps) => theme.borderRadius.roundedPill};
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  font-size: ${({ theme }: ThemeProps) => theme.font.size._18};
  height: 42px;
  margin-left: 20px;
  width: 42px;

  &:hover {
    background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._950};
    color: ${({ theme }: ThemeProps) => theme.palette.feedback.error.brand};
  }
`;
