import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { calculateRem } from "styles/mixins/mixins";

import { Button } from "components/Button";
import { Link } from "components/Link";
import { SvgIconReact } from "components/SvgIconReact";

export const StyledPageLayout = styled.div<ThemeProps>`
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${(props: ThemeProps) => props.theme.palette.neutral._999};
`;

export const StyledHeader = styled.header<ThemeProps>`
  width: 100%;
  color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
  background: ${(props: ThemeProps) => props.theme.palette.neutral.white};
  display: flex;
  padding: 1rem 3rem;
  column-gap: 3rem;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLogo = styled(SvgIconReact)`
  height: 4rem;
  width: 12rem;
`;

export const StyledLoginSection = styled.section<ThemeProps>`
  display: flex;
  column-gap: 2rem;
  align-items: center;
`;

export const StyledButton = styled(Button)<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: bold;
`;

export const StyledFooter = styled.footer<ThemeProps>`
  width: 100%;
  color: ${(props: ThemeProps) => props.theme.palette.neutral._500};
  background: ${(props: ThemeProps) => props.theme.palette.neutral.white};
  padding: 1rem 3rem;
  text-align: center;
  font-size: ${(props: ThemeProps) => props.theme.font.size._12};

  > a {
    font-size: ${(props: ThemeProps) => props.theme.font.size._12};
  }
`;

export const StyledContainer = styled.main<ThemeProps>`
  flex-grow: 1;
  padding: 5rem;
  max-width: 100%;
  width: ${calculateRem(940)};
`;

export const StyledTitle = styled.div<ThemeProps>`
  margin: 0 4.5rem 1.5rem 4.5rem;
`;

export const StyledCard = styled.div<ThemeProps>`
  padding: 2.5rem 4.5rem;
  color: ${(props: ThemeProps) => props.theme.palette.neutral._400};
  background: ${(props: ThemeProps) => props.theme.palette.neutral.white};

  p {
    line-height: 150%;
    color: ${(props: ThemeProps) => props.theme.palette.neutral._400};
  }

  ul {
    margin-top: 1rem;
    padding: 0;
    list-style: none;

    > li {
      line-height: 200%;
      margin-bottom: 0.5rem;

      ::before {
        content: '✓';
        width: fit-content;
        margin-right: 0.5em;
        display: inline-block;
        color: ${(props: ThemeProps) => props.theme.palette.primary.brand};
      }
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledLinkButton = styled(Link)<ThemeProps>`
  display: block;
  margin: 1.5rem 0 1rem 0;

  span {
    color: ${(props: ThemeProps) => props.theme.palette.neutral.white} !important;
  }
`;

export const StyledSeparator = styled.div<ThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2.5rem 0;
  text-transform: uppercase;
  color: ${(props: ThemeProps) => props.theme.palette.neutral._400};

  ::before {
    content: '';
    height: 1px;
    width: 15rem;
    background-color: ${(props: ThemeProps) => props.theme.palette.neutral._500};
    display: block;
    margin-right: 1rem;
  }

  ::after {
    content: '';
    height: 1px;
    width: 15rem;
    background-color: ${(props: ThemeProps) => props.theme.palette.neutral._500};
    display: block;
    margin-left: 1rem;
  }
`;

export const StyledAvailabilityNote = styled.p<ThemeProps>`
  margin: 0;
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
`;
