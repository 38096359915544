import { Button } from "components/Button/Button";
import styled, { css } from "styled-components/macro";
import { Heading4 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";
import { calculateRem, fadeIn } from "styles/mixins/mixins";
import type { StyledDropdownProps as Props } from "./Dropdown.model";

export const StyledDropdown = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledContentList = styled.ul<Props>`
  ${(props: Props) =>
    props.isOpen &&
    css`
      width: ${props.width ? `${props.width}px` : "auto"};
      max-height: 380px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0.5rem 2rem;
      margin: 0;
      opacity: 0;
      animation: ${fadeIn} 0.35s ease-out forwards;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: left;
      background: ${props.theme.palette.neutral.white};
      transition: all 0.35s ease;
      border: 1px solid ${props.theme.palette.neutral._900};
      border-radius: 4px;
      top: ${calculateRem(40)};
      z-index: 100;
    `}

  ${(props: Props) =>
    !props.isOpen &&
    css`
      display: none;
      border: none;
    `}
`;
export const StyledListItem = styled.li<Props>`
  width: ${(props: Props) => (props.width ? `${props.width}px` : "auto")};
  display: flex;
  align-items: center;
  height: ${calculateRem(48)};
  list-style-type: none;
  text-decoration: none;
  margin-left: -2rem;
  padding-left: 2rem;
  margin-right: -2rem;
  padding-right: 2rem;
  background-color: ${(props: Props) => props.selected && props.theme.palette.neutral._999};

  &:hover {
    background-color: ${(props: Props) => (!props.disabled ? props.theme.palette.neutral._999 : "none")};
  }
`;

export const StyledTitle = styled(Heading4)<Props>`
  font-weight: ${(props: Props) => props.theme.font.weight.bold};
  font-size: ${(props: Props) => props.theme.font.size._16};
  color: ${(props: Props) => props.theme.palette.neutral.black};
  margin: 0;
`;

export const StyledHeader = styled(CssFlex)<Props>`
  outline: none;
  cursor: pointer;
  background-color: ${(props: Props) => props.theme.palette.neutral.white};

  &.disabled {
    cursor: not-allowed;
  }
`;

export const StyledButton = styled(Button)<Props>`
  font-weight: ${(props: Props) => props.selected && props.theme.font.weight.bold};
  height: 100%;
  width: 100%;
  text-align: left;
  background: none !important;

  ${(props: Props) =>
    !props.titleView &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  &:disabled {
    color: ${(props: Props) => props.theme.palette.neutral._600};
    cursor: pointer;

    &:hover {
      font-weight: normal;
    }
  }
`;
