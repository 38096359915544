// https://en.wikipedia.org/wiki/MurmurHash
// https://github.com/garycourt/murmurhash-js

const murmurhash2_32_gc = (str: string, seed: number) => {
  let l = str.length;
  let h = seed ^ l;
  let i = 0;
  let k: number;

  while (l >= 4) {
    k =
      (str.charCodeAt(i) & 0xff) |
      ((str.charCodeAt(++i) & 0xff) << 8) |
      ((str.charCodeAt(++i) & 0xff) << 16) |
      ((str.charCodeAt(++i) & 0xff) << 24);

    k = (k & 0xffff) * 0x5bd1e995 + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16);
    k ^= k >>> 24;
    k = (k & 0xffff) * 0x5bd1e995 + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16);

    h = ((h & 0xffff) * 0x5bd1e995 + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16)) ^ k;

    l -= 4;
    ++i;
  }

  switch (l) {
    case 3:
      h ^= (str.charCodeAt(i + 2) & 0xff) << 16;
      return;
    case 2:
      h ^= (str.charCodeAt(i + 1) & 0xff) << 8;
      return;
    case 1:
      h ^= str.charCodeAt(i) & 0xff;
      h = (h & 0xffff) * 0x5bd1e995 + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16);
  }

  h ^= h >>> 13;
  h = (h & 0xffff) * 0x5bd1e995 + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16);
  h ^= h >>> 15;

  return h >>> 0;
};

/**
 *  This method will assign a bucket to a given hash value. MurmurHash algorythm will
 * be used to classify the input value into a group.
 *
 * @param {string} str - Input string, which will contain the hash to classify into a bucket
 * @param {number} buckets - Amount of buckets to choose from
 * @returns {number} Assigned bucket, which will be a value in the range: [0, buckets - 1]
 */
export const getBucket = (str: string, buckets: number): number => {
  const hash = murmurhash2_32_gc(str, 0x9747b28c);
  const bucket = (hash ? hash : 0) % buckets;
  return bucket;
};
