import { ROLES } from "@infinitaslearning/module-he-common";
import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { modalIds } from "constants/modals-constants";
import { useAdmin } from "hooks/useAdmin";
import { useModal } from "hooks/useModal";
import { useProfile } from "hooks/useProfile";
import { useReactTooltip } from "hooks/useReactTooltip";
import useRequest from "hooks/user/useRequest";

import { Header } from "components/Header";
import { SvgIconReact } from "components/SvgIconReact";

import { SimulateLoginButton } from "../SimulateLoginButton/SimulateLoginButton";
import { StyledUserData } from "./AdminHeader.styled";

export const AdminHeader: React.FC = observer(() => {
  useReactTooltip();
  const { t } = useTranslation("admin-environment");
  const { accountDetails } = useAdmin();
  const { toggleModal } = useModal();
  const { fetchUserDetails, userDetails, updateUserDetails } = useProfile();
  const { logout } = useRequest();

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const onToggleSideMenu = () => {
    toggleModal(modalIds.modalSideMenu);
  };

  return (
    <Header
      toggleSideMenu={onToggleSideMenu}
      updateUserDetails={updateUserDetails}
      userDetails={userDetails}
      onLogout={logout}
    >
      {accountDetails.registrationId && accountDetails.role === ROLES.TEACHER && (
        <SimulateLoginButton
          accountId={accountDetails.registrationId}
          activeStateVariant="border"
          variant="primary"
        />
      )}
      {userDetails && (
        <StyledUserData>
          <SvgIconReact iconName="faceIcon" size={1.5} />
          <span>{userDetails.firstName}</span>
        </StyledUserData>
      )}
      <ReactTooltip
        getContent={() =>
          t(
            "header.loginAsTeacher.copy.tooltip",
            "Enter the login link in a different browser or incognito window to login as this teacher",
          )
        }
        id="login-as-teacher-tooltip"
        place="bottom"
      />
    </Header>
  );
});
