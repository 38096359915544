import styled from "styled-components/macro";

import { TextArea } from "components/TextArea";

import type { StyledTextAreaQuestionProps as Props } from "./TextAreaQuestion.model";

export const StyledTextAreaQuestion = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .opdracht {
    color: ${(props: Props) => props.theme.palette.neutral.black};
    font-weight: ${(props: Props) => props.theme.font.weight.bold};
    font-size: ${(props: Props) => props.theme.font.size._24};
  }

  p {
    color: ${(props: Props) => props.theme.palette.neutral.black};
  }

  .answer-container {
    display: grid;
    grid-gap: 2rem;
    width: 100%;
    margin-top: 1rem;
  }
`;

export const StyledTextAreaInput = styled(TextArea)`
  height: 12rem;
`;
