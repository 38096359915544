import type React from "react";
import { useTheme } from "styled-components/macro";
import { ParagraphLarge } from "styles/elements/Texts";
import { CssFlex } from "styles/helpers/layout";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import type { RadioButtonProps } from "./RadioButton.model";
import { StyledCheckmark, StyledLabel } from "./RadioButton.styled";

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  description,
  disabled = false,
  bulletColor = "secondary",
  bulletStyle = "thick",
  dataCy,
  ...inputProps
}) => {
  const hogesTheme = useTheme() as ElearningThemeInterface;

  return (
    <StyledLabel
      $bulletColor={bulletColor}
      $bulletStyle={bulletStyle}
      data-cy={dataCy}
      disabled={disabled}
    >
      <CssFlex alignContent="center" alignItems="center" marginRight={2}>
        <input disabled={disabled} {...inputProps} data-cy="input-field" type="radio" />
        <StyledCheckmark disabled={disabled} />
      </CssFlex>
      <CssFlex alignItems="flex-start" flexDirection="column">
        <ParagraphLarge data-cy="radio-button-title" fontWeight={hogesTheme.font.weight.bold}>
          {label}
        </ParagraphLarge>
        <ParagraphLarge
          color={hogesTheme.palette.neutral._500}
          data-cy="radio-button-description"
          fontStyle="italic"
          textAlign="initial"
        >
          {description}
        </ParagraphLarge>
      </CssFlex>
    </StyledLabel>
  );
};
