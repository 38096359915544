import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

import { CssFlex, CssProgressBar } from "styles/helpers/layout";

export const StyledProgressBarWrapper = styled(CssFlex)<Props>`
  font-size: ${(props: Props) => props.theme.font.size._14};

  ${CssProgressBar} {
    flex-grow: 1;
  }
`;
