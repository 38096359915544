import axios, { type AxiosError, type AxiosResponse } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import type {
  Education,
  ProvideAccessRequest,
  ProvideAccessResponse,
  RegistrationDetails,
  RequestAccessRequest,
  RequestAccessResponse,
  School,
  SchoolType,
  UpdateAccountUserResponse,
} from "models/auth/Login";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type {
  StudentAccountUpdate,
  Survey,
  UpdatableUserFields,
  UpdateStudentAccountResponse,
  User,
  UserByEducations,
  UserUpdate,
} from "models/user/User";
import cookieStorage, { type ROLE } from "persistence";
import { handlingResponse, logError } from "repositories/utils";

const getUserAccountDetails = async (): Promise<User> => {
  try {
    const result = await axios.get("/api/accounts/me", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<User>([200], "Error getting account details")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_USER_ACCOUNT_DETAILS_FAILED });
  }
};

const requestAccess = async (payload: RequestAccessRequest): Promise<RequestAccessResponse> => {
  try {
    const result = await axios.post("/api/access", payload);
    return handlingResponse<RequestAccessResponse>(
      [200],
      "Success status requesting access",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>, false);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_USER_ACCOUNT_DETAILS_FAILED });
  }
};

const provideAccess = async (payload: ProvideAccessRequest): Promise<ProvideAccessResponse> => {
  try {
    const result = await axios.post("/api/access/verify", payload);
    return handlingResponse<ProvideAccessResponse>([200], "Error getting account details")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_USER_ACCOUNT_DETAILS_FAILED });
  }
};

// Retrieve list of schools for login form options
const getSchoolList = async (type?: SchoolType): Promise<School[]> => {
  try {
    const schoolList = await axios.get("/api/schools", {
      headers: { Authorization: cookieStorage.getToken() },
      params: { type },
    });
    return handlingResponse<School[]>([200], "Error retrieving school list")(schoolList);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHOOLS_FAILED });
  }
};

// Fetch list of educations to which the user belongs
const getUserEducations = async (): Promise<Education[]> => {
  try {
    const response = await axios.get("/api/account/me/education", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<Education[]>([200], "Error fetching user educations")(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_ACCOUNT_EDUCATIONS_FAILED,
    });
  }
};

// Fetch details of a registration
const getRegistrationDetails = async (email: string): Promise<RegistrationDetails> => {
  try {
    const response = await axios.post(
      "/api/v2/account/registration-sync-finished",
      { email },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<RegistrationDetails>(
      [200],
      "Error fetching registration details",
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_REGISTRATION_DETAILS_FAILED,
    });
  }
};

// Set required fields for user account on first login
const updateUserEducation = async (
  educationId: string,
  studentId: string,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(
      "/api/account/me/education",
      { educationId, studentId },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<AxiosResponse>([200], "Error updating user infologin data")(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type:
        status === 409
          ? ERROR_TYPES.USER_ALREADY_REGISTERED
          : ERROR_TYPES.UPDATE_USER_INFO_LOGIN_FAILED,
    });
  }
};

// Modify generic information on the user account
const updateUserAccountDetails = async (
  userDetails: UserUpdate,
): Promise<UpdateAccountUserResponse> => {
  try {
    const result = await axios.put("/api/accounts/me", userDetails, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<UpdateAccountUserResponse>(
      [200],
      "Success status updating user details",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPDATE_USER_PROFILE_FAILED });
  }
};

const updateStudentAccountDetails = async (
  studentAccountId: string,
  userDetails: StudentAccountUpdate,
): Promise<UpdateAccountUserResponse> => {
  try {
    const result = await axios.patch(`/api/v1/teacher/student/${studentAccountId}`, userDetails, {
      headers: { Authorization: cookieStorage.getToken(), "Content-Type": "application/json" },
    });
    return handlingResponse<UpdateStudentAccountResponse>(
      [200],
      "Success status updating user details",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPDATE_USER_PROFILE_FAILED });
  }
};

// Modify information on the Hoges' user profile
const updateHogesUserAccountDetails = async (
  userDetails: UpdatableUserFields,
): Promise<AxiosResponse> => {
  try {
    const result = await axios.patch("/api/accounts/me", userDetails, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<AxiosResponse>([200], "Success status updating user details")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPDATE_USER_PROFILE_FAILED });
  }
};

// Fetch list of users to which the user educations belongs
const getUsersByEducationsForTeacher = async (
  searchTerm?: string,
  roles?: ROLE[],
): Promise<UserByEducations[]> => {
  try {
    const currentRoles = roles?.map((role) => role.toString())?.join(",");
    const response = await axios.get("/api/account/me/educations/users", {
      headers: { Authorization: cookieStorage.getToken() },
      params: { search: searchTerm || undefined, roles: currentRoles || undefined },
    });
    return handlingResponse<UserByEducations[]>(
      [200],
      "Error fetching users for educations",
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_ACCOUNT_USERS_EDUCATIONS_FAILED,
    });
  }
};

const fetchSurvey = async (): Promise<Survey> => {
  try {
    const response = await axios.get("/api/survey", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<Survey>([200], "Error fetching if user can do a survey")(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.FETCH_USER_CAN_DO_SURVEY,
    });
  }
};

const submitSurvey = async (): Promise<void> => {
  try {
    const response = await axios.put(
      "/api/submit-survey",
      {},
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    handlingResponse<Survey>([200], "Error submitting survey")(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.PUT_SUBMIT_SURVEY,
    });
  }
};

export {
  requestAccess,
  provideAccess,
  getUserAccountDetails,
  updateUserAccountDetails,
  getSchoolList,
  getUserEducations,
  updateUserEducation,
  updateHogesUserAccountDetails,
  getRegistrationDetails,
  getUsersByEducationsForTeacher,
  fetchSurvey,
  submitSurvey,
  updateStudentAccountDetails,
};
