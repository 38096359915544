import { Modal } from "components/Modal";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import type React from "react";
import { ViewTheory, type ViewTheoryProps } from "./ViewTheory";

export const ViewTheoryModal: React.FC<ViewTheoryProps> = (props) => {
  const { closeModal, isModalOpen } = useModal();
  const viewTheoryModalId = modalIds.viewTheoryModal;
  return (
    <Modal dataCy={viewTheoryModalId} isOpen={isModalOpen(viewTheoryModalId)} onClose={closeModal}>
      <ViewTheory {...props} />
    </Modal>
  );
};
