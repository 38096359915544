import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

interface BarProps extends ThemeProps {
  $width: number;
}

export const StyledContainer = styled.div<ThemeProps>`
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: center;
`;

export const StyledBar = styled.div<ThemeProps>`
  height: 0.5rem;
  border-radius: 0.375rem;
  background: ${(props: ThemeProps) => props.theme.palette.neutral._900};
`;

export const StyledOuterBar = styled(StyledBar)<ThemeProps>`
  flex-grow: 1;
  position: relative;
`;

export const StyledInnerBar = styled(StyledBar)<BarProps>`
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  max-width: 100%;
  width: ${(props: BarProps) => props.$width}%;
`;

export const StyledSuccessBar = styled(StyledInnerBar)<BarProps>`
  z-index: 1;
  background: ${(props: BarProps) => props.theme.palette.feedback.success._800};
`;

export const StyledFailureBar = styled(StyledInnerBar)<BarProps>`
  z-index: 2;
  background: ${(props: BarProps) => props.theme.palette.feedback.error.brand};
`;

export const StyledStats = styled.section<ThemeProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
`;
