import type React from "react";
import { StyledInputAdminSearchWrapper, StyledInputSearch } from "./InputAdminSearch.styled";

interface InputSearchProps {
  labelText: string;
  dataCy?: string;
  onInputChange: React.ChangeEventHandler<HTMLInputElement>;
  inputValue?: string | number;
}

export const InputAdminSearch: React.FC<InputSearchProps> = ({
  labelText,
  onInputChange,
  dataCy,
  inputValue,
}) => (
  <StyledInputAdminSearchWrapper>
    <label htmlFor="input">{labelText}</label>
    <StyledInputSearch
      data-cy={dataCy}
      defaultValue=""
      id="input"
      value={inputValue}
      onBlur={() => onInputChange}
      onChange={onInputChange}
    />
  </StyledInputAdminSearchWrapper>
);
