import { observer } from "mobx-react";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useProduct } from "hooks/useProduct";
import { useScheduledTests } from "hooks/useScheduledTests";
import { ProductTestGroupType } from "models/product/ProductTestGroups";

import { ScheduledTestResultsPageLayout } from "../../components/ScheduledTestResultsPageLayout/ScheduledTestResultsPageLayout";

export const SummativeTestResultsPage: React.FC = observer(() => {
  const { t } = useTranslation("results");

  const { productsList: ownedProducts, fetchProducts } = useProduct();

  const { fetchStudentScheduledTestResultsList, studentScheduledTestResultsList } =
    useScheduledTests();

  useEffect(() => {
    fetchProducts();
    fetchStudentScheduledTestResultsList(ProductTestGroupType.SUMMATIVE);
  }, []);

  const hasNoOwnedProducts = !!ownedProducts?.length;

  return (
    <ScheduledTestResultsPageLayout
      dataCy="summative-test-results"
      noResultsMessage={t(
        "placeholder.noSummativeTestData",
        "You have not done any summative test yet",
      )}
      ownedProducts={hasNoOwnedProducts}
      testResults={studentScheduledTestResultsList}
      title={t("title.summativeTestPage", "Summative tests")}
    />
  );
});
