import { SanitizedHtml } from "components/SanitizedHtml";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Heading3 } from "styles/elements/Headings";
import { CssFlex } from "styles/helpers/layout";
import type { AccordionItemProps } from "./AccordionItem.model";
import {
  StyledAccordionHeader,
  StyledAccordionItem,
  StyledAccordionTrigger,
} from "./AccordionItem.styled";

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  description,
  children,
  headerPanelRight = null,
  basic = false,
  dataCy,
  expandedByDefault = false,
  className,
}) => {
  const { t } = useTranslation("common");
  const [collapsed, setCollapsed] = useState(!expandedByDefault);

  return (
    <StyledAccordionItem basic={basic} className={className} collapsed={collapsed} data-cy={dataCy}>
      <StyledAccordionHeader>
        {/* -- Hidden clickable area covering the whole header -- */}
        {/* (cannot make the container itself clickable, or that'd trigger "nested-interactive"
              accessibility errors when right panel contains buttons or other interactive elements) */}
        <StyledAccordionTrigger
          aria-expanded={!collapsed}
          aria-label={collapsed ? t("iconButtonLabel.expand") : t("iconButtonLabel.collapse")}
          className="accordion-trigger"
          role="button"
          tabIndex={0}
          onClick={() => setCollapsed(!collapsed)}
          onKeyPress={() => setCollapsed(!collapsed)}
        />
        {/* -- Visible header elements -- */}
        <div className="tab-label">
          <CssFlex className="tab-title" flexDirection="column" flexGrow={1} rowGap={0.25}>
            <Heading3 className="tab-title-text" data-cy={`${dataCy}-title`}>
              {title}
            </Heading3>
            {description && (
              <SanitizedHtml
                className="description"
                dataCy={`${dataCy}-description`}
                dirtyHtml={description}
              />
            )}
          </CssFlex>
          {headerPanelRight && <div className="header-panel-right">{headerPanelRight}</div>}
        </div>
      </StyledAccordionHeader>
      {/* -- Collapsible content -- */}
      <div className="tab-content" role="region">
        {children}
      </div>
    </StyledAccordionItem>
  );
};
