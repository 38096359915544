import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/mixins";

import type { ThemeProps as Props } from "models/styles/theme";

import { CssGrid } from "styles/helpers/layout";

export const StyledInfoText = styled(CssGrid)<Props>`
  width: 100%;
  padding: 1.5rem;

  align-items: center;
  justify-items: start;

  background: ${(props: Props) => props.theme.palette.secondary._950};
  border: 1px solid ${(props: Props) => props.theme.palette.secondary.brand};
  border-radius: ${calculateRem(4)};

  .info-icon {
    color: ${({ theme }: Props) => theme.palette.secondary.brand};
  }
`;
