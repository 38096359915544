import { Form } from "formik";
import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";

import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";

export const StyledField = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  }
`;

export const StyledForm = styled(Form)`
  ${Heading3} {
    margin-bottom: 1rem;
  }

  ${ParagraphLarge} {
    margin-bottom: 1.5rem;
  }

  button {
    height: 3rem;
    margin-top: 2rem;
  }
`;
