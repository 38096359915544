import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import type { CreateBasicAccountRequest } from "models/api/users/request";
import type { CreateBasicAccountResponse } from "models/api/users/response";
import type { RegistrationDetailsResponse } from "models/auth/Login";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type { Registration } from "models/register/Register";
import { handlingResponse, logError } from "repositories/utils";

const createRegistration = async (
  invitationId: string,
  registration: Registration,
): Promise<RegistrationDetailsResponse> => {
  try {
    return axios.post(`/api/registration/${invitationId}`, registration);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.CREATE_REGISTRATION_FAILED });
  }
};

// Create a new basic account for the student
const createBasicAccount = async (
  userData: CreateBasicAccountRequest,
): Promise<CreateBasicAccountResponse> => {
  try {
    const response = await axios.post("/api/register", userData);
    return handlingResponse<CreateBasicAccountResponse>(
      [200],
      `Error creating basic account for ${userData.email}`,
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.REGISTER_BASIC_ACCOUNT_FAILED });
  }
};

export { createRegistration, createBasicAccount };
