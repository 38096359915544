import { observer } from "mobx-react";
import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TestType } from "constants/exam-constants";
import { RESULT_PAGE_ROUTES } from "constants/routes";

import { NavigationMenu } from "components/NavigationMenu/NavigationMenu";
import type { NavigationMenuItem } from "components/NavigationMenu/NavigationMenu.model";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TestEnvMenuSection } from "layouts/TestEnvMenuSection";

interface ComponentProps {
  className?: string;
  dataCy?: string;
}

export const NoProductsOwnedNavigationMenu: React.FC<ComponentProps> = observer(
  ({ className, dataCy }) => {
    const { t } = useTranslation("homepage");

    const menuItems = useMemo<NavigationMenuItem[]>(
      () => [
        {
          id: "result-list",
          dataCy: "result-list",
          expandedByDefault: true,
          title: t("sideMenu.results.title", "Results"),
          subItems: [
            {
              id: "results-formative-test",
              dataCy: "results-formative-test",
              title: t("sideMenu.results.items.formativeTest", "Formative Test"),
              redirectPath: buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, {
                testType: TestType.FORMATIVE_TEST,
              }),
            },
            {
              id: "results-summative-test",
              dataCy: "results-summative-test",
              title: t("sideMenu.results.items.summativeTest", "Summative Test"),
              redirectPath: buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, {
                testType: TestType.SUMMATIVE_TEST,
              }),
            },
            {
              id: "results-freebie-test",
              dataCy: "results-freebie-test",
              title: t("sideMenu.results.items.freebieTest", "Intake Test"),
              redirectPath: buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, {
                testType: TestType.INTAKE_TEST,
              }),
            },
            {
              id: "certificate-results",
              dataCy: "certificate-results",
              title: t("sideMenu.results.items.certificate", "Certificates"),
              redirectPath: RESULT_PAGE_ROUTES.OBTAINED_CERTIFICATES,
            },
          ],
        },
      ],
      [t],
    );

    return (
      <aside>
        <NavigationMenu className={className} dataCy={dataCy} menuItems={menuItems} />
        <TestEnvMenuSection />
      </aside>
    );
  },
);
