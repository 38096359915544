import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";
import { CssClickableIcon } from "styles/helpers/layout";

export const StyledEditButton = styled(CssClickableIcon)<Props>`
  transition: color 0.5s;
  color: ${(props: Props) => props.theme.palette.secondary.brand};

  :hover {
    color: ${(props: Props) => props.theme.palette.secondary.brand};
  }
`;
