import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGroups } from "hooks/useGroups";
import { useReactTooltip } from "hooks/useReactTooltip";

import { Select } from "components/SelectUI/Select";

import { Box, CssClickableIcon, CssGrid, CssIcon, CssLabel } from "styles/helpers/layout";
import { StyledGroupIconContainer } from "./GroupSelector.styled";

interface GroupSelectorProps {
  defaultValue?: string;
  onChange: (selectedGroupId: string | null) => void;
}

export const GroupSelector: React.FC<GroupSelectorProps> = observer(
  ({ defaultValue = "all", onChange }) => {
    const { t } = useTranslation("teacher-dashboard");
    const [selectedGroupId, setSelectedGroupId] = useState(defaultValue);

    const { teacherGroups, getTeacherGroups } = useGroups();
    const shouldHideSelector = !teacherGroups || teacherGroups.length === 0;

    // Trigger tooltip rebuild only if we're rendering the selector
    useReactTooltip(!shouldHideSelector);

    // Fetch list of groups available for the teacher
    useEffect(() => {
      getTeacherGroups();
    }, []);

    const groupOptions = useMemo(
      () => [
        {
          value: "all",
          label: t("filters.group.defaultOption", "All groups"),
        },
        ...teacherGroups.map(({ id, name, ownedBySelf }) => ({
          value: id,
          label: name,
          labelView: (
            <CssGrid alignItems="center" columnGap={1} gridTemplateColumns="1.25rem 1fr">
              <StyledGroupIconContainer $ownedBySelf={ownedBySelf}>
                <CssIcon iconName={ownedBySelf ? "fas fa-star" : "fas fa-users"} />
              </StyledGroupIconContainer>
              {name}
            </CssGrid>
          ),
        })),
      ],
      [teacherGroups],
    );

    // Hide filter if there are no groups available to select
    if (shouldHideSelector) {
      return null;
    }

    return (
      <Box>
        <CssLabel id="group-selector-label">
          {t("filters.group.label")}
          <CssClickableIcon
            data-tip={t("filters.group.tooltip")}
            iconName="far fa-info-circle"
            marginLeft={1}
          />
        </CssLabel>
        <Select
          showSearchBox
          aria-labelledby="group-selector-label"
          dataCy="group-selector"
          options={groupOptions}
          showPlaceholderInOptionList={false}
          value={selectedGroupId}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedValue = event.target.value;
            setSelectedGroupId(selectedValue);
            onChange(selectedValue !== "all" ? selectedValue : null);
          }}
        />
      </Box>
    );
  },
);
