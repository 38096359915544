import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import type { ElearningThemeInterface } from "../../themes/elearningInterface.theme";

export interface Props {
  theme: ElearningThemeInterface;
}

export const StyledErrorMessage = styled.span`
  display: block;
  text-align: left;
  margin-top: 0.5rem;
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  color: ${(props: Props) => props.theme.palette.feedback.error.brand};
`;
