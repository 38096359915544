import { useProduct } from "hooks/useProduct";
import cookieStorage from "persistence";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useProfile } from "./useProfile";

interface ProductPathParams {
  productId: string;
}

export interface UserStorage {
  lastSelectedProductId?: number;
}

export interface UserLocalStorageDictionary {
  [userId: number]: UserStorage;
}

export const useSelectedProductId = (): number | null => {
  const { productId: pathProductId }: ProductPathParams = useParams();
  const { productDetails, productsList } = useProduct();
  const { userDetails, fetchUserDetails } = useProfile();

  // FIXME: This is a workaround for not having the ability to listen to cookie changes yet (see
  // https://developer.mozilla.org/en-US/docs/Web/API/CookieChangeEvent for browser implementation status). This change
  // will be removed once all browsers support listening to cookie changes, but for now, we'll go with this. Finally,
  // for more information about why we decided to go with this approach, please refer to the following Slack thread:
  // https://infinitas-chat.slack.com/archives/C04MANE1GUX/p1727168924149059
  const [authTokenIsSet, setAuthTokenIsSet] = useState<boolean>(!!cookieStorage.getToken());

  useEffect(() => {
    if (!authTokenIsSet) {
      const intervalId = setInterval(() => {
        const authToken = cookieStorage.getToken();
        if (authToken) {
          setAuthTokenIsSet(true);
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (!userDetails && authTokenIsSet) {
      fetchUserDetails();
    }
  }, [authTokenIsSet]);

  const productId = useMemo(() => productDetails?.id, [productDetails]);
  const firstElementProductsList = useMemo(() => productsList?.[0]?.id, [productsList]);

  // We assign -1 for two reasons
  // 1 - we want to avoid undefined/null values
  // 2 - we don't have a product that starts with -1, so we know this is an invalid value
  let selectedProductId: number | undefined = undefined;
  let listOfUsersAndProducts: UserLocalStorageDictionary = {};
  const userId = userDetails?.id || -1;

  listOfUsersAndProducts = JSON.parse(localStorage.getItem("selectedProduct") ?? "{}");
  // The second shouldn't never happen, but with this double check we avoid JSON.parse obscure errors
  if (typeof listOfUsersAndProducts !== "object" || !listOfUsersAndProducts) {
    // We have read invalid values, let's start from scratch and remove what was previously stored
    listOfUsersAndProducts = {};
    localStorage.removeItem("selectedProduct");
  }

  const preferencesOnLocalstorage: UserStorage | undefined = listOfUsersAndProducts[userId];
  const productIdPresentOnLocalstorage =
    preferencesOnLocalstorage?.lastSelectedProductId &&
    preferencesOnLocalstorage.lastSelectedProductId > 0
      ? preferencesOnLocalstorage.lastSelectedProductId
      : undefined;

  // Id in the URL shall always prevail
  if (pathProductId && !isNaN(+pathProductId)) {
    selectedProductId = +pathProductId;
  }

  // If URL has no product id, we recover the last selection from the local storage (if available)
  if (
    !selectedProductId &&
    productIdPresentOnLocalstorage &&
    !isNaN(productIdPresentOnLocalstorage)
  ) {
    return productIdPresentOnLocalstorage;
  }

  // If no product was found in the storage, we'll try to guess it from the contents of the MobX store
  if (!selectedProductId && productId) {
    selectedProductId = productId;
  }

  // If we still cannot find any specific selection, we automatically pick up the first product in the list
  if (!selectedProductId && firstElementProductsList) {
    selectedProductId = firstElementProductsList;
  }

  // If value in storage is outdated, we make sure to modify it
  if (productIdPresentOnLocalstorage !== selectedProductId && selectedProductId && userId > 0) {
    const newUserPreferences = {
      ...preferencesOnLocalstorage,
      productId: selectedProductId,
    };
    listOfUsersAndProducts[userId] = newUserPreferences;
    localStorage.setItem("selectedProduct", JSON.stringify(listOfUsersAndProducts));
  }

  // We need to match the contract of the previous implementation that instead of choosing an optional (undefined),
  // it went for chooses null
  return selectedProductId || null;
};
