import styled from "styled-components/macro";
import { ParagraphLarge } from "styles/elements/Texts";

export const StyledWritingDetail = styled.div`
  width: 100%;
`;

export const StyleHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
`;

export const StyledContainer = styled.div`
  margin-top: 40px;
`;

export const StyledDescription = styled(ParagraphLarge)`
  text-align: center;
`;
