import type React from "react";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { getPassingScoreColor } from "_helpers/colorProgressLevel";
import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from "constants/routes";
import { useDomainHandler } from "hooks/useDomainHandler";

import { LevelDash } from "components/LevelDash";
import type { TableRow } from "components/SortableTable/SortableTable.model";
import type {
  ScheduledTestExerciseAvgResult,
  UserScheduledTestResults,
} from "models/exam/ScheduledTest";

import { Card } from "components/Card/Card";
import { Heading3 } from "styles/elements/Headings";
import type { ScheduledTestResultsReportPathParams } from "../../TestResultsReport.model";

import {
  StyledCertificateIcon,
  StyledExtraTimeLabel,
  StyledIcon,
  StyledIsInvalidLabel,
  StyledLabelWrapper,
  StyledScoreContainer,
  StyledTable,
} from "./StudentScoreTable.styled";

interface StudentScoreTableProps {
  exerciseList: ScheduledTestExerciseAvgResult[];
  userScheduledTestResults: UserScheduledTestResults[];
  passingScore: number;
}

export const StudentScoreTable: React.FC<StudentScoreTableProps> = ({
  exerciseList = [],
  userScheduledTestResults = [],
  passingScore,
}) => {
  const { t } = useTranslation("teacher-dashboard");
  const { getStudentTermByDomain } = useDomainHandler();

  const { scheduledTestId }: ScheduledTestResultsReportPathParams = useParams();

  const rowData = useMemo<TableRow[]>(() => {
    if (!userScheduledTestResults || !exerciseList) {
      return [];
    }

    return userScheduledTestResults?.map((student: UserScheduledTestResults) => {
      const { id: userId } = student;
      const fullName = `${student.middleName} ${student.lastName}, ${student.firstName}`;
      const viewTestResultsLink = buildUrlWithPathParams(
        TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_FOR_STUDENT,
        {
          scheduledTestId,
          studentId: userId,
        },
      );

      return {
        id: userId,
        link: viewTestResultsLink,
        cells: [
          {
            id: `student-${userId}-name`,
            dataCy: "student-name",
            content: (
              <div>
                {fullName}
                <StyledLabelWrapper>
                  {student.withExtraTime && (
                    <StyledExtraTimeLabel data-cy="student-with-extra-time">
                      {t(
                        "ownedScheduledTestResults.details.resultsPerStudent.withExtraTime",
                        "With extra time",
                      )}
                    </StyledExtraTimeLabel>
                  )}
                  {student.isInvalid && (
                    <StyledIsInvalidLabel data-cy="student-invalid-label">
                      {t(
                        "ownedScheduledTestResults.details.resultsPerStudent.isInvalid",
                        "Toets ongeldig verklaard",
                      )}
                    </StyledIsInvalidLabel>
                  )}
                </StyledLabelWrapper>
              </div>
            ),
            sortValue: fullName,
          },
          ...exerciseList.map(({ id: exerciseId }) => {
            const resultForExercise =
              student.exerciseScores.find(({ id }) => id === exerciseId)?.score || 0;
            return {
              id: `student-${userId}-exercise-${exerciseId}-score`,
              dataCy: "student-exercise-score",
              content: (
                <StyledScoreContainer>
                  <LevelDash
                    level={getPassingScoreColor(resultForExercise, passingScore).levelType}
                  />
                  <b>{`${Math.round(resultForExercise)}%`}</b>
                </StyledScoreContainer>
              ),
              sortValue: resultForExercise,
            };
          }),
          {
            id: `student-${userId}-final-score`,
            dataCy: "student-final-score",
            content: (
              <StyledScoreContainer>
                <LevelDash level={getPassingScoreColor(student.score, passingScore).levelType} />
                <b>{`${Math.round(student.score)}%`}</b>
              </StyledScoreContainer>
            ),
            sortValue: student.score,
          },
          {
            id: `student-${userId}-certificate`,
            dataCy: "student-certificate-icon-container",
            content: student.hasCertificate ? (
              <StyledCertificateIcon
                data-cy="certificate-icon"
                iconName="fas fa-check-circle"
                iconSize="lg"
              />
            ) : null,
            centerContent: true,
            sortValue: student.hasCertificate ? 1 : 0,
          },
          {
            id: `student-${userId}-result-actions`,
            content: (
              <Link
                aria-label={t("ownedScheduledTestResults.details.resultsPerStudent.seeDetails")}
                data-cy="review-student-results-button"
                to={viewTestResultsLink}
              >
                <StyledIcon iconName="fas fa-chevron-right" />
              </Link>
            ),
            centerContent: true,
          },
        ],
      };
    });
  }, [userScheduledTestResults]);

  return (
    <Card layout={{ mt: 2, p: 0 }}>
      <Heading3 fontWeight="normal" p={2}>
        <Trans
          components={{ studentCountContainer: <span data-cy="student-count" /> }}
          i18nKey="ownedScheduledTestResults.details.resultsPerStudent.title"
          t={t}
          values={{
            studentCount: userScheduledTestResults.length,
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          }}
        />
      </Heading3>
      <StyledTable
        headerCells={[
          {
            id: "title",
            title: t("ownedScheduledTestResults.details.resultsPerStudent.columns.name", "Name"),
            sortable: true,
            size: "2fr",
          },
          ...exerciseList.map((exercise) => ({
            id: `exercise-${exercise.id}`,
            title: exercise.title,
            sortable: true,
            size: "1fr",
          })),
          {
            id: "avgScore",
            title: t(
              "ownedScheduledTestResults.details.resultsPerStudent.columns.finalScore",
              "Score",
            ),
            sortable: true,
            size: "1fr",
          },
          {
            id: "certificate",
            title: t(
              "ownedScheduledTestResults.details.resultsPerStudent.columns.certificate",
              "Certificate",
            ),
            sortable: true,
            size: "auto",
          },
          {
            id: "action-buttons",
            title: "",
            size: "auto",
          },
        ]}
        rows={rowData}
      />
    </Card>
  );
};
