import { action, observable } from "mobx";
import type {
  AverageKnowledgeDetails,
  BasicProgressForAccount,
  GoalLevel,
  OverviewFilters,
  ProductProgressSubjects,
  StudentProgressOverTimeFilters,
  SubjectsProgress,
  SubjectsProgressOverviewFilters,
} from "models/progress/Progress";

//TODO: import { useDomainHandler } from 'hooks/useDomainHandler' and convert this in a hook with shared context among other hooks of this kind;
import { getStudentTermByDomain } from "_helpers/domainHandler";
import { ToastMethods } from "components/ToastNotification";
import type { ProgressAPI, ResultsAPI } from "generated/types";
import i18n from "i18n";
import { progressRepository } from "repositories";

export type StudentSubjectProgress = Awaited<
  ReturnType<typeof progressRepository.getModuleProgress>
>[0];

interface ProductProgressInterface {
  moduleSubjectsProgress: StudentSubjectProgress[];
  moduleProgressFetchException?: Error;
  setModuleSubjectsProgress: (progress: StudentSubjectProgress[]) => void;
  setModuleProgressFetchException: (error: Error) => void;
  fetchModuleProgress: (productId: number, moduleId: number) => Promise<StudentSubjectProgress[]>;
  productProgressSubjects: ProductProgressSubjects[];
  productProgressSubjectsFetchException?: Error;
  setProductProgressSubjects: (progress: ProductProgressSubjects[]) => void;
  setProductProgressSubjectsFetchException: (error: Error) => void;
  getProductProgressSubjects: (productId: number) => void;
  productGoalLevel?: GoalLevel;
  setProductGoalLevel: (goalLevel: GoalLevel) => void;
  fetchProductGoalLevel: (productId: number) => void;
  updateProductGoalLevel: (productId: number, goalLevel: number) => void;
  updateStudentGoalLevel: (
    studentId: number,
    productId: number,
    goalLevel: number,
  ) => Promise<boolean>;
  studentsProgressOverview: ProgressAPI.StudentProgressOverviewV2[] | null;
  fetchStudentsProgressOverview: (productId: number, filters: OverviewFilters) => void;
  setStudentsProgressOverview: (
    studentsProgress: ProgressAPI.StudentProgressOverviewV2[] | null,
  ) => void;
  subjectsProgressOverview: SubjectsProgress | null;
  fetchSubjectsProgressOverview: (
    productId: number,
    filters: SubjectsProgressOverviewFilters,
  ) => void;
  setSubjectsProgressOverview: (subjectsProgress: SubjectsProgress | null) => void;
  productProgressForAccount: BasicProgressForAccount | null;
  fetchProductProgressForAccount: (
    productId: number,
    accountId: number,
    targetLevel?: number,
  ) => void;
  setProductProgressForAccount: (userProgress: BasicProgressForAccount | null) => void;
  averageKnowledgeDetails?: AverageKnowledgeDetails | null;
  fetchAverageKnowledgeDetails: (productId: number) => void;
  setAverageKnowledgeDetails: (avgKnowledgeDetails: AverageKnowledgeDetails | null) => void;
  answeredQuestionsForAccount: ResultsAPI.AnsweredQuestionsForAccount | null;
  setAnsweredQuestionsForAccount: (
    answeredQuestionsForAccount: ResultsAPI.AnsweredQuestionsForAccount | null,
  ) => void;
  fetchAnsweredQuestionsForAccount: (productId: number, studentId: number) => void;

  studentSubjectProgressOverTime: ProgressAPI.SubjectProgressOverTime[] | null;
  setStudentSubjectProgressOverTime: (
    subjectMonthlyProgress: ProgressAPI.SubjectProgressOverTime[] | null,
  ) => void;
  fetchStudentSubjectProgressOverTime: (
    productId: number,
    studentId: number,
    targetLevel: number,
    filters?: StudentProgressOverTimeFilters,
  ) => void;

  studentDetailedProgress: ProgressAPI.StudentSubjectInsights[] | null;
  setStudentDetailedProgress: (
    studentDetailedProgress: ProgressAPI.StudentSubjectInsights[] | null,
  ) => void;
  fetchStudentDetailedProgress: (productId: number, studentId: number, targetLevel: number) => void;
}

const initialState = {
  moduleSubjectsProgress: [],
  productProgressSubjects: [],
  studentsProgressOverview: null,
  productProgressForAccount: null,
  subjectsProgressOverview: null,
  answeredQuestionsForAccount: null,
  studentSubjectProgressOverTime: null,
  studentDetailedProgress: null,
};

const stateSetters = {
  setModuleSubjectsProgress: action((progress: StudentSubjectProgress[]) => {
    store.moduleSubjectsProgress = progress;
  }),
  setModuleProgressFetchException: action((error: Error) => {
    store.moduleProgressFetchException = error;
  }),
  setProductProgressSubjects: action((progress: ProductProgressSubjects[]) => {
    store.productProgressSubjects = progress;
  }),
  setProductProgressSubjectsFetchException: action((error: Error) => {
    store.productProgressSubjectsFetchException = error;
  }),
  setProductGoalLevel: action((goalLevel: GoalLevel) => {
    store.productGoalLevel = goalLevel;
  }),
  setStudentsProgressOverview: action(
    (studentsProgressOverview: ProgressAPI.StudentProgressOverviewV2[] | null) => {
      store.studentsProgressOverview = studentsProgressOverview;
    },
  ),
  setSubjectsProgressOverview: action((subjectsProgressOverview: SubjectsProgress | null) => {
    store.subjectsProgressOverview = subjectsProgressOverview;
  }),
  setProductProgressForAccount: action((userProgress: BasicProgressForAccount | null) => {
    store.productProgressForAccount = userProgress;
  }),
  setAverageKnowledgeDetails: action((averageKnowledgeDetails: AverageKnowledgeDetails | null) => {
    store.averageKnowledgeDetails = averageKnowledgeDetails;
  }),
  setAnsweredQuestionsForAccount: action(
    (answeredQuestionsForAccount: ResultsAPI.AnsweredQuestionsForAccount | null) => {
      store.answeredQuestionsForAccount = answeredQuestionsForAccount;
    },
  ),
  setStudentSubjectProgressOverTime: action(
    (subjectMonthlyProgress: ProgressAPI.SubjectProgressOverTime[] | null) => {
      store.studentSubjectProgressOverTime = subjectMonthlyProgress;
    },
  ),
  setStudentDetailedProgress: action(
    (studentDetailedProgress: ProgressAPI.StudentSubjectInsights[] | null) => {
      store.studentDetailedProgress = studentDetailedProgress;
    },
  ),
};

const apiRequests = {
  fetchModuleProgress: action(async (productId: number, moduleId: number) => {
    try {
      const res = await progressRepository.getModuleProgress(productId, moduleId);
      store.setModuleSubjectsProgress(res);
      return res;
    } catch (error) {
      store.setModuleProgressFetchException(error as Error);
      ToastMethods.showToast(i18n.t("toast:progress.moduleprogress.error"), "error");
    }
  }),
  getProductProgressSubjects: action((productId: number) => {
    progressRepository
      .fetchProductProgressSubjects(productId)
      .then((progress: ProductProgressSubjects[]) => {
        store.setProductProgressSubjects(progress);
      })
      .catch((error) => {
        store.setProductProgressSubjectsFetchException(error);
      });
  }),
  fetchProductGoalLevel: action((productId: number) => {
    progressRepository
      .getGoalLevel(productId)
      .then((goalLevel: GoalLevel) => {
        store.setProductGoalLevel(goalLevel);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:progress.goallevel.error"), "error");
      });
  }),
  // Modify own's goal level on a product
  updateProductGoalLevel: action((productId: number, goalLevel: number) => {
    progressRepository
      .setGoalLevel(productId, goalLevel)
      .then(() => {
        store.setProductGoalLevel({ productId, goalLevel });
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t("toast:progress.setOwnGoalLevel.error"), "error");
      });
  }),
  // As a teacher, update goal level of a student
  updateStudentGoalLevel: action(
    (studentId: number, productId: number, goalLevel: number): Promise<boolean> => {
      return progressRepository
        .setStudentGoalLevel(studentId, productId, goalLevel)
        .then(() => {
          ToastMethods.showToast(
            i18n.t("toast:progress.setStudentGoalLevel.success", {
              studentTerm: getStudentTermByDomain(),
            }),
            "success",
          );
          return Promise.resolve(true);
        })
        .catch(() => {
          ToastMethods.showToast(
            i18n.t("toast:progress.setStudentGoalLevel.error", {
              studentTerm: getStudentTermByDomain(),
            }),
            "error",
          );
          return Promise.resolve(true);
        });
    },
  ),
  // Retrieve an overview of the progress made on a product (for teacher dashboard)
  fetchStudentsProgressOverview: action((productId: number, filters: OverviewFilters) => {
    progressRepository
      .getStudentsProgressOverview(productId, filters)
      .then((studentsProgressOverview: ProgressAPI.StudentProgressOverviewV2[]) => {
        store.setStudentsProgressOverview(studentsProgressOverview);
      })
      .catch(() => {
        store.setStudentsProgressOverview(null);
        ToastMethods.showToast(i18n.t("toast:progress.getBasicProgressOverview.error"), "error");
      });
  }),
  fetchSubjectsProgressOverview: action(
    (productId: number, filters: SubjectsProgressOverviewFilters) => {
      progressRepository
        .getSubjectsProgressOverview(productId, filters)
        .then((subjectsProgressOverview: SubjectsProgress) => {
          store.setSubjectsProgressOverview(subjectsProgressOverview);
        })
        .catch(() => {
          store.setSubjectsProgressOverview(null);
          ToastMethods.showToast(i18n.t("toast:progress.getBasicProgressOverview.error"), "error");
        });
    },
  ),
  // Retrieve current progress for an user in the specified product (for teacher dashboard)
  fetchProductProgressForAccount: action(
    (productId: number, accountId: number, targetLevel?: number) => {
      progressRepository
        .getProductProgressForAccount(productId, accountId, targetLevel)
        .then((userProgress: BasicProgressForAccount) => {
          store.setProductProgressForAccount(userProgress);
        })
        .catch(() => {
          store.setProductProgressForAccount(null);
          ToastMethods.showToast(
            i18n.t("toast:progress.getBasicProgressForAccount.error"),
            "error",
          );
        });
    },
  ),
  fetchAverageKnowledgeDetails: action((productId: number) => {
    progressRepository
      .getAverageKnowledgeDetails(productId)
      .then((averageKnowledgeDetails: AverageKnowledgeDetails) => {
        store.setAverageKnowledgeDetails(averageKnowledgeDetails);
      })
      .catch(() => {
        store.setAverageKnowledgeDetails(null);
        ToastMethods.showToast(i18n.t("toast:progress.getAverageKnowledgeLevels.error"), "error");
      });
  }),
  fetchAnsweredQuestionsForAccount: action((productId: number, studentId: number) => {
    progressRepository
      .fetchAnsweredQuestionsForAccount(productId, studentId)
      .then((details: ResultsAPI.AnsweredQuestionsForAccount) => {
        store.setAnsweredQuestionsForAccount(details);
      })
      .catch(() => {
        store.setAnsweredQuestionsForAccount(null);
        ToastMethods.showToast(
          i18n.t("toast:progress.getAdaptiveModulesProgresses.error"),
          "error",
        );
      });
  }),
  // Retrieve data on subject progress data over time for a specific student relative to the selected goal
  fetchStudentSubjectProgressOverTime: action(
    (
      productId: number,
      studentId: number,
      targetLevel: number,
      filters?: StudentProgressOverTimeFilters,
    ) => {
      progressRepository
        .getStudentSubjectProgressOverTime(productId, studentId, targetLevel, filters)
        .then((subjectMonthlyProgress: ProgressAPI.SubjectProgressOverTime[]) => {
          store.setStudentSubjectProgressOverTime(subjectMonthlyProgress);
        })
        .catch(() => {
          store.setStudentSubjectProgressOverTime(null);
          ToastMethods.showToast(
            i18n.t("toast:progress.getStudentSubjectProgressOverTime.error", {
              studentTerm: getStudentTermByDomain(),
            }),
            "error",
          );
        });
    },
  ),
  fetchStudentDetailedProgress: action(
    (productId: number, studentId: number, targetLevel: number) => {
      progressRepository
        .fetchStudentDetailedProgress(productId, studentId, targetLevel)
        .then((details: ProgressAPI.StudentSubjectInsights[]) => {
          store.setStudentDetailedProgress(details);
        })
        .catch(() => {
          store.setStudentDetailedProgress(null);
          ToastMethods.showToast(
            i18n.t("toast:progress.getStudentDetailedProgress.error"),
            "error",
          );
        });
    },
  ),
};

const store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as ProductProgressInterface);

export const useProductProgress = (): ProductProgressInterface => store;
