import { PAGE_CATEGORIES, PAGE_TYPES } from "analytics/constants/pageTypes";

import {
  ADMIN_ENVIRONMENT_ROUTES,
  AUTH_PAGES_ROUTES,
  GENERIC_ROUTES,
  LEGACY_ROUTES,
  PRODUCT_PAGES_ROUTES,
  RESULT_PAGE_ROUTES,
  TEACHER_HOME_PAGE_ROUTES,
  TEACHER_INSIGHT_PAGE_ROUTES,
  TEACHER_MONITOR_PAGE_ROUTES,
  TEACHER_SCHEDULED_TEST_PAGE_ROUTES,
  TEST_ENVIRONMENT_ROUTES,
  TEST_PAGE_ROUTES,
} from "constants/routes";

export interface RouteForTracking {
  path: string;
  category: string;
  type?: string;
  getType?: (arg: string) => string;
}

export const routesForTracking: RouteForTracking[] = [
  {
    path: PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS,
    category: PAGE_CATEGORIES.COURSE_OVERVIEW,
    type: PAGE_TYPES.COURSE,
  },
  {
    path: RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST,
    category: PAGE_CATEGORIES.RESULT,
    getType: (pathname: string): string => pathname.split("/").pop() || PAGE_TYPES.DIAGNOSTIC_TEST,
  },
  {
    path: RESULT_PAGE_ROUTES.OBTAINED_CERTIFICATES,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.CERTIFICATE,
  },
  {
    path: RESULT_PAGE_ROUTES.PERSONAL_PROGRESS,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.MONITOR,
  },
  {
    path: RESULT_PAGE_ROUTES.RESULTS_PATH,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: RESULT_PAGE_ROUTES.DIAGNOSTIC_TEST,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.DIAGNOSTIC_TEST,
  },
  {
    path: RESULT_PAGE_ROUTES.WRITING_EXERCISES,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.WRITING_EXERCISE,
  },
  {
    path: PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS,
    category: PAGE_CATEGORIES.CONTENT,
    type: PAGE_TYPES.DETAILS,
  },
  {
    path: PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS,
    category: PAGE_CATEGORIES.CONTENT,
    type: PAGE_TYPES.DETAILS,
  },
  {
    path: TEACHER_MONITOR_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR,
    category: PAGE_CATEGORIES.TEACHER,
    type: PAGE_TYPES.MONITOR_LIVE,
  },
  {
    path: TEST_PAGE_ROUTES.SKILL_DETERMINATION_TEST,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.DIAGNOSTIC_TEST,
  },
  {
    path: TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_DETAILS,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.DIAGNOSTIC_TEST_DETAILS,
  },
  {
    path: TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_EXAM,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.DIAGNOSTIC_TEST_EXAM,
  },
  {
    path: TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_LIST,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.DIAGNOSTIC_TEST_LIST,
  },
  {
    path: TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.DIAGNOSTIC_TEST_GROUPS,
  },
  {
    path: TEST_PAGE_ROUTES.WRITING_EXERCISE,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.WRITING_EXERCISE,
  },
  {
    path: TEST_PAGE_ROUTES.SCHEDULED_TESTS_PAGE,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.SCHEDULED_TESTS,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.DASHBOARD,
    category: PAGE_CATEGORIES.HOME,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.MONITOR_PAGE,
    category: PAGE_CATEGORIES.TEACHER,
    type: PAGE_TYPES.MONITOR,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.STUDENT_LICENSE,
    category: PAGE_CATEGORIES.TEACHER,
    type: PAGE_TYPES.INVITE,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.CREATE_INVITE,
    category: PAGE_CATEGORIES.TEACHER,
    type: PAGE_TYPES.INVITE_NEW,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.SENT_INVITE_LIST,
    category: PAGE_CATEGORIES.TEACHER,
    type: PAGE_TYPES.INVITE_LIST,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.HOME_PAGE,
    category: PAGE_CATEGORIES.TEACHER,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.GROUPS,
    category: PAGE_CATEGORIES.ADMIN,
    type: PAGE_TYPES.GROUP,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.EDIT_GROUP,
    category: PAGE_CATEGORIES.ADMIN,
    type: PAGE_TYPES.GROUP_DETAILS,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.NEW_GROUP,
    category: PAGE_CATEGORIES.ADMIN,
    type: PAGE_TYPES.CREATE,
  },
  {
    path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_OVERVIEW,
    category: PAGE_CATEGORIES.PLANNING,
    type: PAGE_TYPES.CREATE,
  },
  {
    path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_REQUEST,
    category: PAGE_CATEGORIES.PLANNING,
    type: PAGE_TYPES.CREATE,
  },
  {
    path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_EDIT_REQUEST,
    category: PAGE_CATEGORIES.PLANNING,
    type: PAGE_TYPES.DETAILS,
  },
  {
    path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR_OVERVIEW,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.MONITOR,
  },
  {
    path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR_LOGS,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_OVERVIEW,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.DETAILS,
  },
  {
    path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_FOR_STUDENT,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.LEARNER_DETAILS,
  },
  {
    path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_RESULTS_FOR_STUDENT,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.LEARNER_DETAILS,
  },
  {
    path: TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.LEARNER_DETAILS,
  },
  {
    path: TEACHER_INSIGHT_PAGE_ROUTES.WRITING_RESULTS_FOR_ACCOUNT,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.LEARNER_DETAILS,
  },
  {
    path: TEACHER_INSIGHT_PAGE_ROUTES.ACTIVITY,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: TEACHER_INSIGHT_PAGE_ROUTES.WRITING_SKILLS,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: TEACHER_INSIGHT_PAGE_ROUTES.EDIT_ACCOUNT,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.LEARNER_DETAILS,
  },
  {
    path: TEACHER_INSIGHT_PAGE_ROUTES.DIAGNOSTIC_TEST,
    category: PAGE_CATEGORIES.RESULT,
    type: PAGE_TYPES.DIAGNOSTIC_TEST,
  },
  {
    path: GENERIC_ROUTES.HOME_PAGE,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.SETTINGS,
  },
  {
    path: PRODUCT_PAGES_ROUTES.PRODUCT_LIST,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.PRODUCT_LIST_PAGE,
  },
  {
    path: TEST_ENVIRONMENT_ROUTES.LANDING_PAGE,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.LOGIN,
  },
  {
    path: TEST_ENVIRONMENT_ROUTES.TEST_DETAILS_PAGE,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.DETAILS,
  },
  {
    path: TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_PAGE,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.EXERCISE,
  },
  {
    path: TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_REVIEW_PAGE,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.EXERCISE_REVIEW,
  },
  {
    path: TEST_ENVIRONMENT_ROUTES.TEST_COMPLETED_PAGE,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.DETAILS,
  },
  {
    path: TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PATH,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.RESULT,
  },
  {
    path: TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PAGE,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.RESULT_CODE,
  },
  {
    path: TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_RESULTS_PAGE,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.EXERCISE,
  },
  {
    path: TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
    category: PAGE_CATEGORIES.CONTENT,
    type: PAGE_TYPES.CONTENT_UNIT,
  },
  {
    path: TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_WORKFLOW_SELECT,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.SETTINGS,
  },
  {
    path: TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_SESSION_RESULTS,
    category: PAGE_CATEGORIES.TEST,
    type: PAGE_TYPES.RESULT,
  },
  {
    path: PRODUCT_PAGES_ROUTES.PRODUCT_WRITING_MODULE_DETAILS,
    category: PAGE_CATEGORIES.CONTENT,
    type: PAGE_TYPES.CONTENT_UNIT,
  },
  {
    path: PRODUCT_PAGES_ROUTES.PRODUCT_TOPIC_DETAILS,
    category: PAGE_CATEGORIES.CONTENT,
    type: PAGE_TYPES.CONTENT_UNIT,
  },
  {
    path: ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE,
    category: PAGE_CATEGORIES.ADMIN,
    type: PAGE_TYPES.USERS,
  },
  {
    path: ADMIN_ENVIRONMENT_ROUTES.USER_TOOLS,
    category: PAGE_CATEGORIES.ADMIN,
    type: PAGE_TYPES.TOOLS,
  },
  {
    path: AUTH_PAGES_ROUTES.LOG_IN,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.LOGIN,
  },
  {
    path: AUTH_PAGES_ROUTES.VALIDATE,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.AUTHORIZATION,
  },
  {
    path: AUTH_PAGES_ROUTES.MIGRATE_ACCESS,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.AUTHORIZATION,
  },
  {
    path: AUTH_PAGES_ROUTES.USER_MISSING_INFO,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.AUTHORIZATION,
  },
  {
    path: AUTH_PAGES_ROUTES.REGISTER_ACCOUNT,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.AUTHORIZATION,
  },
  {
    path: AUTH_PAGES_ROUTES.REGISTER_ACCOUNT_FINISHED,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.AUTHORIZATION,
  },
  {
    path: AUTH_PAGES_ROUTES.CREATE_BASIC_ACCOUNT,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.AUTHORIZATION,
  },
  {
    path: AUTH_PAGES_ROUTES.INVITATION,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.AUTHORIZATION,
  },
  {
    path: AUTH_PAGES_ROUTES.REGISTERED_INVITATION,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.AUTHORIZATION,
  },
  {
    path: AUTH_PAGES_ROUTES.ACCESS_DENIED,
    category: PAGE_CATEGORIES.ACCOUNT,
    type: PAGE_TYPES.AUTHORIZATION,
  },
  {
    path: LEGACY_ROUTES.TEACHER_DASHBOARD,
    category: PAGE_CATEGORIES.HOME,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: LEGACY_ROUTES.STUDENT_DASHBOARD,
    category: PAGE_CATEGORIES.HOME,
    type: PAGE_TYPES.OVERVIEW,
  },
  {
    path: TEACHER_HOME_PAGE_ROUTES.TEACHER_INSTRUCTIONS,
    category: PAGE_CATEGORIES.TEACHER,
    type: PAGE_TYPES.OVERVIEW,
  },
];
