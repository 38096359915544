import type React from "react";
import { useTranslation } from "react-i18next";

import type { TopicDetails } from "models/product/TopicDetails";

import { Button } from "components/Button";
import { CopyrightBottomBar } from "components/CopyrightBottomBar/CopyrightBottomBar";
import { LoadingSpinner } from "components/LoadingSpinner";
import { SanitizedHtml } from "components/SanitizedHtml";
import { SvgIconReact } from "components/SvgIconReact";
import { TheoryRenderer } from "components/TheoryRenderer";

import { ImmersiveReaderButton } from "components/ImmersiveReaderButton/ImmersiveReaderButton";
import {
  StyledContentContainer,
  StyledViewTheory,
  StyledViewTheoryHeading,
} from "./ViewTheory.styled";

export interface ViewTheoryProps {
  onClose: () => void;
  topic: TopicDetails;
}

const IR_TARGET_SELECTOR = "view-theory-immersive-reader";

export const ViewTheory: React.FC<ViewTheoryProps> = ({ onClose, topic }) => {
  const { t } = useTranslation("common");

  if (!topic?.id) {
    return (
      <StyledViewTheory>
        <LoadingSpinner />
      </StyledViewTheory>
    );
  }

  return (
    <StyledViewTheory>
      <Button
        aria-label={t("iconButtonLabel.close")}
        dataCy="close-view-theory-moda-button"
        onClick={() => onClose()}
      >
        <SvgIconReact iconName="closeX" />
      </Button>
      <StyledContentContainer flexDirection="column" justifyContent="space-between">
        <div className={`theory ${IR_TARGET_SELECTOR}`}>
          <StyledViewTheoryHeading>
            <ImmersiveReaderButton targetIndex={0} targetSelector={`.${IR_TARGET_SELECTOR}`} />
            <SanitizedHtml dirtyHtml={topic.title || ""} />
          </StyledViewTheoryHeading>
          <TheoryRenderer content={topic.content} productId={topic.product.id} />
        </div>
        <CopyrightBottomBar />
      </StyledContentContainer>
    </StyledViewTheory>
  );
};
