import { observer } from "mobx-react";
import type React from "react";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { DEFAULT_ROWS_PER_PAGE, ROWS_PER_PAGE } from "constants/misc-constants";
import { ADMIN_ENVIRONMENT_ROUTES } from "constants/routes";

import { Link } from "components/Link";
import { LoadingSpinner } from "components/LoadingSpinner";
import type { TableRow } from "components/SortableTable/SortableTable.model";
import { SvgIconReact } from "components/SvgIconReact";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { useAdmin } from "hooks/useAdmin";
import { useReactTooltip } from "hooks/useReactTooltip";

import type { FilterTableProps } from "models/admin/Admin";

import {
  StyledHeader,
  StyledIcon,
  StyledSelect,
  StyledSelectContainer,
  StyledSortableTable,
  StyledSpinnerWrapper,
  StyledTableContainer,
  StyledTableHeader,
} from "./TeachersTable.styled";

export const TeachersTable: React.FC<FilterTableProps> = observer(
  ({ onResultsChange, selectedResults, storedParams }) => {
    useReactTooltip();
    const { t } = useTranslation("admin-environment");

    const { filteredAccounts, loading } = useAdmin();

    const resultsPerPage = ROWS_PER_PAGE.map((rows) => ({
      value: rows,
      label: `${rows}`,
    }));

    const rowData = useMemo<TableRow[]>(
      () =>
        filteredAccounts.data.map((account) => ({
          id: account.id,
          cells: [
            {
              id: `account-${account.id}-name`,
              dataCy: "account-name",
              content: `${account.fullName}`,
            },
            {
              id: `account-${account.id}-school`,
              dataCy: "account-school",
              content: `${account.school || ""}`,
            },
            {
              id: `account-${account.id}-education`,
              dataCy: "account-education",
              content: `${account?.educations?.join(", ") || ""}`,
            },
            {
              id: `account-${account.id}-email`,
              content: (
                <div data-tip={account.email || t("accountsTable.emailt.tooltip", "No email")}>
                  <SvgIconReact iconName="envelopeIcon" size={2} />
                </div>
              ),
            },
            {
              id: `account-${account.id}-actions`,
              content: (
                <Link
                  data-cy={`account-${account.id}-details`}
                  to={{
                    pathname: `${buildUrlWithPathParams(
                      ADMIN_ENVIRONMENT_ROUTES.USER_DETAILS_PAGE,
                      {
                        userId: account.id,
                      },
                    )}`,
                    state: {
                      params: storedParams,
                    },
                  }}
                >
                  <StyledIcon iconName="pencilIcon" size={1} />
                </Link>
              ),
            },
          ],
        })),
      [filteredAccounts],
    );

    const headerCells = [
      {
        id: "name",
        title: t("accountsTable.name", "Name"),
        size: "auto",
      },
      {
        id: "school",
        title: t("accountsTable.school", "School"),
        size: "auto",
      },
      {
        id: "education",
        title: t("accountsTable.education", "Education"),
        size: "auto",
      },
      {
        id: "email",
        title: t("accountsTable.email", "Email"),
        size: "auto",
      },
      {
        id: "actions",
        title: t("accountsTable.edit", "Edit"),
        size: "auto",
      },
    ];

    return (
      <StyledTableContainer>
        <StyledTableHeader>
          <StyledHeader>
            <Trans i18nKey="accountsTable.teachers" t={t}>
              Teachers (
              <span data-cy="teacher-count">{{ teacherCount: filteredAccounts.data.length }}</span>)
            </Trans>
          </StyledHeader>
          <StyledSelectContainer>
            {t("userFilter.results.label", "Show:")}
            <StyledSelect
              ariaLabel="results-selector"
              dataCy="results-selector"
              defaultValue={DEFAULT_ROWS_PER_PAGE}
              options={resultsPerPage}
              value={selectedResults}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                onResultsChange(Number.parseInt(e.target.value, 10))
              }
            />
          </StyledSelectContainer>
        </StyledTableHeader>
        {loading ? (
          <StyledSpinnerWrapper>
            <LoadingSpinner />
          </StyledSpinnerWrapper>
        ) : (
          <StyledSortableTable headerCells={headerCells} rows={rowData} />
        )}
      </StyledTableContainer>
    );
  },
);
