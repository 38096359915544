import type React from "react";

import { IconButton } from "components/IconButton/IconButton";
import { Modal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { Heading3 } from "styles/elements/Headings";
import type { SideModalProps } from "./SideModal.model";
import { StyledSidebar, StyledTitle } from "./SideModal.styled";

export const SideModal: React.FC<SideModalProps> = ({
  children,
  dataCy,
  title,
  onClose,
  modalAnimation = "slideRight",
  modalPosition = ["center", "right"],
  ...modalProps
}) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      {...modalProps}
      dataCy={dataCy}
      modalAnimation={modalAnimation}
      modalPosition={modalPosition}
      showCloseButton={false}
      onClose={onClose}
    >
      <StyledSidebar>
        <StyledTitle data-cy={dataCy ? `${dataCy}-title` : "side-modal-title"}>
          <IconButton
            aria-label={t("iconButtonLabel.close")}
            dataCy={dataCy ? `${dataCy}-close-button` : "side-modal-close-button"}
            iconName="arrowBackward"
            isCircle={false}
            onClick={onClose}
          />
          {title && <Heading3>{title}</Heading3>}
        </StyledTitle>
        {children}
      </StyledSidebar>
    </Modal>
  );
};
