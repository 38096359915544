import Cookies from "js-cookie";

import {
  COOKIE_SESSION_TOKEN,
  type CookieOptionsInterface,
  NOT_VALID_VALUES,
  cookieENV,
  getCookieOptions,
} from "constants/env-constants";

export enum ROLE {
  ADMIN = "admin",
  TEACHER = "teacher",
  STUDENT = "student",
  EDITOR = "editor",
}
export interface TokenValue {
  uuid: number;
  role: ROLE;
  swell_id: string;
  iat: number;
  exp: number;
}

const options: CookieOptionsInterface = getCookieOptions();

const validStorageValue = (value: string, cb: CallableFunction) => {
  if (NOT_VALID_VALUES.includes(value)) return;
  cb();
};

const cookieStorage = {
  setItem: (key: string, value: string): void => {
    validStorageValue(value, () => {
      Cookies.set(key, value, options);
    });
  },
  removeItem: (key: string): void => Cookies.remove(key, options),
  getItem: (key: string): string => Cookies.get(key) || "",
  getToken: (): string => {
    const env = cookieENV();
    return Cookies.get(`${env}-${COOKIE_SESSION_TOKEN}`) || "";
  },
};

export default cookieStorage;
