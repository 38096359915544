import { sortQuestionsWithAnsweredFirst } from "_helpers/questionHelper";
import { ToastMethods } from "components/ToastNotification";
import i18n from "i18n";
import { action, observable } from "mobx";
import type {
  DeterminationTestStatus,
  GenericTestDetails,
} from "models/adaptive-practice/AdaptivePractice";
import type { RequestErrorModel } from "models/error/Error";
import type { ExamQuestion } from "models/exam/Exam";
import type { SkillDeterminationTestSessionProgress } from "models/results/Results";
import { skillDeterminationRepository } from "repositories";
import type { ErrorResponse } from "./useAdaptivePractice";

interface DeterminationTestStore {
  loading: boolean;
  determinationTestError: RequestErrorModel | null;
  determinationTest?: GenericTestDetails | null;
  determinationTestStatus?: DeterminationTestStatus[] | null;
  setDeterminationTest: (test: GenericTestDetails) => void;
  setDeterminationTestStatus: (status: DeterminationTestStatus[]) => void;
  determinationTestSessionProgress?: SkillDeterminationTestSessionProgress | null;
  setDeterminationTestSessionProgress: (test: SkillDeterminationTestSessionProgress | null) => void;
  setLoading: (val: boolean) => void;
  setDeterminationTestError: (error: RequestErrorModel | null) => void;
  setDeterminationTestProgressError: (error: RequestErrorModel | null) => void;
  fetchSkillDeterminationTest: (productId: number, subjectId: number) => Promise<void>;
  fetchSkillDeterminationStatus: (productId: number, moduleId: number) => Promise<void>;
  resetDeterminationStatus: () => void;
  clearDeterminationTestState: () => void;
}

const initialState = {
  determinationTest: null,
  determinationTestStatus: null,
  determinationTestSessionProgress: null,
  determinationTestError: null,
  loading: false,
};

const stateSetters = {
  setDeterminationTestSessionProgress: action(
    (testSession: SkillDeterminationTestSessionProgress | null) => {
      store.determinationTestSessionProgress = testSession;
    },
  ),
  setDeterminationTest: action((test: GenericTestDetails) => {
    store.determinationTest = test;
  }),
  setDeterminationTestStatus: action((status: DeterminationTestStatus[]) => {
    store.determinationTestStatus = status;
  }),
  setLoading: action((val: boolean) => {
    store.loading = val;
  }),
  setDeterminationTestError: action((error: RequestErrorModel | null) => {
    store.determinationTestError = error;
  }),
  // Reset all variables relative to a skill determination test in the state
  clearDeterminationTestState: action(() => {
    store.loading = false;
    store.determinationTest = null;
    store.determinationTestError = null;
    store.determinationTestSessionProgress = null;
  }),
  resetDeterminationStatus: action((): void => {
    store.determinationTestStatus = [];
  }),
};

const apiRequests = {
  fetchSkillDeterminationTest: action(async (productId: number, subjectId: number) => {
    store.setLoading(true);
    try {
      const response = await skillDeterminationRepository.fetchSkillDeterminationTest(
        productId,
        subjectId,
      );

      let sortedQuestions: ExamQuestion[] = response.questions;

      // Fetch current progress to skip some questions if the user is resuming an existing attempt
      const skillDeterminationTestSessionToken = response.token;
      try {
        const currentAttemptProgress =
          await skillDeterminationRepository.fetchSkillDeterminationTestSessionProgress(
            skillDeterminationTestSessionToken,
            productId,
            subjectId,
          );

        // Reorder questions to put previously answered ones first, so we avoid the user from getting errors
        // in case they've answered questions in the wrong order
        sortedQuestions = sortQuestionsWithAnsweredFirst(
          response.questions,
          currentAttemptProgress.answeredQuestions,
        );

        store.setDeterminationTestSessionProgress(currentAttemptProgress);
      } catch {
        // Default answered question count to 0 when no progress is found
        store.setDeterminationTestSessionProgress({
          answeredQuestions: [],
          skillDeterminationTestSessionToken,
        });
      }

      store.setDeterminationTest({ ...response, questions: sortedQuestions });
      store.setDeterminationTestError(null);
    } catch (e) {
      ToastMethods.showToast(i18n.t("toast:exam.error.fetchSkillDeterminationExam"), "error");
      const { message, status, type } = <ErrorResponse>e;
      store.setDeterminationTestError({ message, status, type });
    }
    store.setLoading(false);
  }),
  // Fetch status of on-going skill determination tests within the selected module
  fetchSkillDeterminationStatus: action(async (productId: number, moduleId: number) => {
    store.setLoading(true);
    try {
      const response = await skillDeterminationRepository.fetchSkillDeterminationStatus(
        productId,
        moduleId,
      );
      store.setDeterminationTestStatus(response);
    } catch (_e) {
      ToastMethods.showToast(i18n.t("toast:exam.error.fetchSkillDeterminationExam"), "error");
    }
    store.setLoading(false);
  }),
};

const store: DeterminationTestStore = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as DeterminationTestStore);

export const useDeterminationTest = (): DeterminationTestStore => store;
