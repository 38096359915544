import type React from "react";
import { useEffect, useState } from "react";

import { CssFlex } from "styles/helpers/layout";
import type { AchievementToastProps } from "./AchievementToast.model";
import {
  StyledCloseButton,
  StyledIcon,
  StyledMessage,
  StyledMessageContentContainer,
  StyledMessageText,
  StyledMessageTitle,
  TimerBarContainer,
  TimerBarFill,
} from "./AchievementToast.styled";

export const AchievementToast: React.FC<AchievementToastProps> = ({
  className,
  dataCy,
  iconName = "trophyIcon",
  children,
  duration,
  title,
  onFadeOut,
  showConfetti = false,
  showCountdown = false,
  showCloseButton = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [fadeTimeoutId, setFadeTimeoutId] = useState<NodeJS.Timeout>();

  // Automatically hide message after a few seconds
  // biome-ignore lint/correctness/useExhaustiveDependencies: Breaks if duration is added to dependencies
  useEffect(() => {
    setIsVisible(true);

    if (duration) {
      const timeoutId = setTimeout(() => {
        setIsVisible(false);

        // Call fade out event handler (if specified)
        onFadeOut?.();
      }, duration + 500);

      setFadeTimeoutId(timeoutId);
    }

    // Clear timeout on component unmount to avoid memory leak
    return () => {
      fadeTimeoutId && clearTimeout(fadeTimeoutId);
    };
  }, []);

  const onCloseButtonClick = () => {
    setIsVisible(false);
    onFadeOut?.();

    // Clear active timeout (if any) if user manually closed the toast
    fadeTimeoutId && clearTimeout(fadeTimeoutId);
  };

  return (
    <StyledMessage
      className={className}
      data-cy={dataCy}
      showConfetti={showConfetti}
      visible={isVisible}
    >
      <CssFlex flexDirection="column">
        {duration && showCountdown && (
          <TimerBarContainer data-cy="achievement-countdown-timer">
            <TimerBarFill duration={duration / 1000} />
          </TimerBarContainer>
        )}
        <StyledMessageContentContainer>
          <StyledIcon iconName={iconName} size={3} />
          <div>
            {showCloseButton && (
              <StyledCloseButton
                data-cy={`${dataCy || "achievement"}-close-button`}
                iconName="fas fa-times"
                onClick={onCloseButtonClick}
              />
            )}
            <StyledMessageTitle>{title}</StyledMessageTitle>
            <StyledMessageText>{children}</StyledMessageText>
          </div>
        </StyledMessageContentContainer>
      </CssFlex>
    </StyledMessage>
  );
};
