import type { TFunction } from "i18next";
import { observer } from "mobx-react";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFullScreen } from "hooks/useFullScreen";
import { useModal } from "hooks/useModal";

import { ExamRule } from "constants/exam-constants";
import { modalIds } from "constants/modals-constants";

import { AlertMessage } from "components/Alert/AlertMessage";
import { Button } from "components/Button";
import { Modal } from "components/Modal";

import type { ExamRuleFailingModalProps } from "./ExamRuleFailingModal.model";

const getRuleTitle = (t: TFunction, rule: ExamRule | null): string => {
  switch (rule) {
    case ExamRule.FULL_SCREEN:
      return t(
        "examRulesAlert.rules.fullScreen.title",
        "Your browser’s window has to be in full- screen mode",
      );
    case ExamRule.MOUSE_INSIDE:
      return t(
        "examRulesAlert.rules.mouseInside.title",
        "Your mouse cursor has to stay on this page",
      );
    default:
      return "";
  }
};

const getRuleSubtitle = (
  t: TFunction,
  rule: ExamRule | null,
  onClick?: () => void,
): string | JSX.Element => {
  switch (rule) {
    case ExamRule.FULL_SCREEN:
      return (
        <>
          {t("examRulesAlert.rules.fullScreen.subtitle")}
          <Button dataCy="fullscreen-button" onClick={onClick}>
            {t("examRulesAlert.rules.fullScreen.button")}
          </Button>
        </>
      );
    case ExamRule.MOUSE_INSIDE:
      return t(
        "examRulesAlert.rules.mouseInside.subtitle",
        "Please, move your mouse cursor back to this page",
      );
    default:
      return "";
  }
};

export const ExamRuleFailingModal: React.FC<ExamRuleFailingModalProps> = observer(
  ({ failingRules }) => {
    const { t } = useTranslation("test-environment");
    const { isModalOpen, toggleModal } = useModal();
    const { fullScreen } = useFullScreen();

    const [lastFailingRule, setLastFailingRule] = useState<ExamRule | null>(
      failingRules.length > 0 ? failingRules[0] : null,
    );

    const onFullScreenClicked = useCallback(() => {
      fullScreen?.enter();
    }, [fullScreen]);

    useEffect(() => {
      // when ANY rule is failing, always open the modal
      if (failingRules.length === 0) {
        return;
      }
      setLastFailingRule(failingRules[0]);
      if (!isModalOpen(modalIds.examRuleFailingModal)) {
        toggleModal(modalIds.examRuleFailingModal);
      }
    }, [failingRules.length]);
    const onContinueButtonClick = () => {
      // when NONE of the rules are failing, the user can close the modal using the button
      if (failingRules.length === 0) {
        toggleModal(modalIds.examRuleFailingModal);
      }
    };

    const isRuleBroken = failingRules.length > 0;

    return (
      <Modal
        closeOnClickOutside={false}
        isOpen={isModalOpen(modalIds.examRuleFailingModal)}
        showCloseButton={false}
      >
        <AlertMessage
          alertMessage={t(
            "examRulesAlert.alertMessage",
            "There is a rule that needs your attention",
          )}
          buttonDisabled={isRuleBroken}
          buttonTitle={t("examRulesAlert.button.title", "Continue")}
          dataCy="exam-rule-failing-alert"
          preText={
            isRuleBroken
              ? t("examRulesAlert.cannotContinue", "Sorry, you cannot continue")
              : t("examRulesAlert.canContinue", "Now you can continue")
          }
          subTitle={getRuleSubtitle(t, lastFailingRule, onFullScreenClicked)}
          title={getRuleTitle(t, lastFailingRule)}
          onButtonClick={onContinueButtonClick}
        />
      </Modal>
    );
  },
);
