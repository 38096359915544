import type React from "react";
import { useTranslation } from "react-i18next";

import { SvgIconReact } from "components/SvgIconReact/SvgIconReact";
import { TestHeader } from "components/TestHeader/TestHeader";
import { modalIds } from "constants/modals-constants";
import { useModal } from "hooks/useModal";
import { ExamProgressBar } from "../ExamProgressBar/ExamProgressBar";
import type { FixedTestHeaderProps } from "./FixedTestHeader.model";
import { StyledViewTheoryButton } from "./FixedTestHeader.styled";

export const FixedTestHeader: React.FC<FixedTestHeaderProps> = ({
  totalQuestionCount,
  selectedQuestionIndex,
  onClose,
  title,
  subtitle,
  currentQuestion,
  options,
}) => {
  const { t } = useTranslation("skill-determination-test");
  const { toggleModal } = useModal();
  const { showTheory } = options;
  const rightComponent = showTheory && currentQuestion && (
    <StyledViewTheoryButton
      dataCy="theory-modal-button"
      variant="secondary"
      onClick={() =>
        toggleModal(modalIds.viewTheoryModal, {
          topicId: currentQuestion.topicId || null,
        })
      }
    >
      <SvgIconReact iconName="theoryIcon" />
      <span>{t("header.button.viewTheory.label", "View theory")}</span>
    </StyledViewTheoryButton>
  );
  return (
    <TestHeader
      rightComponent={rightComponent}
      showCloseButton={!!onClose}
      subtitle={subtitle}
      title={title}
      onClose={onClose}
    >
      <ExamProgressBar
        selectedQuestionIndex={selectedQuestionIndex}
        totalQuestionCount={totalQuestionCount}
      />
    </TestHeader>
  );
};
