import { ADMIN_ENVIRONMENT_ROUTES } from "constants/routes";
import { observer } from "mobx-react";
import type React from "react";
import { useTranslation } from "react-i18next";

import { Heading2, Heading3 } from "styles/elements/Headings";
import { UserAdminBreadcrumbs } from "../../components/UserAdminBreadcrumbs/UserAdminBreadcrumbs";
import { UserUpdateForm } from "./components/UserUpdateForm/UserUpdateForm";

import { StyledUserDetailsPage, StyledUserUpdate } from "./UserDetails.styled";

export const UserDetailsPage: React.FC = observer(() => {
  const { t } = useTranslation("admin-environment");

  return (
    <StyledUserDetailsPage>
      <UserAdminBreadcrumbs
        currentPageLabel={t("accountDetails.breadcrumb.accountDetails", "Account profile edit")}
        currentPagePath={ADMIN_ENVIRONMENT_ROUTES.USER_DETAILS_PAGE}
        previousAdminUrl={ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE}
        previousRoutePath={ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE}
        previousRoutePathLabel={t("accountDetails.breadcrumb.accountsList", "Accounts list")}
        previousText={t("accountDetails.backButton.label", "Previous")}
      />
      <StyledUserUpdate>
        <Heading2>{t("accountDetails.updateUser.header", "Edit user profile")}</Heading2>
        <Heading3>{t("accountDetails.updateUser.title", "User Profile:")}</Heading3>
        <UserUpdateForm />
      </StyledUserUpdate>
    </StyledUserDetailsPage>
  );
});
