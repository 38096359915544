import { SortableTable } from "components/SortableTable/SortableTable";
import { StyledCell, StyledHeaderCell } from "components/SortableTable/SortableTable.styled";
import { SvgIconReact } from "components/SvgIconReact";
import styled from "styled-components/macro";

import type { ThemeProps } from "models/styles/theme";
import { Heading3 } from "styles/elements/Headings";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledHeader = styled(Heading3)<ThemeProps>`
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.normal};
`;

export const StyledTableContainer = styled.div<ThemeProps>`
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._900};
  margin-top: ${calculateRem(44)};
  padding: ${calculateRem(16)};
`;

export const StyledTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${calculateRem(32)};
`;

export const StyledSortableTable = styled(SortableTable)<ThemeProps>`
  ${StyledHeaderCell} {
    align-items: flex-start;
  }

  .header-cell-email,
  .header-cell-actions {
    align-items: center;
    justify-content: end;
  }

  ${StyledCell} {
    background: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
    svg {
      margin-right: 0.5rem;
    }
  }

  ${StyledCell}:nth-child(3) {
    justify-content: flex-end;
  }

`;

export const StyledIcon = styled(SvgIconReact)`
  cursor: pointer;
`;
