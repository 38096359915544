import { Select } from "components/Select";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { calculateRem } from "styles/mixins/calculateRem";

export const StyledInputSelectAdmin = styled(Select)<ThemeProps>`
  height: ${calculateRem(48)};
  margin-left: auto;
  margin-top: ${calculateRem(27)};
  width: ${calculateRem(242)};
`;
