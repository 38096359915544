import { ROLES } from "@infinitaslearning/module-he-common";

import type { SupportWidgetVariantConfig } from "../../models/variant.model";
import { commonWidgetOptions } from "./common";

const DefaultSupportWidgetConfig: SupportWidgetVariantConfig = {
  apiKey: "e0e97052-6a2d-4dd3-b6f9-0e98c09d0b27",
  configPerRole: {
    [ROLES.STUDENT]: {
      options: {
        ...commonWidgetOptions,
        webWidget: {
          ...commonWidgetOptions.webWidget,
          helpCenter: {
            ...commonWidgetOptions.webWidget?.helpCenter,
            filter: { category: "360005040514-Student" },
          },
          contactForm: {
            ...commonWidgetOptions.webWidget?.contactForm,
            ticketForms: [{ id: 360005937214 }],
          },
        },
      },
      suggestions: {
        labels: ["top-suggestion-student"],
      },
    },
    [ROLES.TEACHER]: {
      options: {
        ...commonWidgetOptions,
        webWidget: {
          ...commonWidgetOptions.webWidget,
          helpCenter: {
            ...commonWidgetOptions.webWidget?.helpCenter,
            filter: { category: "1500000120402-Docent" },
          },
          contactForm: {
            ...commonWidgetOptions.webWidget?.contactForm,
            ticketForms: [{ id: 360005937194 }],
          },
        },
      },
      suggestions: {
        labels: ["top-suggestion-teacher"],
      },
    },
  },
};

export default DefaultSupportWidgetConfig;
