import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading2 } from "styles/elements/Headings";

import { Button } from "components/Button";

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  column-gap: 1rem;

  ${Heading2} {
    margin: 0;
  }

  button {
    flex-shrink: 0;
  }

  & > div {
    display: flex;
    &:first-child {
      justify-content: space-between;
      margin-bottom: 3rem;
    }
    &:last-child {
      justify-content: end;
    }
  }
`;

export const StyledFraudLogButton = styled(Button)<Props>`
  border-color: ${({ theme }: Props) => theme.palette.primary.brand};
  color: ${({ theme }: Props) => theme.palette.primary.brand};
  font-weight: ${({ theme }: Props) => theme.font.weight.normal};
`;

export const StyledCertificateButton = styled(Button)<Props>`
  padding: 0.6875rem 2.4rem;
`;
