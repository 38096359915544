import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";
import { calculateRem } from "styles/mixins/mixins";

export const StyledContainer = styled.main<Props>`
  width: 70%;
  margin: 2.5rem auto;
  max-width: ${calculateRem(976)};
`;
