import styled from "styled-components/macro";

import type { ThemeProps as Props } from "models/styles/theme";

import { Heading2 } from "styles/elements/Headings";

import { Breadcrumbs } from "components/Breadcrumbs";

export const StyledBreadcrumbs = styled(Breadcrumbs)<Props>`
  margin-bottom: 2rem;
`;

export const StyledPageHeader = styled(Heading2)<Props>`
  margin-bottom: 1rem;
`;

export const StyledWrapper = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const StyledTable = styled.table<Props>`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 1rem 1.5rem;
  }

  thead {
    th {
      font-weight: bold;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        td {
          background: ${(props: Props) => props.theme.palette.neutral._999};
        }
      }
    }
  }
`;

export const StyledSkillLevelList = styled.div<Props>`
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;

  > :first-child,
  > :last-child {
    visibility: hidden;
  }
`;
