import { useDomainHandler } from "hooks/useDomainHandler";
import { StyledOverlay } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/TestTypeStep/TestTypeStep.styled";
import { StyledDarkOverlay } from "pages/TeacherEnvironment/TeacherDashboard/subpages/StudentLicense/subpages/CreateInviteForm/components/Invitation/Invitation.styled";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Heading3, Heading4 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { StyledFontAwesomeIcon } from "../InvalidateTest/InvalidateTest.styled";
import {
  StyledBodyWrapper,
  StyledCancelButton,
  StyledHeaderWrapper,
  StyledSubmitButton,
} from "./InvalidateTestOverlay.styled";
export interface InvalidateTestOverlayProps {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const InvalidateTestOverlay: FC<InvalidateTestOverlayProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation("teacher-dashboard");
  const { getStudentTermByDomain } = useDomainHandler();

  if (isOpen) {
    return (
      <StyledDarkOverlay>
        <StyledOverlay $marginTop="0rem" $paddingTop="1rem">
          <StyledHeaderWrapper>
            <StyledFontAwesomeIcon iconName="fal fa-exclamation-circle" iconSize="5x" />
            <Heading3>{t("scheduledTestForStudent.overlay.title")}</Heading3>
            <StyledFontAwesomeIcon iconName="fal fa-times" iconSize="2x" onClick={onClose} />
          </StyledHeaderWrapper>
          <StyledBodyWrapper>
            <Heading4>{t("scheduledTestForStudent.overlay.descriptionTitle")}</Heading4>
            <ParagraphLarge>
              {t("scheduledTestForStudent.overlay.description", {
                studentTerm: getStudentTermByDomain(),
              })}
            </ParagraphLarge>
          </StyledBodyWrapper>
          <StyledCancelButton
            dataCy="invalidate-student-cancel"
            variant="primary"
            onClick={onClose}
          >
            {t("scheduledTestForStudent.overlay.cancel")}
          </StyledCancelButton>
          <StyledSubmitButton
            dataCy="invalidate-student-submit"
            variant="primary"
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            {t("scheduledTestForStudent.overlay.submit")}
          </StyledSubmitButton>
        </StyledOverlay>
      </StyledDarkOverlay>
    );
  }
  return <></>;
};
