import type React from "react";
import type { FormErrorMessageProps } from "./FormErrorMessage.model";
import { StyledFormErrorMessage } from "./FormErrorMessage.styled";

export const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ dataCy, errors }) => {
  if (!errors || errors.length === 0) {
    return null;
  }
  return <StyledFormErrorMessage data-cy={dataCy}>{errors[0]}</StyledFormErrorMessage>;
};
