import { SvgIconReact } from "components/SvgIconReact/SvgIconReact";
import type React from "react";
import type { IconButtonProps } from "./IconButton.model";
import { StyledIconButton } from "./IconButton.styled";

export const IconButton: React.FC<IconButtonProps> = ({
  isCircle = true,
  iconName,
  iconSize = 1,
  ...buttonProps
}) => (
  <StyledIconButton {...buttonProps} isNarrow $isCircle={isCircle}>
    <SvgIconReact iconName={iconName} size={iconSize} />
  </StyledIconButton>
);
