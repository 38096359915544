import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type { OptionProps } from "components/Select/Select.model";

import { StyledField, StyledInput, StyledSelect, StyledUsersFilter } from "./UsersFilter.styled";

interface UsersFilterProps {
  educations: OptionProps[];
  schools: OptionProps[];
  selectedEducation?: string;
  selectedSchool?: string;
  searchValueParam: string;
  onEducationChange: (education: string) => void;
  onSearchValueChange: (searchValueParam: string) => void;
  onSchoolChange: (school: string) => void;
}

export const UsersFilter: React.FC<UsersFilterProps> = ({
  searchValueParam,
  schools,
  educations,
  onSearchValueChange,
  onEducationChange,
  onSchoolChange,
  selectedEducation,
  selectedSchool,
}) => {
  const { t } = useTranslation("admin-environment");
  const [searchValue, setSearchValue] = useState(searchValueParam);

  useEffect(() => {
    if (!searchValue && !!searchValueParam) {
      setSearchValue(searchValueParam);
    }
  }, [searchValueParam]);

  return (
    <StyledUsersFilter>
      <StyledField>
        <label htmlFor="input">
          {t("userFilter.input.label", "Search on lastname, email or student number")}
        </label>
        <StyledInput
          data-cy="accounts-filter-input"
          id="input"
          value={searchValue}
          onBlur={() => onSearchValueChange(searchValue)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
        />
      </StyledField>
      <StyledSelect
        ariaLabel="school-selector"
        dataCy="school-selector"
        defaultValue="all"
        options={schools}
        value={selectedSchool}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSchoolChange(e.target.value)}
      />
      <StyledSelect
        ariaLabel="education-selector"
        dataCy="education-selector"
        defaultValue="all"
        disabled={!selectedSchool || selectedSchool === "all"}
        options={educations}
        value={selectedEducation}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onEducationChange(e.target.value)}
      />
    </StyledUsersFilter>
  );
};
