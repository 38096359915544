import type React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components/macro";

import { ModalProvider } from "contexts/ModalProvider";

import { LoadingSpinner } from "components/LoadingSpinner/LoadingSpinner";
import { PlatformDownPage } from "components/PlatformDownPage";
import { PlatformMaintainanceModal } from "components/PlatformMaintainanceModal";
import { ToastNotification } from "components/ToastNotification";
import { TooltipContainer } from "components/TooltipContainer";
import { GlobalAppStyles } from "styles/GlobalApp.styled";

import { FeatureFlagsProvider } from "contexts/FeatureFlagProvider";
import { HelmetProvider } from "contexts/HelmetProvider/HelmetProvider";
import { UsetifulProvider } from "contexts/UsetifulProvider/UsetifulProvider";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import AppRouter from "./router/AppRouter";
import ScrollToTop from "./router/ScrollToTop";
import { hogesTheme } from "./themes/hoges";

import { useMetaBasePageTracker } from "analytics/MetabaseTracker/useMetabasePageTracker";
import { ErrorRenderer } from "components/ErrorRenderer/ErrorRenderer";
import { ImmersiveReaderProvider } from "contexts/ImmersiveReaderProvider/ImmersiveReaderProvider";
import { useProfile } from "hooks/useProfile";
import { observer } from "mobx-react";
import { logAPMError, setAxiosLogger } from "observability";
import { ErrorBoundary } from "react-error-boundary";
import SupportWidget from "supportWidget/SupportWidget";
import { getTheme } from "themes";

setAxiosLogger();

export const StyledLoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const HSTApp: React.FC = () => {
  const { isLoading, showPlatformIsDownMessageModal } = useFeatureFlags();
  useMetaBasePageTracker();

  return (
    <>
      <GlobalAppStyles />
      <PlatformMaintainanceModal />
      {isLoading ? (
        <StyledLoaderWrapper>
          <LoadingSpinner />
        </StyledLoaderWrapper>
      ) : showPlatformIsDownMessageModal ? (
        <PlatformDownPage />
      ) : (
        <ScrollToTop>
          <AppRouter />
        </ScrollToTop>
      )}
      <ToastNotification />
      <TooltipContainer />
    </>
  );
};

interface ComponentWithChildren {
  children: React.ReactNode;
}

const ThemeContainer: React.FC<ComponentWithChildren> = observer(({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(hogesTheme);
  const { isBusinessThemeActive } = useFeatureFlags();
  const { isBusinessDomain } = useDomainHandler();
  const { userDetails } = useProfile();

  useEffect(() => {
    const isBusinessTheme = isBusinessDomain() && isBusinessThemeActive;
    const textSize = userDetails?.textSize;
    const theme = getTheme(!!isBusinessTheme, textSize);

    setCurrentTheme(theme);
  }, [isBusinessThemeActive, userDetails?.textSize, isBusinessDomain]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
});

const App: React.FC = () => (
  <Suspense fallback={null}>
    <ErrorBoundary
      fallbackRender={({ error }) => <ErrorRenderer message={error?.message} />}
      onError={(error, { componentStack }) => logAPMError(error, JSON.stringify(componentStack))}
    >
      <FeatureFlagsProvider>
        <ThemeContainer>
          <Suspense
            fallback={
              <StyledLoaderWrapper>
                <LoadingSpinner />
              </StyledLoaderWrapper>
            }
          >
            <BrowserRouter>
              <HelmetProvider>
                <ModalProvider>
                  <UsetifulProvider>
                    <ImmersiveReaderProvider>
                      <HSTApp />
                      <SupportWidget />
                    </ImmersiveReaderProvider>
                  </UsetifulProvider>
                </ModalProvider>
              </HelmetProvider>
            </BrowserRouter>
          </Suspense>
        </ThemeContainer>
      </FeatureFlagsProvider>
    </ErrorBoundary>
  </Suspense>
);

export default App;
