import { useEffect, useState } from "react";

import { useProfile } from "hooks/useProfile";

export default (email?: string): void => {
  const { userDetails, isRegistrationComplete, fetchRegistrationDetails } = useProfile();

  const [interval, setRegCheckInterval] = useState<NodeJS.Timeout>();

  useEffect(() => {
    // If user sync is in progress, start interval
    if (isRegistrationComplete === false && email) {
      setRegCheckInterval(
        setInterval(() => {
          !isRegistrationComplete && fetchRegistrationDetails(email);
        }, 1000),
      );
    }

    // Automatically stop interval when registration complete flag is no longer "false"
    return () => {
      interval && clearInterval(interval);
    };
  }, [isRegistrationComplete, userDetails]);
};
