import triangleSvg from "assets/icons/triangle.svg";
import classnames from "classnames";
import { Checkbox } from "components/Checkbox/Checkbox";
import { SvgIconReact } from "components/SvgIconReact";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import type React from "react";
import { useRef, useState } from "react";
import type { OptionsDropdownProps } from "./OptionsDropdown.model";
import {
  OptionDropdownTriangle,
  OptionsDropdownContent,
  OptionsDropdownHeader,
  OptionsDropdownHeaderIcon,
} from "./OptionsDropdown.styled";

export const OptionsDropdown: React.FC<OptionsDropdownProps> = ({
  dataCy,
  disabled = false,
  direction = "bottom",
  iconName,
  options,
  title,
  onOptionToggle,
}) => {
  const elRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useOnClickOutside({
    ref: elRef,
    handler: () => setIsOpen(false),
  });
  const toggleOpen = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };
  return (
    <div ref={elRef} data-cy={dataCy}>
      <OptionsDropdownHeader
        $isOpen={isOpen}
        className={classnames(disabled && "disabled")}
        onClick={toggleOpen}
      >
        <div>
          {iconName && (
            <OptionsDropdownHeaderIcon>
              <SvgIconReact iconName={iconName} size={1.5} />
            </OptionsDropdownHeaderIcon>
          )}
          <span>{title}</span>
        </div>
        <OptionDropdownTriangle $isOpen={isOpen}>
          <img alt="Open" src={triangleSvg} />
        </OptionDropdownTriangle>
      </OptionsDropdownHeader>
      {isOpen && (
        <OptionsDropdownContent $direction={direction} $isOpen={isOpen}>
          <div>
            <ul>
              {options.map((option) => (
                <li key={option.value}>
                  <Checkbox
                    checked={option.isChecked}
                    disabled={option.isDisabled}
                    id={option.value}
                    label={option.label}
                    onChange={() => onOptionToggle(option)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </OptionsDropdownContent>
      )}
    </div>
  );
};
