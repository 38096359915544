import { observer } from "mobx-react";
import type React from "react";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { ExamStatusTag } from "components/ExamStatusTag";
import { SvgIconReact } from "components/SvgIconReact";
import { TEST_PAGE_ROUTES } from "constants/routes";
import type { DiagnosticTestPagePathParams } from "models/path/path-params";
import { useParams } from "react-router-dom";
import type { DiagnosticTestListItemProps } from "./DiagnosticTestListItem.model";
import {
  StyledAccordionDiagnosticListItem,
  StyledDiagnosticTestRowItem,
  StyledPanelRightHeader,
} from "./DiagnosticTestListItem.styled";

export const DiagnosticTestListItem: React.FC<DiagnosticTestListItemProps> = observer(
  ({ item, title = "", testId, status }) => {
    const { productId, moduleId, groupId }: DiagnosticTestPagePathParams = useParams();

    const hasVisibleStatusTag = !!ExamStatusTag({ status });

    return (
      <StyledDiagnosticTestRowItem
        to={buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_DETAILS, {
          productId,
          moduleId,
          groupId,
          testId,
        })}
      >
        <StyledAccordionDiagnosticListItem
          key={item.id}
          dataCy={`diagnostic-test-list-item-${item.id}`}
          headerPanelRight={
            <StyledPanelRightHeader>
              {hasVisibleStatusTag ? <ExamStatusTag status={status} /> : <div />}
              <SvgIconReact iconName="rightArrow" />
            </StyledPanelRightHeader>
          }
          title={title}
        />
      </StyledDiagnosticTestRowItem>
    );
  },
);
