import { showToast } from "components/ToastNotification/ToastNotification.methods";
import { ImmersiveReaderContext } from "contexts/ImmersiveReaderProvider/ImmersiveReaderProvider";
import { logAPMError } from "observability";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

interface UseImmersiveReaderHook {
  loading: boolean;
  irEnabled: boolean;
  handleClickImmersiveReader: (index: number) => void;
}

export const useImmersiveReader = (contentSelector: string): UseImmersiveReaderHook => {
  const { loading, setImmersiveReaderClicked, irEnabled, setContent } =
    useContext(ImmersiveReaderContext);
  const { t } = useTranslation("common");

  // IDEA: could pass no argument, and then use the closest element with a data-ir attribute
  const handleClickImmersiveReader = (index: number) => {
    if (!irEnabled) {
      showToast(t("immersiveReader.notEnabled"), "error");
      return;
    }
    try {
      setImmersiveReaderClicked(true);
      const content = document
        .querySelectorAll(`${contentSelector}`)
        [index].cloneNode(true) as HTMLElement;

      const contentToSkip = content.querySelectorAll(".irSkip");
      // biome-ignore lint/complexity/noForEach: <explanation>
      contentToSkip.forEach((element) => element.remove());

      setContent(content.innerHTML);
    } catch (e) {
      showToast(t("immersiveReader.failedToGetContent"), "error");
      logAPMError(e as Error, "immersive-reader");
    }
  };

  return { loading, irEnabled, handleClickImmersiveReader };
};
