import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading1 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";

export const StyledErrorRendererContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

export const StyledErrorRendererTitle = styled(Heading1)`
  font-size: ${(props: ThemeProps) => props.theme.font.size._32};
  margin: 0.5em 0;
`;

export const StyledErrorRendererDetails = styled(ParagraphLarge)`
  font-size: ${(props: ThemeProps) => props.theme.font.size._24};
  margin: 0.5em 0;
`;
