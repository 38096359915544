import type { ThemeProps as Props } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";

export const StyledDiagnosticWrapper = styled.main<Props>`
  margin-top: 2rem;
  padding: 1rem;
`;

export const StyledDiagnosticInnerWrapper = styled.div<Props>`
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._900};
  margin-top: 2rem;
  padding: 1rem;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledHeading3 = styled(Heading3)<Props>`
  font-weight: 300;
`;
