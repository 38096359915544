import { FontAwesomeIcon } from "components/FontAwesomeIcon";
import type React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledFontAwesomeIcon } from "./DownloadReportButton.styled";

export interface DownloadReportButtonProps {
  loading: boolean;
  onClick: () => void;
}

export const DownloadReportButton: React.FC<DownloadReportButtonProps> = ({ loading, onClick }) => {
  const { t } = useTranslation("teacher-dashboard");

  return (
    <StyledButton dataCy="download-report-button" variant="secondary" onClick={onClick}>
      {loading ? (
        <>
          {t("downloadReportButton.loading")}
          <FontAwesomeIcon iconName="fas fa-spinner fa-spin" />
        </>
      ) : (
        <>
          {t("downloadReportButton.title")}
          <StyledFontAwesomeIcon iconName="fas fa-arrow-alt-to-bottom" />
        </>
      )}
    </StyledButton>
  );
};
