import classnames from "classnames";
import type React from "react";

import type SvgIconReactProps from "./SvgIconReact.model";
import { StyledSvgIconReact } from "./SvgIconReact.styled";

export const SvgIconReact: React.FC<SvgIconReactProps> = ({
  iconName,
  size = 1,
  dataCy = "",
  className,
}) => (
  <StyledSvgIconReact
    className={classnames(className, "icon")}
    data-cy={dataCy}
    iconName={iconName}
    size={size}
  />
);
