import type React from "react";
import sanitizeHtml, { type IOptions as SanitizeOptions } from "sanitize-html";

interface SanitizedHtmlProps {
  className?: string;
  dataCy?: string;
  dirtyHtml: string;
  id?: string;
  options?: SanitizeOptions;
  tagName?: keyof JSX.IntrinsicElements;
}

export const SanitizedHtml: React.FC<SanitizedHtmlProps> = ({
  id,
  className,
  dataCy,
  dirtyHtml,
  options: sanitizeOptions,
  tagName,
}) => {
  const clean = sanitizeHtml(dirtyHtml, sanitizeOptions);

  const Tag = tagName || "div";
  return (
    <Tag
      className={className}
      // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
      dangerouslySetInnerHTML={{ __html: clean }}
      data-cy={dataCy}
      id={id}
    />
  );
};
