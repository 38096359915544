import type { ThemeProps } from "models/styles/theme";
import styled, { css } from "styled-components/macro";
import { errorInputCss } from "styles/helpers/errors";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
  $hasError?: boolean;
  $hasPostFix?: boolean;
}

const postfixInputCss = css`
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const StyledErrorMessage = styled.span<Props>`
  ${(props: Props) => {
    if (props.$hasError) {
      return css`
        color: ${props.theme.palette.feedback.error.brand};
        display: block;
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

export const StyledInputWrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const StyledInput = styled.input<Props>`
  border: none;
  outline: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  border-radius: 2px;
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._600};
  ${({ $hasError }) => $hasError && errorInputCss}
  ${({ $hasPostFix }) => $hasPostFix && postfixInputCss}
`;

export const StyledInputPostFix = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._600};
  ${({ $hasError }) => $hasError && errorInputCss}
  border-radius: 2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  padding: 0 1rem;
`;
