import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { capitalizeFirstLetter } from "_helpers/utils/stringFormatter";
import { isProd, isStaging } from "constants/env-constants";

const buildVersion = process.env.REACT_APP_BUILD_ID;

// Define custom formatters to use in interpolated values
// @see https://www.i18next.com/translation-function/formatting
const initCustomFormatters = (value, format) => {
  switch (format) {
    case "firstLetterToUppercase":
      return capitalizeFirstLetter(value);
    default:
      return value;
  }
};

// Initialize instance of i18n
i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "nl"],
    fallbackLng: isProd() || isStaging() ? "nl" : "en", // Default language
    ns: ["toast", "proctoring-options", "test-request-form"],
    debug: false,
    react: {
      useSuspense: true,
    },
    interpolation: {
      format: initCustomFormatters,
    },
    // Append version query param to i18next requests so it works properly with caching
    // @see https://infinitas-chat.slack.com/archives/C028MSNU9NH/p1739267845554969
    ...(buildVersion
      ? {
          backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json?version=${buildVersion}`,
          },
        }
      : {}),
  });

export default i18n;
