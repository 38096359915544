import { Card } from "components/Card/Card";
import { StyledSubTitle } from "components/Card/Card.styled";
import { SkeletonLoader } from "components/SkeletonLoader/SkeletonLoader";
import { useProductProgress } from "hooks/useProductProgress";
import type { UserProgressPerSubject } from "models/progress/Progress";
import { type FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Heading3, Heading4 } from "styles/elements/Headings";
import { Box, CssGrid } from "styles/helpers/layout";

export interface AccountGroupedProgressProps {
  subjects: UserProgressPerSubject[];
}

export const AccountGroupedProgress: FC<AccountGroupedProgressProps> = ({ subjects }) => {
  const { answeredQuestionsForAccount } = useProductProgress();
  const { t } = useTranslation("teacher-dashboard");
  if (!answeredQuestionsForAccount) return <SkeletonLoader $marginTop={2} />;

  const { questionsGroupedByPeriod, questionsGroupedBySubject } = answeredQuestionsForAccount;

  const cards = [
    {
      title: t("basicProgress.forAccount.groupedProgress.groupingMethodOne"),
      subTitle: t("basicProgress.forAccount.groupedProgress.title"),
      groupDefinition: {
        keyTitle: t("basicProgress.forAccount.groupedProgress.subject"),
        valueTitle: t("basicProgress.forAccount.groupedProgress.totalAnswers"),
      },
      content: subjects.map(({ id, title }) => (
        <Fragment key={`${id}-${title}`}>
          <div data-cy="grouped-subject-title">{title}</div>
          <Box data-cy="grouped-subject-value" textAlign="center">
            {questionsGroupedBySubject.find((x) => x.subjectId === id)?.answeredQuestions ?? "-"}
          </Box>
        </Fragment>
      )),
    },
    {
      title: t("basicProgress.forAccount.groupedProgress.groupingMethodTwo"),
      subTitle: t("basicProgress.forAccount.groupedProgress.title"),
      groupDefinition: {
        keyTitle: t("basicProgress.forAccount.groupedProgress.periodTitle"),
        valueTitle: t("basicProgress.forAccount.groupedProgress.totalAnswers"),
      },
      content: (
        <>
          <div>{t("basicProgress.forAccount.groupedProgress.period.week")}</div>
          <Box data-cy="grouped-period-value" textAlign="center">
            {questionsGroupedByPeriod.find((x) => x.period === "week")?.answeredQuestions ?? 0}
          </Box>
          <div>{t("basicProgress.forAccount.groupedProgress.period.month")}</div>
          <Box data-cy="grouped-period-value" textAlign="center">
            {questionsGroupedByPeriod.find((x) => x.period === "month")?.answeredQuestions ?? 0}
          </Box>
          <div>{t("basicProgress.forAccount.groupedProgress.period.quarter")}</div>
          <Box data-cy="grouped-period-value" textAlign="center">
            {questionsGroupedByPeriod.find((x) => x.period === "quarter")?.answeredQuestions ?? 0}
          </Box>
        </>
      ),
    },
  ];

  return (
    <>
      {cards.map(({ title, subTitle, groupDefinition, content }) => (
        <Card key={title} layout={{ p: 2 }}>
          <Heading3 fontWeight="normal">{title}</Heading3>
          <Heading4 fontWeight="normal">{subTitle}</Heading4>
          <CssGrid gridTemplateColumns="70% 30%" m="auto" width="80%">
            <StyledSubTitle variant="thick">{groupDefinition.keyTitle}</StyledSubTitle>
            <StyledSubTitle variant="thick">{groupDefinition.valueTitle}</StyledSubTitle>
          </CssGrid>
          <CssGrid gridTemplateColumns="50% 50%" m="auto" mb={2} width="80%">
            {content}
          </CssGrid>
        </Card>
      ))}
    </>
  );
};
