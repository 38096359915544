import { ReactComponent as WellDoneIcon } from "assets/icons/well-done-icon.svg";
import { SvgIconReact } from "components/SvgIconReact";
import { useDomainHandler } from "hooks/useDomainHandler";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Heading1, Heading3 } from "styles/elements/Headings";
import { TermsFooter } from "../components/TermsFooter/TermsFooter";
import { StyledHeader, StyledWrapper } from "./AccountCreatedPage.styled";

export const AccountCreatedPage: FC = () => {
  const { t } = useTranslation("auth-pages");
  const { getCustomLogoByDomain } = useDomainHandler();

  return (
    <>
      <StyledHeader>
        <SvgIconReact iconName={getCustomLogoByDomain()} size={13} />
      </StyledHeader>
      <StyledWrapper>
        <div>
          <WellDoneIcon />
          <Heading1>{t("invitationPage.accountCreated.title")}</Heading1>
          <Heading3>{t("invitationPage.accountCreated.description")}</Heading3>
        </div>
      </StyledWrapper>
      <TermsFooter />
    </>
  );
};
