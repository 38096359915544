import type React from "react";

import { ReactComponent as CongratsIcon } from "assets/icons/Illustraton_congrats.svg";
import { ReactComponent as ArrowBackward } from "assets/icons/arrow-backward.svg";
import { ReactComponent as ArrowLongRight } from "assets/icons/arrow-right-long.svg";
import { ReactComponent as BadgeIcon } from "assets/icons/badge.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ChartLineDown } from "assets/icons/chart-line-down.svg";
import { ReactComponent as ChartLineUp } from "assets/icons/chart-line-up.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as CircleCheckIcon } from "assets/icons/circle-check.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as CloseX } from "assets/icons/closeX.svg";
import { ReactComponent as CodeIcon } from "assets/icons/code.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as DotIcon } from "assets/icons/dot.svg";
import { ReactComponent as DownArrow } from "assets/icons/down-arrow.svg";
import { ReactComponent as DownloadOutlineIcon } from "assets/icons/download-outline.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as DragIcon } from "assets/icons/drag.svg";
import { ReactComponent as EnglandFlagComponent } from "assets/icons/england-flag.svg";
import { ReactComponent as EnvelopeIcon } from "assets/icons/envelope.svg";
import { ReactComponent as ExamIcon } from "assets/icons/exam-icon.svg";
import { ReactComponent as ExternalLink } from "assets/icons/external-link.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye-icon.svg";
import { ReactComponent as FaceIcon } from "assets/icons/face-icon.svg";
import { ReactComponent as FileWord } from "assets/icons/file-word.svg";
import { ReactComponent as FlameIcon } from "assets/icons/flame.svg";
import { ReactComponent as EmptyFolderIcon } from "assets/icons/freepik-empty-folder.svg";
import { ReactComponent as GraduationCapIcon } from "assets/icons/graduation-cap.svg";
import { ReactComponent as GreenCheckIcon } from "assets/icons/green-check.svg";
import { ReactComponent as GreenCircleCheckIcon } from "assets/icons/green-circle-check.svg";
import { ReactComponent as MedalsIcon } from "assets/icons/grey-medals.svg";
import { ReactComponent as Group } from "assets/icons/group.svg";
import { ReactComponent as HamburgerIcon } from "assets/icons/hamburger.svg";
import { ReactComponent as ImmersiveReaderIcon } from "assets/icons/immersive-reader.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as ItemListIcon } from "assets/icons/item-list-icon.svg";
import { ReactComponent as LeftArrowIcon } from "assets/icons/left-arrow.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as LogoHogesWithTextComponent } from "assets/icons/logo-hogeschooltaal-text.svg";
import { ReactComponent as LogoNoordhoffComponent } from "assets/icons/logo-noordhoff.svg";
import { ReactComponent as MedalIcon } from "assets/icons/medal.svg";
import { ReactComponent as NetherlandsFlagComponent } from "assets/icons/netherlands-flag.svg";
import { ReactComponent as PartyIcon } from "assets/icons/party.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";
import { ReactComponent as AccessibilityIcon } from "assets/icons/person-solid.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { ReactComponent as RigthArrow } from "assets/icons/right-arrow.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as ShieldCheckIcon } from "assets/icons/shield-check-icon.svg";
import { ReactComponent as BadgeStarIcon } from "assets/icons/star-badge.svg";
import { ReactComponent as StudentIcon } from "assets/icons/student.svg";
import { ReactComponent as StudyIcon } from "assets/icons/study.svg";
import { ReactComponent as TheoryIcon } from "assets/icons/theory-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash-icon.svg";
import { ReactComponent as TrophyAltIcon } from "assets/icons/trophy-alt.svg";
import { ReactComponent as TrophyIcon } from "assets/icons/trophy.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as WarningExclamationTriangle } from "assets/icons/warning-exclamation-triangle.svg";
import { ReactComponent as WellDoneIcon } from "assets/icons/well-done-icon.svg";
import { ReactComponent as LogoBeterschrijvenWithTextComponent } from "assets/logo/logo-beterschrijven-noordhoff.svg";
import { ReactComponent as LogoNoordhoffBusiness } from "assets/logo/logo-noordhoff-business.svg";
import { ReactComponent as LogoUniversiteitstaalWithTextComponent } from "assets/logo/logo-universiteitstaal.svg";

export interface IconComponentNames {
  arrowBackward: React.ElementType;
  badgeIcon: React.ElementType;
  badgeStarIcon: React.ElementType;
  calendarIcon: React.ElementType;
  checkIcon: React.ElementType;
  circleCheckIcon: React.ElementType;
  greenCircleCheckIcon: React.ElementType;
  clockIcon: React.ElementType;
  closeX: React.ElementType;
  codeIcon: React.ElementType;
  congratsIcon: React.ElementType;
  dotIcon: React.ElementType;
  downArrow: React.ElementType;
  downloadIcon: React.ElementType;
  downloadOutlineIcon: React.ElementType;
  dragIcon: React.ElementType;
  emptyFolderIcon: React.ElementType;
  englandFlag: React.ElementType;
  arrowLongRight: React.ElementType;
  envelopeIcon: React.ElementType;
  examIcon: React.ElementType;
  externalLink: React.ElementType;
  eyeIcon: React.ElementType;
  faceIcon: React.ElementType;
  graduationCapIcon: React.ElementType;
  greenCheckIcon: React.ElementType;
  hamburgerIcon: React.ElementType;
  leftArrowIcon: React.ElementType;
  logoHogesWithText: React.ElementType;
  logoNoordhoff: React.ElementType;
  logoNoordhoffBusiness: React.ElementType;
  medal: React.ElementType;
  netherlandsFlag: React.ElementType;
  partyIcon: React.ElementType;
  pencilIcon: React.ElementType;
  plusIcon: React.ElementType;
  rightArrow: React.ElementType;
  searchIcon: React.ElementType;
  shieldCheckIcon: React.ElementType;
  studentIcon: React.ElementType;
  studyIcon: React.ElementType;
  theoryIcon: React.ElementType;
  trashIcon: React.ElementType;
  trophyIcon: React.ElementType;
  trophyAltIcon: React.ElementType;
  warningExclamationTriangle: React.ElementType;
  wellDoneIcon: React.ElementType;
  infoIcon: React.ElementType;
  logoUniversiteitstaalWithText: React.ElementType;
  medalsIcon: React.ElementType;
  group: React.ElementType;
  fileWord: React.ElementType;
  logoBeterschrijvenWithText: React.ElementType;
  user: React.ElementType;
  chartLineUp: React.ElementType;
  chartLineDown: React.ElementType;
  flameIcon: React.ElementType;
  itemListIcon: React.ElementType;
  copyIcon: React.ElementType;
  lockIcon: React.ElementType;
  immersiveReader: React.ElementType;
  accessibilityIcon: React.ElementType;
  externalLinkIcon: React.ElementType;
}

export type IconComponentName = keyof IconComponentNames;

export const iconComponentNames: IconComponentNames = {
  arrowBackward: ArrowBackward,
  badgeIcon: BadgeIcon,
  badgeStarIcon: BadgeStarIcon,
  calendarIcon: CalendarIcon,
  checkIcon: CheckIcon,
  circleCheckIcon: CircleCheckIcon,
  greenCircleCheckIcon: GreenCircleCheckIcon,
  clockIcon: ClockIcon,
  closeX: CloseX,
  codeIcon: CodeIcon,
  congratsIcon: CongratsIcon,
  dotIcon: DotIcon,
  downArrow: DownArrow,
  downloadIcon: DownloadIcon,
  downloadOutlineIcon: DownloadOutlineIcon,
  dragIcon: DragIcon,
  emptyFolderIcon: EmptyFolderIcon,
  englandFlag: EnglandFlagComponent,
  envelopeIcon: EnvelopeIcon,
  examIcon: ExamIcon,
  externalLink: ExternalLink,
  eyeIcon: EyeIcon,
  faceIcon: FaceIcon,
  graduationCapIcon: GraduationCapIcon,
  greenCheckIcon: GreenCheckIcon,
  hamburgerIcon: HamburgerIcon,
  leftArrowIcon: LeftArrowIcon,
  logoHogesWithText: LogoHogesWithTextComponent,
  logoNoordhoff: LogoNoordhoffComponent,
  logoNoordhoffBusiness: LogoNoordhoffBusiness,
  medal: MedalIcon,
  netherlandsFlag: NetherlandsFlagComponent,
  partyIcon: PartyIcon,
  pencilIcon: PencilIcon,
  plusIcon: PlusIcon,
  rightArrow: RigthArrow,
  searchIcon: SearchIcon,
  shieldCheckIcon: ShieldCheckIcon,
  studentIcon: StudentIcon,
  studyIcon: StudyIcon,
  theoryIcon: TheoryIcon,
  trashIcon: TrashIcon,
  trophyIcon: TrophyIcon,
  trophyAltIcon: TrophyAltIcon,
  warningExclamationTriangle: WarningExclamationTriangle,
  wellDoneIcon: WellDoneIcon,
  infoIcon: InfoIcon,
  logoUniversiteitstaalWithText: LogoUniversiteitstaalWithTextComponent,
  medalsIcon: MedalsIcon,
  group: Group,
  fileWord: FileWord,
  logoBeterschrijvenWithText: LogoBeterschrijvenWithTextComponent,
  user: UserIcon,
  chartLineDown: ChartLineDown,
  chartLineUp: ChartLineUp,
  flameIcon: FlameIcon,
  itemListIcon: ItemListIcon,
  arrowLongRight: ArrowLongRight,
  copyIcon: CopyIcon,
  lockIcon: LockIcon,
  immersiveReader: ImmersiveReaderIcon,
  accessibilityIcon: AccessibilityIcon,
  externalLinkIcon: ExternalLink,
};
