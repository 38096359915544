import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { CssButton } from "styles/helpers/layout";
import { calculateRem } from "styles/mixins/mixins";

export const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const StyledButton = styled(CssButton)`
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  font-weight: bold;

  &.showTip {
    padding: 0 1rem;
    color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.palette.neutral._950};
    }
    span {
      font-size: ${(props: ThemeProps) => props.theme.font.size._16};
      font-weight: bold;
      margin-left: ${calculateRem(14)};
    }
  }
`;

export const StyledExercise = styled.div<ThemeProps>`
  display: flex;
  flex: 1 1 0;
  align-items: center;
  flex-direction: column;
  width: 75%;
  margin: 0 auto;
  background-color: ${(props: ThemeProps) => props.theme.palette.neutral.white};
  padding: ${calculateRem(32)};
  border-radius: 4px;
  button.submit {
    width: 9rem;
    font-size: ${(props: ThemeProps) => props.theme.font.size._16};
    padding: 0.8rem 1rem;
    border-radius: 4px;
    margin: ${calculateRem(32)} 0;
  }
`;
