import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type { Exercise, ExerciseStatusResponse, TestDetails } from "models/exam/Exam";
import { handlingResponse, logError } from "repositories/utils";

import type {
  DiagnosticTest,
  DiagnosticTestDetailsResult,
  DiagnosticTestGroup,
  DiagnosticTestResult,
} from "models/exam/DiagnosticTest";
import cookieStorage from "persistence";

export const fetchExercise = async (productId: number, exerciseId: number): Promise<Exercise> => {
  try {
    const result = await axios.get(`/api/products/${productId}/exercises/${exerciseId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<Exercise>([200], "Error retrieving test", "exerciseExamError")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_EXERCISE_FAILED });
  }
};

export const fetchExerciseStatus = async (
  exerciseId: number,
  testToken?: string,
): Promise<ExerciseStatusResponse> => {
  try {
    const response = await axios.get(`/api/exercises/${exerciseId}/status`, {
      headers: testToken
        ? {
            test_token: testToken,
            Authorization: cookieStorage.getToken(),
          }
        : {
            Authorization: cookieStorage.getToken(),
          },
    });
    return handlingResponse<ExerciseStatusResponse>(
      [200, 204],
      "Error checking exercise status",
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_DIAGNOSTIC_TEST_STATUS });
  }
};

// Retrieve diagnostic test levels list
export const fetchDiagnosticTestGroups = async (
  productId: number,
  moduleId: number,
): Promise<DiagnosticTestGroup[]> => {
  try {
    const diagnosticTestGroups = await axios.get(
      `/api/products/${productId}/modules/${moduleId}/diagnostic-test/groups`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<DiagnosticTestGroup[]>(
      [200],
      "Error retrieving diagnostic test groups",
    )(diagnosticTestGroups);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_DIAGNOSTIC_TEST_GROUPS });
  }
};

// Retrieve diagnostic test list for one level selected
export const fetchDignosticTestList = async (
  productId: number,
  moduleId: number,
  groupId: number,
): Promise<DiagnosticTest[]> => {
  try {
    const diagnosticTestList = await axios.get(
      `/api/products/${productId}/modules/${moduleId}/diagnostic-test/groups/${groupId}`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<DiagnosticTest[]>(
      [200],
      "Error retrieving diagnostic tests list",
    )(diagnosticTestList);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_DIAGNOSTIC_TEST_LIST });
  }
};

// Retrieve diagnostic test list results for each test in a group
export const fetchDiagnosticTestListResults = async (
  productId: number,
  moduleId: number,
  groupId: number,
): Promise<DiagnosticTestResult[]> => {
  try {
    const diagnosticTestListResults = await axios.get(
      `/api/products/${productId}/modules/${moduleId}/diagnostic-test/groups/${groupId}/results`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<DiagnosticTestResult[]>(
      [200],
      "Error retrieving diagnostic tests list results",
    )(diagnosticTestListResults);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_DIAGNOSTIC_TEST_LIST_RESULTS });
  }
};

// Retrieve diagnostic test details for the test selected
export const fetchDiagnosticTestDetails = async (
  productId: number,
  moduleId: number,
  groupId: number,
  testId: number,
): Promise<TestDetails> => {
  try {
    const diagnosticTestDetails = await axios.get(
      `/api/products/${productId}/modules/${moduleId}/diagnostic-test/groups/${groupId}/tests/${testId}`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<TestDetails>(
      [200],
      "Error retrieving diagnostic test details",
    )(diagnosticTestDetails);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_DIAGNOSTIC_TEST_DETAILS });
  }
};

// Retrieve diagnostic test details results for the exercises in the test selected
export const fetchDiagnosticTestDetailsResults = async (
  productId: number,
  moduleId: number,
  groupId: number,
  testId: number,
): Promise<DiagnosticTestDetailsResult[]> => {
  try {
    const diagnosticTestDetailsResults = await axios.get(
      `/api/products/${productId}/modules/${moduleId}/diagnostic-test/groups/${groupId}/tests/${testId}/results`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<DiagnosticTestDetailsResult[]>(
      [200],
      "Error retrieving diagnostic test details results",
    )(diagnosticTestDetailsResults);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_DIAGNOSTIC_TEST_DETAILS_RESULTS,
    });
  }
};
