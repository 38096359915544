import styled from "styled-components/macro";
import { calculateRems } from "styles/mixins/calculateRem";
import { calculateRem } from "styles/mixins/mixins";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

interface Props {
  theme: ElearningThemeInterface;
  background?: string;
}

export const StyledNoOwnedBanner = styled.div<Props>`
  width: 100%;
  height: ${calculateRem(160)};
  display: flex;

  text-align: left;
  align-items: center;
  justify-content: space-between;

  background-image: url(${(props) => props.background});
  background-size: cover;
  padding: ${calculateRems([32, 24, 24, 24])};
`;

export const StyledMessage = styled.div<Props>`
  h3 {
    font-weight: ${(props: Props) => props.theme.font.weight.bold};
    font-size: ${(props: Props) => props.theme.font.size._24};
    color: ${(props: Props) => props.theme.palette.neutral.white};
    letter-spacing: 0;
    margin-bottom: ${calculateRem(19)};
  }

  p {
    font-size: ${(props: Props) => props.theme.font.size._18};
    color: ${(props: Props) => props.theme.palette.neutral.white};
    margin: 0;
    line-height: normal;
  }
`;
