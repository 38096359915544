import styled from "styled-components/macro";

import { Select } from "components/SelectUI/Select";
import { CssFlex } from "styles/helpers/layout";

import type { ThemeProps as Props } from "models/styles/theme";

export const StyledContentContainer = styled(CssFlex)`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 2rem;
`;

export const StyledSubjectFilterWrapper = styled(CssFlex)<Props>`
  color: #707070;
`;

export const StyledSelect = styled(Select)`
  width: 15rem;

  > :first-child {
    border: 0;
  }
`;
