import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";
import { Button } from "../../components/Button";
import { calculateRem } from "../../styles/mixins/mixins";

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledViewTheoryButton = styled(Button)`
  border: none;
  padding: 0.8rem 1rem;
  cursor: pointer;
  font-weight: ${({ theme }: Props) => theme.font.weight.bold};
  font-size: ${({ theme }: Props) => theme.font.size._16};
  color: ${({ theme }: Props) => theme.palette.neutral.black};
  background: ${({ theme }: Props) => theme.palette.neutral._950};

  .content {
    display: flex;
    align-items: center;
    justify-items: center;
    column-gap: ${calculateRem(14)};
  }
`;
