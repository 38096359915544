import { ToastMethods } from "components/ToastNotification";
import i18n from "i18n";
import { action, observable } from "mobx";
import type { SchoolyearDashboard } from "models/schoolyear/Schoolyear";
import { schoolyearRepository } from "repositories";

interface SchoolyearInterface {
  loading: boolean;
  isSchoolyearActive: boolean;
  schoolyearDashboard: SchoolyearDashboard;

  fetchIsSchoolyearActive: () => void;
  fetchSchoolyearDashboard: (scheduledTestId: number) => void;

  setLoading: (isLoading: boolean) => void;
  setIsSchoolyearActive: (isSchoolyearActive: boolean) => void;
  setSchoolyearDashboard: (schoolyearDashboard: SchoolyearDashboard) => void;
}

const initialState = {
  loading: false,
  isSchoolyearActive: false,
};

const stateSetters = {
  setSchoolyearDashboard: action((schoolyearDashboard: SchoolyearDashboard) => {
    store.schoolyearDashboard = schoolyearDashboard;
  }),
  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),
  setIsSchoolyearActive: action((isSchoolyearActive: boolean) => {
    store.isSchoolyearActive = isSchoolyearActive;
  }),
};

const apiRequests = {
  fetchSchoolyearDashboard: action((scheduledTestId: number) => {
    store.setLoading(true);
    schoolyearRepository
      .getSchoolyearDashboard(scheduledTestId)
      .then((schoolyearDashboard) => {
        store.setSchoolyearDashboard(schoolyearDashboard);
      })
      .catch(() => {
        ToastMethods.showToast(
          i18n.t("toast:scheduledTest.error.fetchSchoolyearDashboard"),
          "error",
        );
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  // Fetch Schoolyear signature from headers to identify if user is currently using Schoolyear app
  fetchIsSchoolyearActive: action(() => {
    store.setLoading(true);
    schoolyearRepository
      .getSchoolyearSignature()
      .then((signature: string) => {
        store.setIsSchoolyearActive(!!signature);
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
};

const store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as SchoolyearInterface);

export const useSchoolyear = (): SchoolyearInterface => store;
