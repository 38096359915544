import { Button } from "components/Button";
import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import { Heading3 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { calculateRem } from "styles/mixins/calculateRem";

type ParagraphProps = ThemeProps & {
  $marginTop?: number;
};

type ButtonProps = ThemeProps & {
  $transparentBackground?: boolean;
};

export const StyledHeading = styled(Heading3)<ThemeProps>`
  line-height: ${calculateRem(36)};
  margin-top: ${calculateRem(24)};
`;

export const StyledParagraph = styled(ParagraphLarge)<ParagraphProps>`
  color: ${(props: ThemeProps) => props.theme.palette.neutral._500};
  line-height: ${calculateRem(24)};
  margin-bottom: 0;
  margin-top: ${({ $marginTop }: ParagraphProps) => calculateRem($marginTop || 0)};
`;

export const StyledButton = styled(Button)<ButtonProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: unset;
  margin: ${calculateRem(16)};
  border: ${({ $transparentBackground, theme }: ButtonProps) =>
    $transparentBackground ? `solid ${theme.palette.primary.brand}` : "none"};
  background: ${({ $transparentBackground, theme }: ButtonProps) =>
    $transparentBackground ? theme.palette.neutral._950 : theme.palette.primary.brand};
  color: ${({ $transparentBackground, theme }: ButtonProps) =>
    $transparentBackground ? theme.palette.primary.brand : theme.palette.text.primary};
  :hover {
    background: ${({ $transparentBackground, theme }: ButtonProps) =>
      $transparentBackground && theme.palette.neutral._950};
  }
`;
