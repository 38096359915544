import type { ThemeProps } from "models/styles/theme";
import styled from "styled-components/macro";
import type { ElearningThemeInterface } from "themes/elearningInterface.theme";

import { calculateRem } from "styles/mixins/calculateRem";

interface TimerProps {
  theme: ElearningThemeInterface;
  timeLeft: number;
}

export const StyledTimerBar = styled.div<ThemeProps>`
  align-items: center;
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  display: flex;
  font-size: ${({ theme }: ThemeProps) => theme.font.size._14};

  > svg {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
  }
`;

export const StyledTimeLeft = styled.div<TimerProps>`
  background: ${({ theme, timeLeft }: TimerProps) =>
    `linear-gradient(
      to right,
      ${theme.palette.feedback.success._800} 0% ${timeLeft}%,
      ${theme.palette.neutral.white} ${timeLeft}% 100%)`};
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._500};
  height: ${calculateRem(26)};
  margin: 0 ${calculateRem(16)};
  width: ${calculateRem(450)};
`;
