import styled from "styled-components/macro";

import { Button } from "components/Button";
import { Input } from "components/Input";
import { Select } from "components/SelectUI/Select";
import type { ThemeProps } from "models/styles/theme";
import { ParagraphLarge } from "styles/elements/Texts";

export const StyledParagraph = styled(ParagraphLarge)`
  color: ${(props: ThemeProps) => props.theme.palette.neutral._500};
  line-height: 24px;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const StyledButton = styled(Button)`
  display: flex;
  width: fit-content;
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: bold;
  padding: 0.5rem 1rem;
  .content {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const StyledUploadInput = styled.input`
  border: 1px black dashed;
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInput = styled(Input)`
  width: 34rem;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  text-align: left;
`;
