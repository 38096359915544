import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { type URLFields, buildURLwithQueryParams } from "_helpers/utils/urlBuilder";
import { useDomainHandler } from "hooks/useDomainHandler";
import useOnLocationChange from "hooks/useOnLocationChange";
import { useScheduledTests } from "hooks/useScheduledTests";
import queryString from "query-string";

import { Paginator } from "components/Paginator/Paginator";
import { SvgIconReact } from "components/SvgIconReact";
import { RESULT_PAGE_ROUTES } from "constants/routes";

import type {
  CertificateResultsPageLayoutProps,
  StudentCertificatesQueryParams,
} from "../../CertificateTestResultsPage.model";
import { CertificateNoResultsPage } from "../CertificateNoResultsPage/CertificateNoResultsPage";
import { CertificateStudentsTable } from "../CertificatesStudentsTable/CertificatesStudentsTable";

import { SnackBar } from "components/SnackBar/SnackBar";
import { StyledContainer, StyledNotice, StyledTitle } from "./CertificateResultsPageLayout.styled";

export const CertificateResultsPageLayout: React.FC<CertificateResultsPageLayoutProps> = observer(
  ({ dataCy, noResultsMessage, title, subtitle, buttonText }) => {
    const history = useHistory();
    const { queryParams } = useOnLocationChange(history);
    const query = queryString.parse(window.location.search);
    const { scheduledTestsCertificates, fetchScheduledTestsCertificates } = useScheduledTests();
    const { t } = useTranslation("results");
    const { getTeacherTermByDomain } = useDomainHandler();

    const { page = 1, results = 6 }: StudentCertificatesQueryParams =
      queryString.parse(queryParams);

    useEffect(() => {
      fetchScheduledTestsCertificates({
        page: +page,
        resultsPerPage: +results,
      });
    }, [query.page, query.results]);

    const paginationFilters = { page, results };

    const filters = Object.keys(paginationFilters).map((key) => ({
      key,
      value: paginationFilters[key as keyof typeof paginationFilters],
    })) as URLFields[];

    const onFilterChange = (_filterValue: string | number, key: string) => {
      const newFilters = filters
        .map((filter) => {
          if (filter.key === "page" && key !== "page") {
            return { key: "page", value: 1 };
          }
          return filter;
        })
        .filter((newFilter) => newFilter.value);

      history.push(buildURLwithQueryParams(RESULT_PAGE_ROUTES.OBTAINED_CERTIFICATES, newFilters));
    };

    const links = useMemo(
      () =>
        [...Array(scheduledTestsCertificates.totalPages)].map((_value, filterPage: number) => {
          const newFilters = filters;
          newFilters[newFilters.findIndex((newFilter) => newFilter.key === "page")].value =
            filterPage + 1;
          const searchURL = buildURLwithQueryParams(
            RESULT_PAGE_ROUTES.OBTAINED_CERTIFICATES,
            newFilters,
          );
          return {
            path: searchURL,
            title: filterPage + 1,
          };
        }),
      [scheduledTestsCertificates.totalPages],
    );

    const resultsAvailable =
      scheduledTestsCertificates && scheduledTestsCertificates.data?.length > 0;

    return (
      <StyledContainer data-cy={dataCy}>
        {!resultsAvailable && (
          <SnackBar
            text={t("placeholder.noCertificatesSnackBar", {
              teacherTerm: getTeacherTermByDomain(),
            })}
          />
        )}
        <StyledTitle>{title}</StyledTitle>
        {resultsAvailable ? (
          <>
            <StyledNotice>
              <SvgIconReact iconName="medal" size={3} />
              <span data-cy="certificates-subtitle">{subtitle}</span>
            </StyledNotice>
            <CertificateStudentsTable
              certificates={scheduledTestsCertificates}
              selectedResults={+results}
            />
            <Paginator
              currentPage={+page}
              links={links}
              totalPages={scheduledTestsCertificates.totalPages}
              onChangePage={(selectedPage) => onFilterChange(selectedPage, "page")}
            />
          </>
        ) : (
          <CertificateNoResultsPage buttonText={buttonText} noResultsMessage={noResultsMessage} />
        )}
      </StyledContainer>
    );
  },
);
