import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { SvgIconReact } from "components/SvgIconReact";
import type { PaginatorProps } from "./Paginator.model";
import {
  StyledButton,
  StyledDot,
  StyledLink,
  StyledPages,
  StyledPaginationSelector,
  StyledPaginator,
} from "./Paginator.styled";

const PAGE_MAX_LIMIT = 4;

export const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  linkBuilderFunction,
}) => {
  const history = useHistory();
  const { t } = useTranslation("common");

  const numPagesToDisplay = totalPages > PAGE_MAX_LIMIT ? PAGE_MAX_LIMIT : totalPages;

  let firstDisplayedPage = currentPage > 1 ? currentPage - 1 : currentPage;
  let lastDisplayedPage = firstDisplayedPage + numPagesToDisplay - 1;

  // Last displayed page must never exceed the total; if so, readjust page numbers to show
  if (lastDisplayedPage > totalPages) {
    firstDisplayedPage =
      totalPages - numPagesToDisplay > 0 ? totalPages - numPagesToDisplay + 1 : currentPage;
    lastDisplayedPage = totalPages;
  }

  const pagesToDisplay: Array<number> = Array.from(
    { length: numPagesToDisplay },
    (_, i) => i + firstDisplayedPage,
  );

  const onPageButtonClick = (newPage: number) => {
    onPageChange?.(newPage);
    linkBuilderFunction && history.push(linkBuilderFunction("page", newPage));
  };

  return (
    <StyledPaginator data-cy="paginator-container">
      <StyledButton
        dataCy="previous-page-button"
        disabled={currentPage === 1}
        variant="secondary"
        onClick={() => onPageButtonClick(currentPage - 1)}
      >
        <SvgIconReact iconName="leftArrowIcon" />
        {t("paginator.previousButton.label", "Previous")}
      </StyledButton>
      <StyledPages>
        {firstDisplayedPage > 1 && <StyledDot>&hellip;</StyledDot>}
        {pagesToDisplay.map((numPage) =>
          linkBuilderFunction ? (
            <StyledLink
              key={numPage}
              $selected={currentPage === numPage}
              aria-label={t("paginator.pageNavigator.label", { pageNumber: numPage })}
              data-cy={`paginator-link-${numPage}`}
              to={linkBuilderFunction ? linkBuilderFunction("page", numPage) : ""}
            >
              {numPage}
            </StyledLink>
          ) : (
            <StyledPaginationSelector
              key={numPage}
              $selected={currentPage === numPage}
              p={1}
              onClick={() => onPageButtonClick(numPage)}
            >
              {numPage}
            </StyledPaginationSelector>
          ),
        )}
        {lastDisplayedPage < totalPages && <StyledDot>&hellip;</StyledDot>}
      </StyledPages>
      <StyledButton
        dataCy="next-page-button"
        disabled={currentPage === totalPages}
        variant="brand"
        onClick={() => onPageButtonClick(currentPage + 1)}
      >
        {t("paginator.nextButton.label", "Next")}
        <SvgIconReact iconName="rightArrow" />
      </StyledButton>
    </StyledPaginator>
  );
};
