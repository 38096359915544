import axios, { type AxiosError } from "axios";
import cookieStorage from "persistence";

import type { UsersAPI } from "generated/types";
import { ERROR_TYPES } from "../../constants/error-constants";
import { type ApiRequestError, RequestError } from "../../models/error/Error";
import type { AddedStudentsData, Group } from "../../models/groups/Group";
import { handlingResponse, logError } from "../utils";

// Return list of all groups accessible by the calling teacher
//  (optionally filtered so they only see groups created by themselves)
export const getTeacherGroups = async (ownedOnly?: boolean): Promise<UsersAPI.GroupListItem[]> => {
  try {
    const result = await axios.get("/api/groups", {
      headers: { Authorization: cookieStorage.getToken() },
      params: { ownedOnly },
    });
    return handlingResponse<UsersAPI.GroupListItem[]>(
      [200],
      "Error retrieving teacher groups for the teacher",
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_TEACHER_GROUPS_FAILED });
  }
};

export const getGroup = async (id: number): Promise<Group> => {
  try {
    const result = await axios.get(`/api/groups/${id}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<Group>([200], `Error retrieving group ${id}`)(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_GROUP_FAILED });
  }
};

export const deleteGroup = async (id: number): Promise<string> => {
  try {
    const result = await axios.delete(`/api/groups/${id}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<string>([200], `Error retrieving group ${id}`)(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.DELETE_GROUP_FAILED });
  }
};

export const createGroup = async (groupName: string): Promise<string> => {
  try {
    const result = await axios.post(
      "/api/groups",
      { groupName },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<string>([200], "Error creating a group")(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.CREATE_GROUP_FAILED });
  }
};

export const editGroupName = async (id: number, groupName: string): Promise<string> => {
  try {
    const result = await axios.patch(
      `/api/groups/${id}`,
      { groupName },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<string>([200], `Error editing group ${id} name`)(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.EDIT_GROUP_NAME_FAILED });
  }
};

export const addStudentsToGroup = async (
  id: number,
  studentsToAdd: string[],
): Promise<AddedStudentsData> => {
  try {
    const result = await axios.patch(
      `/api/groups/${id}/add-students`,
      { studentsToAdd },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<AddedStudentsData>(
      [200],
      `Error adding students to group: ${id}`,
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.ADD_STUDENTS_TO_GRUP_FAILED });
  }
};

export const removeStudentsFromGroup = async (
  id: number,
  studentsToRemove: number[],
): Promise<string> => {
  try {
    const result = await axios.patch(
      `/api/groups/${id}/remove-students`,
      { studentsToRemove },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
    return handlingResponse<string>([200], `Error removing students from group: ${id}`)(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.REMOVE_STUDENTS_FROM_GRUP_FAILED });
  }
};
