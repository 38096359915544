import axios, { type AxiosError } from "axios";
import { ERROR_TYPES } from "constants/error-constants";
import { type ApiRequestError, RequestError } from "models/error/Error";
import type { SchoolyearDashboard } from "models/schoolyear/Schoolyear";
import cookieStorage from "persistence";
import { handlingResponse, logError } from "repositories/utils";

const getSchoolyearDashboard = async (scheduledTestId: number): Promise<SchoolyearDashboard> => {
  try {
    const response = await axios.get(
      `/api/scheduled-test/${scheduledTestId}/schoolyear/dashboard`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<SchoolyearDashboard>(
      [200],
      "Error fetching schoolyear dashboard",
    )(response);
  } catch (error) {
    const { message, status, errCode } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({
      message,
      status,
      errCode,
      type: ERROR_TYPES.GET_SCHOOLYEAR_DASHBOARD_FAILED,
    });
  }
};

// Fetch Schoolyear signature from request headers (we need it to check if user is using Schoolyear)
const getSchoolyearSignature = async (): Promise<string> => {
  try {
    const sySignature = await axios.get("/api/schoolyear/header", {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<string>(
      [200],
      "Error fetching Schoolyear signature from headers",
    )(sySignature);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<ApiRequestError>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHOOLYEAR_SIGNATURE_FAILED });
  }
};

export { getSchoolyearDashboard, getSchoolyearSignature };
