import { RadioButton } from "components/RadioButton/RadioButton";
import { ToggleButton } from "components/ToggleButton/ToggleButton";
import type { ExamType } from "constants/exam-constants";
import { useDomainHandler } from "hooks/useDomainHandler";
import { useReactTooltip } from "hooks/useReactTooltip";
import { useFormUpdate } from "pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/hooks/useFormUpdate";
import type React from "react";
import { type ReactElement, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Heading2 } from "styles/elements/Headings";
import { ParagraphLarge } from "styles/elements/Texts";
import { StyledDarkOverlay } from "../../../StudentLicense/subpages/CreateInviteForm/components/Invitation/Invitation.styled";
import {
  FormLabel,
  FormStep,
  FormStepHeader,
  FormStepHeading,
  FormStepIntro,
} from "../FormStep.styled";
import type { TestTypeStepFormValues, TestTypeStepProps } from "./TestTypeStep.model";
import {
  Content,
  ProductButtons,
  StyledErrorMessage,
  StyledFontAwesomeIcon,
  StyledInfoIcon,
  StyledOverlay,
  TestTypeButtons,
} from "./TestTypeStep.styled";
import { createValidationSchema } from "./TestTypeStep.validation";

export const TestTypeStep: React.FC<TestTypeStepProps> = ({
  productOptions,
  formValues,
  isDisabled = false,
  onFormUpdate,
  testGroupOptions,
}) => {
  useReactTooltip();
  const { t } = useTranslation("scheduled-tests");
  const { getCustomNameByDomain, getStudentTermByDomain, getTeacherTermByDomain } =
    useDomainHandler();

  const [examType, setExamType] = useState<ExamType>();
  const [values, setValues] = useState<TestTypeStepFormValues>(
    (({ productId, testType = "summative-test" }) => ({
      productId,
      testType,
    }))(formValues) as TestTypeStepFormValues,
  );

  const validationSchema = createValidationSchema();
  useFormUpdate<typeof validationSchema>({
    values,
    validationSchema,
    onFormUpdate,
  });

  useEffect(() => {
    // select the first product in the list if none is selected yet
    if (productOptions.length > 0 && !values.productId) {
      setValues({
        ...values,
        productId: productOptions[0].id,
      });
    }
  }, [productOptions]);

  const overlayTitleAndDescription: [string, string | ReactElement] = useMemo(() => {
    const appName = getCustomNameByDomain();
    const teacherTerm = getTeacherTermByDomain();
    const studentTerm = getStudentTermByDomain({ usePlural: true });
    switch (examType) {
      case "formative-test":
        return [
          t("testType.formative.label"),
          t("testType.formative.overlay", { studentTerm, teacherTerm }),
        ];
      case "summative-test":
        return [
          t("testType.summative.label"),
          <Trans
            key="summative"
            components={{
              nav: (
                // tag will have content once translated
                // biome-ignore lint/a11y/useAnchorContent: <explanation>
                <a
                  href="https://hogeschooltaal.zendesk.com/hc/nl/articles/4404836656407"
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
            i18nKey={"testType.summative.overlay"}
            t={t}
            values={{ appName, studentTerm }}
          />,
        ];
      default:
        return [
          t("testType.freebie.label"),
          t("testType.freebie.overlay", { appName, studentTerm }),
        ];
    }
  }, [examType]);

  const updateValue = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <>
      {examType && (
        <StyledDarkOverlay $alignItems="flex-start">
          <StyledOverlay>
            <StyledFontAwesomeIcon
              iconName="fal fa-times"
              iconSize="2x"
              onClick={() => setExamType(undefined)}
            />
            <Heading2>{overlayTitleAndDescription[0]}</Heading2>
            <ParagraphLarge>{overlayTitleAndDescription[1]}</ParagraphLarge>
          </StyledOverlay>
        </StyledDarkOverlay>
      )}
      <FormStep>
        <FormStepHeader>
          <FormStepHeading>{t("testTypeStep.header", "Create a test  - stap 1")}</FormStepHeading>
          <FormStepIntro>
            {t("testTypeStep.intro", {
              studentTerm: getStudentTermByDomain({ usePlural: true }),
            })}
          </FormStepIntro>
        </FormStepHeader>
        <Content>
          <FormLabel htmlFor="test-request-product">
            {t("testTypeStep.testProduct.label", "What kind of test do you want to schedule?")}
          </FormLabel>
          <ProductButtons data-cy="test-request-product-buttons">
            {productOptions.map(({ id, flagIconName, title }) => (
              <ToggleButton
                key={id}
                dataCy={`test-request-product-button-${id}`}
                disabled={isDisabled}
                iconName={flagIconName}
                isActive={values.productId === id}
                onClick={() => setValues({ ...values, productId: id })}
              >
                {title}
              </ToggleButton>
            ))}
          </ProductButtons>
          {testGroupOptions.length > 0 ? (
            <TestTypeButtons>
              <RadioButton
                checked={values.testType === "summative-test"}
                dataCy="test-request-summative-test-radio-button"
                disabled={isDisabled}
                label={
                  <>
                    {t("testType.summative.label", "Summative test")}
                    <StyledInfoIcon onClick={() => setExamType("summative-test")} />
                  </>
                }
                name="test-request-type"
                value="summative-test"
                onChange={updateValue("testType")}
              />
              <RadioButton
                checked={values.testType === "formative-test"}
                dataCy="test-request-formative-test-radio-button"
                disabled={isDisabled}
                label={
                  <>
                    {t("testType.formative.label", "Formative test")}
                    <StyledInfoIcon
                      $marginLeft="2.5rem"
                      onClick={() => setExamType("formative-test")}
                    />
                  </>
                }
                name="test-request-type"
                value="formative-test"
                onChange={updateValue("testType")}
              />
              <RadioButton
                checked={values.testType === "freebie-test"}
                dataCy="test-request-freebie-test-radio-button"
                disabled={isDisabled}
                label={
                  <>
                    {t("testType.freebie.label", "Free intake test")}
                    <StyledInfoIcon
                      $marginLeft="1.9rem"
                      onClick={() => setExamType("freebie-test")}
                    />
                  </>
                }
                name="test-request-type"
                value="freebie-test"
                onChange={updateValue("testType")}
              />
            </TestTypeButtons>
          ) : (
            <StyledErrorMessage data-cy="no-tests-available-message">
              {t("testType.noTestsAvailable", "No tests available for this product.")}
            </StyledErrorMessage>
          )}
        </Content>
      </FormStep>
    </>
  );
};
