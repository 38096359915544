import { observer } from "mobx-react";
import type React from "react";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { buildUrlWithPathParams } from "_helpers/utils/urlBuilder";
import { PRODUCT_PAGES_ROUTES } from "constants/routes";
import { useProduct } from "hooks/useProduct";

import { SanitizedHtml } from "components/SanitizedHtml";

import type { ModuleSubjectChapters } from "models/product/ModuleDetails";
import type { TopicDetailsPagePathParams as PathParams } from "../../TopicDetailsPage.model";

import {
  StyledNavPlaceholder as EmptyItem,
  StyledNavContainer,
  StyledNavIcon,
  StyledNavLink,
  StyledNavText,
} from "./TopicNavigation.styled";

interface SubjectTopic {
  id: number;
  title: string;
  chapter: string;
}

export const TopicNavigation: React.FC = observer(() => {
  const { productId, moduleId, subjectId, topicId }: PathParams = useParams();

  const { moduleDetails, fetchModuleDetails } = useProduct();

  useEffect(() => {
    if (!moduleDetails || moduleDetails.id !== +moduleId) {
      fetchModuleDetails(+productId, +moduleId);
    }
  }, [moduleId]);

  const subjectTopics = useMemo(() => {
    if (!moduleDetails || moduleDetails.id !== +moduleId) {
      return [];
    }

    const subject = moduleDetails?.subjects.find(({ id }) => id === +subjectId);
    return subject?.chapters.reduce(
      (topicList: SubjectTopic[], currentChapter: ModuleSubjectChapters) => [
        // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
        ...topicList,
        ...currentChapter.topics.map((topic) => ({
          id: topic.id,
          title: topic.title,
          chapter: currentChapter.title,
        })),
      ],
      [],
    );
  }, [moduleDetails]);

  if (!subjectTopics || subjectTopics.length === 0) {
    return null;
  }

  const buildTopicDetailsUrl = (newTopicId: number) =>
    buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_TOPIC_DETAILS, {
      productId,
      moduleId,
      subjectId,
      topicId: newTopicId,
    });

  const currentIndex = subjectTopics?.findIndex(({ id }) => id === +topicId);
  const prevTopic = subjectTopics[currentIndex - 1];
  const nextTopic = subjectTopics[currentIndex + 1];

  return (
    <StyledNavContainer>
      {prevTopic ? (
        <StyledNavLink data-cy="prev-topic-button" to={buildTopicDetailsUrl(prevTopic.id)}>
          <StyledNavIcon iconName="fas fa-chevron-left" />
          <StyledNavText align="left">
            <div>
              <SanitizedHtml dirtyHtml={prevTopic.title} />
            </div>
            <div>
              <SanitizedHtml dirtyHtml={prevTopic.chapter} />
            </div>
          </StyledNavText>
        </StyledNavLink>
      ) : (
        <EmptyItem />
      )}
      {nextTopic ? (
        <StyledNavLink data-cy="next-topic-button" to={buildTopicDetailsUrl(nextTopic.id)}>
          <StyledNavText align="right">
            <SanitizedHtml dirtyHtml={nextTopic.title} />
            <SanitizedHtml dirtyHtml={nextTopic.chapter} />
          </StyledNavText>
          <StyledNavIcon iconName="fas fa-chevron-right" />
        </StyledNavLink>
      ) : (
        <EmptyItem />
      )}
    </StyledNavContainer>
  );
});
